import React, { useCallback, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { Portal } from '@mui/material';
import Form from '../form/formik/Form';
import TextInput from '../form/formik/TextInput';
import ColorPicker from '../form/formik/ColorPicker';
import { IdPropType } from '../../modules/proptypes';
import { RESOURCE_RESOURCE } from '../../modules/api/resources';
import PreSelect from '../form/formik/PreSelect';
import SubmitButton from '../form/formik/SubmitButton';
import { selectResourceById } from '../../modules/resources/selectors';
import { selectAllResourceClasses } from '../../modules/resourceClasses/selectors';
import { selectAllResourceTypes } from '../../modules/resourceTypes/selectors';
import { storeResource, updateResource } from '../../modules/resources/actions';
import { resourceSchema } from '../../modules/resources/schema';
import { useDialog } from '../dialogs/DialogContext';
import SelectOverride from '../form/formik/SelectOverride';
import { prefilled } from '../../modules/form/utils';
import IconSelect from '../form/formik/IconSelect';
import ResourceTypeSelect from './ResourceTypeSelect';
import ResourceClassSelect from './ResourceClassSelect';

const useStyles = makeStyles(theme => ({
    lowerButtons: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'flex-end',
        width: 'calc(100% - 264px)',
        bottom: 0,
    },
    inputRow: {
        width: '100%',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

const ResourceForm = ({ resourceId, initialValues, onDone, submitRef, can, ...other }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { openResourceDialog } = useDialog();
    const [filteredClasses, setFilteredClasses] = useState([]);

    const resource = useSelector(state => selectResourceById(state, resourceId));
    const resourceClasses = useSelector(selectAllResourceClasses);
    const resTypes = useSelector(selectAllResourceTypes);

    const getSelectedClass = useCallback(
        resourceClass =>
            resourceClasses.find(
                clss => resourceClass && clss.id === resourceClass.resource_class_id
            ),
        [resourceClasses]
    );

    const getSelectedType = useCallback(
        res => {
            const selectedClass = getSelectedClass(res);

            return resTypes.find(
                type => selectedClass && type.id === selectedClass.resource_type_id
            );
        },
        [resTypes, getSelectedClass]
    );

    const formValues = useMemo(() => {
        const initial = {
            name: '',
            identifier: '',
            short_name: '',
            inventory_no: '',
            color: '#000000',
            icon: '',
            resource_class_id: '',
            resource_type_id: '',
        };

        /** don't modify state object -> create copy */
        const prefill = { ...(resource || initialValues || {}) };

        if (prefill.resource_class_id) {
            const selectedType = getSelectedType(prefill);
            prefill.resource_type_id = selectedType ? selectedType.id : '';
        }

        return prefilled(initial, prefill);
    }, [initialValues, resource, getSelectedType]);

    const handleSubmit = values => {
        const params = { ...values };

        if (resourceId) {
            params.id = resourceId;
        }

        // If we copy a resource, we need to copy the groups, too
        if (initialValues && initialValues.groups && initialValues.groups.length) {
            params.groups = initialValues.groups;
        }

        dispatch(resourceId ? updateResource(params) : storeResource(params)).then(response => {
            if (onDone) {
                onDone(response);
            }
            if (!resourceId) {
                openResourceDialog({ resourceId: response.data.id });
            }
            return response;
        });
    };

    return (
        <Box>
            <Form
                initialValues={formValues}
                onSubmit={handleSubmit}
                subject={RESOURCE_RESOURCE}
                validationSchema={resourceSchema}
                enableReinitialize
                {...other}
            >
                <PreSelect
                    watchTarget="resource_type_id"
                    filteredTarget="resource_class_id"
                    className={classes.inputRow}
                    options={resourceClasses}
                    onChange={setFilteredClasses}
                />
                <SelectOverride
                    className={classes.inputRow}
                    requestSelectedOption={getSelectedClass}
                    keys={['icon', 'color']}
                    emptyOptions={['#000000']}
                />
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <TextInput
                            className={classes.inputRow}
                            name="name"
                            label={t('form.ResourceForm.name')}
                            size="small"
                            can={can}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <ResourceTypeSelect
                            className={classes.inputRow}
                            name="resource_type_id"
                            label={t('form.ResourceForm.type')}
                            size="small"
                            fullWidth
                            can={can}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            className={classes.inputRow}
                            name="identifier"
                            label={t('form.ResourceForm.identifier')}
                            size="small"
                            can={can}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <ResourceClassSelect
                            className={classes.inputRow}
                            name="resource_class_id"
                            options={filteredClasses.map(clss => ({
                                label: clss.name,
                                value: clss.id,
                            }))}
                            label={t('form.ResourceForm.class')}
                            size="small"
                            fullWidth
                            can={can}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            className={classes.inputRow}
                            name="inventory_no"
                            label={t('form.ResourceForm.inventoryNum')}
                            size="small"
                            can={can}
                        />
                    </Grid>

                    <Grid item xs={6} />
                    <Grid item xs={6}>
                        <Grid
                            alignItems="center"
                            container
                            spacing={1}
                            className={classes.separateLine}
                        >
                            <Grid item xs={3}>
                                <TextInput
                                    className={classes.inputRow}
                                    name="short_name"
                                    label={t('form.ResourceForm.short')}
                                    size="small"
                                    can={can}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <ColorPicker
                                    className={classes.inputRow}
                                    name="color"
                                    label={t('form.ResourceForm.color')}
                                    size="small"
                                    I={can}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <IconSelect name="icon" />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {submitRef ? (
                    <Portal container={submitRef.current}>
                        <SubmitButton I={can} outsideForm />
                    </Portal>
                ) : (
                    <Box className={classes.lowerButtons}>
                        <SubmitButton I={can} />
                    </Box>
                )}
            </Form>
        </Box>
    );
};

ResourceForm.propTypes = {
    resourceId: IdPropType,
    initialValues: PropTypes.shape({}),
    onDone: PropTypes.func,
    submitRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
    can: PropTypes.string,
};

ResourceForm.defaultProps = {
    resourceId: null,
    initialValues: null,
    onDone: null,
    submitRef: null,
    can: null,
};

export default ResourceForm;
