import { RESOURCE_TYPE_RESOURCE } from '../api/resources';

export const indexResourceTypes = (params, meta) => (dispatch, getState, api) =>
    api[RESOURCE_TYPE_RESOURCE].index(params, meta);
export const showResourceType = (params, meta) => (dispatch, getState, api) =>
    api[RESOURCE_TYPE_RESOURCE].show(params, meta);
export const storeResourceType = (params, meta) => (dispatch, getState, api) =>
    api[RESOURCE_TYPE_RESOURCE].store(params, meta);
export const updateResourceType = (params, meta) => (dispatch, getState, api) =>
    api[RESOURCE_TYPE_RESOURCE].update(params, meta);
export const destroyResourceType = (params, meta) => (dispatch, getState, api) =>
    api[RESOURCE_TYPE_RESOURCE].destroy(params, meta);
