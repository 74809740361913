import React, { useCallback, useContext } from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Box, Collapse, TableCell as MuiTableCell } from '@mui/material';
import classNames from 'classnames';
import MuiTableRow from '@mui/material/TableRow';
import isFunction from 'lodash/isFunction';
import TableCell from './TableCell';
import { IdPropType } from '../../modules/proptypes';
import TableContext from './TableContext';
import { TableActionPropType, TableExpandPropType } from './proptypes';
import TableInlineActions from './TableInlineActions';

const useStyles = makeStyles(theme => ({
    row: {
        cursor: 'pointer',
    },
    extraRow: {
        '&:not($expanded) td, &:not($expanded) td': {
            borderBottom: 'none',
        },
    },
    expanded: {
        backgroundColor: theme.palette.action.hover,
    },

    cellWithGrid: {
        verticalAlign: 'top',
    },

    actions: {
        /*
         * hacky but does the trick,
         * causes the actions cell to shrink according to the content
         */
        width: '0.1%',
        whiteSpace: 'nowrap',
    },
}));

const getValue = (key, relatedKey, data, related) => {
    const value = relatedKey ? related[relatedKey] : data;
    const keyParts = key.split('.');
    return keyParts.reduce((carry, keyPart) => {
        return carry && carry[keyPart];
    }, value);
};

const TableRow = ({
    data,
    related,
    columns,
    actions,
    expand,
    showActionsInline,
    onClick,
    expanded,
    'data-test-id': dataTestId,
}) => {
    const { TableRowDetails } = useContext(TableContext);
    const classes = useStyles();

    const handleClick = useCallback(
        event => {
            /* skip event if user just selected some text */
            const selection = window.getSelection();
            if (selection.toString().length) {
                return;
            }

            if (event.target && event.target.tagName.toLowerCase() === 'a') {
                return;
            }

            onClick(event, data.id);
        },
        [onClick, data.id]
    );

    return (
        <>
            <MuiTableRow
                id={`entry-${data.id}`}
                hover
                tabIndex={-1}
                className={classNames(classes.row, { [classes.expanded]: expanded })}
                onClick={handleClick}
                data-test-id={`${dataTestId}Row`}
            >
                {columns.map(column => (
                    <TableCell
                        key={column.key}
                        value={getValue(
                            column.accessor || column.key,
                            column.related,
                            data,
                            related
                        )}
                        data={column.component ? data : null}
                        related={related}
                        component={column.component}
                        as={column.as || column.accessor || column.key}
                        className={column.className}
                        align={column.align}
                        dataTestId={column.key}
                    />
                ))}
                {actions && actions.length > 0 && (
                    <MuiTableCell className={classes.actions}>
                        <TableInlineActions
                            actions={actions}
                            data={data}
                            showActionsInline={showActionsInline}
                            hidden={
                                !!(
                                    expand &&
                                    expanded &&
                                    actions &&
                                    actions.length > showActionsInline
                                )
                            }
                        />
                    </MuiTableCell>
                )}
            </MuiTableRow>
            {expand && (
                <MuiTableRow
                    className={classNames(classes.row, classes.extraRow, {
                        [classes.expanded]: expanded,
                    })}
                    onClick={handleClick}
                >
                    <MuiTableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={actions && actions.length ? columns.length + 1 : columns.length}
                        className={classes.cellWithGrid}
                    >
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <Box mt={2} mb={2}>
                                <TableRowDetails
                                    data={data}
                                    {...(isFunction(expand) ? { component: expand } : expand)}
                                    actions={
                                        actions && actions.length > showActionsInline
                                            ? actions
                                            : null
                                    }
                                />
                            </Box>
                        </Collapse>
                    </MuiTableCell>
                </MuiTableRow>
            )}
        </>
    );
};

TableRow.propTypes = {
    data: PropTypes.shape({
        id: IdPropType.isRequired,
    }).isRequired,
    related: PropTypes.shape({}),
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            accessor: PropTypes.string,
            dataTestId: PropTypes.string,
            className: PropTypes.string,
            as: PropTypes.string,
            component: PropTypes.func,
            align: PropTypes.string,
        })
    ).isRequired,
    actions: PropTypes.arrayOf(TableActionPropType),
    expand: TableExpandPropType,
    showActionsInline: PropTypes.number.isRequired,
    onClick: PropTypes.func,
    expanded: PropTypes.bool,
    'data-test-id': PropTypes.string,
};

TableRow.defaultProps = {
    related: null,
    actions: null,
    expand: null,
    onClick: null,
    expanded: false,
    'data-test-id': null,
};

export default TableRow;
