import React, { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles(theme => ({
    upload: {
        fontSize: 48,
        color: theme.palette.primary.main,
    },

    overlay: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: 'rgba(255,255,255,0.9)',
        padding: theme.spacing(1),
        opacity: 0,
        transition: theme.transitions.create(['opacity'], {
            duration: theme.transitions.duration.shortest,
        }),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 10,
        pointerEvents: 'none',
    },

    overlayInner: {
        position: 'absolute',
        left: theme.spacing(1),
        right: theme.spacing(1),
        top: theme.spacing(1),
        bottom: theme.spacing(1),
        opacity: 0,
        transition: theme.transitions.create(['opacity'], {
            duration: theme.transitions.duration.shorter,
        }),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 10,
    },

    overlayDrag: {
        borderWidth: 2,
        borderStyle: 'dashed',
        borderColor: theme.palette.grey[400],
        borderRadius: 15,
    },

    overlayActive: {
        opacity: 1,
    },
}));

const BaseDropzoneOverlay = forwardRef(
    ({ isDragActive, getInputProps, loading, ...other }, ref) => {
        const _classes = useStyles();

        return (
            <>
                <Box
                    className={classNames(_classes.overlay, {
                        [_classes.overlayActive]: isDragActive || loading,
                    })}
                    ref={ref}
                    {...other}
                >
                    <Box
                        className={classNames(_classes.overlayInner, _classes.overlayDrag, {
                            [_classes.overlayActive]: isDragActive,
                        })}
                    >
                        <CloudUploadOutlinedIcon className={_classes.upload} />
                    </Box>
                    <Box
                        className={classNames(_classes.overlayInner, {
                            [_classes.overlayActive]: loading,
                        })}
                    >
                        <CircularProgress />
                    </Box>
                </Box>
                {getInputProps ? <input {...getInputProps()} /> : null}
            </>
        );
    }
);

BaseDropzoneOverlay.propTypes = {
    isDragActive: PropTypes.bool.isRequired,
    getInputProps: PropTypes.func,
    loading: PropTypes.bool,
};

BaseDropzoneOverlay.defaultProps = {
    getInputProps: null,
    loading: false,
};

export default memo(BaseDropzoneOverlay);
