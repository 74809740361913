// import React from 'react';
// import { makeStyles } from '@mui/styles';
// import Box from '@mui/material/Box';
//
// const useStyles = makeStyles(theme => ({
//     root: {
//         backgroundColor: theme.palette.background.default,
//         padding: 30,
//         height: 150,
//         width: '100%',
//         textAlign: 'center',
//     },
// }));

const LocationMap = () => {
    // const classes = useStyles();
    // return <Box className={classes.root} />;
    return null;
};

LocationMap.propTypes = {};

export default LocationMap;
