import {
    CARD_CONTACTS_DETAILS_CONTEXT,
    CARD_DETAILS_CONTENT_CONTEXT,
    CARD_DETAILS_FOOTER_CONTEXT,
    CARD_DETAILS_HEADER_CONTEXT,
} from '../metadata/context';

export const CARD_CONTEXTS = [
    CARD_DETAILS_HEADER_CONTEXT,
    CARD_DETAILS_CONTENT_CONTEXT,
    CARD_DETAILS_FOOTER_CONTEXT,
    CARD_CONTACTS_DETAILS_CONTEXT,
];
