import { put, takeEvery } from 'redux-saga/effects';
import { PERSON_RESOURCE, USER_RESOURCE } from '../api/resources';
import { updateAuthUser } from '../auth/authSlice';
import {
    indexFulfilled as personIndexFulfilled,
    showFulfilled as personShowFulfilled,
} from '../persons/personsSlice';

function* nestedIndexFulfilled(action) {
    const payload = action.payload.reduce((carry, { person }) => {
        if (person) {
            carry.push(person);
        }
        return carry;
    }, []);

    yield put(personIndexFulfilled(payload, { key: PERSON_RESOURCE }));
}

function* nestedShowFulfilled(action) {
    const { person } = action.payload;

    if (person) {
        yield put(personShowFulfilled(person, { key: PERSON_RESOURCE }));
    }
}

function* refreshAuthUser(action) {
    const { kumo_connect } = action.payload;
    yield put(updateAuthUser(kumo_connect, { key: 'auth' }));
}

export default [
    takeEvery(`${USER_RESOURCE}/indexFulfilled`, nestedIndexFulfilled),
    takeEvery(`${USER_RESOURCE}/showFulfilled`, nestedShowFulfilled),

    takeEvery(`${USER_RESOURCE}/updateFulfilled`, refreshAuthUser),
];
