import { useCallback, useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

const SelectOverride = ({ requestSelectedOption, keys, emptyOptions }) => {
    const { values, setValues } = useFormikContext();
    const [lastSelectedId, setLastSelectedId] = useState(null);

    const setClassOrExistingValue = useCallback(
        (original, key, selectedOption) => {
            if (
                (original[key] !== '' && !emptyOptions.includes(original[key])) ||
                selectedOption[key] === undefined
            ) {
                return original;
            }
            return {
                ...original,
                [key]: selectedOption[key],
            };
        },
        [emptyOptions]
    );

    useEffect(() => {
        const selectedOption = requestSelectedOption(values);

        if (selectedOption && lastSelectedId !== selectedOption.id) {
            setLastSelectedId(selectedOption.id);
            let updated = values;
            keys.forEach(key => {
                updated = setClassOrExistingValue(updated, key, selectedOption);
            });
            setValues(updated);
        }
    }, [keys, values, setValues, lastSelectedId, setClassOrExistingValue, requestSelectedOption]);

    return null;
};

SelectOverride.propTypes = {
    requestSelectedOption: PropTypes.func.isRequired,
    keys: PropTypes.arrayOf(PropTypes.string).isRequired,
    emptyOptions: PropTypes.arrayOf(PropTypes.any),
};

SelectOverride.defaultProps = {
    emptyOptions: [],
};

export default SelectOverride;
