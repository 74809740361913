import PropTypes from 'prop-types';
import { useFieldFast } from '../../../modules/form/hooks';

/**
 * Changing components on form value change.
 * Either child-mode for hiding
 * Or propNode-mode for getting formikCondition as prop
 */

const OptionalFormikContainer = ({ name, hide, propNode, children }) => {
    const [field] = useFieldFast(name);

    const formikCondition = Array.isArray(hide) ? hide.includes(field.value) : hide === field.value;

    if (propNode) {
        return propNode(formikCondition, field);
    }

    if (formikCondition) {
        return null;
    }

    return children;
};

OptionalFormikContainer.propTypes = {
    name: PropTypes.string.isRequired,
    hide: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.number]),
    propNode: PropTypes.func,
    children: PropTypes.node,
};

OptionalFormikContainer.defaultProps = {
    hide: ['', null],
    propNode: null,
    children: null,
};

export default OptionalFormikContainer;
