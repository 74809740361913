import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Button, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import { useFieldFast } from '../../modules/form/hooks';
import AddressesInputRow from './AddressesInputRow';

const useStyles = makeStyles(theme => ({
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}));

export const getNewAddress = () => ({
    id: null, // needed by AddressInputRow to know what to render :'(
    address_type_id: '',
    address1: '',
    address2: '',
    zip: '',
    city: '',
    state: '',
    country: '',
});

const AddressesInput = ({ name, initialEdit }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const [{ value }, , { setValue }] = useFieldFast(name);
    const [editId, setEditId] = useState(initialEdit);

    const handleAdd = () => {
        setValue([...value, getNewAddress()]);
        setEditId(value.length);
    };

    const handleDelete = index => {
        const cutValues = [...value];
        cutValues.splice(index, 1);
        setValue(cutValues);
        setEditId(null);
    };

    return (
        <>
            {value &&
                value.map((address, index) => (
                    <React.Fragment key={address.id || `${name}.${index}`}>
                        <AddressesInputRow
                            name={`${name}.${index}`}
                            initialEdit={editId === index}
                            onDelete={() => handleDelete(index)}
                        />
                        <Divider className={classes.divider} />
                    </React.Fragment>
                ))}
            <Box>
                <Button
                    variant="text"
                    color="primary"
                    size="small"
                    p={0}
                    onClick={handleAdd}
                    startIcon={<AddOutlinedIcon />}
                >
                    {t('components.Address.addAddress')}
                </Button>
            </Box>
        </>
    );
};

AddressesInput.propTypes = {
    name: PropTypes.string.isRequired,
    initialEdit: PropTypes.number,
};

AddressesInput.defaultProps = {
    initialEdit: null,
};

export default AddressesInput;
