import React from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Avatar from '../../../icons/Avatar';
import { UserPropType } from '../../../../modules/users/proptypes';

const useStyles = makeStyles(() => ({
    mentionSuggestionsEntryContainer: {
        display: 'table',
        width: '100%',
    },
    mentionSuggestionsEntryText: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));

// https://www.draft-js-plugins.com/plugin/mention
const MentionEntry = ({ mention }) => {
    const classes = useStyles();

    return (
        <div className={classes.mentionSuggestionsEntryContainer}>
            <Grid container alignItems="center" spacing={1}>
                <Grid item>
                    <Avatar user={mention} disableHover />
                </Grid>
                <Grid item>
                    <Typography>{mention.display_name}</Typography>
                </Grid>
            </Grid>
        </div>
    );
};

MentionEntry.propTypes = {
    mention: UserPropType.isRequired,
};

MentionEntry.defaultProps = {};

export default MentionEntry;
