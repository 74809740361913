import React, { memo, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import { markdownTagOptions } from '../../modules/editor/tag';

const useStyles = makeStyles(theme => ({
    root: {
        ...theme.typography.body1,
        color: theme.palette.text.primary,
        wordBreak: 'break-word',
        marginTop: 0,
        marginBottom: 0,

        '& > p:first-child': {
            marginTop: 0,
        },
        '& > p:last-child': {
            marginBottom: 0,
        },
    },
    tag: {
        color: theme.palette.text.hint,
    },
}));

const markdownNewline = markdown => (markdown ? markdown.replace(/\r?\n/g, '    \n') : '');

const markdownOptimize = markdown => (markdown ? markdown.replace('>#', '>#:') : '');

export const convertHighlightsToMarkdown = highlights =>
    highlights ? highlights.replace(/(<em>|<\/em>)/g, '**') : '';

const Markdown = ({ content, className, style }) => {
    const classes = useStyles();

    const modifiers = useMemo(
        () => ({
            html: [...markdownTagOptions.html],
            text: [...markdownTagOptions.text],
        }),
        []
    );

    const renderers = {
        html: ({ value }) => {
            const modifier = modifiers.html.find(mod => mod.check(value));
            if (modifier) {
                return modifier.replace(value);
            }

            return null;
        },

        text: ({ value }) => {
            const modifier = modifiers.text.find(mod => mod.check(value));
            if (modifier) {
                return modifier.replace(value);
            }
            return value;
        },
    };

    return (
        <Box data-test-id="taskDescription">
            <ReactMarkdown
                data-test-id="ProtocolItemText"
                renderers={renderers}
                source={markdownNewline(markdownOptimize(content))}
                skipHtml
                parserOptions={{ commonmark: true }}
                className={classNames(classes.root, className)}
                style={style}
            />
        </Box>
    );
};

Markdown.propTypes = {
    content: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.shape({}),
};

Markdown.defaultProps = {
    content: '',
    className: null,
    style: null,
};

export default memo(Markdown);
