import React from 'react';
import * as PropTypes from 'prop-types';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useTranslation } from 'react-i18next';
import Button from '../form/base/Button';
import { UPDATE } from '../../modules/abilities/actions';
import { SubjectPropType } from '../../modules/abilities/proptypes';

const EditButton = ({ onClick, disabled, subject }) => {
    const { t } = useTranslation();

    return (
        <Button
            onClick={onClick}
            startIcon={<EditOutlinedIcon />}
            disabled={disabled}
            action={UPDATE}
            subject={subject}
            data-test-id="EditButton"
        >
            {t('components.ResourceTableRowDetails.edit')}
        </Button>
    );
};

EditButton.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    subject: SubjectPropType,
};

EditButton.defaultProps = {
    onClick: null,
    disabled: false,
    subject: null,
};

export default EditButton;
