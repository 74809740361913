import React from 'react';
import { useSelector } from 'react-redux';
import { selectAppointmentTypeById } from '../../../modules/appointmentTypes/appointmentTypeSlice';
import { useField } from 'formik';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Can from '../../../modules/abilities/Can';
import { READ } from '../../../modules/abilities/actions';
import { APPOINTMENT_RESOURCE } from '../../../modules/api/resources';
import ResourceGroupConfig from '../../resourceGroup/ResourceGroupConfig';

const CalendarDetailResources = ({}) => {
    const { t } = useTranslation();
    const [{ value: appointmentTypeId }] = useField('appointmentTypeId');
    const appointmentType = useSelector(state =>
        selectAppointmentTypeById(state, appointmentTypeId)
    );

    return appointmentType ? (
        <Can I={READ} this={APPOINTMENT_RESOURCE} field="resources">
            <Typography variant="h2" color="primary" mt={4} mb={2}>
                {t('components.AppointmentForm.resources')}
            </Typography>
            <ResourceGroupConfig
                resourceConfig={appointmentType.resource_config}
                name="grouped"
                originalName="resources"
            />
        </Can>
    ) : null;
};

CalendarDetailResources.propTypes = {};

CalendarDetailResources.defaultProps = {};

export default CalendarDetailResources;
