import { createResourceSlice } from '../redux/resource/createResourceSlice';
import { APPOINTMENT_RESOURCE } from '../api/resources';

const appointmentSlice = createResourceSlice({
    resource: APPOINTMENT_RESOURCE,
    byKey: 'entityId', // TODO: polymorph relation
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,
    destroyByReferenceIdFulfilled,

    indexAppointments,
    showAppointment,
    storeAppointment,
    updateAppointment,
    archiveAppointment,
    restoreAppointment,
    destroyAppointment,

    searchAppointments,
    autocompleteAppointments,
    suggestAppointments,
} = appointmentSlice.actions;

export const {
    selectAppointmentById,
    selectAppointmentsById,
    makeAppointmentsByIdsSelector,
    selectAllAppointmentIds,
    selectAllAppointments,
    selectAppointmentsLoading,
    selectAppointmentsInitialized,
    selectAppointmentLoading,
    selectAppointmentInitialized,
    selectAppointmentIdsByEntityId,
    makeAppointmentsByEntityIdSelector,
    selectAppointmentLoadingByEntityId,
    selectAppointmentInitializedByEntityId,
} = appointmentSlice.selectors;
export const { useAppointments, useAppointment, useAppointmentsByEntityId } =
    appointmentSlice.hooks;
export default appointmentSlice.reducer;

/* extra actions */
export const fetchAppointmentsAvailability = params => (dispatch, getState, api) =>
    api[APPOINTMENT_RESOURCE].fetchAvailability(params);
