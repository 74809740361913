import * as Yup from 'yup';
import { personSchema } from '../persons/schema';
import { addressSchema } from '../addresses/schema';
import { contactDataSchema } from '../contactData/schema';
import { companySchema } from '../companies/schema';
import { YupId } from '../form/schema';

export const contactSchema = Yup.object().shape({
    department: Yup.string(),
    person_function_id: Yup.string(),
    id_custom: Yup.string(),
    parent_id: YupId(),
});

export const personContactSchema = Yup.object().shape({
    contact: contactSchema.required('required'),
    person: personSchema.required('required'),
    addresses: Yup.array().of(addressSchema),
    contactData: Yup.array().of(contactDataSchema),
    useContactable: Yup.boolean(),
    contactTypeIds: Yup.array(YupId()).when('useContactable', {
        is: true,
        then: Yup.array(YupId()).required('required'),
    }),
});

export const companyContactSchema = Yup.object().shape({
    contact: contactSchema.required('required'),
    company: companySchema.required('required'),
    addresses: Yup.array().of(addressSchema),
    contactData: Yup.array().of(contactDataSchema),
    useContactable: Yup.boolean(),
    contactTypeIds: Yup.array(YupId()).when('useContactable', {
        is: true,
        then: Yup.array(YupId()).required('required'),
    }),
});
