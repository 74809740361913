import React, { useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Menu, MenuItem } from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ClickBoundary from '../form/ClickBoundary';
import EditIconButton from '../buttons/EditIconButton';
import OpenIconButton from '../buttons/OpenIconButton';
import SubmitIconButton from '../form/formik/SubmitIconButton';
import IconButton from '../form/base/IconButton';
import { UPDATE } from '../../modules/abilities/actions';
import MenuDestroyIntent from '../menu/MenuDestroyIntent';
import DeleteIconButton from '../buttons/DeleteIconButton';

const ResourceTableRowActions = ({
    row,
    table,
    tableParams,
    resource,
    onOpen,
    onEdit,
    onDelete,
    onCancel,
    renderRowMenuItems,
}) => {
    const { t } = useTranslation();
    const [menuAnchor, setMenuAnchor] = useState(null);
    const menuOpen = useMemo(() => Boolean(menuAnchor), [menuAnchor]);
    const hasMenu = useMemo(
        () => Boolean(onDelete || renderRowMenuItems),
        [onDelete, renderRowMenuItems]
    );

    const { size, isEditing } = useMemo(() => {
        const { density, editingRow } = table.getState();

        return {
            size: density === 'compact' ? 'small' : 'medium',
            isEditing: editingRow?.id === row?.id,
        };
    }, [table, row]);

    const subject = useMemo(() => row?.original || resource, [row, resource]);

    const rowMenuItems = useMemo(
        () => (renderRowMenuItems ? renderRowMenuItems(row, table, subject) : null),
        [renderRowMenuItems, row, table, subject]
    );

    const toggleMenu = event => {
        setMenuAnchor(currentEl => (currentEl ? null : event?.target));
    };

    const closeMenu = () => {
        setMenuAnchor(null);
    };

    const handleCancel = () => {
        table.setEditingRow(null);

        if (onCancel) {
            onCancel();
        }
    };

    const handleDelete = id => {
        return onDelete(id)
            .then(() => {
                closeMenu();
            })
            .catch(() => {
                closeMenu();
            });
    };

    if (!row?.original?.id) {
        return null;
    }

    if (isEditing) {
        return (
            <ClickBoundary>
                <Grid container spacing={2} justifyContent="end" wrap="nowrap">
                    <Grid item>
                        <IconButton label={t('abort')} size={size} onClick={handleCancel}>
                            <BlockIcon fontSize={size} />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <SubmitIconButton I={UPDATE} size={size} />
                    </Grid>
                </Grid>
            </ClickBoundary>
        );
    }

    return (
        <ClickBoundary>
            <Grid container spacing={2} justifyContent="end" wrap="nowrap">
                {onEdit ? (
                    <Grid item>
                        <EditIconButton
                            onClick={event => onEdit(row, table, event)}
                            size={size}
                            subject={subject}
                        />
                    </Grid>
                ) : null}
                {onOpen ? (
                    <Grid item>
                        <OpenIconButton
                            onClick={event => onOpen(row.original.id, event)}
                            size={size}
                            subject={subject}
                        />
                    </Grid>
                ) : null}
                {hasMenu ? (
                    <Grid item>
                        <IconButton size={size} onClick={toggleMenu}>
                            <MoreVertIcon fontSize={size} />
                        </IconButton>
                    </Grid>
                ) : null}
            </Grid>
            <Menu
                id={`ResourceTableRowMenu.${row?.id}`}
                open={menuOpen}
                anchorEl={menuAnchor}
                PaperProps={{ style: { width: 220 } }}
                onClose={closeMenu}
            >
                {rowMenuItems}
                {onDelete ? (
                    <MenuDestroyIntent
                        onDelete={() => handleDelete(row.original.id)}
                        subject={subject}
                    />
                ) : null}
            </Menu>
        </ClickBoundary>
    );
};

ResourceTableRowActions.propTypes = {
    resource: PropTypes.string.isRequired,
    row: PropTypes.shape({}).isRequired,
    table: PropTypes.shape({}).isRequired,
    tableParams: PropTypes.shape({}),
    onOpen: PropTypes.func,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    onCancel: PropTypes.func,
    renderRowMenuItems: PropTypes.func,
};

ResourceTableRowActions.defaultProps = {
    tableParams: null,
    onOpen: null,
    onEdit: null,
    onDelete: null,
    onCancel: null,
    renderRowMenuItems: null,
};

export default ResourceTableRowActions;
