import React, { useMemo } from 'react';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import IncomingMailIcon from '../email/IncomingMailIcon';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutline';
import * as PropTypes from 'prop-types';

const types = {
    inbound: IncomingMailIcon,
    outbound: ForwardToInboxIcon,
    missing: ErrorOutlinedIcon,
};

const getIcon = (mail, missing) => {
    if (missing) {
        return types.missing;
    }

    const { inbound, is_inbound: isInbound } = mail || {};

    if (inbound || isInbound) {
        return types.inbound;
    }

    return types.outbound;
};

const FileMailIcon = ({ mail, missing, ...other }) => {
    const Icon = useMemo(() => getIcon(mail, missing), [mail, missing]);

    return <Icon {...other} />;
};

FileMailIcon.propTypes = {
    mail: PropTypes.shape({}),
    missing: PropTypes.bool,
};

FileMailIcon.defaultProps = {
    mail: null,
    missing: false,
};

export default FileMailIcon;
