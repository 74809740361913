import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import * as propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import Form from '../form/formik/Form';
import { taskSchema } from '../../modules/tasks/schema';
import { TaskPropType } from '../../modules/tasks/propTypes';
import TextInput from '../form/formik/TextInput';
import CategorySelect from '../form/CategorySelect';
import SubmitButton from '../form/formik/SubmitButton';
import TaskDurationSelect from '../form/TaskDurationSelect';
import DateInput from '../form/formik/DateInput';
import Editor from '../form/formik/Editor/Editor';
import TaskTypeSelect from '../form/formik/TaskTypeSelect';
import ResponsiblesSelect from '../form/ResponsiblesSelect';
import Button from '../form/base/Button';
import DestroyIntent from '../form/DestroyIntent';
import { DELETE, UPDATE } from '../../modules/abilities/actions';
import TimeInput from '../form/formik/TimeInput';
import { convertUTCtoLocalDate, splitDateTime } from '../../modules/datetime/utils';
import LockButton from '../form/base/LockButton';
import OptionalFormikContainer from '../form/formik/OptionalFormikContainer';
import { TASK_RESOURCE } from '../../modules/api/resources';
import Can from '../../modules/abilities/Can';
import PrioritySelect from '../priorities/PrioritySelect';
import { useInitialValues } from '../../modules/form/hooks';
import TaskStatusSelect from '../taskStatus/TaskStatusSelect';
import { INDIVIDUAL } from '../../modules/tasks/utils';
import NumberInput from '../form/formik/NumberInput';

const useStyles = makeStyles({
    iconButtonInRow: {
        marginTop: 2,
    },
});

const EditTaskForm = ({ task, can, onSubmit, onAbort, onDelete }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [deleteConfirming, setDeleteConfirming] = useState(false);
    const { due_at: dueAt, has_due_time: hasDueTime } = task;
    const splitedLocalDateTime = dueAt && splitDateTime(convertUTCtoLocalDate(dueAt));
    const localDateTime = dueAt && convertUTCtoLocalDate(dueAt);

    const finalInitialValues = useInitialValues(
        {
            ...task,
            due_at: splitedLocalDateTime && splitedLocalDateTime.date,
            due_time: localDateTime && hasDueTime ? localDateTime : null,
            task_type_id: task.task_type_text ? INDIVIDUAL : task.task_type_id,
        },
        {
            id: null,
            name: '',
            order: null,
            description: '',
            task_type_text: '',
            duration_value: '',
            due_at: null,
            due_time: null,
            completed_at: null,
            card_id: '',
            category_id: '',
            task_type_id: '',
            priority_id: '',
            task_status_id: '',
            task_duration_id: 1,
            responsibles: [],
            responsibles_main: [],
        }
    );

    return (
        <Form
            initialValues={finalInitialValues}
            validationSchema={taskSchema}
            onSubmit={onSubmit}
            subject={task}
        >
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <TextInput
                        name="name"
                        label={t('form.CreateTask.name')}
                        size="small"
                        fullWidth
                        can={can}
                    />
                </Grid>
                <Grid item xs={4}>
                    <NumberInput name="order" label={t('form.CreateTask.order')} fullWidth />
                </Grid>
                <Grid item xs={8}>
                    <CategorySelect
                        name="category_id"
                        label={t('form.CreateTask.category')}
                        fullWidth
                        I={can}
                    />
                </Grid>
                <Grid item xs={4}>
                    <ResponsiblesSelect
                        can={can}
                        name="responsibles"
                        mainName="responsibles_main"
                        max={5}
                    />
                </Grid>
                <Grid item xs={8}>
                    <TaskTypeSelect
                        name="task_type_id"
                        individual="task_type_text"
                        label={t('form.CreateTask.taskType')}
                        fullWidth
                        I={can}
                    />
                </Grid>
                <Grid item xs={8}>
                    <PrioritySelect
                        name="priority_id"
                        label={t('components.TaskInfos.priority')}
                        fullWidth
                        I={can}
                    />
                </Grid>
                <Grid item xs={8}>
                    <TaskStatusSelect
                        name="task_status_id"
                        label={t('components.TaskInfos.status')}
                        fullWidth
                        I={can}
                    />
                </Grid>
                <Grid item xs={4} />
                <Grid item xs={4}>
                    <TextInput
                        name="duration_value"
                        data-test-id="task-durationValue-input"
                        label={t('form.CreateTask.duration')}
                        fullWidth
                        type="number"
                        size="small"
                        can={can}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TaskDurationSelect can={can} name="task_duration_id" fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <Editor I={can} name="description" />
                </Grid>
                <Grid item xs={3}>
                    <DateInput
                        can={can}
                        name="due_at"
                        label={t('form.CreateTask.dueAt')}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2.5}>
                    <OptionalFormikContainer
                        name="due_at"
                        propNode={formikCondition => (
                            <TimeInput
                                name="due_time"
                                label={t('form.CreateTask.dueTime')}
                                disabled={formikCondition}
                                fullWidth
                                can={can}
                            />
                        )}
                    />
                </Grid>
                <Grid item className={classes.iconButtonInRow}>
                    <OptionalFormikContainer
                        name="due_at"
                        propNode={formikCondition => (
                            <LockButton can={can} name="due_locked" disabled={formikCondition} />
                        )}
                    />
                </Grid>
                <Grid item xs className={classes.iconButtonInRow}>
                    <Grid container spacing={1} justifyContent="flex-end">
                        <Grid item>
                            <DestroyIntent
                                onConfirmed={() => onDelete(task)}
                                compact
                                onToggle={confirming => setDeleteConfirming(confirming)}
                                labelConfirm={t('form.EditTask.delete')}
                                subject={task || TASK_RESOURCE}
                                action={DELETE}
                            />
                        </Grid>
                        {!deleteConfirming && (
                            <>
                                <Grid item>
                                    <Button onClick={onAbort} variant="outlined" size="medium">
                                        {t('abort')}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Can I={UPDATE} this={task}>
                                        <SubmitButton size="medium" />
                                    </Can>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Form>
    );
};

EditTaskForm.propTypes = {
    task: TaskPropType.isRequired,
    can: propTypes.string,
    onSubmit: propTypes.func.isRequired,
    onAbort: propTypes.func.isRequired,
    onDelete: propTypes.func.isRequired,
};

EditTaskForm.defaultProps = {
    can: null,
};

export default EditTaskForm;
