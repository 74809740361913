import { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { useFieldFast } from '../../../modules/form/hooks';

const PreSelect = ({ watchTarget, filteredTarget, options, onChange }) => {
    const { t } = useTranslation();
    const [{ value: watchValue }] = useField(watchTarget);
    const [{ value: filteredValue }, , { setValue: setFilteredValue }] = useFieldFast(
        filteredTarget
    );

    useEffect(() => {
        let filtered;

        if (watchValue) {
            filtered = options.filter(option => option[watchTarget] === watchValue);
        } else {
            filtered = options;
        }

        filtered.unshift({ name: t('form.Select.empty'), id: '' });

        onChange(filtered);

        if (
            !filtered.some(
                option => option.id === filteredValue && option[watchTarget] === watchValue
            )
        ) {
            setFilteredValue('');
        }
    }, [watchValue, watchTarget, filteredValue, options, setFilteredValue, onChange, t]);

    return null;
};

PreSelect.propTypes = {
    watchTarget: PropTypes.string.isRequired,
    filteredTarget: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onChange: PropTypes.func.isRequired,
};

export default PreSelect;
