import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import { IdPropType } from '../../modules/proptypes';
import { selectContactById } from '../../modules/contacts/selectors';
import { selectContactDataIdsWhereContactId } from '../../modules/contactData/selectors';
import ContactData from '../contactData/ContactData';
import { selectAddressIdsWhereContactId } from '../../modules/addresses/selectors';
import AddressDisplay from '../address/AddressDisplay';
import { showContact } from '../../modules/contacts/actions';
import { selectPersonById } from '../../modules/persons/selectors';
import { showPerson } from '../../modules/persons/actions';
import PersonName from './PersonName';
import GridDisplay from '../layout/GridDisplay';
import PersonFunction from './PersonFunction';
import Date from '../utils/Date';

const useStyles = makeStyles(theme => ({
    title: {
        marginBottom: theme.spacing(2),
    },

    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },

    subtitle: {
        paddingBottom: theme.spacing(2),
        marginTop: theme.spacing(6),
    },
}));

const PersonPreview = ({ contactId }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const contact = useSelector(state => selectContactById(state, contactId));
    const contactDataIds =
        useSelector(state => selectContactDataIdsWhereContactId(state, contactId)) || [];
    const addressIds = useSelector(state => selectAddressIdsWhereContactId(state, contactId)) || [];
    const person = useSelector(state => selectPersonById(state, contact && contact.personId));
    const dispatch = useDispatch();

    useEffect(() => {
        if (contactId && !contact) {
            dispatch(showContact({ id: contactId }));
        }
    }, [contactId, contact, dispatch]);

    useEffect(() => {
        if (contactId && contact && contact.personId && !person) {
            dispatch(showPerson({ id: contact.personId }));
        }
    }, [contactId, contact, person, dispatch]);

    return contact ? (
        <>
            {person && (
                <Typography variant="h6" className={classes.title}>
                    <PersonName personId={contact.personId} />
                </Typography>
            )}

            <GridDisplay columns="auto 1fr" spacing={1}>
                {contact.person_function_id && (
                    <>
                        <Typography variant="body2" color="textSecondary" className={classes.label}>
                            {t('components.ContactBaseData.function')}
                        </Typography>
                        <Typography variant="body2" className={classes.text}>
                            <PersonFunction personFunctionId={contact.person_function_id} />
                        </Typography>
                    </>
                )}
                {contact.department && (
                    <>
                        <Typography variant="body2" color="textSecondary" className={classes.label}>
                            {t('components.ContactBaseData.department')}
                        </Typography>
                        <Typography variant="body2" className={classes.text}>
                            {contact.department}
                        </Typography>
                    </>
                )}
                {contact.id_custom && (
                    <>
                        <Typography variant="body2" color="textSecondary" className={classes.label}>
                            {t('components.ContactBaseData.customId')}
                        </Typography>
                        <Typography variant="body2" className={classes.text}>
                            {contact.id_custom}
                        </Typography>
                    </>
                )}
                {person && person.birthday && (
                    <>
                        <Typography variant="body2" color="textSecondary" className={classes.label}>
                            {t('components.Person.birthday')}
                        </Typography>
                        <Typography variant="body2" className={classes.text}>
                            <Date date={person.birthday} onlyDate />
                        </Typography>
                    </>
                )}
            </GridDisplay>

            <Typography variant="h3" color="primary" className={classes.subtitle}>
                {t('components.ContactBaseData.contactOptions')}
            </Typography>

            <Divider className={classes.divider} />

            {contactDataIds.map(contactDataId => (
                <React.Fragment key={contactDataId}>
                    <ContactData key={contactDataIds} contactDataId={contactDataId} inline />
                    <Divider className={classes.divider} />
                </React.Fragment>
            ))}

            <Typography variant="h3" color="primary" className={classes.subtitle}>
                {t('components.ContactBaseData.addresses')}
            </Typography>

            <Divider className={classes.divider} />

            <Grid container spacing={2}>
                {addressIds.map(addressId => (
                    <Grid item xs={6} key={addressId}>
                        <AddressDisplay addressId={addressId} />
                        <Divider className={classes.divider} />
                    </Grid>
                ))}
            </Grid>
        </>
    ) : null;
};

PersonPreview.propTypes = {
    contactId: IdPropType.isRequired,
};

PersonPreview.defaultProps = {};

export default PersonPreview;
