/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { ADDRESS_TYPE_RESOURCE } from '../api/resources';

const insertAddressType = (state, addressType) => {
    const stale = state.byId[addressType.id];

    if (!stale) {
        state.allIds.push(addressType.id);
    }

    state.byId[addressType.id] = {
        ...addressType,
    };
};

const addressTypeSlice = createSlice({
    name: ADDRESS_TYPE_RESOURCE,
    initialState: {
        byId: {},
        allIds: [],
        loading: false,
        initialized: false,
    },
    reducers: {
        indexPending: state => {
            state.loading = true;
        },
        indexFulfilled: (state, action) => {
            action.payload.forEach(type => insertAddressType(state, type));
            state.loading = false;
            state.initialized = true;
        },
    },
});

export const {
    indexPending,
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,
} = addressTypeSlice.actions;

export default addressTypeSlice.reducer;
