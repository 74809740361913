import { TASK_DURATION_RESOURCE } from '../api/resources';

export const selectTaskDurationsById = state => state[TASK_DURATION_RESOURCE].byId;

export const selectTaskDurationById = (state, id) => selectTaskDurationsById(state)[id];

export const selectAllTaskDurationIds = state => state[TASK_DURATION_RESOURCE].allIds;

export const selectAllTaskDurations = state => Object.values(selectTaskDurationsById(state));

export const selectTaskDurationsIndexing = state => state[TASK_DURATION_RESOURCE].indexing;

export const selectTaskDurationsIndexingOrInitialized = state =>
    selectTaskDurationsIndexing(state) || selectAllTaskDurationIds(state).length > 0;
