import React, { useEffect } from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { KanbanProvider } from 'react-kanban';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import './assets/icons/icons.css';

import configureStore from './modules/redux/configureStore';
import Routes from './routes/Routes';
import configureApi from './modules/api/configureApi';
import configureKanban from './modules/kanban/configureKanban';
import ApiProvider from './modules/api/ApiProvider';
import SnackbarProvider from './components/snackbar/SnackbarProvider';
import { login } from './modules/auth/actions';
import InitialDataLoader from './components/settings/InitialDataLoader';
import ThemeProvider from './components/settings/ThemeProvider';
import AbilityProvider from './modules/abilities/AbilityProvider';
import ApiErrorHandler from './components/api/ApiErrorHandler';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { StyledEngineProvider } from '@mui/material/styles';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import SessionTimeout from './components/sessionTimeout/SessionTimeout';
import CacheBuster from './CacheBuster';

const cache = createCache({
    key: 'css',
    prepend: true,
});

const App = () => {
    const api = configureApi();
    const [kanban, kanbanComponents] = configureKanban(api);
    const store = configureStore(api, undefined);
    api.setStore(store);
    kanban.setStore(store);

    useEffect(() => {
        store.dispatch(login(null)).then(() =>
            setTimeout(() => {
                document.getElementById('loader').style.display = 'none';
            }, 1000)
        );
    });

    return (
        <CacheBuster>
            <StoreProvider store={store}>
                <ApiProvider api={api}>
                    <DndProvider backend={HTML5Backend}>
                        <KanbanProvider kanban={kanban} components={kanbanComponents}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <InitialDataLoader>
                                    <AbilityProvider>
                                        <StyledEngineProvider injectFirst>
                                            <CacheProvider value={cache}>
                                                <ThemeProvider>
                                                    <SnackbarProvider>
                                                        <ApiErrorHandler />
                                                        <Routes>
                                                            <SessionTimeout />
                                                        </Routes>
                                                    </SnackbarProvider>
                                                </ThemeProvider>
                                            </CacheProvider>
                                        </StyledEngineProvider>
                                    </AbilityProvider>
                                </InitialDataLoader>
                            </LocalizationProvider>
                        </KanbanProvider>
                    </DndProvider>
                </ApiProvider>
            </StoreProvider>
        </CacheBuster>
    );
};

export default App;
