import { createSelector } from '@reduxjs/toolkit';
import { CARD_RESOURCE } from '../api/resources';

const selectCardListsByCardStatusId = state => state[CARD_RESOURCE].byCardStatusId;

export const selectCardListByCardStatusId = (state, statusId) =>
    state[CARD_RESOURCE].byCardStatusId[statusId] || {};

export const makeCardsByCardStatusIdSelector = (_, statusId) =>
    createSelector([selectCardListsByCardStatusId], lists => {
        const list = lists[statusId];
        return (list && list.data) || [];
    });

export const selectCardsById = state => state[CARD_RESOURCE].byId;

export const selectCardById = (state, id) => selectCardsById(state)[id];

export const selectCardSearch = state => state[CARD_RESOURCE].filter;
