import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectUserIsRoot } from '../auth/selectors';
import { CASLCan } from './AbilityProvider';
import { usePermissionSubject } from './PermissionSubjectProvider';
import { resolveAction, resolveSubject } from './actions';

const ContextualCan = ({ children, I, field }) => {
    const isRoot = useSelector(selectUserIsRoot);
    const subject = usePermissionSubject();

    if (isRoot || !subject) {
        return children;
    }

    const [resolvedSubject, resolvedField] = resolveSubject(subject, field);
    const action = resolveAction(I, subject);

    return (
        <CASLCan do={action} on={resolvedSubject} field={resolvedField}>
            {children}
        </CASLCan>
    );
};

ContextualCan.propTypes = {
    children: PropTypes.node,
    I: PropTypes.string.isRequired,
    field: PropTypes.string,
};

ContextualCan.defaultProps = {
    children: null,
    field: null,
};

export default ContextualCan;
