/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { CONTACT_DATA_RESOURCE } from '../api/resources';

const insertContactData = (state, contactData) => {
    const stale = state.byId[contactData.id];

    if (!stale) {
        state.allIds.push(contactData.id);
    }

    state.byId[contactData.id] = {
        ...contactData,
    };

    if (!state.byContactId[contactData.contact_id]) {
        state.byContactId[contactData.contact_id] = [];
    }

    if (!state.byContactId[contactData.contact_id].includes(contactData.id)) {
        state.byContactId[contactData.contact_id].push(contactData.id);
    }
};

const contactDataSlice = createSlice({
    name: CONTACT_DATA_RESOURCE,
    initialState: {
        byId: {},
        byContactId: {},
        allIds: [],
    },
    reducers: {
        indexFulfilled: {
            prepare: (payload, meta) => ({ payload, meta }),
            reducer: (state, action) => {
                action.payload.forEach(contactData => {
                    insertContactData(state, contactData);
                });
            },
        },
        showFulfilled: {
            prepare: (payload, meta) => ({ payload, meta }),
            reducer: (state, action) => {
                insertContactData(state, action.payload);
            },
        },
        storeFulfilled: (state, action) => {
            insertContactData(state, action.payload);
        },
        updateFulfilled: (state, action) => {
            insertContactData(state, action.payload);
        },
        destroyFulfilled: (state, action) => {
            const id = action.payload;

            const index = state.allIds.findIndex(_id => _id === id);
            if (index >= 0) {
                delete state.byId[id];
                state.allIds.splice(index, 1);
            }

            Object.keys(state.byContactId).forEach(key => {
                const contactIndex = state.byContactId[key].findIndex(_id => _id === id);

                if (contactIndex >= 0) {
                    state.byContactId[key].splice(contactIndex, 1);
                }
            });
        },
    },
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,
} = contactDataSlice.actions;

export default contactDataSlice.reducer;
