import React, { useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Form from '../form/formik/Form';
import { IdPropType, RefPropType } from '../../modules/proptypes';
import LoadingBox from '../loading/LoadingBox';
import { useTranslation } from 'react-i18next';
import SubmitButton from '../form/formik/SubmitButton';
import FormPopup from '../popups/FormPopup';
import { SubjectPropType } from '../../modules/abilities/proptypes';
import { PRIORITY_RESOURCE } from '../../modules/api/resources';
import {
    destroyPriority,
    selectPriorityById,
    storePriority,
    updatePriority,
} from '../../modules/priority/prioritySlice';
import { prioritySettingsSchema } from '../../modules/priority/schema';
import PriorityFormBody from './PriorityFormBody';

const PriorityForm = ({
    priorityId,
    asPopup,
    openPopupWith,
    popupStateRef,
    onLoading,
    initialValues: initialValuesProps,
    onSubmit,
    subject,
    validationSchema,
    actionsComponent,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const priority = useSelector(state => selectPriorityById(state, priorityId));

    const initialValues = useMemo(
        () =>
            priority ||
            initialValuesProps || {
                name: '',
                icon: null,
                color: null,
                order: null,
            },
        [priority, initialValuesProps]
    );

    const handleSubmit = newValue => {
        setLoading(true);
        if (onLoading) onLoading(true);
        return dispatch(priorityId ? updatePriority(newValue) : storePriority(newValue)).finally(
            () => {
                setLoading(false);
                if (onLoading) onLoading(false);
            }
        );
    };

    const handleDelete = () => dispatch(destroyPriority({ id: priorityId }));

    return asPopup ? (
        <FormPopup
            popupId="priorityPopupForm"
            popupStateRef={popupStateRef}
            initialValues={initialValues}
            validationSchema={validationSchema || prioritySettingsSchema}
            onSubmit={onSubmit || handleSubmit}
            onDelete={priorityId ? handleDelete : null}
            submitLabel={t('form.SubmitButton.defaultLabel')}
            abortLabel={t('abort')}
            enableReinitialize
            FormInputs={
                <Box
                    onKeyDown={e => {
                        if (e.code !== 'Escape') {
                            e.stopPropagation();
                        }
                    }}
                >
                    <PriorityFormBody autoFocus />
                </Box>
            }
        >
            <Grid container>
                <Grid item>
                    <LoadingBox loading={loading} variant="circular" size="small">
                        {openPopupWith}
                    </LoadingBox>
                </Grid>
            </Grid>
        </FormPopup>
    ) : (
        <Form
            initialValues={initialValues}
            subject={subject}
            validationSchema={validationSchema || prioritySettingsSchema}
            onSubmit={onSubmit || handleSubmit}
        >
            <PriorityFormBody />
            <Grid container spacing={2} justifyContent="flex-end">
                {actionsComponent ? (
                    actionsComponent
                ) : (
                    <SubmitButton>{t('form.SubmitButton.defaultLabel')}</SubmitButton>
                )}
            </Grid>
        </Form>
    );
};

PriorityForm.propTypes = {
    priorityId: IdPropType,
    asPopup: PropTypes.bool,
    openPopupWith: PropTypes.node,
    popupStateRef: RefPropType,
    onLoading: PropTypes.func,
    initialValues: PropTypes.shape({}),
    onSubmit: PropTypes.func,
    subject: SubjectPropType,
    validationSchema: PropTypes.shape({}),
    actionsComponent: PropTypes.node,
};

PriorityForm.defaultProps = {
    priorityId: null,
    asPopup: false,
    openPopupWith: null,
    popupStateRef: null,
    onLoading: null,
    initialValues: null,
    onSubmit: null,
    subject: PRIORITY_RESOURCE,
    validationSchema: null,
    actionsComponent: null,
};

export default PriorityForm;
