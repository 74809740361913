import React from 'react';
import * as PropTypes from 'prop-types';
import { useField } from 'formik';
import { default as CompletedButtonBase } from '../../buttons/CompletedButton';
import { formatISO } from 'date-fns';
import { useContextualCan } from '../../../modules/abilities/hooks';
import { UPDATE } from '../../../modules/abilities/actions';

const CompletedButton = ({ name, originalName, subject, disabled, ...other }) => {
    const [{ value }, , { setValue }] = useField(name);
    const canDo = useContextualCan(UPDATE, originalName || name);

    const handleComplete = () => {
        setValue(formatISO(new Date()));
    };

    const handleUndo = () => {
        setValue(null);
    };

    return (
        <CompletedButtonBase
            isCompleted={!!value}
            onCompleted={handleComplete}
            onUndo={handleUndo}
            name={originalName || name}
            subject={subject}
            disabled={!canDo || disabled}
            {...other}
        />
    );
};

CompletedButton.propTypes = {
    name: PropTypes.string.isRequired,
    originalName: PropTypes.string,
    subject: PropTypes.string,
    disabled: PropTypes.bool,
};

CompletedButton.defaultProps = {
    originalName: null,
    subject: null,
    disabled: false,
};

export default CompletedButton;
