import removeMd from 'remove-markdown';

export const markdownToPlainText = (markdown, limit = null) => {
    const singleLine = markdown.replace(/(\r\n|\n|\r)/gm, ' ');
    const noHtml = singleLine.replace(/<\/?[^>]+(>|$)/g, '');
    const noMd = removeMd(noHtml);
    const limitForDots = limit && limit - 2;

    return limitForDots && noHtml.length > limitForDots
        ? noMd.slice(0, limitForDots).trim().concat('…')
        : noMd;
};
