import React from 'react';
import * as PropTypes from 'prop-types';
import { Grid } from '@mui/material';

const Color = ({ value }) =>
    value ? (
        <Grid container alignItems="center">
            <div
                style={{
                    display: 'inline-block',
                    border: 'solid 1px black',
                    height: 12,
                    width: 12,
                    marginRight: 4,
                    backgroundColor: value,
                }}
            >
                {' '}
            </div>
            {value}
        </Grid>
    ) : null;

Color.propTypes = {
    value: PropTypes.string.isRequired,
};

export default Color;
