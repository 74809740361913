import mime from 'mime-types';
import { EMAIL_CONFIG } from './config';
import { formatDateForFilename, isValidDate } from '../datetime/utils';
import { MAIL_PATH_TYPES } from './utils';

export const isMail = upload => {
    const extension = getExtension(upload);
    return EMAIL_CONFIG.allowed.includes(extension);
};

export const deduplicateFilenames = (attachments, existing) => {
    const blocked = existing.map(file => getFilename(file));
    return attachments.map(attachment => {
        const unique = deduplicateFilename(attachment, blocked);
        blocked.push(unique.filename);
        return unique;
    });
};

export const deduplicateFilename = (attachment, blocked) => {
    const parsed = parseFilename(attachment);

    let filename = null;
    let iteration = 0;

    do {
        const tryFilename = assembleFilename(parsed, iteration);

        if (!blocked.includes(tryFilename)) {
            filename = tryFilename;
        }

        iteration++;
    } while (!filename);

    return { ...attachment, filename };
};

export const getFilename = file => {
    if (file.name) {
        return file.name;
    }

    if (file.filename) {
        return file.filename;
    }

    if (file.file?.fileName) {
        return file.file.fileName;
    }

    return 'UnnamedFile';
};

export const parseFilename = file => {
    let name;

    if (typeof file === 'string') {
        const pathLevels = file.split('/');
        name = pathLevels[pathLevels.length].split('.');
    } else {
        name = getFilename(file).split('.');
    }

    if (name.length === 0) {
        return null;
    }

    return {
        name: name.slice(0, name.length - 1).join('.'),
        extension: name.pop().toLowerCase(),
    };
};

export const pathSetup = meta => {
    const setup = {
        parent: '',
        children: {
            mail: '',
            inbound: '',
            outbound: '',
        },
    };

    meta.forEach(node => {
        switch (node.name) {
            case EMAIL_CONFIG.paths.parent:
                setup.parent = node.value;
                break;
            case EMAIL_CONFIG.paths.mail:
                setup.children.mail = node.value;
                break;
            case EMAIL_CONFIG.paths.inbound:
                setup.children.inbound = node.value;
                break;
            case EMAIL_CONFIG.paths.outbound:
                setup.children.outbound = node.value;
                break;
            default:
                break;
        }
    });

    if (setup.parent === '' || setup.children.inbound === '' || setup.children.outbound === '') {
        return null;
    }
    return setup;
};

const emailFilenamePrefixedRegex = /^(\d{6}_\d{4}_EM[EA]_)+/g;

export const formatEmailFilename = (filename, { receivedAt, inbound }) => {
    const date = isValidDate(receivedAt) ? receivedAt : new Date();
    const datetime = formatDateForFilename(date);
    const input = inbound ? 'EME' : 'EMA';
    const prefix = `${[datetime, input].join('_')}_`;

    if (typeof filename === 'string' && emailFilenamePrefixedRegex.test(filename)) {
        return filename.replace(emailFilenamePrefixedRegex, prefix);
    }

    return `${prefix}${filename}`;
};

export const assembleFilename = (parsed, iteration) => {
    const number = iteration === 0 ? '' : iteration;
    return `${parsed.name + number}.${parsed.extension}`;
};

export const assemblePath = (paths, isInbound) => {
    if (paths) {
        return isInbound ? paths.children.inbound : paths.children.outbound;
    }

    return null;
};

export const assembleMailPath = (paths, isInbound) => {
    return paths && paths.children.mail ? paths.children.mail : assemblePath(paths, isInbound);
};

export const assemblePathByType = (paths, isInbound, type) => {
    if (type === MAIL_PATH_TYPES.FILE) {
        return assemblePath(paths, isInbound);
    }

    if (type === MAIL_PATH_TYPES.MAIL) {
        return assembleMailPath(paths, isInbound);
    }
};

export const getMime = file => {
    const parsed = parseFilename(file);
    return EMAIL_CONFIG.mimeExtensions[parsed.extension] || mime.lookup(parsed.extension) || null;
};

export const getExtension = upload => {
    if (upload && typeof upload !== 'string') {
        const fileExtension = parseFilename(upload).extension;

        if (EMAIL_CONFIG.allowed.includes(fileExtension)) {
            return fileExtension;
        }
    }
    return null;
};
