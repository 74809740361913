import React, { useEffect, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useField } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { selectTaskById, showTask } from '../../../modules/tasks/tasksSlice';
import { CALENDAR_RESOURCE_MODELS } from '../../../modules/calendar/utils';
import { TASK_RESOURCE } from '../../../modules/api/resources';
import { selectCardById } from '../../../modules/cards/selectors';
import { showCard } from '../../../modules/cards/actions';

const AppointmentFormTaskValueWorker = ({}) => {
    const dispatch = useDispatch();

    const [{ value: entityId }] = useField('entityId');
    const [{ value: entityType }] = useField('entityType');

    const [{ value: formTask }, , { setValue: setTask }] = useField('task');
    const [{ value: formCard }, , { setValue: setCard }] = useField('card');

    const taskId = useMemo(
        () => (entityType === CALENDAR_RESOURCE_MODELS[TASK_RESOURCE] ? entityId : null),
        [entityId, entityType]
    );
    const selectedTask = useSelector(state => selectTaskById(state, taskId));

    const cardId = useMemo(() => (taskId && selectedTask?.card_id) || null, [taskId, selectedTask]);
    const selectedCard = useSelector(state => selectCardById(state, cardId));

    useEffect(() => {
        if (taskId && (!formTask || formTask.id !== taskId)) {
            if (selectedTask) {
                const { id, category_id, priority_id, name, description } = selectedTask;

                setTask({
                    id,
                    categoryId: category_id,
                    priorityId: priority_id,
                    name,
                    description,
                });
            }
        }

        if (!taskId) {
            setTask(null);
            setCard(null);
        }
    }, [taskId, formTask, selectedTask]);

    useEffect(() => {
        if (cardId && (!formCard || formCard.id !== cardId)) {
            if (!selectedCard) {
                dispatch(showCard({ id: cardId }));
            } else {
                const { id, id_offer, id_order, title, address } = selectedCard;

                setCard({
                    id,
                    idOffer: id_offer,
                    idOrder: id_order,
                    title,
                    address,
                });
            }
        }
    }, [cardId, taskId, formCard, selectedCard]);

    return null;
};

AppointmentFormTaskValueWorker.propTypes = {};

export default AppointmentFormTaskValueWorker;
