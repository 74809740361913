import React from 'react';
import * as PropTypes from 'prop-types';
import { formatDurationText } from '../../modules/datetime/utils';
import { Typography } from '@mui/material';

const Duration = ({ durationValue, asDecimal, ...other }) => {
    return <Typography {...other}>{formatDurationText(durationValue, asDecimal)}</Typography>;
};

Duration.propTypes = {
    durationValue: PropTypes.number,
    asDecimal: PropTypes.bool,
};

Duration.defaultProps = {
    asDecimal: false,
};

export default Duration;
