import React, { useMemo, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Select from './formik/Select';
import { selectAllCategories } from '../../modules/categories/selectors';
import CategoryDisplay from '../categories/CategoryDisplay';
import CategoryForm from '../categories/CategoryForm';
import { UPDATE } from '../../modules/abilities/actions';
import { CATEGORY_RESOURCE } from '../../modules/api/resources';
import { useCan } from '../../modules/abilities/hooks';

const CategorySelect = ({ name, label, includeInactive, I, ...other }) => {
    const { t } = useTranslation();
    const categories = useSelector(selectAllCategories);
    const orderByAlphabet = !categories.length && categories[0].order;

    const [selectedEditItemId, setSelectedEditItemId] = useState(null);
    const [selectedEditItemLoading, setSelectedEditItemLoading] = useState(false);
    const canEdit = useCan(UPDATE, CATEGORY_RESOURCE);
    const popupRef = useRef();

    const options = useMemo(
        () => [
            { label: t('form.CategorySelect.empty'), value: null },
            ...categories
                .filter(({ active }) => includeInactive || active)
                .sort((a, b) =>
                    orderByAlphabet ? a.name.localeCompare(b.name) : a.order - b.order
                )
                .map(category => ({
                    label: <CategoryDisplay categoryId={category.id} />,
                    value: category.id,
                    onEdit: () => setSelectedEditItemId(category.id),
                })),
        ],
        [categories, includeInactive, t]
    );

    return (
        <Select
            can={I}
            name={name}
            label={label}
            options={options}
            ItemEditFormPopupProps={{
                component: (
                    <CategoryForm
                        categoryId={selectedEditItemId}
                        asPopup
                        popupStateRef={popupRef}
                        onLoading={loading => setSelectedEditItemLoading(loading)}
                    />
                ),
                canEdit,
                selectedEditItemValue: selectedEditItemId,
                isLoading: selectedEditItemLoading,
            }}
            {...other}
        />
    );
};

CategorySelect.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    includeInactive: PropTypes.bool,
    I: PropTypes.string,
};

CategorySelect.defaultProps = {
    label: null,
    includeInactive: false,
    I: null,
};

export default CategorySelect;
