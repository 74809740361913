import { createResourceSlice } from '../redux/resource/createResourceSlice';
import { APPOINTMENT_TYPE_RESOURCE } from '../api/resources';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

const appointmentTypeSlice = createResourceSlice({
    resource: APPOINTMENT_TYPE_RESOURCE,
    byKey: '',
});
export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,
    indexAppointmentTypes,
    showAppointmentType,
    storeAppointmentType,
    updateAppointmentType,
    archiveAppointmentType,
    restoreAppointmentType,
    destroyAppointmentType,
} = appointmentTypeSlice.actions;

export const {
    selectAppointmentTypeById,
    selectAppointmentTypesById,
    makeAppointmentTypesByIdsSelector,
    selectAllAppointmentTypeIds,
    selectAllAppointmentTypes,
    selectAppointmentTypesLoading,
    selectAppointmentTypesInitialized,
    selectAppointmentTypeLoading,
    selectAppointmentTypeInitialized,
    selectAppointmentTypeIdsBy,
    makeAppointmentTypesBySelector,
    selectAppointmentTypeLoadingBy,
    selectAppointmentTypeInitializedBy,
} = appointmentTypeSlice.selectors;

export const { useAppointmentTypes, useAppointmentType, useAppointmentTypesBy } =
    appointmentTypeSlice.hooks;

export const useAppointmentTypeByType = typeKey => {
    const appointmentTypes = useSelector(selectAllAppointmentTypes);
    return useMemo(() => {
        if (appointmentTypes && appointmentTypes.length > 0) {
            return appointmentTypes.find(type => type.type === typeKey);
        }

        return { id: 1 };
    }, [appointmentTypes]);
};

export default appointmentTypeSlice.reducer;
