import { ROLE_RESOURCE } from '../api/resources';

export const selectRolesById = state => state[ROLE_RESOURCE].byId;

export const selectRolesIds = state => state[ROLE_RESOURCE].allIds;

export const selectRoleById = (state, id) => selectRolesById(state)[id];

export const selectAllRoles = state => {
    const allIds = selectRolesIds(state);
    return allIds.map(id => {
        return selectRoleById(state, id);
    });
};

export const selectBaseUserRole = state => {
    return selectAllRoles(state).find(role => role['default']);
};
