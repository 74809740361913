import { CHECKLIST_ITEM_RESOURCE } from '../api/resources';

export const indexChecklistItems = params => (dispatch, getState, api) =>
    api[CHECKLIST_ITEM_RESOURCE].index(params);

export const showChecklistItem = params => (dispatch, getState, api) =>
    api[CHECKLIST_ITEM_RESOURCE].show(params);

export const storeChecklistItem = params => (dispatch, getState, api) =>
    api[CHECKLIST_ITEM_RESOURCE].store(params);

export const updateChecklistItem = params => (dispatch, getState, api) =>
    api[CHECKLIST_ITEM_RESOURCE].update(params);

export const destroyChecklistItem = params => (dispatch, getState, api) =>
    api[CHECKLIST_ITEM_RESOURCE].destroy({ ...params, forceDelete: true });

export const archiveChecklistItem = params => (dispatch, getState, api) =>
    api[CHECKLIST_ITEM_RESOURCE].destroy({ ...params, forceDelete: false });
