import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import {
    APPOINTMENT_CALENDAR_CONTEXT_FUNC_TYPE,
    useAppointmentCalendar,
} from '../AppointmentCalendarContext';

const CalendarFormBasicLayout = ({ onFieldChange }) => {
    const { attachFunc } = useAppointmentCalendar();

    useEffect(() => {
        if (attachFunc && onFieldChange) {
            attachFunc(APPOINTMENT_CALENDAR_CONTEXT_FUNC_TYPE.VALUE, onFieldChange);
        }
    }, [onFieldChange, attachFunc]);

    return null;
};

CalendarFormBasicLayout.propTypes = {};

export default CalendarFormBasicLayout;
