import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import pathJoin from 'path.join';
import { selectCardById } from './selectors';
import { indexCards, showCard } from './actions';
import { CARD_RESOURCE, CARD_STATUS_RESOURCE } from '../api/resources';
import { selectMetadataByContext } from '../metadata/selectors';
import { CARD_CLOUD_CONTEXT, CARD_STATUS_CLOUD_CONTEXT } from '../metadata/context';
import { showProtocolItem } from '../protocolItems/actions';
import { pathSetup } from '../email/attachments';

export const useCardFamily = (cardId, contexts = []) => {
    const dispatch = useDispatch();
    const card = useSelector(state => selectCardById(state, cardId));
    const parentId = card && card.parent_id ? card.parent_id : cardId;
    const [loading, setLoading] = useState(false);
    const [cardIds, setCardIds] = useState([]);

    // TODO: Versuchen, dass nur von der API neu geladen wird, wenn wirklich nötig.
    useEffect(() => {
        if (!loading && !cardIds.length) {
            setLoading(true);
            Promise.all([
                dispatch(showCard({ id: parentId, contexts })),
                dispatch(indexCards({ parent_id: parentId, contexts })),
            ]).then(values => {
                setCardIds(
                    values
                        .map(value => value.data)
                        .flat()
                        .map(_card => _card.id)
                );
                setLoading(false);
            });
        }
    }, [dispatch, parentId, cardIds.length, contexts, loading]);

    const handleReload = () => {
        setLoading(false);
        setCardIds([]);
    };

    return { cardIds, loading, handleReload };
};

export const useLocationTab = () => {
    const location = useLocation();
    const [tabName, setTabName] = useState(null);
    const [protocolItemId, setProtocolItemId] = useState(null);
    const [taskId, setTaskId] = useState(null);
    const [contactId, setContactId] = useState(null);
    const [urlTabName, setUrlTabName] = useState(null);
    const [fileId, setFileId] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(location.search);
        setProtocolItemId(urlSearchParams.get('protocol'));
        setTaskId(urlSearchParams.get('task'));
        setContactId(urlSearchParams.get('contact'));
        setUrlTabName(urlSearchParams.get('tab'));
        setFileId(urlSearchParams.get('file'));
    }, [location.search]);

    useEffect(() => {
        if (protocolItemId) {
            dispatch(showProtocolItem({ id: protocolItemId }))
                .then(response => {
                    const protocolItem = response.data;
                    setTabName(
                        protocolItem.protocol_id
                            ? `card-protocol-${protocolItem.protocol_id}`
                            : `protocol`
                    );
                })
                .catch(() => setTabName(`protocol`));
        } else if (taskId) {
            setTabName('tasks');
        } else if (contactId) {
            setTabName('contacts');
        } else if (fileId) {
            setTabName('files');
        } else if (urlTabName) {
            setTabName(urlTabName);
        } else {
            setTabName(null);
        }
    }, [protocolItemId, taskId, contactId, fileId, urlTabName, dispatch]);

    return tabName;
};

export const useCardCloud = cardId => {
    const metadata = useSelector(state =>
        selectMetadataByContext(state, CARD_RESOURCE, cardId, CARD_CLOUD_CONTEXT)
    );
    const cloudDir = metadata.find(({ name }) => name === 'card.cloud.directory');
    const cloudRoot = cloudDir ? pathJoin('/', cloudDir.value) : '/';
    const cloudBlocked = metadata.some(
        ({ name, value }) => name === 'card.cloud.directory.blocked' && value
    );

    return { cloudRoot, cloudBlocked };
};

export const useCardPaths = cardId => {
    const card = useSelector(state => selectCardById(state, cardId));
    const cardMeta = useSelector(state =>
        selectMetadataByContext(state, CARD_RESOURCE, cardId, CARD_CLOUD_CONTEXT)
    );
    const cardStatusMeta = useSelector(state =>
        selectMetadataByContext(
            state,
            CARD_STATUS_RESOURCE,
            card && card.card_status_id,
            CARD_STATUS_CLOUD_CONTEXT
        )
    );

    return useMemo(() => pathSetup([...cardMeta, ...cardStatusMeta]), [cardMeta, cardStatusMeta]);
};
