import * as Yup from 'yup';
import shiftValidator from './shiftValidator';

const getContractSchema = (days, shifts, contractTimes) => {
    const dayShifts = {};

    days.forEach(day => {
        const res = [];

        shifts.forEach(shift => {
            res.push(`${day}_${shift}_start`);
            res.push(`${day}_${shift}_end`);
        });

        dayShifts[day] = res;
    });

    const makeTimeshape = values => {
        const timeShape = {};
        contractTimes.forEach(t => {
            const [day, shift, key] = t.split('_', 3);

            timeShape[t] = Yup.string().test({
                test: function (value) {
                    return shiftValidator(this.createError, shifts, values, day, shift, value, key);
                },
            });
        });

        return timeShape;
    };

    const schema = Yup.object().shape({
        personnel_number: Yup.string().nullable(),
        started_at: Yup.date().nullable(),
        ended_at: Yup.date().nullable(),
        vacation_days: Yup.number()
            .nullable()
            .transform((_, value) => {
                if (String(value).includes('.')) {
                    return null;
                }

                return +String(value).replace(/,/, '.');
            })
            .positive(),
        cancellation_period: Yup.number().typeError('numberMismatch').nullable().positive(),
        contract_work_times: Yup.lazy(values => {
            return Yup.object().shape(makeTimeshape(values));
        }),
    });

    return schema;
};

export default getContractSchema;
