import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import isFunction from 'lodash/isFunction';
import Table from '../Table';
import TableRowStoreContainer from '../TableRowStoreContainer';
import TableContext from '../TableContext';
import CreateResourceButton from './CreateResourceButton';
import { TableActionPropType, TableExpandPropType, TableOrderPropType } from '../proptypes';
import TableRowDetails from '../TableRowDetails';
import EditButton from '../../buttons/EditButton';
import EditIconButton from '../../buttons/EditIconButton';
import ArchiveIconButton from '../../buttons/ArchiveIconButton';
import ArchiveButton from '../../buttons/ArchiveButton';
import DeleteButton from '../../buttons/DeleteButton';
import DeleteIconButton from '../../buttons/DeleteIconButton';
import RestoreButton from '../../buttons/RestoreButton';
import RestoreIconButton from '../../buttons/RestoreIconButton';
import { useResourceList } from '../../../modules/lists/hooks';
import { getResourceIdField } from '../../../modules/redux/resource/utils';
import ResourcePagination from './ResourcePagination';
import OpenButton from '../../buttons/OpenButton';
import OpenIconButton from '../../buttons/OpenIconButton';
import { READ } from '../../../modules/abilities/actions';

const defaultComponents = {
    TableRow: TableRowStoreContainer,
    TableRowDetails,
};

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },

    createButton: {
        display: 'flex',
        marginLeft: 'auto',
    },

    pagination: {
        marginBottom: theme.spacing(3),
    },
}));

const getActions = ({
    actions,
    onEdit,
    onOpen,
    onArchive,
    onDelete,
    editDisabled,
    archiveDisabled,
    deleteDisabled,
}) => {
    const merged = actions ? [...actions] : [];

    if (onEdit) {
        merged.push({
            key: 'edit',
            action: ({ data }) => (
                <EditButton
                    onClick={() => onEdit(data.id)}
                    disabled={!!(data.deleted_at || (editDisabled && editDisabled(data)))}
                    subject={data}
                />
            ),
            compact: ({ data }) => (
                <EditIconButton
                    onClick={() => onEdit(data.id)}
                    disabled={!!(data.deleted_at || (editDisabled && editDisabled(data)))}
                    subject={data}
                    action={READ}
                    size="medium"
                />
            ),
        });
    }

    if (onOpen) {
        merged.push({
            key: 'open',
            action: ({ data }) => (
                <OpenButton
                    onClick={() => onOpen(data)}
                    disabled={!!(data.deleted_at || (editDisabled && editDisabled(data)))}
                    subject={data}
                />
            ),
            compact: ({ data }) => (
                <OpenIconButton
                    onClick={() => onOpen(data)}
                    disabled={!!(data.deleted_at || (editDisabled && editDisabled(data)))}
                    subject={data}
                    size="medium"
                />
            ),
        });
    }

    if (onArchive) {
        merged.push({
            key: 'archive',
            action: ({ data }) =>
                data.deleted_at ? (
                    <RestoreButton
                        onClick={() => onArchive(data.id, true)}
                        disabled={archiveDisabled && archiveDisabled(data)}
                        subject={data}
                    />
                ) : (
                    <ArchiveButton
                        onClick={() => onArchive(data.id)}
                        disabled={archiveDisabled && archiveDisabled(data)}
                        subject={data}
                        size="medium"
                    />
                ),
            compact: ({ data }) =>
                data.deleted_at ? (
                    <RestoreIconButton
                        onClick={() => onArchive(data.id, true)}
                        disabled={archiveDisabled && archiveDisabled(data)}
                        subject={data}
                    />
                ) : (
                    <ArchiveIconButton
                        onClick={() => onArchive(data.id)}
                        disabled={archiveDisabled && archiveDisabled(data)}
                        subject={data}
                        size="medium"
                    />
                ),
        });
    }

    if (onDelete) {
        merged.push({
            key: 'delete',
            action: ({ data }) => (
                <DeleteButton
                    onClick={() => onDelete(data.id)}
                    disabled={deleteDisabled && deleteDisabled(data)}
                    subject={data}
                />
            ),
            compact: ({ data }) => (
                <DeleteIconButton
                    onClick={() => onDelete(data.id)}
                    disabled={deleteDisabled && deleteDisabled(data)}
                    subject={data}
                    size="medium"
                />
            ),
        });
    }

    return merged;
};

const ResourceTableClassic = ({
    resource,
    listId: primaryListId,
    genericListId,
    columns,
    onCreate,
    onEdit,
    onOpen,
    onArchive,
    onDelete,
    editDisabled,
    archiveDisabled,
    deleteDisabled,
    actions,
    expand,
    showActionsInline,
    initialOrderBy,
    initialSearch,
    orderRanks,
    onClick,
    'data-test-id': dataTestId,
    'aria-label': ariaLabel,
    contexts,
    fetchParams,
    autoload,
    index,
    with: withKeys,
    updateWatch,
    criteria,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const listId = primaryListId || resource;

    const {
        dataIds,
        dataSelector,
        relatedSelector,
        loading,
        initialized,
        handlePage,
        orderBy,
        handleOrderBy,
    } = useResourceList({
        listId,
        resource,
        fetchParams,
        contexts,
        initialOrderBy,
        initialSearch,
        autoload,
        with: withKeys,
        updateWatch,
        index,
        criteria,
    });

    const mergedActions = useMemo(
        () =>
            getActions({
                actions,
                onEdit,
                onOpen,
                onArchive,
                onDelete,
                editDisabled,
                archiveDisabled,
                deleteDisabled,
            }),
        [
            onEdit,
            onOpen,
            onArchive,
            onDelete,
            actions,
            editDisabled,
            archiveDisabled,
            deleteDisabled,
        ]
    );

    const labeledColumns = useMemo(
        () =>
            columns.map(column => ({
                ...column,
                label:
                    column.label ||
                    t(`components.ResourceTable.${genericListId || listId}.${column.key}`),
            })),
        [columns, listId, genericListId, t]
    );

    /*
     * [QoL]: use `<resource>Id` as default id field name when using an expand component
     */
    const extendedExpand = useMemo(() => {
        if (!expand) {
            return null;
        }

        return {
            as: getResourceIdField(resource),
            ...(isFunction(expand) ? { component: expand } : expand),
        };
    }, [expand, resource]);

    return (
        <TableContext.Provider value={defaultComponents}>
            {onCreate && (
                <CreateResourceButton
                    listId={listId}
                    onClick={onCreate}
                    resource={resource}
                    data-test-id={`${dataTestId}CreateButton`}
                    className={classes.createButton}
                />
            )}
            <Paper className={classes.paper}>
                <Table
                    dataIds={dataIds}
                    columns={labeledColumns}
                    dataSelector={dataSelector}
                    relatedSelector={relatedSelector}
                    loading={loading || !initialized}
                    data-test-id={dataTestId}
                    aria-label={ariaLabel}
                    actions={mergedActions}
                    expand={extendedExpand}
                    showActionsInline={showActionsInline}
                    orderBy={orderBy}
                    onOrderBy={handleOrderBy}
                    orderRanks={orderRanks}
                    onClick={onClick}
                    listId={listId}
                />
            </Paper>
            <ResourcePagination
                listId={listId}
                onChange={handlePage}
                className={classes.pagination}
            />
        </TableContext.Provider>
    );
};

ResourceTableClassic.propTypes = {
    resource: PropTypes.string.isRequired,
    listId: PropTypes.string,
    genericListId: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onCreate: PropTypes.func,
    onEdit: PropTypes.func,
    onOpen: PropTypes.func,
    onArchive: PropTypes.func,
    onDelete: PropTypes.func,
    editDisabled: PropTypes.func,
    archiveDisabled: PropTypes.func,
    deleteDisabled: PropTypes.func,
    fetchParams: PropTypes.shape({}),
    contexts: PropTypes.arrayOf(PropTypes.string),
    actions: PropTypes.arrayOf(TableActionPropType),
    expand: TableExpandPropType,
    showActionsInline: PropTypes.number,
    initialOrderBy: PropTypes.arrayOf(TableOrderPropType),
    initialSearch: PropTypes.shape({}),
    orderRanks: PropTypes.number,
    autoload: PropTypes.bool,
    onClick: PropTypes.func,
    'data-test-id': PropTypes.string,
    'aria-label': PropTypes.string,
    index: PropTypes.bool,
    with: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.arrayOf(PropTypes.string)]),
    updateWatch: PropTypes.number,
    criteria: PropTypes.shape({
        check: PropTypes.func.isRequired,
        compare: PropTypes.func.isRequired,
        prepare: PropTypes.func,
    }),
};

ResourceTableClassic.defaultProps = {
    listId: null,
    genericListId: null,
    onCreate: null,
    onEdit: null,
    onOpen: null,
    onArchive: null,
    onDelete: null,
    editDisabled: null,
    archiveDisabled: null,
    deleteDisabled: null,
    fetchParams: null,
    contexts: [],
    actions: null,
    expand: null,
    showActionsInline: undefined,
    initialOrderBy: undefined,
    initialSearch: undefined,
    orderRanks: undefined,
    autoload: false,
    onClick: null,
    'data-test-id': null,
    'aria-label': null,
    index: false,
    with: null,
    updateWatch: null,
    criteria: null,
};

export default ResourceTableClassic;
