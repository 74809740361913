import * as workerTimers from 'worker-timers';

const isWebWorkerSupported = !!window.Worker && typeof Worker !== 'undefined';

const timer = {};

timer.clearInterval = intervalId => {
    if (isWebWorkerSupported) {
        return workerTimers.clearInterval(intervalId);
    } else {
        return clearInterval(intervalId);
    }
};

timer.clearTimeout = timeoutId => {
    if (isWebWorkerSupported) {
        return workerTimers.clearTimeout(timeoutId);
    } else {
        return clearTimeout(timeoutId);
    }
};

timer.setInterval = (callback, delay) =>
    isWebWorkerSupported ? workerTimers.setInterval(callback, delay) : setInterval(callback, delay);

timer.setTimeout = (callback, delay) =>
    isWebWorkerSupported ? workerTimers.setTimeout(callback, delay) : setTimeout(callback, delay);

export default timer;
