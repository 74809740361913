import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { bindPopper, usePopupState } from 'material-ui-popup-state/hooks';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import * as PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Button from '../form/base/Button';
import { RefPropType } from '../../modules/proptypes';
import SubmitButton from '../form/formik/SubmitButton';
import Form from '../form/formik/Form';
import WatchContainer from '../form/formik/WatchContainer';
import AutoSave from '../form/formik/AutoSave';
import Popup from './Popup';
import DeleteButton from '../buttons/DeleteButton';
import { SubjectPropType } from '../../modules/abilities/proptypes';
import { ConditionalWrapper } from '../utils/ConditionalWrapper';
import Badge from '@mui/material/Badge';

const useStyles = makeStyles(theme => ({
    paddingBottom: {
        paddingBottom: theme.spacing(1.5),
    },

    buttons: {
        paddingTop: 4,
    },

    button: {
        maxWidth: 120,
    },

    alignRight: {
        textAlign: 'right',
    },
}));

const FormPopup = ({
    headline,
    subheadline,
    submitLabel,
    abortLabel,
    FormInputs,
    initialValues,
    popupStateRef,
    popupId,
    placement,
    disableUntouchedSubmit,
    postponeSubmitClose,
    onOpen,
    onSubmit,
    onAbort,
    onDelete,
    onClickAway,
    children,
    disabled,
    disableFieldEmpty,
    autoSubmit,
    enableReinitialize,
    submitBadge,
    subject,
    validationSchema,
    ...other
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const popupState = useRef();

    const handleSubmit = (values, formProps) => {
        if (postponeSubmitClose && onSubmit) {
            return onSubmit(values, (popupStateRef || popupState).current?.close, formProps);
        }

        if (onSubmit) {
            return Promise.resolve(onSubmit(values, formProps))
                .then(() => {
                    (popupStateRef || popupState).current?.close();
                })
                .catch(() => {
                    (popupStateRef || popupState).current?.close();
                });
        }

        return null;
    };

    const handleDelete = () => {
        if (onDelete) {
            (popupStateRef || popupState).current?.close();
            return onDelete();
        }
        return null;
    };

    const handleOpen = event => {
        if (!disabled) {
            (popupStateRef || popupState).current?.open(event);

            if (onOpen) {
                onOpen(event);
            }
        }
    };

    const handleAbort = () => {
        (popupStateRef || popupState).current?.close();

        if (onAbort) {
            onAbort();
        }
    };

    const handleClickAway = () => {
        if (onClickAway) {
            onClickAway((popupStateRef || popupState).current);
        } else {
            handleAbort();
        }
    };

    return (
        <Popup
            popupId={popupId}
            popupStateRef={popupStateRef || popupState}
            onOpen={handleOpen}
            onAbort={handleClickAway}
            popupChildren={
                <Form
                    initialValues={initialValues}
                    autoSubmit={autoSubmit}
                    preventInitialSubmit={autoSubmit}
                    enableReinitialize={enableReinitialize}
                    subject={subject}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    <Typography variant="h5" color="primary" className={classes.paddingBottom}>
                        {headline}
                    </Typography>
                    {subheadline && (
                        <Typography
                            variant="h5"
                            color="textSecondary"
                            className={classes.paddingBottom}
                        >
                            {subheadline}
                        </Typography>
                    )}
                    <Box className={classes.paddingBottom}>{FormInputs}</Box>
                    {!autoSubmit && (
                        <Grid container spacing={1} className={classes.buttons}>
                            <Grid item xs={4}>
                                {onDelete && (
                                    <DeleteButton
                                        subject={subject}
                                        onClick={handleDelete}
                                        color="error"
                                    />
                                )}
                            </Grid>
                            <Grid item xs={4} className={classes.alignRight}>
                                <Button
                                    className={classes.button}
                                    size="small"
                                    variant="outlined"
                                    onClick={handleAbort}
                                >
                                    {abortLabel || t('abort')}
                                </Button>
                            </Grid>
                            <Grid item xs={4}>
                                {disableFieldEmpty ? (
                                    <WatchContainer
                                        name={disableFieldEmpty}
                                        WatchComponent={({ value }) => (
                                            <SubmitButton
                                                label={
                                                    submitLabel ||
                                                    t('form.SubmitButton.defaultLabel')
                                                }
                                                className={classes.button}
                                                size="small"
                                                variant="contained"
                                                disabled={
                                                    !value || value.length === 0 || value === ''
                                                }
                                                alwaysEnabled
                                            />
                                        )}
                                    />
                                ) : (
                                    <Badge
                                        invisible={!submitBadge}
                                        badgeContent={submitBadge}
                                        color="error"
                                    >
                                        <SubmitButton
                                            label={
                                                submitLabel || t('form.SubmitButton.defaultLabel')
                                            }
                                            className={classes.button}
                                            size="small"
                                            variant="contained"
                                            alwaysEnabled={disableUntouchedSubmit}
                                        />
                                    </Badge>
                                )}
                            </Grid>
                        </Grid>
                    )}
                </Form>
            }
            {...other}
        >
            {children}
        </Popup>
    );
};

FormPopup.propTypes = {
    headline: PropTypes.string,
    subheadline: PropTypes.string,
    submitLabel: PropTypes.string,
    abortLabel: PropTypes.string,
    FormInputs: PropTypes.node,
    initialValues: PropTypes.shape({}),
    popupId: PropTypes.string.isRequired,
    icon: PropTypes.node,
    popupStateRef: RefPropType,
    disableUntouchedSubmit: PropTypes.bool,
    postponeSubmitClose: PropTypes.bool,
    onOpen: PropTypes.func,
    onSubmit: PropTypes.func,
    onAbort: PropTypes.func,
    onClickAway: PropTypes.func,
    children: PropTypes.node,
    placement: PropTypes.string,
    disabled: PropTypes.bool,
    disableFieldEmpty: PropTypes.string,
    autoSubmit: PropTypes.bool,
    enableReinitialize: PropTypes.bool,
    submitBadge: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    subject: SubjectPropType,
    validationSchema: PropTypes.shape({}),
};

FormPopup.defaultProps = {
    headline: '',
    subheadline: null,
    submitLabel: null,
    abortLabel: null,
    FormInputs: null,
    initialValues: {},
    icon: null,
    placement: 'bottom',
    disableUntouchedSubmit: true,
    postponeSubmitClose: false,
    onOpen: null,
    onSubmit: null,
    onAbort: null,
    onClickAway: null,
    popupStateRef: null,
    children: null,
    disabled: false,
    autoSubmit: false,
    enableReinitialize: false,
    submitBadge: null,
    subject: null,
    validationSchema: null,
};

export default FormPopup;
