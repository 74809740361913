export const assembleBulkValue = (prevValue, newValue) =>
    prevValue === undefined || prevValue !== newValue ? undefined : newValue;

export const assembleBulkValues = items =>
    items.reduce((carry, item) => {
        if (item) {
            return Object.entries(item).reduce((fieldCarry, [key, value]) => {
                if (fieldCarry) {
                    const carryVal = fieldCarry[key];
                    const newValue = carry ? assembleBulkValue(carryVal, value) : value;

                    return {
                        ...fieldCarry,
                        [key]: newValue,
                    };
                }

                return {
                    [key]: value,
                };
            }, carry);
        }

        return carry;
    }, null);

export const cleanPartialUpdateValues = (initialValues, newValues) =>
    Object.entries(newValues).reduce((carry, [key, value]) => {
        if (!key in initialValues || value !== initialValues[key]) {
            return {
                ...carry,
                [key]: value,
            };
        }

        return carry;
    }, {});
