import React from 'react';
import * as PropTypes from 'prop-types';
import MuiIconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { Tooltip } from '@mui/material';
import { ConditionalWrapper } from '../../utils/ConditionalWrapper';
import Can from '../../../modules/abilities/Can';
import { SubjectPropType } from '../../../modules/abilities/proptypes';

const useStyles = makeStyles(theme => ({
    button: {
        color: 'inherit',
    },

    contrast: {
        color: theme.palette.primary.contrastText,
    },

    tiny: {
        padding: 0,
        fontSize: 10,
    },
}));

const IconButton = ({
    contrast,
    size,
    disabled,
    color,
    children,
    className,
    subject,
    action,
    label,
    field,
    ...other
}) => {
    const classes = useStyles();
    return (
        <ConditionalWrapper
            condition={!!(subject && action)}
            wrapper={_children => (
                <Can I={action} this={subject} field={field}>
                    {_children}
                </Can>
            )}
        >
            <ConditionalWrapper
                condition={label}
                wrapper={_children => (
                    <Tooltip title={label} disableInteractive>
                        {_children}
                    </Tooltip>
                )}
            >
                <MuiIconButton
                    {...other}
                    size={!size || size === 'tiny' ? '' : size}
                    classes={
                        size === 'tiny'
                            ? {
                                  root: classes.tiny,
                              }
                            : null
                    }
                    color={color}
                    disabled={disabled}
                    className={classNames(className, classes.button, {
                        [classes.contrast]: contrast,
                    })}
                >
                    {children}
                </MuiIconButton>
            </ConditionalWrapper>
        </ConditionalWrapper>
    );
};

IconButton.propTypes = {
    children: PropTypes.node.isRequired,
    size: PropTypes.string,
    color: PropTypes.string,
    contrast: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    action: PropTypes.string,
    subject: SubjectPropType,
    label: PropTypes.string,
    field: PropTypes.string,
};

IconButton.defaultProps = {
    size: 'medium',
    color: 'default',
    contrast: false,
    disabled: false,
    className: null,
    subject: null,
    action: null,
    label: null,
    field: null,
};

export default IconButton;
