import React, { useEffect, useMemo, useRef } from 'react';
import * as PropTypes from 'prop-types';
import { Box } from '@mui/material';

const KeyboardWatcher = ({ onEnter, customKeys, children, ...other }) => {
    const ref = useRef(null);

    const handleBlur = () => {
        if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
        }
        if (ref && ref.current && ref.current instanceof HTMLElement) {
            ref.current.blur();
        }
    };

    const handleEnter = event => {
        if (onEnter) {
            onEnter(event, handleBlur);
        }
    };

    const allKeys = useMemo(
        () => [...customKeys, ...(onEnter ? [{ keyCode: 13, callback: handleEnter }] : [])],
        [customKeys, onEnter, handleEnter]
    );

    useEffect(() => {
        if (ref && ref.current) {
            ref.current.onkeydown = event => {
                const foundKey = allKeys.find(option => option.keyCode === event.keyCode);

                if (foundKey) {
                    foundKey.callback(event);
                }
            };
        }
    }, [allKeys]);

    return (
        <Box ref={ref} {...other}>
            {children}
        </Box>
    );
};

KeyboardWatcher.propTypes = {
    onEnter: PropTypes.func,
    customKeys: PropTypes.arrayOf(
        PropTypes.shape({
            keyCode: PropTypes.number.isRequired,
            callback: PropTypes.func.isRequired,
        })
    ),
    children: PropTypes.node,
};

KeyboardWatcher.defaultProps = {
    onEnter: null,
    customKeys: [],
    children: null,
};

export default KeyboardWatcher;
