import React from 'react';
import * as PropTypes from 'prop-types';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useTranslation } from 'react-i18next';
import IconButton from '../form/base/IconButton';
import { UPDATE } from '../../modules/abilities/actions';
import { SubjectPropType } from '../../modules/abilities/proptypes';

const EditIconButton = ({
    onClick,
    size,
    contrast,
    disabled,
    subject,
    action,
    className,
    ...other
}) => {
    const { t } = useTranslation();

    return (
        <IconButton
            onClick={onClick}
            size={size}
            contrast={contrast}
            disabled={disabled}
            subject={subject}
            action={action || UPDATE}
            data-test-id="EditIconButton"
            label={t('components.ResourceTableRowDetails.edit')}
            className={className}
            {...other}
        >
            <EditOutlinedIcon fontSize={size === 'tiny' ? 'small' : size} />
        </IconButton>
    );
};

EditIconButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    size: PropTypes.string,
    contrast: PropTypes.bool,
    disabled: PropTypes.bool,
    subject: SubjectPropType,
    action: PropTypes.string,
    className: PropTypes.string,
};

EditIconButton.defaultProps = {
    size: 'medium',
    contrast: false,
    disabled: false,
    subject: null,
    action: null,
    className: null,
};

export default EditIconButton;
