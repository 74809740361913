/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { CONTACT_RESOURCE } from '../api/resources';

const insertContact = (state, contact) => {
    if (!state.allIds.includes(contact.id)) {
        state.allIds.push(contact.id);
    }

    const prepped = {
        ...contact,
        personId: contact.person ? contact.person.id : null,
        companyId: contact.company ? contact.company.id : null,
    };

    delete prepped.person;
    delete prepped.company;
    delete prepped.contactables;
    delete prepped.addresses;
    delete prepped.contact_data;

    state.byId[contact.id] = prepped;
};

const contactsSlice = createSlice({
    name: CONTACT_RESOURCE,
    initialState: {
        byId: {},
        allIds: [],
        loading: false,
    },
    reducers: {
        indexPending: state => {
            state.loading = true;
        },
        indexFulfilled: (state, action) => {
            state.loading = false;
            action.payload.forEach(contact => {
                insertContact(state, contact);
            });
        },
        searchPending: state => {
            state.loading = true;
        },
        searchFulfilled: (state, action) => {
            state.loading = false;

            action.payload.forEach(contact => {
                insertContact(state, contact);
            });
        },

        showFulfilled: (state, action) => {
            insertContact(state, action.payload);
        },

        storeFulfilled: (state, action) => {
            insertContact(state, action.payload);
        },

        updateFulfilled: (state, action) => {
            insertContact(state, action.payload);
        },

        destroyFulfilled: (state, action) => {
            if (action.meta.archived) {
                /**
                 * keep the contact if it was just soft deleted
                 */
                insertContact(state, action.meta.archived);
            } else {
                const index = state.allIds.findIndex(id => id === action.payload);
                if (index >= 0) {
                    delete state.byId[action.payload];
                    state.allIds.splice(index, 1);
                }
            }
        },

        restoreFulfilled: (state, action) => {
            insertContact(state, action.payload);
        },
    },
});

export const {
    indexFulfilled,
    searchPending,
    searchFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,
} = contactsSlice.actions;

export default contactsSlice.reducer;
