const toSeconds = str => {
    const [h, m] = str.split(':');
    return h * 3600 + m * 60;
};

const shiftValidator = (createError, shifts, allData, day, shift, value, key) => {
    if (!value) {
        return true;
    }

    if (key === 'start') {
        const end = allData[`${day}_${shift}_end`] || null;
        if (end) {
            if (toSeconds(value) > toSeconds(end)) {
                return createError({ message: 'timeMustBeBefore' });
            }
        }
    }

    if (key === 'end') {
        const start = allData[`${day}_${shift}_start`] || null;
        if (start) {
            if (toSeconds(value) < toSeconds(start)) {
                return createError({ message: 'timeMustBeAfter' });
            }
        }
    }

    const intervals = [];
    for (let i = 0; i < shifts.length; i++) {
        const s = shifts[i];

        const start = allData[`${day}_${s}_start`] || null;
        const end = allData[`${day}_${s}_end`] || null;

        if (!start || !end) {
            continue;
        }

        intervals.push({ start: toSeconds(start), end: toSeconds(end) });
    }

    const newInterval = {
        start: key === 'start' ? toSeconds(value) : toSeconds(allData[`${day}_${shift}_start`]),
        end:
            key === 'start'
                ? allData[`${day}_${shift}_end`]
                    ? toSeconds(allData[`${day}_${shift}_end`])
                    : null
                : toSeconds(value),
    };

    // If it's not already in the interval list
    if (
        !intervals.find(({ start, end }) => start === newInterval.start && end === newInterval.end)
    ) {
        intervals.push(newInterval);
    }

    intervals.sort((a, b) => {
        return a.start - b.start;
    });

    for (let i = 1; i < intervals.length; i++) {
        if (intervals[i - 1].end > intervals[i].start) {
            return createError({ message: 'overlap' });
        }
    }

    return true;
};

export default shiftValidator;
