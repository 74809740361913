import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Box, Portal } from '@mui/material';
import Divider from '@mui/material/Divider';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from '../../modules/snackbar/hooks';
import { useDialog } from '../dialogs/DialogContext';
import { selectContactById } from '../../modules/contacts/selectors';
import { selectContactDatasByContactId } from '../../modules/contactData/selectors';
import { selectAddressesByContactId } from '../../modules/addresses/selectors';
import { selectPersonById } from '../../modules/persons/selectors';
import AddressesInput, { getNewAddress } from '../address/AddressesInput';
import ContactDatasInput, { getNewContactData } from '../contactData/ContactDatasInput';
import {
    ADDRESS_RESOURCE,
    CONTACT_DATA_RESOURCE,
    CONTACT_RESOURCE,
    PERSON_RESOURCE,
} from '../../modules/api/resources';
import { subjectArray } from '../../modules/abilities/actions';
import Form from '../form/formik/Form';
import { personContactSchema } from '../../modules/contacts/schema';
import CompanyInput from '../company/CompanyInput';
import SalutationSelect from '../form/SalutationSelect';
import TextInput from '../form/formik/TextInput';
import DateInput from '../form/formik/DateInput';
import { storePerson, updatePerson } from '../../modules/persons/actions';
import { IdPropType, RefPropType } from '../../modules/proptypes';
import { prefillInitialValues } from '../../modules/form/utils';
import { bulkSubmit } from '../../modules/contacts/utils';
import ContactTypeMultiSelect from '../form/ContactTypeMultiSelect';
import SubmitButton from '../form/formik/SubmitButton';
import FormDialogAbortButton from '../buttons/specialized/FormDialogAbortButton';
import Checkbox from '../form/formik/Checkbox';
import PersonFunctionSelect from '../person/PersonFunctionSelect';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },

    header: {
        paddingBottom: theme.spacing(2),
        marginTop: theme.spacing(1),
    },

    inputRow: {
        width: '100%',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
    },
    headerDivider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    verticalDivider: {
        top: theme.spacing(3),
        bottom: theme.spacing(3),
        position: 'absolute',
        height: '100%',
        width: 1,
        left: 0,
    },
    dividerContainer: {
        position: 'relative',
    },
    fullHeight: {
        height: '100%',
    },
}));

const PersonContactForm = ({
    contactId,
    contextCardId,
    contextParentId,
    submitRef,
    extraRef,
    onDone,
    hasIdCustom = true,
    openContact = true,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { openContactDialog } = useDialog();

    const contact = useSelector(state => selectContactById(state, contactId));
    const contactDatas = useSelector(state => selectContactDatasByContactId(state, contactId));
    const addresses = useSelector(state => selectAddressesByContactId(state, contactId));
    const person = useSelector(state => selectPersonById(state, contact && contact.personId));

    const initialValues = useMemo(
        () => ({
            contact: prefillInitialValues(contact, {
                department: '',
                person_function_id: '',
                id_custom: '',
                parent_id: contextParentId || '',
            }),
            person: prefillInitialValues(person, {
                gender: '',
                title: '',
                first_name: '',
                last_name: '',
                birthday: null,
            }),
            addresses: contactId
                ? addresses.map(address => prefillInitialValues(address, getNewAddress()))
                : [getNewAddress()],
            contactData: contactId
                ? contactDatas.map(contactData =>
                      prefillInitialValues(contactData, getNewContactData())
                  )
                : [getNewContactData('office', 'phone'), getNewContactData('office', 'email')],
            useContactable: !!contextCardId,
            contactTypeIds: [],
        }),
        [contactId, contextCardId, contextParentId, contact, person, contactDatas, addresses]
    );

    const subject = useMemo(
        () => ({
            contact: contact || CONTACT_RESOURCE,
            person: person || PERSON_RESOURCE,
            addresses: subjectArray(ADDRESS_RESOURCE),
            contactData: subjectArray(CONTACT_DATA_RESOURCE),
        }),
        [contact, person]
    );

    const handleSubmit = async (values, { resetForm }) => {
        let { person: _person } = values;

        /* create / update person */
        const prepped = { ..._person, id: person && person.id };
        _person = (
            await dispatch(person && person.id ? updatePerson(prepped) : storePerson(prepped))
        ).data;

        const result = await bulkSubmit({
            values: { ...values, person: _person },
            initialValues,
            contactId,
            contextCardId,
            dispatch,
        });

        enqueueSnackbar(t('components.ContactBaseData.contactSaved'), {
            variant: 'success',
        });

        resetForm();
        if (!contactId && openContact) {
            openContactDialog({ contactId: result.contact.id, contextCardId });
        } else if (onDone) {
            onDone({ contactId: result.contact.id });
        }

        return Promise.resolve();
    };

    return (
        <Form
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={personContactSchema}
            subject={subject}
            className={classes.fullHeight}
        >
            <Grid container spacing={6} className={classes.fullHeight}>
                <Grid item xs={4}>
                    <Typography variant="h2" color="primary" className={classes.header}>
                        {t('components.ContactBaseData.company')}
                    </Typography>
                    <CompanyInput name="contact.parent_id" />
                </Grid>
                <Grid item xs={8} className={classes.dividerContainer}>
                    <Divider className={classes.verticalDivider} />
                    <Typography variant="h2" color="primary" className={classes.header}>
                        {t('components.ContactBaseData.person')}
                    </Typography>

                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <SalutationSelect
                                data-test-id="salutation-select"
                                size="small"
                                name="person.gender"
                                label={t('components.Person.gender')}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextInput
                                size="small"
                                name="person.title"
                                label={t('components.Person.title')}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextInput
                                data-test-id="edit-person-firstname-input"
                                size="small"
                                name="person.first_name"
                                label={t('components.Person.firstName')}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextInput
                                size="small"
                                name="person.last_name"
                                label={t('components.Person.lastName')}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box marginTop={2}>
                                        <PersonFunctionSelect
                                            name="contact.person_function_id"
                                            label={t('components.ContactBaseData.function')}
                                            size="small"
                                            fullWidth
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput
                                        name="contact.department"
                                        label={t('components.ContactBaseData.department')}
                                        size="small"
                                        fullWidth
                                    />
                                </Grid>
                                {hasIdCustom && (
                                    <Grid item xs={12}>
                                        <TextInput
                                            name="contact.id_custom"
                                            label={t('components.ContactBaseData.customId')}
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <Box marginTop={2}>
                                        <DateInput
                                            size="small"
                                            name="person.birthday"
                                            label={t('components.Person.birthday')}
                                            convertUTC
                                            fullWidth
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Divider className={classes.divider} />

                    <Typography variant="h3" color="primary" className={classes.header}>
                        {t('components.ContactBaseData.contactOptions')}
                    </Typography>
                    <Divider className={classes.headerDivider} />
                    <ContactDatasInput name="contactData" initialEdit={contactId ? [] : [0, 1]} />

                    <Typography variant="h3" color="primary" className={classes.header}>
                        {t('components.ContactBaseData.addresses')}
                    </Typography>
                    <Divider className={classes.headerDivider} />
                    <AddressesInput name="addresses" initialEdit={contactId ? null : 0} />
                </Grid>
            </Grid>

            {contextCardId && !contactId && (
                <Portal container={extraRef.current}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item>
                            <Checkbox
                                name="useContactable"
                                color="secondary"
                                label={t('components.ContactBaseData.cardBind')}
                            />
                        </Grid>
                        <Grid item>
                            <ContactTypeMultiSelect
                                name="contactTypeIds"
                                label={t('components.ContactBaseData.contactable')}
                            />
                        </Grid>
                    </Grid>
                </Portal>
            )}

            <Portal container={submitRef.current}>
                <Grid container spacing={1}>
                    {contactId && (
                        <Grid item>
                            <FormDialogAbortButton onClick={onDone} />
                        </Grid>
                    )}
                    <Grid item>
                        <SubmitButton
                            data-test-id="save-all-contact-changes-btn"
                            variant="contained"
                            outsideForm
                        >
                            {t('form.SubmitButton.defaultLabel')}
                        </SubmitButton>
                    </Grid>
                </Grid>
            </Portal>
        </Form>
    );
};

PersonContactForm.propTypes = {
    contactId: IdPropType,
    contextCardId: IdPropType,
    contextParentId: IdPropType,
    submitRef: RefPropType,
    extraRef: RefPropType,
    onDone: PropTypes.func,
};

PersonContactForm.defaultProps = {
    contactId: null,
    contextCardId: null,
    contextParentId: null,
    submitRef: null,
    extraRef: null,
    onDone: null,
};

export default PersonContactForm;
