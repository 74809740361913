import React from 'react';
import * as PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';
import { Box } from '@mui/material';
import LoadingIcon from '../loading/LoadingIcon';
import { useDispatch, useSelector } from 'react-redux';
import { selectListGroupByFieldsNestedList, selectListParams } from '../../modules/lists/selectors';
import { paramCleanup } from '../../modules/api/utils';
import { indexGroupNestedList } from '../../modules/lists/actions';

const GroupLoader = ({ listId, groupKey, groupNestedListKey, params, limit }) => {
    const dispatch = useDispatch();
    const listParams = useSelector(state => selectListParams(state, listId));
    const nestedMeta = useSelector(state =>
        selectListGroupByFieldsNestedList(state, listId, groupKey, groupNestedListKey)
    );
    const { loading } = nestedMeta;

    const handleEnter = () => {
        const includedParams = paramCleanup(listParams); //, false, ['orderBy']
        const fullParams = {
            ...includedParams,
            ...params,
            limit,
        };

        if (!loading) {
            dispatch(indexGroupNestedList(listId, groupKey, groupNestedListKey, 1, fullParams));
        }
    };

    return (
        <Waypoint key={`${listId}.${groupNestedListKey}`} onEnter={handleEnter}>
            <Box height={32}>
                <LoadingIcon isLoading={loading} size="small" />
            </Box>
        </Waypoint>
    );
};

GroupLoader.propTypes = {
    listId: PropTypes.string.isRequired,
    groupKey: PropTypes.string.isRequired,
    params: PropTypes.shape({}),
    limit: PropTypes.number,
};

GroupLoader.defaultProps = {
    params: null,
    limit: 50,
};

export default GroupLoader;
