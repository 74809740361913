import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Box, Grid, Icon } from '@mui/material';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import EmployeeAutocomplete from '../form/formik/autocomplete/EmployeeAutocomplete';
import {
    EMPLOYEE_RESOURCE,
    GROUP_RESOURCE,
    PERSONAL_ACCESS_TOKEN_RESOURCE,
    RESOURCE_RESOURCE,
} from '../../modules/api/resources';
import ResourceAutocomplete from '../form/formik/autocomplete/ResourceAutocomplete';
import BaseAvatar from '../icons/BaseAvatar';
import { IdPropType } from '../../modules/proptypes';
import { selectResourcesById } from '../../modules/resources/selectors';
import { selectEmployeesById } from '../../modules/employees/selectors';
import {
    indexEmployees,
    selectEmployeeObtainablePages,
    showEmployee,
} from '../../modules/employees/employeesSlice';
import {
    indexResources,
    selectResourceObtainablePages,
    showResource,
} from '../../modules/resources/resourcesSlice';
import {
    indexGroups,
    selectGroupObtainablePages,
    showGroup,
} from '../../modules/groups/groupsSlice';
import IconButton from '../form/base/IconButton';
import FormPopup from '../popups/FormPopup';
import { useAppointmentCalendar } from './AppointmentCalendarContext';
import Persistor from '../../modules/persistor/persistor';
import {
    CALENDAR_RESOURCE_MODELS,
    formatEmployeeForCalendar,
    formatFlatResourceKey,
    formatGroupForCalendar,
    formatResourceForCalendar,
    FLAT_UNPLANNED,
} from '../../modules/calendar/utils';
import { useCan } from '../../modules/abilities/hooks';
import { CREATE } from '../../modules/abilities/actions';
import { selectAllGroupsById } from '../../modules/groups/selectors';
import CalendarICalShare from './CalendarICalShare';
import GroupAutocomplete from '../form/formik/autocomplete/GroupAutocomplete';
import CalendarResourceVisibility from './CalendarResourceVisibility';
import {
    getCalendarResourceVisibilityIds,
    toggleCalendarResourceVisibilityForId,
} from '../../modules/calendar/visibility';
import { ConditionalWrapper } from '../utils/ConditionalWrapper';
import ItemStack from '../icons/ItemStack';

export const RESOURCES_MODES = {
    [EMPLOYEE_RESOURCE]: {
        formComponents: ({ getOptionDisabled }) => (
            <EmployeeAutocomplete
                name={EMPLOYEE_RESOURCE}
                getOptionDisabled={getOptionDisabled}
                popupSize="small"
                autofocus
            />
        ),
        selector: selectEmployeesById,
        obtainableSelector: selectEmployeeObtainablePages,
        index: indexEmployees,
        show: showEmployee,
        params: formatEmployeeForCalendar,
        share: true,
    },
    [RESOURCE_RESOURCE]: {
        formComponents: ({ getOptionDisabled }) => (
            <ResourceAutocomplete
                name={RESOURCE_RESOURCE}
                getOptionDisabled={getOptionDisabled}
                popupSize="small"
                autofocus
            />
        ),
        selector: selectResourcesById,
        obtainableSelector: selectResourceObtainablePages,
        index: indexResources,
        show: showResource,
        params: formatResourceForCalendar,
        share: true,
    },
    [GROUP_RESOURCE]: {
        formComponents: ({ getOptionDisabled }) => (
            <GroupAutocomplete
                name={GROUP_RESOURCE}
                onFormatQuery={q => `Gruppe: ${q}`}
                getOptionDisabled={getOptionDisabled}
                expectedType={GROUP_RESOURCE}
                popupSize="small"
                disablePortal
                autofocus
            />
        ),
        containerComponent: ({ children }) => (
            <Box style={{ border: '1px solid #efefef', borderRadius: 5 }}>{children}</Box>
        ),
        selector: selectAllGroupsById,
        obtainableSelector: selectGroupObtainablePages,
        index: indexGroups,
        show: showGroup,
        params: formatGroupForCalendar,
        variant: 'rounded',
    },
};

const CalendarResourcesPicker = ({
    resourceMode,
    initialSelect,
    AddIcon,
    SelectedExtraComponent,
    onChange,
    label,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { calendarId, defaultEntityId, defaultEntityResource } = useAppointmentCalendar();

    const canCreatePersonalAccessToken = useCan(CREATE, PERSONAL_ACCESS_TOKEN_RESOURCE);

    const selectPersistorKey = `${calendarId}.Select.${resourceMode}`;
    const persistedSelect = Persistor.get(selectPersistorKey, initialSelect);
    const [selectedResources, setSelectedResources] = useState(persistedSelect);
    const forceLoad = useRef(false);
    const currentSelected = useRef(null);

    const [hiddenIds, setHiddenIds] = useState([]);

    const defaultEntityFlatKey = useMemo(
        () =>
            defaultEntityResource && formatFlatResourceKey(defaultEntityId, defaultEntityResource),
        [defaultEntityId, defaultEntityResource]
    );

    const finalResources = useMemo(
        () => selectedResources.filter(id => !hiddenIds.includes(id)),
        [selectedResources, hiddenIds]
    );

    const {
        formComponents: FormComponents,
        containerComponent: ContainerComponent = ({ children }) => children,
        selector: resourceSelector,
        obtainableSelector,
        index: loadResources,
        show: loadResource,
        params: formatResource,
        variant,
        share,
    } = useMemo(() => RESOURCES_MODES[resourceMode], [resourceMode]);

    const obtainablePages = useSelector(state => obtainableSelector(state, selectPersistorKey));
    const resources = useSelector(resourceSelector);

    const { existingResources, missingIds } = useMemo(() => {
        const existingIds = Object.keys(resources);

        return selectedResources.reduce(
            (carry, id) => {
                const isExisting = existingIds.includes(id.toString());

                return {
                    existingResources: [
                        ...carry.existingResources,
                        ...(isExisting ? [formatResource(resources[id])] : []),
                    ],
                    missingIds: [...carry.missingIds, ...(!isExisting ? [id] : [])],
                };
            },
            { existingResources: [], missingIds: [] }
        );
    }, [selectedResources, resources]);

    /* ToDo: Remove due to being loaded in CalendarResources */
    const debouncedIndex = useMemo(
        () =>
            debounce(params => {
                dispatch(loadResources(params, { listId: selectPersistorKey }));
            }, 40),
        []
    );

    const debouncedShow = useMemo(
        () =>
            debounce(params => {
                dispatch(
                    loadResource(params, {
                        listId: selectPersistorKey,
                        obtainableFinishedOnError: true,
                        forceHandleError: true,
                        handleError: error => error?.status === 404,
                    })
                );
            }, 40),
        []
    );

    const updateHiddenIds = () => {
        const { disabledIds } = getCalendarResourceVisibilityIds(resourceMode, calendarId);
        setHiddenIds(disabledIds);
    };

    const toggleVisibility = id => {
        const { disabledIds } = toggleCalendarResourceVisibilityForId(id, resourceMode, calendarId);
        setHiddenIds(disabledIds);
    };

    const handleLoad = () => {
        if (!forceLoad.current && Array.isArray(obtainablePages) && obtainablePages.length === 0) {
            const existingIds = Object.keys(resources);
            const remainingIds = selectedResources.filter(id =>
                existingIds.includes(id.toString())
            );

            if (remainingIds.sort().join('-') !== selectedResources.sort().join('-')) {
                Persistor.set(selectPersistorKey, remainingIds);
                setSelectedResources(remainingIds);
            }
        } else if (missingIds.length > 0) {
            forceLoad.current = false;
            if (missingIds.length === 1) {
                debouncedShow({ id: missingIds[0] });
            } else {
                debouncedIndex({ ids: missingIds });
            }
        }
    };

    const handleRemove = id => {
        setSelectedResources(ids => ids.filter(checkId => checkId !== id));
    };

    const handleAdd = values => {
        const added = values[resourceMode];

        if (added && added.id && !selectedResources.includes(added.id)) {
            forceLoad.current = true;
            setSelectedResources(ids => {
                return [...ids, added.id];
            });
        }
    };

    const handleOptionDisabled = ({ id, type }) => {
        if (id && type) {
            return (
                type === CALENDAR_RESOURCE_MODELS[resourceMode] && selectedResources.includes(id)
            );
        }
        return true;
    };

    const showAsStack = useMemo(() => existingResources.length >= 3, [existingResources]);

    useEffect(() => {
        if (JSON.stringify(currentSelected.current) !== JSON.stringify(finalResources)) {
            currentSelected.current = finalResources;
            onChange(finalResources);
        }
    }, [finalResources, onChange]);

    useEffect(() => {
        if (JSON.stringify(persistedSelect) !== JSON.stringify(selectedResources)) {
            Persistor.set(selectPersistorKey, selectedResources);
        }
    }, [persistedSelect, selectedResources]);

    useEffect(() => {
        handleLoad();
    }, [missingIds]);

    useEffect(() => {
        updateHiddenIds();
    }, []);

    return (
        <Box style={{ marginTop: 8 }}>
            <Grid container style={{ height: 56 }} spacing={1}>
                <ConditionalWrapper
                    condition={showAsStack}
                    wrapper={children => (
                        <Box style={{ marginTop: 8 }}>
                            <ItemStack items={children} />
                        </Box>
                    )}
                >
                    {existingResources.map(
                        ({ id, key, flatKey, text, short, icon, avatar, color, ...other }) => {
                            const isDefaultResource =
                                defaultEntityFlatKey &&
                                flatKey === defaultEntityFlatKey.key &&
                                defaultEntityFlatKey.id !== FLAT_UNPLANNED.id;

                            const component = (
                                <Grid item key={key}>
                                    <ContainerComponent>
                                        <BaseAvatar
                                            displayName={text}
                                            shortName={
                                                short && !icon ? (
                                                    short
                                                ) : (
                                                    <Icon fontSize="small">{icon}</Icon>
                                                )
                                            }
                                            path={avatar}
                                            color={color}
                                            variant={variant}
                                            outlined={resourceMode === RESOURCE_RESOURCE}
                                            outlinedBackgroundColor="#efefef"
                                            disabled={hiddenIds.includes(id)}
                                            removeLabel={t(
                                                'components.CalendarResourcesPicker.remove'
                                            )}
                                            confirmRemoveLabel={t(
                                                'components.CalendarResourcesPicker.removeReally'
                                            )}
                                            RemoveIcon={EventBusyIcon}
                                            onClick={() =>
                                                isDefaultResource ? null : toggleVisibility(id)
                                            }
                                            onDelete={
                                                initialSelect.length !== 0 &&
                                                id === initialSelect[0]
                                                    ? null
                                                    : event => handleRemove(id, event)
                                            }
                                            hoverExtra={
                                                <>
                                                    {!isDefaultResource && (
                                                        <CalendarResourceVisibility
                                                            visible={!hiddenIds.includes(id)}
                                                            onClick={() => toggleVisibility(id)}
                                                        />
                                                    )}
                                                    {SelectedExtraComponent && (
                                                        <SelectedExtraComponent {...other} />
                                                    )}
                                                    {share && canCreatePersonalAccessToken && (
                                                        <CalendarICalShare
                                                            entityId={id}
                                                            name={text}
                                                            entityType={
                                                                CALENDAR_RESOURCE_MODELS[
                                                                    resourceMode
                                                                ]
                                                            }
                                                        />
                                                    )}
                                                </>
                                            }
                                        />
                                    </ContainerComponent>
                                </Grid>
                            );

                            if (showAsStack) {
                                return { key, component };
                            }

                            return component;
                        }
                    )}
                </ConditionalWrapper>
                <Grid item>
                    <FormPopup
                        popupId="AddCalendarResource"
                        icon={<ControlPointIcon />}
                        headline={t(`components.CalendarResourcesPicker.${resourceMode}.title`)}
                        subheadline={t(
                            `components.CalendarResourcesPicker.${resourceMode}.subtitle`
                        )}
                        submitLabel={t('components.CalendarResourcesPicker.add')}
                        abortLabel={t('components.CalendarResourcesPicker.cancel')}
                        initialValues={{
                            [resourceMode]: [],
                        }}
                        autoSubmit
                        onSubmit={handleAdd}
                        FormInputs={<FormComponents getOptionDisabled={handleOptionDisabled} />}
                    >
                        <IconButton label={label}>
                            <AddIcon />
                        </IconButton>
                    </FormPopup>
                </Grid>
            </Grid>
        </Box>
    );
};

CalendarResourcesPicker.propTypes = {
    resourceMode: PropTypes.oneOf(Object.keys(RESOURCES_MODES)).isRequired,
    initialSelect: PropTypes.arrayOf(IdPropType),
    AddIcon: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node]),
    SelectedExtraComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node]),
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
};

CalendarResourcesPicker.defaultProps = {
    initialSelect: [],
    SelectedExtraComponent: null,
    AddIcon: ControlPointIcon,
    label: null,
};

export default CalendarResourcesPicker;
