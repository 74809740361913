import React, { useMemo, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import Select from '../form/formik/Select';
import {
    selectAllAbsenceTypes,
    useAbsenceTypes,
} from '../../modules/absenceTypes/absenceTypeSlice';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AbsenceTypeForm from './AbsenceTypeForm';
import { ABSENCE_TYPE_RESOURCE } from '../../modules/api/resources';
import { useCan } from '../../modules/abilities/hooks';
import { UPDATE } from '../../modules/abilities/actions';

const AbsenceTypeSelect = ({ name, ...other }) => {
    const { t } = useTranslation();
    const { loading, initialized } = useAbsenceTypes();

    /* for admin-panel settings */
    const [selectedAdminEditItemId, setSelectedAdminEditItemId] = useState(null);
    const [selectedAdminEditItemLoading, setSelectedAdminEditItemLoading] = useState(false);
    const canAdminEdit = useCan(UPDATE, ABSENCE_TYPE_RESOURCE);
    const adminFormPopupRef = useRef();

    const absenceTypes = useSelector(selectAllAbsenceTypes);

    const options = useMemo(
        () =>
            absenceTypes.map(({ id, name }) => ({
                label: name,
                value: id,
                onEdit: () => setSelectedAdminEditItemId(id),
            })),
        [absenceTypes]
    );

    return (
        <Select
            name={name}
            options={options}
            disabled={loading && !initialized}
            label={t('components.AbsenceType.title')}
            ItemEditFormPopupProps={{
                component: (
                    <AbsenceTypeForm
                        absenceTypeId={selectedAdminEditItemId}
                        asPopup
                        popupStateRef={adminFormPopupRef}
                        onLoading={loading => setSelectedAdminEditItemLoading(loading)}
                    />
                ),
                canEdit: canAdminEdit,
                selectedEditItemValue: selectedAdminEditItemId,
                isLoading: selectedAdminEditItemLoading,
            }}
            {...other}
        />
    );
};

AbsenceTypeSelect.propTypes = {
    name: PropTypes.string.isRequired,
};

export default AbsenceTypeSelect;
