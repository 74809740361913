import { CHECKLIST_RESOURCE } from '../api/resources';

export const indexChecklists = params => (dispatch, getState, api) =>
    api[CHECKLIST_RESOURCE].index(params);

export const showChecklist = params => (dispatch, getState, api) =>
    api[CHECKLIST_RESOURCE].show(params);

export const storeChecklist = params => (dispatch, getState, api) =>
    api[CHECKLIST_RESOURCE].store(params);

export const updateChecklist = params => (dispatch, getState, api) =>
    api[CHECKLIST_RESOURCE].update(params);

export const destroyChecklist = params => (dispatch, getState, api) =>
    api[CHECKLIST_RESOURCE].destroy({ ...params, forceDelete: true });

export const archiveChecklist = params => (dispatch, getState, api) =>
    api[CHECKLIST_RESOURCE].destroy({ ...params, forceDelete: false });
