import React from 'react';
import * as PropTypes from 'prop-types';
import IconPair from '../icons/IconPair';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { makeStyles } from '@mui/styles';
import Icon from '../icons/Icon';

const useStyles = makeStyles({
    secondPairIconContainer: {
        width: '9px',
        height: '5px',
        marginTop: '10px',
        marginLeft: '-10px',
        backgroundColor: 'white',
        transform: 'rotateY(-180deg)',
    },
    secondPairIcon: {
        fontSize: '1rem',
        fontWeight: 'bolder',
        position: 'absolute',
        top: '-4px',
        left: '0.5px',
        width: '9px',
        direction: 'rtl',
    },
});

/* TODO: Custom this component to make icons adjust dynamically/ automatically when 'fontSize' increase */
const IncomingMailIcon = ({ fontSize, color, ...other }) => {
    const classes = useStyles();

    return (
        <IconPair
            PrimaryIcon={_ => <MailOutlineIcon fontSize={fontSize} color={color} {...other} />}
            SecondaryIcon={_ => (
                <div className={classes.secondPairIconContainer}>
                    <Icon type="arrow_right_alt" className={classes.secondPairIcon} color={color} />
                </div>
            )}
        />
    );
};

IncomingMailIcon.propTypes = {
    fontSize: PropTypes.string,
    color: PropTypes.string,
};

IncomingMailIcon.defaultProps = {
    fontSize: 'medium',
    color: 'inherit',
};

export default IncomingMailIcon;
