import React from 'react';
import * as PropTypes from 'prop-types';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { SubjectPropType } from '../../modules/abilities/proptypes';
import IconButton from '../form/base/IconButton';

const MoreIconButton = ({ onClick, size, contrast, color, disabled, action, subject }) => (
    <IconButton
        onClick={onClick}
        size={size}
        contrast={contrast}
        color={color}
        disabled={disabled}
        subject={subject}
        action={action}
        data-test-id="MoreIconButton"
    >
        <MoreHorizIcon />
    </IconButton>
);

MoreIconButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    size: PropTypes.string,
    contrast: PropTypes.bool,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    action: PropTypes.string,
    subject: SubjectPropType,
};

MoreIconButton.defaultProps = {
    size: null,
    contrast: false,
    color: 'default',
    disabled: false,
    action: null,
    subject: null,
};

export default MoreIconButton;
