import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import ResourceGroupPicker from './ResourceGroupPicker';
import { formatResourceGroupConfig } from '../../modules/resourceGroup/utils';

const ResourceGroupConfig = ({
    name,
    originalName,
    groupKeys: originalGroupKeys,
    keyName,
    resourceConfig,
}) => {
    const { groupKeys, groupConfig } = useMemo(
        () =>
            formatResourceGroupConfig(
                resourceConfig,
                keyName,
                originalGroupKeys && Array.isArray(originalGroupKeys)
                    ? config => !originalGroupKeys.includes(config[keyName ?? 'group'])
                    : null
            ),
        [resourceConfig, originalGroupKeys]
    );

    return (
        <Grid container>
            {groupKeys.map(groupKey => (
                <ResourceGroupPicker
                    key={`resourceGroupPicker.${groupKey}`}
                    name={name}
                    originalName={originalName}
                    groupKey={groupKey}
                    groupConfig={groupConfig[groupKey]}
                    resourceConfig={resourceConfig}
                />
            ))}
        </Grid>
    );
};

ResourceGroupConfig.propTypes = {
    name: PropTypes.string.isRequired,
    originalName: PropTypes.string,
    resourceConfig: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    groupKeys: PropTypes.arrayOf(PropTypes.string),
    keyName: PropTypes.string,
};

ResourceGroupConfig.defaultProps = {
    originalName: null,
    groupKeys: null,
    keyName: null,
};

export default ResourceGroupConfig;
