import { useCallbackFunc } from '../hooks';
import { useAutoPaginate, useResourceList } from '../lists/hooks';
import { TASK_RESOURCE } from '../api/resources';
import { useSelector } from 'react-redux';
import { convertUTCtoLocalDate } from '../datetime/utils';
import { compareDesc } from 'date-fns';
import { useEffect, useMemo } from 'react';

const checkTask = (task, referenceId) =>
    referenceId ? task[referenceId.key] === referenceId.value : true;

const sortTask = (old, current) => {
    const dueOld = convertUTCtoLocalDate(old.created_at);
    const dueNew = convertUTCtoLocalDate(current.created_at);

    return compareDesc(dueOld, dueNew);
};

export const useTaskLoad = ({
    referenceId,
    fetchParams = {},
    overrideListId = null,
    listIdKey = 'search',
    autoload = true,
    autoPaginate = false,
}) => {
    const stableCheckTask = useCallbackFunc(checkTask);
    const stableSortTask = useCallbackFunc(sortTask);

    const listId =
        overrideListId || referenceId
            ? `Tasks.${referenceId?.key}.${referenceId?.value}.${listIdKey}`
            : `Tasks.${listIdKey}`;

    const {
        dataIds: taskIds,
        fullSelector,
        handlePage,
        handleNextPage,
        loading,
        initialized,
    } = useResourceList({
        listId,
        resource: TASK_RESOURCE,
        criteria: {
            check: task => stableCheckTask(task, referenceId),
            compare: stableSortTask,
        },
        continuous: true,
        index: true,
        fetchParams:
            typeof referenceId?.key === 'string' && referenceId.value
                ? {
                      ...fetchParams,
                      [referenceId.key]: referenceId.value,
                  }
                : fetchParams,
    });

    const tasksById = useSelector(state => fullSelector(state));
    const tasks = useMemo(() => Object.values(tasksById), [tasksById]);

    const active = useMemo(() => {
        const noTasks = !tasks || tasks.length === 0;
        const isIntendedValue =
            referenceId?.key === 'card_id' && Number.isInteger(referenceId.value);
        return autoload && !loading && !initialized && isIntendedValue && noTasks;
    }, [tasks, referenceId, autoload, loading, initialized]);

    useAutoPaginate({
        active: autoPaginate && active,
        handlePage,
    });

    useEffect(() => {
        if (!autoPaginate && active) {
            handlePage(null, 1, true);
        }
    }, [autoPaginate, active, handlePage]);

    return useMemo(
        () => ({
            listId,
            taskIds,
            handlePage,
            handleNextPage,
            loading,
            initialized,
            tasks,
        }),
        [listId, taskIds, handlePage, handleNextPage, loading, initialized, tasks]
    );
};
