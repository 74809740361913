import React, { useEffect, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    selectAddressTypesInitialized,
    selectAddressTypesIsLoading,
    selectAllAddressTypes,
} from '../../modules/addressTypes/selectors';
import { indexAddressTypes } from '../../modules/addressTypes/actions';
import Select from './formik/Select';

const AddressTypeSelect = ({ name, label, fullWidth }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const addressTypes = useSelector(selectAllAddressTypes);
    const addressTypesIsLoading = useSelector(selectAddressTypesIsLoading);
    const addressTypesIsInitialized = useSelector(selectAddressTypesInitialized);

    useEffect(() => {
        if (!addressTypesIsInitialized && !addressTypesIsLoading) {
            dispatch(indexAddressTypes());
        }
    }, [dispatch, addressTypesIsLoading, addressTypesIsInitialized]);

    const options = useMemo(() => {
        const _options = addressTypes.map(type => ({
            label: t(`components.Address.${type.name}`),
            value: type.id,
        }));
        _options.unshift({
            label: t('components.Address.noType'),
            value: '',
        });
        return _options;
    }, [addressTypes, t]);

    return <Select name={name} label={label} options={options} fullWidth={fullWidth} />;
};

AddressTypeSelect.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    fullWidth: PropTypes.bool,
};

AddressTypeSelect.defaultProps = {
    label: null,
    fullWidth: false,
};

export default AddressTypeSelect;
