import { CONTACT_DATA_RESOURCE } from '../api/resources';

export const indexContactDatas = params => (dispatch, getState, api) =>
    api[CONTACT_DATA_RESOURCE].index(params);

export const storeContactData = params => (dispatch, getState, api) =>
    api[CONTACT_DATA_RESOURCE].store(params);

export const updateContactData = params => (dispatch, getState, api) =>
    api[CONTACT_DATA_RESOURCE].update(params);

export const destroyContactData = params => (dispatch, getState, api) =>
    api[CONTACT_DATA_RESOURCE].destroy(params);
