import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { getContext } from '@redux-saga/core/effects';
import { APPOINTMENT_RESOURCE, CARD_RESOURCE, TASK_RESOURCE } from '../api/resources';
import { destroyByReferenceIdFulfilled as appointmentsDestroyByReferenceIdFulfilled } from '../appointments/appointmentSlice';
import { createResourceClassName } from '../api/utils';
import {
    selectTaskById,
    selectTaskListByTaskStatusId,
    selectTaskSearchByKey,
    taskListPage,
} from './tasksSlice';
import { indexFulfilled as cardIndexFulfilled } from '../cards/cardsSlice';

const filterDestroyAppointment = item => {
    return item && item.entity_type === createResourceClassName(TASK_RESOURCE);
};

function* nestedDestroyFulfilled({ payload }) {
    yield put(
        appointmentsDestroyByReferenceIdFulfilled(
            {
                referenceKey: 'entityId',
                referenceId: payload,
                onFilter: filterDestroyAppointment,
            },
            { key: APPOINTMENT_RESOURCE }
        )
    );
}

function* reloadLastPageIfSocketEventRemovesTask(action) {
    let task = null;

    if (action.meta.socketReaction) {
        // task is filtered
        if (!action.payload.length && action.meta.params.id) {
            task = yield select(selectTaskById, action.meta.params.id);
        }

        // task is destroyed
        if (action.payload && action.meta.destroyedEntity) {
            task = action.meta.destroyedEntity;
        }
    }

    if (task && task.task_status_id) {
        const list = yield select(selectTaskListByTaskStatusId, task.task_status_id);

        try {
            yield put(taskListPage(task.task_status_id, list.current_page)); //TODO: with 'key'?
        } catch (e) {}
    }
}

function* nestedIndexFulfilled(action) {
    const cards = action.meta.card?.reduce((carry, card) => {
        if (card) {
            carry.push(card);
        }
        return carry;
    }, []);
    if (cards && cards.length > 0) {
        yield put(cardIndexFulfilled(cards, { key: CARD_RESOURCE }));
    }
}

function* refreshTask(action) {
    if (!action.payload.id) {
        console.error('refreshTask action payload is missing an id!', action);
        return;
    }

    const api = yield getContext('api');

    const search = yield select(selectTaskSearchByKey); //TODO: with 'key'?

    yield api[TASK_RESOURCE].search(
        {
            id: action.payload.id,
            contexts: action.meta.contexts,
            ...search,
            filters: {
                $and: [
                    ...((search.filters && search.filters.$and) || []),
                    { name: '_id', id: action.payload.id, by_id: true },
                ],
            },
        },
        { socketReaction: true, staleModel: action.meta.staleModel }
    );
}

export default [
    takeEvery(`${TASK_RESOURCE}/destroyFulfilled`, nestedDestroyFulfilled),

    takeLatest(`${TASK_RESOURCE}/indexFulfilled`, reloadLastPageIfSocketEventRemovesTask),
    takeLatest(`${TASK_RESOURCE}/destroyFulfilled`, reloadLastPageIfSocketEventRemovesTask),
    takeEvery(`${TASK_RESOURCE}/searchFulfilled`, nestedIndexFulfilled),
    takeEvery(`${TASK_RESOURCE}/storeSocket`, refreshTask),
    takeEvery(`${TASK_RESOURCE}/updateSocket`, refreshTask),
];
