import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import {
    APPOINTMENT_CALENDAR_CONTEXT_FUNC_TYPE,
    useAppointmentCalendar,
} from '../AppointmentCalendarContext';

const CalendarFormActions = ({ onCommitButtonClick, onDeleteButtonClick }) => {
    const { attachFunc } = useAppointmentCalendar();

    useEffect(() => {
        if (attachFunc && onCommitButtonClick) {
            attachFunc(APPOINTMENT_CALENDAR_CONTEXT_FUNC_TYPE.COMMIT, onCommitButtonClick);
        }
    }, [onCommitButtonClick, attachFunc]);

    useEffect(() => {
        if (attachFunc && onDeleteButtonClick) {
            attachFunc(APPOINTMENT_CALENDAR_CONTEXT_FUNC_TYPE.DELETE, onDeleteButtonClick);
        }
    }, [onDeleteButtonClick, attachFunc]);

    return null;
};

CalendarFormActions.propTypes = {};

export default CalendarFormActions;
