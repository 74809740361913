import React from 'react';
import PropTypes from 'prop-types';
import { autocompleteCards } from '../../../../modules/cards/actions';
import TypedAutocomplete from './TypedAutocomplete';

const BaseCardAutocomplete = ({
    name,
    value,
    label,
    variant,
    contrast,
    onChange,
    fullWidth,
    clearOnBlur,
    blurOnSelect,
    size,
    ...other
}) => (
    <TypedAutocomplete
        name={name}
        fetchAction={autocompleteCards}
        value={value}
        onChange={onChange}
        label={label}
        fullWidth={fullWidth}
        contrast={contrast}
        size={size}
        variant={variant}
        blurOnSelect={blurOnSelect}
        clearOnBlur={clearOnBlur}
        {...other}
    />
);

BaseCardAutocomplete.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    variant: PropTypes.string,
    contrast: PropTypes.bool,
    size: PropTypes.string,
    fullWidth: PropTypes.bool,
    onChange: PropTypes.func,
    clearOnBlur: PropTypes.bool,
    blurOnSelect: PropTypes.bool,
};

BaseCardAutocomplete.defaultProps = {
    name: null,
    value: null,
    label: undefined,
    variant: 'outlined',
    contrast: false,
    fullWidth: false,
    onChange: undefined,
    clearOnBlur: false,
    blurOnSelect: false,
    size: 'medium',
};

export default BaseCardAutocomplete;
