import React, { memo, useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { Box, Button, DialogActions, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Can from '../../modules/abilities/Can';
import { DELETE } from '../../modules/abilities/actions';
import DestroyIntent from '../form/DestroyIntent';
import { selectCardById } from '../../modules/cards/selectors';
import { archiveCard as archiveCardAction } from '../../modules/cards/actions';
import { IdPropType } from '../../modules/proptypes';
import { useDialogControl } from '../dialogs/DialogControlContext';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        width: 'calc(100% - 18px)',
        height: 38,
        bottom: 0,
        padding: 3,
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        pointerEvents: 'none',
        background: `linear-gradient(90deg, ${theme.palette.grey[100]} 220px, transparent 220px)`,
    },

    destroy: {
        pointerEvents: 'all',
    },
    close: {
        pointerEvents: 'all',
        backgroundColor: theme.palette.background.paper,
        boxShadow: `0 0 8px 4px ${theme.palette.background.paper}`,
    },
}));

const CardModalActions = ({ cardId, onClose, setTab }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const card = useSelector(state => selectCardById(state, cardId));
    const dispatch = useDispatch();
    const { confirmClose } = useDialogControl();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const isMaster = card && !card.parent_id && card.children.length;

    const destroyCard = useCallback(() => {
        if (isMaster) {
            const key = enqueueSnackbar(
                <Typography>
                    {t('components.CardModal.failureMessageDeleteMasterCard_start')}
                    <Link component="button" onClick={() => setTab('cardlinks')}>
                        {t('components.ContactDialog.links')}
                    </Link>
                    {t('components.CardModal.failureMessageDeleteMasterCard_end')}
                </Typography>,
                {
                    variant: 'info',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                    onClick: () => {
                        closeSnackbar(key);
                    },
                }
            );
            return;
        }

        return dispatch(archiveCardAction({ id: cardId }))
            .then(onClose)
            .catch(() => null);
    }, [cardId, dispatch, onClose, isMaster, enqueueSnackbar, closeSnackbar, t, setTab]);

    return (
        <DialogActions className={classes.root}>
            <Box className={classes.destroy}>
                {card && (
                    <Can I={DELETE} this={card}>
                        <DestroyIntent
                            onConfirmed={destroyCard}
                            labelConfirm={t('components.CardModal.destroyIntent')}
                            contrast
                        />
                    </Can>
                )}
            </Box>
            <Box className={classes.close}>
                {confirmClose ? (
                    <DestroyIntent
                        onConfirmed={onClose}
                        label="Schließen"
                        labelConfirm={t('components.CardModal.closeIntent')}
                        setYesLeft
                        noDanger
                    />
                ) : (
                    <Button onClick={onClose} data-test-id="CardModalClose">
                        Schließen
                    </Button>
                )}
            </Box>
        </DialogActions>
    );
};

CardModalActions.propTypes = {
    cardId: IdPropType,
    onClose: PropTypes.func.isRequired,
};

CardModalActions.defaultProps = {
    cardId: null,
};

export default memo(CardModalActions);
