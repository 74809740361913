import * as PropTypes from 'prop-types';

// TODO define address
export const AddressPropType = PropTypes.shape({
    id: PropTypes.number,
    id_ext: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    // zip
    // city
    // state
    // country
    latitude: PropTypes.number,
    longitude: PropTypes.number,
});
