import { JOURNAL_RESOURCE } from '../api/resources';

export const selectAllJournalIds = state => {
    return state[JOURNAL_RESOURCE].allIds;
};

export const selectJournalsById = state => state[JOURNAL_RESOURCE].byId;

export const selectJournalById = (state, id) => selectJournalsById(state)[id];

export const selectCardJournals = (state, cardId) => state[JOURNAL_RESOURCE].byCard[cardId];

export const selectCardCached = (state, cardId) =>
    state[JOURNAL_RESOURCE].initialized.includes(cardId);
