import React from 'react';
import PropTypes from 'prop-types';
import AbortButton from '../AbortButton';
import { useDialogControl } from '../../dialogs/DialogControlContext';

const FormDialogAbortButton = ({ onClick }) => {
    const { onClose, confirmClose, setConfirmClose } = useDialogControl();

    const handleClick = () => {
        setConfirmClose(false);

        if (onClick) {
            onClick();
        } else {
            onClose();
        }
    };

    return <AbortButton onClick={handleClick} confirm={confirmClose} />;
};

FormDialogAbortButton.propTypes = {
    onClick: PropTypes.func,
};

FormDialogAbortButton.defaultProps = {
    onClick: null,
};

export default FormDialogAbortButton;
