import React from 'react';
import { EditorState } from 'draft-js';
import { findEditorElements, getElementData } from './element';

export const EDITOR_LINK = 'LINK';

export const EditorLink = props => <a href={getElementData(props).url}>{props.children}</a>;

export const findLinkElements = (contentBlock, callback, contentState) =>
    findEditorElements(contentBlock, callback, contentState, EDITOR_LINK);

export const makeLink = (editorState, url) => {
    const contentState = editorState.getCurrentContent();
    const entity = contentState.createEntity('LINK', 'MUTABLE', { url });
    const newState = EditorState.set(editorState, { currentContent: entity });
    return { editorState: newState, entity };
};
