import { capitalizeFirstLetter } from '../text/utils';

export const calcPosition = (newItem, items, { check, compare, prepare }, state) => {
    const include = check ? check(newItem, state) : true;

    if (!include) {
        return -1;
    }

    const prepped = prepare ? prepare(items, state) : items;
    return prepped.reduce((position, item, index) => {
        if (index !== position) {
            return position;
        }

        if (item.id === newItem.id) {
            return index + 1;
        }

        return compare(item, newItem, state) < 0 ? index + 1 : index;
    }, 0);
};

export const getFromNestedObject = (nestedSteps, layer) =>
    nestedSteps.reduce(
        (carry, step) => {
            if (carry?.layer && step in carry.layer) {
                return {
                    ...carry,
                    layer: carry.layer[step],
                };
            }

            return {
                ...carry,
                missingSteps: [...carry.missingSteps, step],
            };
        },
        { layer, missingSteps: [] }
    );

export const applyToNestedObject = (nestedSteps, nestedObject, newValue, missingSteps = []) =>
    nestedSteps.reverse().reduce((carry, step, index) => {
        if (missingSteps.includes(step)) {
            return {
                [step]: carry,
            };
        }

        return {
            ...getFromNestedObject(nestedSteps.slice(0, index), nestedObject),
            [step]: carry,
        };
    }, newValue);

export const formatModelResource = model => {
    if (typeof model === 'string') {
        if (model.includes('_')) {
            const words = model.split('_');
            const capitalizedWords = words.map(word => capitalizeFirstLetter(word));
            return capitalizedWords.join('');
        }

        return capitalizeFirstLetter(model);
    }

    return null;
};
