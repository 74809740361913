import React, { memo, useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import AsyncAutocomplete from '../../base/autocomplete/AsyncAutocomplete';
import { autocompleteTasks } from '../../../../modules/tasks/tasksSlice';
import { useFieldFast } from '../../../../modules/form/hooks';
import { getFormFieldError } from '../../../../modules/loading/utils';

const useStyles = makeStyles(theme => ({
    label: {
        paddingRight: theme.spacing(1),

        '& em': {
            fontWeight: 700,
            fontStyle: 'normal',
        },
    },
}));

const TaskTemplateAutocomplete = ({ name, nameId, label, size, initialValue, I, ...other }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [, meta, { setValue: setName, setError }] = useFieldFast(name);
    const [, , { setValue: setId }] = useFieldFast(nameId);

    const error = getFormFieldError(name, {}, meta);
    const handleError = useCallback(
        err => {
            if (err !== meta.error) {
                setError(err);
            }
        },

        [setError, meta.error]
    );

    const getValue = useCallback(
        () => initialValue,
        // eslint-disable-next-line
        [initialValue]
    );

    const handleFetch = useCallback(
        query => {
            return dispatch(
                autocompleteTasks({
                    q: `Vorgang: ${query}`,
                    card_id: 'null',
                    datacontainer_id: 'null',
                })
            ).then(({ data }) => {
                setName(query);
                setId('');
                return data.suggestions || data;
            });
        },
        [dispatch, setName, setId]
    );

    const handleSelect = useCallback(
        (event, newValue) => {
            setId(newValue.id);
        },
        [setId]
    );

    return (
        <AsyncAutocomplete
            name={name}
            initialValue={getValue}
            fetch={handleFetch}
            renderOption={(props, task) => (
                <li {...props}>
                    <Typography
                        dangerouslySetInnerHTML={{ __html: task.highlight || task.value }}
                        className={classes.label}
                    />
                </li>
            )}
            getOptionLabel={option => (typeof option === 'string' ? option : option.value || '')}
            onChange={handleSelect}
            label={label}
            size={size}
            data-test-id="TaskTemplateAutocomplete-name"
            error={error}
            handleError={handleError}
            can={I}
            {...other}
        />
    );
};

TaskTemplateAutocomplete.propTypes = {
    name: PropTypes.string.isRequired,
    nameId: PropTypes.string.isRequired,
    label: PropTypes.string,
    size: PropTypes.string,
    I: PropTypes.string,
};

TaskTemplateAutocomplete.defaultProps = {
    label: '',
    size: 'medium',
    I: null,
};

export default memo(TaskTemplateAutocomplete);
