export const pluralize = resource => {
    if (resource.endsWith('y')) {
        return resource.replace(/y$/, 'ies');
    }

    if (resource.endsWith('s')) {
        return resource.replace(/s$/, 'ses');
    }

    return `${resource}s`;
};
export const uppercaseFirst = str => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
export const lowercaseFirst = str => `${str.charAt(0).toLowerCase()}${str.slice(1)}`;

export const asStateKey = byKey => `by${uppercaseFirst(byKey)}`;
export const asInitializedKey = byKey => `initializedBy${uppercaseFirst(byKey)}`;
export const asStateSelectorSuffix = byKey =>
    uppercaseFirst(asStateKey(byKey)).replace(/Ids$/, 'Id');

export const getResourceIdField = resource =>
    `${resource.charAt(0).toLowerCase()}${resource.slice(1)}Id`;

export const isAdditionalPresent = (item, additional) => {
    if (!additional || !item) {
        return true;
    }

    return additional.reduce((carry, field) => carry && !!item[field], true);
};

export const getWithKeys = config => {
    return Array.isArray(config) ? config : Object.keys(config);
};

export const combineWith = (...values) => {
    return values.reduce((carry, value) => {
        if (value) {
            carry.push(...getWithKeys(value));
        }

        return carry;
    }, []);
};

const getRelated = (item, key) => {
    const related = item[key];
    if (!(related && '__type' in item[key])) {
        return [];
    }

    // eslint-disable-next-line no-param-reassign
    delete item[key];
    return Array.isArray(related) ? related : [related];
};

export const extractRelated = (relatedKeys, data) => {
    const byKey = {};

    relatedKeys.forEach(key => {
        const items = Array.isArray(data)
            ? data.reduce((carry, item) => {
                  carry.push(...getRelated(item, key));
                  return carry;
              }, [])
            : getRelated(data, key);
        if (items.length > 0) {
            byKey[key] = items;
        }
    });

    return byKey;
};

export const toCamelCase = str => {
    return str.replace(/_([a-z])/g, g => g[1].toUpperCase());
};

export const toSnakeCase = str => {
    return str
        .match(/([A-Z])/g)
        .reduce((sub, c) => sub.replace(new RegExp(c), `_${c.toLowerCase()}`), str)
        .substring(str.slice(0, 1).match(/([A-Z])/g) ? 1 : 0);
};
