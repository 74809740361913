import React, { forwardRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import classNames from 'classnames';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { IdPropType } from '../../../modules/proptypes';
import ClickBoundary from '../../form/ClickBoundary';
import EditIconButton from '../../buttons/EditIconButton';
import { useProtocol } from '../../../modules/protocol/protocolsSlice';
import CreateIconButton from '../../buttons/CreateIconButton';
import { PROTOCOL_RESOURCE } from '../../../modules/api/resources';

const isIPad = () => {
    if (/iPad|iPhone|iPod/.test(navigator.platform)) {
        /* old ipads */
        return true;
    }
    if (/Macintosh/.test(navigator.userAgent) && 'ontouchend' in document) {
        /* ipad pro */
        return true;
    }

    /* ipad */
    return (
        navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2 &&
        /MacIntel/.test(navigator.platform)
    );
};

const DISABLE_HOVER = isIPad();

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        textAlign: 'left',

        [DISABLE_HOVER ? 'disabledOnMobile' : '&:hover $hoverActions']: {
            opacity: 1,
        },
    },

    actions: {
        background: theme.palette.grey[100],
        clipPath: 'inset(0px 0px 0px -21px)',
        boxShadow: `0px 0px 10px 10px ${theme.palette.grey[100]}`,
        position: 'absolute',
        top: 10,
        right: theme.spacing(2),

        opacity: 0,

        [DISABLE_HOVER ? 'disabledOnMobile' : 'transition']: theme.transitions.create(['opacity'], {
            duration: theme.transitions.duration.shortest,
        }),
    },

    hoverActions: {},
}));

const ProtocolTabActions = forwardRef(
    // eslint-disable-next-line no-unused-vars
    ({ protocolId, index, placeholder, children, className, onEdit, onCreate, ...other }, ref) => {
        const classes = useStyles();
        const [protocol] = useProtocol(protocolId);

        const handleCreate = useCallback(() => onCreate(), [onCreate]);
        const handleEdit = useCallback(() => onEdit(protocolId), [onEdit, protocolId]);

        return (
            <Box ref={ref} className={classNames(className, classes.root)} {...other}>
                <Box className={classNames(classes.actions, classes.hoverActions)}>
                    <Grid container spacing={1}>
                        <Grid item>
                            <ClickBoundary inline>
                                {onCreate && (
                                    <CreateIconButton
                                        onClick={handleCreate}
                                        size="small"
                                        subject={PROTOCOL_RESOURCE}
                                    />
                                )}
                                {onEdit && (
                                    <EditIconButton
                                        onClick={handleEdit}
                                        size="small"
                                        subject={protocol}
                                    />
                                )}
                            </ClickBoundary>
                        </Grid>
                    </Grid>
                </Box>
                {children}
            </Box>
        );
    }
);

ProtocolTabActions.propTypes = {
    protocolId: IdPropType,
    index: PropTypes.number,
    onEdit: PropTypes.func,
    onCreate: PropTypes.func,
    className: PropTypes.string,
    placeholder: PropTypes.node,
    children: PropTypes.node,
};

ProtocolTabActions.defaultProps = {
    protocolId: null,
    index: 1,
    className: null,
    placeholder: null,
    children: null,
    onEdit: null,
    onCreate: null,
};

export default ProtocolTabActions;
