import React from 'react';
import * as PropTypes from 'prop-types';
import IconButton from '../form/base/IconButton';
import PlaceIcon from '@mui/icons-material/Place';

const AddressViewIconButton = ({ full, latitude, longitude }) => {
    const showLocation = () => {
        if (!latitude || !longitude || Number.isNaN(latitude) || Number.isNaN(longitude)) {
            window
                .open(`https://www.google.com/maps/search/${encodeURIComponent(full)}/`, '_blank')
                .focus();
        } else {
            window
                .open(`https://www.google.com/maps/search/${latitude}, ${longitude}/`, '_blank')
                .focus();
        }
    };

    return (
        <IconButton size="small" onClick={showLocation}>
            <PlaceIcon color="primary" fontSize="24px" />
        </IconButton>
    );
};

AddressViewIconButton.propTypes = {
    full: PropTypes.string.isRequired,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
};

AddressViewIconButton.defaultProps = {
    latitude: null,
    longitude: null,
};

export default AddressViewIconButton;
