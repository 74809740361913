import { CONTACT_TYPE_RESOURCE } from '../api/resources';

export const indexContactTypes = params => (dispatch, getState, api) =>
    api[CONTACT_TYPE_RESOURCE].index(params);

export const storeContactType = contactType => (dispatch, getState, api) =>
    api[CONTACT_TYPE_RESOURCE].store(contactType);

export const updateContactType = contactType => (dispatch, getState, api) =>
    api[CONTACT_TYPE_RESOURCE].update(contactType);

export const destroyContactType = params => (dispatch, getState, api) =>
    api[CONTACT_TYPE_RESOURCE].destroy({ ...params, forceDelete: true });
