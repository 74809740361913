import React from 'react';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import PropTypes from 'prop-types';
import FormHelperText from '@mui/material/FormHelperText';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { withPrefix } from '../../../modules/form/utils';
import { useContextualCanWrite } from '../../../modules/abilities/hooks';
import ContextualCan from '../../../modules/abilities/ContextualCan';
import { READ } from '../../../modules/abilities/actions';
import { useTranslatedOptions } from '../../../modules/form/hooks';

const useStyles = makeStyles(theme => ({
    formControlHorizontalFullWidth: {
        width: '100%',
    },
    radioGroupHorizontal: {
        flexDirection: 'row',
    },
    labelHorizontal: {
        marginRight: theme.spacing(4),
    },
}));

const BaseRadioInput = ({
    name,
    value,
    label,
    options: overrideOptions,
    translation,
    error,
    onChange,
    empty,
    disabled,
    horizontal,
    fullWidth,
    allowEmpty,
    prefix,
}) => {
    const prefixedName = withPrefix(name, prefix);
    const classes = useStyles();
    const canWrite = useContextualCanWrite(prefixedName);

    const options = useTranslatedOptions(name, overrideOptions, translation, allowEmpty);

    return (
        <ContextualCan I={READ} field={prefixedName}>
            <FormControl
                component="fieldset"
                className={classNames({
                    [classes.formControlHorizontalFullWidth]: horizontal && fullWidth,
                })}
            >
                {label && <FormLabel component="legend">{label}</FormLabel>}
                <RadioGroup
                    aria-label={prefixedName}
                    name={prefixedName}
                    value={value}
                    onChange={onChange}
                    className={classNames({ [classes.radioGroupHorizontal]: horizontal })}
                >
                    {empty && (
                        <FormControlLabel
                            value=""
                            control={<Radio color="default" />}
                            label={empty}
                            disabled={disabled || !canWrite}
                            className={classNames({ [classes.labelHorizontal]: horizontal })}
                        />
                    )}
                    {options.map(option => (
                        <FormControlLabel
                            key={option.value}
                            value={option.value}
                            control={<Radio color="primary" />}
                            label={option.label}
                            disabled={disabled || !canWrite}
                            className={classNames({ [classes.labelHorizontal]: horizontal })}
                        />
                    ))}
                </RadioGroup>
                {error && <FormHelperText error>{error}</FormHelperText>}
            </FormControl>
        </ContextualCan>
    );
};

BaseRadioInput.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
    onChange: PropTypes.func,
    label: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
                .isRequired,
        })
    ),
    translation: PropTypes.string,
    error: PropTypes.string,
    empty: PropTypes.string,
    disabled: PropTypes.bool,
    horizontal: PropTypes.bool,
    fullWidth: PropTypes.bool,
    allowEmpty: PropTypes.bool,
    prefix: PropTypes.string,
};

BaseRadioInput.defaultProps = {
    options: null,
    translation: null,
    onChange: null,
    label: null,
    error: null,
    empty: null,
    disabled: false,
    horizontal: false,
    fullWidth: false,
    allowEmpty: false,
    prefix: null,
};

export default BaseRadioInput;
