import { PROTOCOL_ITEM_RESOURCE } from '../api/resources';

export const indexProtocolItems = params => (dispatch, getState, api) =>
    api[PROTOCOL_ITEM_RESOURCE].index(params);

export const showProtocolItem =
    (params, meta = {}) =>
    (dispatch, getState, api) =>
        api[PROTOCOL_ITEM_RESOURCE].show(params, meta);

export const storeProtocolItem =
    (protocolItem, meta = {}) =>
    (dispatch, getState, api) =>
        api[PROTOCOL_ITEM_RESOURCE].store(protocolItem, meta);

export const updateProtocolItem =
    (protocolItem, meta = {}) =>
    (dispatch, getState, api) =>
        api[PROTOCOL_ITEM_RESOURCE].update(protocolItem, meta);

export const archiveProtocolItem = params => (dispatch, getState, api) =>
    api[PROTOCOL_ITEM_RESOURCE].destroy({ ...params, forceDelete: false });
