import React, { forwardRef } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import TextInput from './TextInput';
import { formatDuration, parseDuration } from '../../../modules/datetime/utils';

const useStyles = makeStyles(theme => ({
    short: {
        maxWidth: 'calc(5ch + 1.75rem)',
    },
}));

const DurationInput = forwardRef(({ name, label, short, I, ...other }, ref) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const handleInput = value => {
        if (!value) {
            return '';
        }

        if (Number.isInteger(value)) {
            return formatDuration(value);
        }

        return value;
    };

    const handleValue = (input, isTextChange) => {
        if (!input) {
            return '';
        }

        if (isTextChange) {
            return input || '';
        }

        return parseDuration(input || 0);
    };

    return (
        <Box className={classNames({ [classes.short]: short })}>
            <TextInput
                name={name}
                type="text"
                label={label || t('components.DurationInput.label')}
                can={I}
                onFormatInput={handleInput}
                onFormatValue={handleValue}
                ref={ref}
                {...other}
            />
        </Box>
    );
});

DurationInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    short: PropTypes.bool,
    I: PropTypes.string,
};

DurationInput.defaultProps = {
    label: PropTypes.string,
    short: false,
    I: null,
};

export default DurationInput;
