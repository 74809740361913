import { useState } from 'react';

const { useEffect } = require('react');
const { useCallback } = require('react');
const { useRef } = require('react');

export const useTimeout = (callback, timeout = 0) => {
    const timeoutIdRef = useRef();
    const cancel = useCallback(() => {
        const timeoutId = timeoutIdRef.current;
        if (timeoutId) {
            timeoutIdRef.current = undefined;
            clearTimeout(timeoutId);
        }
    }, [timeoutIdRef]);

    useEffect(() => {
        timeoutIdRef.current = setTimeout(callback, timeout);
        return cancel;
    }, [callback, timeout, cancel]);

    return cancel;
};

export const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        const handler = (...args) => savedCallback.current(...args);

        if (delay !== null) {
            const id = setInterval(handler, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
};

export const useCallbackRef = () => {
    const [, setCurrent] = useState(null);
    const ref = useCallback(
        node => {
            setCurrent(node);
            ref.current = node;
        },
        [setCurrent]
    );

    return ref;
};

export const useCallbackFunc = func => {
    const latestRef = useRef({});

    // On every render save newest function to latestRef
    latestRef.current.func = func;

    // On the first render create new function which will never change
    // but call newest function
    if (!latestRef.current.stable) {
        latestRef.current.stable = (...args) => latestRef.current.func(...args);
    }

    return latestRef.current.stable;
};

export const useElementSize = ({ ref, onChange }) => {
    const observer = useRef(
        new ResizeObserver(entries => {
            onChange({
                height: Math.round(entries[0].contentRect.height),
                width: Math.round(entries[0].contentRect.width),
            });
        })
    );

    useEffect(() => {
        if (observer.current && ref.current) {
            observer.current.observe(ref.current);

            return () => {
                if (ref.current) {
                    observer.current.unobserve(ref.current);
                }
            };
        }
    }, [ref.current, observer]);
};
