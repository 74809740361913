import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import Button from '../form/base/Button';
import { UPDATE } from '../../modules/abilities/actions';
import { SubjectPropType } from '../../modules/abilities/proptypes';

const UnlinkButton = ({ onClick, disabled, subject, color }) => {
    const { t } = useTranslation();

    return (
        <Button
            onClick={onClick}
            startIcon={<LinkOffIcon />}
            disabled={disabled}
            action={UPDATE}
            subject={subject}
            data-test-id="UnlinkButton"
            color={color}
        >
            {t('components.UnlinkButton')}
        </Button>
    );
};

UnlinkButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    color: PropTypes.string,
    subject: SubjectPropType,
};

UnlinkButton.defaultProps = {
    disabled: false,
    subject: null,
    color: 'default',
};

export default UnlinkButton;
