/* eslint-disable no-param-reassign */

import pathJoin from 'path.join';
import { getTime } from 'date-fns';

export const FILE_STATUS = {
    RAW: 'raw',
    PROCESSING: 'processing',
    COMPLETED: 'completed',
    UPLOADED: 'uploaded',
    IMPORTED: 'imported',
};

const DIRECTORY_TYPE = 'directory';

export const createNode = (path, node, other) => ({
    ...node,
    path,
    isDirectory: node.type === DIRECTORY_TYPE,
    children: node.type === DIRECTORY_TYPE ? [] : null,
    initialized: false,
    loading: false,
    ...other,
});

export const updateNode = (node, children, other) => ({
    ...node,
    initialized: true,
    loading: false,
    ...other,
    children: children.map(child => child.filename),
});

export const createDirectory = (path, name, setNodes) => {
    const paths = [];
    setNodes(prev => {
        const result = name
            .split('/')
            /* remove empty parts caused by leading/trailing slashes */
            .filter(part => !!part)
            .reduce(
                ({ parentPath, nodes }, part) => {
                    const childPath = pathJoin(parentPath, part, '/');
                    const child =
                        nodes[childPath] ||
                        createNode(
                            childPath,
                            {
                                basename: part,
                                size: 0,
                                type: DIRECTORY_TYPE,
                                intended: true,
                            },
                            /* pretend it was already initialized */
                            { initialized: true }
                        );
                    const parent = nodes[parentPath];
                    paths.push(childPath);
                    return {
                        parentPath: childPath,
                        nodes: {
                            ...nodes,
                            [parentPath]: {
                                ...parent,
                                children: parent.children.includes(childPath)
                                    ? parent.children
                                    : [...parent.children, childPath],
                            },
                            [childPath]: child,
                        },
                    };
                },
                { parentPath: path, nodes: prev }
            );
        return result.nodes;
    });
    return paths;
};

export const initializeNode = (pathOrPaths, nodes, setNodes, cloud, refresh = false) => {
    const paths = Array.isArray(pathOrPaths) ? pathOrPaths : [pathOrPaths];

    const isNew = paths.reduce((carry, path) => {
        if (!nodes[path]) {
            /* must be set directly to prevent concurrency issues */
            nodes[path] = createNode(path, {
                type: DIRECTORY_TYPE,
            });
            return true;
        }
        return carry;
    }, false);

    const { isChanged, results } = paths.reduce(
        (carry, path) => {
            const node = nodes[path];

            if ((!node.initialized || refresh) && !node.loading) {
                nodes[path].loading = true; /* must be set directly to prevent concurrency issues */

                carry.results.push(
                    cloud
                        .directory(node.path)
                        .then(dir => ({ dir, path }))
                        .catch(error => {
                            return { error, path };
                        })
                );
                carry.isChanged = true;
            }

            return carry;
        },
        { isChanged: false, results: [] }
    );

    if (isNew || isChanged) {
        setNodes({ ...nodes });

        return Promise.all(results).then(result => {
            const err = result.reduce((carry, { dir = [], path, error }) => {
                dir.forEach(entry => {
                    nodes[entry.filename] = createNode(entry.filename, entry);
                });

                nodes[path] = updateNode(nodes[path], dir, { error });

                return carry || error;
            }, false);

            setNodes({ ...nodes });

            if (err) {
                throw err;
            }
        });
    }

    return Promise.resolve();
};

export const groupPathsByType = (paths, nodes) =>
    paths.reduce(
        ([dirs, files], path) => {
            if (nodes[path].type === DIRECTORY_TYPE) {
                dirs.push(path);
            } else {
                files.push(path);
            }
            return [dirs, files];
        },
        [[], []]
    );

export const getNodeSortValue = nodes => (path, orderBy) => {
    const node = nodes[path];

    switch (orderBy) {
        case 'name':
            return node.filename.toLowerCase();
        case 'modified':
            return getTime(new Date(node.lastmod));
        default:
            return node.filename;
    }
};

export const getFileName = filename => {
    const parts = filename.endsWith('/')
        ? filename.substring(0, filename.length - 1).split('/')
        : filename.split('/');
    const last = parts.pop();
    return last.length > 0 ? last : '/';
};

export const getFilePath = (filename, prefix = null) => {
    const parts = filename.split('/');
    parts.pop(); // remove file name

    if (parts.length === 1 && parts[0] === '') {
        return prefix ? pathJoin(prefix, '/') : '/';
    }

    return prefix ? pathJoin(prefix, parts.join('/')) : parts.join('/');
};

export const formatFileSize = filesize => {
    const kb = filesize / 1024;
    if (kb < 100) {
        return `${kb.toFixed(2)} KB`;
    }

    const mb = kb / 1024;
    if (mb < 100) {
        return `${mb.toFixed(2)} MB`;
    }

    const gb = mb / 1024;
    return `${gb.toFixed(2)} GB`;
};

export const toAbsolutePath = path => (path && path[0] === '/' ? path : `/${path}`);
