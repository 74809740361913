import React, { useEffect, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { IdPropType } from '../../modules/proptypes';
import { selectCardById } from '../../modules/cards/selectors';
import { showCard } from '../../modules/cards/actions';
import { CardPropType } from '../../modules/cards/proptypes';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

const CardIdentifierDisplay = ({ cardId, rawCard, multiLine, compact, ensureLoad }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const card = useSelector(state => selectCardById(state, cardId));

    const finalCard = useMemo(() => {
        if (cardId) {
            return card;
        }

        if (rawCard) {
            return rawCard;
        }

        console.warn('CardIdentifierDisplay needs either a cardId or rawCard prop');
        return null;
    }, [cardId, rawCard, card]);

    useEffect(() => {
        if (ensureLoad && cardId && !finalCard) {
            dispatch(showCard({ id: cardId }));
        }
    }, [ensureLoad, cardId, finalCard, dispatch]);

    if (!finalCard) {
        return null;
    }

    return compact ? (
        finalCard.id_order || finalCard.id_offer
    ) : (
        <>
            <Tooltip title={t('components.Card.idOrder')} disableInteractive>
                <span>{finalCard.id_order}</span>
            </Tooltip>
            {finalCard.id_order && finalCard.id_offer ? <> {multiLine ? <br /> : ' | '} </> : null}
            <Tooltip title={t('components.Card.idOffer')} disableInteractive>
                <span>{finalCard.id_offer}</span>
            </Tooltip>
        </>
    );
};

CardIdentifierDisplay.propTypes = {
    cardId: IdPropType,
    rawCard: CardPropType,
    multiLine: PropTypes.bool,
    compact: PropTypes.bool,
    ensureLoad: PropTypes.bool,
};

CardIdentifierDisplay.defaultProps = {
    cardId: null,
    rawCard: null,
    multiLine: false,
    compact: false,
    ensureLoad: false,
};

export default CardIdentifierDisplay;
