import React, { useMemo, useRef } from 'react';
import * as PropTypes from 'prop-types';
import {
    APPOINTMENT_RESOURCE,
    EMPLOYEE_RESOURCE,
    RESOURCE_RESOURCE,
} from '../../modules/api/resources';
import Calendar from './Calendar';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CalendarToolbarOptions from './CalendarToolbarOptions';
import { IdPropType } from '../../modules/proptypes';
import AppointmentCalendarContext from './AppointmentCalendarContext';
import { useCalendarAppointments, useDeniedPeriods } from '../../modules/calendar/hooks';
import {
    appointmentResourceMatchSplit,
    assignAppointmentForCalendar,
    formatAppointmentDuplicationParams,
    formatAppointmentExport,
    getDuplicationKeys,
} from '../../modules/appointments/utils';
import {
    destroyAppointment,
    storeAppointment,
    updateAppointment,
} from '../../modules/appointments/appointmentSlice';
import { importDate } from '../../modules/datetime/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useDynamicCan } from '../../modules/abilities/hooks';
import { UPDATE } from '../../modules/abilities/actions';
import {
    checkGeneralOverlap,
    checkOverlap,
    formatFlatResourceKey,
    formatFlatResources,
    getResourceMatchConfig,
    parseFlatAppointmentId,
} from '../../modules/calendar/utils';
import { selectAllAppointmentTypes } from '../../modules/appointmentTypes/appointmentTypeSlice';
import AppointmentAdvanced from './AppointmentAdvanced';
import CalendarLoading from './CalendarLoading';
import CalendarForm from './CalendarForm';
import { useAppointmentKeyManager } from '../../modules/appointments/hooks';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '../../modules/snackbar/hooks';

const useStyles = makeStyles({
    root: {
        position: 'relative',
        height: '100%',
        overflow: 'hidden',
    },
});

/**
 * NOTE:
 * As a little twist, we have, a lot earlier in the process,
 * named models from the database/backend "resource" for our state,
 * but there is also a model instance called "resource".
 * At this point (writing this) the instance should always be named RESOURCE_RESOURCE
 * Both are important here. Keep in mind and have fun!
 *
 * @param calendarId key to distinguish calendars in redux and persistors
 * @param defaultEntityId id of according defaultEntityResource or value for overrideFetchKey
 * @param defaultEntityResource default resource, if overrideFetchKey is set, for creating/editing
 * @param overrideFetchKey when set, fetching can be done using relations without being a direct polymorph relation
 * @param forceCreateValues pre-filled values
 * @param onCheckParams additional checkCriteria
 **/

const AppointmentCalendar = ({
    calendarId,
    defaultEntityId,
    defaultEntityResource,
    overrideFetchKey,
    forceCreateValues,
    createRaised,
    onCheckParams,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const appointmentTypes = useSelector(selectAllAppointmentTypes);
    const pressedKey = useAppointmentKeyManager();

    const resourceMatchConfig = useMemo(
        () => getResourceMatchConfig(appointmentTypes),
        [appointmentTypes]
    );

    const {
        appointmentList,
        originalAppointments,
        appointmentByRowAndDayMeta,
        loading,
        initialized,
        currentDate,
        currentView,
        resourceParams,
        createParams,
        setCurrentDate,
        setSelectedResources,
        setView,
    } = useCalendarAppointments({
        calendarId,
        defaultEntityId,
        defaultEntityResource,
        overrideFetchKey,
        onCheckParams,
        onFormatAppointment: appointment => ({
            ...appointment,
            baseResources: formatFlatResources(appointment, resourceMatchConfig), //ToDo: move to hook, used and needed in all scenarios
        }),
        onSplitAppointment: (appointment, { selectedEmployeeIds, selectedResourceIds }) =>
            appointmentResourceMatchSplit(appointment, selectedEmployeeIds, selectedResourceIds),
        onAssignAppointmentByRow: assignAppointmentForCalendar,
    });

    const { deniedPeriods, denyIds } = useDeniedPeriods(appointmentList);

    const { handleCan } = useDynamicCan();
    const changePermission = ({ originalId }) => {
        const finalAppointment =
            originalId && originalId in originalAppointments
                ? originalAppointments[originalId]
                : APPOINTMENT_RESOURCE;
        return handleCan(UPDATE, finalAppointment, 'ends_at');
    };
    const allowDragResize = useRef((appointment = {}) => changePermission(appointment));

    const handleSubmit = async ({ added, changed, deleted }) => {
        if (added) {
            const formatted = formatAppointmentExport({
                ...added,
                ...createParams,
            });
            dispatch(storeAppointment(formatted));
        }
        if (changed) {
            const duplicationKeys = getDuplicationKeys();
            const isDuplicate = duplicationKeys.includes(pressedKey.current);

            await Promise.all(
                Object.entries(changed).map(([key, values]) => {
                    const {
                        appointmentId: id,
                        resourceEntityId,
                        resourceEntityType,
                    } = parseFlatAppointmentId(key);

                    const original = appointmentList.find(
                        appointment => appointment.id === key || appointment.originalId === id
                    );

                    if (
                        values.startDate &&
                        values.endDate &&
                        checkOverlap(
                            values.startDate,
                            values.endDate,
                            deniedPeriods,
                            formatFlatResourceKey(resourceEntityId, resourceEntityType).key,
                            false,
                            id
                        )
                    ) {
                        enqueueSnackbar(t('components.Calendar.overlapDeny'), { variant: 'error' });

                        return false;
                    }

                    if (
                        values.startDate &&
                        values.endDate &&
                        denyIds.includes(values.appointmentTypeId || original.appointmentTypeId) &&
                        checkGeneralOverlap({ ...original, ...values, id }, appointmentList)
                    ) {
                        enqueueSnackbar(t('components.Calendar.overlapOfBlocker'), {
                            variant: 'error',
                        });

                        return false;
                    }

                    const formatted = formatAppointmentExport({
                        id,
                        ...('allDay' in values && values.allDay && original
                            ? {
                                  startDate: importDate(original.startDate),
                                  endDate: importDate(original.endDate),
                              }
                            : {}),
                        resource: resourceParams,
                        ...values,
                    });

                    if (isDuplicate) {
                        const duplicate = formatAppointmentDuplicationParams({
                            ...formatAppointmentExport(original),
                            ...formatted,
                        });

                        return dispatch(storeAppointment(duplicate));
                    }

                    return dispatch(
                        'id' in formatted
                            ? updateAppointment(formatted)
                            : storeAppointment(formatted)
                    );
                })
            );
        }

        if (deleted) {
            dispatch(destroyAppointment({ id: deleted }));
        }
    };

    return (
        <Box className={classes.root}>
            <AppointmentCalendarContext
                calendarId={calendarId}
                defaultEntityId={defaultEntityId}
                defaultEntityResource={defaultEntityResource}
                forceCreateValues={forceCreateValues}
                overrideFetchKey={overrideFetchKey}
                showColor
                appointmentByRowAndDayMeta={appointmentByRowAndDayMeta}
                deniedPeriods={deniedPeriods}
                onResourcesChange={setSelectedResources}
            >
                <CalendarLoading loading={loading || !initialized} />
                <Calendar
                    calendarId={calendarId}
                    appointments={appointmentList}
                    initialDate={currentDate}
                    initialView={currentView}
                    allowResize={allowDragResize.current}
                    allowDrag={allowDragResize.current}
                    onDateChange={setCurrentDate}
                    onViewChange={setView}
                    onSubmit={handleSubmit}
                    AppointmentComponent={AppointmentAdvanced}
                    FormComponent={CalendarForm}
                    ToolbarOptionsComponent={CalendarToolbarOptions}
                    createRaised={createRaised}
                    showCreate
                />
            </AppointmentCalendarContext>
        </Box>
    );
};

AppointmentCalendar.propTypes = {
    calendarId: PropTypes.string.isRequired,
    defaultEntityId: PropTypes.oneOfType([IdPropType, PropTypes.string]).isRequired,
    defaultEntityResource: PropTypes.oneOf([EMPLOYEE_RESOURCE, RESOURCE_RESOURCE]).isRequired,
    overrideFetchKey: PropTypes.string,
    forceCreateValues: PropTypes.shape({}),
    createRaised: PropTypes.bool,
    onCheckParams: PropTypes.func,
};

AppointmentCalendar.defaultProps = {
    overrideFetchKey: null,
    forceCreateValues: {},
    createRaised: false,
    onCheckParams: null,
};

export default AppointmentCalendar;
