import React, { useState } from 'react';
import { Box, Link as MuiLink, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import { EmailContactPropType } from '../../modules/email/proptypes';
import Link from '../utils/Link';

const EmailContactList = ({ list, minContactAmount, color, disableShowAll }) => {
    const { t } = useTranslation('basics');

    const [maxContact, setMaxContact] = useState(minContactAmount);

    const showAll = () => setMaxContact(list.length);

    const renderContact = ({ name, email }, index = 0) => (
        <Link
            to={email ? `mailto:${email}` : null}
            variant="body2"
            inline="inline"
            key={index}
            color={color}
            disabled={!email}
            external
        >
            {name}
        </Link>
    );

    const renderContacts = (arr, count) =>
        arr &&
        arr.slice(0, count).map((copyContact, index) => {
            const contact = renderContact(copyContact, index);
            if (list.length > 1 && index < count - 1) {
                return (
                    <React.Fragment key={index}>
                        {contact}
                        <Typography component="span" color={color} maxHeight="17px">
                            ,&nbsp;
                        </Typography>
                    </React.Fragment>
                );
            }

            return contact;
        });

    const renderAll = () => {
        if (!list || maxContact === list.length) {
            return null;
        }
        return (
            <Box style={{ display: 'inline-block', maxHeight: '17px' }}>
                {list.length > 1 && (
                    <>
                        <span>,&nbsp;</span>
                        {!disableShowAll ? (
                            <MuiLink to="#" onClick={showAll} external>
                                {t('components.EmailView.more')}
                            </MuiLink>
                        ) : (
                            <span>{t('components.EmailView.more')}</span>
                        )}
                    </>
                )}
            </Box>
        );
    };

    return (
        <>
            {renderContacts(list, maxContact)}
            {renderAll()}
        </>
    );
};

EmailContactList.propTypes = {
    list: PropTypes.arrayOf(EmailContactPropType).isRequired,
    minContactAmount: PropTypes.number,
    color: PropTypes.string,
    disableShowAll: PropTypes.bool,
};

EmailContactList.defaultProps = {
    minContactAmount: 1,
    color: 'initial',
    disableShowAll: false,
};

export default EmailContactList;
