import { download as downloadCsv, generateCsv, mkConfig } from 'export-to-csv';
import { getFromNestedObject } from '../lists/utils';
import { COLUMN_TYPE_CONFIG, getColumnTypeConfig } from './columnTypes';

const formatExportValue = (value, columnConfig, row) => {
    if (value) {
        if (columnConfig) {
            const { convertString } = getColumnTypeConfig(columnConfig, false);

            if (convertString && typeof convertString === 'function') {
                return convertString(value, row);
            }
        }

        if (typeof value === 'object') {
            return JSON.stringify(value);
        }

        if (Array.isArray(value)) {
            if (value.length) {
                return JSON.stringify(value);
            }

            return '-';
        }

        return value;
    }

    return '-';
};

export const formatExportColumns = columns =>
    columns.map(({ id, accessorKey, labelKey, header, type }) => ({
        key: labelKey || accessorKey || id,
        displayLabel: header,
        config: type && COLUMN_TYPE_CONFIG[type],
    }));

export const exportCSV = (columnHeaders, rows, label = 'CSV', download = false) => {
    const csvConfig = mkConfig({
        fieldSeparator: ';',
        decimalSeparator: '.',
        filename: `KumoTabelle-${label}`,
        columnHeaders,
    });

    const rowData = rows.map(row => {
        return columnHeaders.reduce((carry, column) => {
            if (row && typeof column.key === 'string') {
                if (column.key.includes('.')) {
                    const { layer, missingSteps } = getFromNestedObject(column.key.split('.'), row);

                    return {
                        ...carry,
                        [column.key]: formatExportValue(
                            missingSteps.length ? null : layer,
                            column.config,
                            row
                        ),
                    };
                }

                return {
                    ...carry,
                    [column.key]: formatExportValue(row[column.key], column.config, row),
                };
            }

            return carry;
        }, {});
    });

    const csv = generateCsv(csvConfig)(rowData);

    if (download) {
        return downloadCsv(csvConfig)(csv);
    }

    return csv;
};
