import React from 'react';
import * as PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import IdentifierAutocomplete from '../form/formik/autocomplete/IdentifierAutocomplete';
import { selectCardById } from '../../modules/cards/selectors';
import { IDENTIFIER_TYPES } from '../../modules/datacontainers/utils';

const useStyles = makeStyles({
    missing: {
        opacity: '30%',
    },
});

const CardIdentifierSuggest = ({ cardId, identifierType, contrast, variant, onChange }) => {
    const classes = useStyles();
    const card = useSelector(state => selectCardById(state, cardId));

    return (
        <Grid
            item
            xs={5}
            mb={1}
            className={classNames({
                [classes.missing]: card && !card[`id_${identifierType}`],
            })}
            key={identifierType}
        >
            <IdentifierAutocomplete
                name={`id_${identifierType}`}
                onChange={onChange}
                type={identifierType}
                size="small"
                contrast={contrast}
                variant={variant}
                fetchParams={{ card_id: cardId }}
                fullWidth
                confirm
            />
        </Grid>
    );
};

CardIdentifierSuggest.propTypes = {
    cardId: PropTypes.number,
    identifierType: PropTypes.oneOf(Object.values(IDENTIFIER_TYPES)).isRequired,
    contrast: PropTypes.bool,
    variant: PropTypes.string,
    onChange: PropTypes.func,
};

CardIdentifierSuggest.defaultProps = {
    cardId: null,
    contrast: false,
    variant: 'outlined',
    onChange: () => {},
};

export default CardIdentifierSuggest;
