import React from 'react';
import * as PropTypes from 'prop-types';
import Select from '../form/formik/Select';
import { useTranslation } from 'react-i18next';

const OVERLAP_OPTIONS = [
    {
        value: 'allow',
        label: 'form.AppointmentTypeForm.overlapOptions.allow',
    },
    {
        value: 'warn',
        label: 'form.AppointmentTypeForm.overlapOptions.warn',
    },
    {
        value: 'deny',
        label: 'form.AppointmentTypeForm.overlapOptions.deny',
    },
];

const AppointmentTypeOverlapSelect = ({ name, ...other }) => {
    const { t } = useTranslation();

    return (
        <Select
            name={name}
            options={OVERLAP_OPTIONS}
            translateOption
            label={t('form.AppointmentTypeForm.overlap')}
            size="small"
            //fullWidth
            {...other}
        />
    );
};

AppointmentTypeOverlapSelect.propTypes = {
    name: PropTypes.string.isRequired,
};

export default AppointmentTypeOverlapSelect;
