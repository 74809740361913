import React from 'react';
import * as PropTypes from 'prop-types';
import Select from './formik/Select';

const options = [
    { label: 'components.Person.female', value: 'female' },
    { label: 'components.Person.male', value: 'male' },
    { label: 'components.Person.other', value: 'unknown' },
];

const SalutationSelect = ({
    name,
    label,
    labelId,
    onChange,
    fullWidth,
    contrast,
    className,
    variant,
    size,
    disabled,
}) => (
    <Select
        name={name}
        label={label}
        options={options}
        labelId={labelId}
        onChange={onChange}
        fullWidth={fullWidth}
        contrast={contrast}
        className={className}
        variant={variant}
        size={size}
        disabled={disabled}
        translateOption
    />
);

SalutationSelect.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    labelId: PropTypes.string,
    onChange: PropTypes.func,
    fullWidth: PropTypes.bool,
    contrast: PropTypes.bool,
    className: PropTypes.string,
    variant: PropTypes.string,
    size: PropTypes.string,
    disabled: PropTypes.bool,
};

SalutationSelect.defaultProps = {
    label: null,
    labelId: null,
    onChange: null,
    fullWidth: false,
    contrast: false,
    className: null,
    variant: 'outlined',
    size: 'small',
    translateOption: false,
    disabled: false,
};

export default SalutationSelect;
