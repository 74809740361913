import {
    APPOINTMENT_TYPE_AWAY_KEYS,
    formatAppointmentDuplicationParams,
    getDuplicationKeys,
} from './utils';
import { useDispatch, useSelector } from 'react-redux';
import { selectAppointmentById, storeAppointment } from './appointmentSlice';
import { useEffect, useMemo, useRef } from 'react';
import { selectAllAppointmentTypes } from '../appointmentTypes/appointmentTypeSlice';
import { useTranslation } from 'react-i18next';
import { selectAllAbsenceTypes, useAbsenceTypes } from '../absenceTypes/absenceTypeSlice';
import { useDynamicCan } from '../abilities/hooks';
import { READ } from '../abilities/actions';
import { APPOINTMENT_RESOURCE } from '../api/resources';
import { useCallbackFunc } from '../hooks';
import { useAutoPaginate, useResourceList } from '../lists/hooks';
import { convertUTCtoLocalDate } from '../datetime/utils';
import { compareDesc } from 'date-fns';

const checkAppointment = (appointment, taskId) => appointment.task_id === taskId;

const sortAppointment = (old, current) => {
    const dueOld = convertUTCtoLocalDate(old.created_at);
    const dueNew = convertUTCtoLocalDate(current.created_at);

    return compareDesc(dueOld, dueNew);
};

export const useAppointmentLoad = ({
    taskId,
    fetchParams = {},
    overrideListId = null,
    listIdKey = 'search',
    autoload = true,
    autoPaginate = false,
}) => {
    const stableCheckTask = useCallbackFunc(checkAppointment);
    const stableSortTask = useCallbackFunc(sortAppointment);

    const listId = overrideListId || `Appointments.${taskId}.${listIdKey}`;

    const {
        dataIds: appointmentIds,
        fullSelector,
        handlePage,
        handleNextPage,
        loading,
        initialized,
    } = useResourceList({
        listId,
        resource: APPOINTMENT_RESOURCE,
        criteria: {
            check: appointment => stableCheckTask(appointment, taskId),
            compare: stableSortTask,
        },
        continuous: true,
        index: true,
        fetchParams: {
            ...fetchParams,
            task_id: taskId,
        },
    });

    const appointmentsById = useSelector(state => fullSelector(state));
    const appointments = useMemo(() => Object.values(appointmentsById), [appointmentsById]);

    const active = useMemo(() => {
        return (
            Number.isInteger(taskId) &&
            autoload &&
            !loading &&
            !initialized &&
            (!appointments || appointments.length === 0)
        );
    }, [taskId, autoload, appointments, loading, initialized]);

    useAutoPaginate({
        active: autoPaginate && active,
        handlePage,
    });

    useEffect(() => {
        if (!autoPaginate && active) {
            handlePage(null, 1, true);
        }
    }, [taskId, autoPaginate, autoload, appointments, loading, initialized, handlePage]);

    return useMemo(
        () => ({
            listId,
            appointmentIds,
            handlePage,
            handleNextPage,
            loading,
            initialized,
            appointments,
        }),
        [listId, appointmentIds, handlePage, handleNextPage, loading, initialized, appointments]
    );
};

export const useAppointmentDuplication = appointmentId => {
    const dispatch = useDispatch();
    const appointment = useSelector(state => selectAppointmentById(state, appointmentId));

    const submitDuplicate = () => {
        const params = formatAppointmentDuplicationParams(appointment);

        return dispatch(storeAppointment(params));
    };

    return {
        submitDuplicate,
    };
};

const appointmentClassName = 'Appointment-appointment';
export const useAppointmentKeyManager = () => {
    const keyPressed = useRef(null);

    const getAppointmentElements = () => [
        document.body,
        ...document.getElementsByClassName(appointmentClassName),
    ];

    const handleKey = event => {
        if (event?.key) {
            keyPressed.current = event.key;

            const duplicationKeys = getDuplicationKeys();
            if (duplicationKeys.includes(event.key)) {
                getAppointmentElements().forEach(element => {
                    element.style.cursor = 'copy';
                });
            }
        }
    };

    const handleReset = () => {
        keyPressed.current = null;

        getAppointmentElements().forEach(element => {
            if (element?.classList?.contains(appointmentClassName)) {
                element.style.cursor = 'pointer';
            } else {
                element.style.cursor = 'auto';
            }
        });
    };

    useEffect(() => {
        document.onkeydown = handleKey;

        document.onkeyup = handleReset;

        return () => {
            handleReset();
        };
    }, []);

    return keyPressed;
};

export const useAppointmentName = appointment => {
    const { t } = useTranslation();
    const { handleCan } = useDynamicCan();
    const appointmentTypes = useSelector(selectAllAppointmentTypes);

    useAbsenceTypes();
    const absenceTypes = useSelector(selectAllAbsenceTypes);

    if (!appointment) {
        return null;
    }

    const { name, name_inherited: nameInherited, title, task, ...other } = appointment;

    if (nameInherited) {
        return nameInherited;
    }

    if (name || title) {
        return name || title;
    }

    if (task?.name) {
        return task.name;
    }

    const appointmentTypeId = other?.appointmentTypeId || other?.appointment_type_id;
    if (appointmentTypeId) {
        const appointmentType = appointmentTypes.find(({ id }) => id === appointmentTypeId);

        if (
            appointmentType.type === APPOINTMENT_TYPE_AWAY_KEYS.ABSENCE &&
            handleCan(READ, appointment || APPOINTMENT_RESOURCE, 'absence_type_id')
        ) {
            const absenceTypeId = other?.absenceTypeId || other?.absence_type_id;

            if (absenceTypeId) {
                const absenceType = absenceTypes.find(({ id }) => id === absenceTypeId);

                if (absenceType) {
                    return absenceType.name;
                }
            }
        }

        if (
            appointmentType?.type &&
            Object.values(APPOINTMENT_TYPE_AWAY_KEYS).includes(appointmentType.type)
        ) {
            return t(`components.Appointment.${appointmentType.type}`);
        }
    }

    return null;
};
