import React from 'react';
import * as PropTypes from 'prop-types';
import LaunchIcon from '@mui/icons-material/Launch';
import { useHistory } from 'react-router-dom';
import IconButton from '../form/base/IconButton';

const NavigateIconButton = ({ path }) => {
    const history = useHistory();

    const handleNavigate = () => {
        history.push(path);
    };

    return (
        <IconButton onClick={handleNavigate}>
            <LaunchIcon />
        </IconButton>
    );
};

NavigateIconButton.propTypes = {
    path: PropTypes.string.isRequired,
};

export default NavigateIconButton;
