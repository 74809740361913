import { createSelector } from '@reduxjs/toolkit';
import { ADDRESS_RESOURCE } from '../api/resources';

export const selectAddressesById = state => state[ADDRESS_RESOURCE].byId;

export const selectAddressById = (state, id) => selectAddressesById(state)[id];

export const selectAddressIdsByContactId = state => state[ADDRESS_RESOURCE].byContactId;

export const selectAddressIdsWhereContactId = (state, contactId) =>
    selectAddressIdsByContactId(state)[contactId];

export const makeSelectAddressesByContactId = () =>
    createSelector(
        selectAddressIdsWhereContactId,
        selectAddressesById,
        (addressIds, addressesById) => (addressIds || []).map(addressId => addressesById[addressId])
    );
export const selectAddressesByContactId = makeSelectAddressesByContactId();
