import * as Yup from 'yup';

export const groupSchema = Yup.object().shape({
    name: Yup.string().trim().required('required').max(255, 'string255MaxLength'),
    parent_type: Yup.string().nullable(),
    parent_id: Yup.number().nullable(),
});

export const groupSettingsSchema = Yup.object().shape({
    name: Yup.string().trim().required('required').max(255, 'string255MaxLength'),
    icon: Yup.string().nullable(),
    color: Yup.string().nullable(),
    auto_assign: Yup.boolean().required('required'),
});
