import React from 'react';
import * as PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import OptionalFormikContainer from './OptionalFormikContainer';
import DateTimeInput from './DateTimeInput';
import DateInput from './DateInput';
import Checkbox from './Checkbox';
import { useTranslation } from 'react-i18next';

const DateTimeToggleInput = ({ name, hasTimeName, label }) => {
    const { t } = useTranslation();

    return (
        <Grid container spacing={0.5} wrap="nowrap" minWidth={230}>
            <Grid item xs>
                <OptionalFormikContainer
                    name={hasTimeName}
                    hide={[1, true]}
                    propNode={hasTime =>
                        hasTime ? (
                            <DateTimeInput name={name} label={label} size="small" fullWidth />
                        ) : (
                            <DateInput name={name} label={label} size="small" fullWidth />
                        )
                    }
                />
            </Grid>
            <Grid item>
                <Checkbox
                    name={hasTimeName}
                    size="small"
                    label={t('components.DateTimeToggleInput.toggle')}
                    labelTooltip
                />
            </Grid>
        </Grid>
    );
};

DateTimeToggleInput.propTypes = {
    name: PropTypes.string.isRequired,
    hasTimeName: PropTypes.string.isRequired,
    label: PropTypes.string,
};

DateTimeToggleInput.defaultProps = {
    label: null,
};

export default DateTimeToggleInput;
