import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import { useField } from 'formik';
import TextInput from '../form/formik/TextInput';
import Select from '../form/formik/Select';
import RemoveIconButton from '../buttons/RemoveIconButton';

const METHODS = [
    {
        value: 'email',
        label: 'components.ContactData.email',
    },
    {
        value: 'phone',
        label: 'components.ContactData.phone',
    },
    {
        value: 'mobile',
        label: 'components.ContactData.mobile',
    },
    {
        value: 'fax',
        label: 'components.ContactData.fax',
    },
    {
        value: 'web',
        label: 'components.ContactData.web',
    },
];

const TYPES = [
    {
        value: 'private',
        label: 'components.ContactData.private',
    },
    {
        value: 'office',
        label: 'components.ContactData.office',
    },
    {
        value: 'other',
        label: 'components.ContactData.other',
    },
];

const ContactDataInput = ({ name, onDelete }) => {
    const { t } = useTranslation();
    const [{ value: selectedMethod }] = useField(`${name}.method`);

    const [placeholder, setPlaceholder] = useState(
        t('components.ContactData.placeholders.generic')
    );

    const getPlaceholderKey = useCallback(
        (key, value) => {
            if (!value) {
                setPlaceholder(t(`components.ContactData.placeholders.generic`));
            } else {
                const placeholderKey = ['email', 'web'].includes(value) ? 'address' : 'number';
                setPlaceholder(t(`components.ContactData.placeholders.${placeholderKey}`));
            }
        },
        [t]
    );

    useEffect(() => {
        getPlaceholderKey(`${name}.method`, selectedMethod);
    }, [getPlaceholderKey, name, selectedMethod]);

    return (
        <>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={3}>
                    <Select
                        size="small"
                        name={`${name}.method`}
                        options={METHODS}
                        translateOption
                        label={t('components.ContactData.type')}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <Select
                        size="small"
                        name={`${name}.type`}
                        options={TYPES}
                        translateOption
                        label={t('components.ContactData.method')}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextInput
                        data-test-id="contact-data-input"
                        size="small"
                        name={`${name}.value`}
                        label={placeholder}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <RemoveIconButton onClick={onDelete} size="small" />
                </Grid>
                <Grid item xs={9}>
                    <TextInput
                        size="small"
                        name={`${name}.comment`}
                        label={t('components.ContactData.note')}
                        fullWidth
                    />
                </Grid>
            </Grid>
        </>
    );
};

ContactDataInput.propTypes = {
    name: PropTypes.string.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default ContactDataInput;
