import { extractRelated, getWithKeys } from '../redux/resource/utils';

const getFinalAction = (action, meta) =>
    meta?.groupKey && meta?.groupNestedListKey ? 'groupNestedList' : action;

export const pendingAction = (name, action, params, meta) => ({
    type: `${name}/${getFinalAction(action, meta)}Pending`,
    meta: {
        key: name,
        action,
        params,
        ...meta,
    },
});

export const fulfilledAction = (name, action, params, payload, meta) => ({
    type: `${name}/${action}Fulfilled`,
    payload,
    meta: {
        key: name,
        action,
        params,
        ...meta,
    },
});

export const errorAction = (name, action, params, error, meta) => ({
    type: `${name}/${getFinalAction(action, meta)}Error`,
    payload: error,
    error: true,
    meta: {
        key: name,
        action,
        params,
        ...meta,
    },
});

export const socketAction = (name, action, payload, meta) => ({
    type: `${name}/${action}Socket`,
    payload,
    meta: {
        key: name,
        action,
        ...meta,
    },
});

export const bulkFulfilledAction = (name, action, params, payload, meta) => dispatch => {
    if (params && params.with && payload) {
        const relatedKeys = getWithKeys(params.with);

        const byKey = extractRelated(relatedKeys, payload);
        Object.values(byKey).forEach(data => {
            dispatch(fulfilledAction(data[0].__type, 'index', {}, data, meta));
        });
    }

    dispatch(fulfilledAction(name, getFinalAction(action, meta), params, payload, meta));
};
