/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { DATACONTAINER_RESOURCE } from '../api/resources';

const insertDatacontainer = (state, datacontainer) => {
    const stale = state.byId[datacontainer.id];

    if (!stale) {
        state.allIds.push(datacontainer.id);
    }

    state.byId[datacontainer.id] = {
        ...datacontainer,

        /* metadata is handled by different slice */
        metadata: undefined,

        /* datacontainerType object is handled by different slice */
        datacontainer_type: undefined,
        datacontainer_type_id: datacontainer.datacontainer_type.id,
    };
};

const datacontainersSlice = createSlice({
    name: DATACONTAINER_RESOURCE,
    initialState: {
        byId: {},
        allIds: [],
    },
    reducers: {
        indexFulfilled: {
            prepare: (payload, meta) => ({ payload, meta }),
            reducer: (state, action) => {
                action.payload.forEach(datacontainer => {
                    insertDatacontainer(state, datacontainer);
                });
            },
        },

        showFulfilled: {
            prepare: (payload, meta) => ({ payload, meta }),
            reducer: (state, action) => {
                insertDatacontainer(state, action.payload);
            },
        },

        storeFulfilled: (state, action) => {
            insertDatacontainer(state, action.payload);
        },

        updateFulfilled: (state, action) => {
            insertDatacontainer(state, action.payload);
        },

        destroyFulfilled: (state, action) => {
            const index = state.allIds.findIndex(id => id === action.payload);
            if (index >= 0) {
                delete state.byId[action.payload];
                state.allIds.splice(index, 1);
            }
        },
    },
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,
} = datacontainersSlice.actions;

export default datacontainersSlice.reducer;
