import * as Yup from 'yup';
import { YupJson } from '../form/schema';

const resourceConfigSchema = Yup.object().shape({
    group: Yup.string(),
    label: Yup.string(),
    type: Yup.string(),
    api: Yup.string(),
    allowMultiple: Yup.boolean(),
    iconDefault: Yup.string(),
    iconAdd: Yup.string(),
    showOnAppointment: Yup.boolean(),
});

export const appointmentTypeSchema = Yup.object().shape({
    name: Yup.string().trim().required('required'),
    color: Yup.string().nullable(),
    component: Yup.string().nullable(),
    order: Yup.number().nullable().typeError('numberMismatch'),
    overlap: Yup.string().required('required'),
    resource_config: YupJson().nullable(), //Yup.array().of(resourceConfigSchema).nullable(),
    type: Yup.string().nullable(),
});
