import React from 'react';
import * as PropTypes from 'prop-types';
import { useField } from 'formik';
import { Lock as LockIcon, LockOpen as LockOpenIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import IconButton from './IconButton';
import Confirmation from '../Confirmation';
import { useContextualCan } from '../../../modules/abilities/hooks';
import ContextualCan from '../../../modules/abilities/ContextualCan';
import { READ, WRITE } from '../../../modules/abilities/actions';

const LockButton = ({ name, originalName, disabled, can, ...other }) => {
    const { t } = useTranslation();
    const [{ value }, , { setValue }] = useField(name);
    const canDo = useContextualCan(can ? can : WRITE, originalName || name);

    const handleClick = () => {
        if (!value) {
            setValue(true);
        }
    };

    const handleConfirm = () => setValue(false);

    return (
        <ContextualCan I={READ} field={originalName || name}>
            <Confirmation
                label={t('form.LockButton.unlock')}
                popupId={name}
                icon={<LockOpenIcon />}
                onConfirm={handleConfirm}
                disabled={!value || !canDo || disabled}
                {...other}
            >
                <IconButton onClick={handleClick} disabled={!canDo || disabled} size="small">
                    {value ? <LockIcon /> : <LockOpenIcon />}
                </IconButton>
            </Confirmation>
        </ContextualCan>
    );
};

LockButton.propTypes = {
    name: PropTypes.string.isRequired,
    originalName: PropTypes.string,
    disabled: PropTypes.bool,
    can: PropTypes.string,
};

LockButton.defaultProps = {
    originalName: null,
    disabled: false,
    can: null,
};

export default LockButton;
