import React from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import DatacontainerAutocomplete from '../form/formik/autocomplete/DatacontainerAutocomplete';
import { IDENTIFIER_TYPES, CUSTOM_ID_TYPE_PREFIX } from '../../modules/datacontainers/utils';
import { IdPropType } from '../../modules/proptypes';

const useStyles = makeStyles({
    missing: {
        opacity: '30%',
    },
});

const CardIdentifierDatacontainer = ({
    identifierType,
    cardId,
    datacontainer,
    contrast,
    variant,
    onChange,
    I,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Grid item xs={5} className={classNames({ [classes.missing]: !datacontainer })}>
            <DatacontainerAutocomplete
                name={
                    identifierType === IDENTIFIER_TYPES.ORDER ? 'datacontainer' : 'datacontainer2'
                }
                confirm
                label={t(`components.DatacontainerAutocomplete.${identifierType}`)}
                forcePrefix={CUSTOM_ID_TYPE_PREFIX[identifierType]}
                contrast={contrast}
                variant={variant}
                fullWidth
                fetchParams={{
                    card_id: cardId,
                }}
                onChange={onChange}
                I={I}
            />
        </Grid>
    );
};

CardIdentifierDatacontainer.propTypes = {
    cardId: IdPropType,
    identifierType: PropTypes.oneOf(Object.values(IDENTIFIER_TYPES)).isRequired,
    datacontainer: PropTypes.shape({}).isRequired,
    contrast: PropTypes.bool,
    variant: PropTypes.string,
    onChange: PropTypes.func,
    I: PropTypes.string,
};

CardIdentifierDatacontainer.defaultProps = {
    cardId: null,
    contrast: false,
    variant: 'outlined',
    onChange: () => {},
    I: null,
};

export default CardIdentifierDatacontainer;
