import React from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { Box } from '@mui/material';

const useStyles = makeStyles(theme => ({
    error: {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: `${theme.palette.error.main}22`, // should be ~0.07% opacity
        padding: theme.spacing(1),
    },
}));

const ErrorBackground = ({ isError, children }) => {
    const classes = useStyles();

    return <Box className={classNames({ [classes.error]: isError })}>{children}</Box>;
};

ErrorBackground.propTypes = {
    isError: PropTypes.bool,
    children: PropTypes.node,
};

ErrorBackground.defaultProps = {
    isError: false,
    children: null,
};

export default ErrorBackground;
