import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import SlimDialog, { SLIM_DIALOG_WIDTH } from '../../dialogs/slimDialog/SlimDialog';
import { IdPropType, ReferenceIdPropType } from '../../../modules/proptypes';
import Form from '../../form/formik/Form';
import JournalFormBody from '../JournalFormBody';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../../modules/auth/selectors';
import { CREATE, UPDATE } from '../../../modules/abilities/actions';
import { Box, Grid } from '@mui/material';
import Button from '../../form/base/Button';
import SubmitButton from '../../form/formik/SubmitButton';
import { useTranslation } from 'react-i18next';
import { formatJournalSubmit } from '../../../modules/journals/utils';
import {
    archiveJournal,
    storeJournal,
    updateJournal,
} from '../../../modules/journals/journalsSlice';
import { selectJournalsById } from '../../../modules/journals/selectors';
import { assembleBulkValues, cleanPartialUpdateValues } from '../../../modules/form/bulk';
import { JOURNAL_RESOURCE } from '../../../modules/api/resources';
import Badge from '@mui/material/Badge';
import { getJournalSchema } from '../../../modules/journals/schema';

const JournalDialog = ({ journalIds, referenceId, create, open, onClose, onExited }) => {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const { t } = useTranslation();
    const title = useMemo(
        () => t(`components.JournalDialog.${create ? 'create' : 'bulk'}`),
        [create]
    );
    const journalsById = useSelector(selectJournalsById);

    const { bulkMode, initialValues, journals } = useMemo(() => {
        if (!create) {
            if (Array.isArray(journalIds)) {
                if (journalIds.length === 1) {
                    const journal = journalsById[parseInt(journalIds[0])];
                    return {
                        bulkMode: false,
                        initialValues: journal,
                        journals: [journal],
                    };
                }

                if (journalIds.length > 1) {
                    const { journals, userIds } = journalIds.reduce(
                        (carry, journalId) => {
                            const item = journalsById[parseInt(journalId)];
                            return {
                                journals: [...carry.journals, item],
                                userIds: [...carry.userIds, item.user_id],
                            };
                        },
                        { journals: [], userIds: [] }
                    );
                    const { user_id, started_at, ...bulkValues } = assembleBulkValues(journals);

                    return {
                        bulkMode: true,
                        initialValues: {
                            ...bulkValues,
                            started_at: started_at || null,
                        },
                        journals,
                    };
                }
            }
        }

        return {
            bulkMode: false,
            initialValues: {
                card_id: (referenceId?.key === 'card_id' && referenceId?.value) || '',
                started_at: new Date(),
                started_has_time: false,
                task_id: '',
                task_type_id: '',
                duration: null,
                description: '',
                duration_billable: null,
                billed_at: null,
                id_invoice: null,
                user_id:
                    referenceId?.key === 'user_id' && referenceId.value
                        ? [referenceId.value]
                        : [user.id],
            },
            journals: [],
        };
    }, [create, referenceId, user, journalIds, journalsById]);

    const dispatchSubmit = values => {
        if (create) {
            return dispatch(storeJournal(formatJournalSubmit(values)));
        }

        if (Array.isArray(journalIds) && journalIds.length === 1) {
            return dispatch(updateJournal(formatJournalSubmit(values)));
        }

        if (bulkMode) {
            const items = formatJournalSubmit({
                ...values,
                id: journalIds,
            });

            const { duration, ...cleaned } = cleanPartialUpdateValues(initialValues, items);

            return dispatch(
                updateJournal({
                    ...cleaned,
                    ...(duration ? { duration } : {}),
                })
            );
        }

        console.error('could not distinguish submit mode (create/update/bulkUpdate)');
        return null;
    };

    const handleSubmit = values => {
        const submit = dispatchSubmit(values);

        if (submit) {
            return submit.then(() => {
                onClose();
            });
        }

        return submit;
    };

    const handleDelete = () => {
        return dispatch(
            archiveJournal({
                id: journalIds,
            })
        ).then(() => {
            onClose();
        });
    };

    const handleClose = props => {
        onClose(props);
    };

    const toolbarActions = useMemo(() => null, []);

    return (
        <SlimDialog
            title={title}
            open={open}
            width={SLIM_DIALOG_WIDTH.MEDIUM}
            deleteBadge={journalIds.length > 1 ? journalIds.length : null}
            onDelete={create || !journalIds.length ? null : handleDelete}
            onClose={handleClose}
            actions={toolbarActions}
        >
            <Box m={2}>
                <Form
                    initialValues={initialValues}
                    subject={journals.length === 1 ? journals[0] : JOURNAL_RESOURCE}
                    validationSchema={bulkMode ? null : getJournalSchema(referenceId)}
                    onSubmit={handleSubmit}
                >
                    <JournalFormBody create={create} referenceId={referenceId}>
                        <Grid item display="flex" alignItems="end">
                            <Button onClick={handleClose} variant="outlined" size="medium">
                                {t('abort')}
                            </Button>
                        </Grid>
                        <Grid item display="flex" alignItems="end">
                            <Badge
                                invisible={journalIds.length < 2}
                                badgeContent={journalIds.length}
                                color="primary"
                            >
                                <SubmitButton I={create ? CREATE : UPDATE} size="medium" />
                            </Badge>
                        </Grid>
                    </JournalFormBody>
                </Form>
            </Box>
        </SlimDialog>
    );
};

JournalDialog.propTypes = {
    journalIds: PropTypes.arrayOf(IdPropType.isRequired).isRequired,
    referenceId: ReferenceIdPropType,
    create: PropTypes.bool,
    open: PropTypes.bool,
};

JournalDialog.defaultProps = {
    referenceId: null,
    create: false,
    open: false,
};

export default JournalDialog;
