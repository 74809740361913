import React from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { selectContactDatasByContactId } from '../../modules/contactData/selectors';
import { Box, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import ContactData from './ContactData';
import { selectCompanyById } from '../../modules/companies/selectors';
import { useTranslation } from 'react-i18next';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import { selectTypeGroupedContactablesByCardId } from '../../modules/contactables/selectors';
import ContactTypeIcon from '../icons/ContactTypeIcon';
import ClickBoundary from '../form/ClickBoundary';
import Pin from '../form/Pin';
import { updateContactable } from '../../modules/contactables/actions';

const PHONE_METHODS = ['phone', 'mobile', 'fax'];
const MAIL_METHODS = ['email', 'web'];

const ContactLabel = ({ data, accessor, cardId }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch();
    const contactDatas = useSelector(state => selectContactDatasByContactId(state, data.id));
    const contactables = useSelector(state => selectTypeGroupedContactablesByCardId(state, cardId));
    const contactable = contactables ? contactables.find(c => c.contact_id === data.id) : null;
    const company = useSelector(state => selectCompanyById(state, data && data.companyId));

    const phones = contactDatas.filter(data => PHONE_METHODS.includes(data.method));
    phones.sort((a, b) => PHONE_METHODS.indexOf(a.method) - PHONE_METHODS.indexOf(b.method));

    const emails = contactDatas.filter(data => MAIL_METHODS.includes(data.method));

    const handlePinned = (event, pinned) => {
        return Promise.all(
            contactable.contactableIds.map(contactableId =>
                dispatch(updateContactable({ id: contactableId, pinned }))
            )
        );
    };

    switch (accessor) {
        case 'display_name':
            if (data.display_name !== '') {
                return data.display_name;
            }
            return data.display_name2;

        case 'phone':
            if (phones) {
                return phones.map((phone, index) => (
                    <Box style={{ display: 'grid', gridTemplateColumns: 'auto 1fr 5fr' }}>
                        <ContactData
                            key={`child-ph${phone.id}`}
                            contactData={phone}
                            labelStyle={{ paddingRight: theme.spacing(2) }}
                            showIcon
                            iconStyle={{
                                marginRight: theme.spacing(0.5),
                            }}
                        />
                    </Box>
                ));
            }
            return null;

        case 'email':
            if (emails) {
                return emails.map((email, index) => (
                    <Box style={{ display: 'grid', gridTemplateColumns: 'auto 1fr 5fr' }}>
                        <ContactData
                            key={`child-em${email.id}`}
                            contactData={email}
                            labelStyle={{ paddingRight: theme.spacing(2) }}
                            showIcon
                            iconStyle={{
                                marginRight: theme.spacing(0.5),
                            }}
                        />
                    </Box>
                ));
            }
            return null;

        case 'companyId':
            return company ? <Typography variant="inherit">{company.name}</Typography> : null;

        case 'synced':
            return contactable && contactable.synced ? (
                <Tooltip title={t('components.CardContacts.synced')} disableInteractive>
                    <SyncOutlinedIcon color="disabled" />
                </Tooltip>
            ) : null;

        case 'contactType':
            return contactable && contactable.contactTypeIds.length ? (
                <ContactTypeIcon typeIds={contactable.contactTypeIds} />
            ) : null;

        case 'pinned':
            return (
                <ClickBoundary>
                    <Pin
                        onChange={handlePinned}
                        pinned={contactable && contactable.pinned}
                        labelPin={t('components.CardContacts.pin')}
                        labelUnpin={t('components.CardContacts.unpin')}
                    />
                </ClickBoundary>
            );

        default:
            return null;
    }
};

ContactLabel.propTypes = {
    data: PropTypes.shape({
        display_name: PropTypes.string,
        display_name2: PropTypes.string,
    }).isRequired,
    accessor: PropTypes.string.isRequired,
};

export default ContactLabel;
