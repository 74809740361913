import React, { memo, useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import Form from '../form/formik/Form';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    form: {
        width: '100%',
        height: '100%',
    },
});

const makeValuesComparable = object => {
    if (object && typeof object === 'object') {
        return Object.entries(object)
            .sort((a, b) => a[0] - b[0])
            .map(([key, value]) => `${key}:${value}`)
            .join();
    }

    return null;
};

const ResourceTableForm = ({ editingRow, resource, onSubmit, children, ...other }) => {
    const classes = useStyles();
    const [initialValues, setInitialValues] = useState(null);

    useEffect(() => {
        if (makeValuesComparable(editingRow) !== makeValuesComparable(initialValues)) {
            setInitialValues(editingRow);
        }
    }, [editingRow, initialValues]);

    return (
        <Form
            initialValues={initialValues}
            subject={initialValues || resource}
            className={classes.form}
            enableReinitialize
            onSubmit={onSubmit}
            {...other}
        >
            {children}
        </Form>
    );
};

ResourceTableForm.propTypes = {
    editingRow: PropTypes.shape({}).isRequired,
    resource: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

ResourceTableForm.defaultProps = {};

export default memo(ResourceTableForm);
