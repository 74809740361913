import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { useAppointmentName } from '../../modules/appointments/hooks';

const AppointmentFormTitleWorker = () => {
    const { values, setFieldValue } = useFormikContext();
    const titleFallback = useAppointmentName(values);

    useEffect(() => {
        setFieldValue('titleFallback', titleFallback);
    }, [titleFallback, setFieldValue]);

    return null;
};

export default AppointmentFormTitleWorker;
