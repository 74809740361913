/**
 * Usage: <Button onClick={() => openContactDialog({ contactId: 1337 })}>open contact dialog</Button>
 */

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Tooltip } from '@mui/material';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import { makeStyles } from '@mui/styles';
import PersonContactPage from './pages/PersonContactPage';
import { selectContactById } from '../../../modules/contacts/selectors';
import TabDialog from '../../dialogs/tabDialog/TabDialog';
import LinkedCardsPage from './pages/LinkedCardsPage';
import { archiveContact, showContact } from '../../../modules/contacts/actions';
import EditButton from '../../buttons/EditButton';
import CompanyContactPage from './pages/CompanyContactPage';
import ChildContactsPage from './pages/ChildContactsPage';
import { UPDATE } from '../../../modules/abilities/actions';
import { IdPropType } from '../../../modules/proptypes';
import TabDialogActions from '../../dialogs/tabDialog/TabDialogActions';
import { tabPath } from '../../../routes/paths';
import Link from '../../utils/Link';
import { useCallbackRef } from '../../../modules/hooks';
import { CONTACT_RESOURCE } from '../../../modules/api/resources';
import ContactLinkedProtocolPage from './pages/ContactLinkedProtocolPage';

const useStyles = makeStyles(theme => ({
    aside: {
        position: 'absolute',
        right: theme.spacing(2),
        zIndex: 1,
    },
}));

const getTitle = (contactId, contact, createCompany, edit, t) => {
    if (!contactId) {
        return t(
            createCompany
                ? 'components.ContactDialog.addCompany'
                : 'components.ContactDialog.addPerson'
        );
    }

    return (contact && (contact.display_name || contact.display_name2)) || '';
};

const ContactDialog = ({
    contactId,
    contextCardId,
    contextParentId,
    createCompany,
    onClose,
    edit: initialEdit,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const submitRef = useCallbackRef(null);
    const extraRef = useCallbackRef(null);
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(initialEdit);
    const contact = useSelector(state => selectContactById(state, contactId));

    useEffect(() => {
        setEdit(initialEdit || !contactId);
    }, [contactId, initialEdit, setEdit]);

    useEffect(() => {
        if (contactId) {
            setLoading(true);
            dispatch(showContact({ id: contactId })).finally(() => {
                setLoading(false);
            });
        }
    }, [contactId, dispatch]);

    const handleDone = useCallback(() => {
        setEdit(false);
    }, [setEdit]);

    const handleDelete = useCallback(() => {
        if (contactId) {
            dispatch(archiveContact({ id: contactId })).then(() => {
                if (contextCardId) {
                    onClose({ cardId: contextCardId });
                } else {
                    onClose({ cardId: null });
                }
            });
        }
    }, [contactId, contextCardId, dispatch, onClose]);

    const isCompany = useMemo(
        () => (contact && !contact.personId) || (!contactId && createCompany),
        [contact, contactId, createCompany]
    );
    const isPerson = useMemo(
        () => (contact && contact.personId) || (!contactId && !createCompany),
        [contact, contactId, createCompany]
    );

    const tabs = [
        {
            name: 'data',
            title: t('components.ContactDialog.data'),
            disabled: false,
            content: (
                <>
                    {contact && contact.sync_date && (
                        <Box className={classes.aside}>
                            <Tooltip title={t('components.CardContacts.synced')} disableInteractive>
                                <SyncOutlinedIcon color="disabled" fontSize="large" />
                            </Tooltip>
                        </Box>
                    )}

                    {isPerson && (
                        <>
                            <PersonContactPage
                                contactId={contactId}
                                contextParentId={contextParentId}
                                contextCardId={contextCardId}
                                submitRef={submitRef}
                                extraRef={extraRef}
                                edit={edit}
                                onDone={handleDone}
                            />
                        </>
                    )}

                    {isCompany && (
                        <>
                            <CompanyContactPage
                                contactId={contactId}
                                contextCardId={contextCardId}
                                submitRef={submitRef}
                                extraRef={extraRef}
                                edit={edit}
                                onDone={handleDone}
                            />
                        </>
                    )}
                </>
            ),
        },
        ...(isCompany
            ? [
                  {
                      name: 'persons',
                      title: t('components.ContactDialog.persons'),
                      disabled: !contactId,
                      content: (
                          <ChildContactsPage contactId={contactId} contextCardId={contextCardId} />
                      ),
                  },
              ]
            : []),
        {
            name: 'protocols',
            title: t('components.ContactDialog.protocols'),
            disabled: !contactId,
            content: <ContactLinkedProtocolPage contactId={contactId} />,
        },
        {
            name: 'links',
            title: t('components.ContactDialog.links'),
            disabled: !contactId,
            content: <LinkedCardsPage contactId={contactId} />,
        },
    ];

    const tabActions = {
        data: (
            <TabDialogActions
                ref={submitRef}
                refLeft={extraRef}
                left={
                    edit ? null : (
                        <EditButton
                            subject={contact}
                            action={UPDATE}
                            onClick={() => setEdit(true)}
                            disabled={!!(contact && contact.sync_date)}
                        />
                    )
                }
                right={
                    contextCardId ? (
                        <Link variant="button" to={tabPath(contextCardId, 'contacts')} noHover>
                            {t('components.ContactDialog.backToCard')}
                        </Link>
                    ) : null
                }
            />
        ),
        links: <TabDialogActions />,
    };

    const handleClose = useCallback(
        () => onClose({ cardId: contextCardId }),
        [onClose, contextCardId]
    );

    return (
        <TabDialog
            data-test-id="ContactDialog"
            title={getTitle(contactId, contact, createCompany, edit, t)}
            loading={loading}
            tabs={tabs}
            actions={tabActions}
            onClose={handleClose}
            onDelete={contactId && handleDelete}
            subject={contact || CONTACT_RESOURCE}
            resetTabWhenChanged={contactId}
        />
    );
};

ContactDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    contactId: PropTypes.number,
    contextCardId: IdPropType,
    contextParentId: IdPropType,
    edit: PropTypes.bool,
    createCompany: PropTypes.bool,
};

ContactDialog.defaultProps = {
    contactId: null,
    contextCardId: null,
    contextParentId: null,
    edit: false,
    createCompany: false,
};

export default ContactDialog;
