import React, { useEffect, useRef } from 'react';
import * as PropTypes from 'prop-types';
import { Form as FormikForm, Formik, useFormikContext } from 'formik';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { ConditionalWrapper } from '../../utils/ConditionalWrapper';
import PermissionSubjectProvider from '../../../modules/abilities/PermissionSubjectProvider';
import { SubjectPropType } from '../../../modules/abilities/proptypes';
import { useDialogControl } from '../../dialogs/DialogControlContext';
import { useCallbackFunc } from '../../../modules/hooks';
import { useSnackbar } from '../../../modules/snackbar/hooks';
import { editorStateToMarkdown } from '../../../modules/editor/markdown';
import { FORM_STATUS } from '../../../modules/form/utils';

const FormikContext = ({
    onDirty,
    editors,
    autoSubmit,
    preventInitialSubmit,
    children,
    className,
    autofill,
    muteDirty,
}) => {
    const { status, dirty, touched, values, initialValues, submitForm } = useFormikContext();
    const dialogControl = useDialogControl();
    const autoSubmitInitialized = useRef(false);

    const debouncedSubmit = React.useMemo(
        () =>
            debounce(() => {
                if (status === FORM_STATUS.AVAILABLE) {
                    return submitForm();
                }
            }, 50),
        [status, submitForm]
    );

    let isDirty = dirty;

    if (editors && editors.length > 0) {
        /*
         * cant trust formik value, lets hope this gets implemented at some point:
         * https://github.com/jaredpalmer/formik/issues/1421
         */
        const touchedFields = Object.entries(touched)
            .filter(([, isTouched]) => isTouched)
            .map(([field]) => field);
        isDirty =
            touchedFields.reduce(
                (carry, field) =>
                    (!editors.includes(field) && values[field] !== initialValues[field]) || carry,
                false
            ) ||
            editors.reduce(
                (carry, editor) =>
                    editorStateToMarkdown(values[editor]) !==
                        editorStateToMarkdown(initialValues[editor]) || carry,
                false
            );
    }

    useEffect(() => {
        return () => {
            if (dialogControl && dialogControl.open) {
                dialogControl.setConfirmClose(false);
            }
        };
    }, [dialogControl]);

    useEffect(() => {
        if (autoSubmit) {
            if (preventInitialSubmit && !autoSubmitInitialized.current) {
                autoSubmitInitialized.current = true;
            } else {
                debouncedSubmit();
            }
        }
    }, [autoSubmit, preventInitialSubmit, values]);

    useEffect(
        () => () => {
            autoSubmitInitialized.current = false;
        },
        []
    );

    useEffect(() => {
        if (onDirty) {
            onDirty(isDirty);
        }

        if (!muteDirty && dialogControl && dialogControl.open) {
            dialogControl.setConfirmClose(isDirty);
        }
    }, [onDirty, isDirty, muteDirty, dialogControl]);

    return (
        <FormikForm autoComplete={autofill ? undefined : 'off'} className={className}>
            {children}
        </FormikForm>
    );
};

FormikContext.propTypes = {
    onDirty: PropTypes.func,
    editors: PropTypes.arrayOf(PropTypes.string),
    children: PropTypes.node.isRequired,
    autoSubmit: PropTypes.bool,
    preventInitialSubmit: PropTypes.bool,
    autofill: PropTypes.bool,
    className: PropTypes.string,
    muteDirty: PropTypes.bool,
};

FormikContext.defaultProps = {
    onDirty: null,
    editors: [],
    autoSubmit: false,
    preventInitialSubmit: false,
    autofill: false,
    className: null,
    muteDirty: false,
};

const Form = ({
    onSubmit,
    onDirty,
    editors,
    children,
    autoSubmit,
    preventInitialSubmit,
    subject,
    className,
    autofill,
    snackbar,
    successMessage,
    muteDirty,
    ...other
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const handleSubmit = useCallbackFunc((values, context) =>
        Promise.resolve(onSubmit(values, context))
            .then(result => {
                if (!autoSubmit && snackbar) {
                    enqueueSnackbar(successMessage || t('form.success'), { variant: 'success' });
                }
                return result;
            })
            .catch(error => {
                const { setSubmitting } = context;
                setSubmitting(false);

                throw error;
            })
    );

    return (
        <ConditionalWrapper
            condition={!!subject}
            wrapper={wrapped => (
                <PermissionSubjectProvider subject={subject}>{wrapped}</PermissionSubjectProvider>
            )}
        >
            <Formik onSubmit={handleSubmit} {...other}>
                <FormikContext
                    onDirty={onDirty}
                    editors={editors}
                    autoSubmit={autoSubmit}
                    preventInitialSubmit={preventInitialSubmit}
                    className={className}
                    autofill={autofill}
                    muteDirty={muteDirty}
                >
                    {children}
                </FormikContext>
            </Formik>
        </ConditionalWrapper>
    );
};

Form.propTypes = {
    children: PropTypes.node.isRequired,
    initialValues: PropTypes.shape({}),
    onSubmit: PropTypes.func,
    onDirty: PropTypes.func,
    autoSubmit: PropTypes.bool,
    preventInitialSubmit: PropTypes.bool,
    editors: PropTypes.arrayOf(PropTypes.string),
    subject: SubjectPropType,
    className: PropTypes.string,
    autofill: PropTypes.bool,
    enableReinitialize: PropTypes.bool,
    validationSchema: PropTypes.shape({}),
    snackbar: PropTypes.bool,
    successMessage: PropTypes.string,
    muteDirty: PropTypes.bool,
};

Form.defaultProps = {
    initialValues: {},
    onSubmit: () => null,
    editors: [],
    onDirty: null,
    autoSubmit: false,
    preventInitialSubmit: false,
    subject: null,
    className: null,
    autofill: false,
    enableReinitialize: false,
    validationSchema: null,
    snackbar: false,
    successMessage: null,
    muteDirty: false,
};

export default Form;
