import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import { makeStyles } from '@mui/styles';
import { IdPropType } from '../../modules/proptypes';
import { showCompany } from '../../modules/companies/actions';
import { selectContactById } from '../../modules/contacts/selectors';
import { selectContactDataIdsWhereContactId } from '../../modules/contactData/selectors';
import ContactData from '../contactData/ContactData';
import { selectCompanyById } from '../../modules/companies/selectors';
import { selectAddressIdsWhereContactId } from '../../modules/addresses/selectors';
import AddressDisplay from '../address/AddressDisplay';
import { showContact } from '../../modules/contacts/actions';
import { contactPath } from '../../routes/paths';
import Link from '../utils/Link';

const useStyles = makeStyles(theme => ({
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },

    subtitle: {
        paddingBottom: theme.spacing(2),
        marginTop: theme.spacing(6),
    },
}));

const CompanyPreview = ({ contactId, link }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const contact = useSelector(state => selectContactById(state, contactId));
    const contactDataIds =
        useSelector(state => selectContactDataIdsWhereContactId(state, contactId)) || [];
    const addressIds = useSelector(state => selectAddressIdsWhereContactId(state, contactId)) || [];
    const company = useSelector(state => selectCompanyById(state, contact && contact.companyId));
    const dispatch = useDispatch();

    useEffect(() => {
        if (contactId && !contact) {
            dispatch(showContact({ id: contactId }));
        }
    }, [contactId, contact, dispatch]);

    useEffect(() => {
        if (contactId && contact && contact.companyId && !company) {
            dispatch(showCompany({ id: contact.companyId }));
        }
    }, [contactId, contact, company, dispatch]);

    return contact ? (
        <>
            {company && (
                <Link to={contactPath(contactId)} icon={link} variant="h6" disabled={!link}>
                    {company.name}
                </Link>
            )}

            <Typography variant="h3" color="primary" className={classes.subtitle}>
                {t('components.ContactBaseData.contactOptions')}
            </Typography>

            <Divider className={classes.divider} />

            {contactDataIds.map(contactDataId => (
                <React.Fragment key={contactDataId}>
                    <ContactData key={contactDataIds} contactDataId={contactDataId} />
                    <Divider className={classes.divider} />
                </React.Fragment>
            ))}

            <Typography variant="h3" color="primary" className={classes.subtitle}>
                {t('components.ContactBaseData.addresses')}
            </Typography>

            <Divider className={classes.divider} />

            {addressIds.map(addressId => (
                <React.Fragment key={addressId}>
                    <AddressDisplay addressId={addressId} />
                    <Divider className={classes.divider} />
                </React.Fragment>
            ))}
        </>
    ) : null;
};

CompanyPreview.propTypes = {
    contactId: IdPropType,
    link: PropTypes.bool,
};

CompanyPreview.defaultProps = {
    contactId: null,
    link: false,
};

export default CompanyPreview;
