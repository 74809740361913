import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';
import classNames from 'classnames';
import Grid from '@mui/material/Grid';

import { selectContactTypesByIds } from '../../modules/contactTypes/selectors';
import { IdPropType } from '../../modules/proptypes';
import { ContactTypePropType } from '../../modules/contactTypes/proptypes';
import { ConditionalWrapper } from '../utils/ConditionalWrapper';

const useStyles = makeStyles({
    root: {
        textTransform: 'uppercase',
        fontWeight: 600,
    },
});

const SingleIcon = ({ contactType, className, variant, ...other }) => {
    const classes = useStyles();

    return (
        <ConditionalWrapper
            condition={contactType && contactType.name}
            wrapper={children => (
                <Tooltip title={contactType.name} disableInteractive>
                    {children}
                </Tooltip>
            )}
        >
            <Typography
                className={classNames({
                    [classes.root]: true,
                    [className]: true,
                })}
                variant={variant}
                component="p"
                {...other}
            >
                {contactType ? contactType.name_short.substring(0, 2) : null}
            </Typography>
        </ConditionalWrapper>
    );
};

SingleIcon.propTypes = {
    contactType: ContactTypePropType.isRequired,
    className: PropTypes.string,
    variant: PropTypes.string,
};

SingleIcon.defaultProps = {
    className: null,
    variant: 'subtitle2',
};

const ContactTypeIcon = ({ typeIds, className, variant, ...other }) => {
    const contactTypes = useSelector(state => selectContactTypesByIds(state, typeIds));

    if (contactTypes && !contactTypes.includes(undefined)) {
        contactTypes.sort((a, b) => (a.name_short > b.name_short ? 1 : -1));
    }

    return (
        <Grid container>
            <Grid item>
                {contactTypes &&
                    contactTypes.map(
                        contactType =>
                            contactType && (
                                <SingleIcon
                                    key={contactType.id}
                                    contactType={contactType}
                                    className={className}
                                    variant={variant}
                                    {...other}
                                />
                            )
                    )}
            </Grid>
        </Grid>
    );
};

ContactTypeIcon.propTypes = {
    typeIds: PropTypes.arrayOf(IdPropType).isRequired,
    className: PropTypes.string,
    variant: PropTypes.string,
};

ContactTypeIcon.defaultProps = {
    className: null,
    variant: 'subtitle2',
};

export default ContactTypeIcon;
