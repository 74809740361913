import { CHECKLIST_RESOURCE } from '../api/resources';
import { selectChecklistItemsByChecklistId } from '../checklistItems/selectors';

export const selectAllChecklistsById = state => state[CHECKLIST_RESOURCE].byId;

export const selectAllChecklistIds = state => state[CHECKLIST_RESOURCE].allIds;

export const selectChecklistById = (state, id) => selectAllChecklistsById(state)[id];

export const selectChecklistIdsByTaskId = (state, taskId) =>
    state[CHECKLIST_RESOURCE].byTaskId[taskId];

export const selectChecklistsByTaskId = (state, taskId) => {
    const byTaskId = selectChecklistIdsByTaskId(state, taskId);

    if (!byTaskId) {
        return [];
    }

    return byTaskId.map(checklistId => {
        return selectChecklistById(state, checklistId);
    });
};

export const selectChecklistAndItemsByTaskId = (state, taskId) => {
    const checklists = selectChecklistsByTaskId(state, taskId);

    return checklists.map(checklist => ({
        ...checklist,
        items: selectChecklistItemsByChecklistId(state, checklist.id),
    }));
};

export const selectChecklistsLoading = state => state.loading;

export const selectLastChecklistSortByTaskId = (state, taskId) => {
    const checklists = selectChecklistsByTaskId(state, taskId) || [];
    return checklists.length > 0 ? Math.max(...checklists.map(c => c.sort)) : 0;
};
