/**
 * themeOverride is spread without nested merging!
 */
import { createTheme } from '@mui/material/styles';

const configureTheme = (overrideOptions = {}, themeOverride = {}) => {
    const primaryColor = overrideOptions.palette && overrideOptions.palette.primary;
    const secondaryColor = overrideOptions.palette && overrideOptions.palette.secondary;
    const backgroundPrimaryColor =
        overrideOptions.palette && overrideOptions.palette.primaryBackground;

    const options = {
        palette: {
            primary: {
                main: primaryColor || '#033466',
            },

            secondary: {
                main: secondaryColor || '#CB0E00',
            },

            text: {
                primary: '#333333',
                secondary: '#737373',
            },

            background: {
                paper: '#fff',
                default: '#fafafa',
                error: '#fae9e9',
                primary: backgroundPrimaryColor || '#E5EAF1',
            },

            success: {
                light: '#81c784',
                main: '#4caf50',
                dark: '#388e3c',
                contrastText: '#fff',
            },

            border: {
                main: '#E6E6E6',
            },
        },
        typography: {
            // fontFamily: '"Playfair Display", serif',
            // fontFamily: '"Roboto", sans-serif',
            fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
            // fontWeightLight: 100,
            // fontWeightRegular: 300,
            // htmlFontSize: '14px',
            // fontSize: 12,

            h1: {
                fontWeight: 700,
                fontSize: '1.5rem',
            },
            h2: {
                fontSize: '1.375rem',
                lineHeight: '1.4rem',
                fontWeight: 700,
            },
            h3: {
                fontSize: '1rem',
                lineHeight: '1.2rem',
                fontWeight: 700,
            },
            h4: {
                fontSize: '0.875rem',
                lineHeight: '1.2rem',
                fontWeight: 700,
                textTransform: 'uppercase',
                letterSpacing: 1,
            },
            h5: {
                fontSize: '0.75rem',
                lineHeight: '1.2rem',
                fontWeight: 700,
            },
            subtitle1: {
                fontSize: '0.75rem',
                lineHeight: 1.5,
            },
            subtitle2: {
                fontSize: '0.75rem',
                lineHeight: 1.43,
                fontWeight: 700,
            },
            body1: {
                fontSize: '0.875rem',
                lineHeight: 1.5,
            },
            body2: {
                fontSize: '0.75rem',
                lineHeight: 1.43,
            },
            button: {
                fontSize: '0.75rem',
                lineHeight: 1.75,
                fontWeight: 700,
            },
        },
        components: {
            MuiTab: {
                styleOverrides: {
                    root: {
                        fontSize: '0.875rem',
                        fontWeight: 400,
                        textTransform: 'none',
                        alignItems: 'flex-start',
                        paddingLeft: '20px',
                    },
                },
            },

            MuiButton: {
                styleOverrides: {
                    contained: {
                        boxShadow: 'none',
                    },
                    sizeSmall: {
                        fontSize: '0.75rem',
                    },
                    outlinedSizeSmall: {
                        fontSize: '0.75rem',
                    },
                },
            },

            MuiInputBase: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#ffffff',
                    },
                },
            },

            KanbanBoard: {
                styleOverrides: {
                    root: { background: '#F9F9F9', paddingBottom: 8 },
                },
            },

            KanbanList: {
                styleOverrides: {
                    root: {
                        padding: 0,
                        boxShadow: 'none',
                        border: '1px solid #ededed',
                        borderRadius: '5px 5px 0 0',
                    },
                },
            },

            KanbanListBody: {
                styleOverrides: {
                    root: { background: 'white' },
                },
            },

            ListFilterBar: {
                styleOverrides: {
                    root: {
                        padding: '4px 8px',
                        margin: 0,
                        background: primaryColor || '#033466',
                        borderRadius: '4px 4px 0 0',
                        color: 'white',
                    },

                    title: {
                        marginLeft: 8,
                        textTransform: 'uppercase',
                        fontSize: '0.85rem',
                        fontWeight: 600,
                        letterSpacing: '0.07em',
                    },
                },
            },

            ...themeOverride,
        },
    };

    return createTheme(options);
    // theme = responsiveFontSizes(theme); // TODO responsive fonts how?
};

export default configureTheme;
