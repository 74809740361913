import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { autocompleteDatacontainers } from '../../../../modules/datacontainers/actions';
import TypedAutocomplete from '../../base/autocomplete/TypedAutocomplete';
import { useFieldFast } from '../../../../modules/form/hooks';
import Confirmation from '../../Confirmation';

// TODO: extract confirmation workflow into component and/or hook

const DatacontainerAutocomplete = ({
    name,
    confirm,
    variant,
    label,
    forcePrefix,
    contrast,
    fetchParams,
    onChange,
    fullWidth,
    size,
    I,
}) => {
    const { t } = useTranslation();
    const [{ value }, { initialValue }, helpers] = useFieldFast(name);
    const ref = useRef(null);
    const [tempValue, setTempValue] = useState(initialValue);
    const popupStateRef = useRef(null);

    const handleConfirm = () => {
        helpers.setValue(tempValue);

        if (onChange) {
            onChange(value);
        }
    };

    const handleAbort = () => {
        setTempValue(value);
    };

    const handleChangeIntent = (newValue, oldValue, setValue) => {
        if (value === newValue || (value && newValue && value.value === newValue.value)) {
            return;
        }

        if (confirm && initialValue) {
            setTempValue(newValue);
            popupStateRef.current.open(ref.current);
        } else {
            helpers.setValue(newValue);
            setTempValue(newValue);
            if (onChange) {
                onChange(newValue);
            }
        }

        if (confirm && !newValue) {
            setValue(oldValue.value);
        }
    };

    const handleAutocomplete = ({ q }) => {
        const query = forcePrefix && !q.includes(forcePrefix) ? `${forcePrefix}: ${q}` : q;
        return autocompleteDatacontainers({ q: query, ...fetchParams });
    };

    useEffect(() => {
        setTempValue(value);
    }, [value, setTempValue]);

    return (
        <>
            <TypedAutocomplete
                name={name}
                fetchAction={handleAutocomplete}
                value={tempValue}
                initialValue={tempValue?.value}
                onChange={handleChangeIntent}
                label={label}
                fullWidth={fullWidth}
                contrast={contrast}
                size={size}
                innerRef={ref}
                variant={variant}
                blurOnSelect
                clearOnBlur
                forceEmptyInitialValue
                I={I}
            />
            <Confirmation
                popupId="datacontainerAutocompletePopover"
                popupStateRef={popupStateRef}
                onConfirm={handleConfirm}
                onAbort={handleAbort}
                label={t(
                    `components.DatacontainerAutocomplete.${tempValue ? 'replace' : 'remove'}`
                )}
                color={tempValue ? 'default' : 'danger'}
            />
        </>
    );
};

DatacontainerAutocomplete.propTypes = {
    name: PropTypes.string.isRequired,
    confirm: PropTypes.bool,
    variant: PropTypes.string,
    label: PropTypes.string,
    forcePrefix: PropTypes.string,
    contrast: PropTypes.bool,
    fetchParams: PropTypes.shape({}),
    onChange: PropTypes.func,
    fullWidth: PropTypes.bool,
    size: PropTypes.string,
    I: PropTypes.string,
};

DatacontainerAutocomplete.defaultProps = {
    confirm: false,
    variant: 'outlined',
    label: '',
    forcePrefix: null,
    contrast: false,
    fetchParams: {},
    onChange: null,
    fullWidth: false,
    size: 'medium',
    I: null,
};

export default DatacontainerAutocomplete;
