import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { Popover } from '@mui/material';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import LinkOffOutlinedIcon from '@mui/icons-material/LinkOffOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { RichUtils } from 'draft-js';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { EditorStatePropType } from '../../../../../modules/proptypes';
import TextField from '../../../base/TextField';
import { EDITOR_LINK, makeLink } from '../../../../../modules/editor/link';
import { getElementData, getElements } from '../../../../../modules/editor/element';
import { iconColor } from '../../../../../modules/editor/utils';
import IconButton from '../../../base/IconButton';

const useStyles = makeStyles({
    button: {},
    icon: {
        fontSize: '.75em',
    },
});

const ToolbarLink = ({ editorState, doChange, doFocus, disabled }) => {
    const { t } = useTranslation('basics');
    const classes = useStyles();

    const [openLinkPopover, setOpenLinkPopover] = useState(null);
    const [link, storeLink] = useState('');

    const setSafeLink = url => {
        if (typeof url !== 'undefined') {
            storeLink(url);
        }
    };

    const getSelected = () => {
        return getElements(editorState, EDITOR_LINK, true);
    };

    const isSelected = () => {
        return getSelected().length !== 0;
    };

    const setLink = () => {
        const updated = makeLink(editorState, link);
        doChange(
            RichUtils.toggleLink(
                updated.editorState,
                editorState.getSelection(),
                updated.entity.getLastCreatedEntityKey()
            )
        );
        doFocus();
    };

    const removeLink = () => {
        const selection = editorState.getSelection();

        if (!selection.isCollapsed()) {
            doChange(RichUtils.toggleLink(editorState, selection, null));
        }
    };

    const getSelectedLink = () => {
        // eslint-disable-next-line no-use-before-define
        const selection = getSelected();

        if (selection.length === 1) {
            const data = getElementData(selection[0]);
            setSafeLink(data.url);
        } else {
            let url = null;

            selection.find(element => {
                const elData = getElementData(element);
                if (elData.url) {
                    url = elData.url;
                    return true;
                }
                return false;
            });
            setSafeLink(url);
        }
    };

    const handleLinkChange = e => {
        if (!e.target) {
            return '';
        }
        if (e.target.value.includes('http')) {
            return storeLink(e.target.value);
        }
        return storeLink(`https://${e.target.value}`);
    };

    const open = e => {
        getSelectedLink();
        setOpenLinkPopover(e.currentTarget);
    };

    const close = () => setOpenLinkPopover(null);

    const renderRemoveButton = () => {
        if (!isSelected()) {
            return null;
        }

        return (
            <IconButton
                className={classes.button}
                onClick={removeLink}
                label={t('components.EditorToolbar.removeLink')}
            >
                <LinkOffOutlinedIcon className={classes.icon} />
            </IconButton>
        );
    };

    return (
        <>
            <IconButton
                className={classes.button}
                onClick={open}
                disabled={disabled}
                label={t('components.EditorToolbar.link')}
            >
                <LinkOutlinedIcon className={classes.icon} color={iconColor(isSelected())} />
            </IconButton>
            <Popover
                open={openLinkPopover !== null}
                onClose={close}
                anchorEl={openLinkPopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Grid container style={{ alignItems: 'center', paddingTop: '3px' }}>
                    <TextField
                        type="url"
                        autoFocus
                        defaultValue={link}
                        onChange={handleLinkChange}
                        style={{ margin: '2px' }}
                        label={t('components.ProtocolItemForm.linkPlaceholder')}
                        sx={{ '& label': { '&.Mui-focused': { zIndex: 1000 } } }}
                    />
                    {renderRemoveButton()}
                    <IconButton onClick={setLink} label={t('components.EditorToolbar.saveLink')}>
                        <SaveOutlinedIcon />
                    </IconButton>
                </Grid>
            </Popover>
        </>
    );
};

ToolbarLink.propTypes = {
    editorState: EditorStatePropType.isRequired,
    doChange: PropTypes.func.isRequired,
    doFocus: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

ToolbarLink.defaultProps = {
    disabled: false,
};

export default ToolbarLink;
