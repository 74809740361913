import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectAppointmentTypeById } from '../../../modules/appointmentTypes/appointmentTypeSlice';
import { formatAppointmentTypeResourceGroups } from '../../../modules/appointments/utils';
import { useTranslation } from 'react-i18next';
import CalendarDetailResourceGroupText from '../detail/CalendarDetailResourceGroupText';
import { IdPropType } from '../../../modules/proptypes';

const CalendarDetailResourcesText = ({ appointmentTypeId, groupedIds }) => {
    const { t } = useTranslation();
    const appointmentType = useSelector(state =>
        selectAppointmentTypeById(state, appointmentTypeId)
    );

    const { groupKeys, groupConfig } = useMemo(
        () => formatAppointmentTypeResourceGroups(appointmentType),
        [appointmentType]
    );

    return groupKeys.map(groupKey => (
        <p>
            <CalendarDetailResourceGroupText
                groupConfig={groupConfig[groupKey]}
                groupedIds={Array.isArray(groupedIds) ? groupedIds : groupedIds[groupKey]}
            />
        </p>
    ));
};

CalendarDetailResourcesText.propTypes = {
    appointmentTypeId: IdPropType.isRequired,
    groupedIds: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array]).isRequired,
};

CalendarDetailResourcesText.defaultProps = {};

export default CalendarDetailResourcesText;
