import { ACTIVITY_RESOURCE } from '../api/resources';
import { createResourceSlice } from '../redux/resource/createResourceSlice';
import { RESPONSIBLE_ACTIVITY, RESPONSIBLE_REMOVE_ACTIVITY } from './utils';

const insertActivity = (state, activity) => {
    const stale = state.byId[activity.id];

    /**
     * responsible activities must have at least a card object to be valid,
     * sometimes the api sends invalid activities -> filter these out
     * */
    if (
        [RESPONSIBLE_ACTIVITY, RESPONSIBLE_REMOVE_ACTIVITY].includes(activity.type) &&
        !activity.card
    ) {
        if (stale) {
            delete state.byId[activity.id];
            state.allIds = state.allIds.filter(id => id !== activity.id);
        }
        return;
    }

    if (!stale) {
        state.allIds.push(activity.id);
    }

    state.byId[activity.id] = activity;
};

const activitiesSlice = createResourceSlice({
    resource: ACTIVITY_RESOURCE,
    reducers: {
        indexFulfilled: (state, action) => {
            action.payload.forEach(activity => {
                insertActivity(state, activity);
            });
            state.initialize.loading = false;
            state.initialize.done = true;
        },

        showFulfilled: (state, action) => {
            insertActivity(state, action.payload);
        },

        storeFulfilled: (state, action) => {
            insertActivity(state, action.payload);
        },

        updateFulfilled: (state, action) => {
            insertActivity(state, action.payload);
        },

        readPending: state => {
            Object.values(state.byId).forEach(activity => {
                if (!activity.read_at) {
                    activity.read_at = true;
                }
            });
        },

        destroyAllFulfilled: state => {
            state.byId = {};
            state.allIds = [];
        },
    },
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,

    indexActivities,
    showActivity,
    storeActivity,
    updateActivity,
    restoreActivity,
    destroyActivity,
    destroyAllActivities,
    readActivities,
} = activitiesSlice.actions;

/* Selectors */
const {
    selectActivityById,
    selectActivitiesById,
    selectAllActivityIds,
    selectAllActivities,
    selectActivitiesInitialized,
} = activitiesSlice.selectors;

const selectActivitiesUnread = state =>
    selectAllActivities(state).some(({ read_at: readAt }) => !readAt);

export {
    selectActivityById,
    selectActivitiesById,
    selectAllActivityIds,
    selectAllActivities,
    selectActivitiesInitialized,
    selectActivitiesUnread,
};

export default activitiesSlice.reducer;
