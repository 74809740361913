import React from 'react';
import * as PropTypes from 'prop-types';
import { DialogContent, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCallbackRef } from '../../hooks';
import Dialog from '../../../components/dialogs/Dialog';
import DialogFormActions from '../../../components/dialogs/DialogFormActions';
import ProtocolForm from './ProtocolForm';
import { IdPropType } from '../../proptypes';
import ProtocolDeleteButton from './ProtocolDeleteButton';

const ProtocolDialog = ({ cardId, protocolId, onClose }) => {
    const { t } = useTranslation();
    const ref = useCallbackRef();

    return (
        <Dialog fullWidth maxWidth="sm">
            <DialogTitle>
                {t(`form.ProtocolForm.${protocolId ? 'editProtocol' : 'addProtocol'}`)}
            </DialogTitle>

            <DialogContent>
                <ProtocolForm
                    cardId={cardId}
                    protocolId={protocolId}
                    onDone={onClose}
                    submitRef={ref}
                />
            </DialogContent>

            <DialogFormActions ref={ref}>
                {protocolId && (
                    <ProtocolDeleteButton
                        protocolId={protocolId}
                        popupId="ProtocolDelete"
                        onConfirm={onClose}
                    />
                )}
            </DialogFormActions>
        </Dialog>
    );
};

ProtocolDialog.propTypes = {
    cardId: IdPropType.isRequired,
    protocolId: IdPropType,
    onClose: PropTypes.func.isRequired,
};

ProtocolDialog.defaultProps = {
    protocolId: null,
};

export default ProtocolDialog;
