import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Rotate90DegreesCcwIcon from '@mui/icons-material/Rotate90DegreesCcw';
import { useDispatch } from 'react-redux';
import { TASK_RESOURCE } from '../../modules/api/resources';
import { IdPropType } from '../../modules/proptypes';
import Checkbox from '../form/formik/Checkbox';
import { storeTask } from '../../modules/tasks/tasksSlice';
import FormPopup from '../popups/FormPopup';
import IconButton from '../form/base/IconButton';
import { CREATE } from '../../modules/abilities/actions';
import ClickBoundary from '../form/ClickBoundary';
import Button from '../form/base/Button';
import { useSnackbar } from 'notistack';
import { showDialog } from '../../modules/dialogs/dialogSlice';
import { TASK_DIALOG } from '../dialogs/dialogs';

const TaskTemplateConverter = ({ taskId }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const popupRef = useRef();

    const handleConvertTask = (selectedRelations, closePopup) => {
        const relations = Object.entries(selectedRelations).reduce((carry, [key, isActive]) => {
            if (isActive) {
                return [...carry, key];
            }

            return carry;
        }, []);

        return dispatch(storeTask({ id: taskId, card_id: null, relations })).then(({ data }) => {
            closePopup();

            enqueueSnackbar(t('components.TaskTemplateConvert.done'), {
                key: `taskTemplateConverted.${taskId}.${data?.id}`,
                variant: 'success',
                autoHideDuration: 8000,
                preventDuplicate: true,
                anchorOrigin: {
                    horizontal: 'center',
                    vertical: 'top',
                },
                action: (
                    <Button
                        onClick={() => {
                            dispatch(
                                showDialog({ type: TASK_DIALOG, props: { taskId: data?.id } })
                            );
                        }}
                        variant="outlined"
                        size="small"
                        contrast
                    >
                        {t('components.TaskTemplateConvert.show')}
                    </Button>
                ),
            });
        });
    };

    return (
        <ClickBoundary>
            <FormPopup
                popupId="taskTemplateForm"
                headline={t('components.TaskTemplateConvert.title')}
                subheadline={t('components.TaskTemplateConvert.subhead')}
                submitLabel={t('components.TaskTemplateConvert.submit')}
                abortLabel={t('components.TaskTemplateConvert.abort')}
                popupStateRef={popupRef}
                subject={TASK_RESOURCE}
                postponeSubmitClose
                onSubmit={handleConvertTask}
                initialValues={{
                    checklists: true,
                    appointments: true,
                    responsibles: true,
                }}
                FormInputs={
                    <>
                        <Checkbox
                            name="checklists"
                            label={t('components.TaskTemplateConvert.checklists')}
                            color="secondary"
                        />
                        <Checkbox
                            name="appointments"
                            label={t('components.TaskTemplateConvert.appointments')}
                            color="secondary"
                        />
                        <Checkbox
                            name="responsibles"
                            label={t('components.TaskTemplateConvert.responsibles')}
                            color="secondary"
                        />
                    </>
                }
            >
                <IconButton
                    size="small"
                    action={CREATE}
                    subject={{ __type: 'Task', card_id: null }}
                >
                    <Rotate90DegreesCcwIcon />
                </IconButton>
            </FormPopup>
        </ClickBoundary>
    );
};
TaskTemplateConverter.propTypes = {
    taskId: IdPropType.isRequired,
};

export default TaskTemplateConverter;
