import React from 'react';
import { EditorState, Modifier } from 'draft-js';
import { remarkableCommonPlugin } from './common';

export const getTagValue = elementData => /data-name="(.*?)"/.exec(elementData)[1];

const createTag = (elementData, state) => {
    const matchHashtagName = getTagValue(elementData);

    state.push({
        type: 'tag_open',
        name: matchHashtagName,
        level: state.level,
    });

    state.push({
        type: 'text',
        content: `#${matchHashtagName}`,
        level: state.level + 1,
    });

    state.push({
        type: 'tag_close',
        level: state.level,
    });
};

export const activateTag = editorState => {
    const selectionState = editorState.getSelection();
    const contentState = editorState.getCurrentContent();

    const currentBlockKey = selectionState.getStartKey();
    const currentBlock = contentState
        .getBlocksAsArray()
        .find(block => block.key === currentBlockKey);

    const offset = selectionState.getAnchorOffset();
    const charBeforeCursor = currentBlock.text.charAt(Math.max(offset - 1, 0));
    const insertText = charBeforeCursor === ' ' || offset === 0 ? '#' : ' #';

    const newContentState = Modifier.insertText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        insertText
    );

    const newEditorState = EditorState.push(editorState, newContentState, 'insert-fragment');
    return EditorState.moveFocusToEnd(newEditorState);
};

// Beispiel: https://github.com/Rosey/markdown-draft-js/blob/master/test/markdown-to-draft.spec.js#L415
export const remarkableTagPlugin = remarkable => {
    remarkable.inline.ruler.push('tag', (state, silent) => {
        const tagRegEx = /^<tag (.*?)>.*?<\/tag>/;
        return remarkableCommonPlugin(state, silent, tagRegEx, createTag);
    });
};

export const markdownTagOptions = {
    html: [
        {
            check: value => value.startsWith('<tag'),
            replace: value => {
                const tag = `#${getTagValue(value)}`;
                return <span style={{ color: '#89A' }}>{tag}</span>;
            },
        },
        {
            check: value => value.startsWith('</tag'),
            replace: () => '',
        },
    ],
    text: [{ check: value => value.startsWith('#:'), replace: () => '' }],
};
