import React from 'react';
import { IdPropType } from '../../modules/proptypes';
import TaskInfos from './TaskInfos';
import { useSelector } from 'react-redux';
import { selectTaskById, useTask } from '../../modules/tasks/tasksSlice';
import Grid from '@mui/material/Grid';
import AppointmentBundlePreview from '../previews/AppointmentBundlePreview';
import TaskLinks from '../tasks/TaskLinks';
import { selectCardById } from '../../modules/cards/selectors';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';

const TaskDetail = ({ taskId }) => {
    const { t } = useTranslation();
    const [task] = useTask(taskId);
    const card = useSelector(state => selectCardById(state, task?.card_id));

    return task ? (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <TaskInfos task={task} />
            </Grid>
            <Grid item>
                <Divider />
            </Grid>
            <Grid item>
                <AppointmentBundlePreview taskId={taskId} />
            </Grid>
            {card ? (
                <>
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item>
                        <Typography variant="h4" color="primary">
                            {t('components.TaskDetail.links')}
                        </Typography>
                        <TaskLinks task={task} card={card} m={1} />
                    </Grid>
                </>
            ) : null}
        </Grid>
    ) : null;
};

TaskDetail.propTypes = {
    taskId: IdPropType.isRequired,
};

export default TaskDetail;
