import React from 'react';
import * as PropTypes from 'prop-types';
import IconButton from '../form/base/IconButton';
import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from '@mui/styles';
import { useCan } from '../../modules/abilities/hooks';
import { CREATE } from '../../modules/abilities/actions';
import { APPOINTMENT_RESOURCE } from '../../modules/api/resources';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
    button: {
        position: 'absolute',
        bottom: 24,
        right: 24,
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        transition: 'background .2s, bottom .2a',

        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },

        '&:active': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    buttonRaised: {
        bottom: 42,
    },
    icon: {
        color: 'white',
    },
}));

const CalendarCreateButton = ({ raised, onClick }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const canDo = useCan(CREATE, APPOINTMENT_RESOURCE);

    return (
        <IconButton
            onClick={onClick}
            className={classNames({
                [classes.button]: true,
                [classes.buttonRaised]: raised,
            })}
            disabled={!canDo}
            size="large"
            aria-label="add"
            label={t('components.Calendar.CalendarCreateButton.label')}
        >
            <AddIcon className={classes.icon} />
        </IconButton>
    );
};

CalendarCreateButton.propTypes = {
    raised: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
};

CalendarCreateButton.defaultProps = {
    raised: false,
};

export default CalendarCreateButton;
