import React, { useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import TextInput from '../form/formik/TextInput';
import Form from '../form/formik/Form';
import Button from '../form/base/Button';
import SubmitButton from '../form/formik/SubmitButton';
import {
    archiveChecklist,
    storeChecklist,
    updateChecklist,
} from '../../modules/checklists/actions';
import { IdPropType } from '../../modules/proptypes';
import Confirmation from '../form/Confirmation';
import LoadingButton from '../loading/LoadingButton';
import { CHECKLIST_RESOURCE } from '../../modules/api/resources';
import { checklistSchema } from '../../modules/checklists/schema';
import { DELETE } from '../../modules/abilities/actions';
import ContextualCan from '../../modules/abilities/ContextualCan';
import { selectChecklistById } from '../../modules/checklists/checklistSlice';
import { selectLastChecklistSortByTaskId } from '../../modules/checklists/selectors';

const ChecklistForm = ({ checklistId, taskId, onEdit, can }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [deleteLoading, setDeleteLoading] = useState(false);
    const checklist = useSelector(state => selectChecklistById(state, checklistId));
    const checklistLastSort = useSelector(state => selectLastChecklistSortByTaskId(state, taskId));

    const initialValues = useMemo(() => checklist || { name: '' }, [checklist]);

    const handleSubmit = values => {
        const prepped = {
            ...values,
            task_id: taskId,
            sort: checklist ? checklist.sort : checklistLastSort + 1,
        };
        return dispatch(checklistId ? updateChecklist(prepped) : storeChecklist(prepped)).then(
            () => {
                onEdit(false);
            }
        );
    };

    const handleAbort = () => onEdit(false);

    const handleDeleteConfirmed = () => {
        setDeleteLoading(true);
        return dispatch(archiveChecklist({ id: checklistId, taskId })).then(() => {
            onEdit(false);
            setDeleteLoading(false);
        });
    };

    return (
        <Form
            width={1}
            initialValues={initialValues}
            validationSchema={checklistSchema}
            onSubmit={handleSubmit}
            subject={checklist || CHECKLIST_RESOURCE}
        >
            <Box mb={1}>
                <TextInput
                    data-test-id="add-checklist-input"
                    name="name"
                    label={t('components.Checklist.name')}
                    size="small"
                    fullWidth
                    can={can}
                />
            </Box>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Button onClick={handleAbort} size="small">
                        {t('abort')}
                    </Button>
                </Grid>

                {checklist && (
                    <Grid item>
                        <ContextualCan I={DELETE}>
                            <Confirmation
                                popupId="deleteChecklistItemPopover"
                                onConfirm={handleDeleteConfirmed}
                                label={t('components.DestroyIntent.confirm')}
                                color="danger"
                            >
                                <LoadingButton
                                    data-test-id="delete-checklist-btn"
                                    isLoading={deleteLoading}
                                    size="small"
                                    color="danger"
                                >
                                    {t('delete')}
                                </LoadingButton>
                            </Confirmation>
                        </ContextualCan>
                    </Grid>
                )}

                <Grid item>
                    <SubmitButton I={can} data-test-id="save-checklist-btn" variant="contained">
                        {t('form.SubmitButton.defaultLabel')}
                    </SubmitButton>
                </Grid>
            </Grid>
        </Form>
    );
};

ChecklistForm.propTypes = {
    checklistId: IdPropType,
    taskId: IdPropType.isRequired,
    onEdit: PropTypes.func.isRequired,
    can: PropTypes.string,
};

ChecklistForm.defaultProps = {
    checklistId: null,
    can: null,
};

export default ChecklistForm;
