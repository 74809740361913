import React from 'react';
import * as PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import IconButton from '../form/base/IconButton';
import Can from '../../modules/abilities/Can';
import { READ, UPDATE } from '../../modules/abilities/actions';
import { makeStyles } from '@mui/styles';
import LoadingIcon from '../loading/LoadingIcon';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        height: 24,
    },

    iconContainer: {
        height: 24,
    },

    buttonContainer: {
        position: 'relative',
    },
}));

const CompletedIconButton = ({
    name,
    isCompleted,
    subject,
    loading,
    small,
    contrast,
    onChange,
    ...other
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const handleChange = () => {
        onChange(!isCompleted);
    };

    return (
        <Can I={READ} this={subject} field={name}>
            <Grid container className={isCompleted ? classes.root : null}>
                {isCompleted && (
                    <Grid item mr={1} className={classes.iconContainer}>
                        <DoneAllIcon color="success" />
                    </Grid>
                )}
                <Grid item className={classes.buttonContainer}>
                    <LoadingIcon
                        isLoading={loading}
                        size="small"
                        style={contrast ? { color: 'white' } : null}
                    >
                        <IconButton
                            onClick={handleChange}
                            size={isCompleted ? 'tiny' : small ? 'small' : 'large'}
                            subject={subject}
                            action={UPDATE}
                            field={name}
                            {...other}
                            label={t(
                                `components.Calendar.CalendarDetailHead.${
                                    isCompleted ? 'unDone' : 'done'
                                }`
                            )}
                        >
                            {isCompleted ? <UndoOutlinedIcon /> : <DoneAllIcon />}
                        </IconButton>
                    </LoadingIcon>
                </Grid>
            </Grid>
        </Can>
    );
};

CompletedIconButton.propTypes = {
    name: PropTypes.string,
    isCompleted: PropTypes.bool,
    subject: PropTypes.any,
    loading: PropTypes.bool,
    small: PropTypes.bool,
    contrast: PropTypes.bool,
    onChange: PropTypes.func,
};

CompletedIconButton.defaultProps = {
    name: null,
    isCompleted: false,
    subject: null,
    loading: false,
    small: false,
    contrast: false,
    onChange: null,
};

export default CompletedIconButton;
