import React from 'react';
import { AppointmentTooltip } from '@devexpress/dx-react-scheduler-material-ui';

const CalendarDetailLayout = props => {
    return (
        <AppointmentTooltip.Layout
            PaperProps={{ style: { width: 480 } }}
            disablePortal
            {...props}
        />
    );
};

export default CalendarDetailLayout;
