import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import { Button, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import ContactDatasInputRow from './ContactDatasInputRow';

const useStyles = makeStyles(theme => ({
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}));

export const getNewContactData = (type = '', method = '') => ({
    id: null, // needed by ContactDatasInputRow to know what to render :'(
    type,
    method,
    value: '',
    comment: '',
});

const ContactDatasInput = ({ name, initialEdit }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const [{ value }, , { setValue }] = useField(name);

    const [editIds, setEditIds] = useState(initialEdit);

    const handleAdd = () => {
        setValue([...value, getNewContactData('office')]);
        setEditIds([value.length]);
    };

    const handleDelete = index => {
        const cutValues = [...value];
        cutValues.splice(index, 1);
        setValue(cutValues);
        setEditIds([]);
    };

    return (
        <>
            {value &&
                value.map((contact, index) => (
                    <React.Fragment key={contact.id || `${name}.${index}`}>
                        <ContactDatasInputRow
                            name={`${name}.${index}`}
                            initialEdit={editIds.includes(index)}
                            onDelete={() => handleDelete(index)}
                        />
                        <Divider className={classes.divider} />
                    </React.Fragment>
                ))}
            <Box mb={3}>
                <Button
                    data-test-id="add-contact-data-btn"
                    variant="text"
                    color="primary"
                    size="small"
                    p={0}
                    onClick={handleAdd}
                    startIcon={<AddIcon />}
                >
                    {t('components.ContactData.addContactData')}
                </Button>
            </Box>
        </>
    );
};

ContactDatasInput.propTypes = {
    name: PropTypes.string.isRequired,
    initialEdit: PropTypes.arrayOf(PropTypes.number),
};

ContactDatasInput.defaultProps = {
    initialEdit: [],
};

export default ContactDatasInput;
