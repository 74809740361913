/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { CARD_STATUS_RESOURCE } from '../api/resources';

const insertCardStatus = (state, cardStatus) => {
    const stale = state.byId[cardStatus.id];

    if (!stale) {
        state.allIds.push(cardStatus.id);
    }

    state.byId[cardStatus.id] = {
        ...cardStatus,
    };
};

const cardStatusSlice = createSlice({
    name: CARD_STATUS_RESOURCE,
    initialState: {
        byId: {},
        allIds: [],
        error: null,
        loading: false,
    },
    reducers: {
        indexPending: state => {
            state.loading = true;
        },
        indexFulfilled: {
            prepare: (payload, meta) => ({ payload, meta }),
            reducer: (state, action) => {
                state.loading = false;

                action.payload.forEach(cardStatus => {
                    insertCardStatus(state, cardStatus);
                });
            },
        },
        indexError: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
        showFulfilled: (state, action) => {
            const cardStatus = action.payload;
            state.byId[cardStatus.id] = cardStatus;
            state.allIds = [...new Set([...state.allIds, cardStatus.id])];
        },
        storeFulfilled: (state, action) => {
            insertCardStatus(state, action.payload);
        },
        updateFulfilled: (state, action) => {
            insertCardStatus(state, action.payload);
        },
        destroyFulfilled: (state, action) => {
            const id = action.payload;

            const index = state.allIds.findIndex(_id => _id === id);
            if (index >= 0) {
                delete state.byId[id];
                state.allIds.splice(index, 1);
            }
        },
    },
});

export const {
    indexPending,
    indexFulfilled,
    indexError,
    showFulfilled,
    storeFulfilled,
    destroyFulfilled,
} = cardStatusSlice.actions;

export default cardStatusSlice.reducer;
