import { RESOURCE_RESOURCE } from '../api/resources';
import { createResourceSlice } from '../redux/resource/createResourceSlice';

const resourcesSlice = createResourceSlice({
    resource: RESOURCE_RESOURCE,
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,

    indexResources,
    showResource,
    storeResource,
    updateResource,
    archiveResource,
    restoreResource,
    destroyResource,

    searchResources,
    autocompleteResources,
    suggestResources,
} = resourcesSlice.actions;

export const {
    selectResourceById,
    makeResourcesByIdsSelector,
    selectAllResourceIds,
    selectAllResources,
    selectResourcesLoading,
    selectResourcesInitialized,
    selectResourceLoading,
    selectResourceInitialized,
    selectResourceObtainablePages,
    selectResourceCurrentPage,
} = resourcesSlice.selectors;

export const { useResources, useResource } = resourcesSlice.hooks;

export default resourcesSlice.reducer;
