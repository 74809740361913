import { PROTOCOL_RESOURCE } from '../api/resources';
import { createResourceSlice } from '../redux/resource/createResourceSlice';

const protocolsSlice = createResourceSlice({
    resource: PROTOCOL_RESOURCE,
    byKey: 'cardId',
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,
    suggestProtocols,
    indexProtocols,
    showProtocol,
    storeProtocol,
    updateProtocol,
    archiveProtocol,
    restoreProtocol,
    destroyProtocol,
} = protocolsSlice.actions;

export const {
    selectProtocolById,
    makeProtocolsByIdsSelector,
    selectAllProtocolIds,
    selectAllProtocols,
    selectProtocolsLoading,
    selectProtocolsInitialized,
    selectProtocolLoading,
    selectProtocolInitialized,
    selectProtocolIdsByCardId,
    makeProtocolsByCardIdSelector,
    selectProtocolLoadingByCardId,
    selectProtocolInitializedByCardId,
} = protocolsSlice.selectors;

export const { useProtocols, useProtocol, useProtocolsByCardId } = protocolsSlice.hooks;

export default protocolsSlice.reducer;
