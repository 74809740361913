import React from 'react';
import * as PropTypes from 'prop-types';
import { useFieldFast } from '../../../../modules/form/hooks';
import BaseUserAutocomplete from '../../base/autocomplete/BaseUserAutocomplete';

const UserAutocomplete = ({ name, multiple, I, ...other }) => {
    const [{ value }, , { setValue }] = useFieldFast(name);

    const handleSelect = newValue => {
        if (multiple) {
            setValue(newValue.map(user => user.id));
        } else {
            setValue(newValue ? newValue.id : null);
        }
    };

    return (
        <BaseUserAutocomplete
            value={value}
            name={name}
            onChange={handleSelect}
            multiple={multiple}
            can={I}
            {...other}
        />
    );
};

UserAutocomplete.propTypes = {
    name: PropTypes.string.isRequired,
    multiple: PropTypes.bool,
    clearOnSubmit: PropTypes.bool,
    I: PropTypes.string,
};

UserAutocomplete.defaultProps = {
    multiple: false,
    clearOnSubmit: false,
    I: null,
};

export default UserAutocomplete;
