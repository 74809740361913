import React from 'react';
import * as PropTypes from 'prop-types';
import { ListItem, ListItemText } from '@mui/material';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { IdPropType } from '../../modules/proptypes';

const useStyles = makeStyles({
    root: {
        cursor: 'pointer',
    },
});

const ResourceListItem = ({ itemId, selector, primary, secondary, onClick }) => {
    const classes = useStyles();
    const item = useSelector(state => selector(state, itemId));

    const handleClick = () => {
        if (onClick) {
            onClick(itemId);
        }
    };

    return (
        <ListItem onClick={handleClick} className={classes.root}>
            <ListItemText primary={item[primary]} secondary={secondary && item[secondary]} />
        </ListItem>
    );
};

ResourceListItem.propTypes = {
    itemId: IdPropType.isRequired,
    selector: PropTypes.func.isRequired,
    primary: PropTypes.string.isRequired,
    secondary: PropTypes.string,
    onClick: PropTypes.func,
};

ResourceListItem.defaultProps = {
    secondary: null,
    onClick: null,
};

export default ResourceListItem;
