import { RESOURCE_TYPE_RESOURCE } from '../api/resources';

export const selectResourceTypesById = state => state[RESOURCE_TYPE_RESOURCE].byId;

export const selectAllResourceTypeIds = state => state[RESOURCE_TYPE_RESOURCE].allIds;

export const selectResourceTypeById = (state, id) => selectResourceTypesById(state)[id];

export const selectAllResourceTypes = state => Object.values(selectResourceTypesById(state));

export const selectResourceTypesInitialized = state => state[RESOURCE_TYPE_RESOURCE].initialized;

export const selectResourceTypesLoading = state => state[RESOURCE_TYPE_RESOURCE].loading;
