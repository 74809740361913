import React from 'react';
import * as PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import TextInput from './TextInput';

const JsonInput = ({ name, label, fullWidth, resizable, width, minRows, maxRows, ...other }) => {
    const formatInput = (inputValue = null) => {
        if (!inputValue) {
            return null;
        }
        return typeof inputValue === 'string' ? inputValue : JSON.stringify(inputValue, null, 4);
    };

    return (
        <>
            {label && (
                <Grid item md={12} xs={12} mt={1}>
                    <label>{label}</label>
                </Grid>
            )}
            <Grid item md={12} xs={12} mb={2}>
                <TextInput
                    //type='json'
                    name={name}
                    onFormatInput={formatInput}
                    multiline
                    minRows={minRows}
                    maxRows={maxRows}
                    inputProps={{
                        sx: {
                            minWidth: 350,
                            width: width,
                            resize: resizable ? 'both' : 'none',
                        },
                    }}
                    {...other}
                />
            </Grid>
        </>
    );
};

JsonInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    resizable: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    minRows: PropTypes.number,
    maxRows: PropTypes.number,
};

JsonInput.defaultProps = {
    label: null,
    resizable: false,
    width: null,
    minRows: 3,
    maxRows: 25,
};

export default JsonInput;
