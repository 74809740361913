import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
// eslint-disable-next-line import/no-named-default
import { default as BaseAvatar } from '../../icons/Avatar';
import { useFieldFast } from '../../../modules/form/hooks';

const Avatar = ({ name, ...other }) => {
    const [{ value }] = useFieldFast(name);
    const [preview, setPreview] = useState(null);

    useEffect(() => {
        if (value instanceof File) {
            const reader = new FileReader();
            reader.onload = () => {
                setPreview(reader.result);
            };
            reader.readAsDataURL(value);
        } else {
            setPreview(null);
        }
    }, [value]);

    return <BaseAvatar path={preview || value} name={false} {...other} />;
};

Avatar.propTypes = {
    name: PropTypes.string.isRequired,
};

export default Avatar;
