import React from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import BlockIcon from '@mui/icons-material/Block';
import { useTranslation } from 'react-i18next';
import { selectTaskStatusById } from '../../modules/taskStatus/taskStatusSlice';
import { IdPropType } from '../../modules/proptypes';
import Icon from '../icons/Icon';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        width: 'max-content',
        borderRadius: 2,
    },
    icon: {
        verticalAlign: 'middle',
    },
    empty: {
        color: theme.palette.text.secondary,
        fontStyle: 'italic',
    },
    emptyIcon: {
        marginLeft: 2,
        verticalAlign: 'middle',
        color: theme.palette.text.secondary,
    },
}));

const TaskStatus = ({ taskStatusId, onlyIcon, showEmpty, size }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const taskStatus = useSelector(state => selectTaskStatusById(state, taskStatusId));

    return (
        <Box
            className={classes.root}
            component="div"
            style={{
                padding: size === 'small' || size === 'tiny' ? 0 : 4,
            }}
        >
            {taskStatus || showEmpty ? (
                <>
                    {taskStatus && (
                        <Icon
                            type={taskStatus.icon}
                            className={classes.icon}
                            style={{
                                ...(size === 'tiny'
                                    ? {
                                          height: 10,
                                          lineHeight: '10px',
                                      }
                                    : {}),
                                fontSize: size === 'tiny' ? 12 : size === 'small' ? 20 : 24,
                                color: `#${taskStatus.color}`,
                                marginRight: onlyIcon ? 0 : 6,
                            }}
                        />
                    )}
                    {!taskStatus && showEmpty && onlyIcon && (
                        <BlockIcon
                            className={classes.emptyIcon}
                            style={{
                                fontSize: size === 'tiny' ? 12 : size === 'small' ? 20 : 24,
                                marginRight: onlyIcon ? 0 : 6,
                            }}
                        />
                    )}
                    {!onlyIcon && (
                        <Typography
                            component="span"
                            className={!taskStatus ? classes.empty : null}
                            style={{ marginRight: size === 'small' ? 6 : 3 }}
                        >
                            {(taskStatus && taskStatus.name) ||
                                t('components.TaskStatus.selectTaskStatus')}
                        </Typography>
                    )}
                </>
            ) : null}
        </Box>
    );
};

TaskStatus.propTypes = {
    taskStatusId: IdPropType.isRequired,
    onlyIcon: PropTypes.bool,
    showEmpty: PropTypes.bool,
    size: PropTypes.string,
};

TaskStatus.defaultProps = {
    onlyIcon: false,
    showEmpty: false,
    size: 'small',
};

export default TaskStatus;
