import React from 'react';
import * as PropTypes from 'prop-types';
import CacheBusterBase from 'react-cache-buster';
import packageJson from '../package.json';

import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';

const CacheBuster = ({ children }) => {
    const { version } = packageJson;

    return (
        <CacheBusterBase
            currentVersion={version}
            isEnabled={process.env.NODE_ENV === 'production'}
            isVerboseMode={false}
            loadingComponent={
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    height="100vh"
                >
                    <CircularProgress />
                    <Typography variant="body1" mt={2}>
                        {'Updating…'}
                    </Typography>
                </Box>
            }
        >
            {children}
        </CacheBusterBase>
    );
};

CacheBuster.propTypes = {};

export default CacheBuster;
