import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import { useContextualCan } from '../../../modules/abilities/hooks';
import { WRITE } from '../../../modules/abilities/actions';
import { getFormFieldError } from '../../../modules/loading/utils';
import { Box, FormHelperText } from '@mui/material';

const useStyles = makeStyles({
    input: {
        display: 'none',
    },
});

const UploadField = ({
    variant,
    icon,
    size,
    name,
    multiple,
    children,
    color,
    onChange,
    onBlur,
    can,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const inputRef = useRef(null);
    const [{ value }, meta, helpers] = useField(name);
    const error = getFormFieldError(name, null, meta);
    const canDo = useContextualCan(can ? can : WRITE, name);

    const handleChange = event => {
        const files = multiple ? Array.from(event.target.files) : Array.from(event.target.files)[0];
        helpers.setValue(files);
        onChange && onChange(files);
    };

    useEffect(() => {
        if (inputRef.current && (!value || value === '')) {
            inputRef.current.value = null;
        }
    }, [value]);

    // TODO: error display
    return (
        <>
            <input
                accept="image/*"
                className={classes.input}
                id={name}
                multiple={multiple}
                type="file"
                ref={inputRef}
                onChange={handleChange}
                onBlur={onBlur}
                disabled={!canDo}
            />
            <label htmlFor={name}>
                {variant === 'button' ? (
                    <Button component="span" size={size} color={color} disabled={!canDo}>
                        {children || t('form.UploadField.label')}
                    </Button>
                ) : (
                    <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        size={size}
                        disabled={!canDo}
                    >
                        {icon || <CloudUploadOutlinedIcon />}
                    </IconButton>
                )}
            </label>
            {error && (
                <Box>
                    <FormHelperText error>{t(`errors.${error}`)}</FormHelperText>
                </Box>
            )}
        </>
    );
};

UploadField.propTypes = {
    name: PropTypes.string.isRequired,
    icon: PropTypes.node,
    variant: PropTypes.string,
    multiple: PropTypes.bool,
    children: PropTypes.node,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    size: PropTypes.string,
    color: PropTypes.string,
    can: PropTypes.string,
};

UploadField.defaultProps = {
    multiple: false,
    icon: null,
    children: null,
    onChange: null,
    variant: 'button',
    onBlur: null,
    size: 'medium',
    color: 'inherit',
    can: null,
};

export default UploadField;
