/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import { CARD_RESOURCE } from '../api/resources';
import { sortMultipleCardStatusByDue, bundleCardStatus } from './sort';

const affirmList = (state, statusId) => {
    if (!state.byCardStatusId[statusId]) {
        state.byCardStatusId[statusId] = { data: [] };
    }
};

const insertCard = (state, card, head = false, addToBoard = false) => {
    const stale = state.byId[card.id];
    if (!stale) {
        state.allIds.push(card.id);
    }

    if (addToBoard) {
        affirmList(state, card.card_status_id);

        if (!stale) {
            if (head) {
                state.byCardStatusId[card.card_status_id].data.unshift(card.id);
            } else {
                state.byCardStatusId[card.card_status_id].data.push(card.id);
            }
        } else if (stale.card_status_id !== card.card_status_id) {
            const staleList = state.byCardStatusId[stale.card_status_id].data;
            const index = staleList.findIndex(id => id === card.id);
            if (index >= 0) {
                staleList.splice(index, 1);
            }
            state.byCardStatusId[card.card_status_id].data.unshift(card.id);
        } else if (!state.byCardStatusId[card.card_status_id].data.includes(card.id)) {
            state.byCardStatusId[card.card_status_id].data.push(card.id);
        }
    }

    const normalized = {
        ...card,

        responsibles: [
            ...card.responsibles_main,
            ...card.responsibles.filter(id => !card.responsibles_main.includes(id)),
        ],

        /* metadata is handled by different slice */
        metadata: undefined,

        /* datacontainer object is handled by different slice */
        datacontainer: undefined,
        datacontainer_id: card.datacontainer ? card.datacontainer.id : null,
        datacontainer2: undefined,
        datacontainer_id2: card.datacontainer2 ? card.datacontainer2.id : null,
    };

    /* prevent unnecessary re-rendering if the object is the same anyways */
    if (!isEqual(state.byId[card.id], normalized)) {
        state.byId[card.id] = normalized;
    }
};

const handleParent = (state, action) => {
    if (action.payload.length === 1) {
        const stale = state.byId[action.payload[0].id];
        if (stale && action.payload[0].parent_id !== stale.parent_id) {
            const oldParent = stale ? state.byId[stale.parent_id] : null;
            const newParent = state.byId[action.payload[0].parent_id];
            if (oldParent) {
                const index = oldParent.children.findIndex(id => id === action.payload[0].id);
                if (index >= 0) {
                    oldParent.children.splice(index, 1);
                }
            }
            if (newParent) {
                const index = newParent.children.findIndex(id => id === action.payload[0].id);
                if (index < 0) {
                    newParent.children.push(action.payload[0].id);
                }
            }
        }
    }
};

const cardsSlice = createSlice({
    name: CARD_RESOURCE,
    initialState: {
        byId: {},
        allIds: [],
        byCardStatusId: {},
        filter: {},
    },
    reducers: {
        indexFulfilled: (state, action) => {
            let influencedStatus = [];

            action.payload.forEach(card => {
                insertCard(state, card, false);
                influencedStatus = bundleCardStatus(influencedStatus, card);
            });

            sortMultipleCardStatusByDue(state, influencedStatus);
        },

        searchFulfilled: (state, action) => {
            if (
                !action.meta.socketReaction &&
                action.meta.params.card_status_id &&
                action.meta.current_page !== undefined
            ) {
                affirmList(state, action.meta.params.card_status_id);
                state.byCardStatusId[action.meta.params.card_status_id].current_page =
                    action.meta.current_page;

                state.byCardStatusId[action.meta.params.card_status_id].is_last_page =
                    action.meta.is_last_page || action.meta.current_page === action.meta.last_page;

                state.byCardStatusId[action.meta.params.card_status_id].isInitialized = true;
                state.byCardStatusId[action.meta.params.card_status_id].isLoading = false;

                if (action.meta.current_page === 1) {
                    state.byCardStatusId[action.meta.params.card_status_id].data = [];
                }
            }

            if (action.meta.socketReaction && action.meta.params.id) {
                const card = state.byId[action.meta.params.id];
                if (card && !action.payload.find(({ id }) => id === action.meta.params.id)) {
                    const statusList = state.byCardStatusId[card.card_status_id].data;
                    const index = statusList.findIndex(id => id === card.id);
                    if (index >= 0) {
                        statusList.splice(index, 1);
                    }
                }
            }

            if (action.meta.socketReaction) {
                handleParent(state, action);
            }

            let influencedStatus = [];

            action.payload.forEach(card => {
                insertCard(state, card, false, true);
                influencedStatus = bundleCardStatus(influencedStatus, card);
            });

            sortMultipleCardStatusByDue(state, influencedStatus);
        },

        showFulfilled: (state, action) => {
            insertCard(state, action.payload, false);
            sortMultipleCardStatusByDue(state, bundleCardStatus([], action.payload));
        },

        storeFulfilled: (state, action) => {
            insertCard(state, action.payload, true, true);
            sortMultipleCardStatusByDue(state, bundleCardStatus([], action.payload));
        },

        updateFulfilled: (state, action) => {
            insertCard(state, action.payload, false, true);
            sortMultipleCardStatusByDue(state, bundleCardStatus([], action.payload));
        },

        destroyFulfilled: (state, action) => {
            const index = state.allIds.findIndex(id => id === action.payload);
            if (index >= 0) {
                state.byId[action.payload] = null;
                state.allIds.splice(index, 1);

                Object.values(state.byCardStatusId).forEach(list => {
                    const listIndex = list.data.findIndex(id => id === action.payload);
                    if (listIndex >= 0) {
                        list.data.splice(listIndex, 1);
                    }
                });
            }
        },

        saveSearch: (state, action) => {
            state.filter = action.payload;
        },
    },
});

export const {
    indexFulfilled,
    searchFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,
    saveSearch,
} = cardsSlice.actions;

export default cardsSlice.reducer;
