import React from 'react';
import * as PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import DirectionsOutlined from '@mui/icons-material/DirectionsOutlined';
import Box from '@mui/material/Box';

const useStyles = makeStyles({
    icon: {
        opacity: 0.5,
        height: 24,
    },
});

const Location = ({ value }) => {
    const classes = useStyles();

    return (
        <Grid container alignItems="center" spacing={1}>
            <Grid item>
                <Box className={classes.icon}>
                    <DirectionsOutlined />
                </Box>
            </Grid>
            <Grid item>
                <Typography variant="body2">{value}</Typography>
            </Grid>
        </Grid>
    );
};

Location.propTypes = {
    value: PropTypes.string.isRequired,
};

export default Location;
