import { METADATA_RESSOURCE } from '../api/resources';

const stableEmptyObj = {};
const stableEmptyArr = [];

export const selectMetadataByResources = state => state[METADATA_RESSOURCE].byResource;

export const selectMetadataByResource = (state, resource) =>
    selectMetadataByResources(state)[resource] || stableEmptyObj;

export const selectMetadataById = (state, resource, id) =>
    selectMetadataByResource(state, resource)[id] || stableEmptyObj;

export const selectMetadataByContext = (state, resource, id, context) =>
    selectMetadataById(state, resource, id)[context] || stableEmptyArr;

const sortByValue = (m1, m2) => m1.value - m2.value;

const appendMetadataForResource = (carry, context, resource, byId, flatten) =>
    Object.entries(byId).reduce((_carry, [id, byContext]) => {
        const metadata = byContext[context];
        if (metadata) {
            if (flatten) {
                metadata.forEach(data => {
                    _carry.push({
                        itemId: parseInt(id, 10),
                        resource,
                        ...data,
                    });
                });
            } else {
                _carry.push({
                    itemId: parseInt(id, 10),
                    resource,
                    metadata,
                });
            }
        }
        return _carry;
    }, carry);

// TODO: merge this into selectAllMetadataByContext?
export const selectMetadatasByContext = (state, resource, ids, context) =>
    ids.reduce((carry, id) => {
        const byContext = selectMetadataByContext(state, resource, id, context);
        byContext.forEach(metadata => {
            carry.push({
                itemId: parseInt(id, 10),
                resource,
                ...metadata,
            });
        });
        return carry;
    }, []);

// TODO: use createSelector to improve performance
export const selectAllMetadataByContext = (
    state,
    context,
    resource = null,
    flatten = true,
    sortBy = sortByValue
) =>
    (resource
        ? [[resource, selectMetadataByResource(state, resource)]]
        : Object.entries(selectMetadataByResources(state))
    )
        .reduce(
            (carry, [res, byId]) => appendMetadataForResource(carry, context, res, byId, flatten),
            []
        )
        .sort(sortBy);
