import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as PropTypes from 'prop-types';
import { useSnackbar } from '../../modules/snackbar/hooks';
import {
    selectLastCreatedPersonalAccessTokenUrl,
    setLastCreatedPersonalAccessTokenUrl,
    storePersonalAccessToken,
} from '../../modules/personalAccessToken/personalAccessTokenSlice';
import React, { useState } from 'react';
import { Box, ButtonBase, CircularProgress, Divider, Grid, Icon, Typography } from '@mui/material';
import FormPopup from '../popups/FormPopup';
import TextField from '../form/base/TextField';
import { Icon as IconifyIcon } from '@iconify/react';
import { makeStyles } from '@mui/styles';
import { IdPropType } from '../../modules/proptypes';

const useStyles = makeStyles(theme => ({
    hoverExtra: {
        width: '100%',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    hoverExtraInner: {
        cursor: 'pointer',
    },
    hoverExtraIcon: {
        fontSize: 20,
        display: 'flex',
    },
}));

const CalendarICalShare = ({ entityId, name, entityType }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const lastCreatedUrl = useSelector(selectLastCreatedPersonalAccessTokenUrl);
    const [loading, setLoading] = useState(false);
    const [url, setUrl] = useState('');

    const handleShareICalUrl = (id, name, entityType) => {
        if (lastCreatedUrl) {
            setUrl(lastCreatedUrl);
            return;
        }

        setLoading(true);
        dispatch(
            storePersonalAccessToken({
                name: `iCal ${name}`,
                abilities: [{ subject: 'appointment', action: 'read' }],
                payload: {
                    'appointment-read': {
                        entity_type: entityType,
                        entity_id: id,
                    },
                },
            })
        )
            .then(({ data }) => {
                const absoluteUrl = `${window.location.protocol}//${window.location.host}/api/appointments/${data.token}/calendar.ics`;
                dispatch(setLastCreatedPersonalAccessTokenUrl(absoluteUrl));
                setUrl(absoluteUrl);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleCopy = event => {
        if (event.metaKey || event.ctrlKey) {
            /* keep default behaviour */
            return;
        }

        try {
            navigator.clipboard.writeText(url).then(() => {
                enqueueSnackbar(t('components.CopyLinkButton.success'), { preventDuplicate: true });
            });
            event.preventDefault();
        } catch (e) {
            /* fallback to default behaviour, e.g. opening the link :D */
        }
    };

    return (
        <Box>
            <FormPopup
                popupId="ShareICalUrl"
                subheadline={t('components.CalendarResourcesPicker.copyShareLink')}
                FormInputs={
                    <Box sx={{ display: 'flex', alignItems: 'center', width: 400 }}>
                        <TextField
                            size="small"
                            value={url}
                            onFocus={event => {
                                event.target.select();
                            }}
                            InputProps={{
                                endAdornment: (
                                    <>
                                        <Divider
                                            sx={{ height: 28, m: 0.5 }}
                                            orientation="vertical"
                                        />
                                        {loading ? (
                                            <CircularProgress color="inherit" size={16} />
                                        ) : (
                                            <Icon
                                                fontSize="small"
                                                sx={{ cursor: 'pointer' }}
                                                onClick={handleCopy}
                                            >
                                                {'content_copy'}
                                            </Icon>
                                        )}
                                    </>
                                ),
                            }}
                        />
                    </Box>
                }
                placement="bottom"
                autoSubmit
            >
                <ButtonBase
                    onClick={() => handleShareICalUrl(entityId, name, entityType)}
                    className={classes.hoverExtra}
                >
                    <Grid
                        container
                        alignItems="center"
                        spacing={1}
                        className={classes.hoverExtraInner}
                    >
                        <Grid item>
                            <IconifyIcon
                                icon="mdi:calendar-arrow-right"
                                fontSize="small"
                                className={classes.hoverExtraIcon}
                            />
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle2" color="textPrimary">
                                {t('components.CalendarResourcesPicker.share')}
                            </Typography>
                        </Grid>
                    </Grid>
                </ButtonBase>
            </FormPopup>
        </Box>
    );
};

CalendarICalShare.propTypes = {
    name: PropTypes.string.isRequired,
    entityId: IdPropType.isRequired,
    entityType: PropTypes.string.isRequired,
};

export default CalendarICalShare;
