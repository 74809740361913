export const remarkableCommonPlugin = (state, silent, elementRegEx, createElement) => {
    // it is surely not our rule, so we could stop early
    if (
        !state.src ||
        state.pos === undefined ||
        state.pos === null ||
        state.src[state.pos] !== '<'
    ) {
        return false;
    }

    const match = elementRegEx.exec(state.src.slice(state.pos));
    if (!match) {
        return false;
    }

    // in silent mode it shouldn't output any tokens or modify pending
    if (!silent) {
        createElement(match[1], state);
    }

    // every rule should set state.pos to a position after tokens contents
    state.pos += match[0].length;
    return true;
};
