import { GROUP_RESOURCE } from '../api/resources';

export const indexGroups = params => (dispatch, getState, api) => api[GROUP_RESOURCE].index(params);

export const storeGroup = (group, meta) => (dispatch, getState, api) =>
    api[GROUP_RESOURCE].store(group, meta);

export const updateGroup = (group, meta) => (dispatch, getState, api) =>
    api[GROUP_RESOURCE].update(group, meta);

export const destroyGroup = params => (dispatch, getState, api) =>
    api[GROUP_RESOURCE].destroy(params);

export const autocompleteGroups = params => (dispatch, getState, api) =>
    api[GROUP_RESOURCE].autocomplete(params);
