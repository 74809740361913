export const isBetween = (num, low, high) => num >= low && num <= high;

export const hasSelectionRange = editorState => {
    const selection = editorState.getSelection();
    return selection.getStartOffset() !== selection.getEndOffset();
};

export const iconColor = selected => (selected ? 'primary' : 'inherit');

/**
 * Plugin Methoden kopiert und nur etwas angepasst
 * https://github.com/draft-js-plugins/draft-js-plugins/blob/master/draft-js-mention-plugin/src/utils/positionSuggestions.js
 */
const getRelativeParent = element => {
    if (!element) {
        return null;
    }
    const position = window.getComputedStyle(element).getPropertyValue('position');
    if (position !== 'static') {
        return element;
    }
    return getRelativeParent(element.parentElement);
};

export const positionSuggestions = ({ decoratorRect, popover, props, state }) => {
    const relativeParent = getRelativeParent(popover.parentElement);
    const relativeRect = {};

    if (relativeParent) {
        relativeRect.scrollLeft = relativeParent.scrollLeft;
        relativeRect.scrollTop = relativeParent.scrollTop;

        const relativeParentRect = relativeParent.getBoundingClientRect();
        relativeRect.left = decoratorRect.left - relativeParentRect.left;
        relativeRect.top = decoratorRect.bottom - relativeParentRect.top;

        // angepasst: damit das Popover nicht rechts rausguckt bzw. versteckt wird
        if (relativeRect.left + (popover.clientWidth + 30) > relativeParentRect.width) {
            relativeRect.left = relativeParentRect.width - (popover.clientWidth + 30);
        }
        // angepasst: damit das Popover nicht unten rausguckt bzw. versteckt wird
        // if (relativeRect.top + popover.clientHeight > relativeParentRect.height) {
        //     relativeRect.top = relativeParentRect.height - popover.clientHeight;
        // }
    } else {
        relativeRect.scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        relativeRect.scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

        relativeRect.top = decoratorRect.bottom;
        relativeRect.left = decoratorRect.left;
    }

    const left = relativeRect.left + relativeRect.scrollLeft;
    const top = relativeRect.top + relativeRect.scrollTop;

    let transform;
    let transition;
    if (!state || state.isActive) {
        if (props.suggestions.length > 0) {
            transform = 'scale(1)';
            transition = 'all 0.25s cubic-bezier(.3,1.2,.2,1)';
        } else {
            transform = 'scale(0)';
            transition = 'all 0.35s cubic-bezier(.3,1,.2,1)';
        }
    }

    return {
        left: `${left}px`,
        top: `${top}px`,
        transform,
        transformOrigin: '1em 0%',
        transition,
    };
};
