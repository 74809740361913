import React from 'react';
import * as PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import ContactLink from '../contacts/ContactLink';
import EditIconButton from '../buttons/EditIconButton';
import { selectContactDataById } from '../../modules/contactData/selectors';
import Icon from '../icons/Icon';
import { IdPropType } from '../../modules/proptypes';

const useStyles = makeStyles(theme => ({
    title: {
        display: 'block',
        color: theme.palette.primary.main,
        fontWeight: 'bold',
    },
    icon: {
        verticalAlign: 'middle',
        marginRight: '.4ch',
    },
    undefined: {
        color: theme.palette.primary.light,
        fontStyle: 'italic',
    },
    extraSpacing: {
        paddingTop: theme.spacing(2),
    },
    alignRight: {
        textAlign: 'right',
    },
    comment: {
        marginTop: theme.spacing(1),
    },
}));

const methodIcons = {
    web: 'public',
    fax: 'print',
    mobile: 'smartphone',
};

const ContactData = ({ contactDataId, onEdit, inline }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const contactData = useSelector(state => selectContactDataById(state, contactDataId));

    return contactData ? (
        <Grid container alignItems="center" spacing={2}>
            <Grid item xs>
                <Grid container alignItems="center" spacing={0}>
                    <Grid item xs={inline ? true : 12}>
                        {!contactData.method && !contactData.type && (
                            <Typography className={classes.undefined}>
                                {t('components.ContactData.undefined')}
                            </Typography>
                        )}
                        <Typography className={classes.title}>
                            <Icon
                                className={classes.icon}
                                type={methodIcons[contactData.method] || contactData.method}
                            />

                            {contactData &&
                                contactData.method &&
                                t(`components.ContactData.${contactData.method}`)}
                            {contactData && contactData.method && contactData.type && ', '}
                            {contactData &&
                                contactData.type &&
                                t(`components.ContactData.${contactData.type}`)}
                        </Typography>
                    </Grid>
                    <Grid item className={classNames({ [classes.extraSpacing]: !inline })}>
                        <ContactLink value={contactData.value} method={contactData.method} />
                    </Grid>

                    {contactData && contactData.comment && (
                        <Grid
                            item
                            xs={12}
                            className={classNames(classes.comment, {
                                [classes.alignRight]: inline,
                            })}
                        >
                            <Typography variant="body2" color="textSecondary">
                                {contactData.comment}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            {onEdit && (
                <Grid item>
                    <EditIconButton onClick={onEdit} size="small" />
                </Grid>
            )}
        </Grid>
    ) : null;
};

ContactData.propTypes = {
    contactDataId: IdPropType.isRequired,
    onEdit: PropTypes.func,
    inline: PropTypes.bool,
};

ContactData.defaultProps = {
    onEdit: null,
    inline: false,
};

export default ContactData;
