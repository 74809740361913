import React, { memo, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Paper, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { compareDesc } from 'date-fns';
import { makeStyles } from '@mui/styles';
import ResourceList from '../lists/ResourceList';
import { APPOINTMENT_RESOURCE, TASK_RESOURCE } from '../../modules/api/resources';
import AppointmentBasePreview from './AppointmentBasePreview';
import { IdPropType } from '../../modules/proptypes';
import { useCallbackFunc } from '../../modules/hooks';
import { convertUTCtoLocalDate } from '../../modules/datetime/utils';
import { CALENDAR_RESOURCE_MODELS } from '../../modules/calendar/utils';

const useStyles = makeStyles(theme => ({
    headContainer: {
        marginLeft: 8,
    },
    head: {
        marginBottom: -12,
    },
    content: {
        maxWidth: '100%',
        paddingBottom: 8,
    },
    appointmentWrap: {
        width: '100%',
        padding: theme.spacing(1),
    },
}));

const AppointmentBundlePreview = ({ taskId, appointmentIds }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const listId = useMemo(() => `appointmentBundleByTask${taskId}`, [taskId]);

    const stableCheck = useCallbackFunc(item => {
        return (
            item.entity_id === taskId &&
            item.entity_type === CALENDAR_RESOURCE_MODELS[TASK_RESOURCE]
        );
    });

    const stableCompare = useCallbackFunc((old, current) => {
        const endOld = convertUTCtoLocalDate(old.ends_at);
        const endNew = convertUTCtoLocalDate(current.ends_at);
        return compareDesc(endOld, endNew);
    });

    return (
        <Grid container direction="column" spacing={2} style={{ margin: 0, width: '100%' }}>
            <Grid2 item sx={{ paddingBottom: '8px!important' }}>
                <Typography variant="h4" color="primary">
                    {t('components.Appointment.tasks')}
                </Typography>
            </Grid2>
            <Box className={classes.content}>
                <Grid2 item sx={{ paddingTop: '8px!important', paddingBottom: '8px!important' }}>
                    <Grid container className={classes.headContainer}>
                        <Grid2 item xs={3} md>
                            <Typography
                                color="textSecondary"
                                variant="body2"
                                className={classes.head}
                            >
                                {t('components.Appointment.name')}
                            </Typography>
                        </Grid2>
                        <Grid2 item xs={3} md>
                            <Typography
                                color="textSecondary"
                                variant="body2"
                                className={classes.head}
                            >
                                {t('components.Appointment.starts_at')}
                            </Typography>
                        </Grid2>
                        <Grid2 item xs={3} md>
                            <Typography
                                color="textSecondary"
                                variant="body2"
                                className={classes.head}
                            >
                                {t('components.Appointment.duration')}
                            </Typography>
                        </Grid2>
                        <Grid2 item xs={3} md>
                            <Typography
                                color="textSecondary"
                                variant="body2"
                                className={classes.head}
                            >
                                {t('components.Appointment.ends_at')}
                            </Typography>
                        </Grid2>
                        <Grid2 item xs={3} md>
                            <Typography
                                color="textSecondary"
                                variant="body2"
                                className={classes.head}
                            >
                                {t('components.Appointment.responsible')}
                            </Typography>
                        </Grid2>
                    </Grid>
                </Grid2>
                <Grid2 item sx={{ paddingTop: '8px!important' }}>
                    {appointmentIds ? (
                        appointmentIds.map(id => (
                            <Grid item>
                                <Paper elevation={1} className={classes.appointmentWrap}>
                                    <AppointmentBasePreview appointmentId={id} />
                                </Paper>
                            </Grid>
                        ))
                    ) : (
                        <ResourceList
                            resource={APPOINTMENT_RESOURCE}
                            listId={listId}
                            criteria={{
                                check: stableCheck,
                                compare: stableCompare,
                            }}
                            fetchParams={{
                                entity_id: taskId,
                                entity_type: CALENDAR_RESOURCE_MODELS[TASK_RESOURCE],
                            }}
                            initialOrderBy={['ends_at', 'desc']}
                            ItemComponent={({ itemId }) => (
                                <Grid item>
                                    <Paper elevation={1} className={classes.appointmentWrap}>
                                        <AppointmentBasePreview appointmentId={itemId} />
                                    </Paper>
                                </Grid>
                            )}
                            ListComponent={({ children }) => (
                                <Grid container direction="column" spacing={1}>
                                    {children}
                                </Grid>
                            )}
                        />
                    )}
                </Grid2>
            </Box>
        </Grid>
    );
};

AppointmentBundlePreview.propTypes = {
    taskId: IdPropType.isRequired,
    appointmentIds: PropTypes.arrayOf(IdPropType),
};

AppointmentBundlePreview.defaultProps = {
    appointmentIds: null,
};

export default memo(AppointmentBundlePreview);
