import React, { useState } from 'react';
import * as PropTypes from 'prop-types';

const LINE_HEIGHT = 20;

const HtmlMailView = ({ htmlContent, expanded, lines }) => {
    const [initialIframeHeight, setInitialIframeHeight] = useState(0);

    const minHeight = `${lines * LINE_HEIGHT}px`;

    const handleIframeLoad = event => {
        const iframe = event.target;

        iframe.contentWindow.document.body.style.overflow = 'hidden';

        const iframeHeight = iframe.contentWindow.document.body.scrollHeight + 20;
        setInitialIframeHeight(iframeHeight);

        const links = [...iframe.contentDocument.getElementsByTagName('a')];

        if (links.length) {
            links.forEach(link => {
                link.target = '_blank';
                link.rel = 'noopener';
            });
        }
    };

    return (
        <div>
            <iframe
                srcDoc={htmlContent}
                sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin"
                csp="script-src 'none'"
                onLoad={handleIframeLoad}
                style={{
                    width: '100%',
                    border: 'none',
                    height: expanded ? initialIframeHeight : minHeight,
                    transition: 'height 0.3s',
                }}
            />
        </div>
    );
};

HtmlMailView.propTypes = {
    htmlContent: PropTypes.string,
    expanded: PropTypes.bool,
    lines: PropTypes.number,
};

HtmlMailView.defaultProps = {
    htmlContent: '',
    expanded: false,
    lines: 6,
};

export default HtmlMailView;
