import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../modules/auth/selectors';
import { getAuthenticated, logout } from '../../modules/auth/actions';
import { Alert, AlertTitle, Fade, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useIdleTimer } from 'react-idle-timer';
import timer from './utils/timer';
import { useHistory } from 'react-router-dom';
import { loginPath, outlookPluginPath, protocolItemFormPath } from '../../routes/paths';
import { selectSettingsByKey } from '../../modules/settings/selectors';
import { SESSION_TIMEOUT_SETTINGS } from '../../modules/settings/config';
import { differenceInMinutes } from 'date-fns';

const NOTIFICATION_TIME = 1; // Time in minutes (m) to show logout notification

const IDLE_TIMER_ACTIONS = {
    RESET: 'reset',
};

const useStyles = makeStyles({
    alert: {
        position: 'fixed',
        top: 0,
        zIndex: 99999,
        width: '100%',
        fontSize: '13px',
    },
});

const LOGOUT_ALTERNATIVES = {
    //Might need a better solution later, since protocolItemFormPath might be used by other services too
    [protocolItemFormPath]: outlookPluginPath,
};

const SessionTimeout = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [secondLeft, setSecondLeft] = useState(null); // remaining seconds left before logout
    const [open, setOpen] = useState(false); // open notification
    const user = useSelector(selectUser);
    const lastHideTime = useRef(null);
    const classes = useStyles();
    const { location, push: redirect } = useHistory();

    // Maximum inactivity time given before logout in minutes (m)
    const maxInactivityTime = useSelector(state =>
        selectSettingsByKey(state, SESSION_TIMEOUT_SETTINGS)
    );

    const handlePrompt = () => {
        // Fire a Modal Prompt
        if (user && location.pathname !== loginPath) setOpen(true);
    };

    const handleIdle = () => {
        // Close Modal Prompt
        // Log out user
        setOpen(false);

        const alternative = LOGOUT_ALTERNATIVES[location.pathname];

        if (alternative) {
            redirect(alternative);
        } else {
            dispatch(logout());
        }
    };
    const handleActive = event => {
        // Close Modal Prompt
        // And maybe do some active action
        setOpen(false);
    };
    const handleAction = () => {
        // Do something when a user triggers a watched event
        // Like activate (that includes reset) timer
        activate();
    };
    const handleMessage = data => {
        /* if (data === IDLE_TIMER_ACTIONS.RESET) {
            reset();
            setOpen(false);
        } */
    };

    // define idleTimer
    const { start, activate, reset, getRemainingTime, message } = useIdleTimer({
        name: 'idle-timer',
        events: ['click', 'scroll'],
        timeout: 1000 * 60 * (maxInactivityTime - NOTIFICATION_TIME),
        promptTimeout: 1000 * 60 * NOTIFICATION_TIME,
        onPrompt: handlePrompt,
        onIdle: handleIdle,
        onActive: handleActive,
        onAction: handleAction,
        onMessage: handleMessage,
        startManually: true,
        stopOnIdle: true,
        crossTab: true,
    });

    useEffect(() => {
        let logoutTimeInterval;

        if (user) {
            start();
            logoutTimeInterval = timer.setInterval(() => {
                setSecondLeft(Math.floor(getRemainingTime() / 1000));
            }, 1000);
        } else {
            reset();
            if (logoutTimeInterval) timer.clearInterval(logoutTimeInterval);
        }

        return () => (logoutTimeInterval ? timer.clearInterval(logoutTimeInterval) : null);
        // eslint-disable-next-line
    }, [user]);

    useEffect(() => {
        const handlePageVisibilityChange = () => {
            const now = new Date();

            if (document.hidden) {
                lastHideTime.current = now;
            } else {
                if (
                    (lastHideTime.current && differenceInMinutes(lastHideTime.current, now) > 5) ||
                    !lastHideTime.current
                ) {
                    // Check if current user is still authenticated by calling GET users/me
                    dispatch(
                        getAuthenticated({
                            ...(user?.auth_token && { xAuthToken: user.auth_token }),
                        })
                    ).then(user => {
                        if (!user) message(IDLE_TIMER_ACTIONS.RESET, true);
                    });
                }

                lastHideTime.current = null;
            }
        };

        // Add event listeners for visibility change & focus
        document.addEventListener('visibilitychange', handlePageVisibilityChange);

        // Cleanup function to remove event listeners
        return () => {
            document.removeEventListener('visibilitychange', handlePageVisibilityChange);
        };
    }, [user]);

    return open ? (
        <Fade in={open}>
            <Alert
                severity="warning"
                variant="standard"
                className={classes.alert}
                onClose={() => {}}
            >
                <AlertTitle>Session Timeout</AlertTitle>
                {t('components.SessionTimeout.notification.startSentence') +
                    (maxInactivityTime - NOTIFICATION_TIME) +
                    t('components.SessionTimeout.notification.continueSentence')}
                <strong>{secondLeft}</strong>
                {t('components.SessionTimeout.notification.endSentence')}
                <Typography variant="subtitle2">
                    — {t('components.SessionTimeout.notification.doSomething')}
                </Typography>
            </Alert>
        </Fade>
    ) : null;
};

export default SessionTimeout;
