import { useMemo } from 'react';
import { arrangeDatacontainers, IDENTIFIER_TYPES } from './utils';
import { useSelector } from 'react-redux';
import { selectSettingsByKey } from '../settings/selectors';

export const useArrangedDatacontainers = (datacontainer, datacontainer2) => {
    const offerSettings = useSelector(state =>
        selectSettingsByKey(state, `app.identifier.${IDENTIFIER_TYPES.OFFER}`)
    );
    const orderSettings = useSelector(state =>
        selectSettingsByKey(state, `app.identifier.${IDENTIFIER_TYPES.ORDER}`)
    );

    return useMemo(() => {
        return arrangeDatacontainers(datacontainer, datacontainer2, offerSettings, orderSettings);
    }, [datacontainer, datacontainer2, offerSettings, orderSettings]);
};
