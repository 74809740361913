import React, { useEffect, useRef } from 'react';
import PropTypes, { bool, func, node } from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import Button from '../form/base/Button';
import { useLoading } from '../../modules/loading/hooks';
import Box from '@mui/material/Box';

const FadeContainer = React.forwardRef((props, ref) => <Box {...props} ref={ref} />);

const useStyles = makeStyles(theme => ({
    primary: {
        color: theme.palette.primary.main,
    },
    secondary: {
        color: theme.palette.secondary.main,
    },

    contrastPrimary: {
        color: theme.palette.primary.contrastText,
    },
    contrastSecondary: {
        color: theme.palette.secondary.contrastText,
    },

    contrastHoverRoot: {
        '&:hover $hover': {
            color: theme.palette.primary.main,
        },
    },
    contrastContainedHoverRoot: {
        '&:hover $hover': {
            color: theme.palette.primary.contrastText,
        },
    },
    contrastSecondaryHoverRoot: {
        '&:hover $hover': {
            color: theme.palette.secondary.main,
        },
    },

    hover: {},
}));

const LoadingButton = React.forwardRef(
    (
        {
            onClick,
            disabled,
            isLoading,
            children,
            contrast,
            hideIfDisabled,
            loaderClassName,
            color,
            variant,
            size,
            className,
            fullWidth,
            ...other
        },
        ref
    ) => {
        const classes = useStyles();
        const fallbackRef = useRef(null);
        const [dimensions, setDimensions] = React.useState(null);
        const { loading, handleClick } = useLoading(onClick, isLoading);

        useEffect(() => {
            if (loading && !dimensions) {
                const dim = (ref || fallbackRef).current.getBoundingClientRect();
                setDimensions({ width: dim.width, height: dim.height });
            }
        }, [ref, fallbackRef, loading, dimensions]);

        return (
            !(hideIfDisabled && disabled) && (
                <Button
                    {...other}
                    disabled={disabled}
                    ref={ref || fallbackRef}
                    style={loading && dimensions ? dimensions : {}}
                    color={color}
                    variant={variant}
                    size={size}
                    className={classNames(className, {
                        [classes.contrastHoverRoot]: contrast && variant === 'outlined',
                        [classes.contrastContainedHoverRoot]: contrast && variant !== 'outlined',
                        [classes.contrastSecondaryHoverRoot]: contrast && color === 'secondary',
                        // [classes.secondary]: color === 'secondary',
                    })}
                    contrast={contrast}
                    fullWidth={fullWidth}
                    onClick={handleClick}
                >
                    <Fade
                        in={!loading}
                        style={{
                            transitionDelay: loading ? '300ms' : '0ms',
                        }}
                        timeout={{
                            enter: 300,
                            exit: 800,
                        }}
                    >
                        <FadeContainer>{children}</FadeContainer>
                    </Fade>
                    <Fade
                        in={loading}
                        style={{
                            transitionDelay: loading ? '800ms' : '0ms',
                            position: 'absolute',
                            top: size === 'tiny' ? 0 : 7,
                        }}
                        timeout={{
                            enter: 1000,
                        }}
                        unmountOnExit
                    >
                        <FadeContainer>
                            {dimensions && (
                                <CircularProgress
                                    className={classNames(loaderClassName, classes.hover, {
                                        [classes.primary]: color === 'primary',
                                        [classes.secondary]: color === 'secondary',

                                        [classes.contrastPrimary]:
                                            color !== 'secondary' &&
                                            ((!contrast && variant === 'contained') ||
                                                (contrast && variant !== 'contained')),
                                        [classes.contrastSecondary]:
                                            color === 'secondary' &&
                                            variant === 'contained' &&
                                            !contrast,
                                    })}
                                    size={Math.min(dimensions.width, dimensions.height) - 14}
                                />
                            )}
                        </FadeContainer>
                    </Fade>
                </Button>
            )
        );
    }
);

LoadingButton.propTypes = {
    disabled: bool,
    isLoading: bool,
    children: node.isRequired,
    contrast: bool,
    onClick: func,
    hideIfDisabled: PropTypes.bool,
    loaderClassName: PropTypes.string,
    color: PropTypes.string,
    variant: PropTypes.string,
    size: PropTypes.string,
    className: PropTypes.string,
    fullWidth: PropTypes.bool,
};

LoadingButton.defaultProps = {
    disabled: false,
    isLoading: null,
    contrast: false,
    onClick: null,
    hideIfDisabled: false,
    loaderClassName: '',
    color: 'inherit',
    variant: 'text',
    size: 'medium',
    className: undefined,
    fullWidth: false,
};

export default LoadingButton;
