import PropTypes from 'prop-types';
import { Component } from 'react';

export const EditorStatePropType = PropTypes.shape({});
export const EditorReferencePropType = PropTypes.shape({});

export const IdPropType = PropTypes.number;

export const RefPropType = PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
        current: PropTypes.oneOfType([
            PropTypes.instanceOf(HTMLInputElement),
            PropTypes.instanceOf(Component),
            PropTypes.object,
        ]),
    }),
]);

export const ReferenceIdPropType = PropTypes.shape({
    value: IdPropType,
    key: PropTypes.string.isRequired,
});
