import React from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import TextInput from '../form/formik/TextInput';
import CountrySelect from '../form/CountrySelect';
import AddressTypeSelect from '../form/AddressTypeSelect';
import RemoveIconButton from '../buttons/RemoveIconButton';

const AddressesInputRowForm = ({ name, onDelete }) => {
    const { t } = useTranslation();

    return (
        <Grid container spacing={2}>
            <Grid item xs={3}>
                <AddressTypeSelect
                    name={`${name}.address_type_id`}
                    label={t('components.Address.type')}
                    fullWidth
                />
            </Grid>
            <Grid item xs={9}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextInput
                            size="small"
                            name={`${name}.address1`}
                            label={t('components.Address.address1')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            size="small"
                            name={`${name}.address2`}
                            label={t('components.Address.address2')}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextInput
                            size="small"
                            name={`${name}.zip`}
                            label={t('components.Address.zip')}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextInput
                            size="small"
                            name={`${name}.city`}
                            label={t('components.Address.city')}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={3}>
                        <RemoveIconButton onClick={onDelete} size="small" />
                    </Grid>
                    <Grid item xs={3}>
                        <TextInput
                            size="small"
                            name={`${name}.state`}
                            label={t('components.Address.state')}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <CountrySelect
                            name={`${name}.country`}
                            label={t('components.Address.country')}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

AddressesInputRowForm.propTypes = {
    name: PropTypes.string.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default AddressesInputRowForm;
