import { combineReducers } from 'redux';
import { kanbanReducer } from 'react-kanban';

import api from '../api/apiSlice';
import auth from '../auth/authSlice';
import user from '../users/userSlice';
import card from '../cards/cardsSlice';
import audit from '../audits/auditsSlice';
import cardStatus from '../cardStatus/cardStatusSlice';
import lists from '../lists/listsSlice';
import dialogs from '../dialogs/dialogSlice';
import cloud from '../cloud/cloudSlice';
import contacts from '../contacts/contactsSlice';
import contactSearch from '../contacts/search/contactSearchSlice';
import contactables from '../contactables/contactablesSlice';
import contactTypes from '../contactTypes/contactTypesSlice';
import datacontainers from '../datacontainers/datacontainersSlice';
import datacontainerTypes from '../datacontainerTypes/datacontainerTypesSlice';
import metadata from '../metadata/metadataSlice';
import protocolItem from '../protocolItems/protocolItemSlice';
import protocolItemType from '../protocolItemTypes/protocolItemTypeSlice';
import attachment from '../attachments/attachmentSlice';
import tasks from '../tasks/tasksSlice';
import taskDurations from '../taskDurations/taskDurationSlice';
import taskStatus from '../taskStatus/taskStatusSlice';
import taskTypes from '../taskTypes/taskTypesSlice';
import activities from '../activities/activitiesSlice';
import person from '../persons/personsSlice';
import company from '../companies/companySlice';
import address from '../addresses/addressSlice';
import addressType from '../addressTypes/addressTypeSlice';
import contactData from '../contactData/contactDataSlice';
import category from '../categories/categoriesSlice';
import checklist from '../checklists/checklistSlice';
import checklistItem from '../checklistItems/checklistItemsSlice';
import search from '../searches/searchesSlice';
import setting from '../settings/settingsSlice';
import personFunction from '../personFunction/personFunctionSlice';
import personalAccessToken from '../personalAccessToken/personalAccessTokenSlice';
import invoice from '../invoice/invoiceSlice';
import journal from '../journals/journalsSlice';
import protocolsSlice from '../protocol/protocolsSlice';
import protocolTemplatesSlice from '../protocol/protocolTemplatesSlice';
import resource from '../resources/resourcesSlice';
import resourceType from '../resourceTypes/resourceTypesSlice';
import resourceClass from '../resourceClasses/resourceClassesSlice';
import groups from '../groups/groupsSlice';
import employees from '../employees/employeesSlice';
import role from '../role/roleSlice';
import contract from '../contracts/contractsSlice';
import appointments from '../appointments/appointmentSlice';
import appointmentTypes from '../appointmentTypes/appointmentTypeSlice';
import priority from '../priority/prioritySlice';
import tag from '../tags/tagSlice';
import absenceTypes from '../absenceTypes/absenceTypeSlice';

import {
    ACTIVITY_RESOURCE,
    ADDRESS_RESOURCE,
    ADDRESS_TYPE_RESOURCE,
    ATTACHMENT_RESOURCE,
    AUDIT_RESOURCE,
    CARD_RESOURCE,
    CARD_STATUS_RESOURCE,
    CATEGORY_RESOURCE,
    CHECKLIST_ITEM_RESOURCE,
    CHECKLIST_RESOURCE,
    COMPANY_RESOURCE,
    CONTACT_DATA_RESOURCE,
    CONTACT_RESOURCE,
    CONTACT_TYPE_RESOURCE,
    CONTACTABLE_RESOURCE,
    CONTRACT_RESOURCE,
    DATACONTAINER_RESOURCE,
    DATACONTAINER_TYPE_RESOURCE,
    EMPLOYEE_RESOURCE,
    JOURNAL_RESOURCE,
    METADATA_RESSOURCE,
    PERSON_FUNCTION_RESOURCE,
    PERSON_RESOURCE,
    PERSONAL_ACCESS_TOKEN_RESOURCE,
    PROTOCOL_ITEM_RESOURCE,
    PROTOCOL_ITEM_TYPE_RESOURCE,
    RESOURCE_CLASS_RESOURCE,
    RESOURCE_RESOURCE,
    RESOURCE_TYPE_RESOURCE,
    GROUP_RESOURCE,
    ROLE_RESOURCE,
    APPOINTMENT_RESOURCE,
    SEARCH_RESOURCE,
    SETTING_RESSOURCE,
    TASK_DURATION_RESOURCE,
    TASK_RESOURCE,
    TASK_STATUS_RESOURCE,
    TASK_TYPE_RESOURCE,
    USER_RESOURCE,
    PROTOCOL_TEMPLATE_RESOURCE,
    PROTOCOL_RESOURCE,
    PRIORITY_RESOURCE,
    TAG_RESOURCE,
    APPOINTMENT_TYPE_RESOURCE,
    ABSENCE_TYPE_RESOURCE,
    INVOICE_RESOURCE,
} from '../api/resources';

const reducers = {
    api,
    auth,
    cloud,
    kanban: kanbanReducer,
    lists,
    dialogs,

    [METADATA_RESSOURCE]: metadata,
    [CARD_RESOURCE]: card,
    [CARD_STATUS_RESOURCE]: cardStatus,
    [CONTACT_RESOURCE]: contacts,
    contactSearch,
    [CONTACTABLE_RESOURCE]: contactables,
    [CONTACT_TYPE_RESOURCE]: contactTypes,
    [DATACONTAINER_RESOURCE]: datacontainers,
    [DATACONTAINER_TYPE_RESOURCE]: datacontainerTypes,
    [PROTOCOL_RESOURCE]: protocolsSlice,
    [PROTOCOL_TEMPLATE_RESOURCE]: protocolTemplatesSlice,
    [PROTOCOL_ITEM_RESOURCE]: protocolItem,
    [PROTOCOL_ITEM_TYPE_RESOURCE]: protocolItemType,
    [USER_RESOURCE]: user,
    [ATTACHMENT_RESOURCE]: attachment,
    [TASK_RESOURCE]: tasks,
    [TASK_DURATION_RESOURCE]: taskDurations,
    [TASK_STATUS_RESOURCE]: taskStatus,
    [TASK_TYPE_RESOURCE]: taskTypes,
    [ACTIVITY_RESOURCE]: activities,
    [PERSON_RESOURCE]: person,
    [PERSON_FUNCTION_RESOURCE]: personFunction,
    [PERSONAL_ACCESS_TOKEN_RESOURCE]: personalAccessToken,
    [COMPANY_RESOURCE]: company,
    [ADDRESS_RESOURCE]: address,
    [ADDRESS_TYPE_RESOURCE]: addressType,
    [CONTACT_DATA_RESOURCE]: contactData,
    [CATEGORY_RESOURCE]: category,
    [CHECKLIST_RESOURCE]: checklist,
    [CHECKLIST_ITEM_RESOURCE]: checklistItem,
    [SEARCH_RESOURCE]: search,
    [SETTING_RESSOURCE]: setting,
    [INVOICE_RESOURCE]: invoice,
    [JOURNAL_RESOURCE]: journal,
    [AUDIT_RESOURCE]: audit,
    [RESOURCE_RESOURCE]: resource,
    [RESOURCE_CLASS_RESOURCE]: resourceClass,
    [RESOURCE_TYPE_RESOURCE]: resourceType,
    [GROUP_RESOURCE]: groups,
    [EMPLOYEE_RESOURCE]: employees,
    [APPOINTMENT_RESOURCE]: appointments,
    [APPOINTMENT_TYPE_RESOURCE]: appointmentTypes,
    [ROLE_RESOURCE]: role,
    [CONTRACT_RESOURCE]: contract,
    [PRIORITY_RESOURCE]: priority,
    [TAG_RESOURCE]: tag,
    [ABSENCE_TYPE_RESOURCE]: absenceTypes,
};

const createReducer = () => combineReducers(reducers);

export default createReducer;
