import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ResourceForm from '../../resources/ResourceForm';
import { IdPropType } from '../../../modules/proptypes';
import { selectResourceById } from '../../../modules/resources/selectors';
import ResourceDataLoader from '../../resources/ResourceDataLoader';
import { WRITE } from '../../../modules/abilities/actions';

const ResourceBaseData = ({ resourceId, cloneResourceId, onDone, submitRef }) => {
    const { t } = useTranslation();

    const clone = useSelector(state => selectResourceById(state, cloneResourceId));

    return (
        <>
            <ResourceDataLoader resourceId={resourceId} cloneResourceId={cloneResourceId} />
            <Typography variant="h2">{t('components.ResourceDialog.tabs.basics.title')}</Typography>
            <br />
            <ResourceForm
                resourceId={resourceId}
                initialValues={clone}
                onDone={onDone}
                submitRef={submitRef}
                can={WRITE}
            />
        </>
    );
};

ResourceBaseData.propTypes = {
    resourceId: IdPropType,
    cloneResourceId: IdPropType,
    onDone: PropTypes.func,
    submitRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
};

ResourceBaseData.defaultProps = {
    resourceId: null,
    cloneResourceId: null,
    onDone: null,
    submitRef: null,
};

export default ResourceBaseData;
