import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { markdownToPlainText } from '../../../modules/markdown/utils';

const CalendarDetailSummary = ({ notes, task }) => {
    const text = useMemo(() => {
        if (notes) {
            return notes;
        }

        if (task?.description) {
            return markdownToPlainText(task.description);
        }

        return null;
    }, [notes, task]);

    return text ? (
        <Box m={2}>
            <Typography color="textSecondary">{text}</Typography>
        </Box>
    ) : null;
};

CalendarDetailSummary.propTypes = {
    notes: PropTypes.string,
    task: PropTypes.shape({}),
};

export default CalendarDetailSummary;
