import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Select from '../../form/formik/Select';
import { IdPropType } from '../../../modules/proptypes';
import { useField } from 'formik';
import { autoAssignResource } from '../../../modules/calendar/utils';
import { debounce } from 'lodash/function';
import { useResourceList } from '../../../modules/lists/hooks';
import { APPOINTMENT_TYPE_RESOURCE } from '../../../modules/api/resources';
import LoadingIcon from '../../loading/LoadingIcon';
import { selectAllAppointmentTypes } from '../../../modules/appointmentTypes/appointmentTypeSlice';
import AppointmentTypeForm from '../../appointmentType/AppointmentTypeForm';
import { useCan } from '../../../modules/abilities/hooks';
import { UPDATE } from '../../../modules/abilities/actions';

const CalendarAppointmentTypeSelect = ({
    name,
    originalName,
    resourcesName,
    label,
    hideSingleOption,
    isCreate,
    forceResource,
    ...other
}) => {
    const [{ value: appointmentTypeId }, , { setValue: setAppointmentTypeId }] = useField(name);
    const [{ value: grouped }, , { setValue: setGrouped }] = useField(resourcesName);
    const [autoAssign, setAutoAssign] = useState(isCreate);
    const [fetchParams, setFetchParams] = useState(null);
    const lastTypeId = useRef(appointmentTypeId);

    /* for admin-panel settings */
    const [selectedAdminEditItemId, setSelectedAdminEditItemId] = useState(null);
    const [selectedAdminEditItemLoading, setSelectedAdminEditItemLoading] = useState(false);
    const canAdminEdit = useCan(UPDATE, APPOINTMENT_TYPE_RESOURCE);
    const adminFormPopupRef = useRef();

    const listId = useMemo(() => {
        const baseName = 'AppointmentTypes';

        if (forceResource) {
            const resourceName = forceResource.key || forceResource.type || null;

            if (resourceName) {
                if (forceResource.id) {
                    return `${baseName}.${resourceName}.${forceResource.id}`;
                }

                return `${baseName}.${resourceName}`;
            }
        }

        return baseName;
    }, [forceResource]);

    const { fullSelector, loading, initialized, handlePage } = useResourceList({
        resource: APPOINTMENT_TYPE_RESOURCE,
        listId,
        fetchParams,
        with: ['appointment_permissions'],
        index: true,
        unique: true,
    });

    const allAppointmentTypes = useSelector(selectAllAppointmentTypes);
    const filteredAppointmentTypesById = useSelector(fullSelector);
    const filteredAppointmentTypes = useMemo(
        () => Object.values(filteredAppointmentTypesById),
        [filteredAppointmentTypesById]
    );

    const appointmentTypes = useMemo(
        () =>
            fetchParams && filteredAppointmentTypes && filteredAppointmentTypes.length
                ? filteredAppointmentTypes
                : allAppointmentTypes,
        [fetchParams, filteredAppointmentTypes, allAppointmentTypes]
    );

    const debouncedLoadAppointmentTypes = useMemo(
        () => debounce(() => handlePage(null, 1, true), 40),
        [handlePage]
    );

    const options = useMemo(() => {
        if (appointmentTypes && appointmentTypes.length > 0) {
            const types = appointmentTypes
                .sort((t1, t2) => t1.order - t2.order)
                .map(type => ({
                    value: type.id,
                    label: type.name,
                    disabled:
                        type.appointment_permissions &&
                        !type.appointment_permissions.includes(isCreate ? 'c' : 'u'),
                    onEdit: () => setSelectedAdminEditItemId(type.id),
                }));

            if (types.length !== 0) {
                return types;
            }
        }

        return [];
    }, [appointmentTypes]);

    useEffect(() => {
        if (appointmentTypes && Array.isArray(appointmentTypes) && appointmentTypes.length !== 0) {
            const newApType = !appointmentTypeId
                ? appointmentTypes.find(apType => apType.type === 'default')
                : appointmentTypes.find(apType => apType.id === appointmentTypeId);

            if (!appointmentTypeId) {
                setAppointmentTypeId(newApType.id);
            }

            if (autoAssign && initialized) {
                if (forceResource && forceResource.id && forceResource.type && newApType) {
                    const group = autoAssignResource(
                        forceResource.id,
                        forceResource.type,
                        newApType
                    );

                    if (group && !grouped[group]) {
                        setGrouped({ [group]: [forceResource.id] });
                        setAutoAssign(false);
                    }
                }

                if (
                    grouped &&
                    typeof grouped === 'object' &&
                    Object.keys(grouped).length !== 0 &&
                    !forceResource
                ) {
                    setGrouped({});
                    setAutoAssign(false);
                }
            }
        }
    }, [forceResource, appointmentTypeId, appointmentTypes, grouped, initialized, autoAssign]);

    useEffect(() => {
        if (lastTypeId.current !== appointmentTypeId) {
            setAutoAssign(true);
            lastTypeId.current = appointmentTypeId;
        }
    }, [appointmentTypeId]);

    useEffect(() => {
        if (forceResource && forceResource.type) {
            setFetchParams({
                ...(forceResource.id ? { entity_id: forceResource.id } : {}),
                entity_type: forceResource.type,
            });
        }
    }, [forceResource]);

    useEffect(() => {
        if (fetchParams && !loading && !initialized) {
            debouncedLoadAppointmentTypes();
        }
    }, [fetchParams, loading, initialized]);

    if (hideSingleOption && options.length < 2) {
        return null;
    }

    return (
        <Select
            name={name}
            originalName={originalName}
            options={options}
            label={label}
            fullWidth
            ItemEditFormPopupProps={{
                component: (
                    <AppointmentTypeForm
                        appointmentTypeId={selectedAdminEditItemId}
                        asPopup
                        popupStateRef={adminFormPopupRef}
                        onLoading={loading => setSelectedAdminEditItemLoading(loading)}
                    />
                ),
                canEdit: canAdminEdit,
                selectedEditItemValue: selectedAdminEditItemId,
                isLoading: selectedAdminEditItemLoading,
            }}
            {...other}
        />
    );
};

CalendarAppointmentTypeSelect.propTypes = {
    name: PropTypes.string.isRequired,
    originalName: PropTypes.string,
    resourcesName: PropTypes.string.isRequired,
    label: PropTypes.string,
    hideSingleOption: PropTypes.bool,
    isCreate: PropTypes.bool,
    forceResource: PropTypes.shape({
        id: IdPropType,
        type: PropTypes.string,
        key: PropTypes.string,
    }),
};

CalendarAppointmentTypeSelect.defaultProps = {
    originalName: null,
    label: '',
    hideSingleOption: false,
    isCreate: false,
    forceResource: null,
};

export default CalendarAppointmentTypeSelect;
