import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { usePopupState } from 'material-ui-popup-state/hooks';
import Popper from '@mui/material/Popper';
import { bindPopper } from 'material-ui-popup-state';
import { RefPropType } from '../../modules/proptypes';
import Paper from '@mui/material/Paper';
import { ClickAwayListener } from '@mui/material';
import Box from '@mui/material/Box';

const useStyles = makeStyles(theme => ({
    listener: {
        display: 'inline-block',
        width: '100%',
    },

    container: {
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),

        zIndex: 2,
        position: 'relative',
    },

    hoverContainer: {
        minWidth: 350,
        zIndex: 1300, // https://github.com/mui-org/material-ui/issues/18905
    },

    hoverArrow: {
        width: 10,
        position: 'absolute',
        marginLeft: -2,

        '&:after': {
            zIndex: 1,

            content: '""',
            width: 14,
            height: 14,
            position: 'absolute',
            left: '50%',
            backgroundColor: 'white',
            top: 4,
            marginLeft: -6,
            boxShadow: '12px 0 0 0 white, 0px 12px 0px 0 white, 0 -2px 10px 0 rgba(0,0,0,0.07)',
            transform: 'rotate(45deg)',
            borderLeft: '1px solid #E6E6E6',
            borderTop: '1px solid #E6E6E6',
        },
    },

    hoverInner: {
        marginTop: 10,
        border: '1px solid #E6E6E6',
        boxShadow: '0 2px 10px 0 rgba(0,0,0,0.07)',
    },
}));

const Popup = ({
    popupId,
    placement,
    onOpen,
    onAbort,
    popupStateRef,
    popupChildren,
    children,
    clickAwayListenerProps,
    ...other
}) => {
    const classes = useStyles();
    const [arrowElement, setArrowElement] = useState(null);
    const popupState = usePopupState({
        variant: 'popover',
        popupId,
    });

    const handleClick = event => {
        if (onOpen) {
            onOpen(event, popupState);
        } else {
            popupState.open(event);
        }
    };

    const handleAbort = () => {
        if (onAbort) {
            onAbort(popupState);
        } else {
            popupState.close();
        }
    };

    useEffect(() => {
        if (popupStateRef) {
            // eslint-disable-next-line no-param-reassign
            popupStateRef.current = popupState;
        }
    }, [popupStateRef, popupState.isOpen]);

    return (
        <>
            <Box onClick={handleClick} className={classes.listener}>
                {children}
            </Box>
            <Popper
                className={classes.hoverContainer}
                {...bindPopper(popupState)}
                placement={placement}
                modifiers={[
                    {
                        name: 'flip',
                        enabled: true,
                    },
                    {
                        name: 'preventOverflow',
                        enabled: true,
                        options: {
                            boundariesElement: 'scrollParent',
                            padding: 5,
                        },
                    },
                    {
                        name: 'arrow',
                        enabled: true,
                        options: {
                            element: arrowElement,
                        },
                    },
                ]}
                {...other}
            >
                <span className={classes.hoverArrow} ref={setArrowElement} />
                <Paper className={classes.hoverInner}>
                    <ClickAwayListener onClickAway={handleAbort} {...clickAwayListenerProps}>
                        <Box className={classes.container}>{popupChildren}</Box>
                    </ClickAwayListener>
                </Paper>
            </Popper>
        </>
    );
};

Popup.propTypes = {
    popupId: PropTypes.string.isRequired,
    popupStateRef: RefPropType,
    placement: PropTypes.string,
    onAbort: PropTypes.func,
    popupChildren: PropTypes.element,
    clickAwayListenerProps: PropTypes.shape({}),
};

Popup.defaultProps = {
    popupStateRef: null,
    placement: 'bottom',
    onAbort: null,
    popupChildren: null,
    clickAwayListenerProps: null,
};

export default Popup;
