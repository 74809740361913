import { USER_RESOURCE } from '../api/resources';

export const selectUsersById = state => state[USER_RESOURCE].byId;

export const selectAllUserIds = state => state[USER_RESOURCE].allIds;

export const selectUserById = (state, id) => selectUsersById(state)[id];

export const selectAuthenticatedUser = state => {
    const authUser = state.auth.user;
    const user = authUser ? selectUserById(state, authUser.id) : null;
    if (authUser) {
        return user && user.id ? { ...authUser, ...user } : authUser;
    }
    return null;
};
