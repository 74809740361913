import React from 'react';
import * as PropTypes from 'prop-types';
import TextInput from './TextInput';

const ColorPicker = ({ name, I, ...other }) => (
    <TextInput can={I} type="color" name={name} throttled {...other} />
);

ColorPicker.propTypes = {
    name: PropTypes.string.isRequired,
    I: PropTypes.string,
};

export default ColorPicker;
