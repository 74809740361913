import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectAllAppointmentTypes } from '../../../modules/appointmentTypes/appointmentTypeSlice';
import { Grid } from '@mui/material';
import CalendarFormTaskLink from './CalendarFormTaskLink';
import CalendarTaskInput from './CalendarTaskInput';
import { useField } from 'formik';
import AbsenceTypeSelect from '../../absenceType/AbsenceTypeSelect';

const formComponentsIncludes = (selectedAppointmentType, find) => {
    if (selectedAppointmentType) {
        const { component } = selectedAppointmentType;
        return component && component === find;
    }
    return false;
};

const DYNAMIC_INPUTS = [
    {
        key: 'taskAutocomplete',
        check: selectedAppointmentType => formComponentsIncludes(selectedAppointmentType, 'task'),
        component: ({ cardId }) => (
            <>
                <Grid item xs>
                    <CalendarTaskInput name="entity" cardId={cardId} />
                </Grid>
                <CalendarFormTaskLink name="entity" />
            </>
        ),
    },
    {
        key: 'absenceType',
        check: selectedAppointmentType =>
            formComponentsIncludes(selectedAppointmentType, 'absence_type'),
        component: () => (
            <Grid item xs>
                <AbsenceTypeSelect name="absenceTypeId" originalName="absence_type_id" fullWidth />
            </Grid>
        ),
    },
];

const CalendarFormDynamicInputs = ({ props }) => {
    const [{ value: appointmentTypeId }] = useField('appointmentTypeId');
    const appointmentTypes = useSelector(selectAllAppointmentTypes);

    const elements = useMemo(() => {
        const selectedAppointmentType = appointmentTypes.find(
            type => type.id === appointmentTypeId
        );

        return DYNAMIC_INPUTS.reduce((carry, { key, check, component: Component }) => {
            if (check(selectedAppointmentType) && Component) {
                return [...carry, <Component key={key} {...props} />];
            }

            return carry;
        }, []);
    }, [appointmentTypeId, appointmentTypes, DYNAMIC_INPUTS]);

    return <>{elements}</>;
};

CalendarFormDynamicInputs.propTypes = {
    props: PropTypes.shape({}),
};

CalendarFormDynamicInputs.defaultProps = {
    props: {},
};

export default CalendarFormDynamicInputs;
