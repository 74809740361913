import { PROTOCOL_TEMPLATE_RESOURCE } from '../api/resources';
import { createResourceSlice } from '../redux/resource/createResourceSlice';

const protocolTemplatesSlice = createResourceSlice({
    resource: PROTOCOL_TEMPLATE_RESOURCE,
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,

    indexProtocolTemplates,
    showProtocolTemplate,
    storeProtocolTemplate,
    updateProtocolTemplate,
    archiveProtocolTemplate,
    restoreProtocolTemplate,
    destroyProtocolTemplate,
} = protocolTemplatesSlice.actions;

export const {
    selectProtocolTemplateById,
    makeProtocolTemplatesByIdsSelector,
    selectAllProtocolTemplateIds,
    selectAllProtocolTemplates,
    selectProtocolTemplatesLoading,
    selectProtocolTemplatesInitialized,
    selectProtocolTemplateLoading,
    selectProtocolTemplateInitialized,
} = protocolTemplatesSlice.selectors;

export const { useProtocolTemplates, useProtocolTemplate } = protocolTemplatesSlice.hooks;

export default protocolTemplatesSlice.reducer;
