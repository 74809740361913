import {
    IDENTIFIER_INVOICE_SETTINGS,
    IDENTIFIER_OFFER_SETTINGS,
    IDENTIFIER_ORDER_SETTINGS,
} from '../settings/config';

export const IDENTIFIER_TYPES = {
    ORDER: 'order',
    OFFER: 'offer',
    INVOICE: 'invoice',
};

export const IDENTIFIER_MODES = {
    DATACONTAINER: 'datacontainer',
    SUGGEST: 'suggest',
};

export const IDENTIFIER_TYPE_DEFAULT_PATTERNS = {
    [IDENTIFIER_TYPES.ORDER]: 'AN-#####',
    [IDENTIFIER_TYPES.OFFER]: 'AG-#####',
    [IDENTIFIER_TYPES.INVOICE]: 'RE-#####',
};

export const IDENTIFIER_TYPE_SETTINGS = {
    [IDENTIFIER_TYPES.ORDER]: IDENTIFIER_ORDER_SETTINGS,
    [IDENTIFIER_TYPES.OFFER]: IDENTIFIER_OFFER_SETTINGS,
    [IDENTIFIER_TYPES.INVOICE]: IDENTIFIER_INVOICE_SETTINGS,
};

export const CUSTOM_ID_TYPE_PREFIX = {
    [IDENTIFIER_TYPES.OFFER]: 'Angebots-Nr',
    [IDENTIFIER_TYPES.ORDER]: 'Auftrags-Nr',
};

const PATTERN = {
    [IDENTIFIER_TYPES.OFFER]: '^\\d{2}-\\d{4}$',
    [IDENTIFIER_TYPES.ORDER]: '^\\d{4}-\\d{2}-\\d{3}$',
};

export const isIdentifierType = (idCustom, type, typeSettings) => {
    const regExp = new RegExp(typeSettings?.pattern || PATTERN[type]);
    return regExp.test(idCustom);
};

export const detectIdentifierType = (idCustom, offerSettings, orderSettings) => {
    if (isIdentifierType(idCustom, IDENTIFIER_TYPES.OFFER, offerSettings)) {
        return IDENTIFIER_TYPES.OFFER;
    }

    if (isIdentifierType(idCustom, IDENTIFIER_TYPES.ORDER, orderSettings)) {
        return IDENTIFIER_TYPES.ORDER;
    }
};

export const arrangeDatacontainers = (
    datacontainer,
    datacontainer2,
    offerSettings,
    orderSettings
) => ({
    ...(datacontainer && {
        [detectIdentifierType(datacontainer.id_custom, offerSettings, orderSettings)]:
            datacontainer,
    }),
    ...(datacontainer2 && {
        [detectIdentifierType(datacontainer2.id_custom, offerSettings, orderSettings)]:
            datacontainer2,
    }),
});
