import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import React from 'react';
import LaunchIcon from '@mui/icons-material/Launch';
import IconButton from '../form/base/IconButton';
import { READ } from '../../modules/abilities/actions';
import { SubjectPropType } from '../../modules/abilities/proptypes';

const OpenIconButton = ({
    onClick,
    size,
    contrast,
    disabled,
    subject,
    className,
    label,
    ...other
}) => {
    const { t } = useTranslation();

    return (
        <IconButton
            onClick={onClick}
            size={size}
            contrast={contrast}
            disabled={disabled}
            subject={subject}
            action={READ}
            data-test-id="OpenIconButton"
            label={label || t('components.ResourceTableRowDetails.open')}
            className={className}
            {...other}
        >
            <LaunchIcon />
        </IconButton>
    );
};

OpenIconButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    size: PropTypes.string,
    contrast: PropTypes.bool,
    disabled: PropTypes.bool,
    subject: SubjectPropType,
    className: PropTypes.string,
    label: PropTypes.string,
};

OpenIconButton.defaultProps = {
    size: null,
    contrast: false,
    disabled: false,
    subject: null,
    className: null,
    label: null,
};

export default OpenIconButton;
