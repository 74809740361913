import { EditorState, SelectionState } from 'draft-js';

export const selectFullBlock = editorState => {
    const currentKey = editorState.getSelection().getAnchorKey();
    const currentBlock = editorState.getCurrentContent().getBlockForKey(currentKey);
    const selectionState = SelectionState.createEmpty(currentKey);

    const entireBlockSelectionState = selectionState.merge({
        anchorKey: currentKey,
        anchorOffset: 0,
        focusKey: currentKey,
        focusOffset: currentBlock.getText().length,
    });

    return EditorState.forceSelection(editorState, entireBlockSelectionState);
};

export const getSelectedBlockKeys = editorState => {
    const selection = editorState.getSelection();
    let gotStart = false;
    let gotEnd = false;
    const blockMap = Array.from(editorState.getCurrentContent().getBlockMap().keys());
    return blockMap.filter(key => {
        if (!gotStart && key === selection.getStartKey()) {
            gotStart = true;
        }
        if (!gotEnd && key === selection.getEndKey()) {
            gotEnd = true;
            return true;
        }

        return gotStart && !gotEnd;
    });
};

export const getBlocksText = blocks => {
    if (!blocks) {
        return '';
    }

    return blocks.map(block => block.getText()).join('\n');
};
