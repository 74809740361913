import React from 'react';
import * as PropTypes from 'prop-types';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import { UPDATE } from '../../modules/abilities/actions';
import IconButton from '../form/base/IconButton';
import { SubjectPropType } from '../../modules/abilities/proptypes';

const RestoreIconButton = ({ onClick, size, contrast, disabled, subject }) => (
    <IconButton
        onClick={onClick}
        size={size}
        contrast={contrast}
        disabled={disabled}
        subject={subject}
        action={UPDATE}
        field="deleted_at"
        data-test-id="RestoreIconButton"
    >
        <UnarchiveOutlinedIcon />
    </IconButton>
);

RestoreIconButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    size: PropTypes.string,
    contrast: PropTypes.bool,
    disabled: PropTypes.bool,
    subject: SubjectPropType,
};

RestoreIconButton.defaultProps = {
    size: null,
    contrast: false,
    disabled: false,
    subject: null,
};

export default RestoreIconButton;
