import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { useField } from 'formik';
import { makeStyles } from '@mui/styles';
import { debounce } from 'lodash/function';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
    },
    fallbackContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        pointerEvents: 'none',
        transition: 'opacity .2s',
    },
    fallbackItem: {
        position: 'relative',
        display: 'flex',
        width: 'calc(100% - 24px)',
        margin: '8px 14px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    fallbackItemSingleLine: {
        alignItems: 'center',
        height: 'calc(100% - 12px)',
    },
    fallbackItemMultiLine: {
        height: 'calc(100% - 36px)',
        alignItems: 'start',
        paddingTop: 24,
    },
    fallbackText: {
        color: theme.palette.textPrimary,
        fontSize: 14,
    },
}));

const FallbackValue = ({
    name,
    fallbackName,
    fallbackValue,
    FormComponent,
    FallbackComponent,
    multiline,
    applyOnClick,
    style,
}) => {
    const classes = useStyles();
    const [{ value }, , { setValue }] = useField(name);
    const [{ value: fallbackField }] = useField(fallbackName);
    const [dimension, setDimension] = useState([0, 0]);
    const [focus, setFocus] = useState(false);
    const [enabled, setEnabled] = useState(true);
    const formComponentRef = useRef();

    const finalFallback = useMemo(
        () => (fallbackName && fallbackField) || fallbackValue,
        [fallbackName, fallbackField, fallbackValue]
    );

    const isFallback = useMemo(
        () => !!(!(value && (typeof value === 'string' ? value.trim() : true)) && finalFallback),
        [finalFallback, value]
    );

    const sizedStyle = useMemo(
        () =>
            dimension[0] > 0 && dimension[1] > 0
                ? {
                      height: dimension[0],
                      width: dimension[1],
                  }
                : null,
        [dimension]
    );

    const rootStyle = useMemo(
        () => ({ ...(isFallback ? sizedStyle : {}), ...(style || {}) }),
        [isFallback, sizedStyle, style]
    );

    const handleChildren = useMemo(
        () =>
            debounce(value => {
                if (
                    value?.offsetHeight &&
                    value?.offsetWidth &&
                    (dimension[0] !== value.offsetHeight || dimension[1] !== value.offsetWidth)
                ) {
                    setDimension([value.offsetHeight - 1, value.offsetWidth - 1]);
                }
            }, 50),
        []
    );

    const handleFocus = () => {
        if (enabled) {
            setFocus(true);

            if (applyOnClick) {
                setValue(finalFallback);
                setEnabled(false);

                if (formComponentRef.current) {
                    setTimeout(() => {
                        formComponentRef.current.select();
                    }, 25);
                }
            }
        }
    };

    const handleBlur = () => {
        setFocus(false);
    };

    useEffect(() => {
        if (value === null) {
            setFocus(false);
        }

        if (value === '') {
            setEnabled(true);
        }
    }, [value]);

    return (
        <Box className={classes.root} style={rootStyle}>
            <Box ref={handleChildren} onFocus={handleFocus} onBlur={handleBlur}>
                <FormComponent hideLabel={enabled && isFallback} ref={formComponentRef} />
            </Box>
            <Box
                className={classes.fallbackContainer}
                style={{
                    ...sizedStyle,
                    opacity: !enabled || !isFallback || focus ? 0 : 0.6,
                }}
            >
                <Box
                    className={classNames({
                        [classes.fallbackItem]: true,
                        [classes.fallbackItemSingleLine]: !multiline,
                        [classes.fallbackItemMultiLine]: multiline,
                    })}
                    style={{ maxWidth: sizedStyle?.width }}
                >
                    {FallbackComponent ? (
                        <FallbackComponent fallback={finalFallback} />
                    ) : (
                        <Typography
                            className={classes.fallbackText}
                            style={multiline ? { textWrap: 'wrap' } : {}}
                        >
                            {finalFallback}
                        </Typography>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

FallbackValue.propTypes = {
    name: PropTypes.string.isRequired,
    fallbackName: PropTypes.string,
    fallbackValue: PropTypes.element,
    FormComponent: PropTypes.elementType.isRequired,
    FallbackComponent: PropTypes.elementType,
    multiline: PropTypes.bool,
    applyOnClick: PropTypes.bool,
    style: PropTypes.shape({}),
};

FallbackValue.defaultProps = {
    fallbackName: null,
    fallbackValue: null,
    FallbackComponent: null,
    multiline: false,
    applyOnClick: false,
    style: {},
};

export default FallbackValue;
