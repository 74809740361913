import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { getColumnTypeConfig } from '../../modules/resourceTable/columnTypes';

const ResourceTablePrintCell = ({ columns, table, cell, aggregateMode = false }) => {
    const renderedValue = useMemo(() => {
        if (cell?.getValue) {
            return cell?.getValue();
        }

        return null;
    }, [cell]);

    const column = useMemo(() => {
        if (Array.isArray(columns) && cell?.column?.id) {
            const column = columns.find(
                column =>
                    column?.valueKey === cell.column.id ||
                    column?.accessorKey === cell.column.id ||
                    column?.id === cell.column.id
            );

            if (column) {
                return column;
            }
        }

        return {};
    }, [columns, cell]);

    const { Cell, convertString, forcePrintString } = useMemo(
        () => getColumnTypeConfig(column, false),
        [column]
    );

    if (Cell && !forcePrintString) {
        return (
            <Cell
                renderedCellValue={renderedValue}
                table={table}
                column={cell.column}
                row={cell.row}
            />
        );
    }

    if (aggregateMode && typeof column?.aggregationConfig?.convertString === 'function') {
        return column?.aggregationConfig?.convertString(renderedValue, cell?.row?.original);
    }

    if (typeof convertString === 'function') {
        return convertString(renderedValue, cell?.row?.original);
    }

    return renderedValue || null;
};

ResourceTablePrintCell.propTypes = {};

export default ResourceTablePrintCell;
