import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import UndoIcon from '@mui/icons-material/Undo';
import Button from '../form/base/Button';
import EditButton from '../buttons/EditButton';
import DeleteButton from '../buttons/DeleteButton';
import { useTranslation } from 'react-i18next';
import IconButton from '../form/base/IconButton';

// grouped,
const ResourceTableSelectionActions = ({
    table,
    selected,
    resource,
    onEdit,
    onDelete,
    onReset,
    renderSelectionOptions,
}) => {
    const { t } = useTranslation();
    const state = table.getState();

    const { message, selectedIds } = useMemo(() => {
        const { rowSelection } = state || {}; //, grouping
        const selectedIds = Object.keys(rowSelection).map(
            rowId => table?.getRow(rowId)?.original?.id
        );

        return {
            message: `${selectedIds.length} ${t(
                'components.ResourceTable.SelectionActions.count'
            )}`,
            selectedIds,
        };
    }, [state]);

    const handleReset = () => {
        if (onReset) {
            onReset(table);
        } else if (selected && table?.resetRowSelection && table.resetRowPinning) {
            table.resetRowSelection();
            table.resetRowPinning();
        }
    };

    const handleEdit = () => {
        onEdit(selectedIds, table);
    };

    const handleDelete = () => {
        handleReset();

        return onDelete(selectedIds, table);
    };

    return (
        <Grid container spacing={2} alignItems="center" height={52} ml={0} width="100%">
            {selected ? (
                <Grid item>
                    <IconButton
                        label={t('components.ResourceTable.SelectionActions.reset')}
                        size="small"
                        onClick={handleReset}
                    >
                        <UndoIcon />
                    </IconButton>
                </Grid>
            ) : null}
            {message ? (
                <Grid item>
                    <Typography>{message}</Typography>
                </Grid>
            ) : null}
            {selected && onEdit ? (
                <Grid item>
                    <EditButton subject={resource} onClick={handleEdit} />
                </Grid>
            ) : null}
            {renderSelectionOptions
                ? renderSelectionOptions(selectedIds, table, handleReset)
                : null}
            {selected && onDelete ? (
                <Grid item>
                    <DeleteButton
                        subject={resource}
                        confirmBadge={selectedIds.length > 1 ? selectedIds.length : null}
                        onClick={handleDelete}
                    />
                </Grid>
            ) : null}
        </Grid>
    );
};

ResourceTableSelectionActions.propTypes = {
    table: PropTypes.shape({}).isRequired,
    grouped: PropTypes.shape({}),
    selected: PropTypes.shape({}),
    resource: PropTypes.string.isRequired,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    onReset: PropTypes.func,
    renderSelectionOptions: PropTypes.func,
};

ResourceTableSelectionActions.defaultProps = {
    grouped: null,
    selected: null,
    onEdit: null,
    onDelete: null,
    onReset: null,
    renderSelectionOptions: null,
};

export default ResourceTableSelectionActions;
