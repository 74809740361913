import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import EmailOutlinedIcon from '@mui/icons-material/MailOutline';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutline';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';

const mimes = {
    missing: ErrorOutlinedIcon,
    image: ImageOutlinedIcon,
    pdf: PictureAsPdfOutlinedIcon,
    email: EmailOutlinedIcon,
    generic: InsertDriveFileOutlinedIcon,
    directory: FolderOpenOutlinedIcon,
};

const getIcon = mime => {
    if (mimes[mime]) {
        return mimes[mime];
    }

    if (mime.startsWith('missing')) {
        return mimes.missing;
    }

    if (mime.startsWith('image')) {
        return mimes.image;
    }

    if (mime.startsWith('application/pdf')) {
        return mimes.pdf;
    }

    return mimes.generic;
};

const FileMimeIcon = ({ mime, missing, ...other }) => {
    const Icon = useMemo(() => getIcon(missing ? 'missing' : mime || ''), [missing, mime]);

    return <Icon {...other} />;
};

FileMimeIcon.propTypes = {
    mime: PropTypes.string,
    missing: PropTypes.bool,
};

FileMimeIcon.defaultProps = {
    mime: '',
    missing: false,
};

export default FileMimeIcon;
