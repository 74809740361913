import React from 'react';
import * as PropTypes from 'prop-types';

import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ClickBoundary from '../form/ClickBoundary';
import TableAction from './TableAction';
import { TableActionPropType } from './proptypes';

const useStyles = makeStyles(theme => ({
    actionHeader: {
        fontWeight: 700,
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(2),
    },
}));

const TableRowDetails = ({ component: Component, data, actions }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Grid container spacing={2}>
            <Grid item xs>
                {Component && <Component data={data} />}
            </Grid>
            {actions && actions.length > 0 && (
                <Grid item>
                    <Typography variant="body2" className={classes.actionHeader}>
                        {t(`components.TableRowDetails.actions`)}
                    </Typography>
                    <ClickBoundary>
                        <Grid container spacing={1} direction="column">
                            {actions.map(action => (
                                <Grid item key={action.key}>
                                    <TableAction action={action} data={data} />
                                </Grid>
                            ))}
                        </Grid>
                    </ClickBoundary>
                </Grid>
            )}
        </Grid>
    );
};

TableRowDetails.propTypes = {
    component: PropTypes.func,
    data: PropTypes.shape({}),
    actions: PropTypes.arrayOf(TableActionPropType),
};

TableRowDetails.defaultProps = {
    component: null,
    data: null,
    actions: null,
};

export default TableRowDetails;
