import { PROTOCOL_ITEM_TYPE_RESOURCE } from '../api/resources';

export const selectProtocolItemTypesIsLoading = state => state[PROTOCOL_ITEM_TYPE_RESOURCE].loading;

export const selectProtocolItemTypesError = state => state[PROTOCOL_ITEM_TYPE_RESOURCE].error;

export const selectProtocolItemTypesById = state => state[PROTOCOL_ITEM_TYPE_RESOURCE].byId;

export const selectAllProtocolItemTypesIds = state => state[PROTOCOL_ITEM_TYPE_RESOURCE].allIds;

export const selectProtocolItemTypeById = (state, id) => selectProtocolItemTypesById(state)[id];
