import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Button from './base/Button';
import LoadingButton from '../loading/LoadingButton';
import { usePermissionSubject } from '../../modules/abilities/PermissionSubjectProvider';
import { useCan } from '../../modules/abilities/hooks';
import { resolveAction } from '../../modules/abilities/actions';

const useStyles = makeStyles(theme => ({
    label: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },

    first: {
        marginRight: theme.spacing(4),
    },

    danger: {
        color: theme.palette.error.main,
    },

    labelCompact: {
        display: 'inline-block',
        position: 'relative',
        top: 2,
    },

    buttonCompact: {
        marginRight: theme.spacing(1),
    },
}));

const DestroyIntent = React.forwardRef(
    (
        {
            onConfirmed,
            compact,
            label,
            labelConfirm,
            labelConfirmSub,
            setYesLeft,
            onToggle,
            onIntent,
            onAbort,
            noDanger,
            contrast,
            hidden,
            icon,
            disabled,
            action,
            subject,
            dangerText,
        },
        ref
    ) => {
        const classes = useStyles();
        const [confirm, setConfirm] = useState(false);
        const { t } = useTranslation();
        const contextualSubject = usePermissionSubject();
        const can =
            useCan(resolveAction(action), subject === undefined ? contextualSubject : subject) ||
            !action;

        const handleOpenConfirm = () => {
            onIntent();
            setConfirm(true);
        };

        const handleAbort = () => {
            onAbort();
            setConfirm(false);
        };

        useEffect(() => {
            onToggle(confirm);
        }, [confirm, onToggle]);

        useEffect(() => {
            if (hidden) {
                setConfirm(false);
            }
        }, [hidden, setConfirm]);

        const NoButton = () => (
            <Button
                data-test-id="DestroyIntentButtonNo"
                onClick={handleAbort}
                size="tiny"
                variant={contrast ? 'contained' : 'outlined'}
                className={classNames({
                    [classes.buttonCompact]: compact,
                    [classes.first]: !compact && !setYesLeft,
                })}
                contrast={contrast}
            >
                {t('no')}
            </Button>
        );

        const YesButton = () => (
            <LoadingButton
                data-test-id="DestroyIntentButtonYes"
                onClick={onConfirmed}
                size="tiny"
                color="danger"
                variant="contained"
                className={classNames({
                    [classes.buttonCompact]: compact,
                    [classes.first]: !compact && setYesLeft,
                })}
                contrast={contrast}
            >
                {t('yes')}
            </LoadingButton>
        );

        if (!can) {
            return null;
        }

        return confirm ? (
            <>
                <Typography
                    data-test-id="DestroyIntentButtonConfirm"
                    variant="body2"
                    component={`${compact ? 'span' : 'p'}`}
                    className={classNames(classes.label, {
                        [classes.danger]: dangerText,
                        [classes.labelCompact]: compact,
                    })}
                >
                    {labelConfirm || t('components.DestroyIntent.confirm')}
                </Typography>
                {labelConfirmSub && (
                    <Typography variant="body2" className={classes.label}>
                        {labelConfirmSub}
                    </Typography>
                )}
                {setYesLeft ? (
                    <>
                        <YesButton />
                        <NoButton />
                    </>
                ) : (
                    <>
                        <NoButton />
                        <YesButton />
                    </>
                )}
            </>
        ) : (
            <Button
                ref={ref}
                data-test-id="DestroyIntentButtonDestroy"
                onClick={handleOpenConfirm}
                className={classNames({
                    [classes.buttonCompact]: compact,
                })}
                color={noDanger ? 'primary' : 'danger'}
                startIcon={icon}
                disabled={disabled}
            >
                {label || t('delete')}
            </Button>
        );
    }
);

DestroyIntent.propTypes = {
    onConfirmed: PropTypes.func.isRequired,
    compact: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    labelConfirm: PropTypes.string,
    labelConfirmSub: PropTypes.string,
    onIntent: PropTypes.func,
    onAbort: PropTypes.func,
    setYesLeft: PropTypes.bool,
    onToggle: PropTypes.func,
    noDanger: PropTypes.bool,
    contrast: PropTypes.bool,
    hidden: PropTypes.bool,
    icon: PropTypes.node,
    disabled: PropTypes.bool,
    action: PropTypes.string,
    subject: PropTypes.shape({}),
    dangerText: PropTypes.bool,
};

DestroyIntent.defaultProps = {
    compact: false,
    label: null,
    labelConfirm: null,
    labelConfirmSub: null,
    onIntent: () => null,
    onAbort: () => null,
    setYesLeft: false,
    onToggle: () => null,
    noDanger: false,
    contrast: false,
    hidden: false,
    icon: null,
    disabled: false,
    action: null,
    subject: undefined,
    dangerText: true,
};

export default DestroyIntent;
