import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { getI18n, useTranslation } from 'react-i18next';
import Select from './formik/Select';
import countriesDe from '../../assets/locales/de/countries.json';
import countriesEn from '../../assets/locales/en/countries.json';

const prepareCountryOptions = countries =>
    countries.reduce((carry, country) => {
        const option = { label: country.name, value: country.name };
        if (country.alpha2 === 'de') {
            carry.unshift(option);
        } else {
            carry.push(option);
        }
        return carry;
    }, []);

const COUNTRIES = {
    de: prepareCountryOptions(countriesDe),
    en: prepareCountryOptions(countriesEn),
};

const CountrySelect = ({ name, label, fullWidth }) => {
    const { t } = useTranslation();
    const lang = getI18n().language;
    const options = useMemo(
        () => [
            {
                label: t('components.Address.noCountry'),
                value: '',
            },
            ...COUNTRIES[lang],
        ],
        [t, lang]
    );
    return <Select name={name} label={label} options={options} fullWidth={fullWidth} />;
};

CountrySelect.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    fullWidth: PropTypes.bool,
};

CountrySelect.defaultProps = {
    label: null,
    fullWidth: false,
};

export default CountrySelect;
