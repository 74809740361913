import React from 'react';
import * as PropTypes from 'prop-types';
import { useField } from 'formik';
import BaseCardAutocomplete from '../../base/autocomplete/BaseCardAutocomplete';
import { getFormFieldError } from '../../../../modules/loading/utils';
import FormHelperText from '@mui/material/FormHelperText';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectCardById } from '../../../../modules/cards/selectors';

const CardAutocomplete = ({ name, label, showInitialState, onChange, ...other }) => {
    const { t } = useTranslation();
    const [{ value }, meta, { setValue }] = useField(name);
    const error = getFormFieldError(name, null, meta);
    const selectedCard = useSelector(state => selectCardById(state, value));

    const handleChange = value => {
        setValue(value?.id || '');

        if (onChange) {
            onChange(value);
        }
    };

    return (
        <BaseCardAutocomplete
            name={name}
            value={value}
            label={label || t('components.Card.autocomplete')}
            error={error}
            initialValue={showInitialState ? selectedCard?.title : null}
            onChange={handleChange}
            {...other}
        />
    );
};

CardAutocomplete.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    showInitialState: PropTypes.bool,
    onChange: PropTypes.func,
};

CardAutocomplete.defaultProps = {
    label: null,
    showInitialState: false,
    onChange: null,
};

export default CardAutocomplete;
