import { Icon, Tab, Tabs, Tooltip } from '@mui/material';
import { withStyles, makeStyles } from '@mui/styles';
import * as PropTypes from 'prop-types';
import React from 'react';

const StyledTabs = withStyles({
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        width: '60px !important',
        '& > span': {
            width: '100%',
            height: 2,
            backgroundColor: 'white',
        },
    },
})(props => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles(theme => ({
    root: {
        textTransform: 'none',
        color: '#fff',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        opacity: 0.7,
        '&:focus': {
            opacity: 1,
        },
        '&.Mui-selected': {
            color: '#fff',
            opacity: 1,
        },
    },
}))(props => <Tab {...props} />);

const useStyles = makeStyles({
    tab: {
        width: 60,
        minWidth: 'unset',
        flexGrow: 1,
    },
});

/**
 *
 * @param categorizedList [{icon, label, items}, ...]
 * @param value
 * @param onChange
 * @return {JSX.Element}
 * @constructor
 */

const IconTabs = ({ categorizedList, value, onChange }) => {
    const classes = useStyles();

    return (
        <StyledTabs value={value} onChange={onChange} variant="scrollable" scrollButtons>
            {categorizedList.map(category => (
                <StyledTab
                    key={`tab_${category.icon}`}
                    className={classes.tab}
                    icon={
                        <Tooltip title={category.label} disableInteractive>
                            <Icon>{category.icon}</Icon>
                        </Tooltip>
                    }
                />
            ))}
        </StyledTabs>
    );
};

IconTabs.propTypes = {
    categorizedList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default IconTabs;
