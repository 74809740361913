import React from 'react';
import * as PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

const LocationSuggestion = ({ value, onClick, buttonLabel }) => {
    return (
        <Grid container alignItems="center" spacing={1}>
            <Grid item>
                <Typography variant="body2">{value}</Typography>
            </Grid>
            <Grid item>
                <Button
                    onClick={() => {
                        if (onClick) {
                            onClick(value);
                        }
                    }}
                    size="small"
                    variant="outlined"
                >
                    {buttonLabel}
                </Button>
            </Grid>
        </Grid>
    );
};

LocationSuggestion.propTypes = {
    value: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    buttonLabel: PropTypes.string,
};

LocationSuggestion.defaultProps = {
    onClick: undefined,
    buttonLabel: '',
};

export default LocationSuggestion;
