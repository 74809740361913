import { createSelector } from '@reduxjs/toolkit';

export const selectUser = state => state.auth.user;

export const selectLoginIsInitialized = state => state.auth.isInitialized;

export const selectLoginError = state => state.auth.error;

export const selectUserIsRoot = createSelector(
    [selectUser],
    user => user && user.permissions.roles.some(({ name }) => name === 'root')
);

export const selectTmpKumoOutlookUserToken = state => state.auth._tmpKumoOutlookUserToken;
