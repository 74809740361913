import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AutocompleteAddButton from '../../formik/autocomplete/AutocompleteAddButton';
import TypedAutocomplete from './TypedAutocomplete';
import { autocompleteGroups } from '../../../../modules/groups/actions';
import { CREATE } from '../../../../modules/abilities/actions';
import { GROUP_RESOURCE } from '../../../../modules/api/resources';

const GroupsAutocomplete = ({
    initialValue,
    label,
    variant,
    onChange,
    onInputChange,
    onAddClick,
    getOptionDisabled,
    contrast,
    fullWidth,
    size,
    className,
    name,
    I,
    ...other
}) => {
    const { t } = useTranslation();
    const [value, setValue] = useState([]);

    const handleChange = newValue => {
        setValue(newValue);
        onChange(newValue);

        if (newValue && newValue.onCreate) {
            newValue.onCreate();
        }
    };

    return (
        <TypedAutocomplete
            name={name}
            fetchAction={autocompleteGroups}
            value={initialValue || value}
            label={label}
            onChange={handleChange}
            onInputChange={onInputChange}
            getOptionDisabled={getOptionDisabled}
            variant={variant}
            contrast={contrast}
            size={size}
            fullWidth={fullWidth}
            className={className}
            popupSize="small"
            footer={
                onAddClick ? (
                    <AutocompleteAddButton
                        onClick={onAddClick}
                        buttonLabel={t('components.Group.addNewGroup')}
                        action={CREATE}
                        subject={GROUP_RESOURCE}
                    />
                ) : null
            }
            I={I}
            {...other}
        />
    );
};

GroupsAutocomplete.propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func,
    onInputChange: PropTypes.func,
    onAddClick: PropTypes.func,
    getOptionDisabled: PropTypes.func,
    variant: PropTypes.string,
    contrast: PropTypes.bool,
    size: PropTypes.string,
    fullWidth: PropTypes.bool,
    className: PropTypes.string,
    initialValue: PropTypes.array,
    I: PropTypes.string,
};

GroupsAutocomplete.defaultProps = {
    label: null,
    onChange: () => null,
    onInputChange: undefined,
    onAddClick: null,
    getOptionDisabled: null,
    variant: 'outlined',
    contrast: false,
    size: 'small',
    fullWidth: false,
    className: null,
    initialValue: null,
    I: null,
};

export default GroupsAutocomplete;
