import * as Yup from 'yup';

export const personSchema = Yup.object().shape(
    {
        gender: Yup.string().oneOf(['male', 'female', 'unknown']).required('required'),
        title: Yup.string().nullable(),
        first_name: Yup.string().when('last_name', {
            is: last_name => !last_name || !last_name.trim(),
            then: Yup.string().trim().required('required').max(255, 'string255MaxLength'),
            otherwise: Yup.string().nullable(),
        }),
        last_name: Yup.string().when('first_name', {
            is: first_name => !first_name || !first_name.trim(),
            then: Yup.string().trim().required('required').max(255, 'string255MaxLength'),
            otherwise: Yup.string().nullable(),
        }),
        birthday: Yup.date().nullable(),
    },
    ['last_name', 'first_name']
);
