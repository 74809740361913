import { EMPLOYEE_RESOURCE } from '../api/resources';

export const indexEmployees = (params, meta) => (dispatch, getState, api) =>
    api[EMPLOYEE_RESOURCE].index(params, meta);

export const showEmployee = (params, meta) => (dispatch, getState, api) =>
    api[EMPLOYEE_RESOURCE].show(params, meta);

export const storeEmployee = (params, meta) => (dispatch, getState, api) =>
    api[EMPLOYEE_RESOURCE].store(params, meta);

export const updateEmployee = (params, meta) => (dispatch, getState, api) =>
    api[EMPLOYEE_RESOURCE].update(params, meta);

export const destroyEmployee = (params, meta) => (dispatch, getState, api) =>
    api[EMPLOYEE_RESOURCE].destroy(params, meta);

export const restoreEmployee = (params, meta) => (dispatch, getState, api) =>
    api[EMPLOYEE_RESOURCE].restore(params, meta);
