import React, { memo, useEffect, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchAppointmentsAvailability,
    indexAppointments,
    selectAllAppointmentIds,
    selectAllAppointments,
} from '../../../modules/appointments/appointmentSlice';
import { useFieldFast } from '../../../modules/form/hooks';
import { assembleLocal, importDate } from '../../../modules/datetime/utils';
import { IdPropType } from '../../../modules/proptypes';
import { difference } from 'lodash';
import { formatAppointmentImport } from '../../../modules/appointments/utils';

const AppointmentsAvailabilityContext = React.createContext(null);

export const useAppointmentsAvailability = () =>
    React.useContext(AppointmentsAvailabilityContext) || {};

const AppointmentsAvailabilityProvider = ({
    appointmentId,
    startDate,
    endDate,
    allDay,
    startDateFieldName,
    endDateFieldName,
    allDayFieldName,
    children,
}) => {
    const dispatch = useDispatch();
    const [derivedStartDate, setDerivedStartDate] = useState(startDate);
    const [derivedEndDate, setDerivedEndDate] = useState(endDate);
    const [derivedAllDay, setDerivedAllDay] = useState(allDay);
    const [appointmentsAvailability, setAppointmentsAvailability] = useState(null);
    const [availabilityLoading, setAvailabilityLoading] = useState(false);

    const [{ value: formikStartDate }] = useFieldFast(startDateFieldName);
    const [{ value: formikEndDate }] = useFieldFast(endDateFieldName);
    const [{ value: formikAllDay }] = useFieldFast(allDayFieldName);

    const appointmentIds = useSelector(selectAllAppointmentIds);
    const appointments = useSelector(selectAllAppointments);

    const getAppointmentsAvailabilityByModelAndId = (model, id) => {
        if (
            appointmentsAvailability &&
            appointmentsAvailability[model] &&
            appointmentsAvailability[model][id]
        ) {
            return appointmentsAvailability[model][id];
        }
        // returning {} to avoid falsy condition (appointmentsAvailability && <AvailabilityStatus />)
        // and diplay default status too when appointments availability is set for this resource
        return availabilityLoading ? null : {};
    };

    const getAsyncConflictAppointmentsByModelAndId = async (model, id) => {
        return new Promise(async (resolve, reject) => {
            try {
                const availabilityByModelAndId = getAppointmentsAvailabilityByModelAndId(model, id);

                if (availabilityByModelAndId?.appointments?.length > 0) {
                    const missingAppointmentIds = difference(
                        [...availabilityByModelAndId.appointments],
                        [...appointmentIds]
                    );

                    let finalAppointments = [...appointments];

                    if (missingAppointmentIds.length > 0) {
                        await dispatch(indexAppointments({ ids: missingAppointmentIds })).then(
                            ({ data }) => {
                                finalAppointments = [...finalAppointments, ...data];
                            }
                        );
                    }

                    const filteredAppointments = finalAppointments?.filter(appointment =>
                        availabilityByModelAndId.appointments.includes(appointment.id)
                    );

                    const formattedAppointments = filteredAppointments?.map(appointment =>
                        formatAppointmentImport(appointment)
                    );

                    resolve(formattedAppointments);
                } else {
                    resolve(null);
                }
            } catch (error) {
                reject(error);
            }
        });
    };

    useEffect(() => {
        let derivedStartDate = startDate || (startDateFieldName ? formikStartDate : null);
        let derivedEndDate = endDate || (endDateFieldName ? formikEndDate : null);
        const derivedAllDay =
            typeof allDay === 'boolean' ? allDay : allDayFieldName ? formikAllDay : null;

        if (derivedAllDay) {
            derivedStartDate = derivedStartDate
                ? assembleLocal(importDate(derivedStartDate), '00:00:00')
                : null;
            derivedEndDate = derivedEndDate
                ? assembleLocal(importDate(derivedEndDate), '23:59:59')
                : null;
        }

        setDerivedStartDate(derivedStartDate);
        setDerivedEndDate(derivedEndDate);
        setDerivedAllDay(derivedAllDay);

        if (derivedStartDate || derivedEndDate) {
            setAvailabilityLoading(true);
            dispatch(
                fetchAppointmentsAvailability({
                    start: derivedStartDate,
                    end: derivedEndDate,
                    ...(appointmentId && { appointment_id: appointmentId }),
                })
            )
                .then(({ data }) => setAppointmentsAvailability(data))
                .finally(() => setAvailabilityLoading(false));
        } else {
            setAppointmentsAvailability(null);
        }
    }, [
        appointmentId,
        startDate,
        endDate,
        allDay,
        startDateFieldName,
        endDateFieldName,
        allDayFieldName,
        formikStartDate,
        formikEndDate,
        formikAllDay,
    ]);

    const values = useMemo(
        () => ({
            appointmentId,
            startDate: derivedStartDate,
            startDate: derivedEndDate,
            allDay: derivedAllDay,
            appointmentsAvailability,
            availabilityLoading,
            getAppointmentsAvailabilityByModelAndId,
            getAsyncConflictAppointmentsByModelAndId,
        }),
        [
            appointmentId,
            derivedStartDate,
            derivedEndDate,
            derivedAllDay,
            appointmentsAvailability,
            availabilityLoading,
            getAppointmentsAvailabilityByModelAndId,
            getAsyncConflictAppointmentsByModelAndId,
        ]
    );

    return (
        <AppointmentsAvailabilityContext.Provider value={values}>
            {children}
        </AppointmentsAvailabilityContext.Provider>
    );
};

AppointmentsAvailabilityProvider.propTypes = {
    appointmentId: IdPropType,
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
    allDay: PropTypes.bool,
    startDateFieldName: PropTypes.string,
    endDateFieldName: PropTypes.string,
    allDayFieldName: PropTypes.string,
};

export default memo(AppointmentsAvailabilityProvider);
