import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import { convertFromRaw, convertToRaw, EditorState, Modifier, SelectionState } from 'draft-js';
import { EDITOR_HIGHLIGHT, remarkableHighlightPlugin } from './highlight';
import { remarkableMentionPlugin } from './mention';
import { getEditorEntities } from './element';
import { remarkableTagPlugin } from './tag';

export const markdownToEditorState = (markdownString = '', responsibleUserIds = []) => {
    // TODO mentions / tags evtl. wieder parsen und einlesen
    // https://www.npmjs.com/package/markdown-draft-js
    const contentState = convertFromRaw(
        markdownToDraft(markdownString, {
            preserveNewlines: true,
            remarkablePlugins: [
                remarkableMentionPlugin,
                remarkableTagPlugin,
                remarkableHighlightPlugin,
            ],
            blockEntities: {
                mention_open(item) {
                    return {
                        type: 'mention',
                        mutability: 'IMMUTABLE',
                        data: {
                            mention: {
                                id: item.id,
                                name: item.name,
                                avatar: item.avatar,
                            },
                        },
                    };
                },
                tag_open(item) {
                    const { name } = item;
                    return {
                        type: '#mention',
                        mutability: 'IMMUTABLE',
                        data: {
                            mention: { name },
                        },
                    };
                },
                highlight_open(item) {
                    return {
                        type: EDITOR_HIGHLIGHT,
                        mutability: 'MUTABLE',
                        data: {
                            backgroundColor: item.backgroundColor,
                            color: item.color,
                            text: item.text,
                        },
                    };
                },
            },
        })
    );

    const content = getEditorEntities(contentState).reduce((state, entity) => {
        if (
            entity.entity.type.includes('mention') &&
            entity.entity.data.mention &&
            !responsibleUserIds.includes(entity.entity.data.mention.id)
        ) {
            const selectionState = SelectionState.createEmpty(entity.blockKey);
            const updatedSelection = selectionState.merge({
                anchorOffset: entity.start,
                anchorKey: entity.blockKey,
                focusOffset: entity.end,
                focusKey: entity.blockKey,
                isBackward: false,
            });

            return Modifier.applyEntity(state, updatedSelection, entity.entityKey || null);
        }
        return state;
    }, contentState);

    return EditorState.createWithContent(content);
};

export const rawDraftToMarkdown = rawEditorContent => {
    return draftToMarkdown(rawEditorContent, {
        preserveNewlines: true,
        entityItems: {
            mention: {
                open(entity) {
                    // open() {
                    // TODO mentions anders abspeichern?
                    // Ich hab nochmal nachgedacht und denke fast, dass mentions gar nicht wieder eingelesen werden müssen.
                    // Die Responsibles werden nämlich beim Speichern erstellt.
                    // 1. Protokolleinträge anzeigen: es kann einfach @username plaintext darin stehen
                    // 2. Protokolleinträge bearbeiten: vorhandene Responsibles entfernen kann man über die Bubble über dem Eintrag.
                    // Neue Responsibles können im Editor hinzugefügt werden.
                    // return '@';
                    // return `<mention data-user-id="${entity.data.mention.id}" data-user-avatar="${entity.data.mention.avatar}">`;
                    return `<mention data-user-id="${entity.data.mention.id}" data-user-avatar="${entity.data.mention.avatar}" data-user-name="${entity.data.mention.name}">`;
                },
                close() {
                    return '</mention>';
                },
            },
            '#mention': {
                open(entity) {
                    return `<tag data-name="${entity.data.mention.name}">`;
                },
                close() {
                    return '</tag>';
                },
            },
            HIGHLIGHT: {
                open(entity) {
                    return `<highlight data-background="${entity.data.backgroundColor}" data-color="${entity.data.color}" data-text="${entity.data.text}">`;
                },
                close() {
                    return '</highlight>';
                },
            },
        },
    });
};

export const editorStateToMarkdown = state =>
    rawDraftToMarkdown(convertToRaw(state.getCurrentContent()));
