/* must match the values for activity.type that are defined in the api */
export const RESPONSIBLE_ACTIVITY = 'RESPONSIBLE',
    RESPONSIBLE_REMOVE_ACTIVITY = 'RESPONSIBLE_REMOVE',
    APPOINTMENT_ACTIVITY = 'APPOINTMENT',
    APPOINTMENT_REMOVE_ACTIVITY = 'APPOINTMENT_REMOVE';

export const getActivitySubtype = activity => {
    switch (activity.type) {
        case RESPONSIBLE_ACTIVITY:
        case RESPONSIBLE_REMOVE_ACTIVITY:
            if (activity.protocol_item) {
                return 'mention';
            }

            if (activity.checklist_item) {
                return 'checklistItem';
            }

            if (activity.task) {
                return 'task';
            }
            break;
        case APPOINTMENT_ACTIVITY:
        case APPOINTMENT_REMOVE_ACTIVITY:
            if (activity.appointment) {
                return 'appointment';
            }
            break;
    }
    return null;
};
