import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { Collapse, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ExpandLessOutlined from '@mui/icons-material/ExpandLessOutlined';

import FileListItem from './FileListItem';
import { FilePropType } from '../../modules/files/proptypes';
import { ConditionalWrapper } from '../utils/ConditionalWrapper';
import { getFileName, getFilePath } from '../../modules/files/utils';
import CloudStatusCheck from '../cloud/CloudStatusCheck';
import { useApi } from '../../modules/api/ApiProvider';

const useStyles = makeStyles(theme => ({
    listItem: {
        border: `1px solid ${theme.palette.grey['300']}`,
        borderRadius: theme.spacing(0.5),
        padding: theme.spacing(1, 2),
        '&:not(:last-child)': {
            marginBottom: theme.spacing(1),
        },
    },
    expandBar: {
        cursor: 'pointer',
        border: `1px solid ${theme.palette.grey['300']}`,
        borderRadius: theme.spacing(0.5),
        marginBottom: theme.spacing(2),
    },
    expandLabel: {
        color: theme.palette.text.hint,
        margin: theme.spacing(1, 0),
    },
}));

const FileList = ({
    cloudRoot,
    attachments,
    showDirectory,
    blocked,
    expanded: initialExpanded,
}) => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(initialExpanded);
    const collapse = attachments && attachments.length > 5;
    const { kumoConnect } = useApi();
    const [kumoConnectStatus, setKumoConnectStatus] = useState(null);

    useEffect(() => {
        async function fetchStatus() {
            const status = await kumoConnect.getKumoConnectStatus();
            setKumoConnectStatus(status && status.data ? status.data : null);
        }
        fetchStatus();
    }, []);

    return (
        <CloudStatusCheck blocked={blocked} margin="dense">
            <ConditionalWrapper
                condition={collapse}
                wrapper={children => (
                    <Collapse in={expanded} timeout="auto">
                        {children}
                    </Collapse>
                )}
            >
                <List dense>
                    {attachments.map(file => (
                        <ListItem
                            key={file.path}
                            className={classNames({
                                [classes.listItem]: true,
                            })}
                        >
                            <FileListItem
                                cloudRoot={cloudRoot}
                                file={{
                                    ...file,
                                    path: getFilePath(file.path),
                                    filename: getFileName(file.path),
                                }}
                                showDirectory={showDirectory}
                                kumoConnectStatus={kumoConnectStatus}
                            />
                        </ListItem>
                    ))}
                </List>
            </ConditionalWrapper>
            {collapse && (
                <Box
                    display="flex"
                    justifyContent="center"
                    onClick={() => {
                        setExpanded(!expanded);
                    }}
                    className={classes.expandBar}
                >
                    {expanded ? (
                        <IconButton size="small">
                            <ExpandLessOutlined />
                        </IconButton>
                    ) : (
                        <Typography variant="body2" className={classes.expandLabel}>
                            {`${attachments.length} ${
                                attachments.length === 1 ? 'Anhang' : 'Anhänge'
                            }`}
                        </Typography>
                    )}
                </Box>
            )}
        </CloudStatusCheck>
    );
};

FileList.propTypes = {
    attachments: PropTypes.arrayOf(FilePropType).isRequired,
    blocked: PropTypes.bool,
    cloudRoot: PropTypes.string,
    showDirectory: PropTypes.func,
    expanded: PropTypes.bool,
};

FileList.defaultProps = {
    blocked: false,
    cloudRoot: null,
    showDirectory: null,
    expanded: false,
};

export default FileList;
