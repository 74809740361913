import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showResource } from '../../modules/resources/actions';
import {
    selectResourceClassesInitialized,
    selectResourceClassesLoading,
} from '../../modules/resourceClasses/selectors';
import { indexResourceClasses } from '../../modules/resourceClasses/actions';
import {
    selectResourceTypesInitialized,
    selectResourceTypesLoading,
} from '../../modules/resourceTypes/selectors';
import { indexResourceTypes } from '../../modules/resourceTypes/actions';
import { IdPropType } from '../../modules/proptypes';
import { indexGroups } from '../../modules/groups/actions';

const ResourceDataLoader = ({ resourceId, cloneResourceId }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (resourceId) {
            dispatch(showResource({ id: resourceId }));
        }
    }, [resourceId, dispatch]);

    useEffect(() => {
        if (cloneResourceId) {
            dispatch(showResource({ id: cloneResourceId }));
        }
    }, [cloneResourceId, dispatch]);

    /* load resource classes once */
    const resourceClassesLoading = useSelector(selectResourceClassesLoading);
    const resourceClassesInitialized = useSelector(selectResourceClassesInitialized);
    useEffect(() => {
        if (!resourceClassesLoading && !resourceClassesInitialized) {
            dispatch(indexResourceClasses({ limit: 250 }));
        }
    }, [dispatch, resourceClassesLoading, resourceClassesInitialized]);

    /* load resource types once */
    const resourceTypesLoading = useSelector(selectResourceTypesLoading);
    const resourceTypesInitialized = useSelector(selectResourceTypesInitialized);
    useEffect(() => {
        if (!resourceTypesLoading && !resourceTypesInitialized) {
            dispatch(indexResourceTypes({ limit: 250 }));
        }
    }, [dispatch, resourceTypesLoading, resourceTypesInitialized]);

    /* load groups */
    useEffect(() => {
        dispatch(indexGroups({ limit: 250 }));
    }, [dispatch]);

    return null;
};

ResourceDataLoader.propTypes = {
    resourceId: IdPropType,
    cloneResourceId: IdPropType,
};

ResourceDataLoader.defaultProps = {
    resourceId: null,
    cloneResourceId: null,
};

export default ResourceDataLoader;
