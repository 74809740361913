export const TEXT_COMPONENT = 'text';
export const TEXT_WITH_LABEL_COMPONENT = 'textWithLabel';
export const TITLE_COMPONENT = 'title';

export const PERSONS_FILTERED_BY_TYPE_COMPONENT = 'persons_filtered_by_type';
export const PERSONS_FILTERED_BY_TYPE_SIMPLE_COMPONENT = 'persons_filtered_by_type_simple';

export const LOCATION_COMPONENT = 'location';
export const MAP_COMPONENT = 'map';
export const LOCATION_SUGGESTION_COMPONENT = 'location_suggestion';

export const PROTOCOL_ITEMS_FILTERED_BY_TYPE_COMPONENT = 'protocol_items_filtered_by_type';

export const PROTOCOL_ITEMS_FILTERED_BY_STATUS_COMPONENT = 'protocol_items_filtered_by_status';

export const EMAIL_VIEW_COMPONENT = 'email_view';
