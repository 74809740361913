import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { makeStyles } from '@mui/styles';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import enLocale from 'date-fns/locale/en-US';
import deLocale from 'date-fns/locale/de';
import { formatISO, isValid } from 'date-fns';

import { getFormFieldError } from '../../../modules/loading/utils';
import { ErrorPropType } from '../../../modules/loading/proptypes';
import Warning from '../Warning';
import { useContextualCan } from '../../../modules/abilities/hooks';
import ContextualCan from '../../../modules/abilities/ContextualCan';
import { READ, WRITE } from '../../../modules/abilities/actions';
import { importDate, PATTERN } from '../../../modules/datetime/utils';

const useStyles = makeStyles({
    input: {
        margin: 0,
        width: 156,
    },

    fullWidth: {
        width: '100%',
    },
});

const localeMap = {
    en: enLocale,
    de: deLocale,
};

const DateInput = ({
    name,
    originalName,
    label,
    convertUTC,
    onChange,
    errors,
    warnings,
    fullWidth,
    disabled,
    can,
    slotProps,
    ...other
}) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [field, meta, helpers] = useField(name);
    const error = getFormFieldError(name, errors, meta);
    const [locale, setLocale] = useState('de');
    const canDo = useContextualCan(can ? can : WRITE, originalName || name);

    useEffect(() => {
        setLocale(i18n.language.slice(0, 2));
    }, [i18n]);

    return (
        <ContextualCan I={READ} field={originalName || name}>
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={localeMap[locale] || 'en'}
            >
                <DatePicker
                    {...field}
                    label={label}
                    value={typeof field.value === 'string' ? importDate(field.value) : field.value}
                    slotProps={{
                        textField: {
                            size: 'small',
                            variant: 'outlined',
                            error: !!error,
                            helperText: error && t(`errors.${error.split('.').join('')}`),
                            onBlur: () => helpers.setTouched(true, true),
                        },
                        ...slotProps,
                    }}
                    onChange={date => {
                        // TODO: figure out the best way to handle time zone stuff
                        //       just use the time zoned date for now

                        // QUICKFIX -> sort this stuff out o.o
                        date?.setHours(0, 0, 0, 0);
                        if (convertUTC) {
                            const converted = isValid(date) ? formatISO(date).slice(0, -6) : date;
                            helpers.setValue(converted);
                        } else {
                            helpers.setValue(date);
                        }

                        if (onChange) {
                            onChange(date);
                        }
                    }}
                    onError={err => {
                        if (err !== meta.error && err) {
                            helpers.setError(err);
                        }
                    }}
                    format={PATTERN.localDate}
                    className={`${classes.input} ${fullWidth ? classes.fullWidth : ''}`}
                    disabled={disabled || !canDo}
                    {...other}
                />
                <Warning warnings={warnings} />
            </LocalizationProvider>
        </ContextualCan>
    );
};

DateInput.propTypes = {
    name: PropTypes.string.isRequired,
    originalName: PropTypes.string,
    label: PropTypes.string,
    convertUTC: PropTypes.bool,
    onChange: PropTypes.func,
    errors: ErrorPropType,
    warnings: PropTypes.arrayOf(PropTypes.string),
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    can: PropTypes.string,
    slotProps: PropTypes.shape({}),
};

DateInput.defaultProps = {
    originalName: null,
    label: null,
    convertUTC: false,
    onChange: null,
    errors: null,
    fullWidth: false,
    disabled: false,
    can: null,
    slotProps: {},
};

export default DateInput;
