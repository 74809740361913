import * as Yup from 'yup';

export const employeeInternalDataSchema = Yup.object().shape({
    username: Yup.string().trim().required('required'),
    email: Yup.string().email('invalidEmailFormat').required('required'),
    display_name_short: Yup.string().nullable(),
    name_short: Yup.string().nullable(),
    deactivated_at: Yup.date().nullable(),
    color: Yup.string().max(7).nullable(),
    roles: Yup.array().required('required'),
});
