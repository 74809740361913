import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useTranslation } from 'react-i18next';
import IconButton from '../form/base/IconButton';
import { exportCSV, formatExportColumns } from '../../modules/resourceTable/export';

const ResourceTableExport = ({ columns, rows, label, disabled: overrideDisabled, ...other }) => {
    const { t } = useTranslation();

    const disabled = useMemo(
        () =>
            overrideDisabled ||
            !(
                columns &&
                Array.isArray(columns) &&
                columns.length &&
                rows &&
                Array.isArray(rows) &&
                rows.length
            ),
        [overrideDisabled, columns, rows]
    );

    const handleExport = () => {
        const headers = formatExportColumns(columns);
        exportCSV(headers, rows, label, true);
    };

    return (
        <IconButton
            onClick={handleExport}
            label={t('components.ResourceTable.Export.button')}
            disabled={disabled}
            {...other}
        >
            <FileDownloadOutlinedIcon />
        </IconButton>
    );
};

ResourceTableExport.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({})),
    rows: PropTypes.arrayOf(PropTypes.shape({})),
    label: PropTypes.string,
    disabled: PropTypes.bool,
};

ResourceTableExport.defaultProps = {
    columns: null,
    rows: null,
    label: null,
    disabled: false,
};

export default ResourceTableExport;
