import React from 'react';
import * as PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

const Text = ({ value, color, className }) => (
    <Typography variant="body2" color={color || undefined} className={className}>
        {value}
    </Typography>
);

Text.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    color: PropTypes.string,
    className: PropTypes.string,
};

Text.defaultProps = {
    color: undefined,
    className: null,
};

export default Text;
