import React from 'react';
import { Container } from '@mui/material';
import { useFormikContext } from 'formik';
import { secondsFromString } from './util';

export default function TotalPerDay({ name, day, shifts }) {
    const { values } = useFormikContext();

    const total =
        Math.round(
            shifts.reduce((carry, val) => {
                const start = values[name][`${day.id}_${val.id}_start`];
                const end = values[name][`${day.id}_${val.id}_end`];

                if (!start || !end) {
                    return carry;
                }

                return carry + (secondsFromString(end) - secondsFromString(start)) / 3600;
            }, 0) * 100
        ) / 100;

    return <Container>{total.toLocaleString('de-DE')}h</Container>;
}
