import * as Yup from 'yup';

export const addressSchema = Yup.object().shape({
    address_type_id: Yup.number().nullable(),
    address1: Yup.string().nullable(),
    address2: Yup.string().nullable(),
    zip: Yup.string().nullable(),
    city: Yup.string().nullable(),
    state: Yup.string().nullable(),
    country: Yup.string().nullable(),
});
