import React, { memo, useState } from 'react';
import * as PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { CircularProgress } from '@mui/material';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import Icon from '../icons/Icon';
import Avatar from '../icons/Avatar';
import { formatLocalDateTime } from '../../modules/datetime/utils';
import { protocolItemPath } from '../../routes/paths';
import Avatars from '../icons/Avatars';
import Can from '../../modules/abilities/Can';
import { UPDATE } from '../../modules/abilities/actions';
import Pin from '../form/Pin';
import { selectProtocolItemTypeById } from '../../modules/protocolItemTypes/selectors';
import { selectUserById } from '../../modules/users/selectors';
import { updateProtocolItem } from '../../modules/protocolItems/actions';
import { selectProtocolItemById } from '../../modules/protocolItems/selectors';
import { IdPropType } from '../../modules/proptypes';
import usePrint from '../utils/usePrint';
import ProtocolItemPrintView from './ProtocolItemPrintView';
import CopyLinkButton from '../buttons/CopyLinkButton';
import IconButton from '../form/base/IconButton';
import NavigateIconButton from '../buttons/NavigateIconButton';
import PrintIconButton from '../buttons/PrintIconButton';
import { PROTOCOL_ITEM_RESOURCE } from '../../modules/api/resources';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(theme => ({
    type: {
        color: theme.palette.primary.main,
        position: 'relative',
        top: 2,
    },

    edit: {
        marginRight: -theme.spacing(1),
        // marginLeft: -theme.spacing(1),
    },
}));

const ProtocolToolbar = ({ id, minimal, actions, readOnly, onEdit, enableNavigate }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();

    const protocolItem = useSelector(state => selectProtocolItemById(state, id));
    const protocolItemType = useSelector(state =>
        selectProtocolItemTypeById(state, protocolItem.protocol_item_type_id)
    );
    const user = useSelector(state => selectUserById(state, protocolItem.user_id));

    const handlePinned = (event, pinned) => dispatch(updateProtocolItem({ id, pinned }));

    const handleDeleteResponsible = deleteId => {
        dispatch(
            updateProtocolItem({
                id: protocolItem.id,
                responsibles: protocolItem.responsibles.filter(userId => userId !== deleteId),
            })
        );
    };

    return (
        <Grid container spacing={1} alignItems="center">
            <Grid item>
                <Tooltip title={protocolItemType ? protocolItemType.name : null} disableInteractive>
                    {/* Tooltip braucht ein Element, auf das ein Ref gesetzt werden kann */}
                    <Box>
                        <Icon
                            type={protocolItemType ? protocolItemType.icon : null}
                            className={classes.type}
                        />
                    </Box>
                </Tooltip>
            </Grid>
            <Grid item>
                <Avatar
                    user={user}
                    name
                    small
                    inline
                    disableHover
                    label={t('components.Avatar.author')}
                />
            </Grid>
            <Grid item>
                <Typography variant="subtitle1" component="p" color="textSecondary">
                    {formatLocalDateTime(protocolItem.date)}
                </Typography>
            </Grid>
            <Grid item style={{ marginTop: 6 }}>
                <CopyLinkButton
                    to={protocolItemPath(protocolItem.card_id, protocolItem.id)}
                    label={t('components.CopyLinkButton.copy')}
                />
            </Grid>
            <Grid item xs />
            <Grid item>
                <Avatars
                    userIds={protocolItem.responsibles}
                    onDelete={handleDeleteResponsible}
                    small
                    showSingleTooltip
                />
            </Grid>
            {enableNavigate && (
                <Grid item>
                    <NavigateIconButton path={protocolItemPath(protocolItem.card_id, id)} />
                </Grid>
            )}
            {!readOnly && onEdit && (
                <Grid item className={classes.edit} data-test-id="btn-editProtocolItem">
                    <IconButton
                        action={UPDATE}
                        subject={protocolItem}
                        onClick={() => onEdit(id)}
                        label={t('components.ProtocolItem.edit')}
                    >
                        <EditOutlinedIcon />
                    </IconButton>
                </Grid>
            )}
            {actions}
            {!minimal && (
                <>
                    <Can I={UPDATE} this={protocolItem} field="pinned">
                        <Grid item data-test-id="btn-pinProtocolItem">
                            <Pin
                                onChange={handlePinned}
                                pinned={protocolItem.pinned}
                                labelPin={t('components.ProtocolItem.pin')}
                                labelUnpin={t('components.ProtocolItem.unpin')}
                                confirm
                                label={t(
                                    `components.ProtocolItem.${
                                        protocolItem.pinned ? 'unpinTooltip' : 'pinTooltip'
                                    }`
                                )}
                            />
                        </Grid>
                    </Can>
                    <Grid item>
                        <PrintIconButton subject={PROTOCOL_ITEM_RESOURCE}>
                            <ProtocolItemPrintView id={protocolItem.id} />
                        </PrintIconButton>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

ProtocolToolbar.propTypes = {
    id: IdPropType.isRequired,
    minimal: PropTypes.bool,
    actions: PropTypes.arrayOf(PropTypes.node),
    readOnly: PropTypes.bool,
    onEdit: PropTypes.func,
    enableNavigate: PropTypes.bool,
};

ProtocolToolbar.defaultProps = {
    minimal: false,
    actions: [],
    readOnly: false,
    onEdit: null,
    enableNavigate: false,
};

export default memo(ProtocolToolbar);
