import { CONTACT_TYPE_RESOURCE } from '../api/resources';

export const selectContactTypesInitialized = state => state[CONTACT_TYPE_RESOURCE].initialized;

export const selectContactTypesById = state => state[CONTACT_TYPE_RESOURCE].byId;

export const selectContactTypeById = (state, id) => selectContactTypesById(state)[id];

export const selectContactTypesByIds = (state, ids) => {
    return ids
        .filter((id, index) => ids.indexOf(id) === index)
        .map(id => selectContactTypeById(state, id));
};

export const selectAllContactTypeIds = state => state[CONTACT_TYPE_RESOURCE].allIds;
