import React from 'react';
import { useSelector } from 'react-redux';
import Markdown from '../utils/Markdown';
import { formatLocalDateTime } from '../../modules/datetime/utils';
import { selectCardById } from '../../modules/cards/selectors';
import { selectProtocolItemById } from '../../modules/protocolItems/selectors';
import { selectProtocolItemTypeById } from '../../modules/protocolItemTypes/selectors';
import { selectUserById } from '../../modules/users/selectors';
import { IdPropType } from '../../modules/proptypes';

const getIdentifier = card => {
    if (!card || (!card.id_order && !card.id_offer)) {
        return 'Nicht definiert';
    }

    return `${card.id_order || ''}${card.id_order && card.id_offer ? ' / ' : ''}${card.id_offer ||
        ''}`;
};

const ProtocolItemPrintView = ({ id }) => {
    const protocolItem = useSelector(state => selectProtocolItemById(state, id));
    const protocolItemType = useSelector(state =>
        selectProtocolItemTypeById(state, protocolItem.protocol_item_type_id)
    );
    const user = useSelector(state => selectUserById(state, protocolItem.user_id));
    const card = useSelector(state => selectCardById(state, protocolItem.card_id));

    return (
        <div className="protocolItem">
            <h1>Protokolleintrag ({protocolItemType.name})</h1>
            <hr />
            <p className="protocolItem-meta">Erstellt von: {user.display_name}</p>
            <p className="protocolItem-meta">
                Erstellt am: {formatLocalDateTime(protocolItem.date)}
            </p>
            <p className="protocolItem-meta">Angebots- / Auftragsnummer: {getIdentifier(card)}</p>
            <hr />
            <Markdown content={protocolItem.text} className="protocolItem-text" />
        </div>
    );
};

ProtocolItemPrintView.propTypes = {
    id: IdPropType.isRequired,
};

export default ProtocolItemPrintView;
