import * as PropTypes from 'prop-types';
import { useFieldFast } from '../../../modules/form/hooks';
import { useMemo } from 'react';

const WatchContainer = ({ name, extraProps, WatchComponent }) => {
    const [{ value }] = useFieldFast(name);

    return useMemo(
        () => <WatchComponent value={value} {...extraProps} />,
        [WatchComponent, value, extraProps]
    );
};

WatchContainer.propTypes = {
    name: PropTypes.string.isRequired,
    extraProps: PropTypes.shape({}),
    WatchComponent: PropTypes.elementType.isRequired,
};

WatchContainer.defaultProps = {
    extraProps: {},
};

export default WatchContainer;
