import * as PropTypes from 'prop-types';

export const EmailContactPropType = PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
});

export const EmailReceiverPropType = PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    type: PropTypes.string,
    contact_data_id: PropTypes.string,
});

export const EmailPropType = PropTypes.shape({
    receivers: PropTypes.arrayOf(EmailReceiverPropType),
    is_inbound: PropTypes.bool,
    received_at: PropTypes.instanceOf(Date),
    subject: PropTypes.string,
    body: PropTypes.string,
    preview: PropTypes.string,
    remain: PropTypes.string,
});
