import React, { useCallback, useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { bindPopper, usePopupState } from 'material-ui-popup-state/hooks';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Button from './base/Button';
import { RefPropType } from '../../modules/proptypes';
import Badge from '@mui/material/Badge';

const useStyles = makeStyles(theme => ({
    listener: {
        display: 'inline-block',
    },

    container: {
        paddingBottom: theme.spacing(1.5),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(1.5),

        zIndex: 2,
        position: 'relative',
    },

    buttons: {
        paddingTop: 4,
    },

    button: {
        maxWidth: 80,
    },

    alignRight: {
        textAlign: 'right',
    },

    popper: {
        zIndex: 1301, // https://github.com/mui-org/material-ui/issues/18905

        '&[x-placement*="bottom"] $arrow': {
            top: 0,
            left: 0,
        },
        '&[x-placement*="top"] $arrow': {
            bottom: 0,
            left: 0,
            transform: 'rotate(180deg)',
        },
        '&[x-placement*="left"] $arrow': {
            right: -3,
            transform: 'rotate(90deg)',
        },
        '&[x-placement*="right"] $arrow': {
            left: -1,
            transform: 'rotate(-90deg)',
        },
    },

    arrow: {
        width: 10,
        position: 'absolute',
        marginLeft: -2,

        '&:after': {
            marginLeft: -8,

            zIndex: 1,
            content: '""',
            left: '50%',
            top: 3,
            width: 15,
            height: 15,
            position: 'absolute',
            backgroundColor: 'white',
            boxShadow: '12px 0 0 0 white, 0px 12px 0px 0 white, 0 -2px 10px 0 rgba(0,0,0,0.07)',
            transform: 'rotate(45deg)',
            borderLeft: '1px solid #E6E6E6',
            borderTop: '1px solid #E6E6E6',
        },
    },

    hoverInner: {
        // space for the arrow
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 12,
        marginRight: 12,

        border: '1px solid #E6E6E6',
        boxShadow: '0 2px 10px 0 rgba(0,0,0,0.07)',
    },
}));

const Confirmation = ({
    label,
    icon,
    popupStateRef,
    popupId,
    placement,
    onConfirm,
    onAbort,
    children,
    disabled,
    force,
    color,
    badge,
    ...other
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [arrowElement, setArrowElement] = useState(null);
    const popupState = usePopupState({
        variant: 'popover',
        popupId,
    });

    useEffect(() => {
        if (popupStateRef) {
            // eslint-disable-next-line no-param-reassign
            popupStateRef.current = popupState;
        }
    }, [popupStateRef, popupState]);

    const handleClick = useCallback(
        event => {
            if (force) {
                onConfirm();
            } else if (!disabled) {
                popupState.open(event);
            }
        },
        [popupState, disabled, force, onConfirm]
    );

    const handleConfirm = useCallback(() => {
        popupState.close();
        if (onConfirm) {
            onConfirm();
        }
    }, [popupState, onConfirm]);

    const handleAbort = useCallback(() => {
        popupState.close();
        if (onAbort) {
            onAbort();
        }
    }, [popupState, onAbort]);

    return (
        <>
            <Box onClick={handleClick} className={classes.listener} {...other}>
                {children}
            </Box>
            <Popper
                className={classes.popper}
                {...bindPopper(popupState)}
                placement={placement}
                modifiers={[
                    {
                        name: 'flip',
                        enabled: true,
                    },
                    {
                        name: 'preventOverflow',
                        enabled: true,
                        options: {
                            boundariesElement: 'scrollParent',
                            padding: 5,
                        },
                    },
                    {
                        name: 'arrow',
                        enabled: true,
                        options: {
                            element: arrowElement,
                        },
                    },
                ]}
            >
                <span className={classes.arrow} ref={setArrowElement} />
                <Paper className={classes.hoverInner}>
                    <ClickAwayListener onClickAway={handleAbort}>
                        <Box className={classes.container}>
                            <Grid container spacing={1} alignItems="center">
                                {icon && <Grid item>{icon}</Grid>}
                                {label && (
                                    <Grid item xs>
                                        <Typography
                                            variant="body2"
                                            color={color === 'danger' ? 'error' : 'textPrimary'}
                                            align="center"
                                        >
                                            {label}
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                            <Grid container spacing={1} className={classes.buttons}>
                                <Grid item xs={6} className={classes.alignRight}>
                                    <Button
                                        className={classes.button}
                                        size="small"
                                        variant="outlined"
                                        onClick={handleAbort}
                                        fullWidth
                                    >
                                        {t('no')}
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Badge invisible={!badge} badgeContent={badge} color="error">
                                        <Button
                                            data-test-id="confirm-btn"
                                            className={classes.button}
                                            size="small"
                                            variant="contained"
                                            color={color === 'warning' ? 'warning' : 'primary'}
                                            onClick={handleConfirm}
                                            fullWidth
                                        >
                                            {t('yes')}
                                        </Button>
                                    </Badge>
                                </Grid>
                            </Grid>
                        </Box>
                    </ClickAwayListener>
                </Paper>
            </Popper>
        </>
    );
};

Confirmation.propTypes = {
    popupId: PropTypes.string.isRequired,
    label: PropTypes.string,
    icon: PropTypes.node,
    popupStateRef: RefPropType,
    onConfirm: PropTypes.func,
    onAbort: PropTypes.func,
    children: PropTypes.node,
    placement: PropTypes.string,
    disabled: PropTypes.bool,
    force: PropTypes.bool,
    color: PropTypes.string,
    badge: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Confirmation.defaultProps = {
    label: null,
    icon: null,
    placement: 'bottom',
    onConfirm: null,
    onAbort: null,
    popupStateRef: null,
    children: null,
    disabled: false,
    force: false,
    color: 'default',
    badge: null,
};

export default Confirmation;
