import * as Yup from 'yup';
import { YupId } from '../form/schema';

export const categorySchema = Yup.object().shape({
    id: YupId().nullable(),
    name: Yup.string().trim().required('required'),
    color: Yup.string().nullable(),
    icon: Yup.string().nullable(),
    order: Yup.number().nullable(),
    active: Yup.boolean(),
});
