import React, { useEffect, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { IdPropType } from '../../../modules/proptypes';
import { useTaskLoad } from '../../../modules/tasks/hooks';
import { CALENDAR_RESOURCE_MODELS } from '../../../modules/calendar/utils';
import { TASK_RESOURCE } from '../../../modules/api/resources';
import { useField } from 'formik';
import Select from '../../form/formik/Select';

const MAX_CHARS = 65;

const CalendarTaskSelect = ({ name, cardId, params }) => {
    const { t } = useTranslation();
    const [, , { setValue: setEntityType }] = useField(`${name}Type`);

    const { loading, initialized, tasks } = useTaskLoad({
        referenceId: { key: 'card_id', value: cardId },
        fetchParams: params,
        listIdKey: 'unfinished',
        autoPaginate: true,
    });

    const taskOptions = useMemo(
        () =>
            tasks.map(task => ({
                value: task.id,
                label:
                    task.name.length > MAX_CHARS
                        ? `${task.name.substring(0, MAX_CHARS)}…`
                        : task.name,
            })),
        [tasks]
    );

    useEffect(() => {
        setEntityType(CALENDAR_RESOURCE_MODELS[TASK_RESOURCE]);
    }, []);

    return (
        <Select
            name={`${name}Id`}
            originalName={`${name}_id`}
            label={t('components.CalendarTaskAutocomplete.title')}
            disabled={loading}
            options={initialized && !loading ? taskOptions : []}
            fullWidth
        />
    );
};

CalendarTaskSelect.propTypes = {
    name: PropTypes.string.isRequired,
    cardId: IdPropType.isRequired,
};

export default CalendarTaskSelect;
