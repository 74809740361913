import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { IdPropType } from '../../modules/proptypes';
import { selectTaskTypeById } from '../../modules/taskTypes/selectors';

const TaskType = ({ typeId, text }) => {
    const taskType = useSelector(state => selectTaskTypeById(state, typeId));

    return text || (taskType && taskType.name) || '';
};

TaskType.propTypes = {
    typeId: IdPropType,
    text: PropTypes.string,
};

export default TaskType;
