import React, { useEffect, useRef } from 'react';
import { node } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { indexSettings } from '../../modules/settings/actions';
import { indexUsers } from '../../modules/users/actions';
import { indexCategories } from '../../modules/categories/actions';
import { indexContactTypes } from '../../modules/contactTypes/actions';
import {
    CARD_DETAILS_HEADER_CONTEXT,
    CARD_LINKED_DETAILS_CONTEXT,
} from '../../modules/metadata/context';
import { selectUser } from '../../modules/auth/selectors';

const InitialDataLoader = ({ children }) => {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);

    const lastUserId = useRef(null);

    useEffect(() => {
        dispatch(indexSettings({ limit: 250 }));
    }, [dispatch]);

    useEffect(() => {
        if (user && lastUserId.current !== user.id) {
            // TODO: what happens if the company has more than 250 users?
            dispatch(indexSettings({ limit: 250 }));
            dispatch(indexUsers({ limit: 250 }));
            dispatch(indexCategories({ limit: 250 }));
            dispatch(
                indexContactTypes({
                    limit: 250,
                    contexts: [CARD_DETAILS_HEADER_CONTEXT, CARD_LINKED_DETAILS_CONTEXT],
                })
            );
            lastUserId.current = user.id;
        }

        if (!user) {
            lastUserId.current = null;
        }
    }, [dispatch, user]);

    return <>{children}</>;
};

InitialDataLoader.propTypes = {
    children: node.isRequired,
};

export default InitialDataLoader;
