import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Chip from '@mui/material/Chip';
import Icon from '@mui/material/Icon';
import { useTheme } from '@mui/styles';

import { IdPropType } from '../../modules/proptypes';
import { selectCategoryById } from '../../modules/categories/selectors';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';

const tinycolor = require('tinycolor2');

const CategoryDisplay = ({ categoryId, style }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const category = useSelector(state => selectCategoryById(state, categoryId));

    return categoryId && category ? (
        <Tooltip title={t('components.Card.category')} disableInteractive>
            <Chip
                label={category.name}
                style={{
                    opacity: !category?.active ? 0.3 : 1,
                    backgroundColor: tinycolor(category.color).setAlpha(0.2).toRgbString(),
                    border: `1px solid ${category.color}`,
                    height: theme.spacing(2),
                    fontSize: '0.75rem',
                    ...style,
                }}
                icon={
                    category.icon ? (
                        <Icon style={{ fontSize: '0.85rem' }}>{category.icon}</Icon>
                    ) : null
                }
            />
        </Tooltip>
    ) : (
        <Chip
            label={t('form.CategorySelect.empty')}
            style={{
                backgroundColor: tinycolor(theme.palette.grey[500]).setAlpha(0.2).toRgbString(),
                border: `1px solid ${theme.palette.grey[500]}`,
                height: theme.spacing(2),
                fontSite: '0.75rem',
                ...style,
            }}
            icon={<Icon style={{ fontSize: '0.85rem' }}>{'blocked'}</Icon>}
        />
    );
};

CategoryDisplay.propTypes = {
    categoryId: IdPropType,
    style: PropTypes.shape({}),
};

CategoryDisplay.defaultProps = {
    categoryId: null,
    style: {},
};

export default CategoryDisplay;
