import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Confirmation from '../form/Confirmation';
import IconButton from '../form/base/IconButton';
import { ConditionalWrapper } from '../utils/ConditionalWrapper';

const CloseIconButton = ({ confirm, onClick, size, contrast, confirmLabel, disabled, color }) => {
    const { t } = useTranslation();

    return (
        <ConditionalWrapper
            condition={confirm}
            wrapper={children => (
                <Confirmation
                    popupId="CloseIconButton"
                    label={confirmLabel || t('components.CloseButton.closeIntent')}
                    onConfirm={onClick}
                >
                    {children}
                </Confirmation>
            )}
        >
            <IconButton
                onClick={confirm ? null : onClick}
                size={size}
                contrast={contrast}
                disabled={disabled}
                data-test-id="CloseIconButton"
                color={color}
            >
                <CloseOutlinedIcon fontSize="small" />
            </IconButton>
        </ConditionalWrapper>
    );
};

CloseIconButton.propTypes = {
    confirm: PropTypes.bool,
    confirmLabel: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    size: PropTypes.string,
    contrast: PropTypes.bool,
    disabled: PropTypes.bool,
    color: PropTypes.string,
};

CloseIconButton.defaultProps = {
    confirm: false,
    confirmLabel: null,
    size: null,
    contrast: false,
    disabled: false,
    color: 'default',
};

export default CloseIconButton;
