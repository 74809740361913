import React from 'react';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { Divider } from '@mui/material';
import { selectContactById } from '../../modules/contacts/selectors';
import CompanyPreview from '../company/CompanyPreview';
import PersonPreview from '../person/PersonPreview';
import { IdPropType } from '../../modules/proptypes';

const useStyles = makeStyles(theme => ({
    fullHeight: {
        minHeight: '100%',
    },
    dividerContainer: {
        position: 'relative',
    },
    verticalDivider: {
        top: theme.spacing(3),
        bottom: theme.spacing(3),
        position: 'absolute',
        height: '100%',
        width: 1,
        left: 0,
    },
}));

const PersonContact = ({ contactId }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const contact = useSelector(state => selectContactById(state, contactId));

    return (
        <Grid container spacing={6} className={classes.fullHeight}>
            <Grid item xs={4}>
                <Box marginTop={1} marginBottom={2}>
                    <Typography variant="h2" color="primary">
                        {t('components.ContactBaseData.company')}
                    </Typography>
                </Box>
                {contact && <CompanyPreview contactId={contact.parent_id} link />}
            </Grid>
            <Grid item xs={8} className={classes.dividerContainer}>
                <Divider className={classes.verticalDivider} />
                <Box marginTop={1} marginBottom={2}>
                    <Typography variant="h2" color="primary">
                        {t('components.ContactBaseData.person')}
                    </Typography>
                </Box>
                <PersonPreview contactId={contactId} />
            </Grid>
        </Grid>
    );
};

PersonContact.propTypes = {
    contactId: IdPropType,
};

PersonContact.defaultProps = {
    contactId: null,
};

export default PersonContact;
