import React from 'react';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import PermContactCalendarOutlined from '@mui/icons-material/PermContactCalendarOutlined';
import { makeStyles } from '@mui/styles';
import * as PropTypes from 'prop-types';
import Contact from '../contacts/Contact';
import { IdPropType } from '../../modules/proptypes';
import { selectTypeGroupedContactablesByCardId } from '../../modules/contactables/selectors';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    icon: {
        opacity: 0.5,
        height: 32,
    },
});

const PersonsFilteredByType = ({ cardId, itemId, variant, simple }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const contactables = useSelector(state => selectTypeGroupedContactablesByCardId(state, cardId));

    const filtered = contactables.filter(contactable =>
        Array.isArray(itemId)
            ? itemId.reduce(
                  (carry, _itemId) => carry || contactable.contactTypeIds.includes(_itemId),
                  false
              )
            : contactable.contactTypeIds.includes(itemId)
    );

    return filtered.length > 0 ? (
        <Grid container alignItems="center" spacing={1}>
            {!simple && (
                <Grid item className={classes.icon}>
                    <PermContactCalendarOutlined />
                </Grid>
            )}
            <Tooltip title={t('components.Card.contacts')} disableInteractive>
                <Grid item>
                    {filtered.map(contactable => (
                        <Contact
                            key={contactable.id}
                            contactId={contactable.contact_id}
                            typeIds={contactable.contactTypeIds.filter(typeId =>
                                Array.isArray(itemId) ? itemId.includes(typeId) : typeId === itemId
                            )}
                            variant={variant}
                            noTypeIcon={simple}
                        />
                    ))}
                </Grid>
            </Tooltip>
        </Grid>
    ) : null;
};

PersonsFilteredByType.propTypes = {
    cardId: IdPropType,
    itemId: PropTypes.oneOfType([IdPropType, PropTypes.arrayOf(IdPropType)]).isRequired,
    variant: PropTypes.string,
    simple: PropTypes.bool,
};

PersonsFilteredByType.defaultProps = {
    cardId: null,
    variant: undefined,
    simple: false,
};

export default PersonsFilteredByType;
