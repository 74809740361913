import React, { useCallback, useState } from 'react';
import * as PropTypes from 'prop-types';
import TypedAutocomplete from '../../base/autocomplete/TypedAutocomplete';
import { autocompleteContacts } from '../../../../modules/contacts/actions';

const CompanyContactsAutocomplete = ({
    name,
    label,
    variant,
    onChange,
    onInputChange,
    contrast,
    fullWidth,
    size,
    className,
}) => {
    const [value, setValue] = useState(null);

    const handleChange = newValue => {
        setValue(newValue);
        onChange(newValue);
    };

    const fetch = useCallback(
        params =>
            autocompleteContacts({
                ...params,
                // must be a string - otherwise it will be filtered by axios
                // https://github.com/axios/axios/pull/1987
                person: 'null',
            }),
        []
    );

    return (
        <TypedAutocomplete
            name={name}
            fetchAction={fetch}
            value={value}
            label={label}
            onChange={handleChange}
            onInputChange={onInputChange}
            isOptionEqualToValue={(option, _value) => _value && option.id === _value.id}
            getOptionLabel={option => (typeof option === 'string' ? option : option.label || '')}
            clearOnBlur
            blurOnSelect
            variant={variant}
            contrast={contrast}
            size={size}
            fullWidth={fullWidth}
            className={className}
            popupSize="small"
            hideTypes
        />
    );
};

CompanyContactsAutocomplete.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    onInputChange: PropTypes.func,
    variant: PropTypes.string,
    contrast: PropTypes.bool,
    size: PropTypes.string,
    fullWidth: PropTypes.bool,
    className: PropTypes.string,
};

CompanyContactsAutocomplete.defaultProps = {
    name: null,
    label: null,
    onChange: () => null,
    onInputChange: () => null,
    variant: 'outlined',
    contrast: false,
    size: 'small',
    fullWidth: false,
    className: null,
};

export default CompanyContactsAutocomplete;
