import { put, all, select, takeEvery } from 'redux-saga/effects';
import { CONTACTABLE_RESOURCE } from '../api/resources';
import { selectAllContactIds } from '../contacts/selectors';
import { indexContacts } from '../contacts/actions';

function* nestedIndexFulfilled(action) {
    const contacts = yield select(selectAllContactIds);

    if (action.meta && action.meta.socketReaction) {
        yield all(
            action.payload
                .filter(({ contact_id: contactId }) => !contacts.includes(contactId))
                .map(({ contact_id: contactId }) => put(indexContacts({ id: contactId })))
        );
    }
}

export default [takeEvery(`${CONTACTABLE_RESOURCE}/indexFulfilled`, nestedIndexFulfilled)];
