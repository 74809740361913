import PropTypes from 'prop-types';
import { IdPropType } from '../proptypes';

export const TaskPropType = PropTypes.shape({
    id: IdPropType,
    card_id: IdPropType,
    name: PropTypes.string,
    category_id: IdPropType,
    task_type_id: IdPropType,
    task_type_text: PropTypes.string,
    completed_at: PropTypes.string,
    priority_id: IdPropType,
});
