import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectUserIsRoot } from '../auth/selectors';
import { CASLCan } from './AbilityProvider';

const Can = ({ children, I, this: on, field }) => {
    const isRoot = useSelector(selectUserIsRoot);

    return isRoot ? (
        children
    ) : (
        <CASLCan do={I} on={on} field={field}>
            {children}
        </CASLCan>
    );
};

Can.propTypes = {
    children: PropTypes.node,
    I: PropTypes.string.isRequired,
    this: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
    field: PropTypes.string,
};

Can.defaultProps = {
    children: null,
    this: null,
    field: null,
};

export default Can;
