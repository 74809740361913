import React from 'react';
import * as PropTypes from 'prop-types';
import { RichUtils } from 'draft-js';
import Grid from '@mui/material/Grid';
import MentionOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import BoldOutlinedIcon from '@mui/icons-material/FormatBoldOutlined';
import ItalicOutlinedIcon from '@mui/icons-material/FormatItalicOutlined';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import BulletListOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import NumberListOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined';
import StrikeOutlinedIcon from '@mui/icons-material/FormatStrikethroughOutlined';
import IconButton from '../../../base/IconButton';
import { makeStyles } from '@mui/styles';
import ToolbarLink from './ToolbarLink';
import { EditorStatePropType, EditorReferencePropType } from '../../../../../modules/proptypes';
import { hasSelectionRange, iconColor } from '../../../../../modules/editor/utils';
import { activateMention } from '../../../../../modules/editor/mention';
import { activateTag } from '../../../../../modules/editor/tag';
import { Icon } from '@iconify/react';
import baselineTag from '@iconify/icons-ic/baseline-tag';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
    container: {
        borderBottom: '1px solid rgba(230, 230, 230, 1)',
    },
    item: {
        borderRight: '1px solid rgba(230, 230, 230, 1)',
    },
    button: {},
    icon: {
        fontSize: '.75em',
    },
    minimalContainer: {
        flexWrap: 'nowrap',
    },
    minimalItem: {
        whiteSpace: 'nowrap',
        '& button': {
            paddingLeft: theme.spacing(0.75),
            paddingRight: theme.spacing(0.75),
        },
    },
}));

const editorOptions = {
    bold: 'BOLD',
    italic: 'ITALIC',
    code: 'CODE',
    underline: 'UNDERLINE',
    strikethrough: 'STRIKETHROUGH',
    bullet: 'unordered-list-item',
    number: 'ordered-list-item',
};

const EditorToolbar = ({
    editorState,
    setEditorState,
    editorRef,
    doChange,
    mention,
    tag,
    disabled,
    minimal,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const focus = () => {
        setTimeout(() => {
            if (editorRef.current) {
                editorRef.current.focus();
            }
        }, 50);
    };

    // STYLES
    const currentStyle = editorState.getCurrentInlineStyle();
    const usedStyleOption = option => currentStyle.has(option);
    const toggleStyleOption = (event, option) => {
        event.preventDefault();

        if (hasSelectionRange(editorState)) {
            doChange(RichUtils.toggleInlineStyle(editorState, option));
            focus();
        } else {
            doChange(RichUtils.toggleInlineStyle(editorState, option));

            // waitForInput(editorState => {doChange(RichUtils.toggleInlineStyle(editorState, option)); focus();});
        }
    };

    const toggleBold = event => toggleStyleOption(event, editorOptions.bold);
    const toggleItalic = event => toggleStyleOption(event, editorOptions.italic);
    const toggleCode = event => toggleStyleOption(event, editorOptions.code);
    // const toggleUnderline = event => toggleStyleOption(event, editorOptions.underline);
    const toggleStrikethrough = event => toggleStyleOption(event, editorOptions.strikethrough);

    // BLOCK TYPES
    const currentType = RichUtils.getCurrentBlockType(editorState);
    const usedBlockType = type => currentType === type;
    const toggleBlockType = (event, type) => {
        event.preventDefault();
        doChange(RichUtils.toggleBlockType(editorState, type));
        focus();
    };

    const toggleBulletList = event => toggleBlockType(event, editorOptions.bullet);
    const toggleNumberList = event => toggleBlockType(event, editorOptions.number);

    // UTILS
    const addMention = () => {
        setEditorState(activateMention(editorState));
        focus();
    };

    const addTag = () => {
        setEditorState(activateTag(editorState));
        focus();
    };

    // ToDo: Reactivate Highlighted Text: <ToolbarColor classes={classes} className={classes.button} editorState={editorState} doChange={doChange} doFocus={focus} />
    /*
    * Reactivate Underline (Not supported by Markdown)
                <IconButton className={classes.button} onMouseDown={toggleUnderline}>
                    <UnderlineIcon color={iconColor(usedStyleOption(editorOptions.underline))} />
                </IconButton>
    */
    return (
        <Grid
            container
            className={classNames({
                [classes.container]: true,
                [classes.minimalContainer]: minimal,
            })}
        >
            <Grid
                item
                className={classNames({
                    [classes.item]: true,
                    [classes.minimalItem]: minimal,
                })}
            >
                <IconButton
                    className={classes.button}
                    onMouseDown={toggleBold}
                    disabled={disabled}
                    label={t('components.EditorToolbar.bold')}
                >
                    <BoldOutlinedIcon
                        className={classes.icon}
                        color={iconColor(usedStyleOption(editorOptions.bold))}
                    />
                </IconButton>
                <IconButton
                    className={classes.button}
                    onMouseDown={toggleItalic}
                    disabled={disabled}
                    label={t('components.EditorToolbar.italic')}
                >
                    <ItalicOutlinedIcon
                        className={classes.icon}
                        color={iconColor(usedStyleOption(editorOptions.italic))}
                    />
                </IconButton>
                <IconButton
                    className={classes.button}
                    onMouseDown={toggleStrikethrough}
                    disabled={disabled}
                    label={t('components.EditorToolbar.strike')}
                >
                    <StrikeOutlinedIcon
                        className={classes.icon}
                        color={iconColor(usedStyleOption(editorOptions.strikethrough))}
                    />
                </IconButton>
            </Grid>
            <Grid
                item
                className={classNames({
                    [classes.item]: true,
                    [classes.minimalItem]: minimal,
                })}
            >
                {mention && (
                    <IconButton
                        className={classes.button}
                        onMouseDown={addMention}
                        disabled={disabled}
                        label={t('components.EditorToolbar.mention')}
                    >
                        <MentionOutlinedIcon className={classes.icon} />
                    </IconButton>
                )}
                {tag && (
                    <IconButton
                        className={classes.button}
                        onMouseDown={addTag}
                        disabled={disabled}
                        label={t('components.EditorToolbar.tag')}
                    >
                        <Icon icon={baselineTag} className={classes.icon} />
                    </IconButton>
                )}
                <ToolbarLink
                    classes={classes}
                    editorState={editorState}
                    doChange={doChange}
                    doFocus={focus}
                    disabled={disabled}
                />
            </Grid>
            <Grid
                item
                className={classNames({
                    [classes.item]: true,
                    [classes.minimalItem]: minimal,
                })}
            >
                <IconButton
                    className={classes.button}
                    onMouseDown={toggleCode}
                    disabled={disabled}
                    label={t('components.EditorToolbar.code')}
                >
                    <CodeOutlinedIcon
                        className={classes.icon}
                        color={iconColor(usedStyleOption(editorOptions.code))}
                    />
                </IconButton>
            </Grid>
            <Grid
                item
                className={classNames({
                    [classes.item]: true,
                    [classes.minimalItem]: minimal,
                })}
            >
                <IconButton
                    className={classes.button}
                    onMouseDown={toggleBulletList}
                    disabled={disabled}
                    label={t('components.EditorToolbar.bulletList')}
                >
                    <BulletListOutlinedIcon
                        className={classes.icon}
                        color={iconColor(usedBlockType(editorOptions.bullet))}
                    />
                </IconButton>
                <IconButton
                    className={classes.button}
                    onMouseDown={toggleNumberList}
                    disabled={disabled}
                    label={t('components.EditorToolbar.numberList')}
                >
                    <NumberListOutlinedIcon
                        className={classes.icon}
                        color={iconColor(usedBlockType(editorOptions.number))}
                    />
                </IconButton>
            </Grid>
        </Grid>
    );
};

EditorToolbar.propTypes = {
    editorState: EditorStatePropType.isRequired,
    doChange: PropTypes.func.isRequired,
    editorRef: EditorReferencePropType.isRequired,
    setEditorState: PropTypes.func.isRequired,
    mention: PropTypes.bool,
    tag: PropTypes.bool,
    disabled: PropTypes.bool,
    minimal: PropTypes.bool,
};

EditorToolbar.defaultProps = {
    mention: false,
    tag: false,
    disabled: false,
    minimal: false,
};

export default EditorToolbar;
