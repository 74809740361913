import React from 'react';
import * as PropTypes from 'prop-types';
import MUIIcon from '@mui/material/Icon';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';

const useStyles = makeStyles({
    color: ({ color }) => ({
        color,
    }),

    border: {
        border: 1,
        borderStyle: 'solid',
    },

    background: {
        background: 'white',
        borderRadius: '50%',
        width: 40,
        height: 40,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    small: {
        height: 32,
        width: 32,
        fontSize: 17,
    },
});

const Icon = ({ type, color, border, background, small, ...other }) => {
    const classes = useStyles({ color });

    const [muiColor, className] = [
        'inherit',
        'primary',
        'secondary',
        'action',
        'error',
        'disabled',
    ].includes(color)
        ? [color, null]
        : ['inherit', classes.color];

    return (
        <MUIIcon
            className={classNames(className, {
                [classes.border]: border,
                [classes.background]: background || border,
                [classes.small]: small,
            })}
            color={muiColor}
            {...other}
        >
            {type}
        </MUIIcon>
    );
};

Icon.propTypes = {
    type: PropTypes.string,
    color: PropTypes.string,
    border: PropTypes.bool,
    background: PropTypes.bool,
    small: PropTypes.bool,
};

Icon.defaultProps = {
    type: null,
    color: null,
    border: false,
    background: false,
    small: false,
};

export default Icon;
