import React from 'react';
import Typography from '@mui/material/Typography';
import { exportDate, importDate } from '../datetime/utils';
import { formatMailViewSchema } from './schema';

const { createHash } = require('crypto');

export const MAIL_PATH_TYPES = {
    MAIL: 'mail',
    FILE: 'file',
};

export const formatMailView = ({
    messageId,
    inbound,
    from,
    to,
    cc,
    bcc,
    date,
    subject,
    body,
    bodyHTML,
}) => {
    const receivers = getReceivers(from, to, cc, bcc);
    const messageIdHash = generateMessageIdHash({ subject, date, from, to });

    return {
        messageId: messageId || messageIdHash,
        messageIdHash,
        inbound,
        receivedAt: importDate(date, true),
        subject,
        body,
        bodyHTML,
        receivers,
        ...trimPreview(body),
    };
};

export const validateFormatMailView = values => formatMailViewSchema.validate(values);

export const formatMailImport = ({
    messageId,
    subject,
    body,
    receivers,
    body_html: bodyHTML,
    is_inbound: inbound,
    received_at,
}) => ({
    messageId,
    inbound,
    receivedAt: importDate(received_at),
    subject,
    body,
    bodyHTML,
    receivers,
    ...trimPreview(body),
});

export const formatMailExport = ({
    messageId: message_id,
    messageIdHash,
    inbound: is_inbound,
    receivedAt,
    bodyHTML: body_html,
    ...other
}) => ({
    message_id,
    is_inbound,
    body_html,
    received_at: exportDate(receivedAt),
    ...other,
});

const getReceivers = (from, to, cc, bcc) => {
    return [
        ...(Array.isArray(from) ? from : [from]).map(item => getReceiver(item, 'from')),
        ...(Array.isArray(to) ? to.map(item => getReceiver(item, 'to')) : []),
        ...(Array.isArray(cc) ? cc.map(item => getReceiver(item, 'cc')) : []),
        ...(Array.isArray(bcc) ? bcc.map(item => getReceiver(item, 'bcc')) : []),
    ];
};

const getReceiver = ({ name, email, displayName, emailAddress }, type = null) => ({
    type,
    email: email || emailAddress,
    name: name || displayName,
});

export const splitNewlines = string => (string ? string.split(/\r?\n/) : []);

export const trimPreview = (fullString, limit = 6) => {
    const lines = fullString ? splitNewlines(fullString) : '';

    return {
        preview: lines ? lines.slice(0, limit).join('\n') : '',
        remain: lines ? lines.slice(limit, lines.length).join('\n') : '',
    };
};

export const removeTriangleBrackets = string => string.replace(/[<>]/g, '');

export const renderNewlines = (string, prefix) => {
    return splitNewlines(string).map((line, index) => (
        <Typography key={prefix + index} variant="body2" style={{ wordWrap: 'break-word' }}>
            {line}
        </Typography>
    ));
};

export const generateMessageIdHash = ({ subject, date, from, to }) => {
    const optimizedDate = exportDate(date);
    const optimizedFrom = getReceiver((Array.isArray(from) && from[0]) || from)?.email;
    const optimizedTo = Array.isArray(to)
        ? to.map(receiver => getReceiver(receiver)?.email).join(',')
        : null;

    const valueString = [subject, optimizedDate, optimizedFrom, optimizedTo].join('.');
    const hash = createHash('sha1');

    hash.update(valueString);

    return hash.digest('hex');
};

export const exportTempId = tempId => `_${tempId}`;
