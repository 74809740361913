import { EditorState, Entity } from 'draft-js';
import getFragmentFromSelection from 'draft-js/lib/getFragmentFromSelection';
import DraftModifier from 'draft-js/lib/DraftModifier';
import { getBlocksText, getSelectedBlockKeys } from './block';
import { isBetween } from './utils';

export const getElementData = props => Entity.get(props.entityKey).getData();

export const makeCustomElement = (editorState, type, value = {}) => {
    let contentState = editorState.getCurrentContent();
    const selectedBlocks = getFragmentFromSelection(editorState);
    const text = getBlocksText(selectedBlocks);
    const entity = contentState.createEntity(type, 'MUTABLE', { ...value, text }); // Standard-Property storing selected Text

    contentState = DraftModifier.replaceText(
        contentState,
        editorState.getSelection(),
        text,
        null,
        entity.getLastCreatedEntityKey()
    );

    return EditorState.push(editorState, contentState, 'change-block-type');
};

export const getElements = (editorState, entityType = null, useSelection = false) => {
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    const entities = [];
    contentState.getBlocksAsArray().forEach(block => {
        let found = null;
        block.findEntityRanges(
            character => {
                const entityID = character.getEntity();
                if (entityID != null) {
                    const entity = contentState.getEntity(entityID);
                    if (!entityType || (entityType && entity.getType() === entityType)) {
                        found = {
                            entityKey: entityID,
                            blockKey: block.getKey(),
                            entity: contentState.getEntity(character.getEntity()),
                        };
                        return true;
                    }
                }
                return false;
            },
            (entityStart, entityEnd) => {
                if (useSelection) {
                    const isSelectedBlock = getSelectedBlockKeys(editorState).includes(
                        found.blockKey
                    );

                    if (isSelectedBlock) {
                        const isStartBlock = selection.getStartKey() === found.blockKey;
                        const isEndBlock = selection.getEndKey() === found.blockKey;
                        const isInAreaSelected =
                            !isStartBlock ||
                            isBetween(
                                entityStart,
                                selection.getStartOffset(),
                                selection.getEndOffset()
                            ) ||
                            !isEndBlock ||
                            isBetween(
                                entityEnd,
                                selection.getStartOffset(),
                                selection.getEndOffset()
                            ) ||
                            isBetween(selection.getStartOffset(), entityStart, entityEnd) ||
                            isBetween(selection.getStartOffset(), entityStart, entityEnd);

                        if (isInAreaSelected) {
                            entities.push({ ...found, entityStart, entityEnd });
                        }
                    }
                } else {
                    entities.push({ ...found, entityStart, entityEnd });
                }
            }
        );
    });

    return entities;
};

export const getEditorEntities = (content, entityType = null) => {
    // ToDo: Use getElements?
    const entities = [];
    content.getBlocksAsArray().forEach(block => {
        let selectedEntity = null;
        block.findEntityRanges(
            character => {
                if (character.getEntity() !== null) {
                    const entity = content.getEntity(character.getEntity());
                    if (!entityType || (entityType && entity.getType() === entityType)) {
                        selectedEntity = {
                            entityKey: character.getEntity(),
                            blockKey: block.getKey(),
                            entity: content.getEntity(character.getEntity()),
                        };
                        return true;
                    }
                }
                return false;
            },
            (start, end) => {
                entities.push({ ...selectedEntity, start, end });
            }
        );
    });
    return entities;
};

export const findEditorElements = (contentBlock, callback, contentState, type) => {
    contentBlock.findEntityRanges(character => {
        const entityKey = character.getEntity();
        return entityKey !== null && contentState.getEntity(entityKey).getType() === type;
    }, callback);
};
