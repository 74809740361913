import React from 'react';
import * as PropTypes from 'prop-types';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import { useTranslation } from 'react-i18next';
import Confirmation from '../form/Confirmation';
import Button from '../form/base/Button';
import { DELETE } from '../../modules/abilities/actions';
import { SubjectPropType } from '../../modules/abilities/proptypes';

const ArchiveButton = ({ onClick, confirmLabel, disabled, subject }) => {
    const { t } = useTranslation();

    return (
        <Confirmation
            popupId="ArchiveButton"
            label={confirmLabel || 'Archivieren?'}
            onConfirm={onClick}
        >
            <Button
                startIcon={<ArchiveOutlinedIcon />}
                disabled={disabled}
                action={DELETE}
                subject={subject}
                field="deleted_at"
                data-test-id="ArchiveButton"
            >
                {t('components.ResourceTableRowDetails.archive')}
            </Button>
        </Confirmation>
    );
};

ArchiveButton.propTypes = {
    confirmLabel: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    subject: SubjectPropType,
};

ArchiveButton.defaultProps = {
    confirmLabel: null,
    disabled: false,
    subject: null,
};

export default ArchiveButton;
