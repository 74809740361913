import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import CalendarTaskAutocomplete from './CalendarTaskAutocomplete';
import CalendarTaskSelect from './CalendarTaskSelect';
import { IdPropType } from '../../../modules/proptypes';

const params = {
    completed_at: 'null',
    card_id: 'not_null',
};

const CalendarTaskInput = ({ name, cardId }) => {
    return cardId ? (
        <CalendarTaskSelect name={name} cardId={cardId} params={params} />
    ) : (
        <CalendarTaskAutocomplete name={name} params={params} />
    );
};

CalendarTaskInput.propTypes = {
    name: PropTypes.string.isRequired,
    cardId: IdPropType,
};

CalendarTaskInput.defaultProps = {
    cardId: null,
};

export default CalendarTaskInput;
