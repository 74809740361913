import { makeStyles } from '@mui/styles';
import MuiTextField from '@mui/material/TextField';
import * as PropTypes from 'prop-types';
import React, { forwardRef, useMemo } from 'react';
import { useContextualCan } from '../../../modules/abilities/hooks';
import ContextualCan from '../../../modules/abilities/ContextualCan';
import { READ, WRITE } from '../../../modules/abilities/actions';
import { generateErrorTooltipStyle } from '../ErrorTooltip';

const useStyles = makeStyles(theme => ({
    root: {
        margin: 0,
        width: '100%',
    },

    input: {
        backgroundColor: 'transparent',
        color: theme.palette.primary.contrastText,
        // minWidth: 300,
        borderWidth: 5,

        '&:hover $inputOutline': {
            borderColor: theme.palette.primary.contrastText,
        },
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
            '&:hover $inputOutline': {
                borderColor: theme.palette.primary.contrastText,
            },
        },
        '&$inputFocused $inputOutline': {
            borderColor: theme.palette.primary.contrastText,
        },
        '&$inputError $inputOutline': {
            borderColor: theme.palette.error.light,
        },
    },
    inputFocused: {},
    inputError: {},
    inputOutline: {
        borderColor: theme.palette.primary.contrastText,
    },

    label: {
        color: theme.palette.primary.contrastText,

        '&$labelFocused': {
            color: theme.palette.primary.contrastText,
        },

        '&$labelError': {
            color: theme.palette.error.light,
        },
    },
    labelFocused: {},
    labelError: {},

    helper: {
        color: theme.palette.error.light,

        '&$helperError': {
            color: theme.palette.error.light,
        },
    },
    helperError: {},
}));

const getInputProps = (contrast, variant, classes) => {
    if (!contrast) {
        return null;
    }

    return variant === 'outlined'
        ? {
              classes: {
                  root: classes.input,
                  notchedOutline: classes.inputOutline,
                  focused: classes.inputFocused,
                  error: classes.inputError,
              },
          }
        : {
              classes: {
                  root: classes.input,
                  // TODO: error state for underlined
              },
              disableUnderline: true,
          };
};

const getInputLabelProps = (contrast, variant, classes) =>
    contrast && {
        classes: {
            root: classes.label,
            focused: classes.labelFocused,
            error: classes.labelError,
        },
    };

const getFormHelperTextProps = (contrast, variant, classes) =>
    contrast && {
        classes: {
            root: classes.helper,
            error: classes.helperError,
        },
    };

const TextField = forwardRef(
    (
        {
            name,
            originalName,
            variant,
            contrast,
            InputProps,
            inputProps,
            InputLabelProps,
            FormHelperTextProps,
            disabled,
            label,
            size,
            autofill,
            errorAsTooltip = false,
            can,
            ...other
        },
        ref
    ) => {
        const classes = useStyles();
        const canDo = useContextualCan(can ? can : WRITE, name);
        const autoComplete = useMemo(() => (autofill ? undefined : 'new-password'), [autofill]);

        return (
            <ContextualCan I={READ} field={originalName || name}>
                <MuiTextField
                    label={label}
                    data-test-id={`${name}TextField`}
                    variant={variant}
                    size={size}
                    className={classes.root}
                    InputProps={{
                        ...getInputProps(contrast, variant, classes),
                        ...InputProps,
                        inputProps: {
                            ...inputProps,
                            id: autofill
                                ? inputProps && inputProps.autofill
                                : (Math.random() + 1).toString(36).substring(7),
                            autoComplete,
                            autofill,
                        },
                    }}
                    InputLabelProps={{
                        ...getInputLabelProps(contrast, variant, classes),
                        ...InputLabelProps,
                    }}
                    FormHelperTextProps={{
                        ...getFormHelperTextProps(contrast, variant, classes),
                        ...FormHelperTextProps,
                        ...(errorAsTooltip
                            ? { classes: { error: generateErrorTooltipStyle().tooltip } }
                            : {}),
                    }}
                    disabled={disabled || !canDo}
                    autoComplete={autoComplete}
                    name={(Math.random() + 1).toString(36).substring(7)}
                    inputRef={ref}
                    {...other}
                />
            </ContextualCan>
        );
    }
);

TextField.propTypes = {
    name: PropTypes.string,
    originalName: PropTypes.string,
    variant: PropTypes.string,
    contrast: PropTypes.bool,
    size: PropTypes.string,
    InputProps: PropTypes.shape({}),
    inputProps: PropTypes.shape({}),
    InputLabelProps: PropTypes.shape({}),
    FormHelperTextProps: PropTypes.shape({}),
    label: PropTypes.string,
    disabled: PropTypes.bool,
    autofill: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    can: PropTypes.string,
};

TextField.defaultProps = {
    name: undefined,
    originalName: undefined,
    variant: 'outlined',
    size: 'medium',
    contrast: false,
    InputProps: null,
    inputProps: null,
    InputLabelProps: null,
    FormHelperTextProps: null,
    label: null,
    disabled: false,
    autofill: 'false',
    can: null,
};

export default React.memo(TextField);
