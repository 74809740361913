import React, { memo, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Box, Collapse, Typography, IconButton, Grid, ToggleButtonGroup } from '@mui/material';
import ExpandMoreOutlined from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessOutlined from '@mui/icons-material/ExpandLessOutlined';
import UnfoldLessOutlined from '@mui/icons-material/UnfoldLessOutlined';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import ArticleIcon from '@mui/icons-material/Article';
import IncomingMailIcon from './IncomingMailIcon';

import { format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';

import EmailContactList from './EmailContactList';
import EmailHeaderRow from './EmailHeaderRow';
import { EmailPropType } from '../../modules/email/proptypes';
import HtmlMailView from './HtmlMailView';
import TooltipToggleButton from '../form/base/TooltipToggleButton';
import { makeStyles } from '@mui/styles';
import { renderNewlines, trimPreview } from '../../modules/email/utils';
import { MAIL_VIEW_MODE } from '../../modules/email/config';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        border: `1px solid ${theme.palette.grey[400]}`,
        borderRadius: 4,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    container: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    typeIcon: {
        height: 20,
        marginRight: theme.spacing(0.5),
    },
    viewModeSwitch: {
        overflow: 'hidden',
        height: '28px',
    },
    titleRow: {
        alignItems: 'center',
        overflow: 'hidden',
        minHeight: '28px',
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(2),
    },
    titleWrap: {
        overflow: 'hidden',
    },
    titleText: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
}));

const MAIL_VIEW_MODE_CONFIG = {
    [MAIL_VIEW_MODE.HTML]: {
        title: 'components.EmailView.view_mode.html',
        IconComponent: NewspaperIcon,
    },
    [MAIL_VIEW_MODE.PLAIN_TEXT]: {
        title: `components.EmailView.view_mode.text`,
        IconComponent: ArticleIcon,
    },
};

const EmailView = ({ data, expanded, viewMode }) => {
    const { t } = useTranslation('basics');
    const classes = useStyles();

    const [open, setOpen] = useState(expanded);
    const [mailViewMode, setMailViewMode] = useState(viewMode);
    const [wrapTitle, setWrapTitle] = useState(false);

    const handleToggle = () => setOpen(!open);
    const showToggle = () => setOpen(true);

    const hasHTML = data.bodyHTML;

    const toggleMailViewMode = event => {
        event.preventDefault();
        if (mailViewMode === MAIL_VIEW_MODE.HTML) {
            setMailViewMode(MAIL_VIEW_MODE.PLAIN_TEXT);
        } else if (mailViewMode === MAIL_VIEW_MODE.PLAIN_TEXT) {
            setMailViewMode(MAIL_VIEW_MODE.HTML);
        }
    };

    const renderContactRow = (title, contacts) => {
        if (contacts == null || !contacts.length) {
            return null;
        }

        return (
            <EmailHeaderRow title={title}>
                <Grid item xs container>
                    <EmailContactList list={contacts} />
                </Grid>
            </EmailHeaderRow>
        );
    };

    const { preview, remain } = trimPreview(data.body);

    const { from, to, cc, bcc } = useMemo(() => {
        if (!data.receivers) {
            return {};
        }
        return data.receivers.reduce((carry, current) => {
            if (current.type === 'from') {
                return {
                    ...carry,
                    from: 'from' in carry ? [...carry.from, current] : [current],
                };
            }
            if (current.type === 'to') {
                return {
                    ...carry,
                    to: 'to' in carry ? [...carry.to, current] : [current],
                };
            }
            if (current.type === 'cc') {
                return {
                    ...carry,
                    cc: 'cc' in carry ? [...carry.cc, current] : [current],
                };
            }
            if (current.type === 'bcc') {
                return {
                    ...carry,
                    bcc: 'bcc' in carry ? [...carry.bcc, current] : [current],
                };
            }

            return carry;
        }, {});
    }, [data.receivers]);

    const receivedAt = useMemo(() => {
        if (!data.received_at || (data.received_at instanceof Date && isNaN(data.received_at))) {
            return null;
        }

        const date =
            data.received_at instanceof Date ? data.received_at : parseISO(data.received_at);

        return format(date, 'dd.MM.yyyy HH:mm');
    }, [data.received_at]);

    return (
        <Box className={classes.root}>
            <Box className={classes.container}>
                <EmailHeaderRow title={t('components.EmailView.from')}>
                    <Grid item xs container>
                        <EmailContactList list={from} />
                    </Grid>
                    <Grid item>
                        <Box mr={2}>
                            <Typography variant="body2" color="textSecondary">
                                {receivedAt ? receivedAt + ' ' + t('time') : null}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item className={classes.viewModeSwitch}>
                        <ToggleButtonGroup
                            onChange={toggleMailViewMode}
                            value={hasHTML ? mailViewMode : MAIL_VIEW_MODE.PLAIN_TEXT}
                            disabled={!hasHTML}
                        >
                            {Object.values(MAIL_VIEW_MODE).map(mailViewModeOption => {
                                const { title, IconComponent } =
                                    MAIL_VIEW_MODE_CONFIG[mailViewModeOption];

                                return (
                                    <TooltipToggleButton
                                        title={t(title)}
                                        key={mailViewModeOption}
                                        value={mailViewModeOption}
                                        size="tiny"
                                    >
                                        <IconComponent />
                                    </TooltipToggleButton>
                                );
                            })}
                        </ToggleButtonGroup>
                    </Grid>
                </EmailHeaderRow>

                <EmailHeaderRow title={t('components.EmailView.to')}>
                    <Grid item xs container>
                        <EmailContactList list={to} />
                    </Grid>
                    <Grid item>
                        <Box className={classes.typeIcon}>
                            {data.inbound ? (
                                <IncomingMailIcon fontSize="small" color="disabled" />
                            ) : (
                                <ForwardToInboxIcon fontSize="small" color="disabled" />
                            )}
                        </Box>
                    </Grid>
                </EmailHeaderRow>

                {renderContactRow(t('components.EmailView.cc'), cc)}
                {renderContactRow(t('components.EmailView.bcc'), bcc)}

                <Grid container className={classes.titleRow}>
                    <Grid
                        item
                        xs
                        className={wrapTitle ? null : classes.titleWrap}
                        onClick={() => setWrapTitle(lastWrap => !lastWrap)}
                    >
                        <Typography
                            variant="subtitle1"
                            className={wrapTitle ? null : classes.titleText}
                        >
                            {data.subject}
                        </Typography>
                    </Grid>
                    {open && (
                        <Grid item>
                            <IconButton size="small" onClick={handleToggle}>
                                <UnfoldLessOutlined fontSize="12px" />
                            </IconButton>
                        </Grid>
                    )}
                </Grid>

                {hasHTML && mailViewMode === MAIL_VIEW_MODE.HTML ? (
                    <HtmlMailView htmlContent={data.bodyHTML} expanded={open} />
                ) : (
                    <>
                        <Box onClick={showToggle}>
                            <Box>{renderNewlines(preview, 'pv')}</Box>
                        </Box>

                        <Collapse in={open} timeout="auto" unmountOnExit>
                            {renderNewlines(remain, 'rm')}
                        </Collapse>
                    </>
                )}
            </Box>
            <Box display="flex" justifyContent="center" onClick={handleToggle}>
                <IconButton onClick={handleToggle} size="small">
                    {open ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
                </IconButton>
            </Box>
        </Box>
    );
};

EmailView.propTypes = {
    data: EmailPropType.isRequired,
    expanded: PropTypes.bool,
    viewMode: PropTypes.oneOf([...Object.values(MAIL_VIEW_MODE)]),
};

EmailView.defaultProps = {
    expanded: false,
    viewMode: MAIL_VIEW_MODE.HTML,
};

export default memo(EmailView);
