import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import FormPopup from '../popups/FormPopup';
import { selectUser } from '../../modules/auth/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { SEARCH_RESOURCE } from '../../modules/api/resources';
import TextInput from '../form/formik/TextInput';
import { destroySearch, storeSearch, updateSearch } from '../../modules/searches/actions';

const SearchPillEdit = ({
    editingPill,
    filters,
    type,
    onSubmitted,
    onDeleted,
    onAbort,
    children,
    ...other
}) => {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);

    const initialValues = useMemo(
        () =>
            editingPill || {
                name: '',
                type,
                search_data: filters,
                created_by: user.id,
            },
        [editingPill, type, filters, user]
    );

    const handleSubmit = values => {
        if (values.id) {
            return dispatch(updateSearch(values)).then(data => {
                if (onSubmitted) {
                    onSubmitted(data);
                }
            });
        }

        return dispatch(storeSearch(values)).then(data => {
            if (onSubmitted) {
                onSubmitted(data);
            }
        });
    };

    const handleDelete = () => {
        if (editingPill?.id) {
            return dispatch(destroySearch({ id: editingPill.id }))
                .then(onDeleted)
                .catch(() => null);
        }
    };

    return (
        <FormPopup
            popupId={`searchPill${editingPill ? 'Edit' : 'Create'}`}
            initialValues={initialValues} // ToDo validationSchema and headline
            subject={SEARCH_RESOURCE}
            enableReinitialize
            disableUntouchedSubmit
            muteDirty
            onSubmit={handleSubmit}
            onDelete={editingPill?.id ? handleDelete : null}
            onAbort={onAbort}
            FormInputs={
                <TextInput
                    name="name"
                    label={'Name'} // ToDo translate
                    size="small"
                    autoFocus
                />
            }
            {...other}
        >
            {children}
        </FormPopup>
    );
};

SearchPillEdit.propTypes = {
    editingPill: PropTypes.shape({}),
    filters: PropTypes.arrayOf(PropTypes.shape({})),
    type: PropTypes.string.isRequired,
    onSubmitted: PropTypes.func,
    onDeleted: PropTypes.func,
    onAbort: PropTypes.func,
};

SearchPillEdit.defaultProps = {
    editingPill: null,
    filters: [],
    onSubmitted: null,
    onDeleted: null,
    onAbort: null,
};

export default SearchPillEdit;
