export const CARD_BOARD_CONTEXT = 'card.board'; // kanban generates the board context dynamically

export const CARD_MENU_CONTEXT = 'card.menu';

export const CARD_DETAILS_HEADER_CONTEXT = 'card.details.header';
export const CARD_DETAILS_CONTENT_CONTEXT = 'card.details.content';
export const CARD_DETAILS_FOOTER_CONTEXT = 'card.details.footer';

export const CARD_LINKED_DETAILS_CONTEXT = 'card.linked.details';

export const CARD_IDENTIFIER_CONTEXT = 'card.identifier';

export const CARD_CLOUD_CONTEXT = 'card.cloud';
export const CARD_STATUS_CLOUD_CONTEXT = 'card_status.cloud';

export const CARD_CONTACTS_DETAILS_CONTEXT = 'card.contacts.details';

export const CONTACT_DETAILS_CONTEXT = 'contact.details';

export const ATTACHMENT_HEADER_CONTEXT = 'attachment.header';

export const CARD_MAP_CONTEXT = 'card.map';
