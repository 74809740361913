import PropTypes from 'prop-types';
import { IdPropType } from '../../proptypes';

export const AppointmentAvailabilityPropsPropType = PropTypes.shape({
    resourceModel: PropTypes.string,
    resourceId: IdPropType,
    componentProps: PropTypes.shape({}),
});

export const AppointmentAvailabilityPropType = PropTypes.shape({
    avail: PropTypes.string,
    appointments: PropTypes.arrayOf(IdPropType),
});
