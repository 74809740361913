import React from 'react';
import * as PropTypes from 'prop-types';
import ResourceTablePrintCell from '../../components/resourceTable/ResourceTablePrintCell';
import { getAggregationFieldKey } from '../../modules/aggregation/utils';

const ResourceTablePrintAggregates = ({ table, columns, aggregateTotal, onCheckColumnId }) => {
    const visibleCols = table.getHeaderGroups()[0]?.headers;

    return (
        <tr style={{ backgroundColor: '#777' }}>
            {visibleCols.map(visibleColumn => {
                const aggregationKey = getAggregationFieldKey(visibleColumn?.column?.columnDef);

                return onCheckColumnId(visibleColumn?.id) ? (
                    <td
                        style={{ borderLeft: '1px solid #666', borderRight: '1px solid #666' }}
                        key={`printAggregates.${visibleColumn?.id}`}
                    >
                        <div
                            style={{
                                padding: 4,
                                fontSize: 14,
                                fontWeight: 'bold',
                                color: '#FFF',
                                textWrap: 'balance',
                                ...(typeof visibleColumn?.column?.columnDef?.forcePrintStyle ===
                                'object'
                                    ? visibleColumn.column.columnDef.forcePrintStyle
                                    : {}),
                            }}
                        >
                            <ResourceTablePrintCell
                                table={table}
                                columns={columns}
                                cell={{
                                    getValue: () =>
                                        typeof aggregateTotal === 'object' &&
                                        aggregateTotal[aggregationKey],
                                    column: visibleColumn,
                                    row: { original: aggregateTotal },
                                }}
                                aggregateMode
                            />
                        </div>
                    </td>
                ) : null;
            })}
        </tr>
    );
};

ResourceTablePrintAggregates.propTypes = {
    table: PropTypes.shape({}).isRequired,
    columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    aggregateTotal: PropTypes.shape({}).isRequired,
    onCheckColumnId: PropTypes.func.isRequired,
};

export default ResourceTablePrintAggregates;
