import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';

import * as PropTypes from 'prop-types';
import { ConditionalWrapper } from '../../utils/ConditionalWrapper';
import { alpha, Box, ToggleButton } from '@mui/material';

/**
 * https://github.com/brennancheung/edajs/blob/aeebceeedd73ae9898f7a3c5a567655f74d673dd/src/app/core/components/TooltipToggleButton.js
 * Unfortunately, ToggleButtonGroup uses React.children.map instead of context
 * so wrapping with Tooltip breaks ToggleButton functionality.
 * This is a workaround.
 */

const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.text.disabled,
    },
    tiny: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        fontSize: theme.typography.pxToRem(12),
    },

    textButtonRoot: {
        background: theme.palette.grey[300],
        color: alpha(theme.palette.action.active, 0.5),

        '&$selected': {
            backgroundColor: theme.palette.background.default,
            color: theme.palette.primary.main,
        },
    },

    selected: {},

    disabled: {
        color: theme.palette.grey[300],

        '&:hover': {
            background: 'transparent',
        },
    },

    contrast: {
        color: 'inherit',
        fontSize: 'large',
        border: `1px solid ${theme.palette.primary.contrastText}`,
        padding: '6px 6px',

        '&.Mui-selected': {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.primary.contrastText,
        },

        '&:not(:first-of-type)': { borderLeft: `1px solid ${theme.palette.primary.contrastText}` },
    },
}));

const TooltipToggleButton = ({
    children,
    title,
    contrast,
    className,
    size,
    variant,
    disabled,
    placement,
    ...other
}) => {
    const classes = useStyles();

    return (
        <Tooltip title={title} placement={placement} disableInteractive>
            <ToggleButton
                className={classNames(className, {
                    [classes.root]: true,
                    [classes.tiny]: size === 'tiny',
                    [classes.contrast]: contrast,
                    [classes.disabled]: disabled,
                })}
                classes={
                    variant === 'text'
                        ? {
                              root: classes.textButtonRoot,
                              selected: classes.selected,
                          }
                        : null
                }
                size={size === 'tiny' ? 'medium' : size}
                {...other}
                {...(disabled ? { onChange: null } : {})}
            >
                {children}
            </ToggleButton>
        </Tooltip>
    );
};

TooltipToggleButton.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
    contrast: PropTypes.bool,
    className: PropTypes.string,
    size: PropTypes.string,
    variant: PropTypes.string,
    disabled: PropTypes.bool,
    placement: PropTypes.string,
};

TooltipToggleButton.defaultProps = {
    title: '',
    contrast: false,
    className: null,
    size: 'medium',
    variant: 'default',
    disabled: false,
    placement: 'bottom',
};

export default TooltipToggleButton;
