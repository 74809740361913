import React from 'react';
import * as PropTypes from 'prop-types';
import LinkIcon from '@mui/icons-material/Link';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '../../modules/snackbar/hooks';
import { ConditionalWrapper } from '../utils/ConditionalWrapper';
import { Tooltip } from '@mui/material';

const CopyLinkButton = ({ label, to, className, ...other }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const handleClick = event => {
        if (event.metaKey || event.ctrlKey) {
            /* keep default behaviour */
            return;
        }

        const absoluteUrl = `${window.location.protocol}//${window.location.host}${to}`;
        try {
            navigator.clipboard.writeText(absoluteUrl).then(() => {
                enqueueSnackbar(t('components.CopyLinkButton.success'), { preventDuplicate: true });
            });
            event.preventDefault();
        } catch (e) {
            /* fallback to default behaviour, e.g. opening the link :D */
        }
    };
    return (
        <ConditionalWrapper
            condition={label}
            wrapper={_children => (
                <Tooltip title={label} disableInteractive>
                    {_children}
                </Tooltip>
            )}
        >
            <Link to={to} onClick={handleClick} className={className} {...other}>
                <LinkIcon color="disabled" />
            </Link>
        </ConditionalWrapper>
    );
};

CopyLinkButton.propTypes = {
    to: PropTypes.string.isRequired,
    className: PropTypes.string,
    label: PropTypes.string,
};

CopyLinkButton.defaultProps = {
    className: null,
    label: null,
};

export default CopyLinkButton;
