import { Kanban } from 'react-kanban';

import Form from '../../components/form/formik/Form';
import TextInput from '../../components/form/formik/TextInput';
import DateInput from '../../components/form/formik/DateInput';
import Select from '../../components/form/formik/Select';
import { apiWrapper } from './apiWrapper';
import { makeCardsByCardStatusIdSelector } from '../cards/selectors';
import Typography from '@mui/material/Typography';

const components = {
    Typography,
    Form,
    TextInput,
    DateInput,
    Select,
};

const configureKanban = api => {
    const kanban = new Kanban(apiWrapper(api), null, null, {
        makeListSelector: makeCardsByCardStatusIdSelector,
    });
    return [kanban, components];
};

export default configureKanban;
