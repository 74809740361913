import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import AsyncAutocomplete from './AsyncAutocomplete';
import TypedAutocompleteOption from './TypedAutocompleteOption';
import TypedAutocompleteTag from './TypedAutocompleteTag';
import { RefPropType } from '../../../../modules/proptypes';
import { createResourceClassName } from '../../../../modules/api/utils';
import { nanoid } from 'nanoid';
import TypedAutocompleteSelectionComponent from './TypedAutocompleteSelectionComponent';

// TODO: what values?
const SHOW_WHEN_SCORE_ABOVE = 30;
const SCORE_MINIMUM = 10;

const sortByScore = (suggestion1, suggestion2) => suggestion2.score - suggestion1.score;

const useStyles = makeStyles({
    root: {
        '& .MuiAutocomplete-input': {
            minWidth: 200,
        },
    },
});

const defaultGetOptionLabel = option => {
    return option.value || '';
};

const isOptionEqualToValue = (option, selected) =>
    selected && option.value === selected.value && option.type === selected.type;

const TypedAutocomplete = ({
    value,
    initialValue,
    name,
    originalName,
    onChange,
    label,
    fetchAction,
    extraParams,
    extraButton,
    fullWidth,
    onInputChange,
    permitSuggestion,
    getOptionLabel,
    getOptionDisabled,
    error,
    onError,
    expectedType,
    contrast,
    multiple,
    size,
    variant,
    blurOnSelect,
    clearOnBlur,
    disableCloseOnSelect,
    innerRef,
    footer,
    filters,
    showResults,
    showAllResults,
    hideTypes,
    autofocus,
    freeSolo,
    shortcuts,
    I,
    triggerOpen,
    onSearchLabelSelect,
    forceEmptyInitialValue,
    compact,
    ...other
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const stableExtraParams = useRef(extraParams);

    const [open, setOpen] = useState(false);

    /* Dont open the popup when the value changes during initialization */
    const [interacted, setInteracted] = useState(false);

    const [inputValue, setInputValue] = useState('');

    const [autoHighlight, setAutoHighlight] = useState(false);

    useEffect(() => {
        if (initialValue || (forceEmptyInitialValue && !initialValue)) {
            setInputValue(initialValue);
        }
    }, [initialValue]);

    const handleInputChange = useCallback(
        (event, newValue) => {
            if (event) {
                setInputValue(
                    shortcuts && newValue.trim().length === 1 && shortcuts[newValue.trim()]
                        ? shortcuts[newValue.trim()]
                        : newValue
                );
            }
            onInputChange(newValue);

            if (freeSolo) {
                onChange({ value: newValue });
            }
        },
        [shortcuts, setInputValue, onInputChange, onChange, freeSolo]
    );

    const handleOpen = useCallback(() => {
        setOpen(true);
    }, [setOpen]);
    const handleClose = useCallback(
        (event, reason) => {
            setOpen(false);

            if (initialValue && reason === 'blur') {
                handleInputChange(event, initialValue);
            }
        },
        [setOpen, initialValue, handleInputChange]
    );

    useEffect(() => {
        if (inputValue && interacted) {
            setOpen(true);
        }
    }, [inputValue, setOpen, interacted]);

    useEffect(() => {
        if (triggerOpen) handleOpen();
    }, [triggerOpen]);

    const handleChange = useCallback(
        (event, newValue, reason) => {
            if (reason === 'selectOption') {
                const added = multiple
                    ? newValue.find(option => !value.includes(option))
                    : newValue;
                if (added.value === undefined) {
                    setInteracted(true);
                    setInputValue(`${added.label}: `);
                    if (onSearchLabelSelect) onSearchLabelSelect(added.label);
                } else if (expectedType && added.type !== createResourceClassName(expectedType)) {
                    setInteracted(true);
                    setInputValue(`${added.label}: ${added.value}`);
                } else {
                    setInteracted(false);
                    onChange(newValue);
                }
            } else {
                onChange(newValue, value, setInputValue, event, reason);
            }
        },
        [multiple, value, setInputValue, onChange, setInteracted, expectedType]
    );

    const handleFetch = useCallback(
        query => {
            const getParams = _query => {
                const params = { q: _query, ...stableExtraParams.current };
                const filterString = filters
                    ? `[${filters.map(filter => JSON.stringify(filter)).join(',')}]`
                    : '';
                if (filterString) {
                    params.filters = filterString;
                }
                return params;
            };

            return dispatch(fetchAction(getParams(query))).then(
                ({ data: { suggestions = [] } }) => {
                    /**
                     * the response should be processed into a flattend list of options, so that
                     * - the suggestions are grouped by type
                     * - disregard every suggestion below a certain threshold
                     * - there is at least one suggestion for the remaining types
                     * - every type should have at most X suggestions
                     * - every suggestion above a certain score should be included (ignoring the previous rule)
                     * - below the suggestions are all suggested types above a certain score
                     * - additional suggestions starting with the ones with the best score are added
                     *   while there are less than Y suggestions in total
                     */

                    const typeSuggestions = [];
                    const fulltext = {};
                    const suggestionsByName = {};
                    const permittedSuggestions = permitSuggestion
                        ? suggestions.filter(permitSuggestion)
                        : suggestions;

                    permittedSuggestions.forEach(suggestion => {
                        if (
                            suggestion.value === undefined &&
                            typeof suggestion.component !== 'string'
                        ) {
                            /* type suggestions have no value / highlight */
                            if (
                                !hideTypes &&
                                (suggestion.score >= SCORE_MINIMUM || !query.length)
                            ) {
                                typeSuggestions.push(suggestion);
                            }
                        } else if (suggestion.fulltext) {
                            fulltext[suggestion.name] = suggestion;
                        } else {
                            if (!suggestionsByName[suggestion.name]) {
                                suggestionsByName[suggestion.name] = [];
                            }
                            suggestionsByName[suggestion.name].push(suggestion);
                        }
                    });

                    /* create dictionary D with mapping: type -> suggestion (empty at first) */
                    const optionsByType = {};

                    /* insert into D the best suggestion and create list L with all remaining suggestions */
                    const scoredSuggestions = [];
                    Object.entries(suggestionsByName).forEach(([_name, suggestionsForName]) => {
                        const options = {
                            suggestions: [],
                            score: 0,
                        };

                        optionsByType[_name] = options;

                        if (fulltext[_name]) {
                            options.suggestions.push(fulltext[_name]);
                            delete fulltext[_name];
                        }

                        if (suggestionsForName.length) {
                            const [bestSuggestion, ...other] = suggestionsForName.sort(sortByScore);
                            if (bestSuggestion.score >= SCORE_MINIMUM) {
                                options.suggestions.push(bestSuggestion);
                                options.score = bestSuggestion.score;
                            } else {
                                scoredSuggestions.push(bestSuggestion);
                            }

                            other.forEach(suggestion => scoredSuggestions.push(suggestion));
                        }
                    });

                    /* append remaining typed fulltext suggestions that don't have any corresponding concrete suggestions */
                    Object.entries(fulltext).forEach(([_name, suggestion]) => {
                        if (_name !== 'fulltext') {
                            optionsByType[_name] = {
                                suggestions: [suggestion],
                                score: 0,
                            };
                        }
                    });

                    /* order list L by score */
                    scoredSuggestions.sort(sortByScore);

                    /* insert into D all suggestions with an amazing score for every type */
                    const cutoff = scoredSuggestions.findIndex(
                        ({ score }) => score <= SHOW_WHEN_SCORE_ABOVE
                    );
                    scoredSuggestions.splice(0, cutoff).forEach(suggestion => {
                        optionsByType[suggestion.name].suggestions.push(suggestion);
                    });

                    /* check how many suggestions can be added */
                    const maxResults = showAllResults ? scoredSuggestions.length : showResults;
                    const remaining = Math.max(
                        0,
                        maxResults -
                            Object.values(optionsByType).reduce(
                                (count, options) => count + options.suggestions.length,
                                0
                            ) -
                            typeSuggestions.length
                    );

                    /* add the best suggestions from L into D */
                    scoredSuggestions.slice(0, remaining).forEach(suggestion => {
                        const forType = optionsByType[suggestion.name];
                        forType.suggestions.push(suggestion);
                        forType.score = Math.max(forType.score, suggestion.score);
                    });

                    /* order types by best suggestion and flatten the list */
                    const flattend = Object.entries(optionsByType)
                        .sort(([, { score }], [, { score: score2 }]) => score2 - score)
                        .reduce((carry, [, options]) => [...carry, ...options.suggestions], []);

                    const result = [...flattend, ...typeSuggestions];

                    if (fulltext.fulltext) {
                        result.unshift(fulltext.fulltext);
                    }

                    setAutoHighlight(result.length === 1 && result[0].select);

                    return result;
                }
            );
        },
        [
            dispatch,
            fetchAction,
            filters,
            showResults,
            showAllResults,
            hideTypes,
            stableExtraParams,
            permitSuggestion,
        ]
    );

    const renderOption = useCallback(
        (props, option) => {
            return (
                <li {...props} style={{ width: '100%' }} key={`${option.id}_${nanoid()}`}>
                    <TypedAutocompleteOption option={option} hideTypes={hideTypes} />
                </li>
            );
        },
        [hideTypes]
    );

    return (
        <TypedAutocompleteSelectionComponent value={value} onChange={handleChange}>
            {({ onChange }) => (
                <AsyncAutocomplete
                    className={classes.root}
                    threshold={0}
                    value={value}
                    name={name}
                    originalName={originalName}
                    label={label}
                    fetch={handleFetch}
                    onChange={onChange}
                    inputValue={inputValue}
                    onInputChange={handleInputChange}
                    renderOption={renderOption}
                    isOptionEqualToValue={isOptionEqualToValue}
                    getOptionLabel={getOptionLabel || defaultGetOptionLabel}
                    getOptionDisabled={getOptionDisabled}
                    renderTags={(options, getTagProps) =>
                        options.map((option, index) => (
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            <TypedAutocompleteTag
                                option={option}
                                size={compact ? 'small' : 'medium'}
                                {...getTagProps({ index })}
                            />
                        ))
                    }
                    filterSelectedOptions
                    multiple={multiple}
                    fullWidth={fullWidth}
                    contrast={contrast}
                    size={size}
                    variant={variant}
                    blurOnSelect={blurOnSelect}
                    clearOnBlur={clearOnBlur}
                    disableCloseOnSelect={disableCloseOnSelect}
                    innerRef={innerRef}
                    footer={footer}
                    extraButton={extraButton}
                    error={error}
                    handleError={onError}
                    open={open}
                    onOpen={handleOpen}
                    onClose={handleClose}
                    autofocus={autofocus}
                    freeSolo={freeSolo}
                    can={I}
                    autoHighlight={autoHighlight}
                    {...other}
                />
            )}
        </TypedAutocompleteSelectionComponent>
    );
};

TypedAutocomplete.propTypes = {
    value: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.arrayOf(PropTypes.shape({}))]),
    initialValue: PropTypes.oneOfType([
        PropTypes.shape({}),
        PropTypes.arrayOf(PropTypes.shape({})),
    ]),
    name: PropTypes.string,
    originalName: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    fetchAction: PropTypes.func.isRequired,
    extraParams: PropTypes.shape({}),
    extraButton: PropTypes.node,
    fullWidth: PropTypes.bool,
    error: PropTypes.string,
    onError: PropTypes.func,
    contrast: PropTypes.bool,
    onInputChange: PropTypes.func,
    permitSuggestion: PropTypes.func,
    getOptionLabel: PropTypes.func,
    getOptionDisabled: PropTypes.func,
    size: PropTypes.string,
    variant: PropTypes.string,
    expectedType: PropTypes.string,
    multiple: PropTypes.bool,
    blurOnSelect: PropTypes.bool,
    clearOnBlur: PropTypes.bool,
    disableCloseOnSelect: PropTypes.bool,
    innerRef: RefPropType,
    footer: PropTypes.node,
    filters: PropTypes.arrayOf(PropTypes.shape({})),
    showResults: PropTypes.number,
    showAllResults: PropTypes.bool,
    hideTypes: PropTypes.bool,
    freeSolo: PropTypes.bool,
    autofocus: PropTypes.bool,
    shortcuts: PropTypes.shape({}),
    triggerOpen: PropTypes.bool,
    onSearchLabelSelect: PropTypes.func,
    forceEmptyInitialValue: PropTypes.bool,
    I: PropTypes.string,
    compact: PropTypes.bool,
};

TypedAutocomplete.defaultProps = {
    value: null,
    initialValue: null,
    name: null,
    originalName: null,
    label: null,
    extraParams: null,
    extraButton: null,
    fullWidth: false,
    error: null,
    onError: null,
    contrast: false,
    onInputChange: () => null,
    permitSuggestion: null,
    getOptionLabel: null,
    getOptionDisabled: option => option.disabled,
    size: 'medium',
    variant: 'outlined',
    expectedType: null,
    multiple: false,
    blurOnSelect: false,
    clearOnBlur: false,
    disableCloseOnSelect: false,
    innerRef: undefined,
    footer: null,
    filters: null,
    showResults: 60,
    showAllResults: false,
    hideTypes: false,
    freeSolo: false,
    autofocus: false,
    shortcuts: null,
    triggerOpen: false,
    onSearchLabelSelect: () => null,
    forceEmptyInitialValue: false,
    I: null,
    compact: false,
};

export default TypedAutocomplete;
