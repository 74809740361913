import React from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import { IdPropType } from '../../../../modules/proptypes';
import ChildContactsTable from '../../ChildContactsTable';
import Button from '../../../form/base/Button';
import { CONTACT_RESOURCE } from '../../../../modules/api/resources';
import { useCallbackRef } from '../../../../modules/hooks';
import { useDialog } from '../../../dialogs/DialogContext';

const ChildContactsPage = ({ contactId, contextCardId }) => {
    const { t } = useTranslation();
    const switchRef = useCallbackRef();
    const { openContactDialog } = useDialog();

    const handleCreatePerson = () => {
        openContactDialog({ contextCardId, contextParentId: contactId, edit: true });
    };

    return (
        <Grid container alignItems="center" spacing={2}>
            <Grid item xs>
                <Typography variant="h2" color="primary">
                    {t('components.Person.name')}
                </Typography>
            </Grid>
            <Grid item>
                <div ref={switchRef} />
            </Grid>
            <Grid item>
                <Button
                    subject={CONTACT_RESOURCE}
                    onClick={handleCreatePerson}
                    color="primary"
                    variant="contained"
                >
                    {t('components.ContactDialog.addPerson')}
                </Button>
            </Grid>
            <Grid item xs={12}>
                <ChildContactsTable contactId={contactId} switchRef={switchRef} />
            </Grid>
        </Grid>
    );
};

ChildContactsPage.propTypes = {
    contactId: IdPropType.isRequired,
    contextCardId: IdPropType,
};

ChildContactsPage.defaultProps = {
    contextCardId: null,
};

export default ChildContactsPage;
