import Persistor from '../persistor/persistor';

export const getCalendarResourceVisibilityKey = (resourceType, calendarId) =>
    `CalendarGroupVisibility.${calendarId}.${resourceType}`;

export const getCalendarResourceVisibilityIds = (resourceType, calendarId) => {
    const persistorKey = getCalendarResourceVisibilityKey(resourceType, calendarId);
    const disabledIds = Persistor.get(persistorKey, []);
    return {
        disabledIds,
        persistorKey,
    };
};

export const toggleCalendarResourceVisibilityForId = (id, resourceType, calendarId) => {
    const { disabledIds: currentDisabledIds, persistorKey } = getCalendarResourceVisibilityIds(
        resourceType,
        calendarId
    );

    const currentVisible = !currentDisabledIds.includes(id);
    const visible = !currentVisible;

    const disabledIds = visible
        ? currentDisabledIds.filter(disabledId => disabledId !== id)
        : [...currentDisabledIds, id];

    Persistor.set(persistorKey, disabledIds);

    return {
        visible,
        disabledIds,
    };
};
