import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IdPropType } from '../../modules/proptypes';
import { CALENDAR_RESOURCE_MODELS, formatFlatResourceKey } from '../../modules/calendar/utils';
import { EMPLOYEE_RESOURCE, RESOURCE_RESOURCE } from '../../modules/api/resources';
import { selectAppointmentById } from '../../modules/appointments/appointmentSlice';
import {
    formatAppointmentImport,
    formatAppointmentTypeResourceGroups,
} from '../../modules/appointments/utils';
import { useAppointmentType } from '../../modules/appointmentTypes/appointmentTypeSlice';
import ItemStack from '../icons/ItemStack';
import CalendarDetailResource from '../calendar/detail/CalendarDetailResource';

const AppointmentResourcesPreview = ({ appointmentId }) => {
    const appointment = useSelector(state => selectAppointmentById(state, appointmentId));
    const { baseGroups, appointmentTypeId } = useMemo(
        () => (appointment?.id ? formatAppointmentImport(appointment) : {}),
        [appointment]
    );
    const [appointmentType] = useAppointmentType(appointmentTypeId); // { loading: appointmentTypeLoading }

    const { groupKeys, groupConfig } = useMemo(
        () => formatAppointmentTypeResourceGroups(appointmentType),
        [appointmentType]
    );

    const { employees, resources } = useMemo(() => {
        const employeeModel = CALENDAR_RESOURCE_MODELS[EMPLOYEE_RESOURCE];
        const resourceModel = CALENDAR_RESOURCE_MODELS[RESOURCE_RESOURCE];

        return {
            employees: baseGroups?.[employeeModel] || {},
            resources: baseGroups?.[resourceModel] || {},
        };
    }, [baseGroups]);

    const avatarList = useMemo(
        () =>
            groupKeys.reduce((carry, groupKey) => {
                const config = groupConfig[groupKey];

                const processIds = rawIds => {
                    if (rawIds) {
                        const ids = Array.isArray(rawIds) ? rawIds : [rawIds];

                        return ids.map(id => {
                            const flatKey = formatFlatResourceKey(id, config.api).key;

                            return {
                                key: flatKey,
                                component: (
                                    <CalendarDetailResource
                                        flatKey={flatKey}
                                        groupConfig={config}
                                        autoLoad
                                        tiny
                                    />
                                ),
                            };
                        });
                    }

                    return [];
                };

                const getResources = () => {
                    if (config.api === EMPLOYEE_RESOURCE) {
                        return processIds(employees[groupKey]);
                    }

                    if (config.api === RESOURCE_RESOURCE) {
                        return processIds(resources[groupKey]);
                    }

                    return [];
                };

                const stackable = getResources();

                return [...carry, ...stackable];
            }, []),
        [groupKeys, groupConfig]
    );

    return <ItemStack items={avatarList} itemSize={24} />;
};

AppointmentResourcesPreview.propTypes = {
    appointmentId: IdPropType.isRequired,
};

export default AppointmentResourcesPreview;
