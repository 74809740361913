import React, { useEffect, useMemo, useState } from 'react';
import { OpenInNew } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Portal from '@mui/material/Portal';
import ResourceTableClassic from '../table/resource/ResourceTableClassic';
import { CONTACT_RESOURCE } from '../../modules/api/resources';
import { IdPropType, RefPropType } from '../../modules/proptypes';
import ContactLabel from './ContactLabel';
import { useDialog } from '../dialogs/DialogContext';
import Button from '../form/base/Button';
import IconButton from '../form/base/IconButton';
import BaseSwitch from '../form/base/BaseSwitch';
import { indexPersonFunctions } from '../../modules/personFunction/actions';
import { useDispatch } from 'react-redux';
import PersonFunction from '../person/PersonFunction';
import { Typography } from '@mui/material';

const ChildContactsTable = ({ contactId, switchRef }) => {
    const { t } = useTranslation();
    const { openContactDialog } = useDialog();
    const [showArchived, setShowArchived] = useState(false);
    const dispatch = useDispatch();

    const columns = [
        {
            key: 'display_name',
            component: ({ data }) => <ContactLabel data={data} accessor="display_name" />,
        },
        {
            key: 'phone',
            label: t('components.CardContacts.phone'),
            component: ({ data }) => <ContactLabel data={data} accessor="phone" />,
            sortable: false,
        },
        {
            key: 'email',
            label: t('components.CardContacts.email'),
            component: ({ data }) => <ContactLabel data={data} accessor="email" />,
            sortable: false,
        },
        {
            key: 'personFunction.name',
            component: ({ data }) => <PersonFunction personFunctionId={data.person_function_id} />,
        },
        {
            key: 'department',
            component: ({ data }) => <Typography variant="inherit">{data.department}</Typography>,
        },
    ];

    const params = useMemo(
        () => ({ parent_id: contactId, withTrashed: showArchived }),
        [contactId, showArchived]
    );

    const actions = useMemo(
        () => [
            {
                key: 'show',
                action: ({ data }) => (
                    <Button
                        onClick={() => openContactDialog({ contactId: data.id })}
                        data-test-id="ShowButton"
                        disabled={!!data.deleted_at}
                        subject={CONTACT_RESOURCE}
                    >
                        {t('components.Contacts.showContact')}
                    </Button>
                ),
                compact: ({ data }) => (
                    <IconButton
                        onClick={() => openContactDialog({ contactId: data.id })}
                        data-test-id="ShowButton"
                        disabled={!!data.deleted_at}
                        subject={CONTACT_RESOURCE}
                        label={t('components.Contacts.showContact')}
                        size="small"
                    >
                        <OpenInNew />
                    </IconButton>
                ),
            },
        ],
        [openContactDialog, t]
    );

    useEffect(() => {
        dispatch(indexPersonFunctions());
    }, [dispatch]);

    return (
        <>
            <Box textAlign="right">
                {switchRef ? (
                    <Portal container={switchRef.current}>
                        <BaseSwitch
                            name="showArchived"
                            label={t('form.ContactSearch.showArchived')}
                            value={showArchived}
                            onChange={event => setShowArchived(event.target.checked)}
                        />
                    </Portal>
                ) : (
                    <BaseSwitch
                        name="showArchived"
                        label={t('form.ContactSearch.showArchived')}
                        value={showArchived}
                        onChange={event => setShowArchived(event.target.checked)}
                    />
                )}
            </Box>
            <ResourceTableClassic
                resource={CONTACT_RESOURCE}
                listId="ChildContactsTable"
                fetchParams={params}
                columns={columns}
                actions={actions}
                showActionsInline={1}
                autoload
                index
            />
        </>
    );
};

ChildContactsTable.propTypes = {
    contactId: IdPropType.isRequired,
    switchRef: RefPropType,
};

ChildContactsTable.defaultProps = {
    switchRef: null,
};

export default ChildContactsTable;
