import React from 'react';
import * as PropTypes from 'prop-types';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import { useTranslation } from 'react-i18next';
import Confirmation from '../form/Confirmation';
import IconButton from '../form/base/IconButton';
import { UPDATE } from '../../modules/abilities/actions';
import { SubjectPropType } from '../../modules/abilities/proptypes';

const ArchiveIconButton = ({ onClick, size, contrast, confirmLabel, disabled, subject }) => {
    const { t } = useTranslation();

    return (
        <Confirmation
            popupId="ArchiveIconButton"
            label={confirmLabel || 'Archivieren?'}
            onConfirm={onClick}
        >
            <IconButton
                size={size}
                contrast={contrast}
                disabled={disabled}
                subject={subject}
                action={UPDATE}
                field="deleted_at"
                data-test-id="ArchiveIconButton"
                label={t('components.ResourceTableRowDetails.archive')}
            >
                <ArchiveOutlinedIcon />
            </IconButton>
        </Confirmation>
    );
};

ArchiveIconButton.propTypes = {
    confirmLabel: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    size: PropTypes.string,
    contrast: PropTypes.bool,
    disabled: PropTypes.bool,
    subject: SubjectPropType,
};

ArchiveIconButton.defaultProps = {
    confirmLabel: null,
    size: null,
    contrast: false,
    disabled: false,
    subject: null,
};

export default ArchiveIconButton;
