import React from 'react';
import * as PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CompanyContactsAutocomplete from '../form/formik/autocomplete/CompanyContactsAutocomplete';
import { useFieldFast } from '../../modules/form/hooks';
import CompanyPreview from './CompanyPreview';
import UnlinkButton from '../buttons/UnlinkButton';

const useStyles = makeStyles(theme => ({
    root: {
        paddingBottom: theme.spacing(2),
    },

    company: {
        paddingBottom: theme.spacing(3),
    },

    name: {
        fontWeight: 'bold',
    },
}));

const CompanyInput = ({ name }) => {
    const classes = useStyles();
    const [{ value }, , { setValue }] = useFieldFast(name);

    const handleAutocompleteChange = selected => {
        setValue(selected.id);
    };

    const handleDelete = () => {
        setValue(null);
    };

    return (
        <Box className={classes.root}>
            {value ? (
                <>
                    <CompanyPreview contactId={value} />
                    <Box textAlign="center" marginTop={2}>
                        <UnlinkButton onClick={handleDelete} color="primary" />
                    </Box>
                </>
            ) : (
                <span data-test-id="company-autocomplete-input">
                    <CompanyContactsAutocomplete
                        name={name}
                        onChange={handleAutocompleteChange}
                        className={classes.company}
                        size="small"
                        fullWidth
                    />
                </span>
            )}
        </Box>
    );
};

CompanyInput.propTypes = {
    name: PropTypes.string.isRequired,
};

CompanyInput.defaultProps = {};

export default CompanyInput;
