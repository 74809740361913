import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { getContext } from '@redux-saga/core/effects';
import { CARD_RESOURCE, DATACONTAINER_RESOURCE } from '../api/resources';
import { selectCardById, selectCardListByCardStatusId, selectCardSearch } from './selectors';
import { cardListPage } from './actions';
import {
    indexFulfilled as datacontainerIndexFulfilled,
    showFulfilled as datacontainerShowFulfilled,
} from '../datacontainers/datacontainersSlice';
import { indexContacts } from '../contacts/actions';
import { CARD_CONTEXTS } from '../contacts/contexts';

function* refreshCard(action) {
    if (!action.payload.id) {
        console.error('refreshCard action payload is missing an id!', action);
        return;
    }

    const api = yield getContext('api');

    const search = yield select(selectCardSearch);

    yield api[CARD_RESOURCE].search(
        {
            id: action.payload.id,
            contexts: action.meta.contexts,
            ...search,
            filters: {
                $and: [
                    ...((search.filters && search.filters.$and) || []),
                    { name: '_id', id: action.payload.id, by_id: true },
                ],
            },
        },
        { socketReaction: true, staleModel: action.meta.staleModel }
    );
}

function* reloadLastPageIfSocketEventRemovesCard(action) {
    let card = null;

    if (action.meta?.socketReaction) {
        // card is filtered
        if (!action.payload.length && action.meta.params.id) {
            card = yield select(selectCardById, action.meta.params.id);
        }

        // card is destroyed
        if (action.payload && action.meta.destroyedEntity) {
            card = action.meta.destroyedEntity;
        }
    }

    if (card && card.card_status_id) {
        const list = yield select(selectCardListByCardStatusId, card.card_status_id);

        try {
            yield put(cardListPage(card.card_status_id, list.current_page));
        } catch (e) {}
    }
}

function* nestedIndexFulfilled(action) {
    const payload = action.payload.reduce(
        (carry, { datacontainer, datacontainer2 }) => {
            if (datacontainer) {
                carry.datacontainers.push(datacontainer);
            }
            if (datacontainer2) {
                carry.datacontainers.push(datacontainer2);
            }
            return carry;
        },
        { datacontainers: [] }
    );

    yield put(datacontainerIndexFulfilled(payload.datacontainers, { key: DATACONTAINER_RESOURCE }));

    // TODO socket.js index zu show anpassen und dann z.B. an dieser Stelle anpassen
    if (
        action.payload.length === 1 &&
        action.meta?.staleModel &&
        action.meta?.staleModel.id === action.payload[0].id
    ) {
        const datacontainerId =
            action.payload[0].datacontainer && action.payload[0].datacontainer.id;
        const datacontainer2Id =
            action.payload[0].datacontainer2 && action.payload[0].datacontainer2.id;

        if (
            action.meta.staleModel.datacontainer_id !== datacontainerId &&
            action.meta.staleModel.datacontainer_id !== datacontainer2Id
        ) {
            yield put(
                indexContacts({
                    limit: 250, // TODO: handle more than 250 contacts?
                    card_id: action.meta.staleModel.id,
                    contexts: CARD_CONTEXTS,
                })
            );
        }
    }
}

function* nestedShowFulfilled(action) {
    const { datacontainer, datacontainer2 } = action.payload;

    if (datacontainer && datacontainer2) {
        yield all([
            put(datacontainerShowFulfilled(datacontainer, { key: DATACONTAINER_RESOURCE })),
            put(datacontainerShowFulfilled(datacontainer2, { key: DATACONTAINER_RESOURCE })),
        ]);
    } else if (datacontainer) {
        yield put(datacontainerShowFulfilled(datacontainer, { key: DATACONTAINER_RESOURCE }));
    } else if (datacontainer2) {
        yield put(datacontainerShowFulfilled(datacontainer2, { key: DATACONTAINER_RESOURCE }));
    }
}

export default [
    takeEvery(`${CARD_RESOURCE}/storeSocket`, refreshCard),
    takeEvery(`${CARD_RESOURCE}/updateSocket`, refreshCard),

    takeLatest(`${CARD_RESOURCE}/indexFulfilled`, reloadLastPageIfSocketEventRemovesCard),
    takeLatest(`${CARD_RESOURCE}/destroyFulfilled`, reloadLastPageIfSocketEventRemovesCard),
    takeEvery(`${CARD_RESOURCE}/indexFulfilled`, nestedIndexFulfilled),
    takeEvery(`${CARD_RESOURCE}/searchFulfilled`, nestedIndexFulfilled),
    takeEvery(`${CARD_RESOURCE}/showFulfilled`, nestedShowFulfilled),
];
