import { addMilliseconds, format, parse } from 'date-fns';

export function getTimestampFromString(timeString) {
    return (
        parse(`02/01/2000 ${timeString}`, 'dd/MM/yyyy HH:mm', new Date()).getTime() -
        parse(`02/01/2000 00:00`, 'dd/MM/yyyy HH:mm', new Date()).getTime()
    );
}

export function getDays(dayMap) {
    const days = [];
    for (const key in dayMap) {
        days.push({ id: key, name: dayMap[key] });
    }
    return days;
}

export function getShifts(blockMap) {
    const shifts = [];
    for (const key in blockMap) {
        shifts.push({ id: key, title: blockMap[key], subTitle: 'von-bis' });
    }
    return shifts;
}

export function checkValidTime(schedules, newTime, newKey) {
    const startRegex = new RegExp(`${newKey.split('_')[0]}_.+_start`);
    const newKeyRegex = new RegExp(`${newKey.split('_', 2).join('_')}_.+`);
    const startSchedule = {};
    const endSchedule = {};
    const newTimestamp = getTimestampFromString(newTime);
    let startTimestamp, endTimestamp;
    for (const key in schedules) {
        if (startRegex.test(key)) {
            startSchedule[key] = schedules[key];
            if (schedules[key.replace('start', 'end')]) {
                endSchedule[key.replace('start', 'end')] = schedules[key.replace('start', 'end')];
            }
        }
    }
    for (const key in startSchedule) {
        if (newKeyRegex.test(key)) continue;
        if (!endSchedule[key.replace('start', 'end')]) continue;
        startTimestamp = getTimestampFromString(startSchedule[key]);
        endTimestamp = getTimestampFromString(endSchedule[key.replace('start', 'end')]);
        if (startTimestamp < newTimestamp && newTimestamp < endTimestamp) return false;
    }
    if (
        !startRegex.test(newKey) &&
        newTimestamp < getTimestampFromString(schedules[newKey.replace('end', 'start')])
    ) {
        return false;
    }

    if (
        startRegex.test(newKey) &&
        schedules[newKey.replace('start', 'end')] &&
        newTimestamp > getTimestampFromString(schedules[newKey.replace('start', 'end')])
    ) {
        return false;
    }

    return true;
}

export function transformWorktimesToTransportFormat(formFormat) {
    const r = [];

    Object.keys(formFormat)
        .filter(key => {
            return key.indexOf('_start') !== -1;
        })
        .forEach(key => {
            const [day, block] = key.split('_', 3);

            const start = formFormat[key] ?? null;
            const end = formFormat[`${day}_${block}_end`] ?? null;

            if (start === null || end === null) {
                return;
            }

            const startSeconds = secondsFromString(start);
            const endSeconds = secondsFromString(end);

            if (startSeconds === null || endSeconds === null) {
                return;
            }

            r.push({
                day,
                block,
                start_seconds: startSeconds,
                end_seconds: endSeconds,
            });
        });

    return r;
}

export function formatWorkTimes(workTimes) {
    const formatedWorkTimes = {};
    for (const wt of workTimes) {
        formatedWorkTimes[`${wt.day}_${wt.block}_start`] = getWorkTimeValueFromTimestamp(
            wt.start_seconds
        );
        formatedWorkTimes[`${wt.day}_${wt.block}_end`] = getWorkTimeValueFromTimestamp(
            wt.end_seconds
        );
    }
    return formatedWorkTimes;
}

function getWorkTimeValueFromTimestamp(timestamp) {
    return format(addMilliseconds(new Date('01/02/2000 00:00'), timestamp * 1000), 'HH:mm');
}

export function secondsFromString(string) {
    if (!string.includes(':')) {
        return null;
    }

    const [hours, minutes] = string.split(':');

    return parseInt(hours, 10) * 3600 + parseInt(minutes, 10) * 60;
}
