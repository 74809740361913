/* these normally come from the slice */
import { CONTRACT_RESOURCE } from '../api/resources';

export const indexContracts = params => (dispatch, getState, api) =>
    api[CONTRACT_RESOURCE].index(params);

export const showContract = params => (dispatch, getState, api) =>
    api[CONTRACT_RESOURCE].show(params);

export const storeContract = params => (dispatch, getState, api) =>
    api[CONTRACT_RESOURCE].store(params);

export const updateContract = params => (dispatch, getState, api) =>
    api[CONTRACT_RESOURCE].update(params);

export const destroyContract = params => (dispatch, getState, api) =>
    api[CONTRACT_RESOURCE].destroy(params);
