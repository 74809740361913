import { ADDRESS_TYPE_RESOURCE } from '../api/resources';

export const indexAddressTypes = params => (dispatch, getState, api) =>
    api[ADDRESS_TYPE_RESOURCE].index(params);

export const storeAddressType = person => (dispatch, getState, api) =>
    api[ADDRESS_TYPE_RESOURCE].store(person);

export const updateAddressType = person => (dispatch, getState, api) =>
    api[ADDRESS_TYPE_RESOURCE].update(person);

export const destroyAddressType = params => (dispatch, getState, api) =>
    api[ADDRESS_TYPE_RESOURCE].destroy(params);
