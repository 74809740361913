import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import MuiCheckbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Box, Tooltip } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { useContextualCan } from '../../../modules/abilities/hooks';
import { WRITE } from '../../../modules/abilities/actions';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },

    center: {
        alignItems: 'center',
        justifyContent: 'center',
    },

    label: {
        marginBottom: 0,
    },

    noLabel: {
        marginRight: 0,
    },

    errorTooltip: {
        backgroundColor: theme.palette.error.main,
    },

    checkbox: {
        padding: 8,
        paddingLeft: 8,
    },

    checkboxContrast: {
        color: 'white',
        padding: 8,
        paddingLeft: 26,
    },

    error: {
        color: theme.palette.error.main,

        '&.Mui-checked': {
            color: theme.palette.error.main,
        },
    },

    small: {
        '&> .MuiFormControlLabel-label': {
            fontSize: '0.7rem',
        },
    },
}));

const BaseCheckbox = ({
    name,
    originalName,
    label,
    labelTooltip,
    value,
    error,
    onChange,
    disabled,
    contrast,
    size,
    center,
    color,
    I,
    ...other
}) => {
    const classes = useStyles();
    const canDo = useContextualCan(I ? I : WRITE, originalName || name);

    const checkbox = useMemo(
        () => (
            <MuiCheckbox
                checked={value}
                onChange={onChange}
                name={name}
                className={classNames({
                    [classes.checkboxContrast]: contrast,
                    [classes.checkbox]: !contrast,
                    [classes.error]: error,
                })}
                disabled={disabled || !canDo}
                color={color}
                {...other}
            />
        ),
        [value, onChange, name, contrast, classes, disabled, canDo, error, other]
    );

    return (
        <Box
            className={classNames({
                [classes.root]: true,
                [classes.center]: center || labelTooltip,
            })}
        >
            {labelTooltip && label ? (
                <Tooltip
                    title={error ? `${label} - ${error}` : label}
                    componentsProps={{
                        tooltip: { className: error ? classes.errorTooltip : null },
                    }}
                >
                    {checkbox}
                </Tooltip>
            ) : (
                <FormControlLabel
                    control={checkbox}
                    data-test-id={`${name}BaseCheckbox`}
                    value={value}
                    label={label}
                    className={classNames({
                        [classes.noLabel]: !label,
                        [classes.label]: true,
                        [classes.small]: size === 'small',
                    })}
                />
            )}
            {error && !labelTooltip && <FormHelperText error>{error}</FormHelperText>}
        </Box>
    );
};

BaseCheckbox.propTypes = {
    value: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    originalName: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    labelTooltip: PropTypes.bool,
    onChange: PropTypes.func,
    error: PropTypes.string,
    disabled: PropTypes.bool,
    contrast: PropTypes.bool,
    size: PropTypes.string,
    center: PropTypes.bool,
    color: PropTypes.string,
    I: PropTypes.string,
};

BaseCheckbox.defaultProps = {
    originalName: null,
    label: null,
    labelTooltip: false,
    onChange: null,
    error: null,
    disabled: false,
    contrast: false,
    size: 'default',
    center: false,
    color: 'default',
    I: null,
};

export default BaseCheckbox;
