import React, { memo, useCallback, useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useResourceList } from '../../modules/lists/hooks';
import { useSelector } from 'react-redux';
import { CALENDAR_RESOURCE_MODELS } from '../../modules/calendar/utils';

const CalendarResourceLoader = ({ listId, type, params: fetchParams, onChange, onLoad }) => {
    const [gotAll, setGotAll] = useState(false);

    const { fullSelector, loading, initialized, handlePage } = useResourceList({
        index: true,
        listId,
        resource: Object.entries(CALENDAR_RESOURCE_MODELS).reduce(
            (carry, [resource, model]) => (model === type ? resource : carry),
            null
        ),
        fetchParams,
        continuous: true,
    });

    const byId = useSelector(fullSelector);
    const list = useMemo(() => Object.values(byId), [byId]);

    const checkNextPage = useCallback(
        ({ meta: { current_page: currentPage, last_page: lastPage } }) => {
            const nextPage = currentPage + 1;
            if (lastPage >= nextPage) {
                return handlePage(null, nextPage).then(checkNextPage);
            }

            setGotAll(true);
            return false;
        },
        [handlePage, setGotAll]
    );

    useEffect(() => {
        if (gotAll) {
            onChange(list);
        }
    }, [gotAll, list, onChange]);

    useEffect(() => {
        onLoad(loading);
    }, [loading, onLoad]);

    useEffect(() => {
        if (!initialized) {
            handlePage(null, 1, true).then(checkNextPage);
        }
    }, [initialized, handlePage]);

    return null;
};

CalendarResourceLoader.propTypes = {
    listId: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    api: PropTypes.string.isRequired,
    params: PropTypes.shape({}),
    onLoad: PropTypes.func,
};

CalendarResourceLoader.defaultProps = {
    params: null,
    onLoad: () => null,
};

export default memo(CalendarResourceLoader);
