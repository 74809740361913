import { createSelector } from '@reduxjs/toolkit';

import { PROTOCOL_ITEM_RESOURCE } from '../api/resources';

export const selectProtocolItemsIsLoading = state => state[PROTOCOL_ITEM_RESOURCE].loading;

export const selectProtocolItemsInitialized = state => state[PROTOCOL_ITEM_RESOURCE].initialized;

export const selectProtocolItemsError = state => state[PROTOCOL_ITEM_RESOURCE].error;

export const selectProtocolItemsById = state => state[PROTOCOL_ITEM_RESOURCE].byId;

export const selectProtocolItemById = (state, id) => selectProtocolItemsById(state)[id];

export const selectAllProtocolItemsIds = state => state[PROTOCOL_ITEM_RESOURCE].allIds;

export const selectCardProtocolItemsIds = (state, cardId) =>
    state[PROTOCOL_ITEM_RESOURCE].byCard[cardId];

export const selectFilteredCardProtocolItems = createSelector(
    (state, cardId) =>
        (state[PROTOCOL_ITEM_RESOURCE].byCard[cardId] || []).map(id =>
            selectProtocolItemById(state, id)
        ),
    (state, cardId, filter) => filter,
    (protocolItems, { pinned, cardStatusId, protocolItemTypeId }) =>
        protocolItems
            .filter(
                item =>
                    !(
                        (pinned && !item.pinned) ||
                        (cardStatusId && item.card_status_id !== cardStatusId) ||
                        (protocolItemTypeId && item.protocol_item_type_id !== protocolItemTypeId)
                    )
            )
            .map(item => item.id)
);
