import React, { useEffect, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isAfter, parseISO } from 'date-fns';
import { makeStyles } from '@mui/styles';

import Form from '../form/formik/Form';
import TextInput from '../form/formik/TextInput';
import UserAutocomplete from '../form/formik/autocomplete/UserAutocomplete';
import DateInput from '../form/formik/DateInput';
import { selectChecklistItemById } from '../../modules/checklistItems/selectors';
import {
    archiveChecklistItem,
    storeChecklistItem,
    updateChecklistItem,
} from '../../modules/checklistItems/actions';
import { IdPropType } from '../../modules/proptypes';
import Confirmation from '../form/Confirmation';
import LoadingIcon from '../loading/LoadingIcon';
import AutoSave from '../form/formik/AutoSave';
import { CHECKLIST_ITEM_RESOURCE } from '../../modules/api/resources';
import { checklistItemSchema } from '../../modules/checklistItems/schema';
import LockButton from '../form/base/LockButton';
import { useTask } from '../../modules/tasks/tasksSlice';
import OptionalFormikContainer from '../form/formik/OptionalFormikContainer';
import {
    assembleLocal,
    convertLocalDateToUTC,
    convertUTCtoLocalDate,
} from '../../modules/datetime/utils';
import { useInitialValues } from '../../modules/form/hooks';
import { DELETE, WRITE } from '../../modules/abilities/actions';
import ContextualCan from '../../modules/abilities/ContextualCan';

const useStyles = makeStyles({
    inputHelper: {
        position: 'absolute',
        bottom: '-1.5em',
    },
});

const ChecklistItemForm = ({ itemId, checklistId, taskId, onEdit, newSortIndex, can }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [dateWarnings, setDateWarnings] = useState([]);
    const item = useSelector(state => selectChecklistItemById(state, itemId));
    const [task] = useTask(taskId);
    const [deleted, setDeleted] = useState(false);

    const textRef = useRef(null);
    useEffect(() => {
        if (textRef.current) {
            textRef.current.focus();
        }
    }, [loading]);

    const initialValues = useInitialValues(
        { ...item, due_at: item && item.due_at ? convertUTCtoLocalDate(item.due_at) : null },
        {
            id: null,
            checked_at: null,
            text: ' ',
            due_at: null,
            sort: newSortIndex,
            due_locked: false,
            checklist_id: checklistId,
            responsibles: [],
        }
    );

    const handleDateChange = date => {
        setDateWarnings([]);
        if (isAfter(date, parseISO(task.due_at))) {
            setDateWarnings([...dateWarnings, t('components.Checklist.dueTooLate')]);
        }
    };

    const hasChanged = values => JSON.stringify(initialValues) !== JSON.stringify(values);

    const handleSubmit = (values, { resetForm }) => {
        if (!deleted && hasChanged(values)) {
            setLoading(true);

            const arResponsibles = Array.isArray(values.responsibles)
                ? values.responsibles
                : [values.responsibles];

            const prepped = {
                ...values,
                due_at:
                    values.due_at && convertLocalDateToUTC(assembleLocal(values.due_at, '23:59')),
                responsibles: values.responsibles ? arResponsibles : [],
            };

            if (values.id) {
                return dispatch(updateChecklistItem(prepped)).then(() => {
                    setLoading(false);
                    onEdit(values.id, false);
                });
            }
            return dispatch(storeChecklistItem(prepped)).then(() => {
                setLoading(false);
                resetForm();
            });
        }

        onEdit(null, false);
        return null;
    };

    const handleDeleteIntent = () => {
        if (!itemId) {
            onEdit(null, false);
        }
    };

    const handleDeleteConfirmed = () => {
        setLoading(true);
        setDeleted(true);
        dispatch(archiveChecklistItem({ id: itemId, checklistId })).then(() => {
            onEdit(itemId, false);
            setLoading(false);
        });
    };

    return (
        <Form
            initialValues={initialValues}
            onSubmit={handleSubmit}
            subject={itemId ? item : CHECKLIST_ITEM_RESOURCE}
            validationSchema={checklistItemSchema}
        >
            <Grid container spacing={2} alignItems="center" data-test-id="checklist-item-form">
                <Grid item xs={3}>
                    <TextInput
                        name="text"
                        data-test-id="checklist-item-input-text"
                        label={t('components.Checklist.text')}
                        inputRef={textRef}
                        size="small"
                        FormHelperTextProps={{
                            className: classes.inputHelper,
                        }}
                        fullWidth
                        can={can}
                    />
                </Grid>
                <Grid item xs={3} data-test-id="select-user-autocomplete">
                    <UserAutocomplete
                        name="responsibles"
                        label={t('components.Checklist.responsible')}
                        fullWidth
                        I={can}
                    />
                </Grid>
                <OptionalFormikContainer
                    name="due_at"
                    propNode={formikCondition => (
                        <Grid item xs={formikCondition ? 3 : 2}>
                            <DateInput
                                name="due_at"
                                label={t('components.Checklist.due')}
                                warnings={dateWarnings}
                                onChange={handleDateChange}
                                fullWidth
                                can={can}
                            />
                        </Grid>
                    )}
                />
                <OptionalFormikContainer
                    name="due_at"
                    propNode={formikCondition =>
                        formikCondition ? null : (
                            <Grid item>
                                <LockButton can={can} name="due_locked" />
                            </Grid>
                        )
                    }
                />
                <Grid item>
                    <ContextualCan I={DELETE}>
                        <Confirmation
                            popupId="deleteChecklistItemPopover"
                            onConfirm={handleDeleteConfirmed}
                            label={t('components.DestroyIntent.confirm')}
                            disabled={!itemId}
                            color="danger"
                        >
                            <LoadingIcon isLoading={loading} size="small">
                                <IconButton size="small" onClick={handleDeleteIntent}>
                                    <DeleteForeverOutlinedIcon />
                                </IconButton>
                            </LoadingIcon>
                        </Confirmation>
                    </ContextualCan>
                </Grid>
                <Grid data-test-id="save-checklist-item-btn" item>
                    <LoadingIcon isLoading={loading} size="small">
                        <IconButton size="small" type="submit">
                            <SaveOutlinedIcon />
                        </IconButton>
                    </LoadingIcon>
                </Grid>
            </Grid>
        </Form>
    );
};

ChecklistItemForm.propTypes = {
    itemId: IdPropType,
    taskId: IdPropType.isRequired,
    checklistId: IdPropType.isRequired,
    onEdit: PropTypes.func.isRequired,
    newSortIndex: PropTypes.number,
    can: PropTypes.string,
};

ChecklistItemForm.defaultProps = {
    itemId: null,
    newSortIndex: null,
    can: WRITE,
};

export default ChecklistItemForm;
