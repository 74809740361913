import React from 'react';
import { TableHead, TableRow, TableCell, Typography } from '@mui/material';

const headCells = [
    { id: 'type', label: 'Kontaktart' },
    { id: 'number', label: 'Angebots-/Auftragsnummer' },
    { id: 'location', label: 'Lage' },
    { id: 'title', label: 'Aktentitel' },
];

const ContactableLinkCardHead = () => {
    return (
        <TableHead>
            <TableRow>
                {headCells.map(headCell => (
                    <TableCell key={headCell.id}>
                        <Typography variant="h5" component="span" color="primary">
                            {headCell.label || ''}
                        </Typography>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

export default ContactableLinkCardHead;
