import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import classNames from 'classnames';
import DropzoneInputProvider from './DropzoneInputProvider';
import { formatFileSize } from '../../../modules/files/utils';
import { useContextualCan, useContextualCanWrite } from '../../../modules/abilities/hooks';
import { READ } from '../../../modules/abilities/actions';
import BaseDropzoneOverlay from './BaseDropzoneOverlay';

const useStyles = makeStyles({
    root: {
        outline: 'none',
        position: 'relative',
    },
});

const BaseDropzone = ({
    onDrop,
    children,
    multiple,
    maxSize,
    name,
    disabled,
    classes,
    loading,
}) => {
    const _classes = useStyles();
    const canRead = useContextualCan(READ, name);
    const canWrite = useContextualCanWrite(name);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        onDrop,
        noClick: true,
        noDragEventsBubbling: true,
        multiple,
        maxSize,
        onDropRejected: files => {
            files.forEach(file => {
                const key = enqueueSnackbar(
                    `${file.path} überschreitet die Maximalgröße von ${formatFileSize(maxSize)}`,
                    {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        onClick: () => {
                            closeSnackbar(key);
                        },
                    }
                );
            });
        },
        disabled: disabled || loading || !canRead || !canWrite,
    });
    const dropzone = useMemo(() => ({ open, name }), [open, name]);

    return (
        <DropzoneInputProvider value={dropzone}>
            <Box {...getRootProps()} className={classNames(_classes.root, classes && classes.root)}>
                <BaseDropzoneOverlay
                    getInputProps={getInputProps}
                    isDragActive={isDragActive}
                    loading={loading}
                />

                {children}
            </Box>
        </DropzoneInputProvider>
    );
};

BaseDropzone.propTypes = {
    onDrop: PropTypes.func,
    children: PropTypes.node.isRequired,
    multiple: PropTypes.bool,
    maxSize: PropTypes.number,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    classes: PropTypes.shape({
        root: PropTypes.string,
    }),
    loading: PropTypes.bool,
};

BaseDropzone.defaultProps = {
    onDrop: null,
    multiple: false,
    maxSize: null,
    name: null,
    disabled: false,
    classes: null,
    loading: false,
};

export default BaseDropzone;
