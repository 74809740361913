import React from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Metadata from './Metadata';
import { selectMetadataByContext } from '../../modules/metadata/selectors';
import { IdPropType } from '../../modules/proptypes';

const MetadataList = ({ resource, id, context, ...other }) => {
    const metadata = useSelector(state => selectMetadataByContext(state, resource, id, context));

    return metadata.map(data => <Metadata key={data.id} metadata={data} {...other} />);
};

MetadataList.propTypes = {
    resource: PropTypes.string.isRequired,
    id: IdPropType,
    context: PropTypes.string.isRequired,
};

MetadataList.defaultProps = {
    id: null,
};

export default MetadataList;
