import React, { useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Form from '../form/formik/Form';
import { IdPropType, RefPropType } from '../../modules/proptypes';
import LoadingBox from '../loading/LoadingBox';
import { useTranslation } from 'react-i18next';
import { selectCategoryById } from '../../modules/categories/selectors';
import { categorySchema } from '../../modules/categories/schema';
import SubmitButton from '../form/formik/SubmitButton';
import FormPopup from '../popups/FormPopup';
import CategoryFormBody from './CategoryFormBody';
import { destroyCategory, storeCategory, updateCategory } from '../../modules/categories/actions';

const CategoryForm = ({
    categoryId,
    asPopup,
    openPopupWith,
    asSmallForm,
    popupStateRef,
    onLoading,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const category = useSelector(state => selectCategoryById(state, categoryId));

    const initialValues = useMemo(
        () =>
            category || {
                name: '',
                color: '#000000',
                icon: '',
                active: true,
                order: null,
            },
        [category]
    );

    const handleSubmit = newValue => {
        setLoading(true);
        if (onLoading) onLoading(true);
        return dispatch(categoryId ? updateCategory(newValue) : storeCategory(newValue)).finally(
            () => {
                setLoading(false);
                if (onLoading) onLoading(false);
            }
        );
    };

    const handleDelete = () => dispatch(destroyCategory({ id: categoryId }));

    return asPopup || asSmallForm ? (
        <FormPopup
            popupId="categoryPopupForm"
            popupStateRef={popupStateRef}
            initialValues={initialValues}
            validationSchema={categorySchema}
            onSubmit={handleSubmit}
            onDelete={categoryId ? handleDelete : null}
            submitLabel={t('form.SubmitButton.defaultLabel')}
            abortLabel={t('abort')}
            enableReinitialize
            FormInputs={
                <Box
                    onKeyDown={e => {
                        if (e.code !== 'Escape') {
                            e.stopPropagation();
                        }
                    }}
                >
                    <CategoryFormBody asSmallForm={asSmallForm} autoFocus />
                </Box>
            }
        >
            <Grid container>
                <Grid item>
                    <LoadingBox loading={loading} variant="circular" size="small">
                        {openPopupWith}
                    </LoadingBox>
                </Grid>
            </Grid>
        </FormPopup>
    ) : (
        <Form
            initialValues={initialValues}
            validationSchema={categorySchema}
            onSubmit={handleSubmit}
        >
            <CategoryFormBody />
            <Grid container spacing={2} justifyContent="flex-end">
                <SubmitButton>{t('form.SubmitButton.defaultLabel')}</SubmitButton>
            </Grid>
        </Form>
    );
};

CategoryForm.propTypes = {
    categoryId: IdPropType,
    asPopup: PropTypes.bool,
    asSmallForm: PropTypes.bool,
    openPopupWith: PropTypes.node,
    popupStateRef: RefPropType,
    onLoading: PropTypes.func,
};

CategoryForm.defaultProps = {
    categoryId: null,
    asPopup: false,
    asSmallForm: false,
    openPopupWith: null,
    popupStateRef: null,
    onLoading: null,
};

export default CategoryForm;
