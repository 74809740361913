const formatParamValue = (columnKey, fieldConfig) => {
    if (fieldConfig.fields) {
        const fields = fieldConfig.fields;
        const flatValue = Array.isArray(fields) && fields.length !== 1 ? fields : fields[0];

        return {
            [fieldConfig.type]: flatValue,
        };
    }

    if (fieldConfig.alias) {
        return {
            [fieldConfig.type]: columnKey,
        };
    }

    return fieldConfig.type;
};

export const getRemoteAggregationConfig = columns => {
    if (Array.isArray(columns)) {
        return columns.reduce(
            (carry, column) => {
                const columnKey = column?.apiKey || column?.accessorKey || column?.id;

                if (typeof column === 'object' && columnKey && column.aggregationConfig) {
                    const columnFieldConfig = column.aggregationConfig;

                    if (columnFieldConfig) {
                        const paramKey = columnFieldConfig.alias || columnKey;
                        const paramValue = formatParamValue(columnKey, columnFieldConfig);

                        return {
                            aggregationParams: {
                                ...carry.aggregationParams,
                                [paramKey]: paramValue,
                            },
                            aggregationConfig: {
                                ...carry.aggregationConfig,
                                [columnKey]: columnFieldConfig,
                            },
                        };
                    }
                }

                return carry;
            },
            { aggregationParams: {}, aggregationConfig: {} }
        );
    }

    return { aggregationParams: null, aggregationConfig: null };
};
