import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import * as PropTypes from 'prop-types';
import IconButton from '../IconButton';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import SearchIcon from '@mui/icons-material/Search';
import TypedAutocomplete from './TypedAutocomplete';
import { selectUser } from '../../../../modules/auth/selectors';
import SearchPills from '../../../search/SearchPills';
import SearchPillEdit from '../../../search/SearchPillEdit';
import Persistor from '../../../../modules/persistor/persistor';
import { combineFilters } from '../../../../modules/searches/utils';
import { Grid, InputAdornment } from '@mui/material';

const getSearchPersistKey = type => `search.${type}`;

const mergeAttributes = (current, changed) => {
    return {
        usageCount: (current?.usageCount || 0) + (changed?.usageCount || 1),
    };
};

const BaseSearchFilterAutocomplete = ({
    onChange,
    initialFilters,
    name,
    label,
    fullWidth,
    contrast,
    fetchAction,
    type,
    noSave,
    shortcuts,
    I,
    compact,
}) => {
    const user = useSelector(selectUser);
    const popupState = useRef();
    const isCreating = useRef(false);
    const [editSearch, setEditSearch] = useState(null);
    const [filters, setFilters] = useState(initialFilters || []);

    const persistedConfig = useMemo(() => Persistor.get(getSearchPersistKey(type)), [type]);

    const [searchIds, setSearchIds] = useState(persistedConfig?.searchIds || []);
    const [extraAttributes, setExtraAttributes] = useState(persistedConfig?.extraAttributes || {});

    const typeVariants = useMemo(() => {
        if (typeof type === 'string' && type.includes('.')) {
            const typeParts = type.split('.');

            if (Array.isArray(typeParts) && typeParts.length) {
                return typeParts.reduce((carry, typePart) => {
                    if (carry.length) {
                        const typeVariant = `${carry[carry.length - 1]}.${typePart}`;

                        return [...carry, typeVariant];
                    }

                    return [typePart];
                }, []);
            }
        }

        return [type];
    }, []);

    const searches = useMemo(() => {
        if (Array.isArray(user?.searches)) {
            return user.searches.reduce((carry, search) => {
                if (typeVariants.includes(search.type)) {
                    return [
                        ...carry,
                        {
                            ...search,
                            ...(extraAttributes[search.id] || {}),
                        },
                    ];
                }
                return carry;
            }, []);
        }

        return [];
    }, [user, typeVariants, extraAttributes]);

    const handleChangeSearch = newSearchIds => {
        const newExtraAttributes = newSearchIds.reduce((carry, searchId) => {
            const changedAttributes = {
                usageCount: 1,
            };
            const existing = carry[searchId];

            return {
                ...carry,
                [searchId]: mergeAttributes(existing, changedAttributes),
            };
        }, extraAttributes);

        const newSearchPref = {
            searchIds: newSearchIds,
            extraAttributes: newExtraAttributes,
        };

        Persistor.set(getSearchPersistKey(type), newSearchPref);
        setSearchIds(newSearchIds);
    };

    const handleChangeFilter = (newValue, oldValue, setValue, event, reason) => {
        if (
            !(
                isCreating.current &&
                reason === 'removeOption' &&
                event.type === 'keydown' &&
                event.keyCode === 8
            )
        ) {
            setFilters(Array.isArray(newValue) ? newValue : [newValue]);
        }
    };

    const handleEditSearch = (searchObject, event) => {
        setEditSearch(searchObject);
        setFilters(searchObject.search_data);
        popupState.current?.open(event);
    };

    const handleSaveSearch = (result, created = false) => {
        isCreating.current = false;
        setEditSearch(null);
        setFilters([]);
        if (created && result?.data?.id) {
            setSearchIds([...(Array.isArray(searchIds) ? searchIds : []), result.data.id]);
        }
    };

    const handleCancelSaveSearch = (create = false) => {
        if (create) {
            isCreating.current = false;
        } else {
            setFilters([]);
        }

        setEditSearch(null);
    };

    const handleDeletedSearch = () => {
        setEditSearch(null);
        setFilters([]);
    };

    useEffect(() => {
        if (Array.isArray(searchIds)) {
            const savedFilters = searches
                .filter(search => searchIds.includes(search.id))
                .reduce((carry, search) => [...carry, ...search.search_data], []);

            const allFlatFilters = [...filters, ...savedFilters];
            const combined = combineFilters(allFlatFilters);

            if (onChange) {
                onChange(combined, allFlatFilters);
            }
        }
    }, [searchIds, filters, onChange, searches]);

    return (
        <>
            <Grid
                container
                alignItems={compact ? 'center' : null}
                direction={compact ? 'row' : 'column'}
                wrap={compact ? 'nowrap' : 'wrap'}
                spacing={1}
                {...(compact ? {} : { pt: 1, pb: 1 })}
            >
                {!compact && (
                    <Grid item width="100%">
                        {searches.length > 0 && (
                            <SearchPills
                                options={searches}
                                value={searchIds || []}
                                onChange={handleChangeSearch}
                                onEdit={handleEditSearch}
                                contrast={contrast}
                                I={I}
                            />
                        )}
                    </Grid>
                )}
                <Grid
                    item
                    style={compact ? { minWidth: 320, maxHeight: 56 } : null}
                    xs={compact ? 8 : true}
                >
                    <TypedAutocomplete
                        name={name}
                        fetchAction={fetchAction}
                        value={filters}
                        onChange={handleChangeFilter}
                        label={label}
                        extraButton={
                            filters.length &&
                            !noSave &&
                            !editSearch && (
                                <SearchPillEdit
                                    filters={filters}
                                    type={type}
                                    onOpen={() => (isCreating.current = true)}
                                    onSubmitted={id => handleSaveSearch(id, true)}
                                    onAbort={() => handleCancelSaveSearch(true)}
                                >
                                    <IconButton
                                        size="small"
                                        contrast={contrast}
                                        data-test-id="SaveFilterButton"
                                    >
                                        <SaveOutlinedIcon />
                                    </IconButton>
                                </SearchPillEdit>
                            )
                        }
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon
                                        fontSize="small"
                                        style={contrast ? { color: 'white' } : null}
                                        color="primary"
                                    />
                                </InputAdornment>
                            ),
                        }}
                        fullWidth={fullWidth}
                        contrast={contrast}
                        multiple
                        size="small"
                        compact={compact}
                        shortcuts={shortcuts}
                        error={filters.length === 0 && editSearch && 'required'}
                        I={I}
                    />
                </Grid>
                {compact && searches.length > 0 && (
                    <Grid item style={{ width: 280 }}>
                        <SearchPills
                            options={searches}
                            value={searchIds || []}
                            editing={!!editSearch}
                            onChange={handleChangeSearch}
                            onEdit={handleEditSearch}
                            contrast={contrast}
                            I={I}
                        />
                    </Grid>
                )}
            </Grid>
            <Grid display="none">
                <SearchPillEdit
                    editingPill={editSearch}
                    filters={filters}
                    type={type}
                    popupStateRef={popupState}
                    onSubmitted={({ id }) => handleSaveSearch(id)}
                    onDeleted={handleDeletedSearch}
                    onAbort={handleCancelSaveSearch}
                    onClickAway={() => null}
                />
            </Grid>
        </>
    );
};

BaseSearchFilterAutocomplete.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    fullWidth: PropTypes.bool,
    contrast: PropTypes.bool,
    fetchAction: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    noSave: PropTypes.bool,
    onChange: PropTypes.func,
    shortcuts: PropTypes.shape({}),
    I: PropTypes.string,
    compact: PropTypes.bool,
};

BaseSearchFilterAutocomplete.defaultProps = {
    label: null,
    fullWidth: false,
    contrast: false,
    noSave: false,
    onChange: null,
    shortcuts: null,
    I: null,
    compact: false,
};

export default BaseSearchFilterAutocomplete;
