import React from 'react';
import * as PropTypes from 'prop-types';
import Box from '@mui/material/Box';

const ClickBoundary = ({ children, onClick, inline, className }) => (
    <Box
        className={className}
        display={inline ? 'inline' : 'block'}
        onClick={event => {
            event.stopPropagation();
            if (onClick) {
                onClick(event);
            }
        }}
    >
        {children}
    </Box>
);

ClickBoundary.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    inline: PropTypes.bool,
};

ClickBoundary.defaultProps = {
    className: null,
    onClick: null,
    inline: false,
};

export default ClickBoundary;
