import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDynamicCan } from '../abilities/hooks';
import { READ } from '../abilities/actions';
import { TASK_RESOURCE } from '../api/resources';
import { getColumnTypeConfig } from './columnTypes';
import { getAggregationFieldKey, makeFormatAggregationFunc } from '../aggregation/utils';

export const useResourceTableColumns = ({
    listId,
    rawColumns,
    isEdit,
    aggregationFooterFields = null,
}) => {
    const { t } = useTranslation();
    const { handleCan } = useDynamicCan();

    return useMemo(
        () =>
            rawColumns.reduce((carry, column) => {
                if (column) {
                    const {
                        id,
                        accessorKey,
                        valueKey,
                        apiKey,
                        type,
                        Cell,
                        Edit,
                        Footer,
                        convertString,
                        formatValue,
                        readOnly = false,
                        ...colProps
                    } = column;
                    const columnKey = valueKey || accessorKey || id;

                    if (columnKey && handleCan(READ, TASK_RESOURCE, columnKey)) {
                        const columnTypeConfig = getColumnTypeConfig(column, isEdit);

                        const aggregationFieldKey = getAggregationFieldKey(column);
                        const aggregationComponent = makeFormatAggregationFunc(
                            aggregationFieldKey,
                            aggregationFooterFields,
                            columnTypeConfig?.aggregationConfig?.convertString ||
                                columnTypeConfig?.convertString
                        );
                        const footerComponent = Footer || aggregationComponent;

                        return [
                            ...carry,
                            {
                                ...colProps,
                                ...columnTypeConfig,
                                ...(columnTypeConfig.Edit && readOnly ? { Edit: () => null } : {}),
                                id,
                                accessorKey: columnKey,
                                type,
                                header: t(
                                    `components.ResourceTable.${listId}.${
                                        column.id || column.accessorKey
                                    }`
                                ),
                                enableGrouping: !!colProps?.groupConfig,
                                ...(footerComponent ? { Footer: footerComponent } : {}),
                            },
                        ];
                    }
                }

                return carry;
            }, []),
        [rawColumns, aggregationFooterFields, isEdit, listId]
    );
};
