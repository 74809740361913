import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

// TODO: find a better way to do this..
import basicsDE from '../assets/locales/de/basics.json';
import playgroundDE from '../assets/locales/de/playground.json';
import basicsEN from '../assets/locales/en/basics.json';
import playgroundEN from '../assets/locales/en/playground.json';

const resources = {
    de: {
        basics: basicsDE,
        playground: playgroundDE,
    },
    en: {
        basics: basicsEN,
        playground: playgroundEN,
    },
};

const initI18n = () =>
    i18n
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            // we init with resources
            resources,
            fallbackLng: 'de',
            lng: 'de', // set explicitly for now
            debug: process.env.NODE_ENV !== `production`,

            // have a common namespace used around the full app
            ns: ['basics', 'playground'],
            defaultNS: 'basics',

            interpolation: {
                escapeValue: false,
            },
        });

export default initI18n;
