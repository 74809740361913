import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { IdPropType } from '../../../modules/proptypes';
import { useSelector } from 'react-redux';
import { Grid, Icon } from '@mui/material';
import BaseAvatar from '../../icons/BaseAvatar';
import { formatUserForCalender } from '../../../modules/calendar/utils';
import { importDate, generateRelativeDate } from '../../../modules/datetime/utils';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { selectUserById } from '../../../modules/users/selectors';
import { selectPersonById } from '../../../modules/persons/personsSlice';

const useStyles = makeStyles({
    avatar: {
        height: 32,
        width: 32,
    },
});

const getDateString = date => {
    if (date) {
        return generateRelativeDate(importDate(date));
    }
    return '';
};

const CalendarDetailChange = ({ userId, date, textOnly, onFormatText }) => {
    const classes = useStyles();
    const user = useSelector(state => selectUserById(state, userId));
    const person = useSelector(state => selectPersonById(state, user?.personId));

    const { key, text, short, icon, avatar, color } = useMemo(() => {
        if (user && person) {
            return formatUserForCalender(user, person);
        }

        return {};
    }, [user, person]);

    const message = useMemo(() => {
        const dateString = getDateString(date);

        if (onFormatText) {
            return onFormatText(text, dateString);
        }

        if (text && text !== '') {
            return `${text} - ${dateString}`;
        }

        return dateString;
    }, [text, date]);

    if (textOnly) {
        return message;
    }

    return (
        <Grid container mb={1} alignItems="center">
            <Grid item className={classes.avatar}>
                {userId ? (
                    <BaseAvatar
                        displayName={text}
                        shortName={short && !icon ? short : <Icon fontSize="small">{icon}</Icon>}
                        path={avatar}
                        color={color}
                        small
                    />
                ) : null}
            </Grid>
            <Grid item ml={1}>
                <Typography color="textSecondary">{message}</Typography>
            </Grid>
        </Grid>
    );
};

CalendarDetailChange.propTypes = {
    userId: IdPropType.isRequired,
    date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    textOnly: PropTypes.bool,
    onFormatText: PropTypes.func,
    onFormatTextOnly: PropTypes.func,
};

CalendarDetailChange.defaultProps = {
    date: null,
    textOnly: false,
    onFormatText: null,
    onFormatTextOnly: null,
};

export default CalendarDetailChange;
