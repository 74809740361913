import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import SlimDialog, { SLIM_DIALOG_WIDTH } from '../../dialogs/slimDialog/SlimDialog';
import { useDispatch, useSelector } from 'react-redux';
import { destroyTask, updateTask, useTask } from '../../../modules/tasks/tasksSlice';
import EditTaskForm from '../EditTaskForm';
import TaskDetail from '../TaskDetail';
import { formatTaskSubmit } from '../../../modules/tasks/utils';
import { indexTaskTypes } from '../../../modules/taskTypes/actions';
import { selectTaskTypesIndexingOrInitialized } from '../../../modules/taskTypes/selectors';
import CreateTaskForm from '../CreateTaskForm';
import { useTranslation } from 'react-i18next';
import { showDialog } from '../../../modules/dialogs/dialogSlice';
import { TASK_DIALOG } from '../../dialogs/dialogs';
import { Grid } from '@mui/material';
import ChecklistBundle from '../../checklists/ChecklistBundle';
import Divider from '@mui/material/Divider';
import { useCan } from '../../../modules/abilities/hooks';
import { UPDATE } from '../../../modules/abilities/actions';
import { TASK_RESOURCE } from '../../../modules/api/resources';
import CompletedIconButton from '../../buttons/CompletedIconButton';
import TaskTemplateConverter from '../TaskTemplateConverter';
import { convertLocalDateToUTC } from '../../../modules/datetime/utils';
import { useLoading } from '../../../modules/loading/hooks';
import { useHistory } from 'react-router-dom';
import { tasksPath } from '../../../routes/paths';

const TaskDialog = ({ taskId, cardId, open, edit: overrideEdit, onClose, onExited }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const [task, { loading }] = useTask(taskId === -1 ? null : taskId);
    const taskTypesInitialized = useSelector(selectTaskTypesIndexingOrInitialized);
    const lastOverrideEdit = useRef(overrideEdit);
    const [edit, setEdit] = useState(overrideEdit);
    const create = useMemo(() => taskId === -1 && edit, [taskId, edit]);
    const template = useMemo(() => !cardId && !task?.card_id, [cardId, task]);
    const canComplete = useCan(UPDATE, task || TASK_RESOURCE, 'completed_at');

    const handleEdit = () => setEdit(true);
    const abortEdit = () => setEdit(false);

    const handleTaskChange = (newTaskId = -1, props = {}) => {
        dispatch(showDialog({ type: TASK_DIALOG, props: { taskId: newTaskId, ...props } }));
    };

    const handleClose = props => {
        if (history.location.pathname.includes(tasksPath)) {
            history.push(tasksPath, { shallow: true });
        }
        onClose(props);
    };

    const handleSubmit = values => {
        return dispatch(updateTask(formatTaskSubmit(values))).then(result => {
            abortEdit();
            return result;
        });
    };

    const handleDelete = () => {
        setEdit(false);
        dispatch(destroyTask({ id: taskId })).then(() => {
            onExited();
        });
    };

    const toggleCompleted = useCallback(
        () =>
            dispatch(
                updateTask({
                    id: taskId,
                    completed_at: task.completed_at ? null : convertLocalDateToUTC(),
                })
            ),
        [taskId, task, dispatch]
    );

    const { loading: completedLoading, handleClick: handleCompleted } = useLoading(toggleCompleted);

    const title = useMemo(() => {
        if (create) {
            return t('form.CreateTask.header');
        }

        if (template) {
            return `${t('components.ResourceTable.TaskTemplateTable.name')}: ${task?.name}`;
        }

        return task?.name;
    }, [task, create, template]);

    const toolbarActions = useMemo(() => {
        if (edit || template) {
            return null;
        }

        return [
            <CompletedIconButton
                isCompleted={task?.completed_at !== null}
                subject={task || TASK_RESOURCE}
                loading={completedLoading}
                onChange={handleCompleted}
                disabled={!canComplete}
                contrast
                small
            />,
            <TaskTemplateConverter taskId={taskId} />,
        ];
    }, [taskId, template, task, edit, canComplete, completedLoading, handleCompleted]);

    useEffect(() => {
        if (overrideEdit !== edit && overrideEdit !== lastOverrideEdit.current) {
            setEdit(overrideEdit);
            lastOverrideEdit.current = overrideEdit;
        }
    }, [overrideEdit, edit, setEdit]);

    useEffect(() => {
        if (!taskTypesInitialized) {
            dispatch(indexTaskTypes());
        }
    }, [dispatch]);

    return (
        <SlimDialog
            title={title}
            open={open}
            width={create ? SLIM_DIALOG_WIDTH.MEDIUM : SLIM_DIALOG_WIDTH.LARGE}
            loading={loading}
            onEdit={edit ? null : handleEdit}
            onAbort={edit && !create ? abortEdit : null}
            onDelete={edit ? null : handleDelete}
            onClose={handleClose}
            actions={toolbarActions}
        >
            <Grid
                container
                direction="row"
                spacing={1}
                style={{
                    flexFlow: 'nowrap',
                    minHeight: 256,
                    padding: 16,
                }}
            >
                <Grid item xs={create ? 12 : 7}>
                    {task ? (
                        <>
                            {!edit ? <TaskDetail taskId={taskId} /> : null}
                            {edit && !create ? (
                                <EditTaskForm
                                    task={task || {}}
                                    onSubmit={handleSubmit}
                                    onAbort={abortEdit}
                                    onDelete={handleDelete}
                                />
                            ) : null}
                        </>
                    ) : null}
                    {create ? <CreateTaskForm cardId={cardId} onCreate={handleTaskChange} /> : null}
                </Grid>
                {!create ? (
                    <>
                        <Grid item>
                            <Divider orientation="vertical" />
                        </Grid>
                        <Grid item xs={5} style={{ height: '100%', overflowY: 'auto' }}>
                            <ChecklistBundle taskId={taskId} />
                        </Grid>
                    </>
                ) : null}
            </Grid>
        </SlimDialog>
    );
};

TaskDialog.propTypes = {
    taskId: PropTypes.number,
    cardId: PropTypes.number,
    open: PropTypes.bool,
    edit: PropTypes.bool,
    onClose: PropTypes.func,
    onExited: PropTypes.func,
};

TaskDialog.defaultProps = {
    taskId: null,
    cardId: null,
    open: false,
    edit: false,
    onClose: () => null,
    onExited: () => null,
};

export default TaskDialog;
