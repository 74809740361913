import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { selectCloudStatus } from '../../modules/cloud/selectors';
import { UNHEALTHY } from '../../modules/cloud/states';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },

    dense: {
        marginTop: 0,
        marginBottom: 0,
    },

    icon: {
        position: 'relative',
        top: 3,
        color: theme.palette.text.secondary,
    },

    loading: {
        marginTop: 3,
    },
}));

const CloudStatusCheck = ({ blocked, margin, children }) => {
    const classes = useStyles();
    const status = useSelector(selectCloudStatus);

    return blocked || status === UNHEALTHY ? (
        <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="center"
            className={classNames(classes.root, { [classes.dense]: margin === 'dense' })}
        >
            <Grid item>
                {status === UNHEALTHY ? (
                    <ErrorOutlineOutlinedIcon className={classes.icon} fontSize="small" />
                ) : (
                    <CircularProgress size={14} className={classes.loading} />
                )}
            </Grid>
            <Grid item>
                <Typography color="textSecondary">
                    {status === UNHEALTHY
                        ? 'Cloud ist gerade nicht erreichbar'
                        : 'Ordner werden gerade erstellt / verschoben'}
                </Typography>
            </Grid>
        </Grid>
    ) : (
        children
    );
};

CloudStatusCheck.propTypes = {
    blocked: PropTypes.bool,
    margin: PropTypes.string,
    children: PropTypes.node,
};

CloudStatusCheck.defaultProps = {
    blocked: false,
    margin: null,
    children: null,
};

export default CloudStatusCheck;
