import React, { useState } from 'react';
import * as propTypes from 'prop-types';
import ContactDataInput from './ContactDataInput';
import { useFieldFast } from '../../modules/form/hooks';
import ContactData from './ContactData';

const ContactDatasInputRow = ({ name, initialEdit, onDelete }) => {
    const [{ value }] = useFieldFast(name);
    const [isEdit, setIsEdit] = useState(initialEdit);

    return isEdit ? (
        <ContactDataInput name={name} onDelete={onDelete} />
    ) : (
        <ContactData contactDataId={value.id} onEdit={() => setIsEdit(true)} inline />
    );
};

ContactDatasInputRow.propTypes = {
    name: propTypes.string.isRequired,
    initialEdit: propTypes.bool.isRequired,
    onDelete: propTypes.func.isRequired,
};

export default ContactDatasInputRow;
