import { SEARCH_RESOURCE } from '../api/resources';

export const storeSearch = params => (dispatch, getState, api) =>
    api[SEARCH_RESOURCE].store(params);

export const updateSearch = params => (dispatch, getState, api) =>
    api[SEARCH_RESOURCE].update(params);

export const destroySearch = params => (dispatch, getState, api) =>
    api[SEARCH_RESOURCE].destroy(params);
