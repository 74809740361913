/*
 * extra methods
 */

import { CARD_STATUS_RESOURCE } from '../api/resources';

export const indexCardStatus = params => (dispatch, getState, api) =>
    api[CARD_STATUS_RESOURCE].index(params);

export const storeCardStatus = cardStatus => (dispatch, getState, api) =>
    api[CARD_STATUS_RESOURCE].store(cardStatus);

export const updateCardStatus = cardStatus => (dispatch, getState, api) =>
    api[CARD_STATUS_RESOURCE].update(cardStatus);

export const destroyCardStatus = params => (dispatch, getState, api) =>
    api[CARD_STATUS_RESOURCE].destroy({ ...params, forceDelete: true });
