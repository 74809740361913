import React, { createRef, useCallback, useEffect, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { Box, Icon, Popover, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Waypoint } from 'react-waypoint';
import { useTranslation } from 'react-i18next';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import * as CategorizedIcons from '../../../assets/icons/MaterialIcons-Categorized.json';
import Button from '../base/Button';
import TextField from '../base/TextField';
import { useFieldFast } from '../../../modules/form/hooks';
import { getIconsOnly } from '../../../modules/icons/utils';
import IconTabs from '../../layout/IconTabs';
import IconSelectButton from '../base/IconSelectButton';
import VirtualizedGrid from '../../layout/VirtualizedGrid';
import { WRITE } from '../../../modules/abilities/actions';
import { usePermissionSubject } from '../../../modules/abilities/PermissionSubjectProvider';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'block',
        width: theme.spacing(52),
        height: theme.spacing(64),
        overflow: 'hidden',
        position: 'relative',
    },
    iconBox: {
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(0.5),
    },
    filter: {
        height: 52,
        padding: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    scrollBox: {
        height: `calc(100% - 116px)`,
        padding: theme.spacing(1),
    },
    scrollBoxSearch: {
        height: `calc(100% - 52px)`,
        padding: theme.spacing(2),
    },
    tabs: {
        height: 64,
        padding: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        overflowY: 'hidden',
        overflowX: 'auto',
    },
    category: {
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    categoryItem: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
    row: {
        height: '100%',
    },
    waypoint: {
        height: '100%',
        overflow: 'hidden',
    },
}));

const ROW_HEIGHT = 76;
const ROW_COLS = 4;

const IconSelect = ({ name }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [{ value }, , { setValue }] = useFieldFast(name);
    const scrollGridRef = createRef();
    const [tab, setTab] = useState(0);
    const [search, setSearch] = useState('');
    const subject = usePermissionSubject();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const justIcons = useMemo(() => getIconsOnly(CategorizedIcons), []);

    const searchResult = useMemo(() => {
        if (search === '' || !justIcons) {
            return [];
        }

        return justIcons.filter(item => {
            const searchOptimised = search.toLowerCase();
            const labelOptimised = item.label && item.label.toLowerCase();
            const valueOptimised = item.value && item.value.toLowerCase();

            if (!item || !labelOptimised || !valueOptimised) {
                return false;
            }

            return (
                labelOptimised.includes(searchOptimised) || valueOptimised.includes(searchOptimised)
            );
        });
    }, [search, justIcons]);

    const tabIndexes = useMemo(() => {
        const indexBuild = CategorizedIcons.default.reduce((carry, category) => {
            const currentCarry =
                carry && carry.count
                    ? carry
                    : { count: Math.ceil(carry.icons.length / ROW_COLS), indexes: [0] };
            return {
                count: currentCarry.count + Math.ceil(category.icons.length / ROW_COLS) + 1,
                indexes: [...currentCarry.indexes, currentCarry.count + 1],
            };
        });

        return indexBuild.indexes;
    }, []);

    useEffect(() => {
        if (scrollGridRef.current) {
            scrollGridRef.current.scrollToItem({ rowIndex: tabIndexes[tab], columnIndex: 0 });
        }
    }, [tab, tabIndexes, scrollGridRef]);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);

    const handleTab = (event, newTab) => {
        setTab(newTab);
        scrollGridRef.current.scrollToItem({ rowIndex: tabIndexes[newTab] + 6, columnIndex: 0 });
    };

    const handleSearch = query => {
        setSearch(query);
    };

    const handleSelect = useCallback(
        iconValue => {
            handleClose();
            setValue(iconValue);
        },
        [handleClose, setValue]
    );

    const getCategory = useCallback(
        (category, index) => (
            <Box className={classes.waypoint}>
                <Waypoint onEnter={() => setTab(index)}>
                    <Grid container className={classes.category} spacing={2}>
                        <Grid item className={classes.categoryItem}>
                            <Icon color="primary">{category.icon}</Icon>
                        </Grid>
                        <Grid item className={classes.categoryItem}>
                            <Typography color="primary">{category.label}</Typography>
                        </Grid>
                    </Grid>
                </Waypoint>
            </Box>
        ),
        [setTab, classes.category, classes.categoryItem, classes.waypoint]
    );

    const getFakeGridRows = useCallback(
        (items, cols) => {
            const rows = Math.ceil(items.length / cols);
            const result = [];

            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < rows; index++) {
                result.push(
                    <Grid container className={classes.row}>
                        {items.slice(index * cols, index * cols + cols).map(item => (
                            <Grid item xs={Math.floor(12 / cols)} key={`icon_${item.value}`}>
                                <IconSelectButton
                                    label={item.label}
                                    value={item.value}
                                    selected={item.value === value}
                                    onClick={handleSelect}
                                />
                            </Grid>
                        ))}
                    </Grid>
                );
            }

            return result;
        },
        [handleSelect, value, classes.row]
    );

    const virtualItems = useMemo(
        () =>
            CategorizedIcons.default.reduce((carry, category, index) => {
                const currentCarry = Array.isArray(carry)
                    ? carry
                    : [getCategory(carry, 0), ...getFakeGridRows(carry.icons, ROW_COLS)];
                return [
                    ...currentCarry,
                    getCategory(category, index),
                    ...getFakeGridRows(category.icons, ROW_COLS),
                ];
            }),
        [getFakeGridRows, getCategory]
    );

    return (
        <Grid container>
            <Grid item className={classes.iconBox}>
                {value ? <Icon>{value}</Icon> : <AddPhotoAlternateOutlinedIcon color="disabled" />}
            </Grid>
            <Grid item xs>
                <Button
                    action={WRITE}
                    subject={subject}
                    variant="contained"
                    color="primary"
                    onClick={handleClick}
                >
                    {t('components.IconSelect.open')}
                </Button>
            </Grid>
            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Grid container className={classes.root}>
                    <Grid item xs={12} className={classes.filter}>
                        <TextField
                            type="search"
                            onChange={ev => handleSearch(ev.target.value)}
                            label={t('components.IconSelect.filter')}
                            size="small"
                            contrast
                            autoFocus
                        />
                    </Grid>
                    {search === '' ? (
                        <>
                            <Grid item xs={12} className={classes.tabs}>
                                <IconTabs
                                    categorizedList={CategorizedIcons.default}
                                    value={tab}
                                    onChange={handleTab}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.scrollBox}>
                                <VirtualizedGrid
                                    cols={1}
                                    itemHeight={ROW_HEIGHT}
                                    ref={scrollGridRef}
                                    items={[null, ...virtualItems]}
                                />
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12} className={classes.scrollBoxSearch}>
                            <VirtualizedGrid
                                cols={ROW_COLS}
                                itemHeight={ROW_HEIGHT}
                                items={searchResult.map(item => (
                                    <IconSelectButton
                                        label={item.label}
                                        value={item.value}
                                        selected={item.value === value}
                                        onClick={handleSelect}
                                    />
                                ))}
                            />
                        </Grid>
                    )}
                </Grid>
            </Popover>
        </Grid>
    );
};

IconSelect.propTypes = {
    name: PropTypes.string.isRequired,
};

export default IconSelect;
