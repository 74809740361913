import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import AsyncAutocomplete from '../form/base/autocomplete/AsyncAutocomplete';
import { debounce } from 'lodash';
import axios from 'axios';
import { formatNominatimAddress } from '../../modules/addresses/utils';
import { useField } from 'formik';

const AddressAutocomplete = ({ name, onChange, ...other }) => {
    const [{ value }, , { setValue }] = useField(name || '');

    const handleChange = (event, option) => {
        const address = formatNominatimAddress(option);

        if (onChange) {
            onChange(address, event);
        }

        if (name) {
            setValue(address);
        }
    };

    const handleOptionLabel = option =>
        typeof option === 'string' ? option : option.display_name || '';

    const fetchDebounced = q => {
        if (!q || q === '') {
            return new Promise(resolve => resolve());
        }

        return axios
            .get('https://nominatim.openstreetmap.org/search', {
                params: {
                    q,
                    'accept-language': 'de',
                    countrycodes: 'de',
                    addressdetails: 1,
                    format: 'json',
                },
                headers: {
                    'Access-Control-Allow-Origin': location.origin,
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': true,
                    'Access-Control-Allow-Methods': 'PUT, GET, POST, DELETE, OPTIONS',
                    'Access-Control-Allow-Headers': 'Content-Type',
                },
                withCredentials: false,
                xsrfCookieName: null,
            })
            .then(response => (response && Array.isArray(response.data) ? response.data : []));
    };

    return (
        <AsyncAutocomplete
            value={value?.full}
            fetch={fetchDebounced}
            onChange={handleChange}
            getOptionLabel={handleOptionLabel}
            isOptionEqualToValue={() => false}
            clearOnBlur
            {...other}
        />
    );
};

AddressAutocomplete.propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func,
};

AddressAutocomplete.defaultProps = {
    name: null,
    onChange: null,
};

export default AddressAutocomplete;
