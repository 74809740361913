import React from 'react';
import * as PropTypes from 'prop-types';
import { useField } from 'formik';

import BaseToggleButton from '../base/BaseToggleButton';

const ToggleButton = ({ name, icon, iconToggled, size, onChange, I }) => {
    const [field, , helpers] = useField(name);

    return (
        <BaseToggleButton
            name={name}
            value={field.value}
            icon={icon}
            iconToggled={iconToggled}
            size={size}
            onChange={value => {
                helpers.setValue(value);

                if (onChange) {
                    onChange(name, value);
                }
            }}
            I={I}
        />
    );
};

ToggleButton.propTypes = {
    name: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    iconToggled: PropTypes.node.isRequired,
    onChange: PropTypes.func,
    size: PropTypes.string,
    I: PropTypes.string,
};

ToggleButton.defaultProps = {
    size: 'medium',
    onChange: null,
    I: null,
};

export default ToggleButton;
