import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { flexRender } from 'material-react-table';
import ResourceTablePrintCell from './ResourceTablePrintCell';
import { toAbsolutePath } from '../../modules/files/utils';
import { useSelector } from 'react-redux';
import { selectSettingsByKey } from '../../modules/settings/selectors';
import { LOGO_DEFAULT_SETTINGS, SITE_NAME_SETTINGS } from '../../modules/settings/config';
import { useTheme } from '@mui/styles';
import ResourceTablePrintAggregates from './ResourceTablePrintAggregates';

const CONFIG = {
    overrideColumns: {
        'mrt-row-expand': {
            Cell: ({ cell }) => {
                if (cell.row?.original?.label) {
                    return cell.row?.original?.label;
                }
                return null;
            },
            Header: ({ groupColumnConfigs }) => {
                if (Array.isArray(groupColumnConfigs)) {
                    return groupColumnConfigs.map(({ header }) => header).join(', ');
                }
                return null;
            },
        },
    },
    ignoredColumns: ['mrt-row-select', 'mrt-row-actions'],
    ignoredRowProps: { loading: true },
};

const checkColumnIncluded = (id, ignoredColumns = []) =>
    !(id && [...CONFIG.ignoredColumns, ...ignoredColumns].includes(id));

const checkRowIncluded = (row, ignoredRowProps = {}) => {
    if (!row || typeof row !== 'object') {
        return false;
    }

    const allIgnoredProps = Object.entries({
        ...CONFIG.ignoredRowProps,
        ...ignoredRowProps,
    });

    return !allIgnoredProps.find(
        ([key, value]) => key in row.original && row.original[key] === value
    );
};

const ResourceTablePrint = ({
    table,
    columns,
    label,
    ignoredColumns,
    aggregateTotal,
    grouping,
    renderHead,
    renderFooterRow,
    renderFooter,
}) => {
    const theme = useTheme();
    const logoPath = useSelector(state => selectSettingsByKey(state, LOGO_DEFAULT_SETTINGS));
    const siteName = useSelector(state => selectSettingsByKey(state, SITE_NAME_SETTINGS));

    const groupColumnConfigs = useMemo(
        () =>
            Array.isArray(grouping) && Array.isArray(columns)
                ? columns.filter(({ id }) => grouping.includes(id))
                : [],
        [grouping, columns]
    );

    const handleCheckColumnId = columnId => checkColumnIncluded(columnId, ignoredColumns);

    const renderCell = cell => {
        if (cell?.column?.id in CONFIG.overrideColumns) {
            const Component = CONFIG.overrideColumns[cell.column.id].Cell;

            if (Component) {
                const renderedComponent = <Component table={table} cell={cell} />;

                if (renderedComponent) {
                    return renderedComponent;
                }
            }
        }

        return <ResourceTablePrintCell table={table} columns={columns} cell={cell} />;
    };

    const renderHeader = header => {
        if (header.id in CONFIG.overrideColumns) {
            const Component = CONFIG.overrideColumns[header.id].Header;

            if (Component) {
                const renderedComponent = (
                    <Component
                        table={table}
                        groupColumnConfigs={groupColumnConfigs}
                        header={header}
                    />
                );

                if (renderedComponent) {
                    return renderedComponent;
                }
            }
        }

        return flexRender(
            header.column.columnDef.Header ?? header.column.columnDef.header,
            header.getContext()
        );
    };

    return (
        <div>
            {label ? (
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                        <h3>{label}</h3>
                        {typeof renderHead === 'function' ? renderHead(table) : null}
                    </div>
                    <div
                        style={{
                            flex: 0,
                            paddingTop: 12,
                            paddingBottom: 12,
                        }}
                    >
                        <img
                            src={toAbsolutePath(logoPath)}
                            alt={`Logo ${siteName}`}
                            style={{
                                maxWidth: 300,
                                minHeight: 56,
                                padding: 10,
                                backgroundColor: theme.palette.primary.main,
                            }}
                        />
                    </div>
                </div>
            ) : null}
            <table aria-label={label}>
                <thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id} style={{ height: 32 }}>
                            {headerGroup.headers.map(header =>
                                checkColumnIncluded(header?.id, ignoredColumns) &&
                                !header.isPlaceholder ? (
                                    <th key={header.id}>
                                        <div
                                            style={{
                                                padding: 4,
                                                fontWeight: 'bold',
                                                textWrap: 'balance',
                                                ...(typeof header?.column?.columnDef
                                                    ?.forcePrintStyle === 'object'
                                                    ? header.column.columnDef.forcePrintStyle
                                                    : {}),
                                            }}
                                            color="primary"
                                        >
                                            {renderHeader(header)}
                                        </div>
                                    </th>
                                ) : null
                            )}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map(row =>
                        row?.id && checkRowIncluded(row) ? (
                            <tr key={row.id}>
                                {row.getVisibleCells().map((cell, _columnIndex) =>
                                    checkColumnIncluded(cell?.column?.id, ignoredColumns) ? (
                                        <td key={cell.id}>
                                            <div
                                                style={{
                                                    padding: 4,
                                                    fontSize: 14,
                                                    textWrap: 'balance',
                                                    ...(typeof cell?.column?.columnDef
                                                        ?.forcePrintStyle === 'object'
                                                        ? cell.column.columnDef.forcePrintStyle
                                                        : {}),
                                                }}
                                            >
                                                {renderCell(cell)}
                                            </div>
                                        </td>
                                    ) : null
                                )}
                            </tr>
                        ) : null
                    )}
                    {typeof renderFooterRow === 'function'
                        ? renderFooterRow({ table, columns, aggregateTotal, handleCheckColumnId })
                        : null}
                    {aggregateTotal ? (
                        <ResourceTablePrintAggregates
                            table={table}
                            columns={columns}
                            aggregateTotal={aggregateTotal}
                            onCheckColumnId={handleCheckColumnId}
                        />
                    ) : null}
                </tbody>
            </table>
            {typeof renderFooter === 'function' ? renderFooter(table) : null}
        </div>
    );
};

ResourceTablePrint.propTypes = {
    table: PropTypes.shape({}).isRequired,
    columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    label: PropTypes.string,
    ignoredColumns: PropTypes.arrayOf(PropTypes.string),
    aggregateTotal: PropTypes.shape({}),
    activeGroupConfigs: PropTypes.arrayOf(PropTypes.shape({})),
    renderHead: PropTypes.func,
    renderFooterRow: PropTypes.func,
    renderFooter: PropTypes.func,
};

ResourceTablePrint.defaultProps = {
    label: null,
    ignoredColumns: [],
    aggregateTotal: null,
    activeGroupConfigs: [],
    renderHead: null,
    renderFooterRow: null,
    renderFooter: null,
};

export default ResourceTablePrint;
