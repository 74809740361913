import React from 'react';
import * as PropTypes from 'prop-types';
import { LinearProgress } from '@mui/material';

const CalendarLoading = ({ loading }) => {
    return loading ? (
        <LinearProgress
            style={{
                position: 'absolute',
                width: '100%',
                top: 60,
            }}
        />
    ) : null;
};

CalendarLoading.propTypes = {
    loading: PropTypes.bool,
};

CalendarLoading.defaultProps = {
    loading: false,
};

export default CalendarLoading;
