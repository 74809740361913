import { ADDRESS_RESOURCE } from '../api/resources';

export const indexAddresses = params => (dispatch, getState, api) =>
    api[ADDRESS_RESOURCE].index(params);

export const storeAddress = person => (dispatch, getState, api) =>
    api[ADDRESS_RESOURCE].store(person);

export const updateAddress = person => (dispatch, getState, api) =>
    api[ADDRESS_RESOURCE].update(person);

export const destroyAddress = params => (dispatch, getState, api) =>
    api[ADDRESS_RESOURCE].destroy(params);
