import { TASK_TYPE_RESOURCE } from '../api/resources';

export const selectTaskTypesById = state => state[TASK_TYPE_RESOURCE].byId;

export const selectTaskTypeById = (state, id) => selectTaskTypesById(state)[id];

export const selectAllTaskTypeIds = state => state[TASK_TYPE_RESOURCE].allIds;

export const selectAllTaskTypes = state => Object.values(selectTaskTypesById(state));

export const selectTaskTypesIndexing = state => state[TASK_TYPE_RESOURCE].indexing;

export const selectTaskTypesIndexingOrInitialized = state =>
    selectTaskTypesIndexing(state) || selectAllTaskTypeIds(state).length > 0;
