import React, { useMemo, useRef } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import TabDialog from '../dialogs/tabDialog/TabDialog';
import { IdPropType } from '../../modules/proptypes';
import ResourceBaseData from './pages/ResourceBaseData';
import GroupsData from '../groups/GroupsData';
import { selectResourceById } from '../../modules/resources/selectors';
import TabDialogActions from '../dialogs/tabDialog/TabDialogActions';
import { RESOURCE_RESOURCE } from '../../modules/api/resources';
import { archiveResource } from '../../modules/resources/resourcesSlice';
import AppointmentCalendar from '../calendar/AppointmentCalendar';

const ResourceDialog = ({ resourceId, cloneResourceId, initTab, onDone, onClose }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const resource = useSelector(state => selectResourceById(state, resourceId));
    const clone = useSelector(state => selectResourceById(state, cloneResourceId));
    const submitRef = useRef(null);

    const handleDelete = () => {
        if (resourceId) {
            dispatch(archiveResource({ id: resourceId })).then(() => {
                onClose();
            });
        }
    };

    const tabs = useMemo(
        () => [
            {
                name: 'basics',
                title: t('components.ResourceDialog.tabs.basics.tab'),
                disabled: false,
                content: (
                    <ResourceBaseData
                        resourceId={resourceId}
                        cloneResourceId={cloneResourceId}
                        onDone={onDone}
                        submitRef={submitRef}
                    />
                ),
            },
            {
                name: 'groups',
                title: t('components.ResourceDialog.tabs.groups.tab'),
                disabled: !resource || !resourceId,
                content: <GroupsData resourceId={resourceId} />,
            },
            {
                name: 'calendar',
                title: t('components.ResourceDialog.tabs.calendar.tab'),
                disabled: !resource || !resourceId,
                borderless: true,
                content: (
                    <AppointmentCalendar
                        calendarId={`resourceCalendar.${resourceId}`}
                        defaultEntityId={resourceId}
                        defaultEntityResource={RESOURCE_RESOURCE}
                    />
                ),
            },
        ],
        [resource, resourceId, cloneResourceId, onDone, submitRef, t]
    );

    const tabActions = {
        basics: <TabDialogActions ref={submitRef} />,
    };

    const dialogTitle = useMemo(() => {
        if (clone) {
            return `${t('components.ResourceDialog.clone')}: ${clone.name}`;
        }
        if (resource) {
            return `${t('components.ResourceDialog.edit')}: ${resource.name}`;
        }
        return t('components.ResourceDialog.new');
    }, [resource, clone, t]);

    return (
        <TabDialog
            data-test-id="ContactDialog"
            title={dialogTitle}
            initTab={initTab}
            tabs={tabs}
            actions={tabActions}
            onDelete={handleDelete}
            subject={RESOURCE_RESOURCE}
        />
    );
};

ResourceDialog.propTypes = {
    onDone: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    resourceId: IdPropType,
    cloneResourceId: IdPropType,
    initTab: PropTypes.string,
};

ResourceDialog.defaultProps = {
    onDone: null,
    resourceId: null,
    cloneResourceId: null,
    initTab: '',
};

export default ResourceDialog;
