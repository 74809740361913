import { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCallbackFunc } from '../hooks';
import { aggregateList, filterList, groupList, indexPage } from './actions';
import { clearList, initList, saveOrderBy } from './listsSlice';
import {
    selectListData,
    selectListDataById,
    selectListDataIds,
    selectListGroupByFieldsAggs,
    selectListGroupByFieldsGroups,
    selectListGroupByFieldsLoading,
    selectListGroupByFieldsNestedLists,
    selectListIsAggregatesLoading,
    selectListIsInitialized,
    selectListIsLoading,
    selectListItemsTotal,
    selectListOrderBy,
    selectListPage,
    selectListPageCount,
    selectListSearch,
    selectListTotalAggregate,
    selectResourceDataById,
} from './selectors';
import { makeRelatedDataSelector } from '../redux/resource/selectors';
import { applyToNestedObject, getFromNestedObject } from './utils';
import { getRemoteAggregationConfig } from '../aggregation/remote';
import { getActiveGroupConfigs, getRemoteGroupConfig } from '../aggregation/group';
import { paramCleanup } from '../api/utils';
import { AGGREGATION_TYPE } from '../aggregation/utils';

export const useResourceList = ({
    listId,
    resource,
    fetchParams,
    staticParams,
    contexts,
    initialOrderBy,
    initialSearch,
    autoload,
    autoInitialize,
    limit = 25,
    continuous,
    index,
    with: withConfig,
    updateWatch,
    criteria,
    unique = false,
}) => {
    const dispatch = useDispatch();
    const dataIds = useSelector(state => selectListDataIds(state, listId));

    /* this is true if the list was loaded at least once - NOT if initList was called or not! */
    const initialized = useSelector(state => selectListIsInitialized(state, listId));

    const loading = useSelector(state => selectListIsLoading(state, listId));
    const page = useSelector(state => selectListPage(state, listId));
    const pageCount = useSelector(state => selectListPageCount(state, listId));
    const itemsTotal = useSelector(state => selectListItemsTotal(state, listId));
    const orderBy = useSelector(state => selectListOrderBy(state, listId));

    const stableWith = useRef(withConfig);
    const stableStatic = useRef(staticParams);
    const stableContexts = useRef(contexts);

    const dataSelector = useMemo(
        () => (state, dataId) => selectListDataById(state, listId, dataId),
        [listId]
    );

    const fullSelector = useMemo(() => state => selectListData(state, listId, true), [listId]);

    const relatedSelector = useMemo(() => makeRelatedDataSelector(withConfig), [withConfig]);

    const handlePage = useCallbackFunc((event, newPage, force = false) => {
        if (initialized || force) {
            return dispatch(
                indexPage(listId, newPage, fetchParams, null, (force && !initialSearch) || index)
            );
        }

        return false;
    });

    const handleNextPage = useCallbackFunc(() => {
        return handlePage(null, page + 1);
    });

    const handleOrderBy = useCallbackFunc(newOrderBy => {
        if (initialized) {
            dispatch(saveOrderBy(newOrderBy, { listId }));
            handlePage(null, 1);
        }
    });

    const handleFetch = useCallbackFunc(params => {
        return dispatch(indexPage(listId, 1, { ...fetchParams, ...params }, null, true));
    });

    const handleSearch = useCallbackFunc(search => {
        if (initialized || (!autoload && !autoInitialize)) {
            return dispatch(filterList(listId, search, orderBy, fetchParams, null, index));
        }
        return Promise.resolve();
    });

    const handleClear = useCallbackFunc(() => dispatch(clearList({ listId })));

    const stableCriteria = useRef(criteria);

    useEffect(() => {
        if (!initialized) {
            dispatch(
                initList({
                    listId,
                    resource,
                    orderBy: initialOrderBy,
                    search: initialSearch,
                    continuous,
                    criteria: stableCriteria.current,
                    staticParams: {
                        limit,
                        ...stableStatic.current,
                        with: stableWith.current,
                        contexts: stableContexts.current,
                    },
                    unique,
                })
            );

            if (!loading && autoload) {
                handlePage(null, 1, true);
            }
        }
    }, [
        dispatch,
        initialized,
        listId,
        resource,
        initialOrderBy,
        continuous,
        autoload,
        handlePage,
        loading,
        initialSearch,
        stableCriteria,
        stableStatic,
        stableWith,
        stableContexts,
        limit,
    ]);

    useEffect(() => {
        if (autoload) {
            handlePage(null, 1);
        }
    }, [listId, fetchParams, contexts, autoload, handlePage]);

    useEffect(() => {
        if (autoInitialize && !initialized) {
            handlePage(null, 1, true);
        }
    }, [autoInitialize, initialized, handlePage]);

    useEffect(() => {
        if (updateWatch) {
            handlePage(null, 1, true);
        }
    }, [updateWatch, handlePage]);

    return useMemo(
        () => ({
            dataIds,
            dataSelector,
            fullSelector,
            relatedSelector,
            loading,
            initialized,
            page,
            pageCount,
            itemsTotal,
            orderBy,
            handlePage,
            handleNextPage,
            handleFetch,
            handleSearch,
            handleClear,
            handleOrderBy,
        }),
        [
            dataIds,
            dataSelector,
            fullSelector,
            relatedSelector,
            loading,
            initialized,
            page,
            pageCount,
            itemsTotal,
            orderBy,
            handlePage,
            handleNextPage,
            handleFetch,
            handleSearch,
            handleClear,
            handleOrderBy,
        ]
    );
};

/**
 *
 * @param listId
 * @param resource
 * @param groupFields
 * @param enableAggregates
 * @param columns
 *          array of column definition objects.
 *          requires a `aggregationConfig` property.
 *          aggregationConfig: {
 *              type: <AGGREGATION_TYPE>,
 *              fields: array of fields included or fallback, optional
 *              alias: string field name only used for aggregated values
 *              convertString: convert to displayed string when format should be different from non-aggregated values
 *          },
 * @param fetchParams
 * @param staticParams
 * @param contexts
 * @param initialOrderBy
 * @param initialSearch
 * @param autoload
 * @param criteria
 * @param waitForSearch
 * @return {{}}
 */
export const useResourceListGrouped = ({
    listId,
    resource,
    groupFields: originalFields = [],
    enableAggregates = false,
    columns,
    fetchParams,
    staticParams,
    contexts,
    initialOrderBy,
    initialSearch,
    autoload,
    criteria,
    waitForSearch = false,
}) => {
    const dispatch = useDispatch();

    const { groupConfigs, idKeyMapping } = useMemo(() => getRemoteGroupConfig(columns), [columns]);
    const groupFields = useMemo(
        () => originalFields.map(fieldName => idKeyMapping?.[fieldName] || fieldName),
        [originalFields, idKeyMapping]
    );
    const activeGroupConfigs = useMemo(
        () => getActiveGroupConfigs(groupConfigs, groupFields),
        [groupFields, groupConfigs]
    );

    const searchParams = useSelector(state => selectListSearch(state, listId));
    const aggregateTotalList = useSelector(state => selectListTotalAggregate(state, listId));
    const aggregateGroupList = useSelector(state =>
        selectListGroupByFieldsAggs(state, listId, groupFields)
    );
    const groups = useSelector(state => selectListGroupByFieldsGroups(state, listId, groupFields));

    /* this is true if the list was loaded at least once - NOT if initList was called or not! */
    const initialized = useSelector(state => selectListIsInitialized(state, listId));
    const aggsLoading = useSelector(state => selectListIsAggregatesLoading(state, listId));
    const groupsLoading = useSelector(state =>
        selectListGroupByFieldsLoading(state, listId, groupFields)
    );

    const orderBy = useSelector(state => selectListOrderBy(state, listId));

    const nestedLists = useSelector(state =>
        selectListGroupByFieldsNestedLists(state, listId, groupFields)
    );
    const fullListById = useSelector(state => selectResourceDataById(state, listId));

    const lastLoadGroups = useRef(null);
    const lastLoadAggs = useRef(null);
    const lastSearch = useRef(null);

    const isGrouped = useMemo(
        () => Array.isArray(groupFields) && groupFields.length,
        [groupFields]
    );

    const loading = useMemo(
        () => (isGrouped ? groupsLoading : aggsLoading),
        [isGrouped, groupsLoading, aggsLoading]
    );

    const aggregateTotal = useMemo(
        () => (isGrouped ? aggregateGroupList : aggregateTotalList),
        [isGrouped, aggregateGroupList, aggregateTotalList]
    );

    const { aggregationParams, aggregationConfig } = useMemo(
        () =>
            getRemoteAggregationConfig([
                ...columns,
                {
                    accessorKey: 'total',
                    aggregationConfig: {
                        type: AGGREGATION_TYPE.COUNT,
                        fields: ['id'],
                    },
                },
            ]),
        [columns]
    );

    const handleLoad = useCallbackFunc((params = {}) => {
        const includedParams = paramCleanup(params);

        const nextParams = {
            aggs: aggregationParams,
            ...(isGrouped ? { group_by: activeGroupConfigs } : {}),
            ...includedParams,
        };

        if (isGrouped) {
            dispatch(groupList(listId, nextParams));
        } else if (enableAggregates) {
            dispatch(aggregateList(listId, nextParams));
        }
    });

    useEffect(() => {
        if (
            autoload &&
            (!waitForSearch || searchParams?.filters) &&
            ((isGrouped && !loading) || (enableAggregates && !aggsLoading))
        ) {
            const nextSearch = JSON.stringify(searchParams?.filters);
            const searchChanged = lastSearch.current !== nextSearch;

            const nextLoadGroups = groupFields.join();
            const groupsChanged = nextLoadGroups !== lastLoadGroups.current;

            const nextAggs = [
                ...Object.values(aggregationParams),
                ...Object.keys(aggregationParams),
            ].join();
            const aggregatesChanged = lastLoadAggs.current !== nextAggs;

            if (searchChanged || groupsChanged || aggregatesChanged) {
                lastLoadGroups.current = nextLoadGroups;
                lastLoadAggs.current = nextAggs;
                lastSearch.current = nextSearch;

                handleLoad({ ...fetchParams, ...searchParams });
            }
        }
    }, [
        groupFields,
        aggregationParams,
        isGrouped,
        enableAggregates,
        autoload,
        loading,
        fetchParams,
        searchParams,
    ]);

    return useMemo(
        () => ({
            isGrouped,
            groupFields,
            groups,
            aggregateTotal,
            loading,
            initialized,
            orderBy,
            activeGroupConfigs,
            nestedLists,
            fullListById,
        }),
        [
            isGrouped,
            groupFields,
            groups,
            aggregateTotal,
            loading,
            initialized,
            orderBy,
            activeGroupConfigs,
            nestedLists,
            fullListById,
        ]
    );
};

/**
 * Merge Nested Models to a List
 * @param list given list without nested models
 * @param subSelectors array of selector configs (i.e. { model: 'task', selector: listByIdSelectorFunction(), path: 'dot.notated.nested' *fallback:nestedOn1stLayer, identifier: 'task_id' *fallback:<model>_id, idNested: true *fallback:false })
 * @return merged given list with merged nested models
 */
export const useResourceListNestedSelects = ({ list = [], nestedSelectors = [] }) => {
    const configWithValues = nestedSelectors.map(({ selector, ...config }) => {
        const valuesById = useSelector(selector);

        return {
            valuesById,
            ...config,
        };
    });

    return useMemo(
        () =>
            list.map(item => {
                if (item) {
                    const newProps = configWithValues.reduce(
                        (
                            carry,
                            { model, valuesById, path = null, identifier = null, idNested = false }
                        ) => {
                            const identifierOptions = [`${model}_id`, `${model}_by`];
                            const finalIdentifier =
                                identifier || identifierOptions.find(ident => ident in item);
                            const id = item[finalIdentifier];

                            if (path) {
                                //ToDo: Deep nested still WIP untested
                                const currentMergedProps = { ...carry, ...item };
                                const nestedSteps = path.split('.');

                                const { layer, missingSteps } = getFromNestedObject(
                                    nestedSteps,
                                    currentMergedProps
                                );

                                const nestedId =
                                    idNested && finalIdentifier in layer
                                        ? layer[finalIdentifier]
                                        : id;

                                if (nestedId in valuesById) {
                                    const layers = applyToNestedObject(
                                        nestedSteps,
                                        carry,
                                        { ...layer, [model]: valuesById[nestedId] },
                                        missingSteps
                                    );

                                    return {
                                        ...carry,
                                        ...layers,
                                    };
                                }

                                return carry;
                            }

                            if (id in valuesById) {
                                const value = valuesById[id];

                                return {
                                    ...carry,
                                    [model]: value,
                                };
                            }

                            return carry;
                        },
                        {}
                    );

                    return {
                        ...item,
                        ...newProps,
                    };
                }

                return {};
            }),
        [list, configWithValues]
    );
};

export const useAutoPaginate = ({ active = true, handlePage = null, delay = 128 }) => {
    const handlePaginate = (page = 1) => {
        handlePage(null, page, true).then(({ meta }) => {
            if (typeof meta === 'object') {
                const { current_page, last_page } = meta;

                if (current_page < last_page) {
                    if (typeof delay === 'number') {
                        setTimeout(() => {
                            handlePaginate(current_page + 1);
                        }, delay);
                    } else {
                        handlePaginate(current_page + 1);
                    }
                }
            }
        });
    };

    useEffect(() => {
        if (active && typeof handlePage === 'function') {
            handlePaginate();
        }
    }, [active, handlePage]);
};

export const useOrderedFullSelect = (dataIds, fullSelector) => {
    const itemsById = useSelector(fullSelector);

    return useMemo(() => {
        if (Array.isArray(dataIds) && dataIds.length && typeof itemsById === 'object') {
            /*return dataIds ToDo: fix sort in resource list state
                .reduce((carry, id) => {
                    if (id in itemsById) {
                        return [...carry, itemsById[id]];
                    }

                    return carry;
                }, [])*/
            return Object.values(itemsById).sort((a, b) => a.order - b.order);
        }

        return [];
    }, [dataIds, itemsById]);
};
