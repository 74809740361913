import React, { useEffect, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import TextInput from '../../form/formik/TextInput';
import Form from '../../form/formik/Form';
import { IdPropType } from '../../../modules/proptypes';
import LoadingBlock from '../../loading/LoadingBlock';
import ScheduleTable from '../scheduleTable/ScheduleTable';
import DateInput from '../../form/formik/DateInput';
import { selectContractById } from '../../../modules/contracts/selectors';
import { showContract, storeContract, updateContract } from '../../../modules/contracts/actions';
import { Portal } from '@mui/material';
import EmployeeActions from './EmployeeActions';
import { useSnackbar } from '../../../modules/snackbar/hooks';
import {
    formatWorkTimes,
    getDays,
    getShifts,
    transformWorktimesToTransportFormat,
} from '../scheduleTable/util';
import getContractSchema from '../../../modules/contracts/schema';
import { CONTRACT_RESOURCE } from '../../../modules/api/resources';
import { showEmployee } from '../../../modules/employees/actions';

// Temp
const days = {
    0: 'Montag',
    1: 'Dienstag',
    2: 'Mittwoch',
    3: 'Donnerstag',
    4: 'Freitag',
    5: 'Samstag',
    6: 'Sonntag',
};
const blocks = {
    0: 'Vormittag',
    1: 'Vormittag',
    2: 'Nachmittag',
    3: 'Nachmittag',
};

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    item: {
        flex: 1,
        flexShrink: 0,
    },
    inputRow: {
        width: '100%',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    h3: {
        color: theme.palette.primary.main,
        paddingBottom: theme.spacing(2),
        fontSize: '1rem',
    },
    h4: {
        color: theme.palette.primary.main,
        paddingBottom: theme.spacing(2),
        fontSize: '.875rem',
    },
}));

const generateIntitialValues = (days, blocks) => {
    const r = {};
    Object.keys(days).forEach(day => {
        Object.keys(blocks).forEach(block => {
            r[day + '_' + block + '_start'] = '';
            r[day + '_' + block + '_end'] = '';
        });
    });

    return r;
};

const fixGermanFloat = value => {
    if (String(value).includes('.')) {
        return value;
    }

    return +String(value).replace(/,/, '.');
};

const EmployeeContract = ({ employeeId, contractId, submitRef }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    const [contractSchema, setContractSchema] = useState({});
    const [initialValues, setInitialValues] = useState({
        personnel_number: '',
        started_at: null,
        ended_at: null,
        vacation_days: '',
        cancellation_period: '',
        contract_work_times: generateIntitialValues(days, blocks),
    });

    const contract = useSelector(state => selectContractById(state, contractId));

    useEffect(() => {
        if (!contractId) {
            return;
        }

        setLoading(true);
        dispatch(showContract({ id: contractId })).then(_ => {
            setLoading(false);
        });
    }, [contractId, dispatch]);

    useEffect(() => {
        if (contract) {
            setInitialValues({
                ...contract,
                contract_work_times: {
                    ...generateIntitialValues(days, blocks),
                    ...formatWorkTimes(contract.contract_work_times),
                },
            });
        }
    }, [contract]);

    useEffect(() => {
        setContractSchema(
            getContractSchema(
                Object.keys(days),
                Object.keys(blocks),
                Object.keys(initialValues.contract_work_times)
            )
        );
    }, [initialValues]);

    const formattedDays = useMemo(() => {
        return getDays(days);
    }, []);

    const formattedBlocks = useMemo(() => {
        return getShifts(blocks);
    }, []);

    const handleSubmit = async (values, { resetForm }) => {
        const preppedValues = {
            ...values,
            vacation_days: values.vacation_days ? fixGermanFloat(values.vacation_days) : null,
            contract_work_times: transformWorktimesToTransportFormat(values.contract_work_times),
            employee_id: employeeId,
        };

        await dispatch(values.id ? updateContract(preppedValues) : storeContract(preppedValues));

        // Reload contract_id relation
        if (!values.id) {
            await dispatch(showEmployee({ id: employeeId }));
        }

        enqueueSnackbar(t('components.ContactBaseData.contactSaved'), {
            variant: 'success',
        });

        if (resetForm) {
            //    resetForm();
        }

        return Promise.resolve();
    };

    return (
        <LoadingBlock isLoading={loading}>
            <Grid container className={classes.root} spacing={2}>
                <Grid item className={classes.item}>
                    <Typography variant="h3" className={classes.h3}>
                        {t('components.EmployeeContract.title')}
                    </Typography>

                    <Form
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={contractSchema}
                        subject={contract || CONTRACT_RESOURCE}
                        enableReinitialize
                    >
                        <Grid container spacing={4}>
                            <Grid item xl={9}>
                                <Typography variant="h4" className={classes.h4}>
                                    {t('components.EmployeeContract.schedule')}
                                </Typography>
                                <ScheduleTable
                                    name={'contract_work_times'}
                                    days={formattedDays}
                                    shifts={formattedBlocks}
                                />
                            </Grid>
                            <Grid item xl={3}>
                                <Typography variant="h4" className={classes.h4}>
                                    {t('components.EmployeeContract.Data')}
                                </Typography>

                                <TextInput
                                    name="personnel_number"
                                    label={t('components.EmployeeContract.personnel_number')}
                                    className={classes.inputRow}
                                    size="small"
                                />

                                <Grid container spacing={2}>
                                    <Grid item xs>
                                        <DateInput
                                            name="started_at"
                                            label={t('components.EmployeeContract.started_at')}
                                            className={classes.inputRow}
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xs>
                                        <DateInput
                                            name="ended_at"
                                            label={t('components.EmployeeContract.ended_at')}
                                            className={classes.inputRow}
                                            size="small"
                                        />
                                    </Grid>
                                </Grid>

                                <TextInput
                                    name="vacation_days"
                                    label={t('components.EmployeeContract.vacation_days')}
                                    className={classes.inputRow}
                                    size="small"
                                />
                                <TextInput
                                    name="cancellation_period"
                                    label={t('components.EmployeeContract.cancellation_period')}
                                    className={classes.inputRow}
                                    size="small"
                                />

                                <Portal container={submitRef.current}>
                                    <EmployeeActions />
                                </Portal>
                            </Grid>
                        </Grid>
                    </Form>
                </Grid>
            </Grid>
        </LoadingBlock>
    );
};

EmployeeContract.propTypes = {
    employeeId: IdPropType,
    updatedContact: PropTypes.shape({
        person_id: IdPropType,
        company_id: IdPropType,
    }),
    onChange: PropTypes.func,
    submitRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
};

EmployeeContract.defaultProps = {
    updatedContact: null,
    employeeId: null,
    onChange: null,
};

export default EmployeeContract;
