import React, { useEffect, useMemo, useRef } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import { useAppointmentLoad } from '../../../modules/appointments/hooks';
import Select from './Select';
import { IdPropType } from '../../../modules/proptypes';
import LoadingIcon from '../../loading/LoadingIcon';

const AppointmentSelect = ({ name, taskId, namePreventReset, label, ...other }) => {
    const { t } = useTranslation();
    const lastTaskId = useRef(taskId);
    const [{ value }, , { setValue }] = useField(name);
    const [{ value: namedValue }] = useField(namePreventReset);
    const lastNamed = useRef(namedValue);

    const { appointments, loading } = useAppointmentLoad({
        taskId,
        fetchParams: { limit: 100 },
        autoPaginate: true,
    });

    const options = useMemo(() => {
        return [
            ...appointments.map(({ id, name, name_inherited }) => ({
                value: id,
                label: name_inherited || name,
            })),
            { value: null, label: t('form.AppointmentSelect.empty') },
        ];
    }, [appointments]);

    useEffect(() => {
        if (taskId !== lastTaskId.current) {
            if (
                (!namePreventReset || namedValue === lastNamed.current) &&
                value &&
                lastTaskId.current
            ) {
                setValue(null);
            }

            lastTaskId.current = taskId;
            lastNamed.current = namedValue;
        }
    }, [taskId, value, namePreventReset, namedValue]);

    return (
        <LoadingIcon isLoading={loading} size="small">
            <Select
                label={label || t('form.AppointmentSelect.label')}
                name={name}
                options={options}
                disabled={!taskId}
                size="small"
                {...other}
            />
        </LoadingIcon>
    );
};

AppointmentSelect.propTypes = {
    name: PropTypes.string.isRequired,
    taskId: IdPropType.isRequired,
    namePreventReset: PropTypes.string,
    label: PropTypes.string,
};

AppointmentSelect.defaultProps = {
    namePreventReset: null,
    label: null,
};

export default AppointmentSelect;
