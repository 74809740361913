import React from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';

const generateErrorTooltipStyle = (extras = {}) => {
    return makeStyles(theme => {
        const defaults = {
            tooltip: {
                position: 'absolute',
                background: theme.palette.error.light,
                top: '85%',
                left: '50%',
                marginLeft: ' -75px !important',
                width: '150px',
                textAlign: 'center',
                color: 'white !important',
                margin: '0 !important',
                padding: '4px !important',
                zIndex: '1000',
                boxShadow: '1px 1px 5px rgb(0 0 0 / 20%)',

                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: '-10px',
                    left: '50%',
                    marginLeft: '-5px',
                    borderWidth: '5px',
                    borderStyle: 'solid',
                    borderColor: `transparent transparent ${theme.palette.error.light} transparent`,
                },
            },
        };

        return Object.assign(defaults, extras);
    });
};

const useStyles = generateErrorTooltipStyle();

const ErrorTooltip = ({ children }) => {
    const classes = useStyles();

    return (
        <Typography variant="body2" className={classes.tooltip}>
            {children}
        </Typography>
    );
};

ErrorTooltip.propTypes = {
    children: PropTypes.node,
};

ErrorTooltip.defaultProps = {
    children: null,
};

export { ErrorTooltip, generateErrorTooltipStyle };
