import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useField } from 'formik';
import Avatars from '../../icons/Avatars';
import { Grid, Icon } from '@mui/material';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    avatar: {
        height: 32,
        minWidth: 32,
    },
});

export const AppointmentFormResponsibles = () => {
    const [{ value: taskResponsibles }] = useField('task.responsibles');
    const [{ value: cardResponsibles }] = useField('card.responsibles');

    return (
        <AppointmentResponsibles
            taskResponsibles={taskResponsibles}
            cardResponsibles={cardResponsibles}
        />
    );
};

const AppointmentResponsibles = ({ taskResponsibles, cardResponsibles }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const responsibles = useMemo(() => {
        if (taskResponsibles && Array.isArray(taskResponsibles) && taskResponsibles.length) {
            return taskResponsibles;
        }

        if (cardResponsibles && Array.isArray(cardResponsibles) && cardResponsibles.length) {
            return cardResponsibles;
        }

        return [];
    }, [taskResponsibles, cardResponsibles]);

    return responsibles.length > 0 ? (
        <Grid container mt={2} mb={1} alignItems="center">
            <Grid item className={classes.avatar}>
                <Avatars userIds={responsibles} dense small />
            </Grid>
            <Grid item ml={1}>
                <Typography color="textSecondary">
                    {t(
                        `components.AppointmentResponsibles.${
                            taskResponsibles &&
                            Array.isArray(taskResponsibles) &&
                            taskResponsibles.length
                                ? 'taskDesc'
                                : 'cardDesc'
                        }`
                    )}
                </Typography>
            </Grid>
        </Grid>
    ) : null;
};

AppointmentResponsibles.propTypes = {
    taskResponsibles: PropTypes.arrayOf(PropTypes.number),
    cardResponsibles: PropTypes.arrayOf(PropTypes.number),
};

AppointmentResponsibles.defaultProps = {
    taskResponsibles: null,
    cardResponsibles: null,
};

export default AppointmentResponsibles;
