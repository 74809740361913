import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { UserPropType } from '../../modules/users/proptypes';
import { ASSIGNED } from '../../modules/responsibles/types';
import BaseAvatar from './BaseAvatar';
import { useSelector } from 'react-redux';
import { selectUserById } from '../../modules/users/selectors';
import { IdPropType } from '../../modules/proptypes';

const selectUserName = (user, fallback = 'u') => {
    if (user) {
        if (user.display_name && user.display_name.length !== 0) {
            return user.display_name;
        }

        if (user.username && user.username.length !== 0) {
            return user.username;
        }
    }

    return fallback;
};

const Avatar = ({
    user: overrideUser,
    userId,
    name,
    extra,
    withBorder,
    disableHover,
    onDelete,
    small,
    tiny,
    crown,
    type,
    hoverExtra,
    disablePortal,
    inline,
    fixedPosition,
    color,
    label,
    showTooltip,
    ...other
}) => {
    const { t } = useTranslation();
    const userFromId = useSelector(state => selectUserById(state, userId));

    const user = useMemo(() => overrideUser || userFromId, [overrideUser, userFromId]);

    const displayName = selectUserName(user, t('components.Avatar.deletedDisplayName'));

    const finalColor = useMemo(() => {
        if (color) {
            return color;
        }

        if (user && user.color) {
            return `${user.color.startsWith('#') ? '' : '#'}${user.color}`;
        }

        return null;
    }, [user, color]);

    return (
        <BaseAvatar
            name={name}
            displayName={displayName}
            shortName={user?.display_name_short}
            path={user?.avatar}
            extra={extra}
            withBorder={withBorder}
            disableHover={disableHover}
            onDelete={onDelete}
            small={small}
            tiny={tiny}
            crown={crown}
            type={type}
            hoverExtra={hoverExtra}
            disablePortal={disablePortal}
            inline={inline}
            fixedPosition={fixedPosition}
            color={finalColor}
            label={label ? label : showTooltip ? `${t('components.Avatar.assigned.to')}` : null}
            {...other}
        />
    );
};

Avatar.propTypes = {
    user: UserPropType,
    userId: IdPropType,
    name: PropTypes.bool,
    extra: PropTypes.string,
    withBorder: PropTypes.bool,
    disableHover: PropTypes.bool,
    onDelete: PropTypes.func,
    small: PropTypes.bool,
    tiny: PropTypes.bool,
    crown: PropTypes.bool,
    hoverExtra: PropTypes.node,
    disablePortal: PropTypes.bool,
    inline: PropTypes.bool,
    fixedPosition: PropTypes.bool,
    type: PropTypes.string,
    label: PropTypes.string,
    showTooltip: PropTypes.bool,
};

Avatar.defaultProps = {
    user: null,
    userId: null,
    name: false,
    extra: null,
    withBorder: false,
    disableHover: false,
    onDelete: null,
    small: false,
    tiny: false,
    crown: false,
    hoverExtra: null,
    disablePortal: false,
    inline: false,
    fixedPosition: false,
    type: ASSIGNED,
    label: null,
    showTooltip: false,
};

export default Avatar;
