import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';

const useStyles = makeStyles({
    root: {
        position: 'relative',
        lineHeight: 0,
    },

    loading: {
        position: 'absolute',
        textAlign: 'center',
        top: 8,
        left: 0,
        right: 0,
    },

    small: {
        top: '50%',
        marginTop: -8, // half the size
    },
});

const getSize = (size, dimensions) => {
    switch (size) {
        case 'small':
            return 16;
        default:
            return Math.min(dimensions.width, dimensions.height) - 10;
    }
};

const FadeContainer = React.forwardRef((props, ref) => <Box {...props} ref={ref} />);

const LoadingIcon = ({ isLoading, children, className, size, ...other }) => {
    const classes = useStyles();
    const ref = useRef(null);
    const [dimensions, setDimensions] = React.useState(null);

    React.useEffect(() => {
        if (isLoading && !dimensions) {
            const dim = ref.current.getBoundingClientRect();
            setDimensions({ width: dim.width, height: dim.height });
        }
    }, [ref, isLoading, dimensions]);

    return (
        <Box ref={ref} className={classes.root}>
            <Fade
                in={!isLoading}
                style={{
                    transitionDelay: isLoading ? '300ms' : '0ms',
                }}
                timeout={{
                    enter: 300,
                    exit: 800,
                }}
            >
                <FadeContainer>{children}</FadeContainer>
            </Fade>
            <Fade
                in={isLoading}
                className={classNames(classes.loading, className, {
                    [classes.small]: size === 'small',
                })}
                style={{
                    transitionDelay: isLoading ? '800ms' : '0ms',
                }}
                timeout={{
                    enter: 1000,
                    exit: 0,
                }}
                unmountOnExit
            >
                <FadeContainer>
                    {dimensions && <CircularProgress size={getSize(size, dimensions)} {...other} />}
                </FadeContainer>
            </Fade>
        </Box>
    );
};

LoadingIcon.propTypes = {
    isLoading: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    size: PropTypes.string,
};

LoadingIcon.defaultProps = {
    isLoading: false,
    children: null,
    className: null,
    size: 'auto',
};

export default LoadingIcon;
