import { GROUP_RESOURCE } from '../api/resources';

export const selectAllGroupsById = state => state[GROUP_RESOURCE].byId;

export const selectGroupsByIds = (state, idArray) => {
    let results = [];
    const groupsById = selectAllGroupsById(state);
    idArray.forEach(id => {
        if (groupsById[id]) {
            results.push(groupsById[id]);
        }
    });
    return results;
};

export const selectGroupById = (state, id) => selectAllGroupsById(state)[id];
