import { createSlice } from '@reduxjs/toolkit';
import { PROTOCOL_ITEM_TYPE_RESOURCE } from '../api/resources';

const protocolItemTypeSlice = createSlice({
    name: PROTOCOL_ITEM_TYPE_RESOURCE,
    initialState: {
        byId: {},
        allIds: [],
        error: null,
        loading: false,
    },
    reducers: {
        indexPending: state => {
            state.loading = true;
        },
        indexFulfilled: (state, action) => {
            state.byId = {};
            state.allIds = [];
            state.loading = false;

            action.payload.forEach(protocolItemType => {
                state.byId[protocolItemType.id] = protocolItemType;
                state.allIds.push(protocolItemType.id);
            });
        },
        indexError: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
        showFulfilled: (state, action) => {
            const protocolItemType = action.payload;
            state.byId[protocolItemType.id] = protocolItemType;
            state.allIds = [...new Set([...state.allIds, protocolItemType.id])];
        },
        storeFulfilled: (state, action) => {
            const protocolItemType = action.payload;
            state.byId[protocolItemType.id] = protocolItemType;
            state.allIds.push(protocolItemType.id);
        },
        updateFulfilled: (state, action) => {
            const protocolItemType = action.payload;
            state.byId[protocolItemType.id] = protocolItemType;
            state.allIds.push(protocolItemType.id);
        },
        destroyFulfilled: (state, action) => {
            const id = action.payload;

            const index = state.allIds.findIndex(_id => _id === id);
            if (index >= 0) {
                delete state.byId[id];
                state.allIds.splice(index, 1);
            }
        },
    },
});

export const {
    indexPending,
    indexFulfilled,
    indexError,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,
} = protocolItemTypeSlice.actions;

export default protocolItemTypeSlice.reducer;
