import { CONTACTABLE_RESOURCE } from '../api/resources';

export const indexContactables = params => (dispatch, getState, api) =>
    api[CONTACTABLE_RESOURCE].index(params);

export const storeContactable = params => (dispatch, getState, api) =>
    api[CONTACTABLE_RESOURCE].store(params);

export const updateContactable = params => (dispatch, getState, api) =>
    api[CONTACTABLE_RESOURCE].update(params);

export const destroyContactable = params => (dispatch, getState, api) =>
    api[CONTACTABLE_RESOURCE].destroy(params);

export const multiupdateContactable = params => (dispatch, getState, api) =>
    api[CONTACTABLE_RESOURCE].multiupdate(params);
