import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { IdPropType } from '../../modules/proptypes';

const CardIdContext = createContext(null);

export const useCardId = () => useContext(CardIdContext);

const CardIdProvider = ({ children, cardId }) => (
    <CardIdContext.Provider value={cardId}>{children}</CardIdContext.Provider>
);

CardIdContext.propTypes = {
    cardId: IdPropType.isRequired,
    children: PropTypes.node.isRequired,
};

export default CardIdProvider;
