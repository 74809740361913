/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
    storeFulfilled as searchStoreFulfilled,
    updateFulfilled as searchUpdateFulfilled,
    destroyFulfilled as searchDestroyFulfilled,
} from '../searches/searchesSlice';

const insertSearch = (state, search) => {
    if (!state.user.searches) {
        state.user.searches = [];
    }

    const staleIndex = state.user.searches.findIndex(_search => _search.id === search.id);
    if (staleIndex > -1) {
        state.user.searches.splice(staleIndex, 1);
    }
    state.user.searches.push(search);
};

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        error: null,
        isInitialized: false,
    },
    reducers: {
        loginSuccess: (state, action) => {
            if (!state.user || state.user?.id !== action.payload.id) {
                state.user = action.payload;
            }

            if (!state.isInitialized) {
                state.isInitialized = true;
            }
        },
        loginError: {
            prepare: error => ({
                payload: error,
                error: true,
            }),
            reducer: (state, action) => {
                state.user = null;
                if (state.isInitialized) {
                    state.error = action.payload;
                }
                state.isInitialized = true;
            },
        },
        logoutSuccess: state => {
            state.user = null;
        },
        updateAuthUser: (state, action) => {
            // TODO overwrite authUser with full updated user instead of only kumo_connect ??
            state.user = { ...state.user, kumo_connect: action.payload };
        },
        setTmpKumoOutlookUserToken: (state, action) => {
            //ToDo: rename key to a cleaner and more general name
            state._tmpKumoOutlookUserToken = action.payload;
        },
    },
    extraReducers: {
        [searchStoreFulfilled]: (state, action) => {
            insertSearch(state, action.payload);
        },
        [searchUpdateFulfilled]: (state, action) => {
            insertSearch(state, action.payload);
        },
        [searchDestroyFulfilled]: (state, action) => {
            if (!state.user.searches) {
                return;
            }

            const index = state.user.searches.findIndex(search => search.id === action.payload);
            if (index > -1) {
                state.user.searches.splice(index, 1);
            }
        },
    },
});

export const {
    loginPending,
    loginSuccess,
    loginError,
    logoutPending,
    logoutSuccess,
    updateAuthUser,
    setTmpKumoOutlookUserToken,
} = authSlice.actions;

export default authSlice.reducer;
