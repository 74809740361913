import { put, takeEvery } from 'redux-saga/effects';
import { ACTIVITY_RESOURCE } from '../api/resources';
import { indexFulfilled as appointmentIndexFulfilled } from '../appointments/appointmentSlice';

function* nestedIndexFulfilled(action) {
    const appointments = action.payload?.reduce((carry, activity) => {
        if (activity.appointment) {
            carry.push(activity.appointment);
        }
        return carry;
    }, []);
    if (appointments && appointments.length > 0) {
        yield put(appointmentIndexFulfilled(appointments, { key: ACTIVITY_RESOURCE }));
    }
}

export default [takeEvery(`${ACTIVITY_RESOURCE}/indexFulfilled`, nestedIndexFulfilled)];
