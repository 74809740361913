import React, { useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Form from '../form/formik/Form';
import { IdPropType, RefPropType } from '../../modules/proptypes';
import LoadingBox from '../loading/LoadingBox';
import { useTranslation } from 'react-i18next';
import SubmitButton from '../form/formik/SubmitButton';
import FormPopup from '../popups/FormPopup';
import { SubjectPropType } from '../../modules/abilities/proptypes';
import { PERSON_FUNCTION_RESOURCE } from '../../modules/api/resources';
import { selectPersonFunctionById } from '../../modules/personFunction/selectors';
import {
    destroyPersonFunction,
    storePersonFunction,
    updatePersonFunction,
} from '../../modules/personFunction/actions';
import { personFunctionSchema } from '../../modules/personFunction/schema';
import PersonFunctionFormBody from './PersonFunctionFormBody';

const PersonFunctionForm = ({
    personFunctionId,
    asPopup,
    openPopupWith,
    popupStateRef,
    onLoading,
    initialValues: initialValuesProps,
    onSubmit,
    subject,
    validationSchema,
    actionsComponent,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const personFunction = useSelector(state => selectPersonFunctionById(state, personFunctionId));

    const initialValues = useMemo(
        () =>
            personFunction ||
            initialValuesProps || {
                name: '',
                id_ext: null,
            },
        [personFunction, initialValuesProps]
    );

    const handleSubmit = newValue => {
        setLoading(true);
        if (onLoading) onLoading(true);
        return dispatch(
            personFunctionId ? updatePersonFunction(newValue) : storePersonFunction(newValue)
        ).finally(() => {
            setLoading(false);
            if (onLoading) onLoading(false);
        });
    };

    const handleDelete = () => dispatch(destroyPersonFunction({ id: personFunctionId }));

    return asPopup ? (
        <FormPopup
            popupId="personFunctionPopupForm"
            popupStateRef={popupStateRef}
            initialValues={initialValues}
            validationSchema={validationSchema || personFunctionSchema}
            onSubmit={onSubmit || handleSubmit}
            onDelete={personFunctionId ? handleDelete : null}
            submitLabel={t('form.SubmitButton.defaultLabel')}
            abortLabel={t('abort')}
            enableReinitialize
            FormInputs={
                <Box
                    onKeyDown={e => {
                        if (e.code !== 'Escape') {
                            e.stopPropagation();
                        }
                    }}
                >
                    <PersonFunctionFormBody autoFocus />
                </Box>
            }
        >
            <Grid container>
                <Grid item>
                    <LoadingBox loading={loading} variant="circular" size="small">
                        {openPopupWith}
                    </LoadingBox>
                </Grid>
            </Grid>
        </FormPopup>
    ) : (
        <Form
            initialValues={initialValues}
            subject={subject}
            validationSchema={validationSchema || personFunctionSchema}
            onSubmit={onSubmit || handleSubmit}
        >
            <PersonFunctionFormBody />
            <Grid container spacing={2} justifyContent="flex-end">
                {actionsComponent ? (
                    actionsComponent
                ) : (
                    <SubmitButton>{t('form.SubmitButton.defaultLabel')}</SubmitButton>
                )}
            </Grid>
        </Form>
    );
};

PersonFunctionForm.propTypes = {
    personFunctionId: IdPropType,
    asPopup: PropTypes.bool,
    openPopupWith: PropTypes.node,
    popupStateRef: RefPropType,
    onLoading: PropTypes.func,
    initialValues: PropTypes.shape({}),
    onSubmit: PropTypes.func,
    subject: SubjectPropType,
    validationSchema: PropTypes.shape({}),
    actionsComponent: PropTypes.node,
};

PersonFunctionForm.defaultProps = {
    personFunctionId: null,
    asPopup: false,
    openPopupWith: null,
    popupStateRef: null,
    onLoading: null,
    initialValues: null,
    onSubmit: null,
    subject: PERSON_FUNCTION_RESOURCE,
    validationSchema: null,
    actionsComponent: null,
};

export default PersonFunctionForm;
