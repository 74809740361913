import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectTaskById, showTask } from '../../../modules/tasks/tasksSlice';
import { CALENDAR_RESOURCE_MODELS } from '../../../modules/calendar/utils';
import { TASK_RESOURCE } from '../../../modules/api/resources';
import OpenIconButton from '../../buttons/OpenIconButton';
import { useField } from 'formik';
import { Grid } from '@mui/material';
import LoadingIcon from '../../loading/LoadingIcon';
import { showDialog } from '../../../modules/dialogs/dialogSlice';
import { TASK_DIALOG } from '../../dialogs/dialogs';
import { selectCardById } from '../../../modules/cards/selectors';

const CalendarFormTaskLink = ({ name }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [taskLoading, setTaskLoading] = useState(false);

    const taskTypeValue = CALENDAR_RESOURCE_MODELS[TASK_RESOURCE];

    const [{ value: selectedId }] = useField(`${name}Id`);
    const [{ value: selectedType }] = useField(`${name}Type`);

    const selectedTask = useSelector(state => selectTaskById(state, selectedId));
    const selectedTaskCard = useSelector(state => selectCardById(state, selectedTask?.card_id));

    const linkText = useMemo(() => {
        const base = t('components.CalendarAppointmentForm.taskLink');
        if (selectedTask?.name) {
            if (selectedTaskCard?.id_order) {
                return `${base}: ${selectedTask.name} (${selectedTaskCard.id_order})`;
            }

            return `${base}: ${selectedTask.name}`;
        }
        return base;
    }, [selectedTask, selectedTaskCard, t]);

    useEffect(() => {
        if (!selectedTask && !taskLoading && selectedType === taskTypeValue && selectedId) {
            setTaskLoading(true);

            dispatch(showTask({ id: selectedId })).then(() => setTaskLoading(false));
        }
    }, [selectedTask, taskLoading, selectedType, selectedId, taskTypeValue, setTaskLoading]);

    if (selectedType !== taskTypeValue || !selectedId || !selectedTask || taskLoading) {
        return null;
    }

    const handleOpen = (taskId = -1, props = {}) => {
        dispatch(showDialog({ type: TASK_DIALOG, props: { taskId, ...props } }));
    };

    return selectedId ? (
        <Grid item>
            <LoadingIcon isLoading={taskLoading}>
                <OpenIconButton
                    onClick={() => handleOpen(selectedId)}
                    subject={TASK_RESOURCE}
                    label={linkText}
                    color="primary"
                    size="small"
                />
            </LoadingIcon>
        </Grid>
    ) : null;
};

CalendarFormTaskLink.propTypes = {};

export default CalendarFormTaskLink;
