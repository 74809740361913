import React from 'react';
import * as PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Link from '../utils/Link';

const PHONE_METHODS = ['mobile', 'fax', 'phone'];
const MAIL_METHODS = ['email'];
const URL_METHODS = ['web'];
const ALL_LINK_METHODS = [...PHONE_METHODS, ...MAIL_METHODS, ...URL_METHODS];

const ContactLink = ({ value, method, variant, className }) => {
    let linkPrepend;
    if (PHONE_METHODS.includes(method)) {
        linkPrepend = 'tel';
    } else if (MAIL_METHODS.includes(method)) {
        linkPrepend = 'mailto';
    }

    return (
        <>
            {ALL_LINK_METHODS.includes(method) ? (
                <Link
                    to={linkPrepend ? `${linkPrepend}:${value}` : value}
                    variant={variant}
                    className={className}
                    noBlank={!!linkPrepend}
                    external
                >
                    {value && PHONE_METHODS.includes(method)
                        ? value.match(/.{1,4}/g).join(' ')
                        : value}
                </Link>
            ) : (
                <Typography variant={variant} className={className}>
                    {value}
                </Typography>
            )}
        </>
    );
};

ContactLink.propTypes = {
    value: PropTypes.string.isRequired,
    method: PropTypes.string,
    className: PropTypes.string,
    variant: PropTypes.string,
};

ContactLink.defaultProps = {
    variant: 'body2',
    method: undefined,
    className: undefined,
};

export default ContactLink;
