import React from 'react';
import * as PropTypes from 'prop-types';
import { TableActionPropType } from './proptypes';

const TableAction = ({ action, data, compact }) => {
    const { action: Action, compact: Compact } = action;

    const Component = (compact && Compact) || Action;

    return typeof Component === 'function' ? <Component data={data} /> : Component;
};

TableAction.propTypes = {
    action: TableActionPropType.isRequired,
    data: PropTypes.shape({}),
    compact: PropTypes.bool,
};

TableAction.defaultProps = {
    data: null,
    compact: false,
};

export default TableAction;
