import { applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';

import createReducer from './reducers';
import sagas from './sagas';

const configureStore = (api, preloadedState = {}, extraMiddlewares = []) => {
    const sagaMiddleware = createSagaMiddleware({
        context: { api },
    });
    const middlewares = [
        thunkMiddleware.withExtraArgument(api),
        sagaMiddleware,
        ...extraMiddlewares,
    ];

    let composedEnhancers;
    if (process.env.NODE_ENV !== `production`) {
        if (process.env.REACT_APP_LOG_REDUX === 'true') {
            const { createLogger } = require(`redux-logger`); // eslint-disable-line global-require, import/no-extraneous-dependencies

            const loggerMiddleware = createLogger({
                collapsed: true,
            });

            middlewares.push(loggerMiddleware); // logger must be the last middleware in chain
        }

        const { composeWithDevTools } = require('redux-devtools-extension'); // eslint-disable-line global-require, import/no-extraneous-dependencies
        composedEnhancers = composeWithDevTools(applyMiddleware(...middlewares));
    } else {
        composedEnhancers = compose(applyMiddleware(...middlewares));
    }

    const rootReducer = createReducer();

    const store = createStore(rootReducer, preloadedState, composedEnhancers);
    sagaMiddleware.run(sagas);

    if (process.env.NODE_ENV !== 'production' && module.hot) {
        module.hot.accept('./reducers', () => store.replaceReducer(rootReducer));
    }

    return store;
};

export default configureStore;
