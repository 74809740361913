export const dashboardPath = '/';

export const playgroundPath = '/playground';

export const kitchenSinkPath = '/kitchensink';

export const loginPath = '/login';
export const serviceLoginPath = '/servicelogin';
export const logoutPath = '/logout';

export const homePath = dashboardPath;

export const cardPath = cardId => `${dashboardPath}card/${cardId}`;

export const protocolItemPath = (cardId, protocolItemId) =>
    `${cardPath(cardId)}?protocol=${protocolItemId}`;

export const cardContactPath = (cardId, contactId) => `${cardPath(cardId)}?contact=${contactId}`;

export const contactsPath = '/contacts';

export const contactPath = (contactId, contextCardId = null) =>
    contextCardId ? `${cardPath(contextCardId)}/contact/${contactId}` : `/contact/${contactId}`;

export const employeesPath = '/employees';
export const employeePath = (employeeId, initTab) =>
    initTab ? `/employees/${employeeId}/${initTab}` : `/employees/${employeeId}`;

export const tabPath = (cardId, tabName) => `${cardPath(cardId)}?tab=${tabName}`;

export const fileManagerPath = (cardId, path) =>
    `${cardPath(cardId)}?file=${encodeURIComponent(path)}`;

export const tasksPath = '/tasks';
export const taskPath = taskId => `${tasksPath}/${taskId}`;

export const resourcesPath = '/resources';

export const resourcePath = (resourceId, { initTab = null, cloneResourceId = null } = {}) => {
    const initial = initTab ? `/${initTab}` : '';
    const clone = cloneResourceId ? `?clone=${cloneResourceId}` : '';
    return `${resourcesPath}/${resourceId}${initial}${clone}`;
};

export const schedulesPath = '/schedules';

export const userCalendarPath = '/usercalendar';

export const planningCalendarPath = '/planning';

export const settingsPath = '/settings';
export const settingPath = (pathParts = []) => {
    const path = pathParts.length > 0 ? `${pathParts.join('')}` : '';
    return `${settingsPath}${path}`;
};

export const protocolItemFormPath = '/protocolItem';

export const outlookPluginPath = '/outlook';
