import React, { useMemo } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import ScheduleInput from './ScheduleInput';
import TotalPerDay from './TotalPerDay';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { ErrorTooltip } from '../../form/ErrorTooltip';

const useStyles = makeStyles(theme => ({
    row: {
        position: 'relative',
    },
}));
const ScheduleRow = ({ name, day, shifts }) => {
    const classes = useStyles();
    const { touched, dirty, errors } = useFormikContext();
    const { t } = useTranslation();

    const hasOverlappingErrors = useMemo(() => {
        if (!dirty || !touched[name] || !errors[name]) {
            return false;
        }

        const dayKeys = Object.keys(errors[name]).filter(key => {
            return key.startsWith(`${day.id}_`);
        });

        return dayKeys.map(key => errors[name][key]).some(i => i === 'overlap');
    }, [touched, dirty, errors, day, name]);

    return (
        <TableRow key={day.name} className={classes.row}>
            <TableCell component="th" scope="row" className={classes.row}>
                {day.name}
                {hasOverlappingErrors ? <ErrorTooltip>{t('errors.overlap')}</ErrorTooltip> : ''}
            </TableCell>
            {shifts.map(shift => (
                <TableCell key={`${day.id}_${shift.id}`}>
                    <ScheduleInput name={name} ca day={day} shift={shift}></ScheduleInput>
                </TableCell>
            ))}
            <TableCell>
                <TotalPerDay name={name} shifts={shifts} day={day} />
            </TableCell>
        </TableRow>
    );
};

export default ScheduleRow;
