import { verifiedDurationInt } from '../datetime/utils';

export const formatJournalSubmit = (
    {
        id,
        card_id,
        task_id,
        task_type_id,
        appointment_id,
        user_id,
        id_invoice,

        started_at,
        started_has_time,
        billed_at,
        billed_by,

        description,
        duration,
        duration_billable,

        transport_km,
        transport_private,
        costs,
    },
    removeEmpty
) => {
    if (removeEmpty) {
        return {
            id,
            ...(card_id !== undefined ? { card_id } : {}),
            ...(task_id !== undefined ? { task_id } : {}),
            ...(task_type_id !== undefined ? { task_type_id } : {}),
            ...(appointment_id !== undefined ? { appointment_id } : {}),
            ...(user_id !== undefined ? { user_id } : {}),
            ...(id_invoice !== undefined ? { id_invoice } : {}),

            ...(started_at !== undefined ? { started_at } : {}),
            ...(started_has_time !== undefined ? { started_has_time } : {}),
            ...(billed_at !== undefined ? { billed_at } : {}),

            ...(description !== undefined ? { description } : {}),
            ...(duration !== undefined ? { duration: verifiedDurationInt(duration) } : {}),
            ...(duration_billable !== undefined
                ? { duration_billable: verifiedDurationInt(duration_billable) }
                : {}),

            ...(transport_km !== undefined ? { transport_km } : {}),
            ...(transport_private !== undefined ? { transport_private } : {}),
            ...(costs !== undefined ? { costs } : {}),
        };
    }

    return {
        id,
        card_id,
        task_id,
        task_type_id,
        appointment_id,
        user_id,
        id_invoice,

        started_at,
        started_has_time,
        billed_at,

        description,
        duration: verifiedDurationInt(duration),
        duration_billable: verifiedDurationInt(duration_billable),

        transport_km,
        transport_private,
        costs,
    };
};
