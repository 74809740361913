import { TASK_STATUS_RESOURCE } from '../api/resources';
import { createResourceSlice } from '../redux/resource/createResourceSlice';

const taskStatusSlice = createResourceSlice({
    resource: TASK_STATUS_RESOURCE,
    byKey: '',
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,
    indexTaskStatuses,
    showTaskStatus,
    storeTaskStatus,
    updateTaskStatus,
    archiveTaskStatus,
    restoreTaskStatus,
    destroyTaskStatus,
} = taskStatusSlice.actions;
export const {
    selectTaskStatusById,
    makeTaskStatusesByIdsSelector,
    selectTaskStatusesById,
    selectAllTaskStatusIds,
    selectAllTaskStatuses,
    selectTaskStatusesLoading,
    selectTaskStatusesInitialized,
    selectTaskStatusLoading,
    selectTaskStatusInitialized,
    selectTaskStatusIdsBy,
    makeTaskStatusesBySelector,
    selectTaskStatusLoadingBy,
    selectTaskStatusInitializedBy,
} = taskStatusSlice.selectors;

export const { useTaskStatuses, useTaskStatus, useTaskStatusesBy } = taskStatusSlice.hooks;
export default taskStatusSlice.reducer;
