import { RESOURCE_CLASS_RESOURCE } from '../api/resources';

export const indexResourceClasses = (params, meta) => (dispatch, getState, api) =>
    api[RESOURCE_CLASS_RESOURCE].index(params, meta);
export const showResourceClass = (params, meta) => (dispatch, getState, api) =>
    api[RESOURCE_CLASS_RESOURCE].show(params, meta);
export const storeResourceClass = (params, meta) => (dispatch, getState, api) =>
    api[RESOURCE_CLASS_RESOURCE].store(params, meta);
export const updateResourceClass = (params, meta) => (dispatch, getState, api) =>
    api[RESOURCE_CLASS_RESOURCE].update(params, meta);
export const destroyResourceClass = (params, meta) => (dispatch, getState, api) =>
    api[RESOURCE_CLASS_RESOURCE].destroy(params, meta);
