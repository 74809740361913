import React from 'react';
import * as PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { useField } from 'formik';

const FormFieldResetButton = ({ name, disabled, onClick }) => {
    const [{ value }, , { setValue }] = useField(name);

    const handleResetField = () => {
        setValue(null);
        onClick();
    };

    return (
        <IconButton
            color="secondary"
            disabled={disabled || !value || value === ''}
            onClick={handleResetField}
            size="small"
        >
            <DeleteForeverOutlinedIcon />
        </IconButton>
    );
};

FormFieldResetButton.propTypes = {
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
};

FormFieldResetButton.defaultProps = {
    disabled: false,
    onClick: () => {},
};

export default FormFieldResetButton;
