import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import { convertFromRaw, convertToRaw, EditorState, Modifier, SelectionState } from 'draft-js';
import { EDITOR_HIGHLIGHT } from '../editor/highlight';

export const FORM_STATUS = {
    AVAILABLE: undefined,
    DISABLED: 'disabled',
};

export const prefilled = (initial, override) => {
    return Object.keys(initial).reduce(
        (carry, key) => (override[key] ? { ...carry, [key]: override[key] } : carry),
        initial
    );
};

export const rawDraftToMarkdown = rawEditorContent => {
    return draftToMarkdown(rawEditorContent, {
        preserveNewlines: true,
        entityItems: {
            mention: {
                open(entity) {
                    // open() {
                    // TODO mentions anders abspeichern?
                    // Ich hab nochmal nachgedacht und denke fast, dass mentions gar nicht wieder eingelesen werden müssen.
                    // Die Responsibles werden nämlich beim Speichern erstellt.
                    // 1. Protokolleinträge anzeigen: es kann einfach @username plaintext darin stehen
                    // 2. Protokolleinträge bearbeiten: vorhandene Responsibles entfernen kann man über die Bubble über dem Eintrag.
                    // Neue Responsibles können im Editor hinzugefügt werden.
                    // return '@';
                    // return `<mention data-user-id="${entity.data.mention.id}" data-user-avatar="${entity.data.mention.avatar}">`;
                    return `<mention data-user-id="${entity.data.mention.id}" data-user-avatar="${entity.data.mention.avatar}" data-user-name="${entity.data.mention.name}">`;
                },
                close() {
                    // return '';
                    return '</mention>';
                },
            },
            HIGHLIGHT: {
                open(entity) {
                    return `<highlight data-background="${entity.data.backgroundColor}" data-color="${entity.data.color}" data-text="${entity.data.text}">`;
                },
                close() {
                    return '</highlight>';
                },
            },
        },
    });
};

export const markdownToEditorState = (markdownString = '', responsibleUserIds = []) => {
    // TODO mentions / tags evtl. wieder parsen und einlesen
    // https://www.npmjs.com/package/markdown-draft-js
    const contentState = convertFromRaw(
        markdownToDraft(markdownString, {
            preserveNewlines: true,
            remarkablePlugins: [remarkableMentionPlugin, remarkableHighlightPlugin],
            blockEntities: {
                mention_open(item) {
                    return {
                        type: 'mention',
                        mutability: 'IMMUTABLE',
                        data: {
                            mention: {
                                id: item.id,
                                name: item.name,
                                avatar: item.avatar,
                            },
                        },
                    };
                },
                highlight_open(item) {
                    return {
                        type: EDITOR_HIGHLIGHT,
                        mutability: 'MUTABLE',
                        data: {
                            backgroundColor: item.backgroundColor,
                            color: item.color,
                            text: item.text,
                        },
                    };
                },
            },
        })
    );

    const content = getEditorEntities(contentState).reduce((state, entity) => {
        if (
            entity.entity.type === 'mention' &&
            entity.entity.data.mention &&
            !responsibleUserIds.includes(entity.entity.data.mention.id)
        ) {
            const selectionState = SelectionState.createEmpty(entity.blockKey);
            const updatedSelection = selectionState.merge({
                anchorOffset: entity.start,
                anchorKey: entity.blockKey,
                focusOffset: entity.end,
                focusKey: entity.blockKey,
                isBackward: false,
            });

            return Modifier.applyEntity(state, updatedSelection, null);
        }
        return state;
    }, contentState);

    return EditorState.createWithContent(content);
};

// Beispiel: https://github.com/Rosey/markdown-draft-js/blob/master/test/markdown-to-draft.spec.js#L415
const remarkableMentionPlugin = remarkable => {
    remarkable.inline.ruler.push('mention', (state, silent) => {
        const mentionRegEx = /^<mention (.*?)>.*?<\/mention>/;
        return remarkableCommonPlugin(state, silent, mentionRegEx, createMention);
    });
};

const createMention = (elementData, state) => {
    const matchUserId = /data-user-id="(.*?)"/.exec(elementData);
    const matchUserName = /data-user-name="(.*?)"/.exec(elementData);
    const matchUserAvatar = /data-user-avatar="(.*?)"/.exec(elementData);

    state.push({
        type: 'mention_open',
        name: matchUserName[1],
        id: parseInt(matchUserId[1], 10),
        avatar: matchUserAvatar.length ? matchUserAvatar[1] : '',
        level: state.level,
    });

    state.push({
        type: 'text',
        content: `@${matchUserName[1]}`,
        level: state.level + 1,
    });

    state.push({
        type: 'mention_close',
        level: state.level,
    });
};

const remarkableHighlightPlugin = remarkable => {
    remarkable.inline.ruler.push(EDITOR_HIGHLIGHT, (state, silent) => {
        const highlightRegEx = /^<highlight (.*?)>.*?<\/highlight>/;
        return remarkableCommonPlugin(state, silent, highlightRegEx, createHighlight);
    });
};

const createHighlight = (elementData, state) => {
    const values = getHighlightValues(elementData);

    state.push({
        type: 'highlight_open',
        backgroundColor: values.backgroundColor,
        color: values.color,
        text: values.text,
        level: state.level,
    });

    state.push({
        type: 'text',
        content: values.text,
        level: state.level + 1,
    });

    state.push({
        type: 'highlight_close',
        level: state.level,
    });
};

export const getHighlightValues = elementData => {
    const background = /data-background="(.*?)"/.exec(elementData);
    const color = /data-color="(.*?)"/.exec(elementData);
    const text = /data-text="(.*?)"/.exec(elementData);

    return {
        backgroundColor: background[1] || '',
        color: color[1] || '',
        text: text[1] || '',
    };
};

const remarkableCommonPlugin = (state, silent, elementRegEx, createElement) => {
    // it is surely not our rule, so we could stop early
    if (
        !state.src ||
        state.pos === undefined ||
        state.pos === null ||
        state.src[state.pos] !== '<'
    ) {
        return false;
    }

    const match = elementRegEx.exec(state.src.slice(state.pos));
    if (!match) {
        return false;
    }

    // in silent mode it shouldn't output any tokens or modify pending
    if (!silent) {
        createElement(match[1], state);
    }

    // every rule should set state.pos to a position after tokens contents
    state.pos += match[0].length;
    return true;
};

export const getEditorEntities = (content, entityType = null) => {
    // ToDo: Use ./editor/utils.js getElements?
    const entities = [];
    content.getBlocksAsArray().forEach(block => {
        let selectedEntity = null;
        block.findEntityRanges(
            character => {
                if (character.getEntity() !== null) {
                    const entity = content.getEntity(character.getEntity());
                    if (!entityType || (entityType && entity.getType() === entityType)) {
                        selectedEntity = {
                            entityKey: character.getEntity(),
                            blockKey: block.getKey(),
                            entity: content.getEntity(character.getEntity()),
                        };
                        return true;
                    }
                }
                return false;
            },
            (start, end) => {
                entities.push({ ...selectedEntity, start, end });
            }
        );
    });
    return entities;
};

export const editorStateToMarkdown = state =>
    rawDraftToMarkdown(convertToRaw(state.getCurrentContent()));

export const activateMention = editorState => {
    /**
     * Falls man direkt ein mention entity einfügen will:
     */
    // const contentState = editorState.getCurrentContent();
    // const selectionState = editorState.getSelection();
    // const newContentState = contentState.createEntity('mention', 'IMMUTABLE', {
    //     mention: {
    //         id: 2,
    //         name: 'Meinhard A.',
    //         avatar: '',
    //     },
    // });
    // const entityKey = newContentState.getLastCreatedEntityKey();
    // const textWithEntity = Modifier.insertText(
    //     contentState,
    //     selectionState,
    //     '@Meinhard A.',
    //     null,
    //     entityKey
    // );
    // const newEditorState = EditorState.push(editorState, textWithEntity, 'insert-characters');
    const selectionState = editorState.getSelection();
    const contentState = editorState.getCurrentContent();

    const currentBlockKey = selectionState.getStartKey();
    const currentBlock = contentState
        .getBlocksAsArray()
        .find(block => block.key === currentBlockKey);

    const offset = selectionState.getAnchorOffset();
    const charBeforeCursor = currentBlock.text.charAt(Math.max(offset - 1, 0));
    const insertText = charBeforeCursor === ' ' || offset === 0 ? '@' : ' @';

    const newContentState = Modifier.insertText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        insertText
    );

    return EditorState.push(editorState, newContentState, 'insert-fragment');
};

/**
 * Plugin Methoden kopiert und nur etwas angepasst
 * https://github.com/draft-js-plugins/draft-js-plugins/blob/master/draft-js-mention-plugin/src/utils/positionSuggestions.js
 */
const getRelativeParent = element => {
    if (!element) {
        return null;
    }
    const position = window.getComputedStyle(element).getPropertyValue('position');
    if (position !== 'static') {
        return element;
    }
    return getRelativeParent(element.parentElement);
};
export const positionSuggestions = ({ decoratorRect, popover, props, state }) => {
    const relativeParent = getRelativeParent(popover.parentElement);
    const relativeRect = {};

    if (relativeParent) {
        relativeRect.scrollLeft = relativeParent.scrollLeft;
        relativeRect.scrollTop = relativeParent.scrollTop;

        const relativeParentRect = relativeParent.getBoundingClientRect();
        relativeRect.left = decoratorRect.left - relativeParentRect.left;
        relativeRect.top = decoratorRect.bottom - relativeParentRect.top;

        // angepasst: damit das Popover nicht rechts rausguckt bzw. versteckt wird
        if (relativeRect.left + (popover.clientWidth + 30) > relativeParentRect.width) {
            relativeRect.left = relativeParentRect.width - (popover.clientWidth + 30);
        }
        // angepasst: damit das Popover nicht unten rausguckt bzw. versteckt wird
        // if (relativeRect.top + popover.clientHeight > relativeParentRect.height) {
        //     relativeRect.top = relativeParentRect.height - popover.clientHeight;
        // }
    } else {
        relativeRect.scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        relativeRect.scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

        relativeRect.top = decoratorRect.bottom;
        relativeRect.left = decoratorRect.left;
    }

    const left = relativeRect.left + relativeRect.scrollLeft;
    const top = relativeRect.top + relativeRect.scrollTop;

    let transform;
    let transition;
    if (state.isActive) {
        if (props.suggestions.length > 0) {
            transform = 'scale(1)';
            transition = 'all 0.25s cubic-bezier(.3,1.2,.2,1)';
        } else {
            transform = 'scale(0)';
            transition = 'all 0.35s cubic-bezier(.3,1,.2,1)';
        }
    }

    return {
        left: `${left}px`,
        top: `${top}px`,
        transform,
        transformOrigin: '1em 0%',
        transition,
    };
};

export const prefillInitialValues = (resource, initialValues) => {
    if (!resource) {
        return initialValues;
    }

    const final = {};
    Object.keys(initialValues).forEach(key => {
        final[key] = resource[key] || initialValues[key];
    });
    return final;
};

export const withPrefix = (name, prefix = null) => (prefix ? `${prefix}.${name}` : name);
