import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { DeleteForever } from '@mui/icons-material';
import { IdPropType } from '../../modules/proptypes';
import { selectResourceById } from '../../modules/resources/selectors';
import { updateResource } from '../../modules/resources/actions';
import GroupsAutocomplete from '../form/base/autocomplete/GroupsAutocomplete';
import { selectGroupsByIds } from '../../modules/groups/selectors';
import GroupForm from './GroupForm';
import { storeGroup, updateGroup } from '../../modules/groups/actions';
import { selectEmployeeById } from '../../modules/employees/selectors';
import { updateEmployee } from '../../modules/employees/actions';
import Form from '../form/formik/Form';
import Can from '../../modules/abilities/Can';
import { UPDATE } from '../../modules/abilities/actions';
import Button from '../form/base/Button';
import { EMPLOYEE_RESOURCE, RESOURCE_RESOURCE } from '../../modules/api/resources';
import { useSnackbar } from '../../modules/snackbar/hooks';
import { union } from 'lodash';

const MODES = {
    AUTOCOMPLETE: 'autocomplete',
    FORM: 'form',
};

const useStyles = makeStyles(theme => ({
    autocomplete: {
        width: '100%',
    },
    table: {
        width: '100%',
        marginBottom: theme.spacing(12),
    },
    divider: {
        marginTop: theme.spacing(4),
    },
}));

const GROUP_FIELD = 'groups';

const GroupsTableRow = ({ group, resourceType, onDeleteGroupEntry }) => {
    const { t } = useTranslation();
    const onDeleteClick = () => {
        onDeleteGroupEntry(group.id);
    };

    return (
        <TableRow tabIndex={-1}>
            <TableCell component="th" scope="row">
                <Typography component="span">{group.name}</Typography>
            </TableCell>
            <TableCell align="center">
                <Button
                    action={UPDATE}
                    subject={resourceType}
                    field={GROUP_FIELD}
                    startIcon={<DeleteForever />}
                    color="secondary"
                    size="small"
                    p={0}
                    onClick={onDeleteClick}
                >
                    {t('components.Group.deleteGroup')}
                </Button>
            </TableCell>
        </TableRow>
    );
};

const GroupsPreview = ({ resourceId, employeeId }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const resource = useSelector(state =>
        resourceId ? selectResourceById(state, resourceId) : selectEmployeeById(state, employeeId)
    );
    const resourceType = resourceId ? RESOURCE_RESOURCE : EMPLOYEE_RESOURCE;
    const groups = useSelector(state => selectGroupsByIds(state, resource.groups));
    const [mode, setMode] = useState(MODES.AUTOCOMPLETE);
    const [selectedGroups, setSelectedGroups] = useState([]);

    const handleError = error => {
        if (error && error.status === 422) {
            if (error.response?.errors) {
                const errorArray = union(Object.values(error.response.errors));

                if (errorArray.length > 0) {
                    enqueueSnackbar(t(`components.Group.${errorArray[0]}`), { variant: 'error' });

                    return true;
                }
            }
        }

        return false;
    };

    const handleSubmitGroup = newGroupValue => {
        setSearchInput('');
        if (newGroupValue.id)
            return dispatch(updateGroup(newGroupValue, { handleError })).then(() =>
                setMode(MODES.AUTOCOMPLETE)
            );
        return dispatch(storeGroup(newGroupValue, { handleError })).then(response => {
            setMode(MODES.AUTOCOMPLETE);

            if (response.data) {
                const newSavedGroup = {
                    by_id: 1,
                    field: 'group.name',
                    group: 'group',
                    id: response.data.id,
                    label: 'Group',
                    name: 'group.name',
                    type: 'App\\Models\\Group',
                    value: response.data.name,
                };

                setSelectedGroups([...selectedGroups, newSavedGroup]);
            }
        });
    };

    const handleAbortGroup = () => {
        setSearchInput('');
        setMode(MODES.AUTOCOMPLETE);
    };

    const handleSubmit = () => {
        const selectedGroupsIds = selectedGroups.map(gp => gp.id);
        let updateResourceGroups = resource.groups.concat(selectedGroupsIds);
        updateResourceGroups = [...new Set([...resource.groups, ...selectedGroupsIds])];
        return dispatch(
            resourceId
                ? updateResource({ id: resourceId, groups: updateResourceGroups })
                : updateEmployee({ id: employeeId, groups: updateResourceGroups })
        ).then(() => {
            setSelectedGroups([]);
            setMode(MODES.AUTOCOMPLETE);
        });
    };

    const handleDeleteGroupEntry = groupId => {
        let updateResourceGroups = [...resource.groups];
        updateResourceGroups.splice(resource.groups.indexOf(groupId), 1);
        updateResourceGroups = updateResourceGroups.length > 0 ? updateResourceGroups : [0];
        return dispatch(
            resourceId
                ? updateResource({ id: resourceId, groups: updateResourceGroups })
                : updateEmployee({ id: employeeId, groups: updateResourceGroups })
        );
    };

    const handleCreate = () => setMode(MODES.FORM);

    const handleAutocompleteChange = val => {
        if (!val.onCreate) {
            setSelectedGroups(val);
            setMode(MODES.AUTOCOMPLETE);
        }
    };

    const [searchInput, setSearchInput] = useState('');

    return (
        <Box>
            <Box>
                <Can I={UPDATE} this={resourceType} field={GROUP_FIELD}>
                    <Grid style={{ marginBottom: '24px' }} container spacing={1}>
                        {mode === MODES.AUTOCOMPLETE && (
                            <Grid item xs={6}>
                                <span data-test-id="group-autocomplete-input">
                                    <Form initialValues={{ groups: '' }} subject={resourceType}>
                                        <GroupsAutocomplete
                                            name="groups"
                                            label={t('components.Group.search')}
                                            className={classes.autocomplete}
                                            initialValue={selectedGroups}
                                            I={UPDATE}
                                            onChange={handleAutocompleteChange}
                                            onAddClick={handleCreate}
                                            onInputChange={setSearchInput}
                                            showAllResults
                                            disableCloseOnSelect
                                            fullWidth
                                            multiple
                                        />
                                    </Form>
                                </span>
                            </Grid>
                        )}
                        {mode === MODES.FORM && (
                            <Grid item xs={12}>
                                <GroupForm
                                    input={searchInput}
                                    onSubmit={handleSubmitGroup}
                                    onAbort={handleAbortGroup}
                                    can={UPDATE}
                                    subject={resourceType}
                                />
                            </Grid>
                        )}
                        {mode !== MODES.FORM && (
                            <Grid item xs={6} style={{ paddingLeft: '15px' }}>
                                <Button
                                    disabled={selectedGroups.length <= 0}
                                    style={{ paddingTop: '9px', paddingBottom: '9px' }}
                                    variant="contained"
                                    color="primary"
                                    size="medium"
                                    p={0}
                                    onClick={handleSubmit}
                                >
                                    {t('components.Group.addGroups')}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Can>
            </Box>

            {groups && groups.length > 0 ? (
                <Box className={classes.divider}>
                    <Grid container>
                        <Grid item xs={12} md={8}>
                            <TableContainer>
                                <Table className={classes.table} aria-label="groups table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <Typography
                                                    variant="h5"
                                                    component="span"
                                                    color="primary"
                                                >
                                                    {t('components.ResourceDialog.tabs.groups.tab')}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography
                                                    variant="h5"
                                                    component="span"
                                                    color="primary"
                                                >
                                                    {t('components.Group.actions')}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {groups.map(row => (
                                            <GroupsTableRow
                                                key={row.id}
                                                group={row}
                                                resourceType={resourceType}
                                                onDeleteGroupEntry={handleDeleteGroupEntry}
                                            />
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Box>
            ) : (
                <Grid>
                    <Typography
                        variant="body1"
                        style={{
                            color: '#767676',
                            fontSize: '15px',
                            fontStyle: 'italic',
                            padding: '12px',
                        }}
                    >
                        {t('components.Group.noGroup')}
                    </Typography>
                </Grid>
            )}
        </Box>
    );
};

GroupsPreview.propTypes = {
    resourceId: IdPropType,
    employeeId: IdPropType,
};

export default GroupsPreview;
