import React, { useEffect } from 'react';
import { AppointmentTooltip } from '@devexpress/dx-react-scheduler-material-ui';
import {
    APPOINTMENT_CALENDAR_CONTEXT_FUNC_TYPE,
    useAppointmentCalendar,
} from '../AppointmentCalendarContext';
import IconButton from '../../form/base/IconButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useTranslation } from 'react-i18next';

const CalendarDetailMeta = ({
    appointmentData,
    commandButtonIds,
    onHide,
    onOpenButtonClick,
    onDeleteButtonClick,
    commandButtonComponent,
    children,
}) => {
    const { t } = useTranslation();
    const { attachFunc } = useAppointmentCalendar();

    const handleOpenButton = () => {
        onHide();
        onOpenButtonClick();
    };

    useEffect(
        () => attachFunc(APPOINTMENT_CALENDAR_CONTEXT_FUNC_TYPE.DELETE, onDeleteButtonClick),
        [attachFunc, onDeleteButtonClick]
    );

    return (
        <AppointmentTooltip.Header
            appointmentData={appointmentData}
            commandButtonIds={commandButtonIds}
            //showOpenButton
            onHide={onHide}
            onOpenButtonClick={onOpenButtonClick}
            onDeleteButtonClick={onDeleteButtonClick}
            commandButtonComponent={commandButtonComponent}
        >
            {children}
            <IconButton
                onClick={handleOpenButton}
                style={{ padding: 12 }}
                label={t('components.Calendar.CalendarDetailHead.edit')}
            >
                <EditOutlinedIcon />
            </IconButton>
        </AppointmentTooltip.Header>
    );
};

CalendarDetailMeta.propTypes = {};

export default CalendarDetailMeta;
