import React from 'react';
import * as PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import tinycolor from 'tinycolor2';
import { useSelector } from 'react-redux';
import { selectCategoryById } from '../../modules/categories/selectors';

const CategoryBox = ({ categoryId, children }) => {
    const category = useSelector(state => selectCategoryById(state, categoryId));

    return (
        <Box
            style={{
                height: '100%',
                width: '100%',
                opacity: !category?.active ? 0.3 : 1,
                backgroundColor: tinycolor(category?.color).setAlpha(0.2).toRgbString(),
                border: `1px solid ${category?.color}`,
                borderRadius: 3,
            }}
        >
            {children}
        </Box>
    );
};

CategoryBox.propTypes = {};

export default CategoryBox;
