import React, { useCallback, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import classNames from 'classnames';
import { Waypoint } from 'react-waypoint';
import copy from 'copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import pathJoin from 'path.join';
import { useApi } from '../../modules/api/ApiProvider';
import { FilePropType } from '../../modules/files/proptypes';
import { FILE_STATUS, formatFileSize } from '../../modules/files/utils';
import { useSnackbar } from '../../modules/snackbar/hooks';
import FileMailIcon from '../icons/FileMailIcon';
import FileMimeIcon from '../icons/FileMimeIcon';
import LoadingIcon from '../loading/LoadingIcon';
import { VisibilityOutlined } from '@mui/icons-material';
import { isMail } from '../../modules/email/attachments';

const useStyles = makeStyles(theme => ({
    root: {
        cursor: 'default',
    },

    meta: {
        flex: '1 0 100%',
        [theme.breakpoints.up('md')]: {
            flex: '1 1 0',
            flexWrap: 'nowrap',
            justifyContent: 'flex-end',
        },
    },

    link: {
        cursor: 'pointer',
        position: 'relative',
    },

    lineHeight: {
        lineHeight: 0,
    },

    filesize: {
        color: theme.palette.text.hint,
        paddingLeft: theme.spacing(1),
    },

    missing: {
        color: theme.palette.action.disabled,
    },

    loading: {
        marginTop: 13,
        marginLeft: 4,
    },
}));

const FileListItem = ({ cloudRoot, file, showDirectory, children, kumoConnectStatus }) => {
    const classes = useStyles();
    const { cloud } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const history = useHistory();

    /**
     * load data from remote if it was uploaded already
     */
    const [data, setData] = useState(file.id ? null : file);
    const handleRetrieveData = useCallback(() => {
        if (!data && typeof file.path === 'string') {
            setData({
                ...file,
                uploaded: true,
                loading: true,
            });
            cloud
                .stat(pathJoin(cloudRoot, file.path, file.filename))
                .then(response => {
                    setData({
                        ...file,
                        uploaded: true,
                        file: response,
                    });
                })
                .catch(err => {
                    setData({
                        ...file,
                        error: true,
                        uploaded: true,
                        missing: err.response && err.response.status === 404,
                    });
                });
        }
    }, [data, setData, file, cloudRoot, cloud]);

    const mime = data && (data.mime || (data.file && (data.file.mime || data.file.type)));

    const handleClick = async () => {
        if (data && data.uploaded) {
            if (kumoConnectStatus) {
                // use fallback function instead?
                const succeed = await openKumoConnectFile(
                    kumoConnectStatus.ip,
                    kumoConnectStatus.port,
                    urljoin(cloudRoot, data.path, data.filename),
                    apiDispatch
                );
                if (succeed) return;
            }

            const isDir = mime ? mime === 'directory' : !data.filename.includes('.');
            if (isDir) {
                if (showDirectory) {
                    history.push(showDirectory(pathJoin(data.path, data.filename)));
                }
            } else {
                cloud.show(pathJoin(cloudRoot, data.path, data.filename));
            }
        }
    };

    const handleCopyPath = () => {
        if (!data.path || typeof data.path !== 'string') {
            return;
        }

        if (kumoConnectStatus) {
            cloud.show(pathJoin(cloudRoot, data.path, ''));
        }

        if (data.uploaded) {
            const localPath = cloud.getLocalPath(pathJoin(cloudRoot, data.path, data.filename));
            if (copy(localPath)) {
                enqueueSnackbar(`${t('components.FileListItem.copy')} ${localPath}`);
            }
        }
    };

    const handleDownload = () => {
        if (data.uploaded) {
            cloud.download(pathJoin(cloudRoot, data.path, data.filename));
        }
    };

    return (
        <Grid
            container
            spacing={2}
            alignItems="center"
            className={classNames({
                [classes.root]: true,
                [classes.missing]: data && data.missing,
            })}
        >
            <Grid item onDoubleClick={handleClick} className={classes.link}>
                <LoadingIcon
                    isLoading={file?.status === FILE_STATUS.PROCESSING || data?.loading}
                    className={classes.loading}
                >
                    {isMail(data) ? (
                        <FileMailIcon mail={data.mail} missing={data && data.missing} />
                    ) : (
                        <FileMimeIcon mime={mime} missing={data && data.missing} />
                    )}
                </LoadingIcon>
                <Waypoint onEnter={handleRetrieveData} />
            </Grid>
            <Grid item onDoubleClick={handleClick} className={classes.link}>
                <Typography variant="body2" color="primary">
                    {data && data.filename}
                </Typography>
                {children}
            </Grid>
            <Grid item container alignItems="center" spacing={2} className={classes.meta}>
                <Grid item style={{ minWidth: 75 }}>
                    <Typography component="span" variant="body2" className={classes.filesize}>
                        {data && data.file && formatFileSize(data.file.size)}
                    </Typography>
                </Grid>
                {data && data.uploaded && !data.missing && (
                    <Grid item>
                        <Box
                            className={classNames({
                                [classes.link]: true,
                                [classes.lineHeight]: true,
                            })}
                            onClick={handleCopyPath}
                        >
                            <FolderOpenOutlinedIcon
                                color="primary"
                                style={{ width: 16, height: 16 }}
                            />
                        </Box>
                    </Grid>
                )}
                {data && data.uploaded && !data.missing && (
                    <Grid item>
                        <Box
                            className={classNames({
                                [classes.link]: true,
                                [classes.lineHeight]: true,
                            })}
                            onClick={handleDownload}
                        >
                            {kumoConnectStatus ? (
                                <VisibilityOutlined
                                    color="primary"
                                    style={{ width: 16, height: 16 }}
                                />
                            ) : (
                                <CloudDownloadOutlinedIcon
                                    color="primary"
                                    style={{ width: 16, height: 16 }}
                                />
                            )}
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

FileListItem.propTypes = {
    file: FilePropType.isRequired,
    children: PropTypes.node,
    cloudRoot: PropTypes.string,
    showDirectory: PropTypes.func,
    kumoConnectStatus: PropTypes.shape({}),
};

FileListItem.defaultProps = {
    children: null,
    cloudRoot: '/',
    showDirectory: null,
    kumoConnectStatus: null,
};

export default FileListItem;
