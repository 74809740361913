import React, { useCallback, useState } from 'react';
import * as PropTypes from 'prop-types';
import { bindPopper, usePopupState } from 'material-ui-popup-state/hooks';
import { Alert, Box, ClickAwayListener, Grid, Paper, Popper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Button from '../../../components/form/base/Button';
import { archiveProtocol, useProtocol } from '../protocolsSlice';
import { useSnackbar } from '../../snackbar/hooks';
import { DELETE } from '../../abilities/actions';
import { IdPropType } from '../../proptypes';
import { SubjectPropType } from '../../abilities/proptypes';

const useStyles = makeStyles(theme => ({
    listener: {
        display: 'inline-block',
    },

    container: {
        paddingBottom: theme.spacing(1.5),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(1.5),

        zIndex: 2,
        position: 'relative',
    },

    buttons: {
        paddingTop: 4,
        maxWidth: 500,
    },

    button: {
        width: 132,
    },

    alignRight: {
        textAlign: 'right',
    },

    popper: {
        zIndex: 1301, // https://github.com/mui-org/material-ui/issues/18905

        '&[x-placement*="bottom"] $arrow': {
            top: 0,
            left: 0,
        },
        '&[x-placement*="top"] $arrow': {
            bottom: 0,
            left: 0,
            transform: 'rotate(180deg)',
        },
        '&[x-placement*="left"] $arrow': {
            right: -3,
            transform: 'rotate(90deg)',
        },
        '&[x-placement*="right"] $arrow': {
            left: -1,
            transform: 'rotate(-90deg)',
        },
    },

    arrow: {
        width: 10,
        position: 'absolute',
        marginLeft: -2,

        '&:after': {
            marginLeft: -8,

            zIndex: 1,
            content: '""',
            left: '50%',
            top: 3,
            width: 15,
            height: 15,
            position: 'absolute',
            backgroundColor: 'white',
            boxShadow: '12px 0 0 0 white, 0px 12px 0px 0 white, 0 -2px 10px 0 rgba(0,0,0,0.07)',
            transform: 'rotate(45deg)',
            borderLeft: '1px solid #E6E6E6',
            borderTop: '1px solid #E6E6E6',
        },
    },

    hoverInner: {
        // space for the arrow
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 12,
        marginRight: 12,

        border: '1px solid #E6E6E6',
        boxShadow: '0 2px 10px 0 rgba(0,0,0,0.07)',
    },
}));

const ProtocolDeleteButton = ({ protocolId, popupId, onAbort, onConfirm }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [arrowElement, setArrowElement] = useState(null);

    const [protocol] = useProtocol(protocolId);

    const popupState = usePopupState({
        variant: 'popover',
        popupId,
    });

    const handleConfirm = scope => {
        popupState.close();
        dispatch(archiveProtocol({ id: protocolId, scope })).then(() => {
            enqueueSnackbar(t('form.ProtocolForm.deleted'), { variant: 'success' });
            onConfirm && onConfirm();
        });
    };

    const handleAbort = useCallback(() => {
        popupState.close();
        if (onAbort) {
            onAbort();
        }
    }, [popupState, onAbort]);

    const global = !!(protocol && protocol.protocol_template_id);

    return (
        <>
            <Button
                onClick={el => popupState.open(el)}
                startIcon={<DeleteForeverOutlinedIcon />}
                disabled={!protocolId}
                action={DELETE}
                subject={protocol}
                data-test-id="DeleteButton"
                color="danger"
            >
                {t('components.ResourceTableRowDetails.delete')}
            </Button>
            <Popper
                className={classes.popper}
                {...bindPopper(popupState)}
                placement="bottom"
                modifiers={[
                    {
                        name: 'flip',
                        enabled: true,
                    },
                    {
                        name: 'preventOverflow',
                        enabled: true,
                        options: {
                            boundariesElement: 'scrollParent',
                            padding: 5,
                        },
                    },
                    {
                        name: 'arrow',
                        enabled: true,
                        options: {
                            element: arrowElement,
                        },
                    },
                ]}
            >
                <span className={classes.arrow} ref={setArrowElement} />
                <Paper className={classes.hoverInner}>
                    <ClickAwayListener onClickAway={handleAbort}>
                        <Box className={classes.container}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item>
                                    <DeleteForeverIcon />
                                </Grid>
                                <Grid item xs>
                                    <Typography variant="body2" color="textPrimary" align="center">
                                        {t('form.ProtocolForm.delete.title')}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Box mt={2} mb={2}>
                                <Alert severity="error">{t('form.ProtocolForm.delete.info')}</Alert>
                            </Box>

                            <Grid container spacing={1} className={classes.buttons}>
                                <Grid item xs={global ? 3 : 6} className={classes.alignRight}>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        onClick={handleAbort}
                                        fullWidth
                                    >
                                        {t('no')}
                                    </Button>
                                </Grid>
                                <Grid item xs={global ? 9 : 6}>
                                    <Button
                                        data-test-id="confirm-btn"
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleConfirm('local')}
                                        fullWidth
                                    >
                                        {t('form.ProtocolForm.delete.local')}
                                    </Button>
                                    {global && (
                                        <Box mt={1}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    <Button
                                                        data-test-id="confirm-btn"
                                                        size="small"
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => handleConfirm('new')}
                                                        fullWidth
                                                    >
                                                        {t('form.ProtocolForm.delete.new')}
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button
                                                        data-test-id="confirm-btn"
                                                        size="small"
                                                        variant="contained"
                                                        color="danger"
                                                        onClick={() => handleConfirm('global')}
                                                        fullWidth
                                                    >
                                                        {t('form.ProtocolForm.delete.global')}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    )}
                                </Grid>
                            </Grid>
                        </Box>
                    </ClickAwayListener>
                </Paper>
            </Popper>
        </>
    );
};

ProtocolDeleteButton.propTypes = {
    protocolId: IdPropType,
    popupId: PropTypes.string.isRequired,
    label: PropTypes.string,
    icon: PropTypes.node,
    onConfirm: PropTypes.func,
    onAbort: PropTypes.func,
    children: PropTypes.node,
    placement: PropTypes.string,
    disabled: PropTypes.bool,
    confirmLabel: PropTypes.string,
    subject: SubjectPropType,
};

ProtocolDeleteButton.defaultProps = {
    protocolId: null,
    label: null,
    icon: null,
    placement: 'bottom',
    onConfirm: null,
    onAbort: null,
    children: null,
    disabled: false,
    confirmLabel: null,
    subject: null,
};

export default ProtocolDeleteButton;
