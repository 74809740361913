import React from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectTaskDurationById } from '../../modules/taskDurations/selectors';
import { IdPropType } from '../../modules/proptypes';
import Number from '../utils/Number';

const TaskDuration = ({ durationId, value }) => {
    const duration = useSelector(state => selectTaskDurationById(state, durationId));

    if (!duration) {
        return value || '';
    }

    const pluralized = (value !== null && value !== 1 && duration.plural) || duration.name;

    return (
        <>
            <Number value={value} /> {pluralized}
        </>
    );
};

TaskDuration.propTypes = {
    durationId: IdPropType,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

TaskDuration.defaultProps = {
    durationId: null,
    value: null,
};

export default TaskDuration;
