import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LockIcon from '@mui/icons-material/Lock';
import { getDateFnsLocale } from '../../../modules/datetime/utils';
import { Grid, Tooltip } from '@mui/material';
import { useTaskDurations } from '../../../modules/taskDurations/taskDurationSlice';
import AddressViewIconButton from '../../buttons/AddressViewIconButton';
import { ALLDAY, restructureDates } from '../../../modules/appointments/utils';
import { useTranslation } from 'react-i18next';

const CalendarDetailBasics = ({
    title,
    address,

    locked,
    startDate,
    endDate,
    allDay,

    color,
}) => {
    useTaskDurations();
    const { i18n } = useTranslation();
    const { t } = useTranslation();

    const [dateMainPhrase, dateOptionalPhrase, dateOptionalPhrase2] = useMemo(() => {
        const dateLocale = getDateFnsLocale(i18n.language);
        return restructureDates(startDate, endDate, allDay, dateLocale);
    }, [startDate, endDate, allDay, i18n]);

    const translateOptionalPhrase = phrase =>
        phrase === ALLDAY ? t('components.CalendarDetail.allday') : phrase;

    return (
        <Box m={2}>
            <Tooltip title={t('components.AppointmentPrintView.title')} disableInteractive>
                <Typography variant="h1" color="primary">
                    {title}
                </Typography>
            </Tooltip>
            <Grid container direction="row" alignItems="center" mt={2}>
                <Grid item mr={2}>
                    <Typography color="textSecondary">{dateMainPhrase}</Typography>
                    {dateOptionalPhrase ? (
                        <Typography color="textSecondary">
                            {translateOptionalPhrase(dateOptionalPhrase)}
                        </Typography>
                    ) : null}
                    {dateOptionalPhrase2 ? (
                        <Typography color="textSecondary">
                            {translateOptionalPhrase(dateOptionalPhrase2)}
                        </Typography>
                    ) : null}
                </Grid>
                <Grid item>
                    {locked ? (
                        <Tooltip
                            title={t('components.AppointmentPrintView.locked')}
                            disableInteractive
                        >
                            <LockIcon color="disabled" fontSize="12px" />
                        </Tooltip>
                    ) : null}
                </Grid>
            </Grid>
            {address?.full ? (
                <Grid container direction="row" alignItems="center" wrap="nowrap" mt={2}>
                    <Grid item xs mr={2}>
                        <Typography color="textSecondary">{address.full}</Typography>
                    </Grid>
                    <Grid item>
                        <AddressViewIconButton {...address} />
                    </Grid>
                </Grid>
            ) : null}
        </Box>
    );
};

CalendarDetailBasics.propTypes = {
    title: PropTypes.string,
    address: PropTypes.shape({ full: PropTypes.string }),

    locked: PropTypes.bool,
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
    allDay: PropTypes.bool,
};

export default CalendarDetailBasics;
