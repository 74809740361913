import { all, put, select, takeEvery } from 'redux-saga/effects';
import {
    removeItem,
    initList,
    insertItem,
    insertGroup,
    insertGroupNestedList,
    groupNestedListPending,
    groupPending,
    insertAggregations,
} from './listsSlice';
import { selectListData } from './selectors';
import { calcPosition } from './utils';

const handlers = {};

function handleInitList(action) {
    const { listId, resource, criteria } = action.payload;
    if (criteria) {
        if (!handlers[resource]) {
            handlers[resource] = {};
        }

        if (!handlers[resource][listId]) {
            handlers[resource][listId] = criteria;
        }
    }
}

function handleItem(state, item, resourceHandlers) {
    return Object.entries(resourceHandlers).map(([listId, criteria]) => {
        const items = Object.values(selectListData(state, listId, true));
        const position = calcPosition(item, items, criteria, state);

        if (position === -1 && items) {
            const { __type: resource } = item;

            return put(removeItem(item.id, { listId, resource }));
        }

        return put(insertItem(item, { listId, position }));
    });
}

function* handleStoreFulfilled({ payload: item }) {
    if (item?.id) {
        const { __type: resource } = item;
        const resourceHandlers = handlers[resource];

        if (resourceHandlers) {
            const state = yield select();

            yield all(handleItem(state, item, resourceHandlers));
        }
    }
}

function* handleStoreBulkFulfilled({ payload: items }) {
    const { __type: resource } = items[0];
    const resourceHandlers = handlers[resource];
    if (resourceHandlers) {
        const state = yield select();

        const stateUpdates = items.reduce((carry, item) => {
            const updateBundle = handleItem(state, item, resourceHandlers);
            return [...carry, ...updateBundle];
        }, []);

        yield all(stateUpdates);
    }
}

function* handleDestroyFulfilled(action) {
    const { key: resource } = action.meta;
    if (resource) {
        yield put(removeItem(action.payload, { resource }));
    }
}

function* handleDestroyBulkFulfilled(action) {
    const { key: resource } = action.meta;
    if (resource) {
        yield all(action.payload.map(removedId => put(removeItem(removedId, { resource }))));
    }
}

function* handleAggregateFulfilled(action) {
    const { key: resource } = action.meta;
    if (resource) {
        const state = yield select();
        const resourceHandlers = handlers[resource];

        if (typeof action.payload === 'object') {
            yield put(insertAggregations(action.payload, action.meta));
        }
    }
}

function* handleGroupPending(action) {
    if (action.meta) {
        const { key: resource, listId } = action.meta;

        if (listId && resource) {
            yield put(groupPending(action));
        }
    }
}

function* handleGroupFulfilled(action) {
    const { key: resource, listId, params } = action.meta;

    if (listId && resource) {
        const groupKeys = params?.group_by;

        if (Array.isArray(groupKeys) && typeof action.payload === 'object') {
            const groupKey = groupKeys.map(({ key }) => key).join();

            if (typeof groupKey === 'string' && groupKey.length) {
                yield put(insertGroup(action.payload, { listId, groupKey }));
            }
        }
    }
}

function* handleGroupNestedListPending(action) {
    if (action?.meta?.key && action.meta.listId) {
        yield put(groupNestedListPending(action));
    }
}

function* handleGroupNestedListFulfilled(action) {
    const { key: resource, listId, groupKey, groupNestedListKey, ...other } = action.meta;

    if (resource && listId && groupKey && groupNestedListKey) {
        yield put(
            insertGroupNestedList(action.payload, {
                listId,
                groupKey,
                groupNestedListKey,
                ...other,
            })
        );
    }
}

export default [
    takeEvery(initList, handleInitList),
    takeEvery(action => /\/storeSocket$/.test(action.type), handleStoreFulfilled),
    takeEvery(action => /\/storeFulfilled$/.test(action.type), handleStoreFulfilled),
    takeEvery(action => /\/storeBulkFulfilled$/.test(action.type), handleStoreBulkFulfilled),
    takeEvery(action => /\/updateSocket$/.test(action.type), handleStoreFulfilled),
    takeEvery(action => /\/updateFulfilled$/.test(action.type), handleStoreFulfilled),
    takeEvery(action => /\/updateBulkFulfilled$/.test(action.type), handleStoreBulkFulfilled),
    takeEvery(action => /\/restoreSocket$/.test(action.type), handleStoreFulfilled),
    takeEvery(action => /\/restoreFulfilled$/.test(action.type), handleStoreFulfilled),
    takeEvery(action => /\/destroyFulfilled$/.test(action.type), handleDestroyFulfilled),
    takeEvery(action => /\/destroyBulkFulfilled$/.test(action.type), handleDestroyBulkFulfilled),
    takeEvery(action => /\/bulkSocket$/.test(action.type), handleStoreBulkFulfilled),
    takeEvery(action => /\/aggregateFulfilled$/.test(action.type), handleAggregateFulfilled),
    takeEvery(action => /\/groupPending$/.test(action.type), handleGroupPending),
    takeEvery(action => /\/groupFulfilled$/.test(action.type), handleGroupFulfilled),
    takeEvery(
        action => /\/groupNestedListPending$/.test(action.type),
        handleGroupNestedListPending
    ),
    takeEvery(
        action => /\/groupNestedListFulfilled$/.test(action.type),
        handleGroupNestedListFulfilled
    ),
];
