import React, { useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Form from '../form/formik/Form';
import { IdPropType, RefPropType } from '../../modules/proptypes';
import LoadingBox from '../loading/LoadingBox';
import { useTranslation } from 'react-i18next';
import SubmitButton from '../form/formik/SubmitButton';
import FormPopup from '../popups/FormPopup';
import { selectCardStateById } from '../../modules/cardStatus/selectors';
import {
    destroyCardStatus,
    storeCardStatus,
    updateCardStatus,
} from '../../modules/cardStatus/actions';
import { cardStatusSchema } from '../../modules/cardStatus/schema';
import CardStatusFormBody from './CardStatusFormBody';

const CardStatusForm = ({
    cardStatusId,
    asPopup,
    openPopupWith,
    asSmallForm,
    popupStateRef,
    onLoading,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const cardStatus = useSelector(state => selectCardStateById(state, cardStatusId));

    const initialValues = useMemo(
        () =>
            cardStatus || {
                name: '',
                icon: '',
                active: true,
                order: null,
            },
        [cardStatus]
    );

    const handleSubmit = newValue => {
        setLoading(true);
        if (onLoading) onLoading(true);
        return dispatch(
            cardStatusId ? updateCardStatus(newValue) : storeCardStatus(newValue)
        ).finally(() => {
            setLoading(false);
            if (onLoading) onLoading(false);
        });
    };

    const handleDelete = () => dispatch(destroyCardStatus({ id: cardStatusId }));

    return asPopup || asSmallForm ? (
        <FormPopup
            popupId="cardStatusPopupForm"
            popupStateRef={popupStateRef}
            initialValues={initialValues}
            validationSchema={cardStatusSchema}
            onSubmit={handleSubmit}
            onDelete={cardStatusId ? handleDelete : null}
            submitLabel={t('form.SubmitButton.defaultLabel')}
            abortLabel={t('abort')}
            enableReinitialize
            FormInputs={
                <Box
                    onKeyDown={e => {
                        if (e.code !== 'Escape') {
                            e.stopPropagation();
                        }
                    }}
                >
                    <CardStatusFormBody asSmallForm={asSmallForm} autoFocus />
                </Box>
            }
        >
            <Grid container>
                <Grid item>
                    <LoadingBox loading={loading} variant="circular" size="small">
                        {openPopupWith}
                    </LoadingBox>
                </Grid>
            </Grid>
        </FormPopup>
    ) : (
        <Form
            initialValues={initialValues}
            validationSchema={cardStatusSchema}
            onSubmit={handleSubmit}
        >
            <CardStatusFormBody />
            <Grid container spacing={2} justifyContent="flex-end">
                <SubmitButton>{t('form.SubmitButton.defaultLabel')}</SubmitButton>
            </Grid>
        </Form>
    );
};

CardStatusForm.propTypes = {
    cardStatusId: IdPropType,
    asPopup: PropTypes.bool,
    asSmallForm: PropTypes.bool,
    openPopupWith: PropTypes.node,
    popupStateRef: RefPropType,
    onLoading: PropTypes.func,
};

CardStatusForm.defaultProps = {
    cardStatusId: null,
    asPopup: false,
    asSmallForm: false,
    openPopupWith: null,
    popupStateRef: null,
    onLoading: null,
};

export default CardStatusForm;
