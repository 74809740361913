import * as PropTypes from 'prop-types';
import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import throttle from 'lodash/throttle';
import { useTranslation } from 'react-i18next';
import { getFormFieldError } from '../../../modules/loading/utils';
import { ErrorPropType } from '../../../modules/loading/proptypes';
import TextField from '../base/TextField';
import { useFieldTurbo } from '../../../modules/form/hooks';
import IPut from 'iput';
import { ConditionalWrapper } from '../../utils/ConditionalWrapper';
import { Tooltip } from '@mui/material';
import { debounce } from 'lodash/function';

const TextInput = forwardRef(
    (
        {
            name,
            originalName,
            label,
            onChange,
            onFormatInput,
            onFormatValue,
            onBlur: onBlurExt,
            onFocus: onFocusExt,
            errors,
            variant,
            contrast,
            type,
            forceInputType,
            disabled,
            throttled,
            ignoreErrorHelperCases,
            can,
            overrideInitialValue,
            shrink: overrideShrink,
            InputProps,
            InputLabelProps,
            tooltipLabel,
            ...other
        },
        ref
    ) => {
        const { t } = useTranslation();
        const [shrink, setShrink] = useState(overrideShrink !== undefined ? overrideShrink : false);
        const [
            { value: rawValue, onChange: changeField, onBlur, onFocus, ...field },
            meta,
            { setValue, setError },
        ] = useFieldTurbo(name);
        const error = getFormFieldError(name, errors, meta);

        const setValueThrottled = useCallback(throttle(setValue, throttled ? 1000 : 0), [
            setValue,
            throttled,
        ]);

        const handleError = useCallback(
            err => {
                if (err !== meta.error) {
                    setError(err);
                }
            },
            [setError, meta.error]
        );

        const approveValue = value => value !== undefined && value !== null && value !== '';

        const updateShrink = value => {
            const nextShrink = approveValue(value);
            if (nextShrink !== shrink) {
                setShrink(nextShrink);
            }
        };

        const helperText = useMemo(() => {
            if (!error) {
                return null;
            }

            if (ignoreErrorHelperCases && ignoreErrorHelperCases.indexOf(error) !== -1) {
                return null;
            }

            return typeof error === 'object' && error.noTranslateMessage
                ? error.noTranslateMessage
                : t(`errors.${error.split('.').join('')}`);
        }, [ignoreErrorHelperCases, error, t]);

        const value = useMemo(() => {
            const prepareText = () => {
                if (approveValue(rawValue) || rawValue === '') {
                    if (type === 'number') {
                        return rawValue.toString().replace(/\./, t('numberDelimiter'));
                    }
                    if (type === 'color') {
                        return `${rawValue.startsWith('#') ? '' : '#'}${rawValue}`;
                    }

                    return rawValue;
                }

                return overrideInitialValue;
            };

            const preppedValue = prepareText();
            return onFormatInput ? onFormatInput(preppedValue) : preppedValue;
        }, [rawValue, type, overrideInitialValue]);

        const handleFormatValue = () => {
            if (onFormatValue) {
                const formatted = onFormatValue(value, false);
                setValue(formatted);
            }
        };

        const handleChange = useCallback(
            event => {
                let newValue;

                if (onFormatValue) {
                    newValue = onFormatValue(event.target.value, true);
                } else if (type === 'number') {
                    newValue = event.target.value.replace(t('numberDelimiter'), '.');
                } else if (type === 'ip') {
                    newValue = event;
                } else {
                    newValue = event.target.value;
                }
                changeField(newValue);
                setValueThrottled(newValue);

                if (onChange) {
                    onChange(name, newValue);
                }
            },
            [type, onChange, onFormatValue, name, setValueThrottled, t]
        );

        useEffect(() => {
            if (value) {
                updateShrink(value);
            }
        }, [value]);

        useEffect(() => {
            if (overrideShrink !== undefined && overrideShrink !== shrink) {
                setShrink(overrideShrink);
            }
        }, [overrideShrink, shrink, setShrink]);

        return (
            <ConditionalWrapper
                condition={tooltipLabel}
                wrapper={_children => (
                    <Tooltip title={tooltipLabel} disableInteractive>
                        {_children}
                    </Tooltip>
                )}
            >
                <span>
                    <TextField
                        {...field}
                        originalName={originalName}
                        value={value}
                        label={label}
                        onChange={handleChange}
                        onError={handleError}
                        onFocus={e => {
                            setShrink(true);
                            if (onFocus) {
                                onFocus(e);
                            }
                            if (onFocusExt) {
                                onFocusExt(e);
                            }
                        }}
                        onBlur={e => {
                            updateShrink(value);
                            if (onBlur) {
                                onBlur(e);
                            }
                            if (onBlurExt) {
                                onBlurExt(e);
                            }
                            handleFormatValue();
                        }}
                        variant={variant}
                        contrast={contrast}
                        error={!!error}
                        helperText={helperText}
                        type={
                            (type === 'number' && !forceInputType) || type === 'ip' ? 'text' : type
                        }
                        disabled={disabled}
                        can={can}
                        InputProps={
                            type === 'ip'
                                ? {
                                      ...InputProps,
                                      sx: {
                                          ...{
                                              '.react-ip-input': {
                                                  border: 'none',
                                                  padding: '8.5px 14px',
                                              },
                                          },
                                      },
                                      defaultValue: rawValue,
                                      inputComponent: IPut,
                                  }
                                : InputProps
                        }
                        InputLabelProps={{
                            ...InputLabelProps,
                            shrink,
                        }}
                        ref={ref}
                        {...other}
                    />
                </span>
            </ConditionalWrapper>
        );
    }
);

TextInput.propTypes = {
    name: PropTypes.string.isRequired,
    originalName: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    onFormatInput: PropTypes.func,
    onFormatValue: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    errors: ErrorPropType,
    variant: PropTypes.string,
    contrast: PropTypes.bool,
    type: PropTypes.string,
    forceInputType: PropTypes.bool,
    disabled: PropTypes.bool,
    throttled: PropTypes.bool,
    can: PropTypes.string,
    overrideInitialValue: PropTypes.any,
    shrink: PropTypes.bool,
    InputProps: PropTypes.shape({}),
    InputLabelProps: PropTypes.shape({}),
    tooltipLabel: PropTypes.string,
};

TextInput.defaultProps = {
    originalName: null,
    label: null,
    onChange: null,
    onFormatInput: null,
    onFormatValue: null,
    onBlur: null,
    onFocus: null,
    errors: null,
    variant: 'outlined',
    contrast: false,
    type: null,
    forceInputType: false,
    disabled: false,
    throttled: false,
    can: null,
    overrideInitialValue: null,
    shrink: undefined,
    InputProps: null,
    InputLabelProps: null,
    tooltipLabel: null,
};

export default React.memo(TextInput);
