import React from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectTypeGroupedContactablesByCardId } from '../../modules/contactables/selectors';
import { IdPropType } from '../../modules/proptypes';
import { selectContactTypesById } from '../../modules/contactTypes/selectors';
import { selectContactsById } from '../../modules/contacts/selectors';

const typeCellStyle = { width: 86 };
const cellStyle = { verticalAlign: 'top' };

const CardContactsPrintView = ({ cardId }) => {
    const contactables = useSelector(state => selectTypeGroupedContactablesByCardId(state, cardId));
    const contacts = useSelector(selectContactsById);
    const contactTypes = useSelector(selectContactTypesById);

    return (
        <>
            <table>
                {contactables.map(({ contact_id: contactId, contact_type_id: contactTypeId }) => {
                    const contact = contacts[contactId];
                    const contactType = contactTypes[contactTypeId];

                    if (contact && contactType) {
                        return (
                            <tr key={`contact.${contactId}`}>
                                <td style={{ ...cellStyle, ...typeCellStyle }}>
                                    {contactType?.name_short}
                                </td>
                                <td style={cellStyle}>
                                    {contact.display_name ? contact.display_name : null}
                                    {contact.display_name && contact.display_name2 ? <br /> : null}
                                    {contact.display_name2 ? contact.display_name2 : null}
                                </td>
                            </tr>
                        );
                    }

                    return null;
                })}
            </table>
        </>
    );
};

CardContactsPrintView.propTypes = {
    cardId: IdPropType.isRequired,
};

export default CardContactsPrintView;
