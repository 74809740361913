import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { Icon as IconifyIcon } from '@iconify/react';
import baselinePushPin from '@iconify/icons-ic/baseline-push-pin';
import outlinePushPin from '@iconify/icons-ic/outline-push-pin';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import classNames from 'classnames';
import Confirmation from './Confirmation';
import FavButton from './FavButton';
import { ConditionalWrapper } from '../utils/ConditionalWrapper';
import { Tooltip } from '@mui/material';

const useStyles = makeStyles(theme => ({
    icon: {
        display: 'flex',
    },

    loading: {
        height: 48,
        width: 48,
    },

    danger: {
        color: theme.palette.error.main,
    },
}));

const Pin = ({ name, pinned, onChange, labelPin, labelUnpin, confirm, label }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);

    const handleConfirm = event => {
        setLoading(true);
        Promise.resolve(onChange(event, !pinned))
            .then(() => {
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const handleChange = (value, event) => {
        if (!confirm) {
            handleConfirm(event);
        }
    };

    return (
        <>
            {loading ? (
                <Grid
                    className={classes.loading}
                    container
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item>
                        <CircularProgress size={20} />
                    </Grid>
                </Grid>
            ) : (
                <Confirmation
                    popupId="pinPopover"
                    onConfirm={handleConfirm}
                    icon={
                        <IconifyIcon
                            icon={pinned ? baselinePushPin : outlinePushPin}
                            className={classNames(classes.icon, {
                                [classes.danger]: pinned,
                            })}
                            fontSize="small"
                        />
                    }
                    label={
                        pinned
                            ? labelUnpin || t('components.Pin.unpin')
                            : labelPin || t('components.Pin.pin')
                    }
                    disabled={!confirm}
                    color={pinned ? 'danger' : 'default'}
                >
                    <ConditionalWrapper
                        condition={label}
                        wrapper={_children => (
                            <Tooltip title={label} disableInteractive>
                                {_children}
                            </Tooltip>
                        )}
                    >
                        <span>
                            <FavButton
                                base
                                preventToggle
                                name={name}
                                value={pinned}
                                onChange={handleChange}
                            />
                        </span>
                    </ConditionalWrapper>
                </Confirmation>
            )}
        </>
    );
};

Pin.propTypes = {
    name: PropTypes.string,
    pinned: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    labelPin: PropTypes.string,
    labelUnpin: PropTypes.string,
    label: PropTypes.string,
    confirm: PropTypes.bool,
};

Pin.defaultProps = {
    name: 'pinned',
    confirm: false,
    labelPin: null,
    labelUnpin: null,
    label: null,
};

export default Pin;
