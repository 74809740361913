const paramToInt = paramId => {
    if (paramId) {
        const id = parseInt(paramId, 10);
        if (!Number.isNaN(id)) {
            return id;
        }
    }
    return null;
};

export const getIdFromParams = (key, urlParams) => paramToInt(urlParams[key]);

export const getIdsFromParams = (keys, urlParams) =>
    keys.reduce((carry, key) => ({ ...carry, [key]: getIdFromParams(key, urlParams) }), {});

export const getIdFromSearch = (key, search) => {
    const urlSearchParams = new URLSearchParams(search);
    return paramToInt(urlSearchParams.get(key));
};

export const getIdsFromSearch = (keys, search) =>
    keys.reduce((carry, key) => ({ ...carry, [key]: getIdFromSearch(key, search) }), {});
