import React from 'react';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { selectContactById } from '../../modules/contacts/selectors';
import CompanyPreview from '../company/CompanyPreview';
import { IdPropType } from '../../modules/proptypes';

const CompanyContact = ({ contactId }) => {
    const { t } = useTranslation();
    const contact = useSelector(state => selectContactById(state, contactId));

    return (
        <>
            <Box marginBottom={2} marginTop={1}>
                <Typography variant="h2" color="primary">
                    {t('components.ContactBaseData.company')}
                </Typography>
            </Box>
            {contact && <CompanyPreview contactId={contactId} />}
        </>
    );
};

CompanyContact.propTypes = {
    contactId: IdPropType,
};

CompanyContact.defaultProps = {
    contactId: null,
};

export default CompanyContact;
