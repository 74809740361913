import { assembleLocal, convertLocalDateToUTC, formatTime } from '../datetime/utils';

export const INDIVIDUAL = 'individual';

export const formatTaskSubmit = ({
    due_at: dueDate,
    due_time: dueTime,
    task_type_id: taskTypeId,
    task_type_text: taskTypeText,
    ...rest
}) => ({
    ...rest,
    due_at:
        dueDate &&
        convertLocalDateToUTC(assembleLocal(dueDate, dueTime ? formatTime(dueTime) : '23:59')),
    has_due_time: dueDate !== null && dueTime !== null,
    task_type_id: taskTypeId === INDIVIDUAL ? null : taskTypeId,
    task_type_text: taskTypeId === INDIVIDUAL ? taskTypeText : null,
});
