import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import LinearProgress from '@mui/material/LinearProgress';
import React from 'react';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },

    page: {
        minHeight: '80vh',
    },
}));

const LoadingBlock = ({ isLoading, children, page }) => {
    const classes = useStyles();

    // TODO: fade in after x seconds

    return isLoading ? (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={`${classes.root} ${page ? classes.page : ''}`}
        >
            <Grid item xs={6} sm={4}>
                <LinearProgress />
            </Grid>
        </Grid>
    ) : (
        children
    );
};

LoadingBlock.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    page: PropTypes.bool,
};

LoadingBlock.defaultProps = {
    page: false,
};

export default LoadingBlock;
