import React, { useMemo } from 'react';
import { IdPropType } from '../../../modules/proptypes';
import AppointmentCalendar from '../../calendar/AppointmentCalendar';
import { EMPLOYEE_RESOURCE } from '../../../modules/api/resources';

const EmployeeCalendar = ({ employeeId }) => {
    return (
        <AppointmentCalendar
            calendarId={`EmployeeCalendar.${employeeId}`}
            defaultEntityId={employeeId}
            defaultEntityResource={EMPLOYEE_RESOURCE}
        />
    );
};

EmployeeCalendar.propTypes = {
    employeeId: IdPropType.isRequired,
};

export default EmployeeCalendar;
