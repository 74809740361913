import { loginError, loginSuccess, logoutSuccess } from './authSlice';
import logger from '../../logger';
//import Persistor from '../persistor/persistor';
import { resetLastCreatedPersonalAccessTokenUrl } from '../personalAccessToken/personalAccessTokenSlice';

export const logout = data => (dispatch, getState, api) =>
    api.logout(data).then(() => {
        dispatch(resetLastCreatedPersonalAccessTokenUrl());
        // Should only remove user login/auth related data. but none is persisted
        //Persistor.clear();
        return dispatch(logoutSuccess());
    });

export const login = data => (dispatch, getState, api) =>
    dispatch(() =>
        api
            .authenticate(data)
            .then(user => {
                dispatch(loginSuccess(user));
                return user;
            })
            .catch(error => {
                // Should only remove user login/auth related data. but none is persisted
                //Persistor.clear();
                logger.error(error);
                dispatch(loginError(error));
                return error;
            })
    );

export const getAuthenticated = data => (dispatch, getState, api) =>
    dispatch(() =>
        api
            .getAuthenticated(data)
            .then(user => {
                dispatch(loginSuccess(user));
                return user;
            })
            .catch(error => {
                logger.error(error);
                dispatch(loginError(error));
                return null;
            })
    );
