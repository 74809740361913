import React, { useEffect, useMemo, useRef } from 'react';
import * as PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    rootFullHeight: {
        height: '100%',
        width: '100%',
    },
});

export const SIZE_WATCH_MODES = {
    PARENT: 'parent',
    CHILD: 'child',
};

const SizeWatcher = ({ mode, onlyOnce, fullHeight, onSizeChange, children, ...other }) => {
    const classes = useStyles();
    const isObserving = useRef(false);

    const resizeObserver = useMemo(
        () =>
            new ResizeObserver(([{ target }], observer) => {
                if (onlyOnce) {
                    observer.unobserve(target);
                }

                onSizeChange(target.getBoundingClientRect(), { target, observer });
            }),
        []
    );

    const handleSize = element => {
        if (element && element instanceof Node && !isObserving.current) {
            if (mode === SIZE_WATCH_MODES.CHILD && element?.children[0] instanceof Node) {
                isObserving.current = true;
                onSizeChange(element?.children[0].getBoundingClientRect());
                resizeObserver.observe(element.children[0]);
            }

            if (mode === SIZE_WATCH_MODES.PARENT && element?.parentElement instanceof Node) {
                isObserving.current = true;
                onSizeChange(element?.parentElement.getBoundingClientRect());
                resizeObserver.observe(element.parentElement);
            }
        }
    };

    useEffect(() => () => resizeObserver.disconnect(), [resizeObserver]);

    return (
        <Box
            ref={handleSize}
            className={fullHeight ? classes.rootFullHeight : classes.root}
            {...other}
        >
            {children}
        </Box>
    );
};

SizeWatcher.propTypes = {
    mode: PropTypes.oneOf(Object.values(SIZE_WATCH_MODES)).isRequired,
    onlyOnce: PropTypes.bool,
    fullHeight: PropTypes.bool,
    onSizeChange: PropTypes.func.isRequired,
    children: PropTypes.element,
};

SizeWatcher.defaultProps = {
    onlyOnce: false,
    fullHeight: false,
};

export default SizeWatcher;
