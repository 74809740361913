import { put, takeEvery } from '@redux-saga/core/effects';

import { CHECKLIST_ITEM_RESOURCE, CHECKLIST_RESOURCE } from '../api/resources';
import { indexFulfilled as indexChecklistItemsFulfilled } from '../checklistItems/checklistItemsSlice';

function* nestedIndexFulfilled(action) {
    const checklistItems = action.payload.reduce((carry, { checklist_items: checklistItems }) => {
        if (checklistItems) {
            carry.push(...checklistItems);
        }

        return carry;
    }, []);
    yield put(indexChecklistItemsFulfilled(checklistItems, { key: CHECKLIST_ITEM_RESOURCE }));
}

function* nestedShowFulfilled(action) {
    yield put(indexChecklistItemsFulfilled(action.payload, { key: CHECKLIST_ITEM_RESOURCE }));
}

export default [
    takeEvery(`${CHECKLIST_RESOURCE}/indexFulfilled`, nestedIndexFulfilled),
    takeEvery(`${CHECKLIST_RESOURCE}/showFulfilled`, nestedShowFulfilled),
    takeEvery(`${CHECKLIST_RESOURCE}/updateFulfilled`, nestedShowFulfilled), //Todo: ??
];
