/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { CATEGORY_RESOURCE } from '../api/resources';

const insertItems = (state, item) => {
    const stale = state.byId[item.id];
    if (!stale) {
        state.allIds.push(item.id);
    }
    state.byId[item.id] = item;
};

const categoriesSlice = createSlice({
    name: CATEGORY_RESOURCE,
    initialState: {
        byId: {},
        allIds: [],
    },
    reducers: {
        indexFulfilled: (state, action) => {
            action.payload.forEach(item => {
                insertItems(state, item);
            });
        },
        showFulfilled: (state, action) => {
            insertItems(state, action.payload);
        },
        storeFulfilled: (state, action) => {
            insertItems(state, action.payload);
        },
        updateFulfilled: (state, action) => {
            insertItems(state, action.payload);
        },
        destroyFulfilled: (state, action) => {
            const index = state.allIds.findIndex(id => id === action.payload);
            if (index >= 0) {
                delete state.byId[action.payload];
                state.allIds.splice(index, 1);
            }
        },
    },
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,
} = categoriesSlice.actions;

export default categoriesSlice.reducer;
