import { createResourceSlice } from '../redux/resource/createResourceSlice';
import { ATTACHMENT_RESOURCE } from '../api/resources';

const attachmentSlice = createResourceSlice({
    resource: ATTACHMENT_RESOURCE,
    byKey: '',
});
export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,
    indexAttachments,
    showAttachment,
    storeAttachment,
    updateAttachment,
    archiveAttachment,
    restoreAttachment,
    destroyAttachment,
} = attachmentSlice.actions;

export const {
    selectAttachmentById,
    makeAttachmentsByIdsSelector,
    selectAllAttachmentIds,
    selectAllAttachments,
    selectAttachmentsLoading,
    selectAttachmentsInitialized,
    selectAttachmentLoading,
    selectAttachmentInitialized,
    selectAttachmentIdsBy,
    makeAttachmentsBySelector,
    selectAttachmentLoadingBy,
    selectAttachmentInitializedBy,
} = attachmentSlice.selectors;

export const { useAttachments, useAttachment, useAttachmentsBy } = attachmentSlice.hooks;

export default attachmentSlice.reducer;
