import React, { useMemo } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MUISelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@mui/styles';
import FormHelperText from '@mui/material/FormHelperText';
import { useField } from 'formik';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import OutlinedInput from '@mui/material/OutlinedInput';
import Input from '@mui/material/Input';
import { useContextualCan } from '../../../modules/abilities/hooks';
import ContextualCan from '../../../modules/abilities/ContextualCan';
import { READ, WRITE } from '../../../modules/abilities/actions';
import Icon from '../../icons/Icon';
import EditIconButton from '../../buttons/EditIconButton';
import { CircularProgress } from '@mui/material';

const useStyles = makeStyles(theme => ({
    fullWidth: {
        width: '100%',
    },

    labelContrast: {
        color: theme.palette.primary.contrastText,

        '&$labelContrastFocused': {
            color: theme.palette.primary.contrastText,
        },

        '&$labelContrastError': {
            color: theme.palette.error.light,
        },
    },
    labelContrastFocused: {},
    labelContrastError: {},

    inputContrast: {
        backgroundColor: 'transparent',
        color: theme.palette.primary.contrastText,
        borderWidth: 5,

        '&:hover $inputContrastOutline': {
            borderColor: theme.palette.primary.contrastText,
        },
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
            '&:hover $inputContrastOutline': {
                borderColor: theme.palette.primary.contrastText,
            },
        },
        '&$inputContrastFocused $inputContrastOutline': {
            borderColor: theme.palette.primary.contrastText,
        },
        '&$inputContrastError $inputContrastOutline': {
            borderColor: theme.palette.error.light,
        },
    },
    inputContrastFocused: {},
    inputContrastError: {},
    inputContrastIcon: {
        color: theme.palette.primary.contrastText,
    },
    inputContrastOutline: {
        borderColor: theme.palette.primary.contrastText,
    },

    optionIcon: {
        fontSize: '1.2rem',
        marginRight: theme.spacing(1),
        float: 'left',
    },
    menuItemFlex: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
}));

const Select = ({
    name,
    originalName,
    label,
    labelId,
    options,
    onChange,
    fullWidth,
    contrast,
    className,
    variant,
    size,
    translateOption,
    disabled,
    can,
    overrideInitialValue,
    InputLabelProps,
    ItemEditFormPopupProps,
    renderValue,
    ...other
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const canDo = useContextualCan(can ? can : WRITE, originalName || name);

    const [field, meta, helpers] = useField(name);
    const value = field?.value || overrideInitialValue || '';

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current ? inputLabel.current.offsetWidth : 0);
    }, []);

    const InputComponent = useMemo(
        () =>
            contrast
                ? {
                      plain: (
                          <Input
                              disableUnderline
                              classes={{
                                  root: classes.inputContrast,
                                  focused: classes.inputContrastFocused,
                                  error: classes.inputContrastError,
                              }}
                              shrink
                          />
                      ), // TODO: implement if used
                      // standard: <Input />, // TODO: implement if used
                      outlined: (
                          <OutlinedInput
                              label={label}
                              classes={{
                                  root: classes.inputContrast,
                                  notchedOutline: classes.inputContrastOutline,
                                  focused: classes.inputContrastFocused,
                                  error: classes.inputContrastError,
                              }}
                              shrink
                          />
                      ),
                      // filled: <FilledInput />, // TODO: implement if used
                  }[variant]
                : null,
        [contrast, variant, label, labelWidth, classes]
    );

    const optionLabel = option => {
        const currentLabel = translateOption ? t(option.label) : option.label;
        return option.value ? (
            <span>
                {option.icon && (
                    <Icon
                        type={option.icon}
                        className={classes.optionIcon}
                        style={{
                            color: option.iconColor
                                ? `${option.iconColor.startsWith('#') ? '' : '#'}${
                                      option.iconColor
                                  }`
                                : 'inherit',
                        }}
                    />
                )}
                {currentLabel || option.value}
            </span>
        ) : (
            <em>{currentLabel}</em>
        );
    };

    const handleOnEdit = (event, handler) => {
        if (handler && typeof handler === 'function') {
            event.stopPropagation();
            handler(event);
            const popupRef = ItemEditFormPopupProps?.component?.props?.popupStateRef;
            return popupRef ? popupRef.current?.open(event) : null;
        }
    };

    const editItemIsLoading = option =>
        ItemEditFormPopupProps?.isLoading &&
        ItemEditFormPopupProps?.selectedEditItemValue === option.value;

    return (
        <ContextualCan I={READ} field={originalName || name}>
            <FormControl
                variant={variant !== 'plain' ? variant : 'standard'}
                className={classNames({ [classes.fullWidth]: fullWidth })}
                error={!!meta.touched && meta?.error}
                style={fullWidth ? {} : { minWidth: Math.max(120, labelWidth + 14 + 32) }}
                size={size}
            >
                {label && (
                    <InputLabel
                        ref={inputLabel}
                        id={`${labelId || name}-label`}
                        className={classNames({
                            [classes.labelContrast]: contrast,
                        })}
                        classes={
                            contrast
                                ? {
                                      root: classes.labelContrast,
                                      focused: classes.labelContrastFocused,
                                      error: classes.labelContrastError,
                                  }
                                : {}
                        }
                        {...InputLabelProps}
                    >
                        {label}
                    </InputLabel>
                )}
                <MUISelect
                    {...field}
                    value={value}
                    onChange={event => {
                        field.onChange(event);
                        if (onChange) {
                            onChange(name, event.target.value);
                        }
                    }}
                    onError={error => {
                        if (error !== meta.error) {
                            helpers.setError(error);
                        }
                    }}
                    labelId={`${labelId || name}-label`}
                    labelWidth={labelWidth}
                    label={label}
                    className={className}
                    classes={
                        contrast
                            ? {
                                  icon: classes.inputContrastIcon,
                              }
                            : {}
                    }
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: '50vh',
                            },
                        },
                    }}
                    renderValue={
                        renderValue ||
                        (selectedValue => {
                            const selectedOption = options.find(o => o.value === selectedValue);
                            return selectedOption ? (
                                <>
                                    {optionLabel(selectedOption)}
                                    {editItemIsLoading(selectedOption) && (
                                        <CircularProgress size={14} />
                                    )}
                                </>
                            ) : null;
                        })
                    }
                    input={InputComponent}
                    fullWidth={fullWidth}
                    disabled={disabled || !canDo}
                    {...other}
                >
                    {options.map(
                        option =>
                            (!option.showOnlyWhenSelected || option.value === value) && (
                                <MenuItem
                                    value={option.value}
                                    key={option.value}
                                    disabled={option.disabled || editItemIsLoading(option)}
                                    className={classes.menuItemFlex}
                                >
                                    {optionLabel(option)}
                                    {/* {ItemEditFormPopupProps?.canEdit &&
                                        option.value &&
                                        (editItemIsLoading(option) ? (
                                            <CircularProgress size={14} />
                                        ) : (
                                            <EditIconButton
                                                onClick={e => handleOnEdit(e, option.onEdit)}
                                                size="small"
                                            />
                                        ))} */}
                                </MenuItem>
                            )
                    )}
                    {ItemEditFormPopupProps?.component}
                </MUISelect>
                {meta.touched && meta.error && (
                    <FormHelperText error>
                        {t(
                            `errors.${meta.error
                                .split('.')
                                .join('')
                                .replaceAll('`', '')
                                .replaceAll(',', '')
                                .replaceAll('(', '')
                                .replaceAll(')', '')
                                .replaceAll(':', '')}`
                        )}
                    </FormHelperText>
                )}
            </FormControl>
        </ContextualCan>
    );
};

Select.propTypes = {
    name: PropTypes.string.isRequired,
    originalName: PropTypes.string,
    label: PropTypes.string,
    labelId: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            icon: PropTypes.string,
            iconColor: PropTypes.string,
            onEdit: PropTypes.func,
        })
    ).isRequired,
    onChange: PropTypes.func,
    fullWidth: PropTypes.bool,
    contrast: PropTypes.bool,
    className: PropTypes.string,
    variant: PropTypes.string,
    size: PropTypes.string,
    translateOption: PropTypes.bool,
    disabled: PropTypes.bool,
    can: PropTypes.string,
    overrideInitialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    InputLabelProps: PropTypes.shape({}),
    ItemEditFormPopupProps: PropTypes.shape({
        component: PropTypes.node.isRequired,
        canEdit: PropTypes.bool,
        selectedEditItemValue: PropTypes.any,
        isLoading: PropTypes.bool,
    }),
    renderValue: PropTypes.func,
};

Select.defaultProps = {
    originalName: null,
    label: null,
    labelId: null,
    onChange: null,
    fullWidth: false,
    contrast: false,
    className: null,
    variant: 'outlined',
    size: 'small',
    translateOption: false,
    disabled: false,
    can: null,
    overrideInitialValue: null,
    InputLabelProps: {},
    ItemEditFormPopupProps: null,
    renderValue: null,
};

export default Select;
