import { ADDRESS_RESOURCE, CONTACT_DATA_RESOURCE, PERSON_RESOURCE } from '../api/resources';
import { storeContact, updateContact } from './actions';
import { MODEL_CARD, MODEL_CONTACT } from '../../components/utils/ModelClasses';
import { destroyAddress, storeAddress, updateAddress } from '../addresses/actions';
import { destroyContactData, storeContactData, updateContactData } from '../contactData/actions';
import { multiupdateContactable } from '../contactables/actions';

export const CONTACT_CHECK_FIELDS = {
    [PERSON_RESOURCE]: ['gender', 'title', 'first_name', 'last_name', 'birthday'],
    [ADDRESS_RESOURCE]: [
        'address1',
        'address2',
        'address_type_id',
        'city',
        'city',
        'country',
        'state',
        'zip',
    ],
    [CONTACT_DATA_RESOURCE]: ['value', 'comment'],
};

export const confirmSubmit = (type, values) => {
    if (!type || !values) {
        return false;
    }
    return !CONTACT_CHECK_FIELDS[type].reduce(
        (carry, field) => carry && values[field] === '',
        true
    );
};

export const bulkSubmit = async ({ values, initialValues, contactId, contextCardId, dispatch }) => {
    let { contact } = values;
    const { person, company, addresses, contactData, contactTypeIds } = values;
    /* create / update contact */
    const preppedContact = {
        ...contact,
        id: contactId,
        person_id: person ? person.id : null,
        company_id: company ? company.id : null,
    };
    if (!contactId) {
        contact = (await dispatch(storeContact(preppedContact))).data;
    } else {
        contact = (await dispatch(updateContact(preppedContact))).data;
    }

    /* sync addresses */
    await Promise.all(
        addresses.map(address => {
            if (confirmSubmit(ADDRESS_RESOURCE, address)) {
                const preppedAddress = {
                    ...address,
                    parent_id: contact.id,
                    parent_type: MODEL_CONTACT,
                };
                return dispatch(
                    address.id ? updateAddress(preppedAddress) : storeAddress(preppedAddress)
                );
            }

            return null;
        })
    );
    await Promise.all(
        initialValues.addresses
            .filter(address => address.id && !addresses.some(keep => keep.id === address.id))
            .map(address => {
                return dispatch(destroyAddress(address));
            })
    );

    /* sync contactDatas */
    await Promise.all(
        contactData.map((data, index) => {
            if (confirmSubmit(CONTACT_DATA_RESOURCE, data)) {
                const preppedContactData = {
                    ...data,
                    contact_id: contact.id,
                    order: index,
                };
                return dispatch(
                    data.id
                        ? updateContactData(preppedContactData)
                        : storeContactData(preppedContactData)
                );
            }
            return null;
        })
    );
    await Promise.all(
        initialValues.contactData
            .filter(data => data.id && !contactData.some(keep => keep.id === data.id))
            .map(data => {
                return dispatch(destroyContactData(data));
            })
    );

    /* create contactables */
    if (contactTypeIds.length) {
        const preppedContactTypes = {
            contactable_id: contextCardId,
            contactable_type: MODEL_CARD,
            contact_id: contact.id,
            contact_type_ids: contactTypeIds,
            pinned: false,
        };

        await dispatch(multiupdateContactable(preppedContactTypes));
    }

    return { contact };
};
