import { createResourceSlice } from '../redux/resource/createResourceSlice';
import { TASK_TYPE_RESOURCE } from '../api/resources';

const taskTypesSlice = createResourceSlice({
    resource: TASK_TYPE_RESOURCE,
    byKey: '',
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,
    indexTaskTypes,
    showTaskType,
    storeTaskType,
    updateTaskType,
    archiveTaskType,
    restoreTaskType,
    destroyTaskType,
} = taskTypesSlice.actions;

export const {
    selectTaskTypeById,
    makeTaskTypesByIdsSelector,
    selectAllTaskTypeIds,
    selectAllTaskTypes,
    selectTaskTypesLoading,
    selectTaskTypesInitialized,
    selectTaskTypeLoading,
    selectTaskTypeInitialized,
    selectTaskTypeIdsBy,
    makeTaskTypesBySelector,
    selectTaskTypeLoadingBy,
    selectTaskTypeInitializedBy,
} = taskTypesSlice.selectors;

export const { useTaskTypes, useTaskType, useTaskTypesBy } = taskTypesSlice.hooks;

export default taskTypesSlice.reducer;
