import React from 'react';
import * as PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import baselinePushPin from '@iconify/icons-ic/baseline-push-pin';
import outlinePushPin from '@iconify/icons-ic/outline-push-pin';

import BaseToggleButton from './base/BaseToggleButton';
import ToggleButton from './formik/ToggleButton';
import { useTheme } from '@mui/styles';

const FavButton = ({ name, base, value, onChange, size, preventToggle, I }) => {
    const theme = useTheme();

    return base ? (
        <BaseToggleButton
            name={name}
            value={value}
            icon={<Icon icon={outlinePushPin} color={theme.palette.primary.main} />}
            iconToggled={<Icon icon={baselinePushPin} color={theme.palette.primary.main} />}
            onChange={onChange}
            size={size}
            preventToggle={preventToggle}
            I={I}
        />
    ) : (
        <ToggleButton
            name={name}
            icon={<Icon icon={outlinePushPin} color={theme.palette.primary.main} />}
            iconToggled={<Icon icon={baselinePushPin} color={theme.palette.primary.main} />}
            size={size}
            I={I}
        />
    );
};

FavButton.propTypes = {
    name: PropTypes.string.isRequired,
    base: PropTypes.bool,
    value: PropTypes.bool,
    onChange: PropTypes.func,
    size: PropTypes.string,
    preventToggle: PropTypes.bool,
    I: PropTypes.string,
};

FavButton.defaultProps = {
    base: false,
    value: false,
    onChange: null,
    size: 'medium',
    preventToggle: false,
    I: null,
};

export default FavButton;
