import React from 'react';
import * as PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '../TextField';

const AsyncAutocompleteInput = ({ loading, InputProps, autofocus, autofill, can, ...other }) => (
    <TextField
        margin="normal"
        autoFocus={autofocus}
        autofill={autofill}
        InputProps={{
            ...InputProps,
            endAdornment: (
                <>
                    {loading ? <CircularProgress color="inherit" size={16} /> : null}
                    {InputProps ? InputProps.endAdornment : null}
                </>
            ),
        }}
        can={can}
        {...other}
    />
);

AsyncAutocompleteInput.propTypes = {
    loading: PropTypes.bool.isRequired,
    InputProps: PropTypes.shape({}),
    autofocus: PropTypes.bool,
    autofill: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    can: PropTypes.string,
};

AsyncAutocompleteInput.defaultProps = {
    InputProps: null,
    autofocus: false,
    autofill: 'false',
    can: null,
};

export default AsyncAutocompleteInput;
