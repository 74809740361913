import { createResourceSlice } from '../redux/resource/createResourceSlice';
import { IDENTIFIER_RESOURCE } from '../api/resources';

const identifierSlice = createResourceSlice({
    resource: IDENTIFIER_RESOURCE,
    byKey: '',
});
export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,
    indexIdentifiers,
    showIdentifier,
    storeIdentifier,
    updateIdentifier,
    archiveIdentifier,
    restoreIdentifier,
    destroyIdentifier,
    searchIdentifiers,
    autocompleteIdentifiers,
    suggestIdentifiers,
} = identifierSlice.actions;
export const {
    selectIdentifierById,
    makeIdentifiersByIdsSelector,
    selectAllIdentifierIds,
    selectAllIdentifiers,
    selectIdentifiersLoading,
    selectIdentifiersInitialized,
    selectIdentifierLoading,
    selectIdentifierInitialized,
    selectIdentifierIdsBy,
    makeIdentifiersBySelector,
    selectIdentifierLoadingBy,
    selectIdentifierInitializedBy,
} = identifierSlice.selectors;
export const { useIdentifiers, useIdentifier, useIdentifiersBy } = identifierSlice.hooks;
export default identifierSlice.reducer;
