import * as Yup from 'yup';

const requiredIfNoOtherValue = (otherValue, schema) =>
    otherValue ? schema : schema.required('email');

export const formatMailViewContactSchema = Yup.object().shape(
    {
        email: Yup.string().when('emailAddress', requiredIfNoOtherValue),
        emailAddress: Yup.string().when('email', requiredIfNoOtherValue),
    },
    [['email', 'emailAddress']]
);

export const formatMailViewSchema = Yup.object().shape({
    messageId: Yup.string().min(2).required('messageId'),
    from: formatMailViewContactSchema.required('from'),
    to: Yup.array().of(formatMailViewContactSchema).min(1).required('to'),
});
