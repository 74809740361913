import React, { memo, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import {
    Appointments,
    Scheduler,
    MonthView,
    WeekView,
    DayView,
    Toolbar,
    DateNavigator,
    TodayButton,
    ViewSwitcher,
    AllDayPanel,
    DragDropProvider,
    AppointmentForm,
    AppointmentTooltip,
    ConfirmationDialog,
    EditRecurrenceMenu,
} from '@devexpress/dx-react-scheduler-material-ui';
import { ViewState } from '@devexpress/dx-react-scheduler';
import Box from '@mui/material/Box';
import DayScaleCell from './DayScaleCell';
import { useTranslation } from 'react-i18next';
import CalendarResources from './CalendarResources';
import CalendarProvider from './CalendarContext';
import { useElementSize } from '../../modules/hooks';
import CalendarEditingState from './CalendarEditingState';
import CalendarDetail from './CalendarDetail';
import CalendarFormActions from './form/CalendarFormActions';
import CalendarFormBasicLayout from './form/CalendarFormBasicLayout';
import CalendarCreateButton from './CalendarCreateButton';
import { setHours, setMinutes } from 'date-fns';
import CalendarDetailMeta from './detail/CalendarDetailMeta';
import CalendarDetailLayout from './detail/CalendarDetailLayout';
import {
    CalendarAllDayCell,
    CalendarDayCell,
    CalendarMonthCell,
    CalendarWeekCell,
} from './CalendarCell';
import { Tooltip } from '@mui/material';
import { CALENDAR_VIEWS } from '../../modules/calendar/utils';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    container: {
        height: '100%',
    },
});

const CONFIG = {
    startDay: 0,
    endDay: 24,
    timeStep: 60,
    startWeek: 1,
};

const CustomNavigationButton = props => {
    const { t } = useTranslation();
    return (
        <Tooltip
            title={t(`components.Calendar.DateNavigator.NavigationButton.${props.type}`)}
            disableInteractive
        >
            <span>
                <DateNavigator.NavigationButton {...props} />
            </span>
        </Tooltip>
    );
};

const CustomOpenButtonComponent = props => {
    const { t } = useTranslation();
    return (
        <Tooltip
            title={t('components.Calendar.DateNavigator.OpenButton.tooltip')}
            disableInteractive
        >
            <span>
                <DateNavigator.OpenButton {...props} />
            </span>
        </Tooltip>
    );
};

const CustomViewSwitcher = props => {
    const { t } = useTranslation();
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const handleTooltip = bool => {
        setTooltipOpen(bool);
    };

    return (
        <Tooltip
            title={t('components.Calendar.ViewSwitcher.tooltip')}
            open={tooltipOpen}
            disableInteractive
        >
            <Box>
                <ViewSwitcher.Switcher
                    {...props}
                    onMouseEnter={() => {
                        handleTooltip(true);
                    }}
                    onMouseLeave={() => {
                        handleTooltip(false);
                    }}
                    onOpen={() => {
                        handleTooltip(false);
                    }}
                />
            </Box>
        </Tooltip>
    );
};

const Calendar = ({
    calendarId,
    appointments,
    availableViews,
    initialDate,
    initialView,
    showCreate,
    createRaised,
    allowDrag,
    allowResize,
    onDateChange,
    onViewChange,
    onSubmit,
    AppointmentComponent,
    FormComponent,
    ToolbarOptionsComponent,
}) => {
    //const locale = useLocale();
    const { t } = useTranslation();
    const classes = useStyles();
    const schedulerRef = useRef();
    const viewDate = useRef(initialDate);
    const [schedulerHeight, setSchedulerHeight] = useState();
    const [showForm, setShowForm] = useState(false);
    const [editingAppointment, setEditingAppointment] = useState(null);
    const [isCreate, setIsCreate] = useState(false);

    const handleEdit = (appointment, isNew) => {
        if (!appointment) {
            setEditingAppointment(null);
            setIsCreate(false);
        }

        if (
            (!editingAppointment && appointment) ||
            editingAppointment?.id !== appointment?.id ||
            !appointment?.id
        ) {
            setEditingAppointment(appointment);
        }

        if (isCreate !== isNew) {
            setIsCreate(isNew);
        }
    };

    const toggleForm = (set = null) => {
        setShowForm(current => (set === null ? !current : set));
    };

    const handleCreate = () => {
        const startDate = setMinutes(setHours(viewDate.current, 12), 0);

        handleEdit(
            {
                startDate,
                endDate: setHours(startDate, 13),
            },
            true
        );

        toggleForm(true);
    };

    const handleDate = date => {
        onDateChange(date);
        viewDate.current = date;
    };

    const handleSubmit = values => {
        setEditingAppointment(null);
        onSubmit(values);
    };

    useElementSize({ ref: schedulerRef, onChange: ({ height }) => setSchedulerHeight(height) });

    return (
        <CalendarProvider
            calendarId={calendarId}
            editingAppointment={editingAppointment}
            isCreate={isCreate}
        >
            <Box className={classes.container} ref={schedulerRef}>
                <Scheduler data={appointments} locale="de-DE" firstDayOfWeek={1}>
                    <ViewState
                        defaultCurrentDate={initialDate}
                        defaultCurrentViewName={initialView}
                        onCurrentDateChange={handleDate}
                        onCurrentViewNameChange={onViewChange}
                    />
                    <CalendarEditingState onSubmit={handleSubmit} onEdit={handleEdit} />

                    {availableViews.includes('month') && (
                        <MonthView
                            name="month"
                            displayName={t('components.Calendar.month')}
                            startDayHour={CONFIG.startDay}
                            endDayHour={CONFIG.endDay}
                            cellDuration={CONFIG.timeStep}
                            timeTableCellComponent={props => (
                                <CalendarMonthCell schedulerHeight={schedulerHeight} {...props} />
                            )}
                        />
                    )}

                    {availableViews.includes('week') && (
                        <WeekView
                            name="week"
                            displayName={t('components.Calendar.week')}
                            startDayHour={CONFIG.startDay}
                            endDayHour={CONFIG.endDay}
                            cellDuration={CONFIG.timeStep}
                            timeTableCellComponent={CalendarWeekCell}
                            dayScaleCellComponent={DayScaleCell}
                        />
                    )}

                    {availableViews.includes('workweek') && (
                        <WeekView
                            name="workweek"
                            displayName={t('components.Calendar.workweek')}
                            startDayHour={CONFIG.startDay}
                            endDayHour={CONFIG.endDay}
                            cellDuration={CONFIG.timeStep}
                            excludedDays={[0, 6]}
                            timeTableCellComponent={CalendarWeekCell}
                            dayScaleCellComponent={DayScaleCell}
                        />
                    )}

                    {availableViews.includes('day') && (
                        <DayView
                            name="day"
                            displayName={t('components.Calendar.day')}
                            startDayHour={CONFIG.startDay}
                            endDayHour={CONFIG.endDay}
                            timeTableCellComponent={CalendarDayCell}
                        />
                    )}

                    <Toolbar
                        flexibleSpaceComponent={ToolbarOptionsComponent || Toolbar.FlexibleSpace}
                    />
                    <DateNavigator
                        navigationButtonComponent={CustomNavigationButton}
                        openButtonComponent={CustomOpenButtonComponent}
                    />
                    <TodayButton messages={{ today: t('components.Calendar.today') }} />
                    <ViewSwitcher switcherComponent={CustomViewSwitcher} />

                    <AllDayPanel
                        cellComponent={CalendarAllDayCell}
                        messages={{ allDay: t('components.Calendar.allDay') }}
                    />
                    <Appointments
                        appointmentComponent={AppointmentComponent || Appointments.Appointment}
                    />

                    <CalendarResources />

                    <EditRecurrenceMenu
                        messages={t('components.Calendar.EditRecurrenceMenu', {
                            returnObjects: true,
                        })}
                    />
                    <ConfirmationDialog
                        messages={t('components.Calendar.ConfirmationDialog', {
                            returnObjects: true,
                        })}
                        ignoreCancel
                    />

                    <AppointmentTooltip
                        layoutComponent={CalendarDetailLayout}
                        contentComponent={CalendarDetail}
                        headerComponent={CalendarDetailMeta}
                        showOpenButton
                    />

                    <AppointmentForm
                        overlayComponent={FormComponent || AppointmentForm.Overlay}
                        commandLayoutComponent={CalendarFormActions}
                        basicLayoutComponent={CalendarFormBasicLayout}
                        visible={showForm}
                        onVisibilityChange={toggleForm}
                        messages={t('components.Calendar.AppointmentForm', { returnObjects: true })}
                    />

                    <DragDropProvider allowDrag={allowDrag} allowResize={allowResize} />
                </Scheduler>
                {showCreate && (
                    <CalendarCreateButton raised={createRaised} onClick={handleCreate} />
                )}
            </Box>
        </CalendarProvider>
    );
};

Calendar.propTypes = {
    calendarId: PropTypes.string.isRequired,
    appointments: PropTypes.arrayOf(PropTypes.shape({})),
    availableViews: PropTypes.arrayOf(PropTypes.oneOf([...Object.values(CALENDAR_VIEWS)])),
    initialDate: PropTypes.instanceOf(Date),
    initialView: PropTypes.oneOf([...Object.values(CALENDAR_VIEWS)]),
    showCreate: PropTypes.bool,
    createRaised: PropTypes.bool,
    allowDrag: PropTypes.func,
    allowResize: PropTypes.func,
    onDateChange: PropTypes.func,
    onViewChange: PropTypes.func,
    onSubmit: PropTypes.func,
    AppointmentComponent: PropTypes.elementType,
    FormComponent: PropTypes.elementType,
    ToolbarOptionsComponent: PropTypes.elementType,
};

Calendar.defaultProps = {
    appointments: [],
    availableViews: Object.values(CALENDAR_VIEWS),
    initialDate: new Date(),
    initialView: CALENDAR_VIEWS.WORKWEEK,
    showCreate: false,
    createRaised: false,
    allowDrag: undefined,
    allowResize: undefined,
    onDateChange: () => null,
    onViewChange: () => null,
    onSubmit: () => null,
    AppointmentComponent: null,
    FormComponent: null,
    ToolbarOptionsComponent: null,
};

export default memo(Calendar);
