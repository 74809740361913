import React from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@mui/styles';
import Box from '@mui/material/Box';
import classNames from 'classnames';

const useStyles = makeStyles({
    root: {
        display: 'grid',
    },
});

const GridDisplay = ({ children, columns, spacing, className, ...other }) => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <Box
            className={classNames(classes.root, className)}
            style={{ gridTemplateColumns: columns, gap: theme.spacing(spacing) }}
            {...other}
        >
            {children}
        </Box>
    );
};

GridDisplay.propTypes = {
    children: PropTypes.node,
    columns: PropTypes.string.isRequired,
    spacing: PropTypes.number,
    className: PropTypes.string,
};

GridDisplay.defaultProps = {
    children: null,
    spacing: 0,
    className: '',
};

export default GridDisplay;
