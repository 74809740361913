import React from 'react';
import * as PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import TableSortLabel from '@mui/material/TableSortLabel';
import MuiTableHead from '@mui/material/TableHead';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { TableOrderPropType } from './proptypes';

const checkOrderBy = (orderBy, key) => {
    const priority = orderBy.findIndex(([_orderBy]) => _orderBy === key);

    if (priority < 0) {
        return { active: false, order: null, priority: null };
    }

    const [, direction] = orderBy[priority];

    return { active: true, direction, priority };
};

const TableHead = ({ columns, withInlineActions, orderBy, onClick }) => {
    const handleClick = cellKey => {
        if (onClick) {
            onClick(cellKey);
        }
    };

    return (
        <MuiTableHead>
            <TableRow>
                {columns.map(column => {
                    const { active, direction, priority } = checkOrderBy(
                        orderBy,
                        column.orderBy || column.accessor || column.key
                    );
                    return (
                        <TableCell
                            key={column.key}
                            sortDirection={direction || false}
                            data-test-id={`th-${column.key}`}
                        >
                            {column.sortable === false ? (
                                <Typography variant="h5" component="span" color="primary">
                                    {column.label || ''}
                                </Typography>
                            ) : (
                                <TableSortLabel
                                    active={active}
                                    direction={direction || 'asc'}
                                    onClick={() =>
                                        handleClick(column.orderBy || column.accessor || column.key)
                                    }
                                    IconComponent={priority > 0 ? ArrowDropDownIcon : undefined}
                                >
                                    <Typography variant="h5" component="span" color="primary">
                                        {column.label || ''}
                                    </Typography>
                                </TableSortLabel>
                            )}
                        </TableCell>
                    );
                })}
                {withInlineActions && <TableCell>{null}</TableCell>}
            </TableRow>
        </MuiTableHead>
    );
};

TableHead.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            sortable: PropTypes.bool,
            label: PropTypes.string,
        })
    ).isRequired,
    withInlineActions: PropTypes.bool,
    orderBy: PropTypes.arrayOf(TableOrderPropType),
    onClick: PropTypes.func,
};

TableHead.defaultProps = {
    withInlineActions: false,
    orderBy: [],
    onClick: null,
};

export default TableHead;
