import * as Yup from 'yup';

export const taskStatusSchema = Yup.object().shape({
    taskStatusId: Yup.number().required('required'),
});

export const taskStatusSettingsSchema = Yup.object().shape({
    name: Yup.string().trim().required('required').max(255, 'string255MaxLength'),
    icon: Yup.string().nullable(),
    active: Yup.boolean(),
    order: Yup.number().required('required').typeError('numberMismatch'),
});
