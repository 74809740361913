import React from 'react';
import * as PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TextInput from '../form/formik/TextInput';
import { makeStyles } from '@mui/styles';
import IconSelect from '../form/formik/IconSelect';
import Checkbox from '../form/formik/Checkbox';

const useStyles = makeStyles(theme => ({
    inputRow: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
}));

const TaskStatusSettingsFormBody = ({ autoFocus }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <>
            <Grid container spacing={1}>
                <Grid item md={6} xs={12}>
                    <TextInput
                        name="name"
                        label={t('form.TaskStatusSettingsForm.name')}
                        className={classes.inputRow}
                        size="small"
                        autoFocus={autoFocus}
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <IconSelect name="icon" />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item md={6} xs={12}>
                    <TextInput
                        name="order"
                        label={t('form.TaskStatusSettingsForm.order')}
                        className={classes.inputRow}
                        size="small"
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <Checkbox
                        name="active"
                        label={t('form.TaskStatusSettingsForm.active')}
                        color="primary"
                    />
                </Grid>
            </Grid>
        </>
    );
};

TaskStatusSettingsFormBody.propTypes = {
    autoFocus: PropTypes.bool,
};

TaskStatusSettingsFormBody.defaultProps = {
    autoFocus: false,
};

export default TaskStatusSettingsFormBody;
