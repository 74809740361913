/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { ADDRESS_RESOURCE } from '../api/resources';
import { MODEL_CONTACT } from '../../components/utils/ModelClasses';

const insertAddress = (state, address) => {
    const { id, parent_type: parentType, parent_id: parentId } = address;

    const stale = state.byId[id];

    if (!stale) {
        state.allIds.push(id);
    }

    state.byId[id] = {
        ...address,
    };

    if (parentType === MODEL_CONTACT) {
        if (!state.byContactId[parentId]) {
            state.byContactId[parentId] = [];
        }

        if (!state.byContactId[parentId].includes(id)) {
            state.byContactId[parentId].push(id);
        }
    }
};

const addressSlice = createSlice({
    name: ADDRESS_RESOURCE,
    initialState: {
        byId: {},
        byContactId: {},
        allIds: [],
    },
    reducers: {
        indexFulfilled: {
            prepare: (payload, meta) => ({ payload, meta }),
            reducer: (state, action) => {
                action.payload.forEach(address => {
                    insertAddress(state, address);
                });
            },
        },
        showFulfilled: {
            prepare: (payload, meta) => ({ payload, meta }),
            reducer: (state, action) => {
                insertAddress(state, action.payload);
            },
        },
        storeFulfilled: (state, action) => {
            insertAddress(state, { ...action.payload, contactId: action.meta.params.contactId });
        },
        updateFulfilled: (state, action) => {
            insertAddress(state, action.payload);
        },
        destroyFulfilled: (state, action) => {
            const id = action.payload;

            const index = state.allIds.findIndex(_id => _id === id);
            if (index >= 0) {
                delete state.byId[id];
                state.allIds.splice(index, 1);
            }

            Object.keys(state.byContactId).forEach(key => {
                const contactIndex = state.byContactId[key].findIndex(_id => _id === id);

                if (contactIndex >= 0) {
                    state.byContactId[key].splice(contactIndex, 1);
                }
            });
        },
    },
});

export const { indexFulfilled, showFulfilled, storeFulfilled, updateFulfilled, destroyFulfilled } =
    addressSlice.actions;

export default addressSlice.reducer;
