import axios from 'axios';
import { isEqual } from 'lodash';
import { createClient } from 'webdav/web';
import logger from '../../logger';
import { showCard, updateCard } from '../cards/actions';
import { apiError } from './apiSlice';
import { encodePath } from './utils';

const getKumoConnectApi = (apiUser, api) => {
    let user = apiUser;
    const createErrorHandler =
        (action, ignore = [], throwError = true) =>
        error => {
            if (
                !(error.response && error.response.status && ignore.includes(error.response.status))
            ) {
                api.dispatch(
                    apiError({
                        status: !error.translate ? error.response && error.response.status : null,
                        message: error.message,
                        translate: error.translate || null,
                        action,
                        key: 'cloud',
                    })
                );
            }
            if (throwError) throw error;
        };

    const getKumoConnectBaseUrl = () =>
        user && user.kumo_connect && user.kumo_connect.ip && user.kumo_connect.port
            ? `http://${user.kumo_connect.ip}:${user.kumo_connect.port}/webdav`
            : null;

    let kumoConnectPollingId = null;
    let pollingCount = 0;
    const startKumoConnectPolling = kumoConnect => {
        if (kumoConnectPollingId !== null) return;

        kumoConnectPollingId = setInterval(() => {
            pollingCount++;
            logger.info('KumoConnectPolling', { pollingCount, maxPollingCount: 6 });
            if (pollingCount === 6) {
                // max. polling times is set to 6
                killKumoConnectPolling();
                logger.warn('KumoConnectPolling stopped: max. polling reached', {
                    pollingCount,
                    maxPollingCount: 6,
                });
                return;
            }
            kumoConnect.getKumoConnectStatus();
        }, 60000); // Run every 1 minute (60,000 milliseconds)
        logger.info('KumoConnectPolling started', { pollingCount, maxPollingCount: 6 });
    };

    const killKumoConnectPolling = () => {
        if (kumoConnectPollingId) {
            clearInterval(kumoConnectPollingId);
            kumoConnectPollingId = null;
            pollingCount = 0;
            logger.warn('KumoConnectPolling has been killed', { pollingCount, maxPollingCount: 6 });
        }
    };

    const timeouts = [];

    const kumoConnect = {
        _client: null,

        _getClient: () => {
            if (kumoConnect._client && isEqual(user, apiUser)) {
                return Promise.resolve(kumoConnect._client);
            }

            if (getKumoConnectBaseUrl()) {
                kumoConnect._client = createClient(getKumoConnectBaseUrl());
                return Promise.resolve(kumoConnect._client);
            }
            throw new Error(
                'No connection established with KumoConnect Server! Please check your configurations {IP, PORT}.'
            );
        },

        getKumoConnectStatus: () => {
            if (user && user.kumo_connect && user.kumo_connect.ip && user.kumo_connect.port)
                return axios
                    .get(`http://${user.kumo_connect.ip}:${user.kumo_connect.port}/status`, {
                        xsrfCookieName: null,
                        timeout: 5000,
                    })
                    .then(response => {
                        killKumoConnectPolling();
                        api.cloud._setKumoConnectStatus(response.data);
                        return response;
                    })
                    .catch(() => {
                        startKumoConnectPolling(kumoConnect);
                        api.cloud._setKumoConnectStatus(null);
                        return null;
                    });
            return null;
        },

        initKumoConnectStatus: params => {
            if (params && params.ip && params.port)
                return axios
                    .get(`http://${params.ip}:${params.port}/status`, {
                        xsrfCookieName: null,
                        timeout: 5000,
                    })
                    .then(response => {
                        killKumoConnectPolling();
                        // sync new updated 'user'
                        user = { ...apiUser, kumo_connect: { ip: params.ip, port: params.port } };
                        api.cloud._setKumoConnectStatus(response.data);
                        return Promise.resolve(response);
                    })
                    .catch(err => {
                        startKumoConnectPolling(kumoConnect);
                        // sync new updated 'user'
                        user = { ...apiUser, kumo_connect: { ip: params.ip, port: params.port } };
                        api.cloud._setKumoConnectStatus(null);
                        return Promise.reject(err);
                    });
            api.cloud._setKumoConnectStatus(null);
            // sync new updated 'user'
            user = { ...apiUser, kumo_connect: params };
            return null;
        },

        openKumoConnectFile: path =>
            axios
                .get(
                    `http://${user.kumo_connect.ip}:${user.kumo_connect.port}/explorer${encodePath(
                        path
                    )}`,
                    { xsrfCookieName: null }
                )
                .then(() => true)
                .catch(error => {
                    api.dispatch(
                        apiError({
                            status: (error.response && error.response.status) || null,
                            message: error.message,
                            key: 'cloud',
                        })
                    );
                    return false;
                }),

        fetchCard: card => {
            api.dispatch(showCard({ id: card.id })).then(res => kumoConnect.checkCardDir(res.data));
        },

        checkCardDir: async card => {
            if (card.directory.blocked > 0) {
                kumoConnect.clearAllTimeouts();
                const timeoutId = setTimeout(
                    kumoConnect.fetchCard(card),
                    Math.min(card.directory.blocked, 10) * 1000
                );
                timeouts.push(timeoutId);
            } else {
                kumoConnect.clearAllTimeouts();
                if (card.directory.move) {
                    const dirExists = await kumoConnect.dirExists(card.directory.move);
                    if (dirExists) {
                        kumoConnect.unblockCard(card, { current: card.directory.move });
                    } else {
                        if (await kumoConnect.blockCard(card)) {
                            const dirMoved = await kumoConnect.dirMove(
                                card.directory.current,
                                card.directory.move
                            );
                            if (dirMoved)
                                kumoConnect.unblockCard(card, { current: card.directory.move });
                            else {
                                kumoConnect.clearAllTimeouts();
                                const timeoutId = setTimeout(
                                    kumoConnect.fetchCard(card),
                                    card.directory.blocked * 1000
                                );
                                timeouts.push(timeoutId);
                            }
                        } else {
                            kumoConnect.clearAllTimeouts();
                            const timeoutId = setTimeout(
                                kumoConnect.fetchCard(card),
                                Math.min(card.directory.blocked, 10) * 1000
                            );
                            timeouts.push(timeoutId);
                        }
                    }
                } else {
                    const dirExists = await kumoConnect.dirExists(card.directory.current);
                    if (!dirExists) {
                        if (await kumoConnect.blockCard(card)) {
                            const dirs = [...card.directory.subdirs];
                            dirs.push(card.directory.current);
                            const created = await kumoConnect.dirCreate(dirs);
                            if (created) kumoConnect.unblockCard(card);
                            else {
                                kumoConnect.clearAllTimeouts();
                                const timeoutId = setTimeout(
                                    kumoConnect.fetchCard(card),
                                    card.directory.blocked * 1000
                                );
                                timeouts.push(timeoutId);
                            }
                        } else {
                            kumoConnect.clearAllTimeouts();
                            const timeoutId = setTimeout(
                                kumoConnect.fetchCard(card),
                                Math.min(card.directory.blocked, 10) * 1000
                            );
                            timeouts.push(timeoutId);
                        }
                    }
                }
            }
        },

        dirExists: dir =>
            kumoConnect
                ._getClient()
                .then(client =>
                    client
                        .exists(dir)
                        .then(exists => {
                            if (exists) {
                                return client
                                    .stat(dir)
                                    .then(stat => {
                                        if (stat.type === 'directory') return true;
                                        else {
                                            logger.error(
                                                `Path ${dir} exists, but it is not a directory`
                                            );
                                            return false;
                                        }
                                    })
                                    .catch(err => {
                                        throw new Error(
                                            `Error checking directory existence: ${dir}. \n ${err.message}`
                                        );
                                    });
                            } else {
                                return false;
                            }
                        })
                        .catch(err => {
                            throw new Error(
                                `Error checking directory existence: ${dir}. \n ${err.message}`
                            );
                        })
                )
                .catch(err => {
                    throw new Error(
                        `Error checking directory existence: ${dir}. \n ${err.message}`
                    );
                }),

        dirMove: (source, dest) =>
            axios
                .request({
                    url: `${getKumoConnectBaseUrl()}/${source}`,
                    method: 'MOVE',
                    xsrfCookieName: null,
                    headers: {
                        Destination: `${dest}`,
                    },
                })
                .then(() => true)
                .catch(error => {
                    logger.error(`Error moving directory ${source} to ${dest}`);
                    createErrorHandler(
                        'dirMove',
                        [],
                        false
                    )({
                        ...error,
                        translate: 'errors.kumoConnect.dirMove',
                    });
                    return false;
                }),

        dirCreate: dirs =>
            axios
                .request({
                    url: `${getKumoConnectBaseUrl()}`,
                    method: 'MKCOL',
                    xsrfCookieName: null,
                    params: {
                        path: dirs,
                    },
                })
                .then(() => true)
                .catch(error => {
                    logger.error(`Error while creating directory: ${dirs}`);
                    createErrorHandler(
                        'dirCreate',
                        [],
                        false
                    )({
                        ...error,
                        translate: 'errors.kumoConnect.dirCreate',
                    });
                    return false;
                }),

        blockCard: card =>
            api
                .dispatch(updateCard({ id: card.id, blocked: true }))
                .then(res => {
                    card.directory = res.data.directory;
                    return card;
                })
                .catch(err => {
                    card.directory = { ...card.directory, ...err.message.data };
                    return null;
                }),

        unblockCard: (card, directory = {}) =>
            api
                .dispatch(
                    updateCard({
                        id: card.id,
                        blocked: false,
                        directory: { move: null, ...directory },
                    })
                )
                .then(res => {
                    card.directory = res.data.directory;
                    return card;
                })
                .catch(() => null),

        clearAllTimeouts: () => timeouts.forEach(timeoutId => clearTimeout(timeoutId)),
    };

    return kumoConnect;
};

export const attachKumoConnect = api => {
    const kumoConnectBinder = data => {
        api.kumoConnect = getKumoConnectApi(data, api);
        return data;
    };

    api.onAuthenticate(kumoConnectBinder);
    api.setGetAuthenticatedHandler(kumoConnectBinder);
    api.onLogout(kumoConnectBinder);

    kumoConnectBinder();
};
