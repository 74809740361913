import React, { useEffect, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import AddressFormBody from './AddressFormBody';
import Popup from '../popups/Popup';
import TextField from '../form/base/TextField';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import { getFullAddressText } from '../../modules/addresses/utils';
import { RefPropType } from '../../modules/proptypes';

const AddressInput = ({ namePrefix, InputElement, popupStateRef, ...other }) => {
    const { t } = useTranslation();
    const popupState = useRef();
    const [{ value }, , { setValue }] = useField(namePrefix);

    const handleClear = () => {
        setValue(null);
    };

    useEffect(() => {
        if (value?.address1) {
            const full = getFullAddressText(value);

            if (value.full !== full) {
                setValue({ ...value, full });
            }
        }

        if (
            value &&
            (!value.address1 || value.address1 === '') &&
            value.address2 === '' &&
            value.zip === '' &&
            value.city === ''
        ) {
            handleClear();
        }
    }, [value]);

    return (
        <Popup
            popupId={namePrefix}
            popupStateRef={popupStateRef || popupState}
            popupChildren={<AddressFormBody namePrefix={namePrefix} />}
            style={{ maxWidth: 420 }}
            {...other}
        >
            {InputElement ? (
                <InputElement onClear={handleClear} />
            ) : (
                <TextField label={t('components.Address.title')} value={value.full} />
            )}
        </Popup>
    );
};

AddressInput.propTypes = {
    namePrefix: PropTypes.string,
    InputElement: PropTypes.elementType,
    popupStateRef: RefPropType,
};

AddressInput.defaultProps = {
    namePrefix: null,
    InputElement: null,
    popupStateRef: null,
};

export default AddressInput;
