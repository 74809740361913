import React from 'react';
import * as PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TextInput from '../form/formik/TextInput';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    inputRow: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
}));

const PersonFunctionFormBody = ({ autoFocus }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <>
            <Grid container spacing={1}>
                <Grid item md={6} xs={12}>
                    <TextInput
                        name="name"
                        label={t('form.PersonFunctionForm.name')}
                        className={classes.inputRow}
                        size="small"
                        autoFocus={autoFocus}
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <TextInput
                        name="id_ext"
                        label={t('form.PersonFunctionForm.id_ext')}
                        className={classes.inputRow}
                        size="small"
                    />
                </Grid>
            </Grid>
        </>
    );
};

PersonFunctionFormBody.propTypes = {
    autoFocus: PropTypes.bool,
};

PersonFunctionFormBody.defaultProps = {
    autoFocus: false,
};

export default PersonFunctionFormBody;
