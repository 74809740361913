import React from 'react';
import * as PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TextInput from '../form/formik/TextInput';
import { makeStyles } from '@mui/styles';
import AppointmentTypeOverlapSelect from './AppointmentTypeOverlapSelect';
import ColorPicker from '../form/formik/ColorPicker';
import JsonInput from '../form/formik/JsonInput';

const useStyles = makeStyles(theme => ({
    inputRow: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
}));

const AppointmentTypeFormBody = ({ autoFocus }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <>
            <Grid container spacing={1}>
                <Grid item md={6} xs={12}>
                    <TextInput
                        name="name"
                        label={t('form.AppointmentTypeForm.name')}
                        className={classes.inputRow}
                        size="small"
                        autoFocus={autoFocus}
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <TextInput
                        name="type"
                        label={t('form.AppointmentTypeForm.type')}
                        className={classes.inputRow}
                        size="small"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item md={6} xs={12}>
                    <AppointmentTypeOverlapSelect
                        name="overlap"
                        className={classes.inputRow}
                        size="small"
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <TextInput
                        name="component"
                        label={t('form.AppointmentTypeForm.component')}
                        className={classes.inputRow}
                        size="small"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item md={6} xs={12}>
                    <ColorPicker
                        name="color"
                        label={t('form.AppointmentTypeForm.color')}
                        className={classes.inputRow}
                        size="small"
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <TextInput
                        name="order"
                        label={t('form.AppointmentTypeForm.order')}
                        className={classes.inputRow}
                        size="small"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <JsonInput
                    name="resource_config"
                    label={`${t('form.AppointmentTypeForm.resourceConfig')} :`}
                    maxRows={20}
                />
            </Grid>
        </>
    );
};

AppointmentTypeFormBody.propTypes = {
    autoFocus: PropTypes.bool,
};

AppointmentTypeFormBody.defaultProps = {
    autoFocus: false,
};

export default AppointmentTypeFormBody;
