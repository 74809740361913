import React from 'react';
import * as PropTypes from 'prop-types';
import Confirmation from '../form/Confirmation';
import Box from '@mui/material/Box';
import LoadingIcon from '../loading/LoadingIcon';
import IconButton from '../form/base/IconButton';
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import LoadingButton from '../loading/LoadingButton';
import Can from '../../modules/abilities/Can';
import { READ } from '../../modules/abilities/actions';

const useStyles = makeStyles({
    undo: {
        position: 'relative',
        opacity: 0.5,
    },
});

const CompletedButton = ({
    name,
    isCompleted,
    subject,
    loading,
    variant,
    onCompleted,
    onUndo,
    disabled,
    ...other
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Can I={READ} this={subject} field={name}>
            <Grid container spacing={1} alignItems="center" justifyContent="center">
                <Grid data-test-id="complete-btn" item>
                    <LoadingButton
                        onClick={onCompleted}
                        disabled={isCompleted || disabled}
                        variant={variant}
                        color="primary"
                        isLoading={!isCompleted && loading}
                        size="small"
                    >
                        {t('components.TaskInfos.completed')}
                    </LoadingButton>
                </Grid>
                {isCompleted && (
                    <Grid item>
                        <Confirmation
                            popupId="UndoCompleted"
                            label={t(`components.TaskInfos.undo`)}
                            onConfirm={onUndo}
                        >
                            <Box data-test-id="uncomplete-btn" className={classes.undo}>
                                <LoadingIcon isLoading={loading} size="small">
                                    <IconButton size="small" disabled={disabled} {...other}>
                                        <UndoOutlinedIcon fontSize="small" />
                                    </IconButton>
                                </LoadingIcon>
                            </Box>
                        </Confirmation>
                    </Grid>
                )}
            </Grid>
        </Can>
    );
};

CompletedButton.propTypes = {
    isCompleted: PropTypes.bool,
    subject: PropTypes.any,
    loading: PropTypes.bool,
    variant: PropTypes.string,
    onCompleted: PropTypes.func.isRequired,
    onUndo: PropTypes.func.isRequired,
};

CompletedButton.defaultProps = {
    isCompleted: false,
    subject: null,
    loading: false,
    variant: 'contained',
};

export default CompletedButton;
