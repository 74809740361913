import { createSelector } from '@reduxjs/toolkit';
import { isObject } from 'lodash';
import {
    asInitializedKey,
    asStateKey,
    asStateSelectorSuffix,
    pluralize,
    uppercaseFirst,
} from './utils';

/* always use the same array to prevent unnecessary rendering */
export const EMPTY_LIST = [];
export const EMPTY_OBJECT = {};

export const selectResourceItemsById = (state, resource) => state[resource].byId;

export const selectResourceItemById = (state, resource, itemId) =>
    selectResourceItemsById(state, resource)[itemId];

export const selectResourceItemsByIds = (state, resource, itemIds) =>
    itemIds ? itemIds.map(itemId => selectResourceItemsById(state, resource)[itemId]) : EMPTY_LIST;

const makeAllResourceItemsSelector = resource =>
    createSelector(
        state => selectResourceItemsById(state, resource),
        itemsById => Object.values(itemsById)
    );

export const makeResourceItemsByIdsSelector = resource =>
    createSelector(
        (_, itemIds) => itemIds,
        state => selectResourceItemsById(state, resource),
        (itemIds, itemsById) =>
            itemIds ? itemIds.map(itemId => itemsById[itemId]).filter(item => !!item) : EMPTY_LIST
    );

export const selectResourceIdsByKey = (state, resource, keyId, byKey) =>
    state[resource][asStateKey(byKey)][keyId];

export const makeResourceItemsByKeySelector = (resource, byKey) =>
    createSelector(
        (state, keyId) => selectResourceIdsByKey(state, resource, keyId, byKey),
        state => selectResourceItemsById(state, resource),
        (itemIds, itemsById) =>
            itemIds ? itemIds.map(itemId => itemsById[itemId]).filter(item => !!item) : EMPTY_LIST
    );

export const selectResourceInitializedByKey = (state, resource, keyId, byKey) => {
    const initialize = state[resource][asInitializedKey(byKey)][keyId];
    return !!(initialize && initialize.done);
};
export const selectResourceLoadingByKey = (state, resource, keyId, byKey) => {
    const initialize = state[resource][asInitializedKey(byKey)][keyId];
    return !!(initialize && initialize.loading);
};

export const selectResourcesInitialized = (state, resource) => state[resource].initialize.done;
export const selectResourcesLoading = (state, resource) => state[resource].initialize.loading;
export const selectResourceInitialized = (state, resource, itemId) =>
    selectResourceInitializedByKey(state, resource, itemId, 'id');
export const selectResourceLoading = (state, resource, itemId) =>
    selectResourceLoadingByKey(state, resource, itemId, 'id');
export const selectResourceCurrentPage = (state, resource, listId = null) =>
    listId ? state[resource].page[listId]?.current : state[resource].page?.current;
export const selectResourceObtainablePages = (state, resource, listId = null) =>
    listId ? state[resource].page[listId]?.obtainable : state[resource].page?.obtainable;

export const selectRelatedData = (state, withConfig, data) => {
    if (!withConfig || !data) {
        return null;
    }

    const entries = Array.isArray(withConfig)
        ? withConfig.map(key => [key, uppercaseFirst(key)])
        : Object.entries(withConfig);

    return entries.reduce((carry, [withKey, resourceConfig]) => {
        // eslint-disable-next-line no-param-reassign
        carry[withKey] = withKey.split('.').reduce((parent, key) => {
            /* crawl through pivot element */
            const itemId = parent[`${key}Id`];

            const resource = isObject(resourceConfig) ? resourceConfig[key] : resourceConfig;
            return selectResourceItemById(state, resource, itemId);
        }, data);
        return carry;
    }, {});
};
export const makeRelatedDataSelector = withConfig =>
    createSelector(
        state => state,
        (_, data) => data,
        (state, data) => selectRelatedData(state, withConfig, data)
    );

const extraSelectors = (name, resource, byKeys) => {
    const selectors = {
        /* selectResourcesById */
        [`select${pluralize(name)}ById`]: state => selectResourceItemsById(state, resource),

        /* selectResourceById */
        [`select${name}ById`]: (state, itemId) => selectResourceItemById(state, resource, itemId),
        /* selectResourcesByIds */
        [`select${pluralize(name)}ByIds`]: (state, itemIds) =>
            selectResourceItemsByIds(state, resource, itemIds),
        /* makeResourcesByIdsSelector */
        [`make${pluralize(name)}ByIdsSelector`]: () => makeResourceItemsByIdsSelector(resource),

        /* selectAllResourceIds */
        [`selectAll${name}Ids`]: state => state[resource].allIds,

        /* selectAllResources */
        [`selectAll${pluralize(name)}`]: makeAllResourceItemsSelector(resource),

        /* selectResourcesLoading */
        [`select${pluralize(name)}Loading`]: state => selectResourcesLoading(state, resource),
        /* selectResourcesInitialized */
        [`select${pluralize(name)}Initialized`]: state =>
            selectResourcesInitialized(state, resource),
        /* selectResourceLoading */
        [`select${name}Loading`]: (state, itemId) => selectResourceLoading(state, resource, itemId),
        /* selectResourceInitialized */
        [`select${name}Initialized`]: (state, itemId) =>
            selectResourceInitialized(state, resource, itemId),
        [`select${name}CurrentPage`]: (state, listId) =>
            selectResourceCurrentPage(state, resource, listId),
        [`select${name}ObtainablePages`]: (state, listId) =>
            selectResourceObtainablePages(state, resource, listId),
    };

    if (byKeys) {
        (Array.isArray(byKeys) ? byKeys : [byKeys]).forEach(byKey => {
            /* selectResourceIdsByKeys */
            selectors[`select${name}Ids${pluralize(asStateSelectorSuffix(byKey))}`] = state =>
                state[resource][asStateKey(byKey)];
            /* selectResourceIdsByKey */
            selectors[`select${name}Ids${asStateSelectorSuffix(byKey)}`] = (state, keyId) =>
                selectResourceIdsByKey(state, resource, keyId, byKey);
            /* makeResourcesByKeySelector */
            selectors[`make${pluralize(name)}${asStateSelectorSuffix(byKey)}Selector`] = () =>
                makeResourceItemsByKeySelector(resource, byKey);

            /* selectResourceLoadingByKey */
            selectors[`select${name}Loading${asStateSelectorSuffix(byKey)}`] = (state, keyId) =>
                selectResourceLoadingByKey(state, resource, keyId, byKey);

            /* selectResourceInitializedByKey */
            selectors[`select${name}Initialized${asStateSelectorSuffix(byKey)}`] = (state, keyId) =>
                selectResourceInitializedByKey(state, resource, keyId, byKey);
        });
    }

    return selectors;
};

export default extraSelectors;
