import { PERSON_RESOURCE } from '../api/resources';

export const indexPersons = params => (dispatch, getState, api) =>
    api[PERSON_RESOURCE].index(params);

export const storePerson = person => (dispatch, getState, api) =>
    api[PERSON_RESOURCE].store(person);

export const updatePerson = person => (dispatch, getState, api) =>
    api[PERSON_RESOURCE].update(person);

export const showPerson = person => (dispatch, getState, api) => api[PERSON_RESOURCE].show(person);

export const destroyPerson = params => (dispatch, getState, api) =>
    api[PERSON_RESOURCE].destroy(params);

export const autocompletePersons = params => (dispatch, getState, api) =>
    api[PERSON_RESOURCE].autocomplete(params);
