import React from 'react';
import * as PropTypes from 'prop-types';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import IconButton from '../form/base/IconButton';
import { CREATE } from '../../modules/abilities/actions';
import { SubjectPropType } from '../../modules/abilities/proptypes';

const CreateIconButton = ({ onClick, size, color, disabled, subject }) => (
    <IconButton
        onClick={onClick}
        size={size}
        color={color}
        disabled={disabled}
        subject={subject}
        action={CREATE}
    >
        <AddCircleIcon />
    </IconButton>
);

CreateIconButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    size: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    subject: SubjectPropType,
};

CreateIconButton.defaultProps = {
    size: null,
    color: 'default',
    disabled: false,
    subject: null,
};

export default CreateIconButton;
