import React from 'react';
import * as PropTypes from 'prop-types';
import LockIcon from '@mui/icons-material/Lock';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Date from './Date';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        wordBreak: 'keep-all',
    },
    locked: {
        marginLeft: theme.spacing(1),
        height: '1rem',
    },
}));

const DueDate = ({ dueAt, hasTime, locked, dateType, onlyDate }) => {
    const classes = useStyles();

    return (
        <Box className={classes.root} element="div">
            <Date date={dueAt} showTime={hasTime} dateType={dateType} onlyDate={onlyDate} />
            {locked && <LockIcon fontSize="small" className={classes.locked} />}
        </Box>
    );
};

DueDate.propTypes = {
    dueAt: PropTypes.string.isRequired,
    hasTime: PropTypes.bool,
    locked: PropTypes.bool,
    dateType: PropTypes.string,
    onlyDate: PropTypes.bool,
};

DueDate.defaultProps = {
    hasTime: null,
    locked: false,
    dateType: null,
    onlyDate: false,
};

export default DueDate;
