import { PERSONAL_ACCESS_TOKEN_RESOURCE } from '../api/resources';
import { createResourceSlice } from '../redux/resource/createResourceSlice';

const personalAccessTokenSlice = createResourceSlice({
    resource: PERSONAL_ACCESS_TOKEN_RESOURCE,
    initialState: { lastCreatedUrl: null },
    reducers: {
        resetLastCreatedPersonalAccessTokenUrl: (state, action) => {
            state.lastCreatedUrl = null;
        },
        setLastCreatedPersonalAccessTokenUrl: (state, action) => {
            state.lastCreatedUrl = action.payload;
        },
    },
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    destroyFulfilled,

    indexPersonalAccessTokens,
    showPersonalAccessToken,
    storePersonalAccessToken,
    destroyPersonalAccessToken,
    resetLastCreatedPersonalAccessTokenUrl,
    setLastCreatedPersonalAccessTokenUrl,
} = personalAccessTokenSlice.actions;

export const {
    selectPersonalAccessTokenById,
    makePersonalAccessTokensByIdsSelector,
    selectAllPersonalAccessTokenIds,
    selectAllPersonalAccessTokens,
    selectPersonalAccessTokensLoading,
    selectPersonalAccessTokensInitialized,
    selectPersonalAccessTokenLoading,
    selectPersonalAccessTokenInitialized,
} = personalAccessTokenSlice.selectors;

export const selectLastCreatedPersonalAccessTokenUrl = state =>
    state[PERSONAL_ACCESS_TOKEN_RESOURCE].lastCreatedUrl;

export const { usePersonalAccessTokens, usePersonalAccessToken } = personalAccessTokenSlice.hooks;

export default personalAccessTokenSlice.reducer;
