import { createSlice } from '@reduxjs/toolkit';
import { USER_RESOURCE } from '../api/resources';

const insertUser = (state, user) => {
    const stale = state.byId[user.id];

    if (!stale) {
        state.allIds.push(user.id);
    }

    const prepped = { ...user, personId: user.person && user.person.id };
    delete prepped.person;

    // eslint-disable-next-line no-param-reassign
    state.byId[user.id] = prepped;
};

const userSlice = createSlice({
    name: USER_RESOURCE,
    initialState: {
        byId: {},
        allIds: [],
    },
    reducers: {
        indexFulfilled: (state, action) => {
            action.payload.forEach(user => {
                insertUser(state, user);
            });
        },

        showFulfilled: (state, action) => {
            insertUser(state, action.payload);
        },

        storeFulfilled: (state, action) => {
            insertUser(state, action.payload);
        },

        updateFulfilled: (state, action) => {
            insertUser(state, action.payload);
        },

        destroyFulfilled: (state, action) => {
            const id = action.payload;

            const index = state.allIds.findIndex(_id => _id === id);
            if (index >= 0) {
                delete state.byId[id];
                state.allIds.splice(index, 1);
            }
        },
    },
});

export const {
    indexPending,
    indexFulfilled,
    indexError,
    showFulfilled,
    updateFulfilled,
    destroyFulfilled,
} = userSlice.actions;

export default userSlice.reducer;
