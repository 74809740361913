import * as Yup from 'yup';

export const taskTypeSettingsSchema = Yup.object().shape({
    name: Yup.string().trim().required('required'),
    is_order_related: Yup.boolean().required('required'),
    is_external: Yup.boolean().required('required'),
    is_working_time: Yup.boolean().required('required'),
    is_active: Yup.boolean().required('required'),
    order: Yup.number().required('required').typeError('numberMismatch'),
});
