import React from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import AddIcon from '@mui/icons-material/Add';
import classNames from 'classnames';

const ICONS = {
    PRIMARY: 'pri',
    SECONDARY: 'sec',
};

export const ICON_POSITION = {
    CENTER_RIGHT: 'cr',
    CENTER_LEFT: 'cl',
};

const getStyleKey = (icon, position) => `${icon}.${position}`;

const useStyles = makeStyles({
    root: {
        position: 'relative',
        display: 'flex',
        height: 24,
        width: 24,
        alignItems: 'center',
        justifyContent: 'center',
    },
    primary: {
        position: 'absolute',
    },
    secondary: {
        position: 'absolute',
        gridArea: 1,
        fontSize: 16,
    },
    [getStyleKey(ICONS.PRIMARY, ICON_POSITION.CENTER_RIGHT)]: {
        left: -4,
    },
    [getStyleKey(ICONS.PRIMARY, ICON_POSITION.CENTER_LEFT)]: {
        right: -4,
    },
    [getStyleKey(ICONS.SECONDARY, ICON_POSITION.CENTER_RIGHT)]: {
        right: -4,
    },
    [getStyleKey(ICONS.SECONDARY, ICON_POSITION.CENTER_LEFT)]: {
        left: -4,
    },
});

const IconPair = ({ PrimaryIcon, SecondaryIcon, position }) => {
    const classes = useStyles();

    return (
        <Box style={{ display: 'inline-block' }}>
            <Box className={classes.root}>
                <PrimaryIcon
                    className={classNames([
                        classes.primary,
                        classes[getStyleKey(ICONS.PRIMARY, position)],
                    ])}
                />
                <SecondaryIcon
                    className={classNames([
                        classes.secondary,
                        classes[getStyleKey(ICONS.SECONDARY, position)],
                    ])}
                />
            </Box>
        </Box>
    );
};

IconPair.propTypes = {
    PrimaryIcon: PropTypes.elementType,
    SecondaryIcon: PropTypes.elementType,
    position: PropTypes.oneOf(Object.values(ICON_POSITION)),
};

IconPair.defaultProps = {
    PrimaryIcon: ImageIcon,
    SecondaryIcon: AddIcon,
    position: ICON_POSITION.CENTER_RIGHT,
};

export default IconPair;
