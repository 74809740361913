import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableContainer } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { selectContactablesByContactId } from '../../modules/contactables/selectors';
import ContactableLinkCardHead from './ContactableLinkCardHead';
import { IdPropType } from '../../modules/proptypes';
import LinkedCardsRow from './LinkedCardsRow';

const useStyles = makeStyles({
    table: {
        width: '100%',
    },
});

const LinkedCards = ({ contactId }) => {
    const contactables = useSelector(state => selectContactablesByContactId(state, contactId));
    const classes = useStyles();
    const { t } = useTranslation();

    const linkedCards = useMemo(() => {
        if (!contactables) {
            return {};
        }

        return contactables.reduce((carry, contactable) => {
            contactable.card_ids.forEach(cardId => {
                if (!carry[cardId]) {
                    carry[cardId] = [];
                }
                if (
                    contactable.contact_type_id &&
                    !carry[cardId].includes(contactable.contact_type_id)
                ) {
                    carry[cardId].push(contactable.contact_type_id);
                }
            });
            return carry;
        }, {});
    }, [contactables]);

    return (
        <TableContainer>
            <Table className={classes.table} aria-label={t('components.LinkContacts.tableName')}>
                <ContactableLinkCardHead />
                <TableBody>
                    {Object.entries(linkedCards).map(([cardId, contactTypeIds]) => (
                        <LinkedCardsRow
                            key={cardId}
                            cardId={Number.parseInt(cardId, 10)}
                            contactTypeIds={contactTypeIds}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

LinkedCards.propTypes = {
    contactId: IdPropType,
};

LinkedCards.defaultProps = {
    contactId: null,
};

export default LinkedCards;
