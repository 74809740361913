/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { asInitializedKey, asStateKey, toSnakeCase } from './utils';
import extraActions from './actions';
import extraSelectors from './selectors';
import extraHooks from './hooks';

const EMPTY_PAGES = { obtainable: null, current: 0 };

const initializeItem = (state, byKey, keyId, loading = undefined, done = undefined) => {
    const initializeKey = asInitializedKey(byKey);

    if (!state[initializeKey][keyId]) {
        state[initializeKey][keyId] = { loading: loading || false, done: done || false };
    } else {
        if (loading !== undefined) {
            state[initializeKey][keyId].loading = loading;
        }
        if (done !== undefined) {
            state[initializeKey][keyId].done = done;
        }
    }
};

export const initializeKey = (state, action, loading = undefined, done = undefined) => {
    if (action.meta && action.meta.initialize) {
        const { byKey, keyId } = action.meta.initialize;

        if (byKey !== 'id') {
            initializeItem(state, byKey, keyId, loading, done);
        }
    }
};

export const initializeId = (state, itemId, loading = undefined, done = undefined) => {
    initializeItem(state, 'id', itemId, loading, done);
};

const insertItem = (state, item, normalize, byKeys, onInsert) => {
    const stale = state.byId[item.id];

    if (!stale) {
        state.allIds.push(item.id);

        if (byKeys) {
            (Array.isArray(byKeys) ? byKeys : [byKeys]).forEach(byKey => {
                let keys = item[toSnakeCase(byKey)];
                const keyState = state[asStateKey(byKey)];

                if (!Array.isArray(keys)) {
                    keys = [keys];
                }

                keys.forEach(key => {
                    if (!keyState[key]) {
                        keyState[key] = [];
                    }

                    keyState[key].push(item.id);
                });
            });
        }
    }

    const normalized = normalize(item);

    state.byId[item.id] = normalized;

    if (onInsert) {
        onInsert(state, item, normalized);
    }
};

const removeItem = (state, removeId, byKeys, onRemove) => {
    const index = state.allIds.findIndex(id => id === removeId);
    if (index >= 0) {
        if (onRemove) {
            onRemove(state, removeId);
        }

        delete state.byId[removeId];
        state.allIds.splice(index, 1);

        if (byKeys) {
            (Array.isArray(byKeys) ? byKeys : [byKeys]).forEach(byKey => {
                Object.values(state[asStateKey(byKey)]).forEach(ids => {
                    const _index = ids.findIndex(id => id === removeId);
                    if (_index >= 0) {
                        ids.splice(_index, 1);
                    }
                });
            });
        }
    }
};

const initializePageMeta = (state, action) => {
    const listId = action?.meta?.listId;

    if ((listId && !state.page[listId]) || state.page[listId]?.obtainable?.length === 0) {
        Object.assign(state.page, { ...state.page, [listId]: EMPTY_PAGES });
    } else if (state.page?.obtainable?.length === 0) {
        Object.assign(state.page, { ...state.page, EMPTY_PAGES });
    }
};

const updatePageMeta = (state, action) => {
    const currentPage = action?.meta?.current_page || 1;

    const listId = action.meta?.listId;

    if (!(listId ? state.page[listId] : state.page).obtainable) {
        if (action?.meta?.last_page) {
            const lastPage = action?.meta?.last_page;
            const obtainablePages = Array.from(
                { length: lastPage - currentPage },
                (_, i) => i + currentPage
            );
            Object.assign(listId ? state.page[listId] : state.page, {
                current: currentPage,
                obtainable: obtainablePages,
            });
        } else {
            Object.assign(listId ? state.page[listId] : state.page, {
                current: currentPage,
                obtainable: [],
            });
        }
    } else if (Array.isArray(state.page.obtainable)) {
        if ((listId ? state.page[listId] : state.page).obtainable.includes(currentPage)) {
            const obtainablePages = (listId ? state.page[listId] : state.page).obtainable.filter(
                page => page !== currentPage
            );

            Object.assign(listId ? state.page[listId] : state.page, {
                current: currentPage,
                obtainable: obtainablePages,
            });
        }
    }
};

const completePageMeta = (state, action, page = 1, isError = false) => {
    if (!isError || action.meta?.obtainableFinishedOnError) {
        const listId = action.meta?.listId;
        const pagesShown = { current: page, obtainable: [] };

        if (listId) {
            Object.assign(state.page, { ...state.page, [listId]: pagesShown });
        } else {
            Object.assign(state.page, { ...state.page, ...pagesShown });
        }
    }
};

export const getPreppedInitialState = (initialState = {}, byKeys = null) => {
    const prepped = {
        byId: {},
        allIds: [],

        /**
         * Used if all items are loaded at once (by using .index)
         */
        initialize: { loading: false, done: false },

        /**
         * Used for the current requested pagination
         * important if pagination is not user-driven
         */
        page: EMPTY_PAGES,

        /**
         * Used if items are loaded individually (by using .show)
         */
        [asInitializedKey('id')]: {},
        ...initialState,
    };

    if (byKeys) {
        (Array.isArray(byKeys) ? byKeys : [byKeys]).forEach(byKey => {
            const key = asStateKey(byKey);
            if (!prepped[key]) {
                prepped[key] = {};
            }

            /**
             * Used if items are loaded via key (by using .search or .index with search parameters)
             */
            const initializedKey = asInitializedKey(byKey);
            if (!prepped[initializedKey]) {
                prepped[initializedKey] = {};
            }
        });
    }

    return prepped;
};

export const createResourceSlice = ({
    name: nameOverride,
    resource,
    initialState = {},
    byKey: byKeys = [],
    reducers = [],
    extraReducers = [],
    normalize = item => item,
    onInsert = null,
    onRemove = null,
}) => {
    const name = nameOverride || resource;

    const preppedInitialState = getPreppedInitialState(initialState, byKeys);

    const slice = createSlice({
        name: resource,
        initialState: preppedInitialState,
        reducers: {
            indexPending: (state, action) => {
                state.initialize.loading = true;

                initializePageMeta(state, action);

                initializeKey(state, action, true);
            },
            indexFulfilled: (state, action) => {
                updatePageMeta(state, action);

                if (!action?.meta?.noStore) {
                    action.payload.forEach(item => {
                        insertItem(state, item, normalize, byKeys, onInsert);
                        initializeId(state, item.id, false, true);
                    });

                    initializeKey(state, action, false, true);
                }

                state.initialize.loading = false;
                state.initialize.done = true;
            },
            indexError: (state, action) => {
                state.initialize.loading = false;
                state.initialize.done = true;

                initializeKey(state, action, false, true);
            },

            searchPending: (state, action) => {
                initializePageMeta(state, action);
                initializeKey(state, action, true);
            },
            searchFulfilled: (state, action) => {
                updatePageMeta(state, action);

                action.payload.forEach(item => {
                    insertItem(state, item, normalize, byKeys, onInsert);
                    initializeId(state, item.id, false, true);
                });

                initializeKey(state, action, false, true);
            },

            groupNestedListFulfilled: (state, action) => {
                action.payload.forEach(item => {
                    insertItem(state, item, normalize, byKeys, onInsert);
                    initializeId(state, item.id, false, true);
                });

                initializeKey(state, action, false, true);
            },

            showPending: (state, action) => {
                initializeId(state, action.meta.params.id, true);
                initializePageMeta(state, action);
                initializeKey(state, action, true);
            },
            showFulfilled: (state, action) => {
                completePageMeta(state, action);

                insertItem(state, action.payload, normalize, byKeys, onInsert);

                initializeId(state, action.payload.id, false, true);
                initializeKey(state, action, false, true);
            },
            showError: (state, action) => {
                completePageMeta(state, action, 1, true);

                initializeId(state, action.meta.params.id, false, true);
                initializeKey(state, action, false, true);
            },

            storeFulfilled: (state, action) => {
                insertItem(state, action.payload, normalize, byKeys, onInsert);

                initializeId(state, action.payload.id, false, true);
            },

            updateFulfilled: (state, action) => {
                insertItem(state, action.payload, normalize, byKeys, onInsert);
            },

            destroyFulfilled: (state, action) => {
                removeItem(state, action.payload, byKeys, onRemove);
            },

            destroyByReferenceIdFulfilled: (state, action) => {
                const { referenceKey, referenceId, onFilter = null } = action.payload;

                const references = state[asStateKey(referenceKey)];
                const ids = [...(references[referenceId] || [])];

                ids.forEach(id => {
                    const item = state.byId[id];

                    if (!onFilter || onFilter(item)) {
                        removeItem(state, id, referenceKey);
                    }
                });
            },

            ...reducers,
        },
        extraReducers,
    });

    return {
        ...slice,
        actions: { ...slice.actions, ...extraActions(name, resource) },
        selectors: extraSelectors(name, resource, byKeys),
        hooks: extraHooks(name, resource, byKeys),
    };
};
