import { CONTACTABLE_RESOURCE } from '../api/resources';

export const selectContactablesById = state => state[CONTACTABLE_RESOURCE].byId;

export const selectContactableById = (state, id) => selectContactablesById(state)[id];

export const selectAllContactableIds = state => state[CONTACTABLE_RESOURCE].allIds;

export const selectContactablesIdsByContactId = state => state[CONTACTABLE_RESOURCE].byContactId;

export const selectContactableIdsByContactId = (state, contactId) =>
    selectContactablesIdsByContactId(state)[contactId];

export const selectContactablesByContactId = (state, contactId) =>
    (selectContactableIdsByContactId(state, contactId) || []).map(id =>
        selectContactableById(state, id)
    );

export const selectContactablesIdsByCardId = state => state[CONTACTABLE_RESOURCE].byCardId;

export const selectContactableIdsByCardId = (state, cardId) =>
    selectContactablesIdsByCardId(state)[cardId];

export const selectContactablesByCardId = (state, cardId) =>
    (selectContactableIdsByCardId(state, cardId) || []).map(id => selectContactableById(state, id));

export const selectTypeGroupedContactablesByCardId = (state, cardId) => {
    const byContactId = selectContactablesByCardId(state, cardId).reduce((carry, contactable) => {
        if (contactable && !carry[contactable.contact_id]) {
            // eslint-disable-next-line no-param-reassign
            carry[contactable.contact_id] = [];
        }
        if (contactable) carry[contactable.contact_id].push(contactable);
        return carry;
    }, {});

    return Object.values(byContactId).map(contactables => ({
        ...contactables[0],
        synced: contactables.find(contactable => contactable.synced),
        pinned: contactables.some(contactable => contactable.pinned),
        contactTypeIds: contactables.map(contactable => contactable.contact_type_id),
        contactableIds: contactables.map(contactable => contactable.id),
        datacontainerContactTypeIds: contactables
            .filter(contactable => contactable.has_datacontainer)
            .map(contactable => contactable.contact_type_id),
    }));
};

export const selectContactablesByContactableIds = (state, contactableIds) =>
    (contactableIds ?? []).map(id => selectContactableById(state, id));

export const selectContactableCardIdsByContactId = (state, contactId) => {
    const contactableIds = selectContactablesIdsByContactId(state)[contactId];
    const contactables = selectContactablesByContactableIds(state, contactableIds);
    var result = [];
    for (var i = 0; i < contactables.length; i++) {
        result = result.concat(contactables[i].card_ids);
    }
    return result;
};
