import React, { useEffect, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useAppointmentCalendar } from './AppointmentCalendarContext';
import { useDispatch, useSelector } from 'react-redux';
import { selectResourcesById } from '../../modules/resources/selectors';
import { selectEmployeesById } from '../../modules/employees/selectors';
import {
    indexResources,
    selectResourcesLoading,
    showResource,
} from '../../modules/resources/resourcesSlice';
import {
    indexEmployees,
    selectEmployeesLoading,
    showEmployee,
} from '../../modules/employees/employeesSlice';
import { debounce } from 'lodash/function';
import { IdPropType } from '../../modules/proptypes';

const CalendarGroupLoader = ({
    forceResources,
    forceEmployees,
    LoadingComponent,
    loadingComponentProps,
    children,
}) => {
    const dispatch = useDispatch();

    const resourcesLoading = useSelector(selectResourcesLoading);
    const employeesLoading = useSelector(selectEmployeesLoading);
    const resources = useSelector(selectResourcesById);
    const employees = useSelector(selectEmployeesById);

    const loading = employeesLoading || resourcesLoading;

    const { selectedGroupResources, selectedGroupEmployees } = useAppointmentCalendar();

    const finalResourceIds = useMemo(
        () => forceResources || selectedGroupResources,
        [forceResources, selectedGroupResources]
    );
    const finalEmployeeIds = useMemo(
        () => forceEmployees || selectedGroupEmployees,
        [forceEmployees, selectedGroupEmployees]
    );

    const missingResourceIds = useMemo(() => {
        const existingIds = Object.keys(resources);
        return finalResourceIds.filter(id => !existingIds.includes(id.toString()));
    }, [finalResourceIds, resources]);

    const missingEmployeeIds = useMemo(() => {
        const existingIds = Object.keys(employees);
        return finalEmployeeIds.filter(id => !existingIds.includes(id.toString()));
    }, [finalEmployeeIds, employees]);

    const loadMissingResources = useMemo(
        () =>
            debounce(missingIds => {
                if (missingIds.length === 1) {
                    return dispatch(showResource({ id: missingIds[0] }));
                }

                return dispatch(indexResources({ ids: missingIds }));
            }, 80),
        [dispatch]
    );

    const loadMissingEmployees = useMemo(
        () =>
            debounce(missingIds => {
                if (missingIds.length === 1) {
                    return dispatch(showEmployee({ id: missingIds[0] }));
                }

                return dispatch(indexEmployees({ ids: missingIds }));
            }, 80),
        [dispatch]
    );

    useEffect(() => {
        if (
            !resourcesLoading &&
            missingResourceIds &&
            Array.isArray(missingResourceIds) &&
            missingResourceIds.length > 0
        ) {
            loadMissingResources(missingResourceIds);
        }
    }, [resourcesLoading, missingResourceIds]);

    useEffect(() => {
        if (
            !employeesLoading &&
            missingEmployeeIds &&
            Array.isArray(missingEmployeeIds) &&
            missingEmployeeIds.length > 0
        ) {
            loadMissingEmployees(missingEmployeeIds);
        }
    }, [employeesLoading, missingEmployeeIds]);

    return LoadingComponent ? (
        <LoadingComponent loading={loading} isLoading={loading} {...loadingComponentProps}>
            {children}
        </LoadingComponent>
    ) : null;
};

CalendarGroupLoader.propTypes = {
    forceResources: PropTypes.arrayOf(IdPropType),
    forceEmployees: PropTypes.arrayOf(IdPropType),
    LoadingComponent: PropTypes.element,
    loadingComponentProps: PropTypes.shape({}),
};

CalendarGroupLoader.defaultProps = {
    forceResources: null,
    forceEmployees: null,
    LoadingComponent: null,
    loadingComponentProps: {},
};

export default CalendarGroupLoader;
