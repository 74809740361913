import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Collapse, Divider, lighten } from '@mui/material';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import ExpandLessOutlined from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlined from '@mui/icons-material/ExpandMoreOutlined';
import classNames from 'classnames';
import { selectProtocolItemById } from '../../modules/protocolItems/selectors';
import BaseDropzone from '../form/base/BaseDropzone';
import Markdown from '../utils/Markdown';
import ProtocolToolbar from './ProtocolToolbar';
import ProtocolItemFiles from './ProtocolItemFiles';
import ContactList from '../contacts/ContactList';
import { PROTOCOL_DISPLAY, useProtocol } from './ProtocolContext';
import { useCardCloud } from '../../modules/cards/hooks';
import { CLOUD_MAX_FILESIZE_SETTINGS } from '../../modules/settings/config';
import { useApi } from '../../modules/api/ApiProvider';
import { selectSettingsByKey } from '../../modules/settings/selectors';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },

    pinned: {
        backgroundColor: theme.palette.background.primary,
    },

    notify: {
        backgroundColor: lighten(theme.palette.warning.light, 0.85),
    },

    content: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },

    expandBar: {
        cursor: 'pointer',
    },

    fadeBottomNotify: {
        position: 'relative',
        '&:after': {
            position: 'absolute',
            bottom: 0,
            height: '100%',
            width: '100%',
            content: '""',
            background: `linear-gradient(to top, ${lighten(
                theme.palette.warning.light,
                0.85
            )} 0%, rgba(255,255,255, 0) 25%)`,
            pointerEvents: 'none',
        },
    },

    fadeBottomPinned: {
        position: 'relative',
        '&:after': {
            position: 'absolute',
            bottom: 0,
            height: '100%',
            width: '100%',
            content: '""',
            background: `linear-gradient(to top, ${theme.palette.background.primary} 0%, rgba(255,255,255, 0) 25%)`,
            pointerEvents: 'none',
        },
    },

    fadeBottom: {
        position: 'relative',
        '&:after': {
            position: 'absolute',
            bottom: 0,
            height: '100%',
            width: '100%',
            content: '""',
            background:
                'linear-gradient(to top, rgba(255,255,255, 1) 0%, rgba(255,255,255, 0) 25%)',
            pointerEvents: 'none',
        },
    },
}));

export const CLOUD_TYPES = {
    KUMO_CONNECT: 'kc',
    NEXTCLOUD: 'nc',
};

const ProtocolItem = ({
    id,
    onEdit,
    readOnly,
    cloudBlocked,
    pinned,
    notify: initialNotify,
    expanded: initialExpanded,
    enableNavigate,
    minimal,
}) => {
    const classes = useStyles();
    const protocolContext = useProtocol();
    const protocolDisplay = protocolContext?.protocolDisplay || PROTOCOL_DISPLAY.FULL;
    const [expanded, setExpanded] = useState(initialExpanded);
    const [expandedHeight, setExpandedHeight] = useState(null);
    const [notify, setNotify] = useState(initialNotify);
    const collapseRef = useRef(null);
    const protocolItem = useSelector(state => selectProtocolItemById(state, id));
    const cloudFileSizeSettings = useSelector(state =>
        selectSettingsByKey(state, CLOUD_MAX_FILESIZE_SETTINGS)
    );
    const [cloudMaxFileSize, setCloudMaxFileSize] = useState(null);
    const { kumoConnect } = useApi();
    const { attachments } = protocolItem;
    const [attachmentCount] = attachments;

    const { cloudRoot } = useCardCloud(protocolItem.card_id);

    const handleDrop = useCallback(
        files => {
            onEdit({ id, files });
        },
        [id, onEdit]
    );

    useEffect(() => {
        async function fetchKumoStatus() {
            const status = await kumoConnect.getKumoConnectStatus();
            if (status) {
                setCloudMaxFileSize(
                    cloudFileSizeSettings
                        ? cloudFileSizeSettings[CLOUD_TYPES.KUMO_CONNECT]
                        : Number.parseInt(process.env.REACT_APP_CLOUD_KC_MAX_FILE_SIZE, 10)
                );
                return;
            }
            setCloudMaxFileSize(
                cloudFileSizeSettings
                    ? cloudFileSizeSettings[CLOUD_TYPES.NEXTCLOUD]
                    : Number.parseInt(process.env.REACT_APP_CLOUD_NC_MAX_FILE_SIZE, 10)
            );
        }
        fetchKumoStatus();
    }, []);

    useEffect(() => {
        setNotify(initialNotify);
    }, [initialNotify, setNotify]);

    useEffect(() => {
        if (collapseRef && collapseRef.current && collapseRef.current.firstElementChild) {
            setExpandedHeight(collapseRef.current.firstElementChild.clientHeight);
        }
    }, [collapseRef, setNotify]);

    return protocolItem ? (
        <BaseDropzone
            onDrop={handleDrop}
            multiple
            maxSize={cloudMaxFileSize}
            disabled={readOnly || protocolDisplay !== PROTOCOL_DISPLAY.FULL}
            name="attachments"
        >
            <Box
                className={classNames(classes.root, {
                    [classes.pinned]: !notify && pinned && protocolItem.pinned,
                    [classes.notify]: notify,
                })}
                data-test-id={`ProtocolEntry-${protocolItem.id}`}
                id={`protocol-${protocolItem.id}`}
            >
                <ProtocolToolbar
                    id={id}
                    onEdit={onEdit}
                    readOnly={readOnly}
                    enableNavigate={enableNavigate}
                    minimal={minimal}
                />
                <Collapse
                    in={expanded}
                    timeout="auto"
                    collapsedSize={expandedHeight > 137 ? 125 : expandedHeight}
                    ref={collapseRef}
                    className={classNames({
                        [classes.fadeBottom]:
                            !notify && !expanded && !(pinned && protocolItem.pinned),
                        [classes.fadeBottomPinned]:
                            !notify && !expanded && pinned && protocolItem.pinned,
                        [classes.fadeBottomNotify]: !expanded && notify,
                    })}
                >
                    <Markdown content={protocolItem.text} className={classes.content} />
                </Collapse>
                {expandedHeight > 137 && (
                    <Box
                        display="flex"
                        justifyContent="center"
                        onClick={() => {
                            setExpanded(!expanded);
                        }}
                        className={classes.expandBar}
                    >
                        <IconButton size="small">
                            {expanded ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
                        </IconButton>
                    </Box>
                )}
                {protocolDisplay === PROTOCOL_DISPLAY.FULL && attachmentCount ? (
                    <>
                        <ProtocolItemFiles
                            protocolItemId={id}
                            cardId={protocolItem.card_id}
                            cloudBlocked={cloudBlocked}
                            cloudRoot={cloudRoot}
                            expanded={initialExpanded}
                            minimal={minimal}
                        />
                        {protocolItem &&
                            protocolItem.attachments.length !== 0 &&
                            protocolItem.contactIds.length !== 0 && <Divider variant="fullWidth" />}
                        <ContactList
                            contactIds={(protocolItem && protocolItem.contactIds) || []}
                            contextCardId={protocolItem.card_id}
                        />
                    </>
                ) : null}
            </Box>
        </BaseDropzone>
    ) : null;
};

ProtocolItem.propTypes = {
    id: PropTypes.number.isRequired,
    onEdit: PropTypes.func,
    readOnly: PropTypes.bool,
    cloudBlocked: PropTypes.bool,
    pinned: PropTypes.bool,
    notify: PropTypes.bool,
    expanded: PropTypes.bool,
    enableNavigate: PropTypes.bool,
    minimal: PropTypes.bool,
};

ProtocolItem.defaultProps = {
    onEdit: null,
    readOnly: false,
    cloudBlocked: false,
    pinned: false,
    notify: false,
    expanded: false,
    enableNavigate: false,
    minimal: false,
};

export default memo(ProtocolItem);
