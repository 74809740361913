import React from 'react';
import * as PropTypes from 'prop-types';
import KeyboardWatcher from '../base/KeyboardWatcher';
import { useFormikContext } from 'formik';

const MultilineSubmit = ({ children }) => {
    const { submitForm } = useFormikContext();

    const handleEnter = (event, handleBlur) => {
        if (!event?.shiftKey) {
            event.preventDefault();
            submitForm();
            handleBlur();
        }
    };

    return <KeyboardWatcher onEnter={handleEnter}>{children}</KeyboardWatcher>;
};

MultilineSubmit.propTypes = {};

export default MultilineSubmit;
