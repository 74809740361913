import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import FormPopup from '../popups/FormPopup';
import CardAutocomplete from '../form/formik/autocomplete/CardAutocomplete';
import TaskSelect from '../form/formik/TaskSelect';
import WatchContainer from '../form/formik/WatchContainer';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { usePopupSelectClickAway } from '../../modules/form/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { selectJournalById, updateJournal } from '../../modules/journals/journalsSlice';
import { JOURNAL_RESOURCE } from '../../modules/api/resources';
import { UPDATE } from '../../modules/abilities/actions';
import { journalMoveSchema } from '../../modules/journals/schema';
import { assembleBulkValues, cleanPartialUpdateValues } from '../../modules/form/bulk';
import AppointmentSelect from '../form/formik/AppointmentSelect';
import { selectJournalsById } from '../../modules/journals/selectors';
import { IdPropType } from '../../modules/proptypes';

const JournalMoveForm = ({ journalIds, onSubmitting, children }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const journal = useSelector(state =>
        selectJournalById(state, journalIds.length === 1 ? journalIds[0] : null)
    );
    const journalsById = useSelector(selectJournalsById);

    const initialValues = useMemo(() => {
        if (journalIds.length > 1) {
            const journals = journalIds.map(journalId => journalsById[parseInt(journalId)]);
            const bulkValues = assembleBulkValues(journals);

            if (bulkValues) {
                return {
                    card_id: bulkValues.card_id,
                    task_id: bulkValues.task_id,
                    appointment_id: bulkValues.appointment_id,
                };
            }
        }

        return {
            card_id: journal?.card_id,
            task_id: journal?.task_id,
            appointment_id: journal?.appointment_id,
        };
    }, [journal, journalIds, journalsById]);

    const { selectProps, handleClickAway } = usePopupSelectClickAway();

    const handleSubmit = (values, handleClose) => {
        if (values) {
            const changedValues = cleanPartialUpdateValues(initialValues, values);

            if (changedValues && Object.keys(changedValues).length !== 0) {
                onSubmitting();

                return dispatch(
                    updateJournal({
                        id: journalIds,
                        ...changedValues,
                    })
                )
                    .then(() => {
                        handleClose();
                    })
                    .catch(() => {
                        handleClose();
                    });
            }
        }
    };

    return (
        <FormPopup
            popupId={`JournalMoveForm.${journalIds.length ? journalIds.join('+') : 'selected'}`}
            submitLabel={t('components.JournalMove.label')}
            initialValues={initialValues}
            submitBadge={journalIds.length > 1 ? journalIds.length : null}
            subject={journal || JOURNAL_RESOURCE}
            validationSchema={journalMoveSchema}
            disableUntouchedSubmit={false}
            postponeSubmitClose
            muteDirty
            FormInputs={
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <CardAutocomplete name="card_id" size="small" showInitialState />
                    </Grid>
                    <Grid item style={{ position: 'relative' }}>
                        <WatchContainer
                            name="card_id"
                            WatchComponent={({ value }) => (
                                <TaskSelect
                                    name="task_id"
                                    referenceId={{ key: 'card_id', value }}
                                    I={UPDATE}
                                    {...selectProps}
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid item style={{ position: 'relative' }}>
                        <WatchContainer
                            name="task_id"
                            WatchComponent={({ value }) => (
                                <AppointmentSelect
                                    name="appointment_id"
                                    taskId={value}
                                    can={UPDATE}
                                    {...selectProps}
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            }
            onClickAway={handleClickAway}
            onSubmit={handleSubmit}
        >
            {children}
        </FormPopup>
    );
};

JournalMoveForm.propTypes = {
    journalIds: PropTypes.arrayOf(IdPropType),
    onSubmitting: PropTypes.func,
};

JournalMoveForm.defaultProps = {
    journalIds: [],
    onSubmitting: () => null,
};

export default JournalMoveForm;
