import React, { memo, useCallback } from 'react';
import * as PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import FileList from '../files/FileList';
import { fileManagerPath } from '../../routes/paths';
import { IdPropType } from '../../modules/proptypes';
import Email from '../email/Email';
import ResourceWaypoint from '../table/resource/ResourceWaypoint';
import { useProtocolItemAttachments } from '../../modules/attachments/hooks';
import { useSelector } from 'react-redux';
import { selectProtocolItemById } from '../../modules/protocolItems/selectors';

const ProtocolItemFiles = ({
    protocolItemId,
    cardId,
    cloudBlocked,
    cloudRoot,
    expanded,
    minimal,
}) => {
    const protocolItem = useSelector(state => selectProtocolItemById(state, protocolItemId));
    const showDirectory = useCallback(path => fileManagerPath(cardId, path), [cardId]);
    const initialLoad = !(
        protocolItem?.attachments &&
        Array.isArray(protocolItem.attachments) &&
        protocolItem.attachments.length &&
        typeof protocolItem.attachments[0] === 'object'
    );

    const { listId, attachments, handleNextPage } = useProtocolItemAttachments(
        protocolItemId,
        initialLoad
    );

    return (
        <>
            {minimal
                ? null
                : attachments.map(attachment => {
                      return attachment.mail ? (
                          <Box key={`mail_${attachment.id}`}>
                              <Email attachmentId={attachment.id} expanded={expanded} />
                          </Box>
                      ) : null;
                  })}
            <FileList
                attachments={attachments}
                blocked={cloudBlocked}
                cloudRoot={cloudRoot}
                showDirectory={showDirectory}
                expanded={expanded}
            />
            <ResourceWaypoint
                onEnter={handleNextPage}
                listId={listId}
                style={{
                    height: 22,
                    marginTop: 16,
                    marginBottom: 8,
                }}
                showLoading
                showInitialLoading
            />
        </>
    );
};

ProtocolItemFiles.propTypes = {
    protocolItemId: IdPropType.isRequired,
    cardId: IdPropType.isRequired,
    cloudBlocked: PropTypes.bool,
    cloudRoot: PropTypes.string,
    expanded: PropTypes.bool,
    minimal: PropTypes.bool,
};

ProtocolItemFiles.defaultProps = {
    cloudBlocked: false,
    cloudRoot: '/',
    expanded: false,
    minimal: false,
};

export default memo(ProtocolItemFiles);
