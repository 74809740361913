import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as PropType from 'prop-types';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { makeStyles } from '@mui/styles';
import { selectAppointmentById } from '../../modules/appointments/appointmentSlice';
import { IdPropType } from '../../modules/proptypes';
import DueDate from '../utils/DueDate';
import Avatars from '../icons/Avatars';
import TaskDuration from '../taskDurations/TaskDuration';
import { useTaskDurations } from '../../modules/taskDurations/taskDurationSlice';
import Date, { DATE_TYPES } from '../utils/Date';
import { useAppointmentName } from '../../modules/appointments/hooks';
import { formatAppointmentImport } from '../../modules/appointments/utils';
import AppointmentResourcesPreview from '../appointments/AppointmentResourcesPreview';

const useStyles = makeStyles(theme => ({
    container: {
        minHeight: 48,
    },
    cardContainer: {
        width: '100%',
    },
    flexTypo: {
        display: 'flex',
    },
    iconSpace: {
        marginRight: theme.spacing(1),
    },
    iconHeight: {
        display: 'inline-block',
        lineHeight: '20px',
    },
}));

const AppointmentBasePreview = ({
    appointmentId,
    appointment: rawAppointment,
    direction,
    spacing,
}) => {
    const classes = useStyles();
    const finalAppointmentId = appointmentId || rawAppointment?.id;
    const appointment = useSelector(state => selectAppointmentById(state, finalAppointmentId));

    useTaskDurations();

    const titleText = useAppointmentName(appointment || rawAppointment);

    const finalAppointment = useMemo(
        () => formatAppointmentImport(appointment || rawAppointment),
        [rawAppointment, appointment]
    );
    const isTable = useMemo(() => direction === 'row', [direction]);

    return (
        <Grid
            container
            spacing={spacing}
            alignItems={isTable ? 'center' : 'start'}
            direction={direction}
            className={isTable ? classes.container : classes.cardContainer}
        >
            <Grid item xs={isTable ? 3 : false} md={isTable}>
                <Typography variant={isTable ? 'h5' : 'h3'} color="primary">
                    {titleText}
                </Typography>
            </Grid>
            <Grid
                item
                xs={isTable ? 3 : false}
                md={isTable}
                {...(isTable || (finalAppointment && finalAppointment.startDate) ? {} : { p: 0 })}
            >
                {finalAppointment && finalAppointment.startDate ? (
                    <Typography variant="body2" className={classes.flexTypo}>
                        <Date
                            date={finalAppointment.startDate}
                            showTime={finalAppointment.hasStartTime}
                            dateType={DATE_TYPES.START}
                        />
                    </Typography>
                ) : (
                    isTable && '-'
                )}
            </Grid>
            <Grid
                item
                xs={isTable ? 3 : false}
                md={isTable}
                {...(isTable || (finalAppointment && finalAppointment.durationValue)
                    ? {}
                    : { p: 0 })}
            >
                {finalAppointment && finalAppointment.durationValue ? (
                    <Typography variant="body2" className={classes.flexTypo}>
                        <TaskDuration
                            durationId={finalAppointment.durationId}
                            value={finalAppointment.durationValue}
                        />
                    </Typography>
                ) : (
                    isTable && '-'
                )}
            </Grid>
            <Grid
                item
                xs={isTable ? 3 : false}
                md={isTable}
                {...(isTable || (finalAppointment && finalAppointment.endDate) ? {} : { p: 0 })}
            >
                {finalAppointment && finalAppointment.endDate ? (
                    <Typography variant="body2" className={classes.flexTypo}>
                        <Box className={classes.iconHeight}>
                            <DueDate
                                dueAt={finalAppointment.endDate}
                                locked={finalAppointment.locked}
                                hasTime={finalAppointment.hasEndTime}
                                dateType={DATE_TYPES.END}
                            />
                        </Box>
                    </Typography>
                ) : (
                    isTable && '-'
                )}
            </Grid>
            <Grid
                item
                xs={isTable ? 3 : false}
                md={isTable}
                {
                    ...(isTable
                        ? {}
                        : { p: 0 }) /* || (responsibles && responsibles.length !== 0) */
                }
            >
                <AppointmentResourcesPreview appointmentId={finalAppointmentId} />
            </Grid>
        </Grid>
    );
};

AppointmentBasePreview.propTypes = {
    appointmentId: IdPropType,
    appointment: PropType.shape({}),
    direction: PropType.string,
    spacing: PropType.number,
};

AppointmentBasePreview.defaultProps = {
    appointmentId: null,
    appointment: null,
    direction: 'row',
    spacing: 2,
};

export default AppointmentBasePreview;
