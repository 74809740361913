import React, { createContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { createContextualCan, useAbility as useCaslAbility } from '@casl/react';
import { Ability, detectSubjectType } from '@casl/ability';
import { useSelector } from 'react-redux';
import { selectUser } from '../auth/selectors';

export const AbilityContext = createContext(null);

export const useAbility = () => useCaslAbility(AbilityContext);

export const CASLCan = createContextualCan(AbilityContext.Consumer);

const AbilityProvider = ({ children }) => {
    const user = useSelector(selectUser);
    const ability = useMemo(() => {
        const _ability = new Ability((user && user.permissions.abilities) || [], {
            detectSubjectType: subject => (subject && subject.__type) || detectSubjectType(subject),
        });

        if (process.env.NODE_ENV !== 'production') {
            /* useful to debug permissions directly in the browser console */
            window.ability = _ability;
        }

        return _ability;
    }, [user]);

    return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>;
};

AbilityProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default AbilityProvider;
