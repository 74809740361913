import React from 'react';
import * as PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { ButtonBase } from '@mui/material';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: theme.palette.background.paper,
        padding: 1,
    },

    grid: {
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(1),
        cursor: 'pointer',
    },

    icon: {
        color: theme.palette.text.primary,
        display: 'flex',
    },

    label: {
        color: theme.palette.text.primary,
        lineHeight: 'normal',
        fontWeight: 'bold',
        textAlign: 'start',
    },
}));

const CalendarResourceVisibility = ({ visible, onClick }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <ButtonBase onClick={onClick} style={{ width: '100%' }}>
            <Grid container spacing={1} alignItems="center" className={classes.grid}>
                <Grid item>
                    {visible ? (
                        <VisibilityOffIcon fontSize="small" className={classes.icon} />
                    ) : (
                        <VisibilityIcon fontSize="small" className={classes.icon} />
                    )}
                </Grid>
                <Grid item xs>
                    <Typography variant="subtitle2" className={classes.label}>
                        {visible
                            ? t('components.CalendarResourcesPicker.hide')
                            : t('components.CalendarResourcesPicker.show')}
                    </Typography>
                </Grid>
            </Grid>
        </ButtonBase>
    );
};

CalendarResourceVisibility.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default CalendarResourceVisibility;
