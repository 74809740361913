import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Button from '../../form/base/Button';
import { CREATE } from '../../../modules/abilities/actions';
import { insertItem } from '../../../modules/lists/listsSlice';

const CreateResourceButton = ({
    onClick,
    listId,
    resource,
    'data-test-id': dataTestId,
    className,
    ...other
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleCreate = useCallback(() => {
        const handleDone = ({ data: created }) => {
            dispatch(insertItem(created, { listId }));
        };
        return onClick({ onDone: handleDone });
    }, [dispatch, listId, onClick]);

    return (
        <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleCreate}
            data-test-id={dataTestId}
            subject={resource}
            action={CREATE}
            className={className}
            {...other}
        >
            {t('components.CreateResourceButton.label')}
        </Button>
    );
};

CreateResourceButton.propTypes = {
    onClick: PropTypes.func,
    listId: PropTypes.string.isRequired,
    resource: PropTypes.string.isRequired,
    'data-test-id': PropTypes.string,
    className: PropTypes.string,
};

CreateResourceButton.defaultProps = {
    onClick: () => null,
    'data-test-id': null,
    className: null,
};

export default CreateResourceButton;
