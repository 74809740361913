import PropTypes from 'prop-types';
import { IdPropType } from '../proptypes';

export const FilePropType = PropTypes.shape({
    id: IdPropType,
    path: PropTypes.string,
    filename: PropTypes.string,
    file: PropTypes.shape({}),
    metadata: PropTypes.shape({}),
});

export const FilesOrderPropType = PropTypes.shape({
    dirs: {
        orderBy: PropTypes.string.isRequired,
        direction: PropTypes.string.isRequired,
    }.isRequired,
    files: {
        orderBy: PropTypes.string.isRequired,
        direction: PropTypes.string.isRequired,
    }.isRequired,
});
