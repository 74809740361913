import React from 'react';
import * as PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TextInput from '../form/formik/TextInput';
import ColorPicker from '../form/formik/ColorPicker';
import IconSelect from '../form/formik/IconSelect';
import { makeStyles } from '@mui/styles';
import Checkbox from '../form/formik/Checkbox';

const useStyles = makeStyles(theme => ({
    inputRow: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
}));

const CategoryFormBody = ({ asSmallForm, autoFocus }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <>
            <Grid container spacing={1}>
                <Grid item md={9} xs={12}>
                    <TextInput
                        className={classes.inputRow}
                        name="name"
                        label={t('form.CategoryForm.name')}
                        size="small"
                        autoFocus={autoFocus}
                    />
                </Grid>
                <Grid item md={3} xs={12}>
                    <ColorPicker
                        className={classes.inputRow}
                        name="color"
                        label={t('form.CategoryForm.color')}
                        size="small"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item md={asSmallForm ? 12 : 9} xs={12}>
                    <IconSelect name="icon" />
                </Grid>
                {!asSmallForm && (
                    <Grid item md={3} xs={12}>
                        <Checkbox
                            name="active"
                            label={t('form.CategoryForm.active')}
                            color="primary"
                        />
                    </Grid>
                )}
            </Grid>
        </>
    );
};

CategoryFormBody.propTypes = {
    asSmallForm: PropTypes.bool,
    autoFocus: PropTypes.bool,
};

CategoryFormBody.defaultProps = {
    asSmallForm: false,
    autoFocus: false,
};

export default CategoryFormBody;
