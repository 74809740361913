import { PERSON_RESOURCE } from '../api/resources';
import { createResourceSlice } from '../redux/resource/createResourceSlice';

const personsSlice = createResourceSlice({
    resource: PERSON_RESOURCE,
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,

    indexPersons,
    showPerson,
    storePerson,
    updatePerson,
    archivePerson,
    restorePerson,
    destroyPerson,
} = personsSlice.actions;

export const {
    selectPersonById,
    makePersonsByIdsSelector,
    selectAllPersonIds,
    selectAllPersons,
    selectPersonsLoading,
    selectPersonsInitialized,
    selectPersonLoading,
    selectPersonInitialized,
} = personsSlice.selectors;

export const { usePersons, usePerson } = personsSlice.hooks;

export default personsSlice.reducer;
