import React, { useCallback, useContext, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import TableContainer from '@mui/material/TableContainer';
import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { Grid, TableCell } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import MuiTableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import TableHead from './TableHead';
import { IdPropType } from '../../modules/proptypes';
import TableContext from './TableContext';
import { TableActionPropType, TableExpandPropType, TableOrderPropType } from './proptypes';
import Persistor from '../../modules/persistor/persistor';

const useStyles = makeStyles(theme => ({
    table: {
        width: '100%',
        marginBottom: theme.spacing(3),
    },
}));

const updateOrderBy = (stale, cellKey, orderRanks) => {
    const priority = stale.findIndex(([orderBy]) => orderBy === cellKey);

    if (priority < 0) {
        /* new column was clicked -> set as new primary sorting key */
        return [[cellKey, 'asc'], ...stale].slice(0, orderRanks);
    }

    const [, direction] = stale[priority];

    if (direction === 'asc') {
        /* column was sorted ascending -> flip direction */
        return stale.map(orderBy => (orderBy[0] === cellKey ? [cellKey, 'desc'] : orderBy));
    }

    /* column was sorted descending -> remove it */
    return stale.filter(([orderBy]) => orderBy !== cellKey);
};

const Table = ({
    dataIds,
    dataSelector,
    relatedSelector,
    columns: dirtyColumns,
    actions,
    expand,
    showActionsInline,
    loading,
    orderBy,
    onOrderBy,
    orderRanks,
    onClick,
    'data-test-id': dataTestId,
    'aria-label': ariaLabel,
    listId,
}) => {
    const { TableRow } = useContext(TableContext);
    const { t } = useTranslation();
    const classes = useStyles();

    const [expanded, setExpanded] = useState(null);
    const toggleExpand = useCallback(
        (event, id) => {
            setExpanded(old => (id === old ? null : id));
            if (onClick) {
                onClick(id);
            }
        },
        [setExpanded, onClick]
    );

    const handleOrderBy = useCallback(
        cellKey => {
            if (onOrderBy) {
                const newOrderBy = updateOrderBy(orderBy, cellKey, orderRanks);
                onOrderBy(newOrderBy);
                Persistor.set(`cachedResourceTableSort.${listId}`, newOrderBy);
            }
        },
        [orderBy, onOrderBy, orderRanks, listId]
    );

    const columns = useMemo(() => dirtyColumns.filter(({ hidden }) => !hidden), [dirtyColumns]);

    const colSpan = actions && actions.length ? columns.length + 1 : columns.length;

    return (
        <TableContainer>
            <MuiTable className={classes.table} aria-label={ariaLabel} data-test-id={dataTestId}>
                <TableHead
                    columns={columns}
                    orderBy={orderBy}
                    onClick={handleOrderBy}
                    withInlineActions={!!(actions && actions.length)}
                />
                <TableBody>
                    {loading ? (
                        <MuiTableRow>
                            <TableCell colSpan={colSpan}>
                                <Grid container justifyContent="center" alignItems="center">
                                    <Grid item xs={6} sm={4}>
                                        <LinearProgress />
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </MuiTableRow>
                    ) : (
                        dataIds.map(dataId => (
                            <TableRow
                                key={dataId}
                                dataId={dataId}
                                onClick={toggleExpand}
                                expanded={dataId === expanded}
                                selector={dataSelector}
                                relatedSelector={relatedSelector}
                                columns={columns}
                                actions={actions}
                                expand={expand}
                                showActionsInline={showActionsInline}
                                data-test-id={dataTestId}
                            />
                        ))
                    )}
                    {!loading && dataIds && dataIds.length === 0 && (
                        <MuiTableRow>
                            <TableCell colSpan={colSpan}>
                                <Grid container justifyContent="center" alignItems="center">
                                    <Box mt={2} mb={2}>
                                        <Typography variant="body2" color="textSecondary">
                                            {t('components.Table.empty')}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </TableCell>
                        </MuiTableRow>
                    )}
                </TableBody>
            </MuiTable>
        </TableContainer>
    );
};

Table.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    dataIds: PropTypes.arrayOf(IdPropType),
    dataSelector: PropTypes.func.isRequired,
    relatedSelector: PropTypes.func,
    actions: PropTypes.arrayOf(TableActionPropType),
    expand: TableExpandPropType,
    showActionsInline: PropTypes.number,
    orderBy: PropTypes.arrayOf(TableOrderPropType),
    onOrderBy: PropTypes.func,
    orderRanks: PropTypes.number,
    loading: PropTypes.bool,
    onClick: PropTypes.func,
    'data-test-id': PropTypes.string,
    'aria-label': PropTypes.string,
    listId: PropTypes.string,
};

Table.defaultProps = {
    dataIds: null,
    relatedSelector: null,
    actions: null,
    expand: null,
    showActionsInline: 1,
    orderBy: [],
    onOrderBy: null,
    orderRanks: 2,
    loading: false,
    onClick: null,
    'data-test-id': 'PaginatedTable',
    'aria-label': 'Paginated table',
    listId: null,
};

export default Table;
