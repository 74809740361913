const NO_GROUP = 'no-group';

export const combineFilters = filters => {
    /**
     * Rules to combine multiple filters:
     * - filters with different groups (or without any group) are combined with `$and`
     * - filters with the same group are combined with `$or` (inside `$and`)
     */

    const byGroup = filters.reduce((carry, filter) => {
        const group = filter.group || NO_GROUP;
        if (!carry[group]) {
            // eslint-disable-next-line no-param-reassign
            carry[group] = [];
        }
        carry[group].push(filter);
        return carry;
    }, {});

    const mainFilters = Object.entries(byGroup).reduce(
        (carry, [group, groupFilters]) => [
            ...carry,
            ...(group === NO_GROUP
                ? groupFilters
                : [
                      {
                          $or: groupFilters,
                      },
                  ]),
        ],
        []
    );

    return mainFilters.length > 0
        ? {
              $and: mainFilters,
          }
        : {};
};

export const flattenFilters = filters => {
    if (Array.isArray(filters)) {
        /* already flattened */
        return filters;
    }

    const flattened = [];
    if (!filters || !filters.$and) {
        return flattened;
    }

    filters.$and.forEach(groupFilter => {
        if (groupFilter.$or) {
            flattened.push(...groupFilter.$or);
        } else {
            flattened.push(groupFilter);
        }
    });

    return flattened;
};
