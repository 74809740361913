import * as Yup from 'yup';

export const prioritySchema = Yup.object().shape({
    priorityId: Yup.number(),
});

export const prioritySettingsSchema = Yup.object().shape({
    name: Yup.string().trim().required('required'),
    icon: Yup.string().nullable(),
    color: Yup.string().nullable(),
    order: Yup.number().required('required').typeError('numberMismatch'),
});
