/* these normally come from the slice */
import { CATEGORY_RESOURCE } from '../api/resources';

export const indexCategories =
    (params, meta = {}) =>
    (dispatch, getState, api) =>
        api[CATEGORY_RESOURCE].index(params, meta);

export const showCategory = params => (dispatch, getState, api) =>
    api[CATEGORY_RESOURCE].show(params);

export const storeCategory = category => (dispatch, getState, api) =>
    api[CATEGORY_RESOURCE].store(category);

export const updateCategory = category => (dispatch, getState, api) =>
    api[CATEGORY_RESOURCE].update(category);

export const destroyCategory = params => (dispatch, getState, api) =>
    api[CATEGORY_RESOURCE].destroy({ ...params, forceDelete: true });
