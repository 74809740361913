import React from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';
import Typography from '@mui/material/Typography';
import LaunchIcon from '@mui/icons-material/Launch';
import PublicIcon from '@mui/icons-material/Public';
import { ConditionalWrapper } from './ConditionalWrapper';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        display: 'inline-block',
        textDecoration: 'inherit',
        color: 'inherit',
        '&:active, &:hover, &:focus, &:visited': {
            color: 'inherit',
        },
    },

    typo: {
        display: 'flex',
        alignItems: 'center',
    },

    typoHover: {
        '&:hover': {
            textDecoration: 'underline',
        },
    },

    icon: {
        verticalAlign: 'baseline',
        marginLeft: theme.spacing(1),
        color: theme.palette.text.secondary,
    },

    iconH1: {
        verticalAlign: 'baseline',
        fontSize: theme.typography.pxToRem(20),
    },
    iconH2: {
        verticalAlign: 'baseline',
        fontSize: theme.typography.pxToRem(18),
    },
    iconH3: {
        verticalAlign: 'text-bottom',
        fontSize: theme.typography.pxToRem(18),
    },
    iconH4: {
        verticalAlign: 'text-bottom',
        fontSize: theme.typography.pxToRem(18),
    },
    iconH5: {
        verticalAlign: 'text-bottom',
        fontSize: theme.typography.pxToRem(16),
    },
    iconH6: {
        verticalAlign: 'text-bottom',
        fontSize: theme.typography.pxToRem(20),
    },
    iconSubtitle1: {
        verticalAlign: 'text-bottom',
        fontSize: theme.typography.pxToRem(16),
    },
    iconSubtitle2: {
        verticalAlign: 'text-bottom',
        fontSize: theme.typography.pxToRem(16),
    },
    iconBody1: {
        verticalAlign: 'text-bottom',
        fontSize: theme.typography.pxToRem(16),
    },
    iconBody2: {
        verticalAlign: 'text-bottom',
        fontSize: theme.typography.pxToRem(16),
    },
    iconCaption: {
        verticalAlign: 'text-bottom',
        fontSize: theme.typography.pxToRem(16),
    },
    iconButton: {
        verticalAlign: 'text-bottom',
        fontSize: theme.typography.pxToRem(16),
    },
    iconOverline: {
        verticalAlign: 'text-bottom',
        fontSize: theme.typography.pxToRem(16),
    },
}));

/**
 * Default Link
 * @param to internal route or url if external is set
 * @param children
 * @param className
 * @param icon
 * @param external
 * @param noBlank
 * @param noHover
 * @param variant
 * @param color
 * @param component
 * @param disabled
 * @param onClick work only with internal routes
 * @return {JSX.Element}
 * @constructor
 */
const Link = ({
    to,
    children,
    className,
    icon,
    external,
    newTab,
    noBlank,
    noHover,
    variant,
    color,
    component,
    disabled,
    ...other
}) => {
    const classes = useStyles();
    const iconClass = {
        [classes.iconH1]: variant === 'h1',
        [classes.iconH2]: variant === 'h2',
        [classes.iconH3]: variant === 'h3',
        [classes.iconH4]: variant === 'h4',
        [classes.iconH5]: variant === 'h5',
        [classes.iconH6]: variant === 'h6',
        [classes.iconSubtitle1]: variant === 'subtitle1',
        [classes.iconSubtitle2]: variant === 'subtitle2',
        [classes.iconBody1]: variant === 'body1',
        [classes.iconBody2]: variant === 'body2',
        [classes.iconCaption]: variant === 'caption',
        [classes.iconButton]: variant === 'button',
        [classes.iconOverline]: variant === 'overline',
    };

    return (
        <ConditionalWrapper
            condition={!disabled}
            wrapper={_children =>
                external ? (
                    <MuiLink
                        href={to}
                        className={classNames(classes.root, className)}
                        target={!newTab && noBlank ? '_self' : '_blank'}
                        rel="noreferrer"
                        {...other}
                    >
                        {_children}
                    </MuiLink>
                ) : (
                    <RouterLink
                        to={to}
                        target={newTab ? '_blank' : undefined}
                        className={classNames(classes.root, className)}
                        {...other}
                    >
                        {_children}
                    </RouterLink>
                )
            }
        >
            <Typography
                variant={variant}
                color={color}
                component={component}
                className={classNames({
                    [classes.typo]: true,
                    [classes.typoHover]: !noHover && !disabled,
                })}
            >
                {children}{' '}
                {icon &&
                    (external ? (
                        <PublicIcon
                            className={classNames(classes.icon, iconClass)}
                            fontSize="inherit"
                            color="inherit"
                        />
                    ) : (
                        <LaunchIcon
                            className={classNames(classes.icon, iconClass)}
                            fontSize="inherit"
                            color="inherit"
                        />
                    ))}
            </Typography>
        </ConditionalWrapper>
    );
};

Link.propTypes = {
    to: PropTypes.string.isRequired,
    children: PropTypes.node,
    className: PropTypes.string,
    icon: PropTypes.bool,
    external: PropTypes.bool,
    newTab: PropTypes.bool,
    noBlank: PropTypes.bool,
    noHover: PropTypes.bool,
    variant: PropTypes.string,
    color: PropTypes.string,
    component: PropTypes.string,
    disabled: PropTypes.bool,
};

Link.defaultProps = {
    className: null,
    children: null,
    icon: false,
    external: false,
    newTab: false,
    noBlank: false,
    noHover: false,
    variant: 'body1',
    color: 'initial',
    component: 'span',
    disabled: false,
};

export default Link;
