import React from 'react';
import * as PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';

const useStyles = makeStyles({
    fullHeight: {
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
});

const TabPanel = ({ children, selected, id, ariaLabelledBy, fullHeight, ...other }) => {
    const classes = useStyles();
    return (
        <Box
            role="tabpanel"
            hidden={!selected}
            id={id}
            aria-labelledby={ariaLabelledBy}
            className={classNames(fullHeight ? [classes.fullHeight] : [])}
            {...other}
        >
            {selected && children}
        </Box>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    selected: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    ariaLabelledBy: PropTypes.string.isRequired,
    fullHeight: PropTypes.bool,
};

TabPanel.defaultProps = {
    children: null,
    fullHeight: false,
};

export default TabPanel;
