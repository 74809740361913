import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Button, Portal } from '@mui/material';
import { useField } from 'formik';
import TextInput from '../../form/formik/TextInput';
import Form from '../../form/formik/Form';
import DateInput from '../../form/formik/DateInput';
import { selectBaseUserRole } from '../../../modules/role/selectors';
import { passwordResetUser, storeUser, updateUser } from '../../../modules/users/actions';
import { useSnackbar } from '../../../modules/snackbar/hooks';
import LoadingButton from '../../loading/LoadingButton';
import UploadField from '../../form/base/UploadField';
import { selectUserById } from '../../../modules/users/selectors';
import EmployeeActions from './EmployeeActions';
import { employeeInternalDataSchema } from '../../../modules/employees/schema';
import { formatDate } from '../../../modules/datetime/utils';
import { USER_RESOURCE } from '../../../modules/api/resources';
import ColorPicker from '../../form/formik/ColorPicker';
import RolesSelect from '../../form/specialized/RolesSelect';
import { prefillInitialValues } from '../../../modules/form/utils';
import FormFieldResetButton from '../../buttons/FormFieldResetButton';
import Avatar from '../../form/formik/Avatar';
import Can from '../../../modules/abilities/Can';
import { UPDATE } from '../../../modules/abilities/actions';
import { IdPropType } from '../../../modules/proptypes';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    item: {
        flex: 1,
        flexShrink: 0,
    },
    inputRow: {
        width: '100%',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    h3: {
        color: theme.palette.primary.main,
        paddingBottom: theme.spacing(2),
        fontSize: '1rem',
    },
    h4: {
        color: theme.palette.primary.main,
        paddingBottom: theme.spacing(2),
        fontSize: '.875rem',
    },
    avatarPreview: {
        width: '100px',
        height: '100px',
        borderRadius: '50px',
        display: 'block',
        objectFit: 'cover',
    },
    spaceBottom: {
        marginBottom: theme.spacing(2),
    },
}));

const prefillRoles = (user, baseRole) => {
    if (!baseRole) {
        return user ? user.roles : [];
    }
    if (!user) {
        return [baseRole.id];
    }
    return user.roles.includes(baseRole.id) ? user.roles : [...user.roles, baseRole.id];
};

const EmployeeInternalData = ({ employeeId, userId, submitRef, onDone }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();

    const [passwordResetLoading, setPasswordResetLoading] = useState(false);
    const [apiErrors, setApiErrors] = useState([]);

    const user = useSelector(state => selectUserById(state, userId));
    const baseRole = useSelector(selectBaseUserRole);
    const userMissing = !userId || !user;

    const { enqueueSnackbar } = useSnackbar();

    const initialValues = prefillInitialValues(
        {
            ...user,
            roles: prefillRoles(user, baseRole),
        },
        {
            username: '',
            email: '',
            roles: baseRole ? [baseRole.id] : [],
            name_short: '',
            color: '',
            deactivated_at: null,
            avatar: null,
        }
    );

    const handleSubmit = async (values, { resetForm }) => {
        setApiErrors([]);

        let avatarUpload = null;
        if (values.avatar instanceof File) {
            avatarUpload = values.avatar;
            delete values.avatar;
        }

        const preppedValues = {
            id: userId,
            employee_id: employeeId,
            ...values,
            deactivated_at:
                values.deactivated_at instanceof Date ? formatDate(values.deactivated_at) : null,
        };

        if (preppedValues.username === initialValues.username) {
            delete preppedValues.username;
        }

        if (preppedValues.email === initialValues.email) {
            delete preppedValues.email;
        }

        try {
            const { data } = await dispatch(
                userId ? updateUser(preppedValues) : storeUser(preppedValues)
            );

            if (avatarUpload) {
                await dispatch(
                    updateUser({
                        id: data.id,
                        avatar: avatarUpload,
                    })
                );
            }

            enqueueSnackbar(t('components.ContactBaseData.contactSaved'), {
                variant: 'success',
            });

            if (resetForm) {
                // resetForm();
            }
            if (onDone) {
                onDone();
            }
        } catch ({ response }) {
            if (response && response.data && response.data.errors) {
                setApiErrors(response.data);
            }
        }

        return Promise.resolve();
    };

    const handlePasswordReset = () => {
        if (window.confirm(t('components.EmployeeInternalData.passwordResetConfirm'))) {
            setPasswordResetLoading(true);
            dispatch(passwordResetUser({ id: userId })).then(() => {
                enqueueSnackbar(t('components.EmployeeInternalData.passwordResetSuccess'), {
                    variant: 'success',
                });

                setPasswordResetLoading(false);
            });
        }
    };

    return (
        <Grid container className={classes.root} spacing={2}>
            <Grid item className={classes.item}>
                <Typography variant="h3" className={classes.h3}>
                    {t('components.EmployeeInternalData.title')}
                </Typography>

                <Form
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={employeeInternalDataSchema}
                    enableReinitialize
                    subject={user || USER_RESOURCE}
                >
                    <Grid container spacing={2}>
                        <Grid item sm={12} md={3}>
                            <TextInput
                                name="username"
                                label={t('components.EmployeeInternalData.username')}
                                className={classes.inputRow}
                                size="small"
                                onChange={() => setApiErrors(false)}
                                errors={apiErrors}
                            />
                        </Grid>

                        <Grid item sm={12} md={3}>
                            <TextInput
                                name="email"
                                label={t('components.EmployeeInternalData.email')}
                                className={classes.inputRow}
                                size="small"
                                errors={apiErrors}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} className={classes.spaceBottom}>
                        <Grid item sm={12} md={3}>
                            <RolesSelect
                                name="roles"
                                label={t('components.EmployeeInternalData.roles')}
                                fullWidth
                            />
                        </Grid>

                        <Grid item sm={12} md={3}>
                            <Can I={UPDATE} this={user || USER_RESOURCE} field="password">
                                <LoadingButton
                                    isLoading={passwordResetLoading}
                                    onClick={handlePasswordReset}
                                    disabled={userMissing}
                                >
                                    {t('components.EmployeeInternalData.passwordReset')}
                                </LoadingButton>
                            </Can>
                        </Grid>
                    </Grid>

                    <DeactivateComponent disableButton={userMissing} disableInput={userMissing} />

                    <Grid container spacing={2} className={classes.spaceBottom}>
                        <Grid item sm={12} md={3}>
                            <TextInput
                                name="name_short"
                                label={t('components.EmployeeInternalData.name_short')}
                                className={classes.inputRow}
                                size="small"
                            />
                        </Grid>
                        <Grid item sm={12} md={3}>
                            <ColorPicker
                                className={classes.inputRow}
                                name="color"
                                label={t('components.EmployeeInternalData.color')}
                                size="small"
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} className={classes.spaceBottom}>
                        <Grid item sm={12} md={4}>
                            <Grid alignItems="center" container spacing={2}>
                                <Grid item>
                                    <Avatar name="avatar" user={user} disableHover />
                                </Grid>
                                <Grid item>
                                    <UploadField name="avatar" />
                                </Grid>
                                <Grid item>
                                    <FormFieldResetButton name="avatar" />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Portal container={submitRef.current}>
                        <EmployeeActions />
                    </Portal>
                </Form>
            </Grid>
        </Grid>
    );
};

EmployeeInternalData.propTypes = {
    employeeId: IdPropType.isRequired,
    userId: IdPropType.isRequired,
    submitRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]).isRequired,
    onDone: PropTypes.func.isRequired,
};

EmployeeInternalData.defaultProps = {};

export default EmployeeInternalData;

const DeactivateComponent = ({ disableInput, disableButton }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [{ value }, , helpers] = useField('deactivated_at');

    const handleButton = () => {
        helpers.setValue(new Date());
    };

    return (
        <Can I={UPDATE} this={USER_RESOURCE} field="deactivated_at">
            <Grid container spacing={2} className={classes.spaceBottom} alignItems="center">
                <Grid item sm={12} md={3}>
                    <DateInput
                        name="deactivated_at"
                        label={t('components.EmployeeInternalData.deactivated_at')}
                        className={classes.inputRow}
                        disabled={disableInput}
                        size="small"
                    />
                </Grid>
                <Grid item sm={12} md={3}>
                    <Button
                        onClick={handleButton}
                        disabled={disableButton || !!value}
                        color="inherit"
                    >
                        {t('components.EmployeeInternalData.deactivateNow')}
                    </Button>
                </Grid>
            </Grid>
        </Can>
    );
};

DeactivateComponent.propTypes = {
    disableInput: PropTypes.bool,
    disableButton: PropTypes.bool,
};

DeactivateComponent.defaultProps = {
    disableInput: false,
    disableButton: false,
};
