/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { CONTACT_TYPE_RESOURCE } from '../api/resources';

const insertContactType = (state, contactType) => {
    const stale = state.byId[contactType.id];

    if (!stale) {
        state.allIds.push(contactType.id);
    }

    state.byId[contactType.id] = {
        ...contactType,

        datacontainer_ids: undefined,
    };
};

const contactTypesSlice = createSlice({
    name: CONTACT_TYPE_RESOURCE,
    initialState: {
        byId: {},
        allIds: [],
        initialized: false,
    },
    reducers: {
        indexFulfilled: {
            prepare: (payload, meta) => ({ payload, meta }),
            reducer: (state, action) => {
                state.initialized = true;

                action.payload.forEach(type => {
                    insertContactType(state, type);
                });
            },
        },

        showFulfilled: {
            prepare: (payload, meta) => ({ payload, meta }),
            reducer: (state, action) => {
                insertContactType(state, action.payload);
            },
        },

        storeFulfilled: (state, action) => {
            insertContactType(state, action.payload);
        },

        updateFulfilled: (state, action) => {
            insertContactType(state, action.payload);
        },

        destroyFulfilled: (state, action) => {
            const index = state.allIds.findIndex(id => id === action.payload);
            if (index >= 0) {
                delete state.byId[action.payload];
                state.allIds.splice(index, 1);
            }
        },
    },
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,
} = contactTypesSlice.actions;

export default contactTypesSlice.reducer;
