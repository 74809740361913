import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Alert, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IdPropType, RefPropType } from '../../proptypes';
import Form from '../../../components/form/formik/Form';
import { useProtocol } from '../protocolsSlice';
import { PROTOCOL_RESOURCE, PROTOCOL_TEMPLATE_RESOURCE } from '../../api/resources';
import { useInitialValues, useResourceSubmit } from '../../form/hooks';
import { protocolSchema } from '../schema';
import SubmitButton from '../../../components/form/formik/SubmitButton';
import RadioInput from '../../../components/form/formik/RadioInput';
import OptionalFormikContainer from '../../../components/form/formik/OptionalFormikContainer';
import ProtocolNameAutocomplete from '../../../components/protocol/ProtocolNameAutocomplete';
import { useCanWrite } from '../../abilities/hooks';

const useStyles = makeStyles(theme => ({
    alert: {
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const ProtocolForm = ({ cardId, protocolId, onDone, submitRef }) => {
    const { t } = useTranslation();
    const [protocol] = useProtocol(protocolId);
    const classes = useStyles();

    const initialValues = useInitialValues(protocol, {
        name: '',
        scope: protocolId && protocol && protocol.protocol_template_id ? 'global' : 'local',
        card_id: cardId,
    });

    const handleSubmit = useResourceSubmit(protocolId, PROTOCOL_RESOURCE, null, { onDone });

    const enableGlobal = useCanWrite(PROTOCOL_TEMPLATE_RESOURCE);

    const global = !protocolId || (protocol && !!protocol.protocol_template_id);

    return (
        <Form
            initialValues={initialValues}
            onSubmit={handleSubmit}
            subject={protocol || PROTOCOL_RESOURCE}
            validationSchema={protocolSchema}
            snackbar
        >
            <Grid container spacing={2}>
                <Grid
                    data-test-id="protocolNameAutocomplete"
                    item
                    xs={12}
                    style={{ paddingTop: '24px' }}
                >
                    <ProtocolNameAutocomplete
                        label={t('form.ProtocolForm.name')}
                        name="name"
                        cardId={cardId}
                    />
                </Grid>
                <Grid item xs={12}>
                    <RadioInput
                        name="scope"
                        label={t(
                            protocolId
                                ? 'form.ProtocolForm.edit.title'
                                : 'form.ProtocolForm.create.title'
                        )}
                        translation={
                            protocolId
                                ? 'form.ProtocolForm.edit.options'
                                : 'form.ProtocolForm.create.options'
                        }
                        fullWidth
                        disabled={!global || !enableGlobal}
                    />
                </Grid>
                {protocolId && global && (
                    <>
                        <OptionalFormikContainer name="scope" hide={['global', 'new']}>
                            <Grid item xs={12}>
                                <Alert severity="warning" className={classes.alert}>
                                    {t('form.ProtocolForm.edit.warningLocal')}
                                </Alert>
                            </Grid>
                        </OptionalFormikContainer>
                        <OptionalFormikContainer name="scope" hide={['global', 'local']}>
                            <Grid item xs={12}>
                                <Alert severity="warning" className={classes.alert}>
                                    {t('form.ProtocolForm.edit.warningNew')}
                                </Alert>
                            </Grid>
                        </OptionalFormikContainer>
                        {/* <OptionalFormikContainer name="scope" hide={['new', 'local']}> */}
                        {/*    <Grid item xs={12}> */}
                        {/*        <Alert severity="error" className={classes.alert}> */}
                        {/*            {t('form.ProtocolForm.split.warningGlobal')} */}
                        {/*        </Alert> */}
                        {/*    </Grid> */}
                        {/* </OptionalFormikContainer> */}
                    </>
                )}
            </Grid>

            <SubmitButton data-test-id="btn-saveProtokoll" container={submitRef} />
        </Form>
    );
};

ProtocolForm.propTypes = {
    cardId: IdPropType.isRequired,
    protocolId: IdPropType,
    onDone: PropTypes.func,
    submitRef: RefPropType,
};

ProtocolForm.defaultProps = {
    onDone: null,
    protocolId: null,
    submitRef: null,
};

export default ProtocolForm;
