import { PERSON_FUNCTION_RESOURCE } from '../api/resources';

export const selectPersonFunctionsById = state => state[PERSON_FUNCTION_RESOURCE].byId;

export const selectPersonFunctionsIds = state => state[PERSON_FUNCTION_RESOURCE].allIds;

export const selectPersonFunctionById = (state, id) => selectPersonFunctionsById(state)[id];

export const selectAllPersonFunctions = state => {
    const allIds = selectPersonFunctionsIds(state);
    return allIds.map(id => {
        return selectPersonFunctionById(state, id);
    });
};
