import * as PropTypes from 'prop-types';
import {
    convertUTCtoLocalDate,
    formatLocalDate,
    formatLocalDateTime,
    formatTime,
} from '../../modules/datetime/utils';

export const DATE_TYPES = {
    START: 'start',
    END: 'end',
};

const Date = ({ date, showTime, dateType, onlyDate }) =>
    date &&
    ((showTime ||
        (dateType === DATE_TYPES.END && formatTime(convertUTCtoLocalDate(date)) !== '23:59') ||
        (dateType === DATE_TYPES.START && formatTime(convertUTCtoLocalDate(date)) !== '00:00') ||
        !dateType) &&
    !onlyDate
        ? `${formatLocalDateTime(convertUTCtoLocalDate(date))} Uhr`
        : formatLocalDate(convertUTCtoLocalDate(date)));

Date.propTypes = {
    date: PropTypes.string,
    showTime: PropTypes.bool,
    dateType: PropTypes.string,
    onlyDate: PropTypes.bool,
};

Date.defaultProps = {
    date: null,
    showTime: false,
    dateType: null,
    onlyDate: false,
};

export default Date;
