import React from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { selectUser } from '../modules/auth/selectors';
import { loginPath } from './paths';

const PrivateRoute = ({ component: Component, role, ...rest }) => {
    const user = useSelector(selectUser);
    return (
        <Route
            {...rest}
            render={({ location, ...props }) => {
                if (
                    user !== null &&
                    (role === null || (typeof user.roles === 'object' && user.roles.includes(role)))
                ) {
                    return <Component location={location} {...props} />;
                }

                // TODO roles / teams

                return <Redirect to={{ pathname: loginPath, state: { from: location } }} />;
            }}
        />
    );
};

PrivateRoute.propTypes = {
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
    role: PropTypes.string,
};

PrivateRoute.defaultProps = {
    role: null,
};

export default withRouter(PrivateRoute);
