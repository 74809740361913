import React, { lazy, memo, Suspense, useCallback, useEffect, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Box, CircularProgress, DialogTitle, Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { showCard } from '../../modules/cards/actions';
import CardTitleForm from './CardTitleForm';
import CardNav from './nav/CardNav';
import { IdPropType } from '../../modules/proptypes';
import { selectCardById } from '../../modules/cards/selectors';
import { useDialog } from '../dialogs/DialogContext';
import { showDatacontainer } from '../../modules/datacontainers/actions';
import { indexContacts } from '../../modules/contacts/actions';
import {
    CARD_CLOUD_CONTEXT,
    CARD_IDENTIFIER_CONTEXT,
    CARD_MAP_CONTEXT,
} from '../../modules/metadata/context';
import { useSnackbar } from '../../modules/snackbar/hooks';
import { cardPath } from '../../routes/paths';
import { CARD_CONTEXTS } from '../../modules/contacts/contexts';
import { useLocationTab } from '../../modules/cards/hooks';
import CardIdProvider from './CardIdProvider';
import CardModalActions from './CardModalActions';
import LoadingBox from '../loading/LoadingBox';
import Dialog from '../dialogs/Dialog';
import { WRITE } from '../../modules/abilities/actions';
import { useApi } from '../../modules/api/ApiProvider';

const CardContent = lazy(() => import(/* webpackChunkName: "card-modal" */ './CardContent'));
const CardContentWide = lazy(() =>
    import(/* webpackChunkName: "card-modal" */ './CardContentWide')
);

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
    },

    header: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },

    container: {
        flexGrow: 1,
        minHeight: 0,
        flexWrap: 'nowrap',
    },

    sidebar: {
        backgroundColor: theme.palette.grey[100],
        width: 220,
        paddingTop: theme.spacing(2),
        paddingBottom: 38,
    },

    content: {
        overflowY: 'auto',
    },

    loader: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    fullHeight: {
        height: '100%',
    },

    forceHeight: {
        height: '100%',
        maxHeight: '100%',
        overflow: 'hidden',
    },
}));

const CardModal = ({ cardId, cardStatusId, open, onClose, onExited }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { openCardDialog } = useDialog();
    const { kumoConnect } = useApi();
    const card = useSelector(state => selectCardById(state, cardId));
    const datacontainerId = card ? card.datacontainer_id : null;
    const currentCardId = useRef(null);

    const [edit, setEdit] = useState(false);
    const history = useHistory();
    const tabFromLocation = useLocationTab();
    const [loading, setLoading] = useState(!!cardId);
    const [tab, setTab] = React.useState('overview');

    const handleChange = useCallback(
        (event, newValue) => {
            /* remove additional url params on navigation */
            history.push(cardPath(cardId));

            setTab(newValue);
        },
        [history, setTab, cardId]
    );

    const handleClose = useCallback(() => {
        kumoConnect.clearAllTimeouts();
        onClose();
    }, [kumoConnect, onClose]);

    /* reset active tab when another card is opened */
    useEffect(() => {
        setTab('overview');
    }, [open]);

    useEffect(() => {
        if (tabFromLocation) {
            setTab(tabFromLocation);
        }
    }, [tabFromLocation]);

    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        if (card === null) {
            handleClose();
            enqueueSnackbar('Akte wurde gelöscht', { variant: 'error' });
        }
    }, [card, handleClose, enqueueSnackbar]);

    useEffect(() => {
        if (cardId && currentCardId.current !== cardId) {
            currentCardId.current = cardId;

            setLoading(true);
            dispatch(
                showCard({
                    id: cardId,
                    contexts: [CARD_CLOUD_CONTEXT, CARD_MAP_CONTEXT, CARD_IDENTIFIER_CONTEXT],
                })
            )
                .then(async response => {
                    const kumoConnectStatus = await kumoConnect.getKumoConnectStatus();
                    if (kumoConnectStatus) kumoConnect.checkCardDir(response.data);
                })
                .catch(() => {
                    handleClose();
                })
                .finally(() => {
                    setLoading(false);
                });

            dispatch(
                indexContacts({
                    limit: 250, // TODO: handle more than 250 contacts?
                    card_id: cardId,
                    contexts: CARD_CONTEXTS,
                })
            );
        }
    }, [dispatch, cardId, handleClose, enqueueSnackbar]);

    useEffect(() => {
        if (datacontainerId) {
            dispatch(
                showDatacontainer({
                    id: datacontainerId,
                    contexts: CARD_CONTEXTS,
                })
            );
        }
    }, [dispatch, datacontainerId]);

    const Loader = (
        <Box className={classes.loader}>
            <CircularProgress size={50} />
        </Box>
    );

    return (
        <CardIdProvider cardId={cardId}>
            <Dialog
                aria-labelledby="form-dialog-title"
                fullWidth
                maxWidth={false} // override default explicitly
                disableBackdropClick={edit}
                disableEscapeKeyDown={edit}
                TransitionProps={{ onExited }}
                classes={{
                    paper: classes.root,
                }}
            >
                <DialogTitle className={classes.header}>
                    <CardTitleForm
                        cardId={cardId}
                        cardStatusId={cardStatusId}
                        onSubmit={cardId ? null : ({ id }) => openCardDialog({ cardId: id })}
                        onDirty={setEdit}
                        can={WRITE}
                    />
                </DialogTitle>

                <Grid container className={classes.container}>
                    <Grid item className={classes.sidebar}>
                        <CardNav
                            onChange={handleChange}
                            active={tab}
                            disabled={!cardId}
                            cardId={cardId}
                        />
                    </Grid>
                    <Grid item xs className={classes.content}>
                        <Suspense fallback={Loader}>
                            <LoadingBox loading={loading}>
                                {tab === 'overview' && (
                                    <Grid container spacing={0} className={classes.fullHeight}>
                                        {!loading && (
                                            <Grid item xs={6}>
                                                <CardContent
                                                    tab={tab}
                                                    cardId={cardId}
                                                    onEdit={setEdit}
                                                />
                                            </Grid>
                                        )}
                                        <Grid item xs={6} className={classes.fullHeight}>
                                            <CardContentWide
                                                tab={tab}
                                                cardId={cardId}
                                                datacontainerId={datacontainerId}
                                            />
                                        </Grid>
                                    </Grid>
                                )}

                                {tab !== 'overview' && (
                                    <Grid container spacing={0} className={classes.forceHeight}>
                                        {!loading && (
                                            <Grid item xs={12} className={classes.forceHeight}>
                                                <CardContent
                                                    tab={tab}
                                                    cardId={cardId}
                                                    onEdit={setEdit}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                )}

                                {/* ToDo: Why did we need this?
                                 tab !== 'overview' && (
                                    <Grid container spacing={0} className={classes.fullHeight}>
                                        <Grid item xs={12} className={classes.fullHeight}>
                                            <CardContentWide
                                                tab={tab}
                                                cardId={cardId}
                                                datacontainerId={datacontainerId}
                                            />
                                        </Grid>
                                    </Grid>
                                )*/}
                            </LoadingBox>
                        </Suspense>
                    </Grid>
                </Grid>

                <CardModalActions
                    cardId={cardId}
                    onClose={handleClose}
                    edit={edit}
                    setTab={setTab}
                />
            </Dialog>
        </CardIdProvider>
    );
};

CardModal.propTypes = {
    open: PropTypes.bool.isRequired,
    cardId: IdPropType,
    cardStatusId: IdPropType,
    onClose: PropTypes.func.isRequired,
    onExited: PropTypes.func.isRequired,
};

CardModal.defaultProps = {
    cardId: null,
    cardStatusId: null,
};

export default memo(CardModal);
