import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import { IdPropType } from '../../../modules/proptypes';
import ProtocolTabActions from './ProtocolTabActions';
import { useProtocol } from '../../../modules/protocol/protocolsSlice';
import { useCanWrite } from '../../../modules/abilities/hooks';

const ProtocolTab = forwardRef(
    // eslint-disable-next-line no-unused-vars
    ({ protocolId, index, placeholder, children, ...other }, ref) => {
        const [protocol] = useProtocol(protocolId);

        const canMove = useCanWrite(protocol);

        return (
            <>
                <Draggable
                    draggableId={`protocol.${protocolId}`}
                    index={index}
                    isDragDisabled={!canMove}
                >
                    {provided => (
                        <ProtocolTabActions
                            protocolId={protocolId}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            {...other}
                        >
                            {children}
                        </ProtocolTabActions>
                    )}
                </Draggable>
                {placeholder}
            </>
        );
    }
);

ProtocolTab.propTypes = {
    protocolId: IdPropType.isRequired,
    index: PropTypes.number.isRequired,
    className: PropTypes.string,
    placeholder: PropTypes.node,
    children: PropTypes.node,
};

ProtocolTab.defaultProps = {
    className: null,
    placeholder: null,
    children: null,
};

export default ProtocolTab;
