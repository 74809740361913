import { CHECKLIST_ITEM_RESOURCE } from '../api/resources';

export const selectAllChecklistItemsById = state => state[CHECKLIST_ITEM_RESOURCE].byId;

export const selectChecklistItemById = (state, id) => selectAllChecklistItemsById(state)[id];

export const selectChecklistItemIdsByChecklistId = (state, checklistId) =>
    state[CHECKLIST_ITEM_RESOURCE].byChecklistId[checklistId];

export const selectChecklistItemsByChecklistId = (state, checklistId) => {
    const byChecklistId = selectChecklistItemIdsByChecklistId(state, checklistId);

    if (!byChecklistId) {
        return [];
    }

    let count = 0;
    return byChecklistId.map(checklistId => {
        const item = selectChecklistItemById(state, checklistId);
        return { ...item, sort: item.sort || count++ };
    });
};

export const selectLastChecklistItemSortByChecklistId = (state, checklistId) => {
    const checklistItems = selectChecklistItemsByChecklistId(state, checklistId) || [];
    return checklistItems.length > 0 ? Math.max(...checklistItems.map(c => c.sort)) : 0;
};
