/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { AUDIT_RESOURCE } from '../api/resources';

const auditsSlice = createSlice({
    name: AUDIT_RESOURCE,
    initialState: {
        byId: {},
        byTags: {},
    },
    reducers: {
        indexFulfilled: (state, action) => {
            //@Todo solang wir keine Paginierung haben, ist das wohl am einfachsten so
            state.byTags = {};
            action.payload.forEach(audit => {
                const tags = audit.tags;

                state.byId[audit.id] = { ...audit };

                if (!state.byTags[tags]) {
                    state.byTags[tags] = [];
                }

                state.byTags[tags].push({ ...audit });
            });
        },
    },
});

export const { indexFulfilled } = auditsSlice.actions;

export default auditsSlice.reducer;
