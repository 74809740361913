import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import IconButton from './base/IconButton';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useField } from 'formik';
import classNames from 'classnames';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import { Icon } from '@iconify/react';
import crownF from '@iconify/icons-jam/crown-f';
import crown from '@iconify/icons-jam/crown';
import BaseUserAutocomplete from './base/autocomplete/BaseUserAutocomplete';
import Avatars from '../icons/Avatars';
import { selectUsersById } from '../../modules/users/selectors';
import { RESPONSIBLE } from '../../modules/responsibles/types';
import { useTranslation } from 'react-i18next';
import { differenceInMilliseconds } from 'date-fns';

const useStyles = makeStyles(theme => ({
    button: {
        padding: 4,
        border: '1px solid rgba(0,0,0,0.4)',
        zIndex: 0,
        background: theme.palette.background.paper,

        '&:hover': {
            background: theme.palette.background.paper,
        },
    },
    buttonMax: {
        visibility: 'hidden',
    },

    contrast: {
        color: theme.palette.primary.contrastText,
        border: '1px solid rgba(255,255,255,0.4)',
        background: theme.palette.primary.main,

        '&:hover': {
            background: theme.palette.primary.main,
        },
    },

    input: {
        width: 'auto',
    },

    spacing: {
        paddingTop: 11,
        paddingBottom: 11,
    },

    root: {
        height: 46,

        '&:hover $item': {
            marginLeft: '-8px',
            // marginRight: 4,
        },
        '&:hover $itemStacked': {
            marginLeft: '-27px',
            // marginRight: 0,
        },

        '&:hover $container': {
            marginLeft: 8,
        },
        '&:hover $containerStacked': {
            marginLeft: 27,
        },
        '&:hover $avatarButtonStacked': {
            zIndex: 100,
        },

        '&:hover $title': {
            display: 'none',
        },
    },
    container: {
        marginLeft: 27,
        transition: theme.transitions.create(['margin'], {
            duration: theme.transitions.duration.shortest,
        }),
    },
    containerStacked: {},
    item: {
        marginLeft: '-27px',
        transition: theme.transitions.create(['margin'], {
            duration: theme.transitions.duration.shortest,
        }),
    },
    itemStacked: {},
    avatarButtonStacked: {
        zIndex: 1000,
    },
    display: {
        overflow: 'hidden',
    },

    icon: {
        fontSize: 18,
        transform: 'rotate(0deg)',
        transition: theme.transitions.create(['transform'], {
            duration: theme.transitions.duration.shortest,
            delay: theme.transitions.duration.shortest * 0.7,
        }),
    },
    iconLarge: {
        fontSize: 22,
    },
    iconClose: {
        transform: 'rotate(45deg)',
    },

    title: {
        display: 'block',
    },

    label: {
        cursor: 'pointer',
    },

    hoverExtra: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(1),
    },
    hoverExtraInner: {
        cursor: 'pointer',
    },
    hoverExtraIcon: {
        fontSize: 20,
        display: 'flex',
    },
}));

const DISPLAY_MODE = 'display';
const SEARCH_MODE = 'search';

const ResponsiblesSelect = ({
    name,
    contrast,
    onChange,
    mainName,
    max,
    type,
    disableMain,
    can,
    ...other
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [mode, setMode] = useState(DISPLAY_MODE);
    const [{ value: responsibleIds }, , { setValue: setResponsibleIds }] = useField(name);
    const [{ value: mainIdValues }, , { setValue: setMainIds }] = useField(mainName);
    const lastModeChange = useRef(null);
    const users = useSelector(selectUsersById);

    const mainIds = mainIdValues || [];

    const hasSelection = Array.isArray(responsibleIds) && responsibleIds.length > 0;

    const sortedResponsibleIds = useMemo(
        () =>
            disableMain
                ? responsibleIds
                : [...mainIds, ...responsibleIds.filter(id => !mainIds.includes(id))],
        [responsibleIds, mainIds]
    );

    const handleMode = nextMode => {
        const now = Date.now();

        if (
            !lastModeChange.current ||
            differenceInMilliseconds(now, lastModeChange.current) > 500
        ) {
            lastModeChange.current = now;
            setMode(nextMode);
        }
    };

    const handleSearch = () => {
        handleMode(mode === SEARCH_MODE ? DISPLAY_MODE : SEARCH_MODE);
    };

    const handleAdd = user => {
        handleMode(DISPLAY_MODE);
        if (user && !responsibleIds.includes(user.id)) {
            const newValue = [...responsibleIds, user.id];
            setResponsibleIds(newValue);

            if (newValue.length === 1) {
                setMainIds([user.id]);
            }

            onChange(newValue);
        }
    };

    const handleBlur = () => handleMode(DISPLAY_MODE);

    const handleAbort = useCallback(
        event => {
            if (mode === SEARCH_MODE && event.keyCode === 27) {
                handleMode(DISPLAY_MODE);
            }
        },
        [mode, handleMode]
    );

    const handleDelete = userId => {
        if (userId) {
            const newValue = responsibleIds.filter(responsibleId => responsibleId !== userId);
            setResponsibleIds(newValue);

            const newMain = mainIds.filter(mainId => mainId !== userId);
            if (newValue.length === 1 && newMain.length === 0) {
                newMain.push(newValue[0]);
            }
            setMainIds(newMain);

            onChange(newValue);
        }
    };

    const handleMain = userId => {
        if (mainIds.includes(userId)) {
            setMainIds([]);
        } else {
            setMainIds([userId]);
        }
        onChange();
    };

    useEffect(() => {
        document.addEventListener('keydown', handleAbort, true);
        return () => {
            document.removeEventListener('keydown', handleAbort, true);
        };
    }, [handleAbort]);

    return (
        <Grid container spacing={1} alignItems="center" className={classes.root}>
            <Grid data-test-id="add-responsible-btn" item>
                {hasSelection ? (
                    <Box>
                        <Avatars
                            userIds={sortedResponsibleIds}
                            mainIds={disableMain ? [] : mainIds}
                            dense
                            small
                            flipped
                            onDelete={handleDelete}
                            classes={{
                                root: classNames({
                                    [classes.container]: true,
                                    [classes.containerStacked]: mode === SEARCH_MODE,
                                }),
                                avatar: classNames({
                                    [classes.item]: true,
                                    [classes.itemStacked]: mode === SEARCH_MODE,
                                }),
                            }}
                            disablePortal
                            disableHover={mode === SEARCH_MODE}
                            popperPlacement="top"
                            type={type}
                            hoverExtras={
                                !disableMain
                                    ? responsibleIds.reduce((carry, userId) => {
                                          carry[userId] = (
                                              <Box className={classes.hoverExtra}>
                                                  <Grid
                                                      container
                                                      alignItems="center"
                                                      spacing={1}
                                                      className={classes.hoverExtraInner}
                                                      onClick={() => handleMain(userId)}
                                                  >
                                                      <Grid item>
                                                          <Icon
                                                              icon={
                                                                  mainIds.includes(userId)
                                                                      ? crownF
                                                                      : crown
                                                              }
                                                              className={classes.hoverExtraIcon}
                                                          />
                                                      </Grid>
                                                      <Grid item>
                                                          <Typography
                                                              variant="subtitle2"
                                                              color="textPrimary"
                                                          >
                                                              Hauptverantwortlich
                                                          </Typography>
                                                      </Grid>
                                                  </Grid>
                                              </Box>
                                          );
                                          return carry;
                                      }, {})
                                    : null
                            }
                        >
                            <IconButton
                                onClick={handleSearch}
                                className={classNames({
                                    [classes.button]: true,
                                    [classes.buttonMax]: max && responsibleIds.length >= max,
                                    [classes.contrast]: contrast,
                                    [classes.avatarButtonStacked]: mode === SEARCH_MODE,
                                })}
                                label={t(
                                    `form.ResponsiblesSelect.${
                                        mode === SEARCH_MODE ? 'cancel' : 'addResponsibles'
                                    }`
                                )}
                            >
                                <AddOutlinedIcon
                                    className={classNames({
                                        [classes.icon]: true,
                                        [classes.iconLarge]: true,
                                        [classes.iconClose]: mode === SEARCH_MODE,
                                    })}
                                />
                            </IconButton>
                        </Avatars>
                    </Box>
                ) : (
                    <IconButton
                        onClick={handleSearch}
                        className={classNames({
                            [classes.button]: true,
                            [classes.contrast]: contrast,
                        })}
                        label={t(
                            `form.ResponsiblesSelect.${
                                mode === SEARCH_MODE ? 'cancel' : 'addResponsibles'
                            }`
                        )}
                    >
                        <AddOutlinedIcon
                            className={classNames({
                                [classes.icon]: true,
                                [classes.iconClose]: mode === SEARCH_MODE,
                            })}
                        />
                    </IconButton>
                )}
            </Grid>
            <Grid data-test-id="display-responsible-user" item xs className={classes.display}>
                {mode === DISPLAY_MODE &&
                    (hasSelection ? (
                        <Box className={classes.title}>
                            <Typography variant="body2" noWrap>
                                {users[responsibleIds[0]] && users[responsibleIds[0]].display_name}
                            </Typography>
                        </Box>
                    ) : (
                        <Typography
                            variant="body2"
                            onClick={handleSearch}
                            className={classes.label}
                        >
                            Verantwortliche hinzufügen
                        </Typography>
                    ))}
                {mode === SEARCH_MODE && (
                    <Box>
                        <BaseUserAutocomplete
                            name="responsibles"
                            contrast={contrast}
                            className={classes.input}
                            can={can}
                            fullWidth
                            autofocus
                            open
                            onChange={handleAdd}
                            onBlur={handleBlur}
                            {...other}
                        />
                    </Box>
                )}
            </Grid>
        </Grid>
    );
};

ResponsiblesSelect.propTypes = {
    name: PropTypes.string.isRequired,
    mainName: PropTypes.string,
    contrast: PropTypes.bool,
    onChange: PropTypes.func,
    max: PropTypes.number,
    type: PropTypes.string,
    disableMain: PropTypes.bool,
    can: PropTypes.string,
};

ResponsiblesSelect.defaultProps = {
    mainName: null,
    contrast: false,
    onChange: () => null,
    max: null,
    type: RESPONSIBLE,
    disableMain: false,
    can: null,
};

export default ResponsiblesSelect;
