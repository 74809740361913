import { createSlice } from '@reduxjs/toolkit';
import { DIALOG_MAPPING } from '../../components/dialogs/dialogs';

export const DIALOG_STATE = 'dialogs';

const INITIAL_DIALOG_STATE = {
    type: null,
    props: {},
};

const dialogSlice = createSlice({
    name: DIALOG_STATE,
    initialState: INITIAL_DIALOG_STATE,
    reducers: {
        showDialog: (state, action) => {
            const { type, props } = action.payload;

            if (type in DIALOG_MAPPING) {
                const typeConfig = DIALOG_MAPPING[type];

                if (typeConfig.viaState) {
                    state.type = type;

                    if (props && typeof props === 'object') {
                        state.props = props;
                    }
                }
            }
        },
        closeDialog: state => {
            state.type = INITIAL_DIALOG_STATE.type;
            state.props = INITIAL_DIALOG_STATE.props;
        },
    },
});

export const { showDialog, closeDialog } = dialogSlice.actions;

export default dialogSlice.reducer;
