import React, { useEffect, useMemo, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { Save as SaveIcon } from '@mui/icons-material';
import PropTypes from 'prop-types';
import TextInput from '../form/formik/TextInput';
import { CardPropType } from '../../modules/cards/proptypes';
import CardStatusSelect from './CardStatusSelect';
import Avatars from '../icons/Avatars';
import CardHeaderContext from '../context/CardHeaderContext';
import logger from '../../logger';
import ResponsiblesSelect from '../form/ResponsiblesSelect';
import SubmitButton from '../form/formik/SubmitButton';
import CardIdentifiers from './CardIdentifiers';
import IconButton from '../form/base/IconButton';

const useStyles = makeStyles(theme => ({
    identifier: {
        marginBottom: theme.spacing(1),
        marginTop: -theme.spacing(1),
    },

    status: {
        width: 220,
        marginRight: -theme.spacing(1),
        position: 'relative',
        top: 4,
    },

    title: {
        ...theme.typography.h2,
    },

    titleSaveButton: {
        color: 'inherit',
        padding: 0,
    },
    belowClose: {
        marginTop: 32,
    },
}));

const CardTitleFormBody = ({ card, can }) => {
    const classes = useStyles();
    const { submitForm } = useFormikContext();
    const { t } = useTranslation();
    const [titleChanged, setTitleChanged] = useState(false);
    const [submitEnqueued, setSubmitEnqueued] = useState(false);

    const handleSubmit = useMemo(
        () => () => {
            submitForm().catch(err => {
                logger.error(err);
            });
        },
        [submitForm]
    );

    const handleChange = () => {
        setSubmitEnqueued(true);
    };

    const handleTitleChange = (key, value) => {
        if (card === null || key !== 'title') return;
        setTitleChanged(value !== card.title);
    };

    const handleTitleSubmit = () => {
        handleChange();
        setTitleChanged(false);
    };

    useEffect(() => {
        if (card === null || !submitEnqueued) {
            return;
        }

        setSubmitEnqueued(false);
        handleSubmit();
    }, [card, submitEnqueued, handleSubmit, setSubmitEnqueued]);

    return (
        <Grid container alignItems="center" spacing={3}>
            <Grid item className={classes.status}>
                <CardStatusSelect I={can} name="card_status_id" onChange={handleChange} contrast />
                <ResponsiblesSelect
                    name="responsibles"
                    mainName="responsibles_main"
                    onChange={handleChange}
                    contrast
                    max={5}
                    can={can}
                />
            </Grid>
            <Grid item xs>
                <Box className={classes.identifier}>
                    <CardIdentifiers
                        cardId={card && card.id}
                        onChange={handleChange}
                        variant="standard"
                        contrast
                        I={can}
                    />
                </Box>
                <Grid container>
                    <Grid item xs={11}>
                        <TextInput
                            name="title"
                            label={card ? '' : 'Aktentitel'}
                            tooltipLabel={t('components.Card.title')}
                            variant={card ? 'standard' : 'outlined'}
                            fullWidth
                            contrast
                            InputProps={{
                                className: classes.title,
                            }}
                            InputLabelProps={{
                                className: classes.title,
                            }}
                            onChange={handleTitleChange}
                            size="small"
                            can={can}
                        />
                    </Grid>

                    {titleChanged && (
                        <Grid item xs={1}>
                            <IconButton
                                onClick={handleTitleSubmit}
                                aria-label={t('form.SubmitButton.defaultLabel')}
                                data-test-id="TitleSaveButton"
                                color="default"
                                className={classes.titleSaveButton}
                                label={t('components.Card.saveTitle')}
                            >
                                <SaveIcon size="large" />
                            </IconButton>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Grid item xs={card !== null}>
                <CardHeaderContext cardId={card ? card.id : null} />
            </Grid>
            {card === null && (
                <Grid item xs style={{ marginTop: 'auto', marginBottom: 3 }}>
                    <Grid container alignItems="flex-end">
                        <Grid item>
                            <SubmitButton
                                color="primary"
                                contrast
                                label={t('form.SubmitButton.defaultLabel')}
                                variant="contained"
                                size="large"
                                data-test-id="CardSaveButton"
                                I={can}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <Grid item className={classes.belowClose}>
                <Avatars
                    userIds={(card && card.protocol_responsibles) || []}
                    dense
                    showSingleTooltip
                />
            </Grid>
        </Grid>
    );
};

CardTitleFormBody.propTypes = {
    card: CardPropType,
    can: PropTypes.string,
};

CardTitleFormBody.defaultProps = {
    card: null,
    can: null,
};

export default CardTitleFormBody;
