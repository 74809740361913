import React, { memo, useMemo, useState } from 'react';
import { Resources } from '@devexpress/dx-react-scheduler-material-ui';
import { useSelector } from 'react-redux';
import { selectAllAppointmentTypes } from '../../modules/appointmentTypes/appointmentTypeSlice';
import { useCalendar } from './CalendarContext';
import CalendarResourceLoader from './CalendarResourceLoader';
import { useCallbackFunc } from '../../modules/hooks';
import { EMPLOYEE_RESOURCE, RESOURCE_RESOURCE } from '../../modules/api/resources';
import { formatEmployeeForCalendar, formatResourceForCalendar } from '../../modules/calendar/utils';
import { useCalendarResourceBasicInstances } from '../../modules/calendar/hooks';

const formatInstances = (groupedInstances = null, group, allowMultiple = false) => {
    if (groupedInstances && group in groupedInstances) {
        const currentInstances = Object.values(groupedInstances[group]);

        if (Array.isArray(currentInstances)) {
            const formatted = currentInstances.map(item => {
                if (item.__type === EMPLOYEE_RESOURCE) {
                    return formatEmployeeForCalendar(item);
                }

                if (item.__type === RESOURCE_RESOURCE) {
                    return formatResourceForCalendar(item);
                }
            });

            return allowMultiple
                ? formatted
                : [{ id: null, text: 'Kein', color: '#ffffff' }, ...formatted]; //ToDo: Translate
        }
    }

    return [];
};

const formatResourceConfig = ({ allowMultiple, group, label, ...other }, groupedInstances = null) =>
    !groupedInstances ||
    (group in groupedInstances &&
        typeof groupedInstances[group] === 'object' &&
        Object.values(groupedInstances[group]).length !== 0)
        ? {
              fieldName: `resources.${group}`,
              title: label,
              instances: formatInstances(groupedInstances, group, allowMultiple),
              group,
              allowMultiple,
              load: {
                  api: null,
                  params: null,
                  ...other,
              },
          }
        : null;

const formatAppointmentType = ({ resource_config: config }, groupedInstances = null) => {
    if (!(config && Array.isArray(config))) {
        return [];
    }

    return config.reduce((carry, option) => {
        const formattedConfig = formatResourceConfig(option, groupedInstances);

        if (!formattedConfig) {
            return carry;
        }

        return [...carry, formattedConfig];
    }, []);
};

const CalendarResources = ({}) => {
    const { calendarId, selectedAppointmentTypeId } = useCalendar();
    const appointmentTypes = useSelector(selectAllAppointmentTypes);
    const [groupedInstances, setGroupedInstances] = useState({});

    const selectedAppointmentType = useMemo(() => {
        if (!selectedAppointmentTypeId) {
            return [];
        }

        return appointmentTypes.find(type => type.id === selectedAppointmentTypeId);
    }, [selectedAppointmentTypeId, appointmentTypes]);

    const resourceData = useMemo(
        () =>
            selectedAppointmentType && selectedAppointmentType.resource_config
                ? formatAppointmentType(selectedAppointmentType)
                : [],
        [selectedAppointmentType, formatAppointmentType]
    );

    const { instances: employeeBaseInstances } =
        useCalendarResourceBasicInstances(EMPLOYEE_RESOURCE);
    const { instances: resourceBaseInstances } =
        useCalendarResourceBasicInstances(RESOURCE_RESOURCE);

    const fullResourceData = useMemo(() => {
        const selectedTypeResources =
            selectedAppointmentType && selectedAppointmentType.resource_config
                ? formatAppointmentType(selectedAppointmentType, groupedInstances)
                : [];

        return [
            {
                fieldName: 'baseResources',
                title: ' ',
                allowMultiple: true,
                instances: [...employeeBaseInstances, ...resourceBaseInstances],
            },
            ...selectedTypeResources,
        ];
    }, [
        selectedAppointmentType,
        groupedInstances,
        employeeBaseInstances,
        resourceBaseInstances,
        formatAppointmentType,
    ]);

    const handleInstanceChange = useCallbackFunc((instance, group) => {
        setGroupedInstances(currentInstances => ({
            ...currentInstances,
            [group]: instance,
        }));
    });

    const loaders = useMemo(() => {
        return resourceData.map(resource => (
            <CalendarResourceLoader
                key={resource.group}
                listId={`Calendar.${calendarId}.${resource.group}`}
                type={resource.load.type}
                params={resource.load.params}
                onChange={list => handleInstanceChange(list, resource.group)}
            />
        ));
    }, [resourceData, handleInstanceChange]);

    return (
        <>
            <Resources data={fullResourceData} mainResourceName="resources.employees" />
            {loaders}
        </>
    );
};

CalendarResources.propTypes = {};

export default memo(CalendarResources);
