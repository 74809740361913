import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { IdPropType } from '../../../modules/proptypes';
import SubmitButton from '../../form/formik/SubmitButton';

const EmployeeActions = ({ mode, contactId, employeeId, userId }) => {
    const { t } = useTranslation();

    return (
        <Grid container justifyContent="flex-end" alignItems="center">
            <Grid item>
                <Grid container spacing={1}>
                    <Grid item>
                        <SubmitButton
                            data-test-id="save-all-contact-changes-btn"
                            variant="contained"
                            outsideForm
                        >
                            {t('form.SubmitButton.defaultLabel')}
                        </SubmitButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

EmployeeActions.propTypes = {
    mode: PropTypes.string,
    employeeId: IdPropType,
    contactId: IdPropType,
    userId: IdPropType,
};

EmployeeActions.defaultProps = {
    mode: 'contact',
    employeeId: null,
    contactId: null,
    userId: null,
};

export default EmployeeActions;
