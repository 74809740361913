export const formatNominatimAddress = nominatimOption => {
    if (nominatimOption?.address && nominatimOption.lat && nominatimOption.lon) {
        const { lat, lon, class: type, address } = nominatimOption;

        if (typeof address === 'object') {
            const {
                [type]: name,
                place,
                road,
                house_number,
                postcode,
                city,
                county,
                state,
                country,
            } = address;

            return {
                latitude: lat,
                longitude: lon,
                address1: road && house_number ? `${road} ${house_number}` : road,
                address2: name || place,
                zip: postcode,
                city: city || county || state,
                state,
                country,
            };
        }
    }

    return null;
};

export const getFullAddressText = ({ address1, address2 = null, zip = null, city = '' }) =>
    `${address1 ? `${address1}, ` : ''}${address2 ? `${address2}, ` : ''}${zip ? `${zip} ` : ''}${
        city || ''
    }`;
