import React from 'react';
import * as PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    root: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    sidebar: {
        width: 220,
    },
    tabActions: {
        display: 'flex',
        justifyContent: 'space-between',
        width: 'calc(100% - 220px)',
    },
    link: {
        color: 'inherit',
        textDecoration: 'none',
    },
});

const EmployeeDialogActions = ({ children }) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.root}>
            <Grid item className={classes.sidebar} />
            <Grid item className={classes.tabActions}>
                {children}
            </Grid>
        </Grid>
    );
};

EmployeeDialogActions.propTypes = {
    children: PropTypes.node,
};

EmployeeDialogActions.defaultProps = {
    children: null,
};

export default EmployeeDialogActions;
