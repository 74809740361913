import React, { useEffect, useCallback, useState } from 'react';
import { useFormikContext } from 'formik';
import * as PropTypes from 'prop-types';

/**
 * AutoSave - Save a Formik Form by Event
 * To be placed inside a Formik Form
 * Feel free to add new modes to increase its strength
 *
 * --- Modes ---
 * UNMOUNT - AutoSave when leaving component
 */

export const AutoSaveModes = {
    UNMOUNT: 'unmount',
};

const AutoSave = ({ suppress, modes }) => {
    const { values, submitForm } = useFormikContext();
    const [triggered, setTriggered] = useState(false);

    const suppressCallback = useCallback(() => {
        if (!suppress(values)) {
            if (!triggered) {
                setTriggered(true);
                submitForm();
            }
        }
    }, [suppress, values, triggered, submitForm]);

    useEffect(() => {
        return () => {
            if (suppress === true) {
                return false;
            }
            if (modes.includes(AutoSaveModes.UNMOUNT)) {
                if (typeof suppress === 'function') {
                    return suppressCallback();
                }
                if (!triggered) {
                    setTriggered(true);
                    return submitForm();
                }
            }
            return null;
        };
        // eslint-disable-next-line
    }, []);

    return <></>;
};

AutoSave.propTypes = {
    suppress: PropTypes.bool,
    modes: PropTypes.arrayOf(PropTypes.string),
};

AutoSave.defaultProps = {
    suppress: false,
    modes: [AutoSaveModes.UNMOUNT],
};

export default AutoSave;
