import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { Portal } from '@mui/material';
import LoadingButton from '../../loading/LoadingButton';
import { ConditionalWrapper } from '../../utils/ConditionalWrapper';
import Button from '../base/Button';
import { RefPropType } from '../../../modules/proptypes';
import { useContextualCan } from '../../../modules/abilities/hooks';
import { WRITE } from '../../../modules/abilities/actions';

const SubmitButton = ({
    label,
    innerRef,
    alwaysEnabled,
    disabled,
    container,
    onClick,
    I,
    ...other
}) => {
    const { t } = useTranslation();
    const { dirty, isSubmitting, submitForm } = useFormikContext();
    const canDo = useContextualCan(I ? I : WRITE);

    const handleClick = event => {
        event.preventDefault();

        return Promise.resolve(onClick(event)).then(() => {
            /*
             * the submit button is not guaranteed to be a child of the form element in the dom tree
             * -> call submitForm explicitly
             */
            return submitForm();
        });
    };

    return (
        <ConditionalWrapper
            wrapper={children => (
                <>
                    {/* render another button that reacts when the enter key is pressed */}
                    <Button type="submit" onClick={handleClick} disabled={disabled} hidden>
                        {label || t('form.SubmitButton.defaultLabel')}
                    </Button>
                    <Portal container={container.current}>{children}</Portal>
                </>
            )}
            condition={!!container}
        >
            <LoadingButton
                type="submit"
                size="small"
                color="primary"
                disabled={disabled || (!dirty && !alwaysEnabled) || !canDo}
                isLoading={isSubmitting}
                onClick={handleClick}
                ref={innerRef}
                {...other}
            >
                {label || t('form.SubmitButton.defaultLabel')}
            </LoadingButton>
        </ConditionalWrapper>
    );
};

SubmitButton.propTypes = {
    label: PropTypes.string,
    innerRef: PropTypes.shape({ current: PropTypes.instanceOf(LoadingButton) }),
    color: PropTypes.string,
    onClick: PropTypes.func,
    alwaysEnabled: PropTypes.bool,
    disabled: PropTypes.bool,
    container: RefPropType,
    I: PropTypes.string,
};

SubmitButton.defaultProps = {
    label: null,
    innerRef: null,
    color: 'primary',
    onClick: () => null,
    alwaysEnabled: false,
    disabled: false,
    container: null,
    I: null,
};

export default SubmitButton;
