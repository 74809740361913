/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { COMPANY_RESOURCE } from '../api/resources';

const insertCompany = (state, company) => {
    const stale = state.byId[company.id];

    if (!stale) {
        state.allIds.push(company.id);
    }

    state.byId[company.id] = {
        ...company,
    };
};

const companySlice = createSlice({
    name: COMPANY_RESOURCE,
    initialState: {
        byId: {},
        allIds: [],
    },
    reducers: {
        indexFulfilled: {
            prepare: (payload, meta) => ({ payload, meta }),
            reducer: (state, action) => {
                action.payload.forEach(type => {
                    insertCompany(state, type);
                });
            },
        },
        showFulfilled: {
            prepare: (payload, meta) => ({ payload, meta }),
            reducer: (state, action) => {
                insertCompany(state, action.payload);
            },
        },
        storeFulfilled: (state, action) => {
            insertCompany(state, action.payload);
        },
        updateFulfilled: (state, action) => {
            insertCompany(state, action.payload);
        },
        destroyFulfilled: (state, action) => {
            const id = action.payload;

            const index = state.allIds.findIndex(_id => _id === id);
            if (index >= 0) {
                delete state.byId[id];
                state.allIds.splice(index, 1);
            }
        },
    },
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,
} = companySlice.actions;

export default companySlice.reducer;
