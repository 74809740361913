/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { ROLE_RESOURCE } from '../api/resources';

const insertRole = (state, role) => {
    const stale = state.byId[role.id];

    if (!stale) {
        state.allIds.push(role.id);
    }

    state.byId[role.id] = {
        ...role,
    };
};

const roleSlice = createSlice({
    name: ROLE_RESOURCE,
    initialState: {
        byId: {},
        allIds: [],
    },
    reducers: {
        indexFulfilled: {
            prepare: (payload, meta) => ({ payload, meta }),
            reducer: (state, action) => {
                action.payload.forEach(type => {
                    insertRole(state, type);
                });
            },
        },
        showFulfilled: {
            prepare: (payload, meta) => ({ payload, meta }),
            reducer: (state, action) => {
                insertRole(state, action.payload);
            },
        },
        storeFulfilled: (state, action) => {
            insertRole(state, action.payload);
        },
        updateFulfilled: (state, action) => {
            insertRole(state, action.payload);
        },
        destroyFulfilled: (state, action) => {
            const id = action.payload;

            const index = state.allIds.findIndex(_id => _id === id);
            if (index >= 0) {
                delete state.byId[id];
                state.allIds.splice(index, 1);
            }
        },
    },
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,
} = roleSlice.actions;

export default roleSlice.reducer;
