import React, { useRef } from 'react';
import * as PropTypes from 'prop-types';
import AddressInput from './AddressInput';
import FallbackValue from '../form/formik/FallbackValue';
import AddressAutocomplete from './AddressAutocomplete';
import EditIconButton from '../buttons/EditIconButton';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
    },
    popupAnchor: {
        position: 'absolute',
        height: 30,
        width: 30,
        top: 'calc(50% - 15px)',
        right: 36,
    },
}));

const AddressSearchInput = ({ namePrefix, label }) => {
    const classes = useStyles();
    const popupState = useRef();
    const popupAnchorRef = useRef();

    const showForm = anchor => popupState.current?.open(anchor || popupAnchorRef.current);
    const hideForm = () => popupState.current?.close(null);

    const handleSelect = address => {
        if (address) {
            showForm();
        }
    };

    return (
        <Box className={classes.root}>
            <Box className={classes.popupAnchor} ref={popupAnchorRef} />
            <AddressInput
                namePrefix={namePrefix}
                popupStateRef={popupState}
                onOpen={() => null}
                onAbort={() => hideForm()}
                InputElement={({ onClear }) => (
                    <FallbackValue
                        name="address"
                        fallbackName="card.address.full"
                        FormComponent={({ hideLabel }) => (
                            <AddressAutocomplete
                                name="address"
                                label={label}
                                InputLabelProps={hideLabel ? { shrink: true } : null}
                                onChange={handleSelect}
                                onClose={(event, reason) =>
                                    reason === 'clear' && onClear ? onClear() : null
                                }
                                extraButton={<EditIconButton onClick={showForm} size="small" />}
                                size="small"
                                fullWidth
                            />
                        )}
                    />
                )}
            />
        </Box>
    );
};

AddressSearchInput.propTypes = {
    namePrefix: PropTypes.string,
    label: PropTypes.string,
};

AddressSearchInput.defaultProps = {
    namePrefix: null,
    label: null,
};

export default AddressSearchInput;
