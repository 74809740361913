import { RESOURCE_CLASS_RESOURCE } from '../api/resources';

export const selectResourceClassesById = state => state[RESOURCE_CLASS_RESOURCE].byId;

export const selectAllResourceClassIds = state => state[RESOURCE_CLASS_RESOURCE].allIds;

export const selectResourceClassById = (state, id) => selectResourceClassesById(state)[id];

export const selectAllResourceClasses = state => Object.values(selectResourceClassesById(state));

export const selectResourceClassesInitialized = state => state[RESOURCE_CLASS_RESOURCE].initialized;

export const selectResourceClassesLoading = state => state[RESOURCE_CLASS_RESOURCE].loading;
