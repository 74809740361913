import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import GroupLoader from '../aggregates/GroupLoader';
import { useTranslation } from 'react-i18next';

const WrapperCell = ({ label, depth = 1, children }) => (
    <Box
        style={{
            display: 'inline-flex',
            width: `calc(100% - ${depth * 40}px)`,
            height: '100%',
            verticalAlign: 'middle',
        }}
    >
        {label ? (
            <Typography component="span" noWrap>
                {label}
            </Typography>
        ) : null}
        {children || null}
    </Box>
);

const ResourceTableGroupedCell = ({
    listId,
    groupKeys,
    columns,
    row,
    table,
    hidden: overrideHidden,
}) => {
    const { t } = useTranslation();
    const groupKey = useMemo(() => groupKeys.join(), [groupKeys]);

    const columnConfigByGroupKeys = useMemo(() => {
        return columns.reduce((carry, column) => {
            const targetKey = column.groupConfig?.key || column.id;
            if (groupKeys.includes(targetKey)) {
                return {
                    ...carry,
                    [targetKey]: column,
                };
            }

            return carry;
        }, {});
    }, [groupKeys, columns]);

    const { Cell, column, groupNestedListKey, label, params, depth, loading, hidden, isUnknown } =
        useMemo(() => {
            if (typeof row?.original === 'object') {
                if (overrideHidden || (!row?.original?.key && !row?.original?.loading)) {
                    return { hidden: true };
                }

                const { key, groupNestedListKey, label, params, loading } = row.original;
                const depth = typeof key === 'string' ? key.split(',').length : 1;

                const columnKey = groupKeys[depth - 1];
                const config = columnConfigByGroupKeys[columnKey];

                const baseValues = {
                    groupNestedListKey,
                    label,
                    params,
                    depth,
                    loading,
                };

                if (
                    config?.enableGroupedCell &&
                    config &&
                    (label || config?.enableGroupedCellWhenUnknown)
                ) {
                    return {
                        Cell: config.Cell,
                        column: { columnDef: config },
                        ...baseValues,
                    };
                }

                if (label || loading) {
                    return baseValues;
                }
            }

            return { isUnknown: true };
        }, [row, overrideHidden, columnConfigByGroupKeys]);

    if (hidden) {
        return null;
    }

    if (!isUnknown) {
        if (loading && typeof params === 'object') {
            return (
                <GroupLoader
                    listId={listId}
                    groupKey={groupKey}
                    groupNestedListKey={groupNestedListKey}
                    params={params}
                />
            );
        }

        if (Cell) {
            return (
                <WrapperCell depth={depth}>
                    <Cell renderedCellValue={label} table={table} column={column} row={row} />
                </WrapperCell>
            );
        }

        if (label) {
            return <WrapperCell label={label} depth={depth} />;
        }
    }

    return <WrapperCell label={t('components.ResourceTable.Grouped.unknown')} />;
};

ResourceTableGroupedCell.propTypes = {
    listId: PropTypes.string.isRequired,
    groupKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
    columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    row: PropTypes.shape({
        original: PropTypes.shape({}),
    }),
    table: PropTypes.shape({}),
    hidden: PropTypes.bool,
    onLoad: PropTypes.func,
};

ResourceTableGroupedCell.defaultProps = {
    row: null,
    table: null,
    hidden: false,
    onLoad: null,
};

export default ResourceTableGroupedCell;
