import { CONTACT_RESOURCE } from '../api/resources';
import { selectPersonById } from '../persons/selectors';

export const selectContactsIsLoading = state => state[CONTACT_RESOURCE].loading;

export const selectContactsById = state => state[CONTACT_RESOURCE].byId;

export const selectContactById = (state, id) => selectContactsById(state)[id];

export const selectContactByIds = (state, ids) => ids.map(id => selectContactById(state, id));

export const selectAllContactIds = state => state[CONTACT_RESOURCE].allIds;

export const selectContactWithRelatedByIds = (state, ids) => {
    const contacts = selectContactByIds(state, ids);
    return contacts.map(contact => ({
        ...contact,
        person: selectPersonById(state, contact.personId),
    }));
};
