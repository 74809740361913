import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { IdPropType } from '../../../modules/proptypes';
import Form from '../../form/formik/Form';
import TextInput from '../../form/formik/TextInput';
import SubmitButton from '../../form/formik/SubmitButton';
import { updateUser } from '../../../modules/users/actions';
import { useSelector } from 'react-redux';
import { selectUserById } from '../../../modules/users/selectors';
import { kumoConnectSchema } from '../../../modules/users/schema';
import { isEqual } from 'lodash';
import classNames from 'classnames';
import Link from '../../utils/Link';
import { useApi } from '../../../modules/api/ApiProvider';
import LoadingBox from '../../loading/LoadingBox';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    item: {
        flex: 1,
        flexShrink: 0,
    },
    h3: {
        color: theme.palette.primary.main,
        paddingBottom: theme.spacing(4),
        fontSize: '1rem',
    },
    h6: {
        color: theme.palette.grey[700],
        fontSize: '.875rem',
        fontWeight: 'bold',
    },
    errorStatus: {
        backgroundColor: theme.palette.background.error,
    },
    successStatus: {
        backgroundColor: '#e8f5e9',
    },
}));

const EmployeeKumoConnect = ({ userId }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const classes = useStyles();
    const { kumoConnect } = useApi();

    const [serverStatus, setServerStatus] = useState(null);
    const user = useSelector(state => selectUserById(state, userId));
    const [loading, setLoading] = useState(false);

    const initialValues = useMemo(
        () => ({
            ip: user && user.kumo_connect ? user.kumo_connect.ip || '...' : '...',
            port: user && user.kumo_connect ? user.kumo_connect.port : null,
        }),
        [user]
    );

    const handleSubmit = values => {
        const newValue = {
            ...values,
            ip: values.ip === '...' ? null : values.ip,
            port: values.port === '' ? null : values.port,
        };
        if (!isEqual(user?.kumo_connect, newValue))
            dispatch(updateUser({ id: userId, kumo_connect: newValue }));
    };

    useEffect(() => {
        if (
            user &&
            user.kumo_connect &&
            user.kumo_connect.ip &&
            user.kumo_connect.port &&
            kumoConnect
        ) {
            setLoading(true);
            kumoConnect
                .initKumoConnectStatus(user.kumo_connect)
                .then(response => setServerStatus(response.data))
                .catch(err =>
                    setServerStatus({
                        version: { label: 'Version', value: 'UNKNOWN' },
                        path: { status: 'error', msg: err.message },
                    })
                )
                .finally(() => setLoading(false));
        } else {
            if (user && user.kumo_connect && kumoConnect)
                kumoConnect.initKumoConnectStatus(user.kumo_connect);
            setServerStatus(null);
            setLoading(false);
        }
    }, [user]);

    return (
        <Grid container className={classes.root} spacing={2}>
            <Grid item className={classes.item}>
                <Typography variant="h3" className={classes.h3}>
                    {t('components.EmployeeDialog.kumoConnect')}
                </Typography>

                <Form
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={kumoConnectSchema}
                    enableReinitialize
                >
                    <Grid container spacing={2} alignItems="center">
                        <Grid item sm={7}>
                            <TextInput name="ip" label="IP" fullWidth type="ip" size="small" />
                        </Grid>
                        <Grid item sm={3}>
                            <TextInput
                                name="port"
                                label="Port"
                                fullWidth
                                type="number"
                                forceInputType
                                inputProps={{
                                    min: 0,
                                    max: 65535,
                                    clampValueOnBlur: true,
                                    precision: 0,
                                    type: 'number',
                                }}
                                size="small"
                            />
                        </Grid>
                        <Grid item sm={2}>
                            <SubmitButton variant="contained">
                                {t('form.SubmitButton.defaultLabel')}
                            </SubmitButton>
                        </Grid>
                    </Grid>
                </Form>
                <LoadingBox loading={loading}>
                    {serverStatus && (
                        <Grid
                            container
                            mt={3}
                            mb={3}
                            className={classNames({
                                [classes.successStatus]: serverStatus.path.status === 'OK',
                                [classes.errorStatus]: serverStatus.path.status !== 'OK',
                            })}
                        >
                            <Typography variant="h6" className={classes.h6}>
                                Server status
                            </Typography>
                            <Grid container>
                                <Grid item sm={12}>
                                    <Typography variant="subtitle1">
                                        {`${serverStatus.version.label}: ${serverStatus.version.value}`}
                                    </Typography>
                                </Grid>
                                <Grid item sm={12}>
                                    <Typography variant="subtitle1">
                                        Status:{' '}
                                        {serverStatus.path.status === 'OK'
                                            ? '✅'
                                            : `❌ ${serverStatus.path.msg}`}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </LoadingBox>
                <Link
                    to="/kumo_connect/latest/setup.exe"
                    external
                    variant="body1"
                    color="primary"
                    icon
                >
                    KumoConnect
                </Link>
            </Grid>
        </Grid>
    );
};

EmployeeKumoConnect.propTypes = {
    userId: IdPropType.isRequired,
};

export default EmployeeKumoConnect;
