import axios from 'axios';

axios.defaults.withCredentials = true;

const getCSRFCookie = () =>
    axios({
        method: 'get',
        url: '/csrf-cookie',
        baseURL: process.env.REACT_APP_API_SANCTUM,
    });

const getAuthenticated = ({ xAuthToken } = {}) => {
    return axios({
        method: 'get',
        url: '/users/me',
        baseURL: process.env.REACT_APP_API_ROOT,
        ...(xAuthToken && { headers: { 'X-Auth-Token': xAuthToken } }),
    }).then(response => response.data.data);
};

const authenticate = data =>
    data
        ? getCSRFCookie().then(() => {
              const { xAuthToken, ...loginData } = data;
              return axios({
                  method: 'post',
                  url: '/login',
                  baseURL: process.env.REACT_APP_API_AUTH,
                  ...(xAuthToken && { headers: { 'X-Auth-Token': xAuthToken } }),
                  data: loginData,
              })
                  .then(_ => getAuthenticated({ xAuthToken }))
                  .catch(error => {
                      if (error.response && error.response.data) {
                          throw error.response.data;
                      }
                      throw error;
                  });
          })
        : getAuthenticated();

const logout = () =>
    getCSRFCookie().then(() =>
        axios({
            method: 'post',
            url: '/logout',
            baseURL: process.env.REACT_APP_API_AUTH,
        })
    );

export const attachAuthentication = api => {
    api.onAuthenticate(authenticate);
    api.setGetAuthenticatedHandler(getAuthenticated);
    api.onLogout(logout);
};
