import { put, takeEvery } from 'redux-saga/effects';
import {
    ADDRESS_RESOURCE,
    COMPANY_RESOURCE,
    CONTACT_DATA_RESOURCE,
    CONTACT_RESOURCE,
    CONTACTABLE_RESOURCE,
    PERSON_RESOURCE,
} from '../api/resources';
import { indexFulfilled as contactableIndexFulfilled } from '../contactables/contactablesSlice';
import {
    indexFulfilled as companyIndexFulfilled,
    showFulfilled as companyShowFulfilled,
} from '../companies/companySlice';
import {
    indexFulfilled as personIndexFulfilled,
    showFulfilled as personShowFulfilled,
} from '../persons/personsSlice';
import { indexFulfilled as contactDataIndexFulfilled } from '../contactData/contactDataSlice';
import { indexFulfilled as addressIndexFulfilled } from '../addresses/addressSlice';

function* nestedIndexFulfilled(action) {
    const payload = action.payload.reduce(
        (carry, { contactables, company, person, addresses, contact_data: contactData }) => {
            if (contactables) {
                contactables.forEach(contactable => {
                    carry.contactables.push(contactable);
                });
            }
            if (company) {
                carry.companies.push({ ...company });
            }
            if (person) {
                carry.persons.push({ ...person });
            }
            if (contactData) {
                carry.contactData.push(...contactData);
            }
            if (addresses) {
                carry.addresses.push(...addresses);
            }

            return carry;
        },
        { contactables: [], companies: [], persons: [], contactData: [], addresses: [] }
    );

    yield put(
        contactableIndexFulfilled(
            { payload: payload.contactables, meta: action.meta },
            { key: CONTACTABLE_RESOURCE }
        )
    );
    yield put(companyIndexFulfilled(payload.companies, { key: COMPANY_RESOURCE }));
    yield put(personIndexFulfilled(payload.persons, { key: PERSON_RESOURCE }));
    yield put(contactDataIndexFulfilled(payload.contactData, { key: CONTACT_DATA_RESOURCE }));
    yield put(addressIndexFulfilled(payload.addresses, { key: ADDRESS_RESOURCE }));
}

function* nestedShowFulfilled(action) {
    const {
        contactables,
        company,
        person,
        addresses,
        contact_data: contactData,
        id: contactId,
    } = action.payload;

    if (contactables) {
        yield put(
            contactableIndexFulfilled(
                { payload: contactables, meta: action.meta },
                { key: CONTACTABLE_RESOURCE }
            )
        );
    }

    if (addresses.length !== 0) {
        yield put(
            addressIndexFulfilled(
                addresses.map(address => ({ ...address, contactId })),
                { key: ADDRESS_RESOURCE }
            )
        );
    }

    if (company) {
        yield put(companyShowFulfilled(company, { key: COMPANY_RESOURCE }));
    }

    if (person) {
        yield put(personShowFulfilled(person, { key: PERSON_RESOURCE }));
    }

    if (contactData) {
        yield put(contactDataIndexFulfilled(contactData, { key: CONTACT_DATA_RESOURCE }));
    }
}

export default [
    takeEvery(`${CONTACT_RESOURCE}/indexFulfilled`, nestedIndexFulfilled),
    takeEvery(`${CONTACT_RESOURCE}/searchFulfilled`, nestedIndexFulfilled),
    takeEvery(`${CONTACT_RESOURCE}/showFulfilled`, nestedShowFulfilled),
];
