import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useResourceList } from '../../modules/lists/hooks';
import { PROTOCOL_ITEM_RESOURCE } from '../../modules/api/resources';
import ResourceWaypoint from '../table/resource/ResourceWaypoint';
import LoadingBox from '../loading/LoadingBox';
import ProtocolItem from '../protocol/ProtocolItem';
import { IdPropType } from '../../modules/proptypes';
import { useDispatch, useSelector } from 'react-redux';
import { indexCards } from '../../modules/cards/actions';
import { selectContactableCardIdsByContactId } from '../../modules/contactables/selectors';

const useStyles = makeStyles(theme => ({
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    empty: {
        color: theme.palette.text.hint,
        textAlign: 'center',
        margin: theme.spacing(2),
    },
}));

const ContactLinkedProtocols = ({ contactId }) => {
    const listId = `contactProtocols.${contactId}`;
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const contactableCardIds = useSelector(state =>
        selectContactableCardIdsByContactId(state, contactId)
    );

    const { dataIds, loading, handlePage } = useResourceList({
        listId,
        index: true,
        continuous: true,
        limit: 10,
        resource: PROTOCOL_ITEM_RESOURCE,
        fetchParams: { contact_id: contactId },
        with: ['ProtocolItem.contacts'],
    });

    useEffect(() => {
        handlePage(null, 1, true);
    }, [contactId, handlePage]);

    useEffect(() => {
        dispatch(indexCards({ ids: contactableCardIds }));
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {dataIds.map((protocolItemId, index) => (
                <Box key={protocolItemId}>
                    <ProtocolItem
                        id={protocolItemId}
                        onEdit={() => {}}
                        key={protocolItemId}
                        readOnly
                        enableNavigate
                    />
                    {index + 1 < dataIds.length && <Divider className={classes.divider} />}
                </Box>
            ))}
            {dataIds.length === 0 && (
                <Typography className={classes.empty}>
                    {t('components.ProtocolItem.empty')}
                </Typography>
            )}
            <LoadingBox loading={loading} />
            <ResourceWaypoint onEnter={() => {}} listId={listId} />
        </>
    );
};

ContactLinkedProtocols.propTypes = {
    contactId: IdPropType.isRequired,
};

export default ContactLinkedProtocols;
