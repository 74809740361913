import React, { forwardRef, memo, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { JOURNAL_RESOURCE } from '../../modules/api/resources';
import ResourceTable from '../resourceTable/ResourceTable';
import { useCallbackFunc } from '../../modules/hooks';
import { selectTaskTypesById } from '../../modules/taskTypes/selectors';
import { selectUserById, selectUsersById } from '../../modules/users/selectors';
import { useTaskTypes } from '../../modules/taskTypes/taskTypesSlice';
import { Grid } from '@mui/material';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import { useDispatch, useSelector } from 'react-redux';
import TaskType from '../taskTypes/TaskType';
import {
    COLUMN_TYPE_CONFIG,
    COLUMN_TYPES,
    getColumnName,
} from '../../modules/resourceTable/columnTypes';
import { useTranslation } from 'react-i18next';
import TaskTypeSelect from '../form/formik/TaskTypeSelect';
import TaskSelect from '../form/formik/TaskSelect';
import { archiveJournal, updateJournal } from '../../modules/journals/journalsSlice';
import Duration from '../utils/Duration';
import IdentifierAutocomplete from '../form/formik/autocomplete/IdentifierAutocomplete';
import { IDENTIFIER_TYPES } from '../../modules/datacontainers/utils';
import CreateResourceButton from '../table/resource/CreateResourceButton';
import { showDialog } from '../../modules/dialogs/dialogSlice';
import { JOURNAL_DIALOG } from '../dialogs/dialogs';
import { formatJournalSubmit } from '../../modules/journals/utils';
import JournalMoveForm from './JournalMoveForm';
import MenuItem from '../menu/MenuItem';
import { ReferenceIdPropType } from '../../modules/proptypes';
import Button from '../form/base/Button';
import DurationInput from '../form/formik/DurationInput';
import FallbackValue from '../form/formik/FallbackValue';
import { getJournalSchema } from '../../modules/journals/schema';
import ResourceSearch from '../search/ResourceSearch';
import ValueChangeAutofocus from '../form/formik/ValueChangeAutofocus';
import WatchContainer from '../form/formik/WatchContainer';
import AppointmentSelect from '../form/formik/AppointmentSelect';
import { UPDATE } from '../../modules/abilities/actions';
import CardAutocomplete from '../form/formik/autocomplete/CardAutocomplete';
import { selectCardById, selectCardsById } from '../../modules/cards/selectors';
import { AGGREGATION_TYPE } from '../../modules/aggregation/utils';
import { formatDurationText } from '../../modules/datetime/utils';
import PrintIconButton from '../buttons/PrintIconButton';
import ResourceTablePrint from '../resourceTable/ResourceTablePrint';
import { selectPersonById } from '../../modules/persons/personsSlice';

const getJournalColumns = (referenceId, t) => [
    {
        id: 'startedAt',
        accessorKey: 'started_at',
        type: COLUMN_TYPES.DATETIME_OPTIONAL,
    },
    {
        accessorKey: 'duration',
        type: COLUMN_TYPES.DURATION,
        aggregationConfig: {
            type: AGGREGATION_TYPE.SUM,
        },
    },
    {
        id: 'durationNightShift',
        accessorKey: 'duration_night_shift',
        type: COLUMN_TYPES.DURATION,
        aggregationConfig: {
            type: AGGREGATION_TYPE.SUM,
        },
        readOnly: true,
    },
    ...(referenceId.key !== 'card_id'
        ? [
              {
                  id: 'card',
                  accessorKey: 'card.title',
                  groupConfig: true,
                  Edit: ({ column }) => {
                      const columnProps = column.columnDef;
                      return (
                          <CardAutocomplete
                              name="card_id"
                              label={columnProps.header}
                              size="small"
                              showInitialState
                              fullWidth
                          />
                      );
                  },
              },
              {
                  id: 'id_offer',
                  accessorKey: 'card.id_offer',
                  readOnly: true,
                  groupConfig: {
                      field: 'card.id_offer',
                      label: 'card.id_offer',
                      order: 'card.id_offer',
                  },
              },
              {
                  id: 'id_order',
                  accessorKey: 'card.id_order',
                  readOnly: true,
                  groupConfig: {
                      field: 'card.id_order',
                      label: 'card.id_order',
                      order: 'card.id_order',
                  },
              },
              {
                  id: 'address',
                  accessorKey: 'card.location',
                  readOnly: true,
                  groupConfig: {
                      field: 'card.location',
                      label: 'card.location',
                      order: 'card.location',
                  },
              },
          ]
        : []),
    {
        id: 'task',
        accessorKey: 'task_id',
        valueKey: 'task_name',
        groupConfig: true,
        Edit: ({ column }) => {
            const columnProps = column.columnDef;
            return (
                <WatchContainer
                    name="card_id"
                    WatchComponent={({ value }) => (
                        <TaskSelect
                            name="task_id"
                            label={columnProps.header}
                            referenceId={{ value, key: 'card_id' }}
                            size="small"
                            fullWidth
                        />
                    )}
                />
            );
        },
    },
    {
        id: 'appointment',
        accessorKey: 'appointment_id',
        valueKey: 'appointment_name',
        groupConfig: {
            label: 'appointment.name',
            order: 'appointment.name',
        },
        Edit: ({ column }) => {
            const columnProps = column.columnDef;
            return (
                <WatchContainer
                    name="task_id"
                    WatchComponent={({ value }) => (
                        <AppointmentSelect
                            name="appointment_id"
                            namePreventReset="id"
                            label={columnProps.header}
                            taskId={value}
                            can={UPDATE}
                            fullWidth
                        />
                    )}
                />
            );
        },
    },
    {
        id: 'taskType',
        accessorKey: 'task_type.name',
        groupConfig: {
            key: 'task_type',
        },
        Cell: ({ row }) => <TaskType typeId={row.original?.task_type_id} />,
        Edit: ({ column }) => {
            const columnProps = column.columnDef;
            return (
                <TaskTypeSelect
                    name="task_type_id"
                    label={columnProps.header}
                    size="small"
                    fullWidth
                />
            );
        },
    },
    { accessorKey: 'description', type: COLUMN_TYPES.TEXT_MULTI },
    ...(referenceId.key !== 'user_id'
        ? [
              {
                  id: 'user',
                  valueKey: 'user_id',
                  accessorKey: 'user.display_name',
                  apiKey: 'user.display_name',
                  type: COLUMN_TYPES.USER,
                  groupConfig: true,
              },
          ]
        : []),
    {
        id: 'durationBillable',
        accessorKey: 'duration_billable',
        type: COLUMN_TYPES.DURATION,
        aggregationConfig: {
            type: AGGREGATION_TYPE.SUM_NOTNULL,
            fields: ['duration_billable', 'duration'],
        },
        forcePrintString: true,
        convertString: (value, row) =>
            row ? formatDurationText(row.duration_billable || row.duration, true) : null,
        Cell: ({ row }) => (
            <Duration
                durationValue={row.original.duration_billable || row.original.duration}
                color={row.original.duration_billable ? 'textPrimary' : 'textSecondary'}
                asDecimal
            />
        ),
        Edit: ({ column }) => {
            const columnProps = column.columnDef;
            const name = getColumnName(columnProps);

            return (
                <FallbackValue
                    name={name}
                    fallbackName="duration"
                    applyOnClick
                    FallbackComponent={({ fallback }) =>
                        typeof fallback === 'string' ? (
                            fallback
                        ) : (
                            <Duration durationValue={fallback} />
                        )
                    }
                    FormComponent={forwardRef(({ hideLabel }, ref) => (
                        <DurationInput
                            name={name}
                            label={columnProps.header}
                            size="small"
                            shrink={hideLabel ? true : undefined}
                            ref={ref}
                            fullWidth
                        />
                    ))}
                />
            );
        },
    },
    {
        id: 'billed',
        accessorKey: 'billed_at',
        type: COLUMN_TYPES.CHECKBOX_DATE,
        aggregationConfig: {
            type: AGGREGATION_TYPE.SUM,
            fields: ['duration_unbilled'],
            alias: 'duration_unbilled',
            convertString: value =>
                `${formatDurationText(value, true)} ${t(
                    'components.ResourceTable.JournalTable.notBilled'
                )}`,
        },
        groupConfig: {
            field: 'billed_by',
            label: 'billedBy.display_name',
            order: 'billedBy.display_name',
        },
        ...COLUMN_TYPE_CONFIG[COLUMN_TYPES.USER_DATE],
        readOnly: false,
    },
    {
        id: 'invoice',
        accessorKey: 'id_invoice',
        groupConfig: {
            field: 'invoice_id',
            label: 'id_invoice',
            order: 'id_invoice',
        },
        Edit: ({ column }) => {
            const columnProps = column.columnDef;

            return (
                <Grid container alignItems="center" minWidth={180}>
                    <ValueChangeAutofocus
                        name="billed_at"
                        FocusComponent={({ focusRef }) => (
                            <IdentifierAutocomplete
                                label={columnProps.header}
                                name="id_invoice"
                                fetchParams={
                                    referenceId ? { [referenceId.key]: referenceId.value } : null
                                }
                                type={IDENTIFIER_TYPES.INVOICE}
                                size="small"
                                confirmRemove
                                fullWidth
                                inputRef={focusRef}
                            />
                        )}
                    />
                </Grid>
            );
        },
    },
    {
        id: 'transportKm',
        accessorKey: 'transport_km',
        type: COLUMN_TYPES.DIGITS,
        formatValue: value => value && `${value} km`,
    },
    {
        id: 'transportPrivate',
        accessorKey: 'transport_private',
        type: COLUMN_TYPES.CHECKBOX_BOOL,
        groupConfig: {
            field: 'transport_private',
            label: 'transport_private',
            order: 'transport_private',
        },
    },
    {
        accessorKey: 'costs',
        type: COLUMN_TYPES.MONEY,
        aggregationConfig: {
            type: AGGREGATION_TYPE.SUM,
        },
    },
    {
        id: 'created',
        accessorKey: 'created_at',
        type: COLUMN_TYPES.USER_DATE,
        readOnly: true,
        groupConfig: {
            field: 'created_by',
            label: 'createdBy.display_name',
            order: 'createdBy.display_name',
        },
    },
    {
        id: 'updated',
        accessorKey: 'updated_at',
        type: COLUMN_TYPES.USER_DATE,
        readOnly: true,
        groupConfig: {
            field: 'updated_by',
            label: 'updatedBy.display_name',
            order: 'updatedBy.display_name',
        },
    },
];

const getNestedSelectors = ({ key }) => [
    ...(key !== 'card_id' ? [{ model: 'card', selector: selectCardsById }] : []),
    { model: 'task_type', selector: selectTaskTypesById },
    { model: 'user', selector: selectUsersById },
    { model: 'billed', selector: selectUsersById },
];

const JournalTable = ({ listId, persistorId, referenceId, pageSize, onSearch, ...other }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const user = useSelector(state =>
        selectUserById(state, referenceId.key === 'user_id' && referenceId.value)
    );
    const person = useSelector(state => selectPersonById(state, user?.personId));
    const card = useSelector(state =>
        selectCardById(state, referenceId.key === 'card_id' && referenceId.value)
    );
    useTaskTypes();

    const nestedSelectors = useMemo(() => getNestedSelectors(referenceId), [referenceId]);

    const columns = useMemo(() => getJournalColumns(referenceId, t), [referenceId, t]);

    const fetchParams = useMemo(
        () => ({
            ...(referenceId ? { [referenceId.key]: referenceId.value } : {}),
            ...(referenceId?.key !== 'card_id' ? { with_shallow: 'card' } : {}),
            ...(pageSize ? { limit: pageSize } : {}),
        }),
        [referenceId, pageSize]
    );

    const label = useMemo(() => {
        const generalLabel = t('components.ResourceTable.JournalTable.label');

        if (referenceId?.key === 'card_id') {
            const cardLabel = t('components.Card.autocomplete');

            return `${generalLabel}-${cardLabel}-${referenceId.value}`;
        }

        if (referenceId?.key === 'user_id') {
            const userLabel = t('components.ResourceTable.JournalTable.user');

            return `${generalLabel}-${userLabel}-${referenceId.value}`;
        }

        return generalLabel;
    }, [referenceId]);

    const stableCheck = useCallbackFunc(item => {
        return item[referenceId.key] === referenceId.value;
    });

    const stableCompare = useCallbackFunc(item => 1);

    const handleDialog = (journalIds = []) => {
        dispatch(
            showDialog({
                type: JOURNAL_DIALOG,
                props: {
                    journalIds,
                    referenceId,
                    create: !journalIds.length,
                },
            })
        );
    };

    const handleSelectionEdit = selectedIds => {
        if (Array.isArray(selectedIds) && selectedIds.length) {
            handleDialog(selectedIds);
        }
    };

    const handleDelete = id => dispatch(archiveJournal({ id }));

    const handleSelectionDelete = selectedIds => {
        return dispatch(archiveJournal({ id: selectedIds }));
    };

    const handleSubmitRow = journal => {
        if (typeof journal === 'object') {
            return dispatch(updateJournal(formatJournalSubmit(journal)));
        }

        return null;
    };

    const handleSearchChange = useCallbackFunc(filters => {
        if (onSearch) {
            onSearch(filters);
        }
    });

    const renderPrintHead = () => {
        if (person || user) {
            return (
                <>
                    <h3>{person?.display_name || user.display_name}</h3>
                    <p>{user.email}</p>
                </>
            );
        }

        if (card) {
            return (
                <>
                    <h3>{card.title}</h3>
                    <p>
                        {card.id_offer} {card.id_offer && card.id_order ? '|' : null}{' '}
                        {card.id_order}
                    </p>
                    <p>{card.address?.full}</p>
                </>
            );
        }

        return null;
    };

    const renderTopToolbarActions = ({
        table,
        columns: fullColumns,
        aggregateTotal,
        grouping,
        isLoading = false,
    }) => (
        <Grid container spacing={2} maxHeight={70} wrap="nowrap">
            <Grid item xs>
                <ResourceSearch
                    listId={listId}
                    fetchParams={fetchParams}
                    suggestParams={{
                        [referenceId?.key]: referenceId?.value,
                    }}
                    muteDirty
                    fullWidth
                    compact
                    onChange={handleSearchChange}
                />
            </Grid>
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <CreateResourceButton
                    listId={listId}
                    resource={JOURNAL_RESOURCE}
                    onClick={() => handleDialog()}
                />
            </Grid>
            <Grid item>
                <PrintIconButton
                    subject={JOURNAL_RESOURCE}
                    label={t('components.Print.currentPage')}
                    title={label}
                    disabled={isLoading}
                >
                    <ResourceTablePrint
                        table={table}
                        columns={fullColumns}
                        label={label}
                        ignoredColumns={['total']}
                        grouping={grouping}
                        aggregateTotal={aggregateTotal}
                        renderHead={renderPrintHead}
                    />
                </PrintIconButton>
            </Grid>
        </Grid>
    );

    const renderRowMenuItems = (row, table, subject, handleReset) => {
        const journalId = row?.original?.id;

        return (
            <JournalMoveForm journalIds={[journalId]} onSubmitting={() => handleReset(table)}>
                <MenuItem
                    icon={<MoveDownIcon fontSize="small" />}
                    label={t('components.JournalMove.label')}
                />
            </JournalMoveForm>
        );
    };

    const renderSelectionOptions = (selectedIds, table, handleReset) => {
        return (
            <Grid item>
                <JournalMoveForm journalIds={selectedIds} onSubmitting={() => handleReset(table)}>
                    <Button startIcon={<MoveDownIcon />}>
                        {t('components.JournalMove.label')}
                    </Button>
                </JournalMoveForm>
            </Grid>
        );
    };

    return (
        <ResourceTable
            listId={listId}
            persistorId={persistorId}
            genericListId="JournalTable"
            label={label}
            resource={JOURNAL_RESOURCE}
            columns={columns}
            nestedSelectors={nestedSelectors}
            pageSize={pageSize || undefined}
            fetchParams={fetchParams}
            initialSorting={[{ id: 'startedAt', desc: true }]}
            initialColumnVisibility={{
                transportKm: false,
                transportPrivate: false,
                costs: false,
                createdAt: false,
                updatedAt: false,
                total: false,
                id_offer: false,
                id_order: false,
                address: false,
                durationNightShift: false,
            }}
            criteria={{
                check: stableCheck,
                compare: stableCompare,
            }}
            muiTopToolbarProps={{
                style: {
                    zIndex: 3,
                    overflow: 'visible',
                },
            }}
            inlineEdit={{
                validationSchema: getJournalSchema(referenceId),
            }}
            topToolbarCustomActions={renderTopToolbarActions}
            renderRowMenuItems={renderRowMenuItems}
            renderSelectionOptions={renderSelectionOptions}
            exportCsv
            rowSelect
            stickyColumnActions
            enableAggregateTotal
            onSubmit={handleSubmitRow}
            onSelectedEdit={handleSelectionEdit}
            onDelete={handleDelete}
            onSelectedDelete={handleSelectionDelete}
            {...other}
        />
    );
};

JournalTable.propTypes = {
    listId: PropTypes.string.isRequired,
    persistorId: PropTypes.string,
    referenceId: ReferenceIdPropType.isRequired,
    pageSize: PropTypes.number,
    onSearch: PropTypes.func,
};

JournalTable.defaultProps = {
    persistorId: null,
    pageSize: 50,
    onSearch: null,
};

export default memo(JournalTable);
