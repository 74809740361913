import * as Yup from 'yup';
import { YupId } from '../form/schema';

export const makeAppointmentSchema = ({ requireEntityOnAppointmentTypeIds = null }) => {
    const getEntityRequired = appointmentTypeId => {
        if (
            requireEntityOnAppointmentTypeIds &&
            Array.isArray(requireEntityOnAppointmentTypeIds) &&
            requireEntityOnAppointmentTypeIds.length > 0
        ) {
            return requireEntityOnAppointmentTypeIds.includes(appointmentTypeId);
        }
        return false;
    };

    return Yup.object().shape({
        startDate: Yup.string().required('required'),
        endDate: Yup.string().required('required'),
        appointmentTypeId: YupId().required('required'),

        entityId: YupId().when('appointmentTypeId', {
            is: getEntityRequired,
            then: schema => schema.required('required'),
            otherwise: schema => schema.nullable(),
        }),
        entityType: Yup.string().when('appointmentTypeId', {
            is: getEntityRequired,
            then: schema => schema.required('required'),
            otherwise: schema => schema.nullable(),
        }),

        /*id: YupId().nullable(),

        title: Yup.string().nullable(),

        notes: Yup.string().nullable(),
        address: Yup.string().nullable(),

        locked: Yup.boolean().nullable(),

        allDay: Yup.boolean().nullable(),

        rRule: Yup.string().nullable(),
        exDate: Yup.string().nullable(),

        categoryId: YupId().nullable(),
        priorityId: YupId().nullable(),

        completedAt: Yup.string().nullable(),*/
    });
};

export const EMPTY_APPOINTMENT = {};
