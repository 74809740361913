import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import SearchPill from './SearchPill';
import { IdPropType } from '../../modules/proptypes';
import { checkElementOverflow } from '../../modules/layout/utils';
import MoreIconButton from '../buttons/MoreIconButton';
import Popup from '../popups/Popup';
import { makeStyles, useTheme } from '@mui/styles';
import SizeWatcher, { SIZE_WATCH_MODES } from '../layout/SizeWatcher';

const useStyles = makeStyles({
    root: {
        position: 'relative',
        paddingTop: 5,
        paddingBottom: 5,
    },
    overflowBox: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        right: 0,
        backdropFilter: 'blur(3px)',
    },
});

const buildGradient = (hexColor, rotate = 0) =>
    `linear-gradient(${rotate}deg, ${hexColor}FF 0%, ${hexColor}33 100%)`;

const SearchPills = ({ options, value, editing, contrast, I, onChange, onEdit }) => {
    const theme = useTheme();
    const classes = useStyles();
    const containerRef = useRef();
    const lastOptions = useRef(null);
    const lastSize = useRef(null);
    const [isOverflow, setOverflow] = useState(0);

    const handleSearchSelection = searchId => {
        if (value.includes(searchId)) {
            onChange(value.filter(_id => _id !== searchId));
        } else {
            onChange([...value, searchId]);
        }
    };

    const updateOverflow = () => {
        const currentOverflow = checkElementOverflow(containerRef.current);

        if (currentOverflow !== isOverflow) {
            setOverflow(currentOverflow);
        }
    };

    const updateSize = ({ height, width }) => {
        const sizeString = `${height}:${width}`;

        if (sizeString !== lastSize.current) {
            lastSize.current = sizeString;

            updateOverflow();
        }
    };

    useEffect(() => {
        const flatOptionsString = options.map(({ name }) => name).join();

        if (lastOptions.current !== flatOptionsString) {
            lastOptions.current = flatOptionsString;

            updateOverflow();
        }
    }, [options]);

    const preparedOptions = useMemo(() => {
        return (options || [])
            .map(option => ({
                ...option,
                active: value.includes(option.id),
            }))
            .sort((a, b) => (b.usageCount || 0) - (a.usageCount || 0));
    }, [options, value]);

    const { pillsInline, pillsPopup } = useMemo(() => {
        return preparedOptions.reduce(
            (carry, search) => ({
                pillsInline: [
                    ...carry.pillsInline,
                    <Grid item key={search.id}>
                        <SearchPill
                            search={search}
                            onEdit={onEdit}
                            onClick={() => handleSearchSelection(search.id)}
                            active={search.active}
                            contrast={contrast}
                            I={I}
                        />
                    </Grid>,
                ],
                pillsPopup: [
                    ...carry.pillsPopup,
                    <Grid item key={search.id}>
                        <SearchPill
                            search={search}
                            onEdit={onEdit}
                            onClick={() => handleSearchSelection(search.id)}
                            active={search.active}
                            I={I}
                        />
                    </Grid>,
                ],
            }),
            { pillsInline: [], pillsPopup: [] }
        );
    }, [preparedOptions, contrast, I]);

    return (
        <SizeWatcher mode={SIZE_WATCH_MODES.PARENT} onSizeChange={updateSize}>
            <Grid
                wrap="nowrap"
                overflow="hidden"
                justifyContent="flex-start"
                alignItems="center"
                ref={containerRef}
                className={classes.root}
                container
            >
                {pillsInline}
                {isOverflow ? (
                    <Grid
                        item
                        className={classes.overflowBox}
                        style={{
                            background: buildGradient(
                                contrast
                                    ? theme.palette.primary.main
                                    : theme.palette.background.default,
                                270
                            ),
                        }}
                    >
                        <Popup
                            popupId="searchPills"
                            popupChildren={
                                <Grid container justifyContent="center" spacing={0.5}>
                                    {pillsPopup}
                                </Grid>
                            }
                            style={{ maxWidth: 360, minWidth: 256 }}
                            {...(editing ? { open: true } : {})}
                        >
                            <MoreIconButton size="tiny" />
                        </Popup>
                    </Grid>
                ) : null}
            </Grid>
        </SizeWatcher>
    );
};

SearchPills.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    value: PropTypes.arrayOf(IdPropType).isRequired,
    editing: PropTypes.bool,
    contrast: PropTypes.bool,
    I: PropTypes.string,
    onEdit: PropTypes.func,
    onChange: PropTypes.func.isRequired,
};

SearchPills.defaultProps = {
    editing: false,
    contrast: false,
    I: null,
    onEdit: null,
};

export default SearchPills;
