import React from 'react';
import * as PropTypes from 'prop-types';
import { List } from '@mui/material';
import ContactListItem from './ContactListItem';
import { IdPropType } from '../../modules/proptypes';

const ContactList = ({ contactIds, contextCardId, edit, removeLabel, onRemove }) => {
    return (
        <List dense>
            {contactIds.map(contactId => (
                <ContactListItem
                    contactId={contactId}
                    contextCardId={contextCardId}
                    edit={edit}
                    removeLabel={removeLabel}
                    onRemove={onRemove}
                    key={`contactListItem.${contactId}`}
                />
            ))}
        </List>
    );
};

ContactList.propTypes = {
    contactIds: PropTypes.arrayOf(IdPropType).isRequired,
    contextCardId: IdPropType,
    edit: PropTypes.bool,
    removeLabel: PropTypes.string,
    onRemove: PropTypes.func,
};

ContactList.defaultProps = {
    contextCardId: null,
    edit: false,
    removeLabel: '',
    onRemove: () => {},
};

export default ContactList;
