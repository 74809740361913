import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { Box } from '@mui/material';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import { IdPropType } from '../../../../modules/proptypes';

const useStyles = makeStyles({
    root: {
        display: 'inline',
    },
});

const EditorSuggestElement = ({ mention, className, decoratedText, children }) => {
    const classes = useStyles();
    const prefix = useMemo(() => {
        return decoratedText.slice(0, 1);
    }, [decoratedText]);

    return (
        <Box className={classNames([className, classes.root])}>
            {children || `${prefix}${mention.name}`}
        </Box>
    );
};

EditorSuggestElement.propTypes = {
    mention: PropTypes.shape({ id: IdPropType, name: PropTypes.string }),
    className: PropTypes.string,
    decoratedText: PropTypes.string,
    children: PropTypes.node,
};

EditorSuggestElement.defaultProps = {
    mention: null,
    className: null,
    decoratedText: null,
    children: null,
};

export default EditorSuggestElement;
