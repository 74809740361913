import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFieldTurbo } from '../../../modules/form/hooks';
import { withPrefix } from '../../../modules/form/utils';
import { getFormFieldError } from '../../../modules/loading/utils';
import BaseRadioInput from '../base/BaseRadioInput';

const RadioInput = ({
    name,
    translation,
    label,
    options,
    errors,
    empty,
    disabled,
    horizontal,
    fullWidth,
    allowEmpty,
    prefix,
    noTurbo,
}) => {
    const prefixedName = withPrefix(name, prefix);
    const [{ value }, meta, { setValue }] = useFieldTurbo(prefixedName, noTurbo);
    const apiError = getFormFieldError(prefixedName, errors, meta);

    const handleChange = useCallback(
        (event, newValue) => {
            setValue(newValue);
        },
        [setValue]
    );

    return (
        <BaseRadioInput
            name={name}
            translation={translation}
            value={value}
            onChange={handleChange}
            options={options}
            label={label}
            empty={empty}
            error={apiError || (meta.touched && meta.error) || null}
            disabled={disabled}
            horizontal={horizontal}
            fullWidth={fullWidth}
            allowEmpty={allowEmpty}
            prefix={prefix}
        />
    );
};

RadioInput.propTypes = {
    name: PropTypes.string.isRequired,
    translation: PropTypes.string,
    label: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
                .isRequired,
        })
    ),
    errors: PropTypes.arrayOf(PropTypes.shape({})),
    empty: PropTypes.string,
    disabled: PropTypes.bool,
    horizontal: PropTypes.bool,
    fullWidth: PropTypes.bool,
    allowEmpty: PropTypes.bool,
    prefix: PropTypes.string,
    noTurbo: PropTypes.bool,
};

RadioInput.defaultProps = {
    options: null,
    label: null,
    errors: null,
    empty: null,
    disabled: false,
    horizontal: false,
    translation: null,
    fullWidth: false,
    allowEmpty: false,
    prefix: null,
    noTurbo: false,
};

export default RadioInput;
