import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Portal } from '@mui/material';
import Divider from '@mui/material/Divider';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from '../../modules/snackbar/hooks';
import { useDialog } from '../dialogs/DialogContext';
import { selectContactById } from '../../modules/contacts/selectors';
import { selectContactDatasByContactId } from '../../modules/contactData/selectors';
import { selectAddressesByContactId } from '../../modules/addresses/selectors';
import AddressesInput, { getNewAddress } from '../address/AddressesInput';
import ContactDatasInput, { getNewContactData } from '../contactData/ContactDatasInput';
import {
    ADDRESS_RESOURCE,
    COMPANY_RESOURCE,
    CONTACT_DATA_RESOURCE,
    CONTACT_RESOURCE,
} from '../../modules/api/resources';
import { subjectArray } from '../../modules/abilities/actions';
import Form from '../form/formik/Form';
import { companyContactSchema } from '../../modules/contacts/schema';
import TextInput from '../form/formik/TextInput';
import { IdPropType, RefPropType } from '../../modules/proptypes';
import { prefillInitialValues } from '../../modules/form/utils';
import { bulkSubmit } from '../../modules/contacts/utils';
import { selectCompanyById } from '../../modules/companies/selectors';
import { storeCompany, updateCompany } from '../../modules/companies/actions';
import ContactTypeMultiSelect from '../form/ContactTypeMultiSelect';
import SubmitButton from '../form/formik/SubmitButton';
import FormDialogAbortButton from '../buttons/specialized/FormDialogAbortButton';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },

    header: {
        paddingBottom: theme.spacing(2),
        marginTop: theme.spacing(1),
    },

    inputRow: {
        width: '100%',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
    },
}));

const CompanyContactForm = ({ contactId, contextCardId, submitRef, extraRef, onDone }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { openContactDialog } = useDialog();

    const contact = useSelector(state => selectContactById(state, contactId));
    const contactDatas = useSelector(state => selectContactDatasByContactId(state, contactId));
    const addresses = useSelector(state => selectAddressesByContactId(state, contactId));
    const company = useSelector(state => selectCompanyById(state, contact && contact.companyId));

    const initialValues = useMemo(
        () => ({
            contact: prefillInitialValues(contact, {
                department: '',
                person_function_id: '',
                id_custom: '',
                parent_id: '',
            }),
            company: prefillInitialValues(company, {
                id: null,
                name: '',
            }),
            addresses: contactId
                ? addresses.map(address => prefillInitialValues(address, getNewAddress()))
                : [getNewAddress()],
            contactData: contactId
                ? contactDatas.map(contactData =>
                      prefillInitialValues(contactData, getNewContactData())
                  )
                : [getNewContactData('office', 'phone'), getNewContactData('office', 'email')],
            useContactable: !!contextCardId,
            contactTypeIds: [],
        }),
        [contactId, contextCardId, contact, company, contactDatas, addresses]
    );

    const subject = useMemo(
        () => ({
            contact: contact || CONTACT_RESOURCE,
            company: company || COMPANY_RESOURCE,
            addresses: subjectArray(ADDRESS_RESOURCE),
            contactData: subjectArray(CONTACT_DATA_RESOURCE),
        }),
        [contact, company]
    );

    const handleSubmit = async (values, { resetForm }) => {
        let { company: _company } = values;

        /* create / update person */
        const prepped = { ..._company, id: company && company.id };
        _company = (
            await dispatch(company && company.id ? updateCompany(prepped) : storeCompany(prepped))
        ).data;

        const result = await bulkSubmit({
            values: { ...values, company: _company },
            initialValues,
            contactId,
            contextCardId,
            dispatch,
        });

        enqueueSnackbar(t('components.ContactBaseData.contactSaved'), {
            variant: 'success',
        });

        resetForm();
        if (!contactId) {
            openContactDialog({ contactId: result.contact.id, contextCardId });
        } else if (onDone) {
            onDone();
        }

        return Promise.resolve();
    };

    return (
        <Form
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={companyContactSchema}
            subject={subject}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h2" color="primary" className={classes.header}>
                        {t('components.ContactBaseData.company')}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextInput label="Name" name="company.name" size="small" />
                </Grid>
                <Grid item xs={6}>
                    <TextInput label="Kundennummer" name="contact.id_custom" size="small" />
                </Grid>
                <Grid item xs={12}>
                    <Divider className={classes.divider} />

                    <Typography variant="h3" color="primary" className={classes.header}>
                        {t('components.ContactBaseData.contactOptions')}
                    </Typography>
                    <ContactDatasInput name="contactData" initialEdit={contactId ? [] : [0, 1]} />

                    <Typography variant="h3" color="primary" className={classes.header}>
                        {t('components.ContactBaseData.addresses')}
                    </Typography>
                    <AddressesInput name="addresses" initialEdit={contactId ? null : 0} />
                </Grid>
            </Grid>

            {contextCardId && !contactId && (
                <Portal container={extraRef.current}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item>
                            <Typography>{t('components.ContactBaseData.cardBind')}</Typography>
                        </Grid>
                        <Grid item>
                            <ContactTypeMultiSelect
                                name="contactTypeIds"
                                label={t('components.ContactBaseData.contactable')}
                            />
                        </Grid>
                    </Grid>
                </Portal>
            )}

            <Portal container={submitRef.current}>
                <Grid container spacing={1}>
                    {contactId && (
                        <Grid item>
                            <FormDialogAbortButton onClick={onDone} />
                        </Grid>
                    )}
                    <Grid item>
                        <SubmitButton
                            data-test-id="save-all-contact-changes-btn"
                            variant="contained"
                            outsideForm
                        >
                            {t('form.SubmitButton.defaultLabel')}
                        </SubmitButton>
                    </Grid>
                </Grid>
            </Portal>
        </Form>
    );
};

CompanyContactForm.propTypes = {
    contactId: IdPropType,
    contextCardId: IdPropType,
    submitRef: RefPropType,
    extraRef: RefPropType,
    onDone: PropTypes.func,
};

CompanyContactForm.defaultProps = {
    contactId: null,
    contextCardId: null,
    submitRef: null,
    extraRef: null,
    onDone: null,
};

export default CompanyContactForm;
