import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { getJournalSummary } from '../../modules/journals/journalsSlice';
import Duration from '../utils/Duration';

const JournalSummary = ({ fetchParams }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [summary, setSummary] = useState(null);

    useEffect(() => {
        dispatch(getJournalSummary(fetchParams)).then(result => setSummary(result.extraData));
    }, [fetchParams]);

    return (
        <Grid
            container
            spacing={1}
            wrap="nowrap"
            sx={{
                marginLeft: 0,
                width: '100%',
                display: 'inline-flex',
            }}
        >
            {summary &&
                Object.entries(summary).map(([key, value]) => (
                    <Grid key={key} item xs>
                        <Typography variant="h5" color="inherit">
                            {t(`components.JournalSummary.${key}`)}
                        </Typography>
                        <Duration durationValue={value} asDecimal />
                    </Grid>
                ))}
        </Grid>
    );
};

JournalSummary.propTypes = {
    fetchParams: PropTypes.shape({}),
};

JournalSummary.defaultProps = {
    fetchParams: {},
};

export default JournalSummary;
