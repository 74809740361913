export const MAIL_VIEW_MODE = {
    HTML: 'html',
    PLAIN_TEXT: 'plain_text',
};

export const EMAIL_CONTENT_TYPES = {
    MAIL: 'mail',
    ERROR: 'error',
    FILE: 'file',
    FILE_ERROR: 'error_file',
    FINISH: 'finish',
};

export const EMAIL_CONFIG = {
    allowed: ['msg', 'eml'],
    paths: {
        parent: 'card.cloud.directory',
        mail: 'card_status.cloud.directory.mail',
        inbound: 'card_status.cloud.directory.in',
        outbound: 'card_status.cloud.directory.out',
    },
    mimeExtensions: {
        msg: 'application/vnd.ms-outlook',
        eml: 'message/rfc822',
    },
};
