import React, { useMemo } from 'react';
import { stableSort } from './utils';

export const useSorting = (
    items,
    getSortValue,
    initialOrderBy = null,
    initialDirection = 'asc'
) => {
    const [direction, setDirection] = React.useState(initialDirection);
    const [orderBy, setOrderBy] = React.useState(initialOrderBy);

    const handleSort = useMemo(
        () => (event, property) => {
            const isAsc = orderBy === property && direction === 'asc';
            setDirection(isAsc ? 'desc' : 'asc');
            setOrderBy(property);
        },
        [orderBy, direction, setDirection, setOrderBy]
    );

    const createSortHandler = useMemo(
        () => property => event => {
            handleSort(event, property);
        },
        [handleSort]
    );

    const sorted = useMemo(() => stableSort(items, direction, orderBy, getSortValue), [
        direction,
        orderBy,
        getSortValue,
        items,
    ]);

    return { direction, orderBy, handleSort, sorted, createSortHandler };
};
