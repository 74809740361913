import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ConditionalWrapper } from '../utils/ConditionalWrapper';
import Confirmation from '../form/Confirmation';
import Button from '../form/base/Button';

const AbortButton = ({ onClick, confirm }) => {
    const { t } = useTranslation();

    return (
        <ConditionalWrapper
            condition={confirm}
            wrapper={children => (
                <Confirmation
                    popupId="AbortButton"
                    label={t('components.CloseButton.closeIntent')}
                    onConfirm={onClick}
                >
                    {children}
                </Confirmation>
            )}
        >
            <Button onClick={confirm ? null : onClick} muted size="small">
                {t('abort')}
            </Button>
        </ConditionalWrapper>
    );
};

AbortButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    confirm: PropTypes.bool,
};

AbortButton.defaultProps = {
    confirm: false,
};

export default AbortButton;
