import { RESOURCE_CLASS_RESOURCE } from '../api/resources';
import { createResourceSlice } from '../redux/resource/createResourceSlice';

const resourceClassesSlice = createResourceSlice({
    resource: RESOURCE_CLASS_RESOURCE,
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,

    destroyResourceClass,
    updateResourceClass,
    storeResourceClass,

    indexResourceClasses,
    showResourceClasses,
    storeResourceClasses,
    updateResourceClasses,
    archiveResourceClasses,
    restoreResourceClasses,
    destroyResourceClasses,

    searchResourceClasses,
    autocompleteResourceClasses,
    suggestResourceClasses,
} = resourceClassesSlice.actions;

export const {
    selectResourceClassById,
    makeResourceClassesByIdsSelector,
    selectAllResourceClassIds,
    selectAllResourceClasses,
    selectResourceClassesLoading,
    selectResourceClassesInitialized,
    selectResourceClassLoading,
    selectResourceClassInitialized,
} = resourceClassesSlice.selectors;

export const { useResourceClass, useResourceClasses } = resourceClassesSlice.hooks;

export default resourceClassesSlice.reducer;
