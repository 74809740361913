import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import createMentionPlugin from '@draft-js-plugins/mention';
import debounce from 'lodash/debounce';
import { positionSuggestions } from '../../../../modules/editor/utils';
import EditorSuggestElement from './EditorSuggestElement';
import { useCallbackFunc } from '../../../../modules/hooks';

const EditorSuggest = ({
    initPrefix,
    fetch,
    renderOption,
    formatSuggestion,
    onInitialize,
    onCreate,
    onSelect,
}) => {
    const [suggestions, setSuggestions] = useState([]);
    const [open, setOpen] = useState(false);

    const suggestPlugin = useMemo(
        () =>
            createMentionPlugin({
                mentionTrigger: initPrefix,
                mentionPrefix: initPrefix,
                mentionComponent: EditorSuggestElement,
                positionSuggestions,
                entityMutability: 'IMMUTABLE',
            }),
        [initPrefix]
    );
    const { MentionSuggestions } = suggestPlugin;

    const resolveFetch = useCallbackFunc(({ data }) => {
        if (
            data.suggestions.length > 0 &&
            (data.suggestions[0].highlight || data.suggestions[0].value)
        ) {
            setSuggestions(
                data.suggestions.reduce((carry, suggestion) => {
                    if (formatSuggestion) {
                        return [...carry, formatSuggestion(suggestion)];
                    }

                    const { id, value: name, highlight, highlight2 } = suggestion;
                    return [
                        ...carry,
                        {
                            ...suggestion,
                            id,
                            name,
                            highlight: highlight || name,
                            desc: highlight2,
                        },
                    ];
                }, [])
            );
        } else {
            setSuggestions([{ empty: true }]);
        }
    });

    const handleFetch = useMemo(
        () =>
            debounce(
                ({ value }) => {
                    fetch({ q: value })
                        .then(resolveFetch)
                        .catch(() => {
                            setSuggestions([{ empty: true }]);
                        });
                },
                120,
                true
            ),
        [resolveFetch, setSuggestions]
    );

    const handleSearch = useCallback(
        value => {
            setSuggestions([{ loading: true }]);
            handleFetch(value);
        },
        [handleFetch]
    );

    const handleSelect = useCallback(
        selected => {
            if (selected.loading) {
                return;
            }

            if (selected.id) {
                onSelect(selected);
                return;
            }

            if (onCreate) {
                onCreate(selected);
            }
        },
        [onSelect, onCreate]
    );

    const onOpenChange = useCallback(_open => {
        setOpen(_open);
    }, []);

    useEffect(() => {
        onInitialize(suggestPlugin);
    }, [suggestPlugin, onInitialize]);

    return (
        <MentionSuggestions
            open={open}
            suggestions={suggestions}
            entryComponent={renderOption}
            onAddMention={handleSelect}
            onSearchChange={handleSearch}
            onOpenChange={onOpenChange}
        />
    );
};

EditorSuggest.propTypes = {
    initPrefix: PropTypes.string.isRequired,
    fetch: PropTypes.func.isRequired,
    renderOption: PropTypes.node.isRequired,
    formatSuggestion: PropTypes.func,
    onInitialize: PropTypes.func.isRequired,
    onCreate: PropTypes.func,
    onSelect: PropTypes.func,
};

EditorSuggest.defaultProps = {
    formatSuggestion: null,
    onCreate: null,
    onSelect: () => {},
};

export default EditorSuggest;
