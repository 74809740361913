import React, { forwardRef } from 'react';
import * as PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { RefPropType } from '../../../modules/proptypes';

const useStyles = makeStyles({
    sidebar: {
        width: 220,
    },
});

const TabDialogActions = forwardRef(({ sidebar, left, right, refLeft }, ref) => {
    const classes = useStyles();

    return (
        <Grid container direction="row" alignItems="center">
            <Grid item className={classes.sidebar}>
                {sidebar}
            </Grid>
            <Grid item xs>
                <Grid container spacing={1} alignItems="center">
                    {left && <Grid item>{left}</Grid>}
                    {refLeft && (
                        <Grid item>
                            <div ref={refLeft} />
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Grid item>
                <Grid container spacing={1} alignItems="center">
                    {right && <Grid item>{right}</Grid>}
                    {ref && (
                        <Grid item>
                            <div ref={ref} />
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
});

TabDialogActions.propTypes = {
    sidebar: PropTypes.node,
    left: PropTypes.node,
    right: PropTypes.node,
    refLeft: RefPropType,
};

TabDialogActions.defaultProps = {
    sidebar: null,
    left: null,
    right: null,
    refLeft: null,
};

export default TabDialogActions;
