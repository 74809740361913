import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import DraftEditor from '@draft-js-plugins/editor';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/mention/lib/plugin.css';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { getDefaultKeyBinding } from 'draft-js';
import { useDispatch, useSelector } from 'react-redux';
import MentionEntry from './MentionEntry';
import EditorToolbar from './EditorToolbar/EditorToolbar';
import { useContextualCanWrite } from '../../../../modules/abilities/hooks';
import ContextualCan from '../../../../modules/abilities/ContextualCan';
import { READ } from '../../../../modules/abilities/actions';
import { useFieldFast } from '../../../../modules/form/hooks';
import { EditorLink, findLinkElements } from '../../../../modules/editor/link';
import { EditorHighlight, findHighlightElements } from '../../../../modules/editor/highlight';
import { editorStateToMarkdown, markdownToEditorState } from '../../../../modules/editor/markdown';
import { getEditorEntities } from '../../../../modules/editor/element';
import EditorSuggest from './EditorSuggest';
import { autocompleteTags, selectAllTags, storeTag } from '../../../../modules/tags/tagSlice';
import EditorSuggestEntry from './EditorSuggestEntry';
import { selectUsersById } from '../../../../modules/users/selectors';
import { selectPersonsById } from '../../../../modules/persons/selectors';
import { Typography } from '@mui/material';

/**
 * Anmerkungen:
 *
 * Draft.js bauen / Hilfsseiten:
 * https://draftjs.org/docs/getting-started
 * https://codesandbox.io/s/QW1rqjBLl
 * https://medium.com/@kz228747/draft-js-examples-ef0d5a980470
 * https://draft-js-samples.now.sh/Home
 *
 * Draft.js plugins (z.B. mention, tags):
 * https://www.draft-js-plugins.com/
 * https://github.com/Darex1991/draft-js-with-an-option-for-delete-mentions
 *
 * https://draftjs.org/docs/advanced-topics-issues-and-pitfalls/#mobile-not-yet-supported
 * mobile not supported?! Wohl erst komplexere Features.
 *
 * vielleicht auch diese Datei löschen und einen vorgefertigten nehmen:
 * https://github.com/nikgraf/awesome-draft-js#standalone-editors-built-on-draftjs
 *
 * Alternative evtl. https://quilljs.com/
 */

const useStyles = makeStyles(theme => ({
    container: {
        border: `1px solid rgba(0, 0, 0, 0.23)`,
        borderRadius: theme.shape.borderRadius,
        minHeight: 150,
        backgroundColor: theme.palette.background.paper,

        '&:hover': {
            borderColor: theme.palette.text.primary,
        },

        '& .public-DraftEditor-content': {
            minHeight: 150,
            padding: theme.spacing(2),
        },
    },

    editor: {
        fontSize: '.875rem',
        maxHeight: 300,
        overflow: 'auto',
        wordBreak: 'break-word',

        backgroundColor: theme.palette.background.paper,
    },

    disabled: {
        borderColor: 'rgba(0,0,0,0.08)',

        '&:hover': {
            borderColor: 'rgba(0,0,0,0.08)',
        },
    },

    hashtagErrorLabel: {
        paddingRight: theme.spacing(1),
        fontStyle: 'italic',
    },
}));

const getFilteredSuggestions = (value, suggestions, max = 25) => {
    const search = value.toLowerCase();
    const filteredSuggestions = suggestions.filter(
        suggestion =>
            !value ||
            suggestion.username.toLowerCase().includes(search) ||
            suggestion.display_name.toLowerCase().includes(search) ||
            (suggestion.first_name && suggestion.first_name.toLowerCase().includes(search)) ||
            (suggestion.last_name && suggestion.last_name.toLowerCase().includes(search))
    );
    return filteredSuggestions.slice(0, Math.min(filteredSuggestions.length, max)).map(user => ({
        ...user,
        id: user.id,
        value: user.display_name,
    }));
};

const linkAndHighlightPlugin = {
    decorators: [
        {
            strategy: findLinkElements,
            component: EditorLink,
        },
        {
            strategy: findHighlightElements,
            component: EditorHighlight,
        },
    ],
};

const HashtagRenderOption = values => {
    const { t } = useTranslation();
    const classes = useStyles();

    return !values.mention.id && values.searchValue.length > 255 ? (
        <Typography
            variant="body2"
            dangerouslySetInnerHTML={{ __html: t('errors.hashtagNameMaxLength') }}
            className={classes.hashtagErrorLabel}
            color="error"
        />
    ) : (
        <EditorSuggestEntry {...values} />
    );
};

const Editor = ({ name, responsibleUsers, tag, disabled, minimal, I }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const canWrite = useContextualCanWrite(name);
    const classes = useStyles();
    const ref = useRef(null);
    const users = useSelector(selectUsersById);
    const persons = useSelector(selectPersonsById);
    const currentTags = useSelector(selectAllTags);

    const [field, meta, helpers] = useFieldFast(name);
    const [, , helpersResponsibleUsers] = useFieldFast(
        responsibleUsers || '_fallbackResponsibleUsersNotUsed'
    );
    const [, , helpersTags] = useFieldFast(tag || '_fallbackTagsNotUsed');

    const [editorState, setEditorState] = useState(
        markdownToEditorState(field && field.value ? field.value : '')
    );

    const userSuggest = useMemo(
        () =>
            Object.values(users).map(user => {
                const person = persons[user.personId];
                return { ...person, ...user, name: user.display_name };
            }),
        [users, persons]
    );

    const [hashtagPlugin, setHashtagPlugin] = useState(null);
    const [mentionPlugin, setMentionPlugin] = useState(null);
    const plugins = useMemo(
        () => [
            ...(hashtagPlugin ? [hashtagPlugin] : []),
            ...(mentionPlugin ? [mentionPlugin] : []),
            linkAndHighlightPlugin,
        ],
        [hashtagPlugin, mentionPlugin]
    );

    const handleKeyBindings = event => {
        if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
            return undefined;
        }

        if (event.keyCode === 27) {
            event.stopPropagation();
        }

        return getDefaultKeyBinding(event);
    };

    const onStore = useMemo(
        () =>
            debounce(
                state => {
                    helpers.setValue(editorStateToMarkdown(state));

                    if (responsibleUsers) {
                        const mentionedIds = getEditorEntities(
                            state.getCurrentContent(),
                            'mention'
                        ).map(({ entity }) => entity.data.mention.id);

                        helpersResponsibleUsers.setValue([...new Set(mentionedIds)]);
                    }

                    if (tag) {
                        const tags = getEditorEntities(state.getCurrentContent(), '#mention').map(
                            ({ entity }) => entity.data.mention.name
                        );

                        helpersTags.setValue([...new Set(tags)]);
                    }
                },
                80,
                true
            ),
        [currentTags]
    );

    const onChange = useCallback(
        newState => {
            setEditorState(newState);
            onStore(newState);
        },
        [onStore]
    );
    const handleCreateTag = suggestion => {
        return dispatch(storeTag({ name: suggestion.name }));
    };

    const handleUserSearch = useCallback(
        ({ q }) => {
            return new Promise(resolve => {
                resolve({
                    data: {
                        q,
                        suggestions: getFilteredSuggestions(q, userSuggest),
                    },
                });
            });
        },
        [userSuggest]
    );

    const showEditor = useMemo(() => {
        if (tag && !hashtagPlugin) {
            return false;
        }

        if (responsibleUsers && !mentionPlugin) {
            return false;
        }

        return true;
    }, [tag, responsibleUsers, hashtagPlugin, mentionPlugin]);

    useEffect(() => {
        const currentMd = editorStateToMarkdown(editorState);
        if (field.value === '' && currentMd !== '') {
            setEditorState(markdownToEditorState(''));
        }
        // eslint-disable-next-line
    }, [field.value]);

    return (
        <ContextualCan I={READ} field={name}>
            <Box
                className={classNames(classes.container, {
                    [classes.disabled]: disabled || !canWrite,
                })}
            >
                <EditorToolbar
                    doChange={onChange}
                    doFocus={false}
                    editorState={editorState}
                    setEditorState={setEditorState}
                    editorRef={ref}
                    mention={!!responsibleUsers}
                    tag={!!tag}
                    disabled={disabled || !canWrite}
                    minimal={minimal}
                />
                <Box className={classes.editor}>
                    {showEditor ? (
                        <DraftEditor
                            ref={ref}
                            editorState={editorState}
                            onChange={onChange}
                            plugins={plugins}
                            onBlur={() => {
                                if (!disabled && canWrite) {
                                    helpers.setTouched(true);
                                }
                            }}
                            keyBindingFn={handleKeyBindings}
                            readOnly={disabled || !canWrite}
                            preserveSelectionOnBlur={true}
                        />
                    ) : null}
                </Box>
                {responsibleUsers && (
                    <EditorSuggest
                        onInitialize={setMentionPlugin}
                        fetch={handleUserSearch}
                        renderOption={values => (
                            <EditorSuggestEntry
                                {...{ ...values, ...{ displayComponent: MentionEntry } }}
                            />
                        )}
                        initPrefix="@"
                    />
                )}
                {tag && (
                    <EditorSuggest
                        onInitialize={setHashtagPlugin}
                        onCreate={handleCreateTag}
                        fetch={values => dispatch(autocompleteTags(values))}
                        renderOption={HashtagRenderOption}
                        initPrefix="#"
                    />
                )}
            </Box>
            {!disabled && meta.touched && meta.error && (
                <FormHelperText error>{t(`errors.${meta.error}`)}</FormHelperText>
            )}
        </ContextualCan>
    );
};

Editor.propTypes = {
    name: PropTypes.string.isRequired,
    responsibleUsers: PropTypes.string,
    tag: PropTypes.string,
    disabled: PropTypes.bool,
    minimal: PropTypes.bool,
    I: PropTypes.string,
};

Editor.defaultProps = {
    responsibleUsers: null,
    tag: null,
    disabled: false,
    minimal: false,
    I: null,
};

export default Editor;
