import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IdPropType } from '../../../../modules/proptypes';
import ContactLinkedProtocols from '../../ContactLinkedProtocols';

const ContactLinkedProtocolPage = ({ contactId }) => {
    const { t } = useTranslation();

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h2" color="primary">
                    {t('components.ContactDialog.protocols')}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <ContactLinkedProtocols contactId={contactId} />
            </Grid>
        </Grid>
    );
};

ContactLinkedProtocolPage.propTypes = {
    contactId: IdPropType,
};

ContactLinkedProtocolPage.defaultProps = {
    contactId: null,
};

export default ContactLinkedProtocolPage;
