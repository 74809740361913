import { useField, useFormikContext } from 'formik';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isFunction, isArray, isObject, debounce } from 'lodash';
import { useApi } from '../api/ApiProvider';
import Persistor from '../persistor/persistor';
import { useTranslation } from 'react-i18next';

/**
 * necessary until this gets fixed:
 * https://github.com/jaredpalmer/formik/issues/2268
 * https://github.com/formium/formik/issues/342
 */
export const useFieldFast = name => {
    const [field, meta] = useField(name);

    const { setFieldTouched, setFieldValue, setFieldError } = useFormikContext();
    const helpers = useMemo(() => {
        return {
            setValue: (oldValue, newValue, shouldValidate) => {
                const value = isFunction(newValue) ? newValue(oldValue) : newValue;
                return setFieldValue(field.name, value, shouldValidate);
            },
            setTouched: (...args) => setFieldTouched(field.name, ...args),
            setError: (...args) => setFieldError(field.name, ...args),
        };
    }, [setFieldTouched, setFieldValue, setFieldError, field.name]);

    const latestRef = useRef({});

    // On every render save newest helpers to latestRef
    latestRef.current.setValue = helpers.setValue;
    latestRef.current.setTouched = helpers.setTouched;
    latestRef.current.setError = helpers.setError;

    latestRef.current.value = field.value;

    // On the first render create new function which will never change
    // but call newest helper function
    if (!latestRef.current.helpers) {
        latestRef.current.helpers = {
            setValue: (...args) => latestRef.current.setValue(latestRef.current.value, ...args),
            setTouched: (...args) => latestRef.current.setTouched(...args),
            setError: (...args) => latestRef.current.setError(...args),
        };
    }

    return [field, meta, latestRef.current.helpers];
};

export const useFieldTurbo = name => {
    const [field, meta] = useField(name);
    const [value, setValue] = useState(field.value);
    const modifiedField = useMemo(() => ({ ...field, value }), [field, value]);

    useEffect(() => {
        setValue(field.value);
    }, [field.value]);

    const { setFieldTouched, setFieldValue, setFieldError } = useFormikContext();
    const helpers = useMemo(() => {
        return {
            setValue: (...args) => {
                return setFieldValue(name, ...args);
            },
            setTouched: (...args) => setFieldTouched(name, ...args),
            setError: (...args) => setFieldError(name, ...args),
        };
    }, [setFieldTouched, setFieldValue, setFieldError, name]);

    const latestRef = useRef({});

    // On every render save newest helpers to latestRef
    latestRef.current.setValue = helpers.setValue;
    latestRef.current.setTouched = helpers.setTouched;
    latestRef.current.setError = helpers.setError;

    // On the first render create new function which will never change
    // but call newest helper function
    if (!latestRef.current.helpers) {
        const debouncedSetValue = debounce((...args) => latestRef.current.setValue(...args), 500);

        latestRef.current.helpers = {
            setValue: (newValue, shouldValidate) => {
                const prepped = isFunction(newValue) ? newValue(value) : newValue;
                setValue(prepped);
                return debouncedSetValue(prepped, shouldValidate);
            },
            setTouched: (...args) => latestRef.current.setTouched(...args),
            setError: (...args) => latestRef.current.setError(...args),
        };
    }

    return [modifiedField, meta, latestRef.current.helpers];
};

const getPrefilled = (item, prefill, initial) =>
    Object.entries(initial).reduce((carry, [key, value]) => {
        let prefilled;
        if (isFunction(value)) {
            prefilled = value(item, prefill);
        } else if (isObject(value) && !isArray(value)) {
            prefilled = getPrefilled(item[key] || {}, prefill[key] || {}, value);
        } else if (item[key] === false || item[key] === true) {
            prefilled = item[key];
        } else {
            prefilled = item[key] || prefill[key] || value;
        }

        carry[key] = prefilled; // eslint-disable-line no-param-reassign

        return carry;
    }, {});

/**
 * Generates initial values from the item with initialValues as fallback.
 *
 * Changing the initialValues does NOT trigger a new return value!
 */
export const useInitialValues = (item, ...fallbacks) => {
    const initial = useRef(fallbacks);

    useEffect(() => {
        if (JSON.stringify(initial.current) !== JSON.stringify(fallbacks)) {
            initial.current = fallbacks;
        }
    }, [fallbacks]);

    return useMemo(() => {
        if (initial.current.length === 1) {
            return getPrefilled(item || {}, {}, initial.current[0]);
        }
        return getPrefilled(item || {}, initial.current[0], initial.current[1]);
    }, [item, initial]);
};

/**
 * Generates initial values from persisted values with initialValues as fallback.
 *
 * Changing the initialValues does NOT trigger a new return value!
 */
export const usePersistedInitialValues = (persistKey, ...fallbacks) => {
    // read localStorage once initially
    const cached = useMemo(() => Persistor.get(`form.${persistKey}`), [persistKey]);

    return useInitialValues(cached, ...fallbacks);
};

export const useResourceSubmit = (
    itemId,
    resource,
    params = {},
    extras = { onDone: null, prepare: null }
) => {
    const paramsRef = useRef({});
    const extrasRef = useRef({});
    const api = useApi();

    paramsRef.current = params;
    extrasRef.current = extras;

    return useCallback(
        values => {
            const { onDone, prepare } = extrasRef.current;

            const prepped = { ...paramsRef.current, ...values, id: itemId };
            const prepared = prepare ? prepare(prepped) : prepped;

            return (itemId ? api[resource].update(prepared) : api[resource].store(prepared)).then(
                response => (onDone ? onDone(response) : response)
            );
        },
        [itemId, paramsRef, extrasRef, api, resource]
    );
};

export const useTranslatedOptions = (name, overrideOptions, translation, allowEmpty = false) => {
    const { t } = useTranslation();

    return useMemo(() => {
        let _options = null;
        if (overrideOptions) {
            _options = [...overrideOptions];
        } else {
            const translations = t(translation || `Select.${name}`, { returnObjects: true });
            if (translations && isObject(translations)) {
                _options = Object.entries(translations).map(([key, val]) => ({
                    value: key,
                    label: val,
                }));
            }
        }

        if (_options === null) {
            throw new Error(
                `Invalid options for "${name}". Provide options, custom translation or set translations for "Select.${name}".`
            );
        }

        if (allowEmpty) {
            _options.unshift({ value: '', label: t('Select.empty') });
        }

        return _options;
    }, [t, translation, overrideOptions, name, allowEmpty]);
};

export const usePopupSelectClickAway = () => {
    const disableClickAway = useRef(false);

    const selectProps = useMemo(
        () => ({
            onOpen: () => {
                disableClickAway.current = true;
            },
            onClose: () => {
                disableClickAway.current = false;
            },
        }),
        []
    );

    const handleClickAway = popupState => {
        if (!disableClickAway.current && popupState) {
            popupState.close();
        }
    };

    return {
        selectProps,
        handleClickAway,
    };
};
