import * as Yup from 'yup';
import { YupId } from '../form/schema';

export const createTaskSchema = Yup.object().shape({
    name: Yup.string().when('templateId', (id, schema) => {
        return id && id !== ''
            ? schema
            : Yup.string().trim().required('required').max(255, 'string255MaxLength');
    }),
    card_id: YupId().required('required'),
    templateId: YupId().nullable(),
    category_id: YupId().nullable(),
    task_status_id: YupId().nullable(),
});

export const taskSchema = Yup.object().shape({
    id: YupId().required('required'),
    name: Yup.string().trim().required('required').max(255, 'string255MaxLength'),
    category_id: YupId().nullable(),
    task_type_id: Yup.string(),
    task_type_text: Yup.string(),
    duration_value: Yup.number().typeError('numberMismatch'),
    task_duration_id: YupId(),
    description: Yup.string(),
    due_at: Yup.date().nullable().typeError('formatMismatch'),
    due_time: Yup.string().nullable().typeError('formatMismatch'),
    next_due_at: Yup.date().nullable().typeError('formatMismatch'),
    priority_id: YupId().nullable(),
    task_status_id: YupId().required('required'),
    due_locked: Yup.bool(),
    completed_at: Yup.date().nullable().typeError('formatMismatch'),
    card_id: YupId(),
    responsibles: Yup.array().nullable(),
    responsibles_main: Yup.array().nullable(),
});
