import { useMemo } from 'react';
import { useSnackbar as useNotistackSnackbar } from 'notistack';

export const useSnackbar = () => {
    const { enqueueSnackbar, closeSnackbar, ...other } = useNotistackSnackbar();

    const wrappedEnqueueSnackbar = useMemo(
        () => (msg, config = {}) => {
            const key = enqueueSnackbar(msg, {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                autoHideDuration: 1500,
                ...config,
                onClick: event => {
                    if (config.onClick) {
                        config.onClick(event);
                    }
                    closeSnackbar(key);
                },
            });
        },
        [enqueueSnackbar, closeSnackbar]
    );

    return {
        enqueueSnackbar: wrappedEnqueueSnackbar,
        closeSnackbar,
        ...other,
    };
};
