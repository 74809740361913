import React, { isValidElement, useEffect, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import { VariableSizeGrid as VirtualGrid } from 'react-window';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
    },
    container: {
        height: '100% !important',
    },
});

/**
 * Generate Vitualized Grid
 * @param items Array (Either React Nodes or {element, width, height})
 * @param cols
 * @param itemHeight only needed if no height per item is set (node-only items)
 * @param containerProps
 * @param itemProps
 * @param innerRef
 * @param other
 * @return {JSX.Element}
 * @constructor
 */

const VirtualizedGrid = React.forwardRef(({ items, cols, itemHeight, ...other }, innerRef) => {
    const classes = useStyles();
    const [rootWidth, setRootWidth] = useState(364);
    const [rootHeight, setRootHeight] = useState(512);
    const rootRef = useRef(null);

    useEffect(() => {
        setRootWidth(rootRef.current.clientWidth);
        setRootHeight(rootRef.current.clientHeight);
    }, [rootRef, setRootWidth, setRootHeight]);

    const Cell = ({ columnIndex, rowIndex, style }) => {
        const index = rowIndex * cols + (columnIndex + 1);

        return (
            <Box style={style}>
                {items &&
                    items[index] &&
                    (isValidElement(items[index]) ? items[index] : items[index].element)}
            </Box>
        );
    };

    Cell.propTypes = {
        columnIndex: PropTypes.number.isRequired,
        rowIndex: PropTypes.number.isRequired,
        style: PropTypes.shape({}).isRequired,
    };

    return (
        <Box ref={rootRef} className={classes.root}>
            <VirtualGrid
                className={classes.container}
                width={rootWidth}
                height={rootHeight}
                columnCount={cols}
                rowCount={Math.ceil(items.length / cols)}
                columnWidth={() => Math.floor(rootWidth / cols)}
                rowHeight={() => itemHeight}
                ref={innerRef}
                {...other}
            >
                {Cell}
            </VirtualGrid>
        </Box>
    );
});

VirtualizedGrid.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.shape({
                element: PropTypes.node,
                height: PropTypes.number,
                width: PropTypes.number,
                widthPercent: PropTypes.number,
            }),
            PropTypes.node,
            PropTypes.shape({}),
            PropTypes.object,
        ])
    ).isRequired,
    cols: PropTypes.number.isRequired,
    itemHeight: PropTypes.number,
    innerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
};

VirtualizedGrid.defaultProps = {
    itemHeight: null,
    innerRef: null,
};

export default VirtualizedGrid;
