import React from 'react';
import PropTypes from 'prop-types';

import { ApiPropType } from './proptypes';

const ApiContext = React.createContext(null);

export const useApi = () => React.useContext(ApiContext);

const ApiProvider = ({ children, api }) => (
    <ApiContext.Provider value={api}>{children}</ApiContext.Provider>
);

ApiProvider.propTypes = {
    api: ApiPropType.isRequired,
    children: PropTypes.node.isRequired,
};

export default ApiProvider;
