import { RESOURCE_TYPE_RESOURCE } from '../api/resources';
import { createResourceSlice } from '../redux/resource/createResourceSlice';

const resourceTypesSlice = createResourceSlice({
    resource: RESOURCE_TYPE_RESOURCE,
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,

    indexResourceTypes,
    showResourceType,
    storeResourceType,
    updateResourceType,
    archiveResourceType,
    restoreResourceType,
    destroyResourceType,

    searchResourceTypes,
    autocompleteResourceTypes,
    suggestResourceTypes,
} = resourceTypesSlice.actions;

export const {
    selectResourceTypeById,
    selectResourceTypesById,
    makeResourceTypesByIdsSelector,
    selectAllResourceTypeIds,
    selectAllResourceTypes,
    selectResourceTypesLoading,
    selectResourceTypesInitialized,
    selectResourceTypeLoading,
    selectResourceTypeInitialized,
} = resourceTypesSlice.selectors;

export const { useResourceTypes, useResourceType } = resourceTypesSlice.hooks;

export default resourceTypesSlice.reducer;
