import React, { useEffect, useRef } from 'react';
import * as PropTypes from 'prop-types';
import { useFieldFast } from '../../../modules/form/hooks';

const ValueChangeAutofocus = ({ name, FocusComponent, ...other }) => {
    const componentRef = useRef();
    const lastAutofocusChangedValue = useRef(null);
    const [{ value }] = useFieldFast(name);

    useEffect(() => {
        if (value !== lastAutofocusChangedValue.current) {
            lastAutofocusChangedValue.current = value;

            if (value && componentRef?.current) {
                setTimeout(() => {
                    componentRef.current.focus();
                }, 12);
            }
        }
    }, [value]);

    return <FocusComponent focusRef={componentRef} {...other} />;
};

ValueChangeAutofocus.propTypes = {
    name: PropTypes.string.isRequired,
    FocusComponent: PropTypes.elementType.isRequired,
};

export default ValueChangeAutofocus;
