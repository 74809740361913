import React from 'react';
import * as PropTypes from 'prop-types';
import TextInput from './TextInput';

export const generateDigits = (digits = 0, decimal = 1, postfix = 0) => {
    if (!digits) {
        return decimal || 1;
    }
    return `${decimal}.${new Array(postfix ? digits - 1 : digits).fill(0).join('')}${
        postfix ? postfix : ''
    }`;
};

const NumberInput = ({ name, label, digits, min, max, numberFormat, locale, ...other }) => {
    const formatInput = (input = null) => {
        if (!input) {
            return null;
        }

        if (typeof input !== 'string' && !Number.isNaN(input)) {
            return input;
        }

        const parsableInput = input.includes(',') ? input.replace(',', '.') : input;
        return digits ? parseFloat(parsableInput) : parseInt(parsableInput);
    };

    const formatValue = (value = null) => {
        if (!value) {
            return '';
        }

        const floatValue = typeof value === 'string' ? parseFloat(value) : value;

        if (numberFormat && locale) {
            return floatValue.toLocaleString(locale, numberFormat);
        }

        if (digits) {
            return floatValue.toFixed(digits);
        }

        return floatValue.toString();
    };

    return (
        <TextInput
            name={name}
            label={label}
            inputProps={{
                type: 'number',
                min,
                max,
                step: generateDigits(digits, 0, 5),
            }}
            onFormatInput={formatInput}
            onFormatValue={formatValue}
            size="small"
            fullWidth
            {...other}
        />
    );
};

NumberInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    digits: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    numberFormat: PropTypes.instanceOf(Intl.NumberFormat),
    locale: PropTypes.string,
};

NumberInput.defaultProps = {
    label: null,
    digits: 0,
    min: 0,
    max: 100000,
    numberFormat: null,
    locale: null,
};

export default NumberInput;
