import { DATACONTAINER_RESOURCE } from '../api/resources';
import { selectCardById } from '../cards/selectors';

export const selectDatacontainersById = state => state[DATACONTAINER_RESOURCE].byId;

export const selectDatacontainerById = (state, id) => selectDatacontainersById(state)[id];

export const selectDatacontainerByCardId = (state, cardId) => {
    const card = selectCardById(state, cardId);
    return card ? selectDatacontainerById(state, card.datacontainer_id) : null;
};

export const selectAllDatacontainerIds = state => state[DATACONTAINER_RESOURCE].allIds;
