import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import LaunchIcon from '@mui/icons-material/Launch';
import Button from '../form/base/Button';
import { READ } from '../../modules/abilities/actions';
import { SubjectPropType } from '../../modules/abilities/proptypes';

const OpenButton = ({ onClick, disabled, subject }) => {
    const { t } = useTranslation();

    return (
        <Button
            onClick={onClick}
            startIcon={<LaunchIcon />}
            disabled={disabled}
            action={READ}
            subject={subject}
            data-test-id="OpenButton"
        >
            {t('components.ResourceTableRowDetails.open')}
        </Button>
    );
};

OpenButton.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    subject: SubjectPropType,
};

OpenButton.defaultProps = {
    onClick: null,
    disabled: false,
    subject: null,
};

export default OpenButton;
