import { RESOURCE_RESOURCE } from '../api/resources';

export const indexResources = (params, meta) => (dispatch, getState, api) =>
    api[RESOURCE_RESOURCE].index(params, meta);

export const showResource = (params, meta) => (dispatch, getState, api) =>
    api[RESOURCE_RESOURCE].show(params, meta);

export const storeResource = (params, meta) => (dispatch, getState, api) =>
    api[RESOURCE_RESOURCE].store(params, meta);

export const updateResource = (params, meta) => (dispatch, getState, api) =>
    api[RESOURCE_RESOURCE].update(params, meta);

export const destroyResource = (params, meta) => (dispatch, getState, api) =>
    api[RESOURCE_RESOURCE].destroy(params, meta);
