import {
    amber,
    blue,
    blueGrey,
    brown,
    cyan,
    deepOrange,
    deepPurple,
    green,
    indigo,
    lightBlue,
    lightGreen,
    lime,
    orange,
    pink,
    purple,
    red,
    teal,
} from '@mui/material/colors';
import { FLAT_UNPLANNED } from '../calendar/utils';

export const avatarCharColorMapping = {
    a: blueGrey['500'],
    b: blue['500'],
    c: blueGrey['500'],
    d: brown['500'],
    e: cyan['500'],
    f: deepOrange['500'],
    g: deepPurple['500'],
    h: green['500'],
    i: indigo['500'],
    j: lightBlue['500'],
    k: lightGreen['500'],
    l: lime['500'],
    m: orange['500'],
    n: pink['500'],
    o: purple['500'],
    p: red['500'],
    q: red['500'],
    r: teal['500'],
    s: orange['500'],
    t: amber['500'],
    u: blue['500'],
    v: amber['500'],
    w: cyan['500'],
    x: green['500'],
    y: purple['500'],
    z: indigo['500'],
};

export const getColor = (name, color = null) => {
    if (color) {
        return `${color.startsWith('#') ? '' : '#'}${color}`;
    }

    if (name === FLAT_UNPLANNED.key) {
        return indigo['500'];
    }

    if (name && typeof name === 'string') {
        return avatarCharColorMapping[name[0].toLowerCase()];
    }

    return avatarCharColorMapping['y'];
};
