import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

const CalendarContext = React.createContext(null);

export const useCalendar = () => React.useContext(CalendarContext);

const CalendarProvider = ({ calendarId, editingAppointment, isCreate, children }) => {
    const [selectedAppointmentTypeId, setSelectedAppointmentTypeId] = useState(null);
    const getAllValues = useRef(null);

    const setAllValues = callback => {
        getAllValues.current = callback;
    };

    const value = useMemo(
        () => ({
            calendarId,
            editingAppointment,
            isCreate,
            selectedAppointmentTypeId,
            setSelectedAppointmentTypeId,
            getAllValues,
            setAllValues,
        }),
        [
            calendarId,
            editingAppointment,
            isCreate,
            selectedAppointmentTypeId,
            setSelectedAppointmentTypeId,
            getAllValues,
            setAllValues,
        ]
    );

    return <CalendarContext.Provider value={value}>{children}</CalendarContext.Provider>;
};

CalendarProvider.propTypes = {
    calendarId: PropTypes.string.isRequired,
    editingAppointment: PropTypes.shape({}),
    isCreate: PropTypes.bool,
    children: PropTypes.node,
};

CalendarProvider.defaultProps = {
    editingAppointment: null,
    isCreate: false,
    children: null,
};

export default CalendarProvider;
