import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as PropTypes from 'prop-types';
import { selectContactTypesById } from '../../modules/contactTypes/selectors';
import { IdPropType } from '../../modules/proptypes';
import { indexContactTypes } from '../../modules/contactTypes/actions';
import MultiSelect from './formik/MultiSelect';

const ContactTypeMultiSelect = ({ name, label, disabledIds }) => {
    const dispatch = useDispatch();

    const contactTypes = Object.values(useSelector(selectContactTypesById)).map(contactType => ({
        label: contactType.name,
        value: contactType.id,
        disabled: disabledIds.includes(contactType.id),
    }));

    useEffect(() => {
        dispatch(indexContactTypes());
    }, [dispatch]);

    return <MultiSelect label={label} name={name} options={contactTypes} size="small" />;
};

ContactTypeMultiSelect.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    disabledIds: PropTypes.arrayOf(IdPropType),
};

ContactTypeMultiSelect.defaultProps = {
    label: null,
    disabledIds: [],
};

export default ContactTypeMultiSelect;
