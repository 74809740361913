import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const Number = ({ value }) => {
    const { t } = useTranslation();
    return value !== null ? value.toString().replace(/\./, t('numberDelimiter')) : null;
};

Number.propTypes = {
    value: PropTypes.number,
};

Number.defaultProps = {
    value: null,
};

export default Number;
