import React from 'react';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ConditionalWrapper } from '../../../utils/ConditionalWrapper';
import Can from '../../../../modules/abilities/Can';

const AutocompleteAddButton = ({ onClick, label, action, subject, field }) => {
    const { t } = useTranslation();

    return (
        <ConditionalWrapper
            condition={!!(subject && action)}
            wrapper={_children => (
                <Can I={action} this={subject} field={field}>
                    {_children}
                </Can>
            )}
        >
            <Button
                startIcon={<AddIcon />}
                onMouseDown={onClick}
                data-test-id="autocomplete-add-button"
                variant="text"
                color="primary"
                size="small"
                fullWidth
            >
                {label || t('form.AutocompleteAddButton.default')}
            </Button>
        </ConditionalWrapper>
    );
};

AutocompleteAddButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string,
    action: PropTypes.string,
    subject: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    field: PropTypes.string,
};

AutocompleteAddButton.defaultProps = {
    label: null,
    action: null,
    subject: null,
    field: null,
};

export default AutocompleteAddButton;
