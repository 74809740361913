import React from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';
import InfoIcon from '@mui/icons-material/Info';

import { selectAllMetadataByContext } from '../../modules/metadata/selectors';
import { CARD_CONTACTS_DETAILS_CONTEXT } from '../../modules/metadata/context';
import { CONTACT_DATA_RESOURCE } from '../../modules/api/resources';
import ContactLink from './ContactLink';
import { ConditionalWrapper } from '../utils/ConditionalWrapper';
import { ContactDataPropType } from '../../modules/contactData/proptypes';
import Icon from '../icons/Icon';

const useStyles = makeStyles(theme => ({
    value: {
        whiteSpace: 'nowrap',
    },
    methodIcon: {
        color: theme.palette.primary.main,
        position: 'relative',
        height: 16,
    },
    icon: {
        fontSize: 16,
        color: theme.palette.text.secondary,
    },
    infoIcon: {
        fontSize: 13,
        color: theme.palette.grey['400'],
    },
    infoBadge: {
        right: -10,
        top: 8,
    },
}));

const METHOD_ICONS = {
    web: 'public',
    fax: 'print',
    mobile: 'smartphone',
};

const ContactData = ({
    contactData,
    variant,
    color,
    labelStyle,
    valueStyle,
    showIcon,
    iconStyle,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const metadata = useSelector(state =>
        selectAllMetadataByContext(state, CARD_CONTACTS_DETAILS_CONTEXT)
    );
    const contactDataComment = metadata.find(
        item => item.resource === CONTACT_DATA_RESOURCE && item.itemId === contactData.id
    );

    return (
        <>
            <Box style={iconStyle}>
                {contactData.method && showIcon && (
                    <Typography variant={variant} className={classes.methodIcon}>
                        <Icon
                            type={METHOD_ICONS[contactData.method] || contactData.method}
                            className={classes.icon}
                        />
                    </Typography>
                )}
            </Box>
            {contactData.value && (
                <>
                    <Box style={labelStyle}>
                        <Typography variant={variant} color={color}>
                            {t(`contactData.type.${contactData.type}`)}:
                        </Typography>
                    </Box>
                    <Box style={valueStyle}>
                        <ConditionalWrapper
                            condition={contactDataComment}
                            wrapper={children => (
                                <Badge
                                    badgeContent={<InfoIcon className={classes.infoIcon} />}
                                    classes={{ badge: classes.infoBadge }}
                                >
                                    <Tooltip title={contactDataComment.value} disableInteractive>
                                        {children}
                                    </Tooltip>
                                </Badge>
                            )}
                        >
                            <span>
                                <ContactLink
                                    value={contactData.value}
                                    method={contactData.method}
                                    className={classes.value}
                                    variant={variant}
                                />
                            </span>
                        </ConditionalWrapper>
                    </Box>
                </>
            )}
        </>
    );
};

ContactData.propTypes = {
    contactData: ContactDataPropType.isRequired,
    variant: PropTypes.string,
    color: PropTypes.string,
    labelStyle: PropTypes.shape({}),
    valueStyle: PropTypes.shape({}),
    showIcon: PropTypes.bool,
    iconStyle: PropTypes.shape({}),
};

ContactData.defaultProps = {
    variant: 'body2',
    color: 'textSecondary',
    labelStyle: undefined,
    valueStyle: undefined,
    showIcon: false,
    iconStyle: undefined,
};

export default ContactData;
