import React, { useEffect, useRef } from 'react';
import * as PropTypes from 'prop-types';
import { CircularProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    parent: {
        maxHeight: 256,
        overflowX: 'hidden',
        overflowY: 'auto',
    },
    load: {
        height: '100%',
        margin: '2px auto',
    },
    empty: {
        fontStyle: 'italic',
        color: theme.palette.grey[600],
    },
    label: {
        paddingRight: theme.spacing(1),

        '& em': {
            fontWeight: 700,
            fontStyle: 'normal',
        },
    },
}));

const EditorSuggestEntry = ({
    mention,
    theme,
    searchValue,
    isFocused,
    displayComponent,
    ...parentProps
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const ref = useRef(null);

    useEffect(() => {
        if (
            ref.current &&
            !ref.current.parentElement.parentElement.classList.contains(classes.parent)
        ) {
            ref.current.parentElement.parentElement.classList.add(classes.parent);
        }
    }, [classes.parent]);

    if (mention.loading) {
        return (
            <div key="suggestLoading" className={classes.load}>
                <CircularProgress size={16} />
            </div>
        );
    }

    if (mention.empty) {
        return (
            <Typography key="suggestEmpty" variant="body1" align="center" className={classes.empty}>
                {t('components.EditorSuggest.empty')}
            </Typography>
        );
    }

    if (displayComponent) {
        const DisplayComponent = displayComponent;
        return (
            <div ref={ref} {...parentProps}>
                <DisplayComponent
                    ref={ref}
                    mention={mention}
                    theme={theme}
                    searchValue={searchValue}
                    isFocused={isFocused}
                />
            </div>
        );
    }

    return (
        <div ref={ref} {...parentProps}>
            <Typography
                dangerouslySetInnerHTML={{ __html: mention.highlight }}
                className={classes.label}
            />
            {mention.desc && (
                <Typography
                    variant="body2"
                    dangerouslySetInnerHTML={{ __html: mention.desc }}
                    className={classes.label}
                />
            )}
        </div>
    );
};

EditorSuggestEntry.propTypes = {
    mention: PropTypes.shape({}).isRequired,
    theme: PropTypes.shape({}), // wenn ich diese oben aus den Params rausnehme, kommen beim Tippen von @ Warnings
    searchValue: PropTypes.string,
    isFocused: PropTypes.bool,
    displayComponent: PropTypes.func,
};

EditorSuggestEntry.defaultProps = {
    theme: null,
    searchValue: null,
    isFocused: false,
    displayComponent: null,
};

export default EditorSuggestEntry;
