import { COMPANY_RESOURCE } from '../api/resources';

export const indexCompanies = params => (dispatch, getState, api) =>
    api[COMPANY_RESOURCE].index(params);

export const showCompany = params => (dispatch, getState, api) =>
    api[COMPANY_RESOURCE].show(params);

export const autocompleteCompany = params => (dispatch, getState, api) =>
    api[COMPANY_RESOURCE].autocomplete(params);

export const storeCompany = params => (dispatch, getState, api) =>
    api[COMPANY_RESOURCE].store(params);

export const updateCompany = params => (dispatch, getState, api) =>
    api[COMPANY_RESOURCE].update(params);

export const destroyCompany = params => (dispatch, getState, api) =>
    api[COMPANY_RESOURCE].destroy(params);
