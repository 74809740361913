import { PRIORITY_RESOURCE } from '../api/resources';
import { createResourceSlice } from '../redux/resource/createResourceSlice';

const prioritySlice = createResourceSlice({
    resource: PRIORITY_RESOURCE,
    byKey: '',
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,
    indexPriorities,
    showPriority,
    storePriority,
    updatePriority,
    archivePriority,
    restorePriority,
    destroyPriority,
} = prioritySlice.actions;
export const {
    selectPrioritiesById,
    selectPriorityById,
    makePrioritiesByIdsSelector,
    selectAllPriorityIds,
    selectAllPriorities,
    selectPrioritiesLoading,
    selectPrioritiesInitialized,
    selectPriorityLoading,
    selectPriorityInitialized,
    selectPriorityIdsBy,
    makePrioritiesBySelector,
    selectPriorityLoadingBy,
    selectPriorityInitializedBy,
} = prioritySlice.selectors;

export const { usePriorities, usePriority, usePrioritiesBy } = prioritySlice.hooks;
export default prioritySlice.reducer;
