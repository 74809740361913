import React from 'react';
import { useSelector } from 'react-redux';

import { CARD_RESOURCE, CONTACT_TYPE_RESOURCE } from '../../modules/api/resources';
import { CARD_DETAILS_HEADER_CONTEXT } from '../../modules/metadata/context';
import MetadataList from '../metadata/MetadataList';
import { IdPropType } from '../../modules/proptypes';
import { selectAllMetadataByContext } from '../../modules/metadata/selectors';
import PersonsFilteredByType from '../metadata/PersonsFilteredByType';
import { PERSONS_FILTERED_BY_TYPE_COMPONENT } from '../../modules/metadata/components';
import { selectCardById } from '../../modules/cards/selectors';
import CardAddress from '../card/CardAddress';
import CardParentTitle from '../metadata/CardParentTitle';

const CardHeaderContext = ({ cardId }) => {
    const contactTypeMetadata = useSelector(state =>
        selectAllMetadataByContext(state, CARD_DETAILS_HEADER_CONTEXT, CONTACT_TYPE_RESOURCE)
    );
    const card = useSelector(state => selectCardById(state, cardId));

    return (
        <>
            <PersonsFilteredByType
                cardId={cardId}
                itemId={contactTypeMetadata
                    .filter(({ component }) => component === PERSONS_FILTERED_BY_TYPE_COMPONENT)
                    .map(({ itemId }) => itemId)}
            />
            {cardId && (
                <MetadataList
                    resource={CARD_RESOURCE}
                    id={cardId}
                    context={CARD_DETAILS_HEADER_CONTEXT}
                />
            )}
            {card && card.address && <CardAddress address={card.address} />}
            {card && card.parent_id && (
                <CardParentTitle cardParentId={card.parent_id} variant="body2" />
            )}
        </>
    );
};

CardHeaderContext.propTypes = {
    cardId: IdPropType,
};

CardHeaderContext.defaultProps = {
    cardId: null,
};

export default CardHeaderContext;
