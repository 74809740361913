/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { SEARCH_RESOURCE } from '../api/resources';

const searchesSlice = createSlice({
    name: SEARCH_RESOURCE,
    initialState: {
        byId: {},
        allIds: [],
    },
    reducers: {
        indexFulfilled: () => {},
        showFulfilled: () => {},
        storeFulfilled: () => {},
        updateFulfilled: () => {},
        destroyFulfilled: () => {},
    },
});
export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,
} = searchesSlice.actions;
export default searchesSlice.reducer;
