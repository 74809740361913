import React from 'react';
import * as PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import Text from './Text';

const useStyles = makeStyles(theme => ({
    label: {
        gridColumnStart: 1,
    },

    text: {
        gridColumnStart: 2,
    },
}));

const TextWithLabel = ({ name, ...other }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <>
            <Typography className={classes.label} variant="body2" color="textSecondary">
                {t(`metadata.${name}`)}
            </Typography>
            <Text className={classes.text} {...other} />
        </>
    );
};

TextWithLabel.propTypes = {
    name: PropTypes.string.isRequired,
};

export default TextWithLabel;
