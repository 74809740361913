import React from 'react';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { selectPersonFunctionById } from '../../modules/personFunction/selectors';

const PersonFunction = ({ personFunctionId }) => {
    const personFunction = useSelector(state => selectPersonFunctionById(state, personFunctionId));
    return personFunction ? <Typography variant="inherit">{personFunction.name}</Typography> : null;
};

PersonFunction.propTypes = {
    personFunctionId: PropTypes.number,
};

PersonFunction.defaultProps = {};

export default PersonFunction;
