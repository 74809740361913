import Reacts, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Link from '../utils/Link';
import { cardPath, taskPath } from '../../routes/paths';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import { showDialog } from '../../modules/dialogs/dialogSlice';
import { TASK_DIALOG } from '../dialogs/dialogs';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles({
    fullWidth: {
        maxWidth: '100% !important',
        overflow: 'hidden',
    },

    link: {
        maxWidth: '100% !important',
        overflow: 'hidden',
    },

    cutText: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
});

const TaskLinks = ({ task, card, taskRedirect, m }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const taskLabel = useMemo(() => {
        if (task?.name) {
            if (card?.id_order) {
                return `${task.name} (${card.id_order})`;
            }

            return task.name;
        }
        return '';
    }, [task, card]);

    const handleTaskClick = event => {
        if (!taskRedirect && task?.id && !(event.metaKey || event.ctrlKey)) {
            event.preventDefault();
            dispatch(showDialog({ type: TASK_DIALOG, props: { taskId: task.id } }));
        }
    };

    return (
        <Grid container className={classes.fullWidth} direction="column" m={m}>
            <Grid item className={classes.fullWidth}>
                <Link to={cardPath(card.id)} newTab className={classes.link} icon>
                    <Tooltip title={t('components.Card.title')} placement="top" disableInteractive>
                        <Typography variant="h6" className={classes.cutText}>
                            {card?.title}
                        </Typography>
                    </Tooltip>
                </Link>
            </Grid>
            <Grid item className={classes.fullWidth}>
                <Link
                    to={taskPath(task.id)}
                    onClick={handleTaskClick}
                    newTab
                    className={classes.link}
                    icon
                >
                    <Tooltip title={t('components.AppointmentPrintView.task')} disableInteractive>
                        <Typography variant="h6" className={classes.cutText}>
                            {taskLabel}
                        </Typography>
                    </Tooltip>
                </Link>
            </Grid>
        </Grid>
    );
};

TaskLinks.propTypes = {
    task: PropTypes.shape({}).isRequired,
    card: PropTypes.shape({}).isRequired,
    taskRedirect: PropTypes.bool,
    m: PropTypes.number,
};

TaskLinks.defaultProps = {
    taskRedirect: false,
    m: 2,
};

export default TaskLinks;
