import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { IdPropType, RefPropType } from '../../../../modules/proptypes';
import { indexPersonFunctions } from '../../../../modules/personFunction/actions';
import PersonContactForm from '../../PersonContactForm';
import PersonContact from '../../PersonContact';

const PersonContactPage = ({
    contactId,
    contextCardId,
    contextParentId,
    extraRef,
    submitRef,
    edit,
    onDone,
    hasIdCustom = true,
    openContact,
}) => {
    const dispatch = useDispatch();

    // TODO: load only once, not everytime the dialog opens
    useEffect(() => {
        dispatch(indexPersonFunctions());
    }, [dispatch]);

    return edit ? (
        <PersonContactForm
            contactId={contactId}
            contextParentId={contextParentId}
            contextCardId={contextCardId}
            submitRef={submitRef}
            extraRef={extraRef}
            onDone={onDone}
            hasIdCustom={hasIdCustom}
            openContact={openContact}
        />
    ) : (
        <PersonContact contactId={contactId} />
    );
};

PersonContactPage.propTypes = {
    contactId: IdPropType,
    contextCardId: IdPropType,
    contextParentId: IdPropType,
    submitRef: RefPropType,
    extraRef: RefPropType,
    edit: PropTypes.bool.isRequired,
    onDone: PropTypes.func,
};

PersonContactPage.defaultProps = {
    contactId: null,
    contextParentId: null,
    contextCardId: null,
    submitRef: null,
    extraRef: null,
    onDone: null,
};

export default PersonContactPage;
