import { createSelector } from '@reduxjs/toolkit';
import { CONTACT_DATA_RESOURCE } from '../api/resources';

export const selectAllContactDataById = state => state[CONTACT_DATA_RESOURCE].byId;

export const selectContactDataById = (state, id) => selectAllContactDataById(state)[id];

export const selectContactDataIdsByContactId = state => state[CONTACT_DATA_RESOURCE].byContactId;

export const selectContactDataIdsWhereContactId = (state, contactId) =>
    selectContactDataIdsByContactId(state)[contactId];

export const makeSelectContactDatasByContactId = () =>
    createSelector(
        selectContactDataIdsWhereContactId,
        selectAllContactDataById,
        (contactDataIds, contactDataById) => {
            return (contactDataIds || []).map(contactDataId => contactDataById[contactDataId]);
        }
    );

export const selectContactDatasByContactId = makeSelectContactDatasByContactId();
