import { EditorState, Modifier } from 'draft-js';
import { remarkableCommonPlugin } from './common';

const createMention = (elementData, state) => {
    const matchUserId = /data-user-id="(.*?)"/.exec(elementData);
    const matchUserName = /data-user-name="(.*?)"/.exec(elementData);
    const matchUserAvatar = /data-user-avatar="(.*?)"/.exec(elementData);

    state.push({
        type: 'mention_open',
        name: matchUserName[1],
        id: parseInt(matchUserId[1], 10),
        avatar: matchUserAvatar.length ? matchUserAvatar[1] : '',
        level: state.level,
    });

    state.push({
        type: 'text',
        content: `@${matchUserName[1]}`,
        level: state.level + 1,
    });

    state.push({
        type: 'mention_close',
        level: state.level,
    });
};

export const activateMention = editorState => {
    const selectionState = editorState.getSelection();
    const contentState = editorState.getCurrentContent();

    const currentBlockKey = selectionState.getStartKey();
    const currentBlock = contentState
        .getBlocksAsArray()
        .find(block => block.key === currentBlockKey);

    const offset = selectionState.getAnchorOffset();
    const charBeforeCursor = currentBlock.text.charAt(Math.max(offset - 1, 0));
    const insertText = charBeforeCursor === ' ' || offset === 0 ? '@' : ' @';

    const newContentState = Modifier.insertText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        insertText
    );

    const newEditorState = EditorState.push(editorState, newContentState, 'insert-fragment');
    return EditorState.moveFocusToEnd(newEditorState);
};

// Beispiel: https://github.com/Rosey/markdown-draft-js/blob/master/test/markdown-to-draft.spec.js#L415
export const remarkableMentionPlugin = remarkable => {
    remarkable.inline.ruler.push('mention', (state, silent) => {
        const mentionRegEx = /^<mention (.*?)>.*?<\/mention>/;
        return remarkableCommonPlugin(state, silent, mentionRegEx, createMention);
    });
};
