import React from 'react';
import * as PropTypes from 'prop-types';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import IconButton from '../form/base/IconButton';
import { SubjectPropType } from '../../modules/abilities/proptypes';

const RemoveIconButton = ({ onClick, size, contrast, disabled, subject, action }) => (
    <IconButton
        onClick={onClick}
        size={size}
        contrast={contrast}
        disabled={disabled}
        subject={subject}
        action={action}
    >
        <RemoveCircleOutlineIcon />
    </IconButton>
);

RemoveIconButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    size: PropTypes.string,
    contrast: PropTypes.bool,
    disabled: PropTypes.bool,
    action: PropTypes.string,
    subject: SubjectPropType,
};

RemoveIconButton.defaultProps = {
    size: null,
    contrast: false,
    disabled: false,
    subject: null,
    action: null,
};

export default RemoveIconButton;
