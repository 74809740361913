/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import { EMPTY_LIST, EMPTY_OBJECT } from '../redux/resource/selectors';
import { union } from 'lodash/array';
import { isBefore } from 'date-fns';
import { getGroupByFromGroupKey } from '../aggregation/group';

export const getInitialListState = ({
    resource,
    continuous,
    orderBy,
    search,
    staticParams,
    unique,
    allIds = [],
}) => ({
    resource: resource || null,
    continuous: continuous || false,
    allIds,
    grouped: {},
    aggs: null,
    aggsLoading: false,
    loading: false,
    initialized: false,
    page: 1,
    count: 0,
    total: 0,
    search: search || EMPTY_OBJECT,
    orderBy: orderBy || EMPTY_LIST,
    params: {},
    staticParams: staticParams || {},
    highlights: EMPTY_OBJECT,
    lastRequestTime: null,
    ...(unique ? { byId: {} } : {}),
});

export const getInitialGroupState = ({ groupBy, loading = false, params = {} }) => ({
    groupBy,
    aggs: null,
    groups: null,
    nestedLists: {},
    loading,
    params,
});

export const getInitialGroupNestedListState = ({
    allIds = [],
    loading = false,
    page = 1,
    count = 0,
    total = 0,
    params = {},
}) => ({
    allIds,
    loading,
    page,
    count,
    total,
    params,
});

const listsSlice = createSlice({
    name: 'lists',
    initialState: {
        byId: {},
    },
    reducers: {
        initList: (state, action) => {
            const { listId, resource, continuous, orderBy, search, staticParams, unique, allIds } =
                action.payload;

            if (!state.byId[listId]) {
                state.byId[listId] = getInitialListState({
                    resource,
                    continuous,
                    orderBy,
                    search,
                    staticParams,
                    unique,
                    allIds,
                });
            }
        },

        saveSearch: {
            prepare: (payload, meta) => ({ payload, meta }),
            reducer: (state, action) => {
                const { listId } = action.meta;
                state.byId[listId].search = action.payload;
            },
        },

        saveOrderBy: {
            prepare: (payload, meta) => ({ payload, meta }),
            reducer: (state, action) => {
                const { listId } = action.meta;
                state.byId[listId].orderBy = action.payload;
            },
        },

        searchPending: (state, action) => {
            const { listId, page, params } = action.payload;

            const list = state.byId[listId];
            list.loading = true;
            list.page = page;
            list.params = params;
        },

        searchFulfilled: {
            prepare: (payload, meta) => ({ payload, meta }),
            reducer: (state, action) => {
                const {
                    listId,
                    current_page: currentPage,
                    last_page: lastPage,
                    total,
                    requestTime = null,
                } = action.meta;

                const list = state.byId[listId];

                if (
                    requestTime &&
                    list.lastRequestTime &&
                    isBefore(requestTime, list.lastRequestTime)
                ) {
                    return false;
                }

                if (list.continuous && currentPage > 1) {
                    action.payload.forEach(item => {
                        list.allIds.push(item.id);
                    });
                } else if (list.allIds.length !== 0 || action.payload.length !== 0) {
                    const newIds = action.payload.map(item => item.id);
                    /* prevent unnecessary re-rendering if the object is the same anyways */
                    if (!isEqual(list.allIds, newIds)) {
                        list.allIds =
                            list.continuous && !action?.meta?.reset
                                ? union(list.allIds, newIds)
                                : newIds;
                    }
                }

                if (list.byId) {
                    action.payload.forEach(item => {
                        list.byId[item?.id] = item;
                    });
                }

                list.page = currentPage;
                list.count = lastPage;
                list.total = total;
                list.loading = false;
                list.initialized = true;
                list.highlights = action.meta.highlights;

                if (action.requestTime) {
                    list.lastRequestTime = action.requestTime;
                }
            },
        },

        searchError: {
            prepare: (error, meta) => ({ payload: error, error: true, meta }),
            reducer: (state, action) => {
                const { listId } = action.meta;

                const list = state.byId[listId];
                list.loading = false;
                list.initialized = true;
            },
        },

        insertItem: {
            prepare: (payload, meta) => ({ payload, meta }),
            reducer: (state, action) => {
                const { listId, position: newPosition = 0 } = action.meta;
                const itemId = action.payload.id;
                const list = state.byId[listId];

                if (!list) {
                    return;
                }

                const oldPosition = list.allIds.findIndex(id => id === itemId);

                if (
                    newPosition === false ||
                    newPosition < 0 ||
                    /* Don't insert items at the last position, it should be loaded by subsequent pagination requests */
                    (oldPosition !== newPosition &&
                        newPosition === list.allIds.length &&
                        list.page < list.count - 1)
                ) {
                    /* remove the old item */
                    if (oldPosition >= 0) {
                        list.allIds.splice(oldPosition, 1);
                    }
                } else if (oldPosition < 0) {
                    /* item was not in the list before -> just insert it at the given position */
                    list.allIds.splice(newPosition, 0, itemId);
                    /* remove last item to keep the total item count when not on last page */
                    if (list.page < list.count - 1) {
                        list.allIds.splice(list.allIds.length - 1, 1);
                    }
                } else if (oldPosition > newPosition) {
                    /* new position is ahead of the old one, removing it first has no effect on the insert position */
                    list.allIds.splice(oldPosition, 1);
                    list.allIds.splice(newPosition, 0, itemId);
                } else if (oldPosition < newPosition) {
                    /* new position is after the old one, inserting it first has no effect on the remove position */
                    list.allIds.splice(newPosition, 0, itemId);
                    list.allIds.splice(oldPosition, 1);
                }
            },
        },

        removeItem: {
            prepare: (payload, meta) => ({ payload, meta }),
            reducer: (state, action) => {
                if (action.meta) {
                    const { resource, listId } = action.meta;
                    Object.entries(state.byId).forEach(([listIdentifier, list]) => {
                        if (listId ? listIdentifier === listId : list.resource === resource) {
                            const index = list.allIds.findIndex(id => id === action.payload);
                            if (index >= 0) {
                                list.allIds.splice(index, 1);
                            }
                        }
                    });
                }
            },
        },

        clearList: {
            prepare: (payload, meta) => ({ payload, meta }),
            reducer: (state, action) => {
                const listId = action?.payload?.listId;

                if (state.byId && listId && state.byId[listId]) {
                    state.byId[listId].allIds = [];
                }
            },
        },

        updateAllIds: (state, action) => {
            if (action.payload && typeof action.payload === 'object') {
                const { listId, allIds } = action.payload;

                if (state.byId[listId] && listId && allIds && Array.isArray(allIds)) {
                    state.byId[listId].allIds = allIds;
                }
            }
        },

        insertAggregations: {
            prepare: (payload, meta) => ({ payload, meta }),
            reducer: (state, action) => {
                const { payload, meta } = action;

                if (typeof meta === 'object') {
                    const { listId } = meta;

                    if (payload && 'aggs' in payload) {
                        state.byId[listId].aggs = payload.aggs;
                        state.byId[listId].aggsLoading = false;
                    }
                }
            },
        },

        groupPending: (state, action) => {
            if (action.payload) {
                const { listId, params } = action.payload?.meta || {};

                if (
                    listId &&
                    'group_by' in params &&
                    Array.isArray(params.group_by) &&
                    params.group_by.length
                ) {
                    const groupBy = params.group_by.map(group => group?.key);
                    const groupKey = groupBy.join();
                    const previousGroupState = state.byId[listId].grouped[groupKey];
                    const updatedValues = {
                        loading: true,
                        groupBy,
                        params,
                    };

                    state.byId[listId].grouped = {
                        ...state.byId[listId].grouped,
                        [groupKey]: previousGroupState
                            ? {
                                  ...previousGroupState,
                                  ...updatedValues,
                              }
                            : getInitialGroupState(updatedValues),
                    };
                }
            }
        },

        insertGroup: {
            prepare: (payload, meta) => ({ payload, meta }),
            reducer: (state, action) => {
                const { payload, meta } = action;

                if (typeof payload === 'object' && typeof meta === 'object') {
                    const { listId, groupKey } = meta;
                    const groupBy = getGroupByFromGroupKey(groupKey);

                    if (
                        typeof listId === 'string' &&
                        typeof groupKey === 'string' &&
                        Array.isArray(groupBy)
                    ) {
                        const { aggs, groups } = payload;
                        const previousGroupState = state.byId[listId].grouped[groupKey];

                        if (typeof aggs === 'object' && Array.isArray(groups)) {
                            state.byId[listId].grouped = {
                                ...state.byId[listId].grouped,
                                [groupKey]: {
                                    ...(previousGroupState || getInitialGroupState({ groupBy })),
                                    loading: false,
                                    groups,
                                    aggs,
                                },
                            };
                        }
                    }
                }
            },
        },

        groupNestedListPending: (state, action) => {
            const { meta } = action.payload;

            if (meta) {
                const { params, listId, groupKey, groupNestedListKey } = meta;
                const { page = 1 } = params;

                if (listId && groupKey && groupNestedListKey) {
                    const list = state.byId[listId];
                    const nestedLists = list?.grouped?.[groupKey]?.nestedLists;

                    if (typeof nestedLists === 'object' && groupNestedListKey in nestedLists) {
                        nestedLists[groupNestedListKey].loading = true;
                        nestedLists[groupNestedListKey].page = page;
                        nestedLists[groupNestedListKey].params = params;
                    } else {
                        nestedLists[groupNestedListKey] = getInitialGroupNestedListState({
                            allIds: null,
                            loading: true,
                            page,
                            params,
                        });
                    }
                }
            }
        },

        insertGroupNestedList: {
            prepare: (payload, meta) => ({ payload, meta }),
            reducer: (state, action) => {
                const { payload, meta } = action;

                if (Array.isArray(payload) && typeof meta === 'object') {
                    const {
                        listId,
                        groupKey,
                        groupNestedListKey,
                        current_page: page,
                        last_page: count,
                        total,
                    } = meta;

                    if (
                        typeof listId === 'string' &&
                        typeof groupKey === 'string' &&
                        typeof groupNestedListKey === 'string'
                    ) {
                        const group = state.byId[listId].grouped?.[groupKey];

                        if (typeof group === 'object' && typeof group?.nestedLists === 'object') {
                            const newIds = payload.map(item => item.id);

                            if (
                                groupNestedListKey in group.nestedLists &&
                                typeof group.nestedLists[groupNestedListKey] === 'object' &&
                                Array.isArray(group.nestedLists[groupNestedListKey].allIds)
                            ) {
                                group.nestedLists[groupNestedListKey].allIds.push(...newIds);
                                group.nestedLists[groupNestedListKey].page = page;
                                group.nestedLists[groupNestedListKey].count = count;
                                group.nestedLists[groupNestedListKey].total = total;
                            } else {
                                group.nestedLists[groupNestedListKey] =
                                    getInitialGroupNestedListState({
                                        allIds: newIds,
                                        page,
                                        count,
                                        total,
                                    });
                            }
                        }
                    }
                }
            },
        },
    },
});

export const {
    initList,
    saveSearch,
    saveOrderBy,
    searchPending,
    searchFulfilled,
    searchError,
    insertItem,
    removeItem,
    clearList,
    updateAllIds,
    insertAggregations,
    groupPending,
    insertGroup,
    groupNestedListPending,
    insertGroupNestedList,
} = listsSlice.actions;

export default listsSlice.reducer;
