import React from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { selectPersonById } from '../../modules/persons/selectors';
import { IdPropType } from '../../modules/proptypes';

const PersonName = ({ personId, variant, className }) => {
    const person = useSelector(state => selectPersonById(state, personId));
    return (
        <Typography
            component="span"
            data-test-id="PersonName"
            variant={variant}
            className={className}
        >
            {person && person.title && <span>{person.title} </span>}
            {person && person.display_name && <span>{person.display_name} </span>}
        </Typography>
    );
};

PersonName.propTypes = {
    personId: IdPropType,
    variant: PropTypes.string,
    className: PropTypes.string,
};

PersonName.defaultProps = {
    personId: null,
    variant: null,
    className: null,
};

export default PersonName;
