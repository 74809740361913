import React, { forwardRef, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Form from '../form/formik/Form';
import { APPOINTMENT_RESOURCE } from '../../modules/api/resources';
import { EMPTY_APPOINTMENT, makeAppointmentSchema } from '../../modules/appointments/schema';
import { makeStyles } from '@mui/styles';
import { Box, Grid } from '@mui/material';
import SubmitButton from '../form/formik/SubmitButton';
import CalendarAppointmentTypeSelect from '../calendar/form/CalendarAppointmentTypeSelect';
import { IdPropType } from '../../modules/proptypes';
import CategorySelect from '../form/CategorySelect';
import PrioritySelect from '../priorities/PrioritySelect';
import TextInput from '../form/formik/TextInput';
import DateInput from '../form/formik/DateInput';
import LockButton from '../form/base/LockButton';
import CalendarDetailResources from '../calendar/form/CalendarDetailResources';
import classNames from 'classnames';
import DeleteButton from '../buttons/DeleteButton';
import AddressViewIconButton from '../buttons/AddressViewIconButton';
import WatchContainer from '../form/formik/WatchContainer';
import DateTimeInput from '../form/formik/DateTimeInput';
import Checkbox from '../form/formik/Checkbox';
import CalendarFormDynamicInputs from '../calendar/form/CalendarFormDynamicInputs';
import CompletedButton from '../form/formik/CompletedButton';
import { useSelector } from 'react-redux';
import { selectAllAppointmentTypes } from '../../modules/appointmentTypes/appointmentTypeSlice';
import AppointmentFormTaskValueWorker from '../calendar/form/AppointmentFormTaskValueWorker';
import FallbackValue from '../form/formik/FallbackValue';
import CategoryDisplay from '../categories/CategoryDisplay';
import Priority from '../priorities/Priority';
import { selectAppointmentById } from '../../modules/appointments/appointmentSlice';
import { useCan } from '../../modules/abilities/hooks';
import { DELETE } from '../../modules/abilities/actions';
import AppointmentResponsibles from '../calendar/form/AppointmentResponsibles';
import AddressSearchInput from '../address/AddressSearchInput';
import TouchedWatcher from '../form/formik/TouchedWatcher';
import {
    APPOINTMENT_TYPE_AWAY_KEYS,
    APPOINTMENT_TYPE_KEYS,
} from '../../modules/appointments/utils';
import AppointmentFormTitleWorker from './AppointmentFormTitleWorker';
import AppointmentsAvailabilityProvider from './availability/AppointmentsAvailabilityContext';

const useStyles = makeStyles(theme => ({
    formWrap: {
        flexWrap: 'nowrap',
    },

    formContainer: {
        padding: 12,
        flexGrow: 1,
        overflowY: 'auto',
        flexWrap: 'nowrap',
    },

    toolbar: {
        width: '100%',
        padding: 6,
        alignItems: 'center',
        justifyContent: 'space-between',
        borderTop: `1px solid ${theme.palette.grey[200]}`,
    },

    fullHeight: {
        flex: 1,
        height: '100%',
        overflow: 'hidden',
    },

    relative: {
        position: 'relative',
    },

    footer: {
        marginBottom: 6,
    },
}));

const AppointmentForm = ({
    initialValues,
    isCreate,
    fullHeight,
    cardId,
    forceResource,
    onSubmit,
    onDelete,
    onDirty,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const originalAppointment = useSelector(state =>
        selectAppointmentById(state, initialValues?.originalId)
    );
    const appointmentTypes = useSelector(selectAllAppointmentTypes);
    const canDelete = useCan(DELETE, originalAppointment || APPOINTMENT_RESOURCE);

    const subject = useMemo(
        () => (initialValues?.originalId ? originalAppointment : APPOINTMENT_RESOURCE),
        [initialValues, originalAppointment]
    );

    const appointmentSchema = useMemo(() => {
        if (cardId) {
            const requireEntityOnAppointmentTypeIds = appointmentTypes.reduce((carry, type) => {
                if (type?.component === 'task') {
                    return [...carry, type.id];
                }

                return carry;
            }, []);

            return makeAppointmentSchema({ requireEntityOnAppointmentTypeIds });
        }

        return makeAppointmentSchema({});
    }, [cardId, appointmentTypes]);

    const handleSubmit = values => onSubmit(values);

    return (
        <Form
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={appointmentSchema}
            subject={subject}
            className={classNames({
                [classes.fullHeight]: fullHeight,
            })}
        >
            {onDirty ? <TouchedWatcher onChange={onDirty} /> : null}
            <Grid
                container
                direction="column"
                className={classNames({
                    [classes.formWrap]: true,
                    [classes.fullHeight]: fullHeight,
                })}
            >
                <Grid item className={classes.formContainer}>
                    <Grid container mt={2} spacing={1} alignItems="center">
                        <Grid item xs={4} className={classes.relative}>
                            <CalendarAppointmentTypeSelect
                                name="appointmentTypeId"
                                originalName="appointment_type_id"
                                resourcesName="grouped"
                                label={t('components.CalendarAppointmentForm.appointmentType')}
                                forceResource={forceResource}
                                isCreate={isCreate}
                            />
                        </Grid>
                        <CalendarFormDynamicInputs props={{ ...(cardId ? { cardId } : {}) }} />
                        <AppointmentFormTaskValueWorker />
                    </Grid>

                    <Grid container mt={2} spacing={1}>
                        <Grid item xs={4}>
                            <FallbackValue
                                name="priorityId"
                                fallbackName="task.priorityId"
                                FallbackComponent={({ fallback }) => (
                                    <Priority priorityId={fallback} />
                                )}
                                FormComponent={({ hideLabel }) => (
                                    <PrioritySelect
                                        name="priorityId"
                                        originalName="priority_id"
                                        label={t('components.AppointmentForm.priority')}
                                        InputLabelProps={{
                                            shrink: hideLabel ? true : undefined,
                                        }}
                                        notched={hideLabel || undefined}
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs>
                            <FallbackValue
                                name="categoryId"
                                fallbackName="task.categoryId"
                                FallbackComponent={({ fallback }) => (
                                    <CategoryDisplay categoryId={fallback} />
                                )}
                                FormComponent={({ hideLabel }) => (
                                    <CategorySelect
                                        name="categoryId"
                                        originalName="category_id"
                                        label={t('components.AppointmentForm.category')}
                                        InputLabelProps={{
                                            shrink: hideLabel ? true : undefined,
                                        }}
                                        notched={hideLabel || undefined}
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>

                    <AppointmentFormTitleWorker />
                    <FallbackValue
                        name="title"
                        fallbackName="titleFallback"
                        style={{ marginTop: 22 }}
                        applyOnClick
                        FormComponent={forwardRef(({ hideLabel }, ref) => (
                            <TextInput
                                name="title"
                                originalName="name"
                                defaultValue={initialValues?.title || ''}
                                label={t('components.AppointmentForm.title')}
                                shrink={hideLabel ? true : undefined}
                                size="small"
                                ref={ref}
                            />
                        ))}
                    />

                    <Grid container mt={2} spacing={1} alignItems="center">
                        <WatchContainer
                            name="locked"
                            WatchComponent={({ value: locked }) => (
                                <WatchContainer
                                    name="allDay"
                                    WatchComponent={({ value: allDay }) => {
                                        const DateComponent = allDay ? DateInput : DateTimeInput;

                                        return (
                                            <>
                                                <Grid item xs>
                                                    <DateComponent
                                                        name="startDate"
                                                        originalName="starts_at"
                                                        label={t(
                                                            'components.AppointmentForm.startDate'
                                                        )}
                                                        disabled={locked}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs>
                                                    <DateComponent
                                                        name="endDate"
                                                        originalName="ends_at"
                                                        label={t(
                                                            'components.AppointmentForm.endDate'
                                                        )}
                                                        disabled={locked}
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </>
                                        );
                                    }}
                                />
                            )}
                        />
                        <Grid item>
                            <LockButton name="locked" />
                        </Grid>
                    </Grid>

                    <Grid container mt={2}>
                        <Grid item pl={1} xs>
                            <Checkbox
                                name="allDay"
                                originalName="all_day"
                                label={t('components.AppointmentForm.allDay')}
                            />
                        </Grid>
                        <Grid item xs></Grid>
                    </Grid>

                    <AppointmentsAvailabilityProvider
                        startDateFieldName="startDate"
                        endDateFieldName="endDate"
                        allDayFieldName="allDay"
                        appointmentId={initialValues?.originalId}
                    >
                        <CalendarDetailResources />
                    </AppointmentsAvailabilityProvider>

                    <Typography variant="h2" color="primary" mt={4}>
                        {t('components.AppointmentForm.detail')}
                    </Typography>

                    <Grid container mt={2} spacing={1} alignItems="center">
                        <Grid item xs>
                            <AddressSearchInput
                                namePrefix="address"
                                label={t('components.Address.title')}
                            />
                        </Grid>
                        <WatchContainer
                            name="address"
                            WatchComponent={({ value: address }) => {
                                const finalAddress =
                                    address?.id ||
                                    address?.address1 ||
                                    address?.address2 ||
                                    address?.zip ||
                                    address?.city ||
                                    typeof address === 'string'
                                        ? address
                                        : initialValues.card?.address;

                                if (
                                    (typeof finalAddress === 'string' &&
                                        finalAddress.length !== 0) ||
                                    finalAddress?.id
                                ) {
                                    return (
                                        <Grid item>
                                            <AddressViewIconButton
                                                {...(typeof finalAddress === 'string'
                                                    ? { full: finalAddress }
                                                    : finalAddress)}
                                            />
                                        </Grid>
                                    );
                                }

                                return null;
                            }}
                        />
                    </Grid>

                    <FallbackValue
                        name="notes"
                        fallbackName="task.description"
                        style={{ marginTop: 22 }}
                        multiline
                        applyOnClick
                        FormComponent={forwardRef(({ hideLabel }, ref) => (
                            <TextInput
                                name="notes"
                                originalName="description"
                                defaultValue={initialValues?.notes || ''}
                                label={t('components.AppointmentForm.desc')}
                                style={{ marginTop: 16 }}
                                minRows={4}
                                shrink={hideLabel ? true : undefined}
                                ref={ref}
                                multiline
                            />
                        ))}
                    />

                    <AppointmentResponsibles />

                    <Box className={classes.footer} />
                </Grid>
                <Grid item>
                    <Grid container className={classes.toolbar}>
                        <Grid item>
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item>
                                    <DeleteButton
                                        onClick={onDelete}
                                        disabled={isCreate || !canDelete}
                                        color="error"
                                        noConfirm
                                    />
                                </Grid>
                                <Grid item>
                                    <CompletedButton
                                        name="completedAt"
                                        originalName="completed_at"
                                        subject={subject}
                                        variant="text"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <SubmitButton variant="contained" />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Form>
    );
};

AppointmentForm.propTypes = {
    initialValues: PropTypes.shape({}),
    isCreate: PropTypes.bool,
    fullHeight: PropTypes.bool,
    cardId: IdPropType,
    requireEntity: PropTypes.bool,
    forceResource: PropTypes.shape({ id: IdPropType, key: PropTypes.string }),
    onSubmit: PropTypes.func,
    onDelete: PropTypes.func,
};

AppointmentForm.defaultProps = {
    initialValues: EMPTY_APPOINTMENT,
    isCreate: false,
    fullHeight: false,
    cardId: null,
    requireEntity: false,
    forceResource: null,
    onSubmit: () => null,
    onDelete: () => null,
};

export default AppointmentForm;
