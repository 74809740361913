import { ROLE_RESOURCE } from '../api/resources';

export const indexRoles = params => (dispatch, getState, api) => api[ROLE_RESOURCE].index(params);

export const storeRole = person => (dispatch, getState, api) => api[ROLE_RESOURCE].store(person);

export const updateRole = person => (dispatch, getState, api) => api[ROLE_RESOURCE].update(person);

export const destroyRole = params => (dispatch, getState, api) =>
    api[ROLE_RESOURCE].destroy(params);
