import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { useFieldFast } from '../../modules/form/hooks';
import { suggestProtocols } from '../../modules/protocol/protocolsSlice';
import TypedAutocomplete from '../form/base/autocomplete/TypedAutocomplete';
import { IdPropType } from '../../modules/proptypes';

const ProtocolNameAutocomplete = ({ cardId, name, label }) => {
    const [{ value }, { error }, { setValue }] = useFieldFast(name);

    const handleSelection = useCallback(selected => setValue(selected?.value || ''), [setValue]);

    return (
        <TypedAutocomplete
            label={label}
            name={name}
            initialValue={value}
            fetchAction={suggestProtocols}
            extraParams={{ card_id: cardId }}
            onChange={handleSelection}
            getOptionDisabled={option => option.disabled}
            error={error}
            size="small"
            fullWidth
            autofocus
            freeSolo
            hideTypes
        />
    );
};

ProtocolNameAutocomplete.propTypes = {
    cardId: IdPropType.isRequired,
    name: PropTypes.string,
    label: PropTypes.string,
};

ProtocolNameAutocomplete.defaultProps = {};

export default ProtocolNameAutocomplete;
