/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { searchFulfilled, searchPending, storeFulfilled, destroyFulfilled } from '../contactsSlice';

const contactsListSlice = createSlice({
    name: 'contactSearch',
    initialState: {
        allIds: [],
        loading: false,
        page: 1,
        count: 0,
        search: {},
    },
    reducers: {
        saveSearch: (state, action) => {
            state.search = action.payload;
        },
    },
    extraReducers: {
        [searchPending]: (state, action) => {
            if (!action.meta.listId) {
                state.loading = true;
                state.page = action.meta.params.page;
            }
        },
        [searchFulfilled]: (state, action) => {
            if (!action.meta.listId) {
                state.loading = false;

                /* we need to remove the contact if we refresh the contact but get 0 results */
                // todo: refactor / delete block?
                if (action.meta.socketReaction && action.meta.params.id) {
                    const stale = state.byId[action.meta.params.id];
                    if (stale && !action.payload.find(({ id }) => id === action.meta.params.id)) {
                        state.allIds = state.allIds.filter(id => id !== stale.id);
                    }
                }

                state.allIds = action.payload.map(contact => contact.id);
                state.page = action.meta.current_page;
                state.count = action.meta.last_page;
            }
        },

        [storeFulfilled]: (state, action) => {
            state.allIds.unshift(action.payload.id);
        },

        [destroyFulfilled]: (state, action) => {
            /** remove contact if:
             * - it was hard deleted
             * - it was archived and archived contacts are currently filtered out
             */
            if (action.meta.params.forceDelete || !state.search.withTrashed) {
                state.allIds = state.allIds.filter(id => id !== action.payload);
            }
        },
    },
});

export const { saveSearch } = contactsListSlice.actions;

export default contactsListSlice.reducer;
