import React from 'react';
import * as PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    warning: {
        color: theme.palette.primary.dark,
    },
}));

const Warning = ({ warnings }) => {
    const classes = useStyles();

    return <Typography className={classes.warning}>{warnings && warnings.join('\n')}</Typography>;
};

Warning.propTypes = {
    warnings: PropTypes.arrayOf(PropTypes.string),
};

export default Warning;
