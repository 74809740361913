import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import React from 'react';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import LoadingOverlay from './LoadingOverlay';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        width: '100%',
        height: '100%',
    },

    fade: {
        position: 'absolute',
        width: '100%',
        textAlign: 'center',
        top: 0,
    },

    small: {},

    medium: {
        padding: theme.spacing(4),
    },
}));

const FadeContainer = React.forwardRef((props, ref) => <Box {...props} ref={ref} />);

const LoadingBox = ({
    loading,
    initialized,
    children,
    variant,
    size,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
}) => {
    const classes = useStyles();

    return (
        <Box
            className={classes.root}
            marginTop={marginTop}
            marginBottom={marginBottom}
            marginLeft={marginLeft}
            marginRight={marginRight}
        >
            <Fade
                in={!loading || initialized}
                style={{
                    height: '100%',
                    transitionDelay: loading ? '300ms' : '0ms',
                    opacity: !loading || initialized ? 1 : 0,
                }}
                timeout={{
                    enter: 300,
                    exit: 800,
                }}
            >
                <FadeContainer
                    style={{
                        height: '100%',
                    }}
                >
                    <LoadingOverlay loading={loading} initialized={initialized} />
                    {children}
                </FadeContainer>
            </Fade>
            <Fade
                in={loading && !initialized}
                className={classNames(classes.fade, {
                    [classes.small]: size === 'small',
                    [classes.medium]: size !== 'small',
                })}
                style={{
                    display: loading && !initialized ? 'block' : 'none',
                    transitionDelay: loading ? '800ms' : '0ms',
                }}
                timeout={{
                    enter: 1000,
                }}
                unmountOnExit
            >
                <Box>
                    {variant === 'linear' && <LinearProgress />}
                    {variant === 'circular' && (
                        <CircularProgress size={size === 'small' ? 12 : undefined} />
                    )}
                </Box>
            </Fade>
        </Box>
    );
};

LoadingBox.propTypes = {
    loading: PropTypes.bool.isRequired,
    initialized: PropTypes.bool,
    children: PropTypes.node,
    variant: PropTypes.string,
    size: PropTypes.string,

    marginTop: PropTypes.number,
    marginBottom: PropTypes.number,
    marginLeft: PropTypes.number,
    marginRight: PropTypes.number,
};

LoadingBox.defaultProps = {
    initialized: false,
    children: null,
    variant: 'linear',
    size: '',

    marginTop: undefined,
    marginBottom: undefined,
    marginLeft: undefined,
    marginRight: undefined,
};

export default LoadingBox;
