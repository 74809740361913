import React, { useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import RepeatIcon from '@mui/icons-material/Repeat';
import Typography from '@mui/material/Typography';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CategoryDisplay from '../../categories/CategoryDisplay';
import PrioritySmallForm from '../../priorities/PrioritySmallForm';
import { IdPropType } from '../../../modules/proptypes';
import IconButton from '../../form/base/IconButton';
import { useAppointmentDuplication } from '../../../modules/appointments/hooks';
import CompletedIconButton from '../../buttons/CompletedIconButton';
import { APPOINTMENT_RESOURCE } from '../../../modules/api/resources';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectAppointmentById,
    updateAppointment,
} from '../../../modules/appointments/appointmentSlice';
import { exportDate } from '../../../modules/datetime/utils';
import { useCan } from '../../../modules/abilities/hooks';
import { CREATE, DELETE, READ, UPDATE } from '../../../modules/abilities/actions';
import AppointmentPrintView from '../../appointments/AppointmentPrintView';
import PrintIconButton from '../../buttons/PrintIconButton';
import { indexContacts } from '../../../modules/contacts/actions';
import { CARD_CONTEXTS } from '../../../modules/contacts/contexts';
import { indexContactTypes } from '../../../modules/contactTypes/actions';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    head: {
        height: 50,
        width: 'calc(100% - 36px)',
        margin: 16,
        marginTop: -50,
        top: 0,
        overflow: 'hidden',
    },
    repeat: {
        display: 'flex',
        alignItems: 'center',
    },
    repeatIcon: {
        height: 16,
        width: 16,
        marginRight: 4,
    },
});

const color = '#757575';

const CalendarDetailHead = ({
    appointmentId,
    categoryId,
    priorityId,
    rRule,
    rCount,
    rIndex,
    completedAt,
    onDelete,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { submitDuplicate } = useAppointmentDuplication(appointmentId);
    const [completedLoading, setCompletedLoading] = useState(false);
    const appointment = useSelector(state => selectAppointmentById(state, appointmentId));
    const canUpdate = useCan(UPDATE, appointment || APPOINTMENT_RESOURCE);
    const printPrepareProgress = useRef(0);

    const preparePrint = onPrepared => {
        const cardId = appointment.card?.id;

        const checkPrepared = (max = 0) => {
            if (printPrepareProgress.current === max) {
                printPrepareProgress.current = 0;
                onPrepared();
            } else {
                printPrepareProgress.current = printPrepareProgress.current + 1;
            }
        };

        if (cardId) {
            const max = 1;

            dispatch(
                indexContacts({
                    limit: 250,
                    card_id: cardId,
                    contexts: CARD_CONTEXTS,
                })
            ).then(() => checkPrepared(max));

            dispatch(indexContactTypes()).then(() => checkPrepared(max));
        } else {
            checkPrepared();
        }
    };

    const handlePriority = newPriorityId => {};

    const handleCompletedChange = () => {
        if (canUpdate) {
            setCompletedLoading(true);

            dispatch(
                updateAppointment({
                    id: appointmentId,
                    completed_at: completedAt ? null : exportDate(null, true),
                })
            )
                .then(() => {
                    setCompletedLoading(false);
                })
                .catch(() => {
                    setCompletedLoading(false);
                });
        }
    };

    return (
        <Grid container alignItems="center" className={classes.head}>
            {rRule ? (
                <Grid item mr={2} className={classes.repeat}>
                    <RepeatIcon className={classes.repeatIcon} />
                    {!Number.isNaN(rIndex) ? (
                        <Typography fontSize={12}>
                            {rCount ? `${rIndex + 1}/${rCount}` : rIndex}
                        </Typography>
                    ) : null}
                </Grid>
            ) : null}
            {categoryId ? (
                <Grid item mr={2} style={{ marginBottom: 2 }}>
                    <CategoryDisplay categoryId={categoryId} />
                </Grid>
            ) : null}
            {
                priorityId ? (
                    <Grid item mr={2}>
                        <PrioritySmallForm priorityId={priorityId} onlyIcon enableTooltip />
                    </Grid>
                ) : null /* onChange={canUpdate && handlePriority} */
            }
            <Grid item xs></Grid>
            <Grid item>
                <IconButton
                    onClick={onDelete}
                    action={DELETE}
                    subject={appointment || APPOINTMENT_RESOURCE}
                    style={{ color }}
                    size="large"
                    label={t('components.Calendar.CalendarDetailHead.delete')}
                >
                    <DeleteOutlinedIcon />
                </IconButton>
            </Grid>
            <Grid item>
                <PrintIconButton
                    subject={appointment || APPOINTMENT_RESOURCE}
                    style={{ color }}
                    size="large"
                    onPrepare={preparePrint}
                    label={t('components.Calendar.CalendarDetailHead.print')}
                >
                    <AppointmentPrintView appointmentId={appointmentId} />
                </PrintIconButton>
            </Grid>
            <Grid item>
                <IconButton
                    onClick={submitDuplicate}
                    action={CREATE}
                    subject={appointment || APPOINTMENT_RESOURCE}
                    style={{ color }}
                    size="large"
                    label={t('components.Calendar.CalendarDetailHead.copy')}
                >
                    <ContentCopyIcon />
                </IconButton>
            </Grid>
            <Grid item>
                <CompletedIconButton
                    isCompleted={!!completedAt}
                    subject={appointment || APPOINTMENT_RESOURCE}
                    name="completed_at"
                    loading={completedLoading}
                    onChange={handleCompletedChange}
                    style={{ color }}
                />
            </Grid>
        </Grid>
    );
};

CalendarDetailHead.propTypes = {
    appointmentId: IdPropType,
    categoryId: IdPropType,
    priorityId: IdPropType,
    rRule: PropTypes.string,
    rCount: PropTypes.number,
    rIndex: PropTypes.number,
    onDelete: PropTypes.func,
};

CalendarDetailHead.defaultProps = {
    appointmentId: null,
    categoryId: null,
    priorityId: null,
    rRule: null,
    rCount: null,
    rIndex: null,
    onDelete: () => null,
};

export default CalendarDetailHead;
