import { createResourceSlice } from '../redux/resource/createResourceSlice';
import { TASK_DURATION_RESOURCE } from '../api/resources';

const taskDurationSlice = createResourceSlice({
    resource: TASK_DURATION_RESOURCE,
    byKey: '',
});
export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,
    indexTaskDurations,
    showTaskDuration,
    storeTaskDuration,
    updateTaskDuration,
    archiveTaskDuration,
    restoreTaskDuration,
    destroyTaskDuration,
} = taskDurationSlice.actions;
export const {
    selectTaskDurationById,
    makeTaskDurationsByIdsSelector,
    selectAllTaskDurationIds,
    selectAllTaskDurations,
    selectTaskDurationsLoading,
    selectTaskDurationsInitialized,
    selectTaskDurationLoading,
    selectTaskDurationInitialized,
    selectTaskDurationIdsBy,
    makeTaskDurationsBySelector,
    selectTaskDurationLoadingBy,
    selectTaskDurationInitializedBy,
} = taskDurationSlice.selectors;
export const { useTaskDurations, useTaskDuration, useTaskDurationsBy } = taskDurationSlice.hooks;
export default taskDurationSlice.reducer;
