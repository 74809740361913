import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import TypedAutocomplete from '../../form/base/autocomplete/TypedAutocomplete';
import { autocompleteTasks, selectTaskById } from '../../../modules/tasks/tasksSlice';
import { useTranslation } from 'react-i18next';
import { TASK_RESOURCE } from '../../../modules/api/resources';
import { CALENDAR_RESOURCE_MODELS } from '../../../modules/calendar/utils';
import { useSelector } from 'react-redux';
import { useField } from 'formik';
import { selectCardById } from '../../../modules/cards/selectors';

const CalendarTaskAutocomplete = ({ name, params }) => {
    const { t } = useTranslation();
    const [{ value: selectedId }, , { setValue: onIdChange }] = useField(`${name}Id`);
    const [{ value: selectedType }, , { setValue: onTypeChange }] = useField(`${name}Type`);
    const selectedTask = useSelector(state => selectTaskById(state, selectedId));
    const selectedTaskCard = useSelector(state => selectCardById(state, selectedTask?.card_id));

    const value = useMemo(() => {
        if (selectedTask?.name) {
            if (selectedTaskCard?.id_order) {
                return `${selectedTask.name} (${selectedTaskCard.id_order})`;
            }

            return selectedTask.name;
        }
        return '';
    }, [selectedTask, selectedTaskCard]);

    const handleChange = newValue => {
        if (newValue) {
            onIdChange(newValue.id);

            const taskModelKey = CALENDAR_RESOURCE_MODELS[TASK_RESOURCE];

            if (selectedType !== taskModelKey) {
                onTypeChange(taskModelKey);
            }
        } else {
            onIdChange(null);
            onTypeChange(null);
        }
    };

    return (
        <TypedAutocomplete
            name={`${name}Id`}
            originalName={`${name}_id`}
            value={value}
            initialValue={value}
            onChange={handleChange}
            fetchAction={autocompleteTasks}
            expectedType={TASK_RESOURCE}
            extraParams={params}
            label={t('components.CalendarTaskAutocomplete.title')}
            noOptionsText={t('components.CalendarTaskAutocomplete.empty')}
            size="small"
            fullWidth
        />
    );
};

CalendarTaskAutocomplete.propTypes = {
    name: PropTypes.string.isRequired,
    params: PropTypes.shape({}),
};

CalendarTaskAutocomplete.defaultProps = {
    params: {},
};

export default CalendarTaskAutocomplete;
