import { put, takeEvery } from '@redux-saga/core/effects';

import { RESOURCE_RESOURCE, GROUP_RESOURCE } from '../api/resources';
import { indexFulfilled as indexGroupFulfilled } from '../groups/groupsSlice';

function* nestedIndexFulfilled(action) {
    const groups = action.payload.reduce((carry, { groups: groupItems }) => {
        if (groupItems) {
            carry.push(...groupItems);
        }

        return carry;
    }, []);

    if (groups.length > 0) {
        yield put(indexGroupFulfilled(groups, { key: GROUP_RESOURCE }));
    }
}

function* nestedShowFulfilled(action) {
    yield put(indexGroupFulfilled(action.payload.groups, { key: GROUP_RESOURCE }));
}

export default [
    takeEvery(`${RESOURCE_RESOURCE}/indexFulfilled`, nestedIndexFulfilled),
    takeEvery(`${RESOURCE_RESOURCE}/searchFulfilled`, nestedIndexFulfilled),
    takeEvery(`${RESOURCE_RESOURCE}/showFulfilled`, nestedShowFulfilled),
    takeEvery(`${RESOURCE_RESOURCE}/updateFulfilled`, nestedShowFulfilled),
];
