import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useField } from 'formik';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import classNames from 'classnames';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import { READ, WRITE } from '../../../modules/abilities/actions';
import ContextualCan from '../../../modules/abilities/ContextualCan';
import { useContextualCan } from '../../../modules/abilities/hooks';
import Icon from '../../icons/Icon';
import { CircularProgress, Grid } from '@mui/material';
import EditIconButton from '../../buttons/EditIconButton';

const useStyles = makeStyles(theme => ({
    fullWidth: {
        width: '100%',
    },

    disabled: {
        color: theme.palette.text.disabled,
    },

    maxHeight: {
        maxHeight: 250,
    },

    optionIcon: {
        fontSize: '1.2rem',
        marginRight: theme.spacing(1),
        float: 'left',
    },

    menuItemFlex: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
}));

const MultiSelect = ({
    name,
    options,
    label,
    variant,
    size,
    fullWidth,
    disabled,
    I,
    ItemEditFormPopupProps,
    ...other
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [field, meta, helpers] = useField(name);
    const canDo = useContextualCan(I ? I : WRITE, name);

    const InputComponent = <OutlinedInput label={label} />;

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current ? inputLabel.current.offsetWidth : 0);
    }, []);

    const optionLabel = option => {
        const currentLabel = option.label;
        return option.value ? (
            <>
                {option.icon && (
                    <Icon
                        type={option.icon}
                        className={classes.optionIcon}
                        style={{
                            color: option.iconColor
                                ? `${option.iconColor.startsWith('#') ? '' : '#'}${
                                      option.iconColor
                                  }`
                                : 'inherit',
                        }}
                    />
                )}
                {currentLabel || option.value}
            </>
        ) : (
            <em>{currentLabel}</em>
        );
    };

    const handleChange = (event, item) => {
        if (!options.some(option => option.value === item.props.value && option.disabled)) {
            const index = field.value.indexOf(item.props.value);
            if (index > -1) {
                const tmp = [...field.value];
                tmp.splice(index, 1);
                helpers.setValue(tmp);
            } else {
                helpers.setValue([...field.value, item.props.value]);
            }
        }
    };

    const selectedItems = useMemo(() => {
        const selectedOptions = options.filter(option => {
            return field.value && field.value.includes(option.value);
        });

        const labels = selectedOptions.map(item => {
            const index = options.indexOf(item);
            return index > -1 ? options[index].label : '';
        });
        return labels.join(', ');
    }, [field, options]);

    const handleOnAdminEdit = (event, handler) => {
        if (handler && typeof handler === 'function') {
            event.stopPropagation();
            handler(event);
            const popupRef = ItemEditFormPopupProps?.component?.props?.popupStateRef;
            return popupRef ? popupRef.current?.open(event) : null;
        }
    };

    const adminEditItemIsLoading = option =>
        ItemEditFormPopupProps?.isLoading &&
        ItemEditFormPopupProps?.selectedEditItemValue === option.value;

    return (
        <ContextualCan I={READ} field={name}>
            <FormControl
                variant={variant !== 'plain' ? variant : 'standard'}
                className={classNames({ [classes.fullWidth]: fullWidth })}
                error={Boolean(meta.touched && meta.error)}
                style={fullWidth ? {} : { minWidth: Math.max(120, labelWidth + 14 + 32) }}
                size={size}
            >
                {label && (
                    <InputLabel
                        ref={inputLabel}
                        id={`${name}-label`}
                        className={classNames({
                            [classes.fullWidth]: fullWidth,
                        })}
                    >
                        {label}
                    </InputLabel>
                )}
                <Select
                    {...field}
                    multiple
                    labelId={`${name}-label-multi`}
                    label={label}
                    value={field.value}
                    onChange={handleChange}
                    renderValue={() => selectedItems}
                    input={InputComponent}
                    fullWidth={fullWidth}
                    disabled={disabled || !canDo}
                    MenuProps={{ classes: { paper: classes.maxHeight } }}
                    {...other}
                >
                    {options.map(option => (
                        <MenuItem
                            key={option.value}
                            value={option.value}
                            disabled={option.disabled || adminEditItemIsLoading(option)}
                            className={classes.menuItemFlex}
                        >
                            <Grid>
                                <Checkbox
                                    checked={field.value && field.value.indexOf(option.value) > -1}
                                    color="secondary"
                                    disabled={option.disabled || adminEditItemIsLoading(option)}
                                />
                                {optionLabel(option)}
                            </Grid>
                            {/* {ItemEditFormPopupProps?.canEdit &&
                                option.value &&
                                (adminEditItemIsLoading(option) ? (
                                    <CircularProgress size={14} />
                                ) : (
                                    <EditIconButton
                                        onClick={e => handleOnAdminEdit(e, option.onEdit)}
                                        size="small"
                                    />
                                ))} */}
                        </MenuItem>
                    ))}
                    {ItemEditFormPopupProps?.component}
                </Select>
                {meta.touched && meta.error && (
                    <FormHelperText error>
                        {t(`errors.${meta.error.split('.').join('')}`)}
                    </FormHelperText>
                )}
            </FormControl>
        </ContextualCan>
    );
};

MultiSelect.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    label: PropTypes.string,
    variant: PropTypes.string,
    size: PropTypes.string,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    I: PropTypes.string,
    ItemEditFormPopupProps: PropTypes.shape({
        component: PropTypes.node.isRequired,
        canEdit: PropTypes.bool,
        selectedEditItemValue: PropTypes.any,
        isLoading: PropTypes.bool,
    }),
};

MultiSelect.defaultProps = {
    label: '',
    variant: 'outlined',
    size: 'small',
    fullWidth: false,
    disabled: false,
    I: null,
    ItemEditFormPopupProps: null,
};

export default MultiSelect;
