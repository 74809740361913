import React from 'react';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import * as PropTypes from 'prop-types';
import { IdPropType } from '../../modules/proptypes';
import { selectCardById } from '../../modules/cards/selectors';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import { Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    icon: {
        opacity: 0.5,
        height: 32,
    },
});

const CardParentTitle = ({ cardParentId, variant, simple }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const parentCard = useSelector(state => selectCardById(state, cardParentId));

    return parentCard && parentCard.title ? (
        <Grid container alignItems="center" spacing={1}>
            {!simple && (
                <Grid item className={classes.icon}>
                    <StarOutlinedIcon />
                </Grid>
            )}
            <Grid item>
                <Tooltip title={t('components.Card.masterCard')} disableInteractive>
                    <Typography variant={variant}>{parentCard.title}</Typography>
                </Tooltip>
            </Grid>
        </Grid>
    ) : null;
};

CardParentTitle.propTypes = {
    cardParentId: IdPropType,
    variant: PropTypes.string,
    simple: PropTypes.bool,
};

CardParentTitle.defaultProps = {
    cardParentId: null,
    variant: undefined,
    simple: false,
};

export default CardParentTitle;
