import React from 'react';
import PropTypes from 'prop-types';
import { IdPropType, RefPropType } from '../../../../modules/proptypes';
import CompanyContactForm from '../../CompanyContactForm';
import CompanyContact from '../../CompanyContact';

const CompanyContactPage = ({ contactId, contextCardId, submitRef, extraRef, edit, onDone }) => {
    return edit ? (
        <CompanyContactForm
            contactId={contactId}
            contextCardId={contextCardId}
            submitRef={submitRef}
            extraRef={extraRef}
            onDone={onDone}
        />
    ) : (
        <CompanyContact contactId={contactId} />
    );
};

CompanyContactPage.propTypes = {
    contactId: IdPropType,
    contextCardId: IdPropType,
    submitRef: RefPropType,
    extraRef: RefPropType,
    edit: PropTypes.bool.isRequired,
    onDone: PropTypes.func,
};

CompanyContactPage.defaultProps = {
    contactId: null,
    contextCardId: null,
    submitRef: null,
    extraRef: null,
    onDone: null,
};

export default CompanyContactPage;
