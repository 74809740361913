import { useCallback, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

const FormApiValidation = ({ error }) => {
    const { errors, setErrors } = useFormikContext();

    const formatEntry = useCallback((key, value, base) => ({ ...base, [key]: value[0] }), []);

    const formatApiErrors = useCallback(
        fields => {
            let formatted = {};

            Object.entries(fields).forEach(([key, value]) => {
                formatted = formatEntry(key, value, formatted);
            });

            return formatted;
        },
        [formatEntry]
    );

    useEffect(() => {
        if (
            error &&
            error.response &&
            error.response.status === 422 &&
            error.response.data &&
            error.response.data.errors
        ) {
            setErrors({ ...errors, ...formatApiErrors(error.response.data.errors) });
        }
    }, [error, errors, formatApiErrors, setErrors]);

    return null;
};

FormApiValidation.propTypes = {
    error: PropTypes.shape({}),
};

FormApiValidation.defaultProps = {
    error: null,
};

export default FormApiValidation;
