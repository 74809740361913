import * as Yup from 'yup';

export const kumoConnectSchema = Yup.object().shape(
    {
        ip: Yup.string().when('port', {
            is: port => !port,
            then: Yup.string(),
            //.matches(/^\.\.\.$/, "required"),
            otherwise: Yup.string()
                .trim()
                .matches(
                    /^(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}$/,
                    'required'
                ),
        }),
        port: Yup.number().when('ip', {
            is: ip => !ip || !ip.trim() || ip.trim() === '...',
            then: Yup.number().nullable(),
            otherwise: Yup.number()
                .required('required')
                .typeError('invalidPort')
                .test('portValidation', 'invalidPort', value =>
                    /^((6553[0-5])|(655[0-2][0-9])|(65[0-4][0-9]{2})|(6[0-4][0-9]{3})|([1-5][0-9]{4})|([0-5]{0,5})|([0-9]{1,4}))$/.test(
                        value
                    )
                ),
        }),
    },
    ['ip', 'port']
);
