const RESOURCE_CLASS_NAME = 'App\\Models\\';

export const createResourceClassName = resourceName => `${RESOURCE_CLASS_NAME}${resourceName}`;

export const encodePath = path =>
    encodeURI(path).replaceAll('#', '%23').replaceAll('&', '&26').replaceAll('+', '%2B');

const paramFilters = {
    basic: ['limit', 'page'],
    strict: ['with', 'with_shallow', 'contexts'],
};

export const paramCleanup = (params, strict = false, filterKeys = []) => {
    const denyKeys = [...paramFilters.basic, ...(strict ? paramFilters.strict : []), ...filterKeys];

    return Object.fromEntries(Object.entries(params).filter(([key]) => !denyKeys.includes(key)));
};
