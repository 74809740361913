import React from 'react';
import * as PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { selectAddressTypeById } from '../../modules/addressTypes/selectors';
import { IdPropType } from '../../modules/proptypes';
import { selectAddressById } from '../../modules/addresses/selectors';

const useStyles = makeStyles(theme => ({
    text: {
        display: 'block',
        paddingBottom: theme.spacing(1),
    },

    title: {
        display: 'block',
        paddingBottom: theme.spacing(1),
        color: theme.palette.primary.main,
        fontWeight: 'bold',
    },

    undefined: {
        color: theme.palette.primary.light,
        fontStyle: 'italic',
    },
}));

const AddressDisplay = ({ addressId, rawAddress }) => {
    const classes = useStyles();

    const selectedAddress = useSelector(state => selectAddressById(state, addressId));
    const address = rawAddress || selectedAddress;

    const addressType = useSelector(state =>
        selectAddressTypeById(state, address && address.address_type_id)
    );

    return address ? (
        <>
            {addressType && addressType.name && (
                <Typography className={classes.title}>{addressType.name}</Typography>
            )}
            {address.address1 && (
                <Typography className={classes.text}>{address.address1}</Typography>
            )}
            {address.address2 && (
                <Typography className={classes.text}>{address.address2}</Typography>
            )}
            {(address.zip || address.city) && (
                <Typography className={classes.text}>
                    {address.zip} {address.city}
                </Typography>
            )}
            {address.state && <Typography className={classes.text}>{address.state}</Typography>}
            {address.country && <Typography className={classes.text}>{address.country}</Typography>}
        </>
    ) : null;
};

AddressDisplay.propTypes = {
    addressId: IdPropType,
    rawAddress: PropTypes.shape({}),
};

export default AddressDisplay;
