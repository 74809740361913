import React from 'react';
import * as PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import TabPanel from '../../../layout/TabPanel';
import LoadingBlock from '../../../loading/LoadingBlock';

const useStyles = makeStyles({
    fullHeight: {
        height: '100%',
    },
});

const TabDialogTabs = ({ tabs, tab, loading, fullHeight }) => {
    const classes = useStyles();

    return tabs.map((tabData, index) => (
        <TabPanel
            selected={tab === tabData.name}
            id={`card-panel-${tabData.name}-header`}
            ariaLabelledBy={`card-nav-${tabData.name}`}
            key={index}
            fullHeight={fullHeight}
        >
            <LoadingBlock isLoading={loading}>
                <Box
                    p={'borderless' in tabData && tabData.borderless ? 0 : 3}
                    className={classNames({ [classes.fullHeight]: fullHeight })}
                >
                    {tabData.content}
                </Box>
            </LoadingBlock>
        </TabPanel>
    ));
};

TabDialogTabs.propTypes = {
    tab: PropTypes.string.isRequired,
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            content: PropTypes.node.isRequired,
        }).isRequired
    ),
    loading: PropTypes.bool,
    fullHeight: PropTypes.bool,
};

TabDialogTabs.defaultProps = {
    tabs: null,
    loading: false,
    fullHeight: true,
};

export default TabDialogTabs;
