import { EMPLOYEE_RESOURCE } from '../api/resources';
import { createResourceSlice } from '../redux/resource/createResourceSlice';

const employeesSlice = createResourceSlice({
    resource: EMPLOYEE_RESOURCE,
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,

    indexEmployees,
    showEmployee,
    storeEmployee,
    updateEmployee,
    archiveEmployee,
    restoreEmployee,
    destroyEmployee,

    searchEmployees,
    autocompleteEmployees,
    suggestEmployees,
} = employeesSlice.actions;

export const {
    selectEmployeeById,
    makeEmployeesByIdsSelector,
    selectAllEmployeeIds,
    selectAllEmployees,
    selectEmployeesLoading,
    selectEmployeesInitialized,
    selectEmployeeLoading,
    selectEmployeeInitialized,
    selectEmployeeObtainablePages,
    selectEmployeeCurrentPage,
} = employeesSlice.selectors;

export const { useEmployees, useEmployee } = employeesSlice.hooks;

export default employeesSlice.reducer;
