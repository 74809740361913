import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import Form from '../form/formik/Form';
import Priority from './Priority';
import { IdPropType } from '../../modules/proptypes';
import PrioritySelect from './PrioritySelect';
import ClickBoundary from '../form/ClickBoundary';
import LoadingBox from '../loading/LoadingBox';

const PrioritySmallForm = ({ priorityId, onChange, onlyIcon, size, enableTooltip }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [isSelect, setIsSelect] = useState(false);

    const handleSubmit = newId => {
        setLoading(true);
        dispatch(onChange(newId, dispatch)).then(() => setLoading(false));
        setIsSelect(false);
    };

    return isSelect ? (
        <ClickBoundary>
            <Form initialValues={{ newId: priorityId }}>
                <PrioritySelect
                    name="newId"
                    autoFocus
                    onChange={(_, newId) => handleSubmit(newId)}
                    onClose={() => setIsSelect(false)}
                    open
                />
            </Form>
        </ClickBoundary>
    ) : (
        <ClickBoundary>
            <Box onClick={onChange ? () => setIsSelect(true) : null}>
                <LoadingBox loading={loading} variant="circular" size="small">
                    <Priority
                        priorityId={priorityId}
                        size={size}
                        onlyIcon={onlyIcon}
                        showEmpty
                        enableTooltip
                    />
                </LoadingBox>
            </Box>
        </ClickBoundary>
    );
};

PrioritySmallForm.propTypes = {
    priorityId: IdPropType.isRequired,
    onChange: PropTypes.func,
    onlyIcon: PropTypes.bool,
    size: PropTypes.string,
    enableTooltip: PropTypes.bool,
};

PrioritySmallForm.defaultProps = {
    onChange: null,
    onlyIcon: false,
    size: 'small',
    enableTooltip: false,
};

export default PrioritySmallForm;
