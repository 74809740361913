import { createResourceSlice } from '../redux/resource/createResourceSlice';
import { INVOICE_RESOURCE } from '../api/resources';

const invoiceSlice = createResourceSlice({
    resource: INVOICE_RESOURCE,
    byKey: '',
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,
    indexInvoices,
    showInvoice,
    storeInvoice,
    updateInvoice,
    archiveInvoice,
    restoreInvoice,
    destroyInvoice,
} = invoiceSlice.actions;

export const {
    selectInvoiceById,
    selectInvoicesById,
    makeInvoicesByIdsSelector,
    selectAllInvoiceIds,
    selectAllInvoices,
    selectInvoicesLoading,
    selectInvoicesInitialized,
    selectInvoiceLoading,
    selectInvoiceInitialized,
    selectInvoiceIdsBy,
    makeInvoicesBySelector,
    selectInvoiceLoadingBy,
    selectInvoiceInitializedBy,
} = invoiceSlice.selectors;

export const { useInvoices, useInvoice, useInvoicesBy } = invoiceSlice.hooks;

export default invoiceSlice.reducer;
