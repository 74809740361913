import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { IdPropType } from '../../modules/proptypes';
import { useDispatch, useSelector } from 'react-redux';
import { CREATE, UPDATE } from '../../modules/abilities/actions';
import { Box, Grid } from '@mui/material';
import Button from '../form/base/Button';
import SubmitButton from '../form/formik/SubmitButton';
import { useTranslation } from 'react-i18next';
import SlimDialog, { SLIM_DIALOG_WIDTH } from '../dialogs/slimDialog/SlimDialog';
import Badge from '@mui/material/Badge';
import { absenceTypeSchema } from '../../modules/absenceTypes/schema';
import { assembleBulkValues, cleanPartialUpdateValues } from '../../modules/form/bulk';
import { ABSENCE_TYPE_RESOURCE } from '../../modules/api/resources';
import {
    selectAbsenceTypesById,
    storeAbsenceType,
    updateAbsenceType,
} from '../../modules/absenceTypes/absenceTypeSlice';
import AbsenceTypeForm from './AbsenceTypeForm';

const AbsenceTypeDialog = ({ absenceTypeIds, create, open, onClose, onExited }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const title = useMemo(
        () => t(`components.AbsenceTypeDialog.${create ? 'create' : 'bulk'}`),
        [create]
    );

    const absenceTypesById = useSelector(selectAbsenceTypesById);

    const { bulkMode, initialValues, absenceTypes } = useMemo(() => {
        const bulkMode = !create && Array.isArray(absenceTypeIds) && absenceTypeIds.length >= 1;

        if (bulkMode) {
            const { absenceTypes } = absenceTypeIds.reduce(
                (carry, absenceTypeId) => {
                    const item = absenceTypesById[parseInt(absenceTypeId)];
                    return {
                        absenceTypes: [...carry.absenceTypes, item],
                    };
                },
                { absenceTypes: [] }
            );
            const bulkValues = assembleBulkValues(absenceTypes);

            return {
                bulkMode,
                initialValues: bulkValues,
                absenceTypes,
            };
        }

        return {
            bulkMode,
            initialValues: {
                color: null,
                name: '',
                order: null,
                type: null,
            },
            absenceTypes: [],
        };
    }, [create, absenceTypeIds, absenceTypesById]);

    const dispatchSubmit = values => {
        if (create) {
            return dispatch(storeAbsenceType(values));
        }

        if (bulkMode) {
            const items = {
                ...values,
                id: absenceTypeIds,
            };

            const cleaned = cleanPartialUpdateValues(initialValues, items);

            return dispatch(updateAbsenceType(cleaned));
        }

        if (Array.isArray(absenceTypeIds) && absenceTypeIds.length === 1) {
            return dispatch(updateAbsenceType(values));
        }

        console.error('could not distinguish submit mode (create/update/bulkUpdate)');
        return null;
    };

    const handleSubmit = values => {
        const submit = dispatchSubmit(values);

        if (submit) {
            submit.then(() => {
                onClose();
            });
        }

        return submit;
    };

    const handleDelete = () => null;

    const handleClose = props => {
        onClose(props);
    };

    const toolbarActions = useMemo(() => null, []);

    return (
        <SlimDialog
            title={title}
            open={open}
            width={SLIM_DIALOG_WIDTH.MEDIUM}
            onDelete={create || absenceTypeIds.length ? null : handleDelete}
            onClose={handleClose}
            actions={toolbarActions}
        >
            <Box m={2}>
                <AbsenceTypeForm
                    initialValues={initialValues}
                    subject={absenceTypes.length === 1 ? absenceTypes[0] : ABSENCE_TYPE_RESOURCE}
                    validationSchema={bulkMode ? null : absenceTypeSchema}
                    onSubmit={handleSubmit}
                    actionsComponent={
                        <>
                            <Grid item display="flex" alignItems="end">
                                <Button onClick={handleClose} variant="outlined" size="medium">
                                    {t('abort')}
                                </Button>
                            </Grid>
                            <Grid item display="flex" alignItems="end">
                                <Badge
                                    invisible={absenceTypeIds.length < 2}
                                    badgeContent={absenceTypeIds.length}
                                    color="primary"
                                >
                                    <SubmitButton I={create ? CREATE : UPDATE} size="medium" />
                                </Badge>
                            </Grid>
                        </>
                    }
                />
            </Box>
        </SlimDialog>
    );
};

AbsenceTypeDialog.propTypes = {
    absenceTypeIds: PropTypes.arrayOf(IdPropType.isRequired).isRequired,
    create: PropTypes.bool,
    open: PropTypes.bool,
};

AbsenceTypeDialog.defaultProps = {
    create: false,
    open: false,
};

export default AbsenceTypeDialog;
