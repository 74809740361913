import { all, put, takeEvery } from 'redux-saga/effects';
import { ATTACHMENT_RESOURCE, CONTACT_RESOURCE, PROTOCOL_ITEM_RESOURCE } from '../api/resources';
import { indexFulfilled as attachmentIndexFulfilled } from '../attachments/attachmentSlice';
import { indexFulfilled as contactIndexFulfilled } from '../contacts/contactsSlice';
import { buildAttachmentListConfig, makeAttachmentListId } from '../attachments/hooks';
import { initList, updateAllIds } from '../lists/listsSlice';

const idsFromAttachments = attachments => attachments.map(attachment => attachment.id);

const collectContacts = protocolItems =>
    protocolItems.reduce((list, protocolItem) => {
        if (protocolItem && protocolItem.contacts) {
            list.push(...protocolItem.contacts);
        }

        return list;
    }, []);

function* nestedIndexFulfilled(action) {
    const contacts = collectContacts(action.payload);

    yield put(contactIndexFulfilled(contacts, { key: CONTACT_RESOURCE }));
}

function* nestedStoreFulfilled(action) {
    const protocolItemId = action.payload.id;
    const attachments = action.payload.attachments || [];
    const allIds = idsFromAttachments(attachments);

    yield all([
        put(attachmentIndexFulfilled(attachments, { key: ATTACHMENT_RESOURCE })),
        put(contactIndexFulfilled(action.payload.contacts || [], { key: CONTACT_RESOURCE })),
        put(
            initList({
                ...buildAttachmentListConfig(protocolItemId),
                allIds,
            })
        ),
    ]);
}

function* nestedUpdateFulfilled(action) {
    const protocolItemId = action.payload.id;
    const listId = makeAttachmentListId(protocolItemId);
    const attachments = action.payload.attachments || [];
    const allIds = idsFromAttachments(attachments);

    yield all([
        put(attachmentIndexFulfilled(attachments, { key: ATTACHMENT_RESOURCE })),
        put(contactIndexFulfilled(action.payload.contacts || [], { key: CONTACT_RESOURCE })),
        put(updateAllIds({ listId, allIds })),
    ]);
}

function* nestedDestroyFulfilled() {
    // TODO: remove attachments from store somehow...
    //       currently the attachments have no reference to the cardId
    //       but the destroy action only provides the cardId
}

export default [
    takeEvery(`${PROTOCOL_ITEM_RESOURCE}/showFulfilled`, nestedStoreFulfilled),
    takeEvery(`${PROTOCOL_ITEM_RESOURCE}/indexFulfilled`, nestedIndexFulfilled),
    takeEvery(`${PROTOCOL_ITEM_RESOURCE}/searchFulfilled`, nestedIndexFulfilled),
    takeEvery(`${PROTOCOL_ITEM_RESOURCE}/storeFulfilled`, nestedStoreFulfilled),
    takeEvery(`${PROTOCOL_ITEM_RESOURCE}/updateFulfilled`, nestedUpdateFulfilled),
    takeEvery(`${PROTOCOL_ITEM_RESOURCE}/destroyFulfilled`, nestedDestroyFulfilled),
];
