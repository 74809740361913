/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { DATACONTAINER_TYPE_RESOURCE } from '../api/resources';
import {
    indexFulfilled as cardIndexFulfilled,
    showFulfilled as cardShowFulfilled,
} from '../cards/cardsSlice';
import {
    indexFulfilled as datacontainerIndexFulfilled,
    showFulfilled as datacontainerShowFulfilled,
} from '../datacontainers/datacontainersSlice';

const insertDatacontainerType = (state, type) => {
    const stale = state.byId[type.id];

    if (!stale) {
        state.allIds.push(type.id);
    }

    state.byId[type.id] = type;
};

const datacontainerTypesSlice = createSlice({
    name: DATACONTAINER_TYPE_RESOURCE,
    initialState: {
        byId: {},
        allIds: [],
    },
    reducers: {
        indexFulfilled: (state, action) => {
            action.payload.forEach(type => {
                insertDatacontainerType(state, type);
            });
        },

        showFulfilled: (state, action) => {
            insertDatacontainerType(state, action.payload);
        },

        storeFulfilled: (state, action) => {
            insertDatacontainerType(state, action.payload);
        },

        updateFulfilled: (state, action) => {
            insertDatacontainerType(state, action.payload);
        },

        destroyFulfilled: (state, action) => {
            const index = state.allIds.findIndex(id => id === action.payload);
            if (index >= 0) {
                delete state.byId[action.payload];
                state.allIds.splice(index, 1);
            }
        },
    },

    extraReducers: {
        [cardIndexFulfilled]: (state, action) => {
            action.payload.forEach(({ datacontainer }) => {
                if (datacontainer) {
                    insertDatacontainerType(state, datacontainer.datacontainer_type);
                }
            });
        },

        [cardShowFulfilled]: (state, action) => {
            const { datacontainer } = action.payload;
            if (datacontainer) {
                insertDatacontainerType(state, datacontainer.datacontainer_type);
            }
        },

        [datacontainerIndexFulfilled]: (state, action) => {
            action.payload.forEach(({ datacontainer_type: type }) => {
                insertDatacontainerType(state, type);
            });
        },

        [datacontainerShowFulfilled]: (state, action) => {
            insertDatacontainerType(state, action.payload.datacontainer_type);
        },
    },
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,
} = datacontainerTypesSlice.actions;

export default datacontainerTypesSlice.reducer;
