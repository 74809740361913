import { takeEvery, put } from 'redux-saga/effects';
import { APPOINTMENT_RESOURCE, EMPLOYEE_RESOURCE, RESOURCE_RESOURCE } from '../api/resources';
import { CALENDAR_RESOURCE_MODELS } from '../calendar/utils';
import { indexFulfilled as employeeIndexed } from '../employees/employeesSlice';
import { indexFulfilled as resourceIndexed } from '../resources/resourcesSlice';

function* nestedIndexFulfilled(list = {}) {
    const { meta } = list;
    if (meta && typeof meta === 'object') {
        const { resources } = meta;
        if (resources && typeof resources === 'object') {
            const employeeList = resources[CALENDAR_RESOURCE_MODELS[EMPLOYEE_RESOURCE]];

            if (employeeList && Array.isArray(employeeList) && employeeList.length !== 0) {
                yield put(employeeIndexed(employeeList));
            }

            const resourceList = resources[CALENDAR_RESOURCE_MODELS[RESOURCE_RESOURCE]];

            if (resourceList && Array.isArray(resourceList) && resourceList.length !== 0) {
                yield put(resourceIndexed(resourceList));
            }
        }
    }
}

export default [takeEvery(`${APPOINTMENT_RESOURCE}/indexFulfilled`, nestedIndexFulfilled)];
