import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IdPropType } from '../../../../modules/proptypes';
import LinkedCards from '../../LinkedCards';

const LinkedCardsPage = ({ contactId }) => {
    const { t } = useTranslation();

    return (
        <>
            <Typography variant="h3">{t('components.LinkContacts.headline')}</Typography>
            <LinkedCards contactId={contactId} />
        </>
    );
};

LinkedCardsPage.propTypes = {
    contactId: IdPropType,
};

LinkedCardsPage.defaultProps = {
    contactId: null,
};

export default LinkedCardsPage;
