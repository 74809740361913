import React, { useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Form from '../form/formik/Form';
import { IdPropType, RefPropType } from '../../modules/proptypes';
import LoadingBox from '../loading/LoadingBox';
import { useTranslation } from 'react-i18next';
import SubmitButton from '../form/formik/SubmitButton';
import FormPopup from '../popups/FormPopup';
import {
    destroyAppointmentType,
    selectAppointmentTypeById,
    storeAppointmentType,
    updateAppointmentType,
} from '../../modules/appointmentTypes/appointmentTypeSlice';
import AppointmentTypeFormBody from './AppointmentTypeFormBody';
import { SubjectPropType } from '../../modules/abilities/proptypes';
import { APPOINTMENT_TYPE_RESOURCE } from '../../modules/api/resources';
import { appointmentTypeSchema } from '../../modules/appointmentTypes/schema';

const AppointmentTypeForm = ({
    appointmentTypeId,
    asPopup,
    openPopupWith,
    popupStateRef,
    onLoading,
    initialValues: initialValuesProps,
    onSubmit,
    subject,
    validationSchema,
    actionsComponent,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const appointmentType = useSelector(state =>
        selectAppointmentTypeById(state, appointmentTypeId)
    );

    const initialValues = useMemo(
        () =>
            appointmentType ||
            initialValuesProps || {
                color: null,
                component: null,
                name: '',
                order: null,
                overlap: '',
                resource_config: null,
                type: null,
            },
        [appointmentType, initialValuesProps]
    );

    const handleSubmit = newValue => {
        setLoading(true);
        if (onLoading) onLoading(true);
        return dispatch(
            appointmentTypeId ? updateAppointmentType(newValue) : storeAppointmentType(newValue)
        ).finally(() => {
            setLoading(false);
            if (onLoading) onLoading(false);
        });
    };

    const handleDelete = () => dispatch(destroyAppointmentType({ id: appointmentTypeId }));

    return asPopup ? (
        <FormPopup
            popupId="appointmentTypePopupForm"
            popupStateRef={popupStateRef}
            initialValues={initialValues}
            validationSchema={validationSchema || appointmentTypeSchema}
            onSubmit={onSubmit || handleSubmit}
            onDelete={appointmentTypeId ? handleDelete : null}
            submitLabel={t('form.SubmitButton.defaultLabel')}
            abortLabel={t('abort')}
            enableReinitialize
            FormInputs={
                <Box
                    onKeyDown={e => {
                        if (e.code !== 'Escape') {
                            e.stopPropagation();
                        }
                    }}
                >
                    <AppointmentTypeFormBody autoFocus />
                </Box>
            }
        >
            <Grid container>
                <Grid item>
                    <LoadingBox loading={loading} variant="circular" size="small">
                        {openPopupWith}
                    </LoadingBox>
                </Grid>
            </Grid>
        </FormPopup>
    ) : (
        <Form
            initialValues={initialValues}
            subject={subject}
            validationSchema={validationSchema || appointmentTypeSchema}
            onSubmit={onSubmit || handleSubmit}
        >
            <AppointmentTypeFormBody />
            <Grid container spacing={2} justifyContent="flex-end">
                {actionsComponent ? (
                    actionsComponent
                ) : (
                    <SubmitButton>{t('form.SubmitButton.defaultLabel')}</SubmitButton>
                )}
            </Grid>
        </Form>
    );
};

AppointmentTypeForm.propTypes = {
    appointmentTypeId: IdPropType,
    asPopup: PropTypes.bool,
    openPopupWith: PropTypes.node,
    popupStateRef: RefPropType,
    onLoading: PropTypes.func,
    initialValues: PropTypes.shape({}),
    onSubmit: PropTypes.func,
    subject: SubjectPropType,
    validationSchema: PropTypes.shape({}),
    actionsComponent: PropTypes.node,
};

AppointmentTypeForm.defaultProps = {
    appointmentTypeId: null,
    asPopup: false,
    openPopupWith: null,
    popupStateRef: null,
    onLoading: null,
    initialValues: null,
    onSubmit: null,
    subject: APPOINTMENT_TYPE_RESOURCE,
    validationSchema: null,
    actionsComponent: null,
};

export default AppointmentTypeForm;
