import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

const TouchedWatcher = ({ onChange }) => {
    const { touched, dirty, isSubmitting } = useFormikContext();

    useEffect(() => {
        if (!isSubmitting && touched) {
            onChange(touched, dirty);
        }
    }, [isSubmitting, touched, dirty]);

    return null;
};

TouchedWatcher.propTypes = {
    onChange: PropTypes.func.isRequired,
};

export default TouchedWatcher;
