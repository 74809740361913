import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { Grid, Checkbox } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { Lock as LockIcon } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';

import { sortableElement } from 'react-sortable-hoc';
import { selectChecklistItemById } from '../../modules/checklistItems/selectors';
import { updateChecklistItem } from '../../modules/checklistItems/actions';
import { convertLocalDateToUTC } from '../../modules/datetime/utils';
import Avatar from '../icons/Avatar';
import { selectUserById } from '../../modules/users/selectors';
import { IdPropType } from '../../modules/proptypes';
import LoadingIcon from '../loading/LoadingIcon';
import { UPDATE } from '../../modules/abilities/actions';
import Date, { DATE_TYPES } from '../utils/Date';
import IconButton from '../form/base/IconButton';

const useStyles = makeStyles(theme => ({
    item: {
        width: '100%',
        minHeight: 56,
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.spacing(1),
    },

    checkbox: {
        position: 'relative',
    },

    text: {
        flex: 4,
    },

    avatar: {
        marginLeft: 'auto',
    },

    date: {
        display: 'flex',
        alignItems: 'center',
        width: theme.spacing(16),
        margin: theme.spacing(2),
    },

    dateText: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },

    loading: {
        top: 13,
    },

    button: {
        margin: theme.spacing(1),
    },
}));

const ChecklistItem = sortableElement(({ itemId, onEdit }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const item = useSelector(state => selectChecklistItemById(state, itemId));
    const responsible = useSelector(state => selectUserById(state, item && item.responsibles[0]));

    const handleCheckChange = () => {
        setLoading(true);
        const update = { ...item };
        update.checked_at = update.checked_at ? null : convertLocalDateToUTC();

        return dispatch(updateChecklistItem(update)).then(() => {
            setLoading(false);
            return true;
        });
    };

    const handleEditClick = () => {
        onEdit(itemId, true);
    };

    return (
        <Grid data-test-class="saved-checklist-item-li" container className={classes.item}>
            <Grid item className={classes.checkbox}>
                <LoadingIcon isLoading={loading} size="small">
                    <Checkbox
                        checked={item && item.checked_at !== null}
                        color="secondary"
                        onChange={handleCheckChange}
                    />
                </LoadingIcon>
            </Grid>
            <Grid item className={classes.text}>
                {item && item.text}
            </Grid>
            <Grid item className={classes.avatar}>
                {responsible && <Avatar user={responsible} />}
            </Grid>
            <Grid item className={classes.date}>
                {item && item.due_at && (
                    <>
                        <EventOutlinedIcon />
                        <Typography className={classes.dateText}>
                            <Date date={item.due_at} dateType={DATE_TYPES.END} />
                        </Typography>
                        {item.due_at && item.due_locked && <LockIcon fontSize="small" />}
                    </>
                )}
            </Grid>
            <Grid item>
                <IconButton
                    action={UPDATE}
                    subject={item}
                    size="small"
                    onClick={handleEditClick}
                    className={classes.button}
                >
                    <EditOutlinedIcon />
                </IconButton>
            </Grid>
        </Grid>
    );
});

ChecklistItem.propTypes = {
    itemId: IdPropType.isRequired,
    onEdit: PropTypes.func.isRequired,
};

export default ChecklistItem;
