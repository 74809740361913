import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import { useField } from 'formik';
import { makeStyles } from '@mui/styles';
import { selectCardStatusById } from '../../modules/cardStatus/selectors';
import Select from '../form/formik/Select';
import Icon from '../icons/Icon';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import classNames from 'classnames';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { useCan } from '../../modules/abilities/hooks';
import { UPDATE } from '../../modules/abilities/actions';
import { CARD_STATUS_RESOURCE } from '../../modules/api/resources';
import CardStatusForm from './CardStatusForm';

const useStyles = makeStyles(theme => ({
    root: {
        opacity: '50%',
    },

    error: {
        opacity: '100%',
    },

    select: {
        ...theme.typography.h4,
    },

    fullWidth: {
        width: '100%',
    },

    label: {
        opacity: '50%',
    },
}));

const CardStatusSelect = ({ name, onChange, contrast, I, simple, fullwidth, ...other }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [field] = useField(name);

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const handleTooltip = bool => {
        setTooltipOpen(bool);
    };

    const [selectedEditItemId, setSelectedEditItemId] = useState(null);
    const [selectedEditItemLoading, setSelectedEditItemLoading] = useState(false);
    const canEdit = useCan(UPDATE, CARD_STATUS_RESOURCE);
    const popupRef = useRef();

    const cardStatusById = useSelector(selectCardStatusById);
    const cardStatusSelectOptions = Object.values(cardStatusById).map(status => ({
        value: status.id,
        label: status.name,
        onEdit: () => setSelectedEditItemId(status.id),
    }));
    const cardStatus = cardStatusById[field.value];

    return (
        <Grid
            container
            spacing={1}
            className={classNames({
                [classes.root]: cardStatus && !simple,
                [classes.error]: !cardStatus,
            })}
        >
            {!simple && <Grid item>{cardStatus && <Icon type={cardStatus.icon} />}</Grid>}
            <Tooltip
                title={t('components.Card.selectCardStatus')}
                open={tooltipOpen}
                disableInteractive
            >
                <Grid
                    data-test-id="CardStatusSelect"
                    item
                    className={classNames({
                        [classes.fullWidth]: fullwidth,
                    })}
                >
                    <Select
                        name="card_status_id"
                        label={!cardStatus ? `${t('components.Card.cardStatus')}...` : null}
                        InputLabelProps={{ className: classes.label }}
                        variant={cardStatus && !simple ? 'plain' : 'outlined'}
                        options={cardStatusSelectOptions}
                        className={classes.select}
                        IconComponent={simple ? ArrowDropDown : () => null}
                        onChange={onChange}
                        contrast={contrast}
                        can={I}
                        onMouseEnter={() => {
                            handleTooltip(true);
                        }}
                        onMouseLeave={() => {
                            handleTooltip(false);
                        }}
                        onOpen={() => {
                            handleTooltip(false);
                        }}
                        fullWidth={fullwidth}
                        className={classNames({
                            [classes.select]: !simple,
                        })}
                        ItemEditFormPopupProps={{
                            component: (
                                <CardStatusForm
                                    cardStatusId={selectedEditItemId}
                                    asPopup
                                    popupStateRef={popupRef}
                                    onLoading={loading => setSelectedEditItemLoading(loading)}
                                />
                            ),
                            canEdit,
                            selectedEditItemValue: selectedEditItemId,
                            isLoading: selectedEditItemLoading,
                        }}
                        {...other}
                    />
                </Grid>
            </Tooltip>
        </Grid>
    );
};

CardStatusSelect.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    contrast: PropTypes.bool,
    I: PropTypes.string,
    simple: PropTypes.bool,
};

CardStatusSelect.defaultProps = {
    onChange: undefined,
    contrast: false,
    I: null,
    simple: false,
};

export default CardStatusSelect;
