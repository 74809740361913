import React, { useMemo, useState, useEffect, useCallback } from 'react';
import * as PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import AsyncAutocomplete from './AsyncAutocomplete';
import { selectUsersById } from '../../../../modules/users/selectors';
import MentionEntry from '../../formik/Editor/MentionEntry';
import { selectPersonsById } from '../../../../modules/persons/selectors';

const renderOption = (props, option) => (
    <li {...props}>
        <Box data-test-class="user-autocomplete-li">
            <MentionEntry
                mention={{
                    ...option,
                    name: option.display_name,
                }}
            />
        </Box>
    </li>
);

const BaseUserAutocomplete = ({
    name,
    label,
    variant,
    onChange,
    contrast,
    size,
    fullWidth,
    className,
    multiple,
    value,
    can,
    ...other
}) => {
    const [inputValue, setInputValue] = useState(multiple ? [] : null);
    const users = useSelector(selectUsersById);
    const persons = useSelector(selectPersonsById);

    const fullNameUser = useCallback(
        user => {
            const { personId } = user;
            const person = (
                personId in persons
                    ? [
                          ...('last_name' in persons[personId]
                              ? [persons[personId].last_name]
                              : []),
                          ...('first_name' in persons[personId]
                              ? [persons[personId].first_name]
                              : []),
                      ]
                    : []
            )
                .join(', ')
                .trim();

            return {
                ...user,
                display_name: person === '' ? user.display_name : person,
            };
        },
        [persons]
    );

    const fullUsers = useMemo(() => Object.values(users).map(fullNameUser), [users, fullNameUser]);

    const fetch = useMemo(
        () => query => {
            const search = query.toLowerCase();

            return fullUsers.reduce((carry, user) => {
                const { display_name: displayName, username, email } = user;

                if (
                    displayName.toLowerCase().includes(search) ||
                    username.toLowerCase().includes(search) ||
                    email.toLowerCase().includes(search)
                ) {
                    return [...carry, user];
                }

                return carry;
            }, []);
        },
        [fullUsers]
    );

    const handleChange = (event, newValue) => {
        setInputValue(newValue);
        onChange(newValue);
    };

    useEffect(() => {
        if (!value) {
            return;
        }

        if (multiple) {
            setInputValue(value.map(id => fullNameUser(users[id])));
        } else {
            const singleValue = Array.isArray(value) ? value[0] : value;
            setInputValue(singleValue ? fullNameUser(users[singleValue]) : null);
        }
    }, [value, multiple, users, fullNameUser]);

    return (
        <AsyncAutocomplete
            multiple={multiple}
            data-test-id="autocomplete-input"
            name={name}
            value={inputValue}
            label={label}
            fetch={fetch}
            onChange={handleChange}
            renderOption={renderOption}
            isOptionEqualToValue={(option, _value) => _value && option.id === _value.id}
            getOptionLabel={option =>
                typeof option === 'string' ? option : option.display_name || ''
            }
            clearOnBlur
            blurOnSelect
            variant={variant}
            contrast={contrast}
            size={size}
            fullWidth={fullWidth}
            className={className}
            popupSize="small"
            can={can}
            {...other}
        />
    );
};

BaseUserAutocomplete.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    variant: PropTypes.string,
    contrast: PropTypes.bool,
    size: PropTypes.string,
    fullWidth: PropTypes.bool,
    className: PropTypes.string,
    multiple: PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.shape({}),
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.shape({})])
        ),
    ]),
    can: PropTypes.string,
};

BaseUserAutocomplete.defaultProps = {
    name: null,
    label: null,
    onChange: () => null,
    variant: 'outlined',
    contrast: false,
    size: 'small',
    fullWidth: false,
    className: null,
    multiple: false,
    value: null,
    can: null,
};

export default BaseUserAutocomplete;
