import { resolveAction, resolveSubject } from './actions';
import { isString } from 'lodash';

export const resolveCan = (ability, action, subject, field = null) => {
    const [resolvedSubject, resolvedField] = resolveSubject(subject, field);
    const resolvedAction = resolveAction(action, subject);

    if (resolvedSubject && !isString(resolvedSubject) && subject.__type === undefined) {
        const subjects = Object.values(resolvedSubject);
        return subjects.some(subj => {
            const [rSubj, rField] = resolveSubject(subj);
            return ability.can(resolvedAction, rSubj);
        });
    }

    return ability.can(resolvedAction, resolvedSubject, resolvedField);
};
