/* these normally come from the slice */
import { selectCardById, selectCardListByCardStatusId } from './selectors';
import { PER_PAGE } from '../kanban/config';
import { selectAllCardStatusIds } from '../cardStatus/selectors';
import { saveSearch } from './cardsSlice';
import { CARD_RESOURCE } from '../api/resources';
import { CARD_BOARD_CONTEXT } from '../metadata/context';

/*
 * extra methods
 */

export const indexCards = (params, meta = {}) => (dispatch, getState, api) =>
    api[CARD_RESOURCE].index(params, meta);

export const searchCards = (params, meta = {}, sequentialId = null) => (dispatch, getState, api) =>
    api[CARD_RESOURCE].search(params, meta, sequentialId);

export const showCard = params => (dispatch, getState, api) => api[CARD_RESOURCE].show(params);

export const storeCard = params => (dispatch, getState, api) => api[CARD_RESOURCE].store(params);

export const updateCard = (params, meta = {}) => (dispatch, getState, api) =>
    api[CARD_RESOURCE].update(params, meta);

export const archiveCard = params => (dispatch, getState, api) =>
    api[CARD_RESOURCE].destroy({ ...params, forceDelete: false });

export const destroyCard = params => (dispatch, getState, api) =>
    api[CARD_RESOURCE].destroy({ ...params, forceDelete: true });

export const cardListPage = (statusId, page = 1) => (dispatch, getState) => {
    const state = getState();
    const { filters, sort } = state[CARD_RESOURCE].filter;

    return dispatch(
        searchCards(
            {
                limit: PER_PAGE,
                page,

                /* fallback if no special filters are set */
                card_status_id: statusId,
                sort,

                filters:
                    (filters.$and && filters.$and.length) > 0
                        ? {
                              $and: [
                                  /* custom filter for card status */
                                  {
                                      name: 'card.status',
                                      id: statusId,
                                      by_id: true,
                                  },
                                  ...filters.$and,
                              ],
                          }
                        : [],

                contexts: [CARD_BOARD_CONTEXT],
            },
            null,
            statusId
        )
    );
};

export const cardListNextPage = statusId => (dispatch, getState) => {
    const state = getState();
    const list = selectCardListByCardStatusId(state, statusId);

    return list.current_page && !list.is_last_page
        ? dispatch(cardListPage(statusId, list.current_page + 1))
        : Promise.resolve();
};

export const filterCards = search => (dispatch, getState) => {
    const state = getState();

    dispatch(saveSearch(search));

    if (search.viewMode === 'board') {
        return Promise.all(
            selectAllCardStatusIds(state).map(cardStatusId =>
                dispatch(cardListPage(cardStatusId, 1))
            )
        );
    }

    return new Promise(resolve => resolve(true));
};

export const suggestCards = params => (dispatch, getState, api) =>
    api[CARD_RESOURCE].suggest(params);

export const autocompleteCards = params => (dispatch, getState, api) =>
    api[CARD_RESOURCE].autocomplete(params);

export const demoteMaster = (masterCard, newMasterId) => (dispatch, getState) => {
    const state = getState();

    return dispatch(updateCard({ ...masterCard, parent_id: newMasterId }))
        .then(({ data: demotedCard }) =>
            dispatch(
                indexCards({
                    id: demotedCard.parent_id,
                    ...state[CARD_RESOURCE].filter,
                })
            )
        )
        .then(({ data: newMasterQueryResult }) => {
            if (newMasterQueryResult.length === 1) {
                return Promise.all(
                    newMasterQueryResult[0].children.map(childId =>
                        dispatch(indexCards({ id: childId, ...state[CARD_RESOURCE].filter }))
                    )
                );
            }
            return Promise.resolve();
        });
};

export const unlinkCard = card => (dispatch, getState) => {
    const state = getState();

    if (card.parent_id && !card.children.length) {
        return dispatch(updateCard({ ...card, parent_id: null })).then(({ data }) =>
            dispatch(indexCards({ id: data.parent_id, ...state[CARD_RESOURCE].filter }))
        );
    }

    return Promise.all(
        card.children.map(childId => {
            const _card = selectCardById(state, childId);
            return dispatch(updateCard({ ..._card, parent_id: null }));
        })
    ).then(() => dispatch(indexCards({ id: card.id, ...state[CARD_RESOURCE].filter })));
};

export const linkCardAsChild = (newChildCard, parentId) => (dispatch, getState) => {
    const state = getState();

    if (!newChildCard.parent_id && !newChildCard.children.length) {
        return dispatch(updateCard({ ...newChildCard, parent_id: parentId })).then(() =>
            dispatch(indexCards({ id: parentId, ...state[CARD_RESOURCE].filter }))
        );
    }

    return Promise.resolve();
};

export const cardsInsideBounds = params => (dispatch, getState, api) =>
    api[CARD_RESOURCE].bounds(params);
