import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

import Button from '../form/base/Button';
import Checklist from './Checklist';
import { IdPropType } from '../../modules/proptypes';
import { useCallbackFunc } from '../../modules/hooks';
import { useResourceList } from '../../modules/lists/hooks';
import { CHECKLIST_RESOURCE } from '../../modules/api/resources';
import LoadingBox from '../loading/LoadingBox';
import ResourceWaypoint from '../table/resource/ResourceWaypoint';
import { compareDesc } from 'date-fns';
import { convertUTCtoLocalDate } from '../../modules/datetime/utils';
import Empty from '../layout/Empty';

const useStyles = makeStyles({
    root: {},

    header: {
        width: '100%',
        alignItems: 'center',
    },

    button: {
        marginLeft: 'auto',
    },
});

const ChecklistBundle = ({ taskId }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const listId = useMemo(() => `checklistsByTask.${taskId}`, [taskId]);

    const stableCheck = useCallbackFunc((item, taskId) => item.task_id === taskId);

    const stableCompare = useCallbackFunc((old, current) => {
        if (old.sort < current.sort) {
            return 1;
        }

        if (old.sort > current.sort) {
            return -1;
        }
        const endOld = convertUTCtoLocalDate(old.created_at);
        const endNew = convertUTCtoLocalDate(current.created_at);
        return compareDesc(endOld, endNew);
    });

    const { dataIds, loading, initialized, handleNextPage } = useResourceList({
        listId,
        index: true,
        continuous: true,
        autoInitialize: true,
        resource: CHECKLIST_RESOURCE,
        initialOrderBy: [
            ['sort', 'desc'],
            ['created_at', 'desc'],
        ],
        fetchParams: {
            task_id: taskId,
        },
        criteria: {
            check: item => stableCheck(item, taskId),
            compare: (item, newItem) => stableCompare(item, newItem),
        },
    });

    const [create, setCreate] = useState(false);

    const handleAddChecklist = () => {
        setCreate(true);
    };

    return (
        <Box className={classes.root}>
            <Grid container className={classes.header}>
                <Grid item>
                    <Typography variant="h4" color="primary">
                        {t('components.Checklist.title')}
                    </Typography>
                </Grid>
                <Grid item className={classes.button}>
                    <Button data-test-class="add-checklist-btn" onClick={handleAddChecklist}>
                        <AddOutlinedIcon />
                        <Typography>{t('components.Checklist.addList')}</Typography>
                    </Button>
                </Grid>
            </Grid>

            <LoadingBox loading={loading} initialized={initialized}>
                <Grid container>
                    {dataIds.length === 0 && !create ? (
                        <Empty />
                    ) : (
                        (create ? [null, ...dataIds] : dataIds).map(checklistId => (
                            <Checklist
                                checklistId={checklistId}
                                taskId={taskId}
                                isCreate={create}
                                onCreate={setCreate}
                                key={checklistId}
                            />
                        ))
                    )}
                    <ResourceWaypoint onEnter={handleNextPage} listId={listId} />
                </Grid>
            </LoadingBox>
        </Box>
    );
};

ChecklistBundle.propTypes = {
    taskId: IdPropType.isRequired,
};

export default ChecklistBundle;
