import React from 'react';
import * as PropTypes from 'prop-types';
import { IdPropType } from '../../modules/proptypes';
import { useSelector } from 'react-redux';
import { selectAttachmentById } from '../../modules/attachments/selectors';
import EmailView from './EmailView';
import { formatMailImport } from '../../modules/email/utils';

const Email = ({ attachmentId, expanded }) => {
    const attachment = useSelector(state => selectAttachmentById(state, attachmentId));

    if (!attachment || !attachment.mail || !attachment.mail.body) {
        return null;
    }

    const mail = formatMailImport(attachment.mail);

    return <EmailView data={mail} expanded={expanded} />;
};

Email.propTypes = {
    attachmentId: IdPropType.isRequired,
    expanded: PropTypes.bool,
};

Email.defaultProps = {
    expanded: false,
};

export default Email;
