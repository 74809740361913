import React, { useMemo, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Select from './Select';
import { selectAllTaskTypes } from '../../../modules/taskTypes/selectors';
import TextInput from './TextInput';
import { selectCategoriesById } from '../../../modules/categories/selectors';
import { INDIVIDUAL } from '../../../modules/tasks/utils';
import { useCan } from '../../../modules/abilities/hooks';
import { UPDATE } from '../../../modules/abilities/actions';
import { TASK_TYPE_RESOURCE } from '../../../modules/api/resources';
import TaskTypeSettingsForm from '../../taskTypes/TaskTypeSettingsForm';

const TaskTypeSelect = ({ name, label, fullWidth, size, I, ...other }) => {
    const { t } = useTranslation();
    const taskTypes = useSelector(selectAllTaskTypes);
    const [{ value }] = useField(name);

    /* for admin-panel settings */
    const [selectedAdminEditItemId, setSelectedAdminEditItemId] = useState(null);
    const [selectedAdminEditItemLoading, setSelectedAdminEditItemLoading] = useState(false);
    const canAdminEdit = useCan(UPDATE, TASK_TYPE_RESOURCE);
    const adminFormPopupRef = useRef();

    const options = useMemo(
        () =>
            taskTypes
                .sort((t1, t2) => t1.name.localeCompare(t2.name))
                .map(type => ({
                    label: type.name,
                    value: type.id,
                    onEdit: () => setSelectedAdminEditItemId(type.id),
                })),
        [taskTypes, t]
    );

    return (
        <Grid container spacing={2}>
            <Grid item sm={12} md={value === INDIVIDUAL ? 6 : 12}>
                <Select
                    size={size}
                    name={name}
                    label={label}
                    options={options}
                    fullWidth={fullWidth}
                    can={I}
                    ItemEditFormPopupProps={{
                        component: (
                            <TaskTypeSettingsForm
                                taskTypeId={selectedAdminEditItemId}
                                asPopup
                                popupStateRef={adminFormPopupRef}
                                onLoading={loading => setSelectedAdminEditItemLoading(loading)}
                            />
                        ),
                        canEdit: canAdminEdit,
                        selectedEditItemValue: selectedAdminEditItemId,
                        isLoading: selectedAdminEditItemLoading,
                    }}
                    {...other}
                />
            </Grid>
        </Grid>
    );
};

TaskTypeSelect.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    fullWidth: PropTypes.bool,
    size: PropTypes.string,
    I: PropTypes.string,
};

TaskTypeSelect.defaultProps = {
    label: null,
    fullWidth: false,
    size: 'small',
    I: null,
};

export default TaskTypeSelect;
