import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllRoles, selectBaseUserRole } from '../../../modules/role/selectors';
import MultiSelect from '../formik/MultiSelect';
import { indexRoles } from '../../../modules/role/actions';

const RolesSelect = ({ name, label, fullWidth }) => {
    const dispatch = useDispatch();
    const baseRole = useSelector(selectBaseUserRole);
    const roles = useSelector(selectAllRoles).map(role => ({
        label: role.display_name,
        value: role.id,
        disabled: role.id === baseRole.id,
    }));

    useEffect(() => {
        //TODO: use hook!
        dispatch(indexRoles());
    }, [dispatch]);

    return <MultiSelect name={name} label={label} options={roles} fullWidth={fullWidth} />;
};

RolesSelect.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    fullWidth: PropTypes.bool,
};

RolesSelect.defaultProps = {
    label: null,
    fullWidth: false,
};

export default RolesSelect;
