/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
    ATTACHMENT_RESOURCE,
    CARD_RESOURCE,
    CARD_STATUS_RESOURCE,
    CONTACT_RESOURCE,
    CONTACT_TYPE_RESOURCE,
    DATACONTAINER_RESOURCE,
    PROTOCOL_ITEM_TYPE_RESOURCE,
    PERSON_RESOURCE,
    CONTACT_DATA_RESOURCE,
    METADATA_RESSOURCE,
} from '../api/resources';
import {
    indexFulfilled as cardsIndexFulfilled,
    searchFulfilled as cardsSearchFulfilled,
    showFulfilled as cardsShowFulfilled,
    updateFulfilled as cardsUpdateFulfilled,
} from '../cards/cardsSlice';
import { indexFulfilled as protocolItemTypesIndexFulfilled } from '../protocolItemTypes/protocolItemTypeSlice';
import {
    indexFulfilled as cardStatusIndexFulfilled,
    showFulfilled as cardStatusShowFulfilled,
} from '../cardStatus/cardStatusSlice';
import {
    indexFulfilled as datacontainerIndexFulfilled,
    showFulfilled as datacontainerShowFulfilled,
} from '../datacontainers/datacontainersSlice';
import {
    indexFulfilled as contactIndexFulfilled,
    showFulfilled as contactShowFulfilled,
} from '../contacts/contactsSlice';
import {
    indexFulfilled as contactDataIndexFulfilled,
    showFulfilled as contactDataShowFulfilled,
} from '../contactData/contactDataSlice';
import {
    indexFulfilled as personIndexFulfilled,
    showFulfilled as personShowFulfilled,
} from '../persons/personsSlice';
import {
    indexFulfilled as contactTypeIndexFulfilled,
    showFulfilled as contactTypeShowFulfilled,
} from '../contactTypes/contactTypesSlice';
import {
    indexFulfilled as attachmentIndexFulfilled,
    showFulfilled as attachmentShowFulfilled,
    storeFulfilled as attachmentStoreFulfilled,
    updateFulfilled as attachmentUpdateFulfilled,
} from '../attachments/attachmentSlice';

const insertMetadata = (state, resource, id, metadata, contexts) => {
    const container = [resource, id].reduce((carry, key) => {
        if (!carry[key]) {
            carry[key] = {};
        }
        return carry[key];
    }, state.byResource);

    contexts.forEach(context => {
        container[context] = [];
    });

    if (metadata && !metadata.length) {
        Object.entries(metadata).forEach(([context, data]) => {
            container[context] = data;
        });
    }
};

const addList = (state, list, key, contexts) => {
    list.forEach(item => {
        insertMetadata(state, key, item.id, item.metadata, contexts);
    });
};

const indexFulfilledReducer = key => (state, action) => {
    const contexts = (action.meta && action.meta.params && action.meta.params.contexts) || [];

    addList(state, action.payload, key, contexts);
};

const showFulfilledReducer = key => (state, action) => {
    const contexts = (action.meta && action.meta.params && action.meta.params.contexts) || [];

    insertMetadata(state, key, action.payload.id, action.payload.metadata, contexts);
};

const storeFulfilledReducer = key => (state, action) => {
    const contexts = (action.meta && action.meta.params && action.meta.params.contexts) || [];

    addList(state, action.payload, key, contexts);
};

const updateFulfilledReducer = key => (state, action) => {
    const contexts = (action.meta && action.meta.params && action.meta.params.contexts) || [];

    insertMetadata(state, key, action.payload.id, action.payload.metadata, contexts);
};

const metadataSlice = createSlice({
    name: METADATA_RESSOURCE,
    initialState: {
        byResource: {},
    },
    reducers: {},
    extraReducers: {
        /**
         * TODO es fehlen auch noch weitere updateFilfilled für metadata,
         * aber diese ganzen extraReducer sollten dann in die api ausgelagert werden
         */

        [cardsIndexFulfilled]: indexFulfilledReducer(CARD_RESOURCE),
        [cardsSearchFulfilled]: indexFulfilledReducer(CARD_RESOURCE),
        [cardsShowFulfilled]: showFulfilledReducer(CARD_RESOURCE),
        [cardsUpdateFulfilled]: updateFulfilledReducer(CARD_RESOURCE),

        [datacontainerIndexFulfilled]: indexFulfilledReducer(DATACONTAINER_RESOURCE),
        [datacontainerShowFulfilled]: showFulfilledReducer(DATACONTAINER_RESOURCE),

        [cardStatusIndexFulfilled]: indexFulfilledReducer(CARD_STATUS_RESOURCE),
        [cardStatusShowFulfilled]: showFulfilledReducer(CARD_STATUS_RESOURCE),

        [protocolItemTypesIndexFulfilled]: indexFulfilledReducer(PROTOCOL_ITEM_TYPE_RESOURCE),

        [contactIndexFulfilled]: indexFulfilledReducer(CONTACT_RESOURCE),
        [contactShowFulfilled]: showFulfilledReducer(CONTACT_RESOURCE),

        [contactDataIndexFulfilled]: indexFulfilledReducer(CONTACT_DATA_RESOURCE),
        [contactDataShowFulfilled]: showFulfilledReducer(CONTACT_DATA_RESOURCE),

        [personIndexFulfilled]: indexFulfilledReducer(PERSON_RESOURCE),
        [personShowFulfilled]: showFulfilledReducer(PERSON_RESOURCE),

        [contactTypeIndexFulfilled]: indexFulfilledReducer(CONTACT_TYPE_RESOURCE),
        [contactTypeShowFulfilled]: showFulfilledReducer(CONTACT_TYPE_RESOURCE),

        [attachmentIndexFulfilled]: indexFulfilledReducer(ATTACHMENT_RESOURCE),
        [attachmentShowFulfilled]: showFulfilledReducer(ATTACHMENT_RESOURCE),
        [attachmentStoreFulfilled]: storeFulfilledReducer(ATTACHMENT_RESOURCE),
        [attachmentUpdateFulfilled]: updateFulfilledReducer(ATTACHMENT_RESOURCE),
    },
});

export default metadataSlice.reducer;
