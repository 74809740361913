import React, { useState } from 'react';
import * as propTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useField } from 'formik';
import AddressesInputRowForm from './AddressesInputRowForm';
import AddressDisplay from './AddressDisplay';
import ErrorBackground from '../form/ErrorBackground';
import EditIconButton from '../buttons/EditIconButton';

const AddressesInputRow = ({ name, initialEdit, onDelete }) => {
    const [{ value }, { error }] = useField(name);
    const [isEdit, setIsEdit] = useState(initialEdit);
    const isError = error && Object.keys(error).length > 0;

    return isEdit ? (
        <AddressesInputRowForm name={name} onDelete={onDelete} />
    ) : (
        <ErrorBackground isError={isError}>
            <Grid container spacing={2}>
                <Grid item xs>
                    <AddressDisplay addressId={value.id} />
                </Grid>

                <Grid item>
                    <EditIconButton onClick={() => setIsEdit(true)} size="small" />
                </Grid>
            </Grid>
        </ErrorBackground>
    );
};

AddressesInputRow.propTypes = {
    name: propTypes.string.isRequired,
    initialEdit: propTypes.bool.isRequired,
    onDelete: propTypes.func.isRequired,
};

export default AddressesInputRow;
