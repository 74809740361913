import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Select from '../form/formik/Select';
import {
    indexPriorities,
    selectAllPriorities,
    selectPrioritiesInitialized,
} from '../../modules/priority/prioritySlice';
import PriorityForm from './PriorityForm';
import { useCan } from '../../modules/abilities/hooks';
import { UPDATE } from '../../modules/abilities/actions';
import { PRIORITY_RESOURCE } from '../../modules/api/resources';

const PrioritySelect = ({ name, label, I, ...other }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const taskPriorities = useSelector(state => selectAllPriorities(state));
    const taskPrioritiesInitialized = useSelector(selectPrioritiesInitialized);

    /* for admin-panel settings */
    const [selectedAdminEditItemId, setSelectedAdminEditItemId] = useState(null);
    const [selectedAdminEditItemLoading, setSelectedAdminEditItemLoading] = useState(false);
    const canAdminEdit = useCan(UPDATE, PRIORITY_RESOURCE);
    const adminFormPopupRef = useRef();

    const options = useMemo(() => {
        const taskPrioritySelectOptions = taskPriorities
            ? Object.values(taskPriorities)
                  .sort((a, b) => (b.order != null) - (a.order != null) || a.order - b.order)
                  .map(taskPriority => ({
                      value: taskPriority.id,
                      label: taskPriority.name,
                      icon: taskPriority.icon,
                      iconColor: taskPriority.color,
                      onEdit: () => setSelectedAdminEditItemId(taskPriority.id),
                  }))
            : [];

        taskPrioritySelectOptions.unshift({
            value: null,
            label: t('components.TaskPriority.selectTaskPriority'),
        });

        return taskPrioritySelectOptions;
    }, [taskPriorities, t]);

    useEffect(() => {
        if (!taskPrioritiesInitialized) dispatch(indexPriorities());
    }, [dispatch, taskPrioritiesInitialized]);

    return (
        <Select
            can={I}
            name={name}
            label={label}
            options={options}
            ItemEditFormPopupProps={{
                component: (
                    <PriorityForm
                        priorityId={selectedAdminEditItemId}
                        asPopup
                        popupStateRef={adminFormPopupRef}
                        onLoading={loading => setSelectedAdminEditItemLoading(loading)}
                    />
                ),
                canEdit: canAdminEdit,
                selectedEditItemValue: selectedAdminEditItemId,
                isLoading: selectedAdminEditItemLoading,
            }}
            {...other}
        />
    );
};

PrioritySelect.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    I: PropTypes.string,
};

PrioritySelect.defaultProps = {
    label: null,
    I: null,
};

export default PrioritySelect;
