import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectEmployeesById } from '../../modules/employees/selectors';
import { selectResourcesById } from '../../modules/resources/selectors';
import {
    CALENDAR_RESOURCE_MODELS,
    formatEmployeeForCalendar,
    formatResourceForCalendar,
} from '../../modules/calendar/utils';
import { EMPLOYEE_RESOURCE, RESOURCE_RESOURCE } from '../../modules/api/resources';
import ItemStack from '../icons/ItemStack';
import BaseAvatar from '../icons/BaseAvatar';
import Box from '@mui/material/Box';
import { Grid, Icon, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CalendarGroupLoader from './CalendarGroupLoader';
import LoadingIcon from '../loading/LoadingIcon';

const CalendarGroupMembers = ({ members }) => {
    const { t } = useTranslation();
    const allResources = useSelector(selectResourcesById);
    const allEmployees = useSelector(selectEmployeesById);

    const { resourceIds, employeeIds } = useMemo(
        () => ({
            resourceIds: members[CALENDAR_RESOURCE_MODELS[RESOURCE_RESOURCE]] || [],
            employeeIds: members[CALENDAR_RESOURCE_MODELS[EMPLOYEE_RESOURCE]] || [],
        }),
        [members]
    );

    const getAvatar = (
        { id, key, text, short, icon, avatar, color, ...other },
        isResource = false
    ) => (
        <Tooltip key={key} title={text} placement="bottom" disableInteractive>
            <Box style={{ height: 32, width: 32 }}>
                <BaseAvatar
                    displayName={text}
                    shortName={short && !icon ? short : <Icon fontSize="small">{icon}</Icon>}
                    path={avatar}
                    color={color}
                    style={isResource ? { backgroundColor: '#FFFFFF' } : null}
                    outlined={isResource}
                    disableHover
                    small
                />
            </Box>
        </Tooltip>
    );

    const formattedResources = useMemo(
        () =>
            resourceIds.map(id => {
                const resource = allResources[id];
                return formatResourceForCalendar(resource);
            }),
        [resourceIds, allResources]
    );

    const formattedEmployees = useMemo(
        () =>
            employeeIds.map(id => {
                const employee = allEmployees[id];
                return formatEmployeeForCalendar(employee);
            }),
        [employeeIds, allEmployees]
    );

    const resourceAvatars = useMemo(
        () =>
            formattedResources.map(resource =>
                resource
                    ? { key: resource.key, component: getAvatar(resource, true) }
                    : { key: null, component: null }
            ),
        [formattedResources, getAvatar]
    );
    const employeeAvatars = useMemo(
        () =>
            formattedEmployees.map(employee =>
                employee
                    ? { key: employee.key, component: getAvatar(employee) }
                    : { key: null, component: null }
            ),
        [formattedEmployees, getAvatar]
    );

    return (
        <Box p={2} style={{ position: 'relative' }}>
            <CalendarGroupLoader
                forceEmployees={employeeIds}
                forceResources={resourceIds}
                LoadingComponent={LoadingIcon}
                loadingComponentProps={{
                    size: 'small',
                }}
            />
            <Grid container direction="column" spacing={1}>
                <Grid item>
                    <Typography variant="subtitle1">
                        {t('components.CalendarResourcesPicker.Group.employees')}
                    </Typography>
                </Grid>
                <Grid item>
                    <ItemStack items={employeeAvatars} itemSize={32} />
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1">
                        {t('components.CalendarResourcesPicker.Group.resources')}
                    </Typography>
                </Grid>
                <Grid item>
                    <ItemStack items={resourceAvatars} itemSize={32} />
                </Grid>
            </Grid>
        </Box>
    );
};

CalendarGroupMembers.propTypes = {
    members: PropTypes.shape({}),
};

CalendarGroupMembers.defaultProps = {
    members: {},
};

export default CalendarGroupMembers;
