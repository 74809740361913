export const getSortingFromOrderBy = (columns, orderBy) => {
    if (Array.isArray(orderBy) && orderBy.length && Array.isArray(columns) && columns.length) {
        return orderBy.reduce((carry, [accessorKey, direction]) => {
            const { id } =
                columns.find(
                    col =>
                        col.apiKey === accessorKey ||
                        col.accessorKey === accessorKey ||
                        col.id === accessorKey
                ) || {};

            if (id && direction) {
                return [
                    ...carry,
                    {
                        id,
                        desc: direction === 'desc',
                    },
                ];
            }

            return carry;
        }, []);
    }

    return [];
};

export const getOrderByFromSorting = (columns, sorting) => {
    if (Array.isArray(sorting) && sorting.length && Array.isArray(columns) && columns.length) {
        return sorting.reduce((carry, { id, desc }) => {
            const { accessorKey, apiKey } =
                columns.find(col => col.apiKey === id || col.accessorKey === id || col.id === id) ||
                {};

            const modelKey = apiKey || accessorKey;

            if (modelKey) {
                return [...carry, [modelKey, desc ? 'desc' : 'asc']];
            }

            return carry;
        }, []);
    }

    return [];
};

export const getDateUserFields = (columnProps, row) => {
    const baseKey = columnProps?.id;

    const userKey = `${baseKey}_by`;
    const userValue = row[userKey];

    const dateKey = `${baseKey}_at`;
    const dateValue = row[dateKey];

    return {
        baseKey,
        userKey,
        userValue,
        dateKey,
        dateValue,
    };
};
