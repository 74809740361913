import { resources } from './resources';
import { attachRestResources } from './rest';
import { attachAuthentication } from './auth';
import { attachCloud } from './cloud';
import { attachSocket } from './socket';
import { attachKumoConnect } from './kumoConnect';

const configureApi = () => {
    if (process.env.REACT_APP_API_ROOT === undefined) {
        throw new Error('REACT_APP_API_ROOT not found in env variables');
    }
    if (process.env.REACT_APP_API_AUTH === undefined) {
        throw new Error('REACT_APP_API_AUTH not found in env variables');
    }

    const api = {
        _authHandlers: [],
        onAuthenticate: handler => {
            api._authHandlers.push(handler);
            return api;
        },
        authenticate: data =>
            api._authHandlers.reduce(
                (carry, handler) => carry.then(result => handler(result)),
                Promise.resolve(data)
            ),

        _getAuthenticatedHandlers: [],
        setGetAuthenticatedHandler: handler => {
            api._getAuthenticatedHandlers.push(handler);
            return api;
        },
        getAuthenticated: data =>
            api._getAuthenticatedHandlers.reduce(
                (carry, handler) => carry.then(result => handler(result)),
                Promise.resolve(data)
            ),

        _logoutHandlers: [],
        onLogout: handler => {
            api._logoutHandlers.push(handler);
            return api;
        },
        logout: data =>
            api._logoutHandlers.reduce(
                (carry, handler) => carry.then(result => handler(result)),
                Promise.resolve(data)
            ),

        dispatch: () => {
            throw new Error('api.setStore(store) must be called before using api.dispatch!');
        },
        getState: () => {
            throw new Error('api.setStore(store) must be called before using api.getState!');
        },
        setStore: store => {
            api.getState = store.getState;
            api.dispatch = store.dispatch;
            return api;
        },
    };

    attachAuthentication(api);

    attachRestResources(api, resources);

    attachCloud(api);

    attachKumoConnect(api);

    attachSocket(api);

    // TODO set up sockets...
    // sockets.on('card.update', event => {
    //     dispatch({
    //         type: type_update_resolved,
    //         payload: event,
    //     });
    // });

    return api;
};

export default configureApi;
