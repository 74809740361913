import React from 'react';
import * as PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { selectContactById } from '../../modules/contacts/selectors';
import ContactTypeIcon from '../icons/ContactTypeIcon';
import { IdPropType } from '../../modules/proptypes';

const Contact = ({ contactId, typeIds, noTypeIcon, variant }) => {
    const contact = useSelector(state => selectContactById(state, contactId));

    return contact ? (
        <Grid container spacing={1}>
            {!noTypeIcon && (
                <Grid item>
                    <ContactTypeIcon typeIds={typeIds} />
                </Grid>
            )}
            <Grid item>
                <Typography variant={variant}>{contact.display_name}</Typography>
            </Grid>
        </Grid>
    ) : null;
};

Contact.propTypes = {
    contactId: IdPropType.isRequired,
    typeIds: PropTypes.arrayOf(IdPropType).isRequired,
    noTypeIcon: PropTypes.bool,
    variant: PropTypes.string,
};

Contact.defaultProps = {
    noTypeIcon: false,
    variant: 'body2',
};

export default Contact;
