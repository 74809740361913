import React, { useEffect, useState } from 'react';
import { Toolbar } from '@devexpress/dx-react-scheduler-material-ui';
import CalendarResourcesPicker from './CalendarResourcesPicker';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { EMPLOYEE_RESOURCE, GROUP_RESOURCE, RESOURCE_RESOURCE } from '../../modules/api/resources';
import { useAppointmentCalendar } from './AppointmentCalendarContext';
import AddResourceIcon from './AddResourceIcon';
import { useSelector } from 'react-redux';
import { selectAllGroupsById } from '../../modules/groups/selectors';
import { CALENDAR_RESOURCE_MODELS } from '../../modules/calendar/utils';
import CalendarGroupLoader from './CalendarGroupLoader';
import CalendarGroupMembers from './CalendarGroupMembers';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    flexibleSpace: {
        width: '100%',
        margin: '0 auto 0 0',
        flexGrow: '1 !important',
        height: 64,
    },
    container: {
        width: '100%',
        height: 80,
        marginRight: -32,
    },
});

const CalendarToolbarOptions = ({ children }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        defaultEntityResource,
        defaultEntityId,
        selectedGroupResources,
        selectedGroupEmployees,
        setSelectedResources,
        setSelectedEmployees,
        setSelectedGroupResources,
        setSelectedGroupEmployees,
    } = useAppointmentCalendar();

    const groups = useSelector(selectAllGroupsById);
    const [selectedGroupIds, setSelectedGroupIds] = useState([]);

    const handleGroupMembers = selectedGroupIds => {
        const selectedGroups = selectedGroupIds.map(id => groups[id]);

        const { groupResources, groupEmployees } = selectedGroups.reduce(
            (carry, group) => {
                if (!group?.members) {
                    return carry;
                }

                const currentResources =
                    group.members[CALENDAR_RESOURCE_MODELS[RESOURCE_RESOURCE]] || [];
                const currentEmployees =
                    group.members[CALENDAR_RESOURCE_MODELS[EMPLOYEE_RESOURCE]] || [];

                return {
                    groupResources: [...carry.groupResources, ...currentResources],
                    groupEmployees: [...carry.groupEmployees, ...currentEmployees],
                };
            },
            { groupResources: [], groupEmployees: [] }
        );

        if (JSON.stringify(groupResources) !== JSON.stringify(selectedGroupResources)) {
            setSelectedGroupResources(groupResources);
        }

        if (JSON.stringify(groupEmployees) !== JSON.stringify(selectedGroupEmployees)) {
            setSelectedGroupEmployees(groupEmployees);
        }
    };

    useEffect(() => {
        const availableGroupIds = Object.keys(groups);
        const allGroupsAvailable = !selectedGroupIds.find(
            id => !availableGroupIds.includes(id.toString())
        );

        if (allGroupsAvailable) {
            handleGroupMembers(selectedGroupIds);
        }
    }, [selectedGroupIds, groups]);

    return (
        <Toolbar.FlexibleSpace className={classes.flexibleSpace}>
            <Grid
                container
                spacing={2}
                alignItems="center"
                justifyContent="flex-end"
                className={classes.container}
            >
                <Grid item>
                    <CalendarResourcesPicker
                        resourceMode={EMPLOYEE_RESOURCE}
                        initialSelect={
                            defaultEntityResource === EMPLOYEE_RESOURCE ? [defaultEntityId] : []
                        }
                        AddIcon={PersonAddAlt1Icon}
                        onChange={setSelectedEmployees}
                        label={t('components.CalendarResourcesPicker.Employee.tooltip')}
                    />
                </Grid>
                <Grid item>
                    <CalendarResourcesPicker
                        resourceMode={RESOURCE_RESOURCE}
                        initialSelect={
                            defaultEntityResource === RESOURCE_RESOURCE ? [defaultEntityId] : []
                        }
                        AddIcon={AddResourceIcon}
                        onChange={setSelectedResources}
                        label={t('components.CalendarResourcesPicker.Resource.tooltip')}
                    />
                </Grid>
                <Grid item>
                    <CalendarResourcesPicker
                        resourceMode={GROUP_RESOURCE}
                        AddIcon={GroupAddIcon}
                        SelectedExtraComponent={CalendarGroupMembers}
                        onChange={setSelectedGroupIds}
                        label={t('components.CalendarResourcesPicker.Group.tooltip')}
                    />
                    <CalendarGroupLoader />
                </Grid>
            </Grid>
            {children}
        </Toolbar.FlexibleSpace>
    );
};

CalendarToolbarOptions.propTypes = {};

CalendarToolbarOptions.defaultProps = {};

export default CalendarToolbarOptions;
