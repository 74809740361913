import { TASK_TYPE_RESOURCE } from '../api/resources';

export const indexTaskTypes = params => (dispatch, getState, api) =>
    api[TASK_TYPE_RESOURCE].index(params);

export const showTaskType = params => (dispatch, getState, api) =>
    api[TASK_TYPE_RESOURCE].show(params);

export const storeTaskType = params => (dispatch, getState, api) =>
    api[TASK_TYPE_RESOURCE].store(params);

export const updateTaskType = params => (dispatch, getState, api) =>
    api[TASK_TYPE_RESOURCE].update(params);

export const destroyTaskType = params => (dispatch, getState, api) =>
    api[TASK_TYPE_RESOURCE].destroy(params);
