const comparator = (a, b, orderBy, getSortValue) => {
    const valueA = getSortValue(a, orderBy);
    const valueB = getSortValue(b, orderBy);

    if (valueA < valueB) {
        return -1;
    }
    if (valueA > valueB) {
        return 1;
    }
    return 0;
};

export const stableSort = (items, order, orderBy, getSortValue) =>
    items
        .map((el, index) => [el, index])
        .sort(
            ([a, aIndex], [b, bIndex]) =>
                (comparator(a, b, orderBy, getSortValue) || aIndex - bIndex) *
                (order === 'asc' ? 1 : -1)
        )
        .map(el => el[0]);
