/* eslint-disable no-param-reassign */
import saveAs from 'file-saver';
import { createClient } from 'webdav/web';
import pathJoin from 'path.join';
import logger from '../../logger';
import { getFilePath } from '../files/utils';
import { apiError } from './apiSlice';
import { NOT_FOUND } from '../../components/api/ApiErrorHandler';

const defaultConfig = {
    withCredentials: false,
    overwrite: false,
};

const DOWNLOAD_PROXY_PATH = '/components/cloud/view?file=';

const getCloudApi = (user, api) => {
    const createErrorHandler =
        (action, ignore = []) =>
        error => {
            if (
                !(error.response && error.response.status && ignore.includes(error.response.status))
            ) {
                api.dispatch(
                    apiError({
                        status: (error.response && error.response.status) || null,
                        message: error.message,
                        action,
                        key: 'cloud',
                    })
                );
            }
            throw error;
        };

    const cloud = {
        _client: null,
        _kumoConnectStatus: null,

        _getClient: () => {
            cloud._client = cloud._kumoConnectStatus ? api.kumoConnect._getClient() : null;

            if (cloud._client) {
                return Promise.resolve(cloud._client);
            }

            if (user && user.cloud_disk) {
                const { username, password, root } = user.cloud_disk;
                if (!root) return Promise.reject(Error('No cloud access available'));
                cloud._client = createClient(root || '', { username, password });
                return Promise.resolve(cloud._client);
            }

            return Promise.reject(Error('Not authenticated'));
        },

        _setKumoConnectStatus: kumoConnectStatus => (cloud._kumoConnectStatus = kumoConnectStatus),

        directory: (path, config) =>
            cloud
                ._getClient()
                .then(client =>
                    client.getDirectoryContents(path, {
                        ...defaultConfig,
                        ...config,
                    })
                )
                .catch(createErrorHandler('directory')),

        stat: (path, config) =>
            cloud
                ._getClient()
                .then(client =>
                    client.stat(path, {
                        ...defaultConfig,
                        ...config,
                    })
                )
                .catch(createErrorHandler('stat', [NOT_FOUND])),

        download: (path, config) => {
            if (cloud._kumoConnectStatus) return api.kumoConnect.openKumoConnectFile(path);

            return cloud
                ._getClient()
                .then(client =>
                    client.getFileContents(path, config).then(res => {
                        const file = new Blob([res]);
                        saveAs(file, path.split('/').pop());
                        return file;
                    })
                )
                .catch(createErrorHandler('download'));
        },

        show: path => {
            if (cloud._kumoConnectStatus) return api.kumoConnect.openKumoConnectFile(path);

            const filePath = encodeURIComponent(path.startsWith('/') ? path.substr(1) : path);
            /*
             * can't use pathJoin with empty string :(
             * https://github.com/jfromaniello/url-join/issues/45
             */
            const downloadUrl = process.env.REACT_APP_ROOT
                ? pathJoin(process.env.REACT_APP_ROOT, DOWNLOAD_PROXY_PATH)
                : DOWNLOAD_PROXY_PATH;
            window.open(`${downloadUrl}${filePath}`);
            return Promise.resolve();
        },

        getLocalPath: path => {
            const localNextcloudPath = process.env.REACT_APP_NEXTCLOUD_LOCAL_PATH || ''; // TODO: @Micha was ist mit copy localPath für KumoConnect?
            return pathJoin(localNextcloudPath, path);
        },

        createDirectory: path => {
            if (cloud._kumoConnectStatus) return api.kumoConnect.dirCreate(path);

            return cloud
                ._getClient()
                .then(client => client.createDirectory(path))
                .catch(err => {
                    if (err.response) {
                        if (err.response.status === 409) {
                            /**
                             * According to the protocol, only one directory can be created
                             * as the result of a single request.
                             *
                             * If the application sends a request to create the /a/b/c/ directory,
                             * but the /a/ directory does not contain a /b/ directory,
                             * the service will not create the /b/ directory,
                             * and will respond with the code 409 Conflict.
                             *
                             * Create the intermediate directories first and try again.
                             */
                            const parentPath = getFilePath(path);
                            logger.info(
                                `Parent folder missing for ${path}, trying to create ${parentPath}`
                            );

                            if (parentPath === path) {
                                throw new Error(`Root path reached: ${path}`);
                            }

                            return cloud
                                .createDirectory(parentPath)
                                .then(() => cloud.createDirectory(path));
                        }

                        if (err.response.status === 405 || err.response.status === 423) {
                            /**
                             * If the same directory is created multiple times simultaneously,
                             * only the first request succeeds. The other requests will fail with
                             * - 405 Method Not Allowed: when the directory already exists at this point
                             * - 423 Locked: when the directory is currently being created
                             * Either way we do not need to do anything.
                             */
                            return Promise.resolve();
                        }
                    }
                    throw err;
                })
                .catch(createErrorHandler('createDirectory'));
        },

        /**
         * This produces an error: Refused to set unsafe header "Content-Length"
         * For now the error can be ignored, lets see if this gets fixed...
         * https://github.com/perry-mitchell/webdav-client/issues/150
         */
        upload: (path, file, onProgress, config) =>
            cloud
                ._getClient()
                .then(client =>
                    client
                        .putFileContents(path, file, {
                            ...defaultConfig,
                            ...config,
                            onUploadProgress: onProgress,
                        })
                        .catch(err => {
                            if (
                                err.response &&
                                [
                                    404, // nextcloud status code if parent dir is missing
                                    403, // apache webdav status code if parent dir is missing
                                ].includes(err.response.status)
                            ) {
                                /**
                                 * This is probably caused by missing parent folders,
                                 * create them first and try again.
                                 */
                                const parentPath = getFilePath(path);
                                logger.info(
                                    `Parent folder missing for ${path}, trying to create ${parentPath}`
                                );
                                return cloud
                                    .createDirectory(parentPath)
                                    .then(() => cloud.upload(path, file, onProgress, config));
                            }
                            throw err;
                        })
                )
                .catch(createErrorHandler('upload')),

        bulkUpload: (files, config) =>
            Promise.all(
                files.map(
                    ({
                        id,
                        sourceId,
                        path,
                        file,
                        metadata,
                        mails,
                        onProgress,
                        onError,
                        config: fileConfig,
                    }) =>
                        file
                            ? cloud
                                  .upload(path, file, onProgress, { ...config, ...fileConfig })
                                  .then(() => ({
                                      ...(id ? { id } : {}),
                                      ...(sourceId ? { source_id: sourceId } : {}),
                                      path,
                                      metadata,
                                      mails,
                                      file,
                                  }))
                                  .catch(err => {
                                      logger.error(err);
                                      if (onError) {
                                          onError(err);
                                      }
                                      return null;
                                  })
                            : { path, metadata: {} }
                )
            ).then(paths => paths.filter(path => path)),

        destroy: (path, config) =>
            cloud
                ._getClient()
                .then(client => client.deleteFile(path, { ...defaultConfig, ...config }))
                .catch(createErrorHandler('destroy')),

        bulkDestroy: (paths, config) =>
            Promise.all(
                paths.map(({ path, onError }) =>
                    cloud
                        .destroy(path, config)
                        .then(() => path)
                        .catch(err => {
                            logger.error(err);
                            if (onError) {
                                onError(err);
                            }
                            return null;
                        })
                )
            ),
    };

    return cloud;
};

export const attachCloud = api => {
    const cloudBinder = data => {
        api.cloud = getCloudApi(data, api);
        return data;
    };

    api.onAuthenticate(cloudBinder);
    api.setGetAuthenticatedHandler(cloudBinder);
    api.onLogout(cloudBinder);

    cloudBinder();
};
