export const SORT_VARIANTS = [
    { label: 'components.CardSearch.createdAt', value: 'created' },
    { label: 'components.CardSearch.taskDue', value: 'task' },
    { label: 'components.CardSearch.nextDue', value: 'next' },
];

export const SORT_CONFIG = {
    created: null,
    task: [{ 'card.tasks_due_at': 'asc' }, { 'card.created_at': 'desc' }],
    next: [{ 'card.next_due_at': 'asc' }, { 'card.created_at': 'desc' }],
};

export const SORT_DEFAULT = [{ 'card.created_at': 'desc' }];

const sortByPreferences = (byId, prefs, aId, bId) => {
    const itemA = byId[aId];
    const itemB = byId[bId];

    return prefs.reduce((carry, pref) => {
        if (carry !== 0) {
            /* we already know how to sort these two */
            return carry;
        }

        const [[keyString, direction]] = Object.entries(pref);
        const key = keyString.replace('card.', '');

        const valueA = itemA[key];
        const valueB = itemB[key];

        if (valueA === valueB) {
            /* both are the same or both are not set */
            return 0;
        }

        if (valueA && valueB) {
            return valueA.localeCompare(valueB) * (direction === 'desc' ? -1 : 1);
        }

        return valueA ? -1 : 1;
    }, 0);
};

const sortCardStatusByDue = (state, statusId) => {
    if (state.byCardStatusId[statusId]) {
        const prefs =
            state.filter.sort && state.filter.sort.length > 0 ? state.filter.sort : SORT_DEFAULT;
        state.byCardStatusId[statusId].data.sort((a, b) =>
            sortByPreferences(state.byId, prefs, a, b)
        );
    }
};

export const sortMultipleCardStatusByDue = (state, cardStatus = []) => {
    if (cardStatus.length !== 0) {
        cardStatus.forEach(statusId => sortCardStatusByDue(state, statusId));
    }
};

export const bundleCardStatus = (bundle, discovered) => {
    if (!bundle.includes(discovered.card_status_id)) {
        return [...bundle, discovered.card_status_id];
    }
    return bundle;
};
