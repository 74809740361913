import React, { useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { animated, useSpring } from 'react-spring';
import { selectUser } from '../../modules/auth/selectors';
import { SearchPropType } from '../../modules/searches/proptypes';
import { Tooltip, alpha } from '@mui/material';
import { useContextualCan } from '../../modules/abilities/hooks';
import { WRITE } from '../../modules/abilities/actions';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        '&$active': {
            borderColor: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,

            '&.MuiChip-clickable.MuiChip-outlined:focus': {
                backgroundColor: theme.palette.primary.main,
            },

            '&.MuiChip-clickable.MuiChip-outlined:hover': {
                backgroundColor: alpha(theme.palette.primary.main, 0.75),
                borderColor: 'transparent',
            },
        },
    },

    contrast: {
        borderColor: theme.palette.primary.contrastText,
        backgroundColor: 'transparent',
        color: theme.palette.primary.contrastText,

        '&.MuiChip-clickable.MuiChip-outlined:hover': {
            backgroundColor: alpha(theme.palette.primary.contrastText, 0.2),
            borderColor: 'transparent',
        },

        '&$active': {
            borderColor: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.contrastText,

            color: theme.palette.primary.main,
            '&.MuiChip-clickable.MuiChip-outlined:focus': {
                backgroundColor: theme.palette.primary.contrastText,
            },

            '&.MuiChip-clickable.MuiChip-outlined:hover': {
                backgroundColor: alpha(theme.palette.primary.contrastText, 0.75),
                borderColor: 'transparent',
            },
        },
    },

    active: {
        backgroundColor: theme.palette.background.primary,
    },

    iconItem: {
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        fontSize: 14,
        marginTop: -1,
    },
}));

const SearchPill = ({ search, onEdit, active, onClick, contrast, I }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const user = useSelector(selectUser);
    const [hover, setHover] = useState(false);
    const canDo = useContextualCan(I ? I : WRITE);

    const { editable, iconContainerWidth } = useMemo(() => {
        const editable = onEdit && search.created_by === user.id;
        const iconContainerWidth = editable ? 14 : 0;

        return { editable, iconContainerWidth };
    }, [search, user, onEdit]);
    const { width, opacity, paddingLeft, paddingRight } = useSpring({
        from: { width: 0, opacity: 0, paddingLeft: 0, paddingRight: iconContainerWidth },
        to: {
            width: hover ? iconContainerWidth : 0,
            opacity: hover ? 1 : 0,
            paddingLeft: hover ? 2 : 0,
            paddingRight: hover ? 0 : iconContainerWidth,
        },
    });

    return (
        <Tooltip
            title={t(
                `components.SearchPill.${active ? 'turnOffFilterTooltip' : 'applyFilterTooltip'}`
            )}
            disableInteractive
        >
            <animated.div style={{ paddingRight: editable ? paddingRight : 14 }}>
                <Chip
                    label={
                        <Grid container alignItems="center" wrap="nowrap">
                            <Grid item>{search.name}</Grid>
                            {editable && (
                                <Grid item>
                                    <animated.div
                                        style={{ opacity, width, paddingLeft, height: 14 }}
                                    >
                                        <EditOutlinedIcon
                                            onClick={event => {
                                                event.stopPropagation();
                                                onEdit(search, event);
                                            }}
                                            className={classes.icon}
                                        />
                                    </animated.div>
                                </Grid>
                            )}
                        </Grid>
                    }
                    clickable
                    // color={active ? 'primary' : undefined}
                    color="primary"
                    onClick={onClick}
                    size="small"
                    className={classNames(classes.root, {
                        [classes.active]: active,
                        [classes.contrast]: contrast,
                    })}
                    variant="outlined"
                    onMouseEnter={() => {
                        setHover(true);
                    }}
                    onMouseLeave={() => {
                        setHover(false);
                    }}
                    data-test-id="SearchPill"
                    disabled={!canDo}
                />
            </animated.div>
        </Tooltip>
    );
};

SearchPill.propTypes = {
    search: SearchPropType.isRequired,
    onEdit: PropTypes.func,
    active: PropTypes.bool,
    onClick: PropTypes.func,
    contrast: PropTypes.bool,
    I: PropTypes.string,
};

SearchPill.defaultProps = {
    onEdit: null,
    active: false,
    onClick: () => null,
    contrast: false,
    I: null,
};

export default SearchPill;
