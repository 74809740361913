import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';

import Box from '@mui/material/Box';
import { IdPropType } from '../../modules/proptypes';
import { selectUsersById } from '../../modules/users/selectors';
import Avatar from './Avatar';
import { useSorting } from '../../modules/sorting/hooks';
import { ASSIGNED } from '../../modules/responsibles/types';
import { ConditionalWrapper } from '../utils/ConditionalWrapper';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'block',
    },

    dense: {
        marginLeft: `-${theme.spacing(3)}`,
    },
    small: {
        marginLeft: `-${theme.spacing(2)}`,
    },
    normal: {
        marginLeft: `-${theme.spacing(1)}`,
    },

    offsetDense: {
        marginLeft: theme.spacing(3),
    },
    offsetSmall: {
        marginLeft: theme.spacing(2),
    },
    offsetNormal: {
        marginLeft: theme.spacing(1),
    },
    fakeAvatar: {
        display: 'inline-block',
        height: 40,
        width: 40,
        borderRadius: 20,
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
        '&:nth-child(2)': {
            marginLeft: `-${theme.spacing(2)}`,
        },
    },
    fakeAvatarSmall: {
        display: 'inline-block',
        height: 32,
        width: 32,
        borderRadius: 16,
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
        '&:nth-child(2)': {
            marginLeft: `-${theme.spacing(2)}`,
        },
    },
}));

const Avatars = ({
    userIds,
    mainIds,
    dense,
    small,
    onDelete,
    children,
    flipped,
    disableHover,
    hoverExtras,
    type,
    disablePortal,
    placeholder,
    classes: overrideClasses,
    label,
    showSingleTooltip,
    ...other
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const users = useSelector(state => selectUsersById(state));

    const getSortValue = useMemo(
        () => userId => mainIds.includes(userId) === flipped ? -1 : 1,
        [mainIds, flipped]
    );
    const { sorted: orderedByMain } = useSorting(userIds || [], getSortValue);

    return placeholder && userIds.length === 0 ? (
        <Box className={classes.root}>
            <Box
                className={classNames({
                    [classes.fakeAvatar]: !(dense || small),
                    [classes.fakeAvatarSmall]: dense || small,
                })}
            />
        </Box>
    ) : (
        <ConditionalWrapper
            condition={label}
            wrapper={_children => (
                <Tooltip title={label} placement="left" disableInteractive>
                    {_children}
                </Tooltip>
            )}
        >
            <Box className={classes.root}>
                <Grid
                    container
                    spacing={0}
                    className={classNames({
                        [classes.offsetDense]: dense && !small,
                        [classes.offsetSmall]: dense && small,
                        [classes.offsetNormal]: !dense,
                        // [classes.container]: true,
                        [overrideClasses.root]: true,
                    })}
                >
                    {orderedByMain.map((userId, index) => (
                        <Grid
                            key={userId}
                            item
                            className={classNames({
                                [classes.dense]: dense && !small,
                                [classes.small]: dense && small,
                                [classes.normal]: !dense,
                                [overrideClasses.avatar]: true,
                            })}
                            style={{ zIndex: flipped ? orderedByMain.length - index : index }}
                            data-test-id="taskResponsible"
                        >
                            {users[userId] && (
                                <Avatar
                                    type={type}
                                    user={users[userId]}
                                    withBorder
                                    onDelete={onDelete ? () => onDelete(userId) : null}
                                    small={small}
                                    crown={mainIds.includes(userId)}
                                    disableHover={disableHover}
                                    hoverExtra={hoverExtras ? hoverExtras[userId] : null}
                                    disablePortal={disablePortal}
                                    fixedPosition={mainIds && orderedByMain.length === 1}
                                    showTooltip={showSingleTooltip}
                                    {...other}
                                />
                            )}
                        </Grid>
                    ))}
                    {React.Children.map(children, child => (
                        <Grid
                            item
                            className={classNames({
                                [classes.dense]: dense && !small,
                                [classes.small]: dense && small,
                                [classes.normal]: !dense,
                                [overrideClasses.avatar]: true,
                                [overrideClasses.extra]: true,
                            })}
                        >
                            {child}
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </ConditionalWrapper>
    );
};

Avatars.propTypes = {
    userIds: PropTypes.arrayOf(IdPropType).isRequired,
    mainIds: PropTypes.arrayOf(IdPropType),
    dense: PropTypes.bool,
    onDelete: PropTypes.func,
    small: PropTypes.bool,
    flipped: PropTypes.bool,
    classes: PropTypes.shape({
        root: PropTypes.string,
        avatar: PropTypes.string,
        extra: PropTypes.string,
    }),
    children: PropTypes.node,
    disableHover: PropTypes.bool,
    hoverExtras: PropTypes.shape({}),
    disablePortal: PropTypes.bool,
    type: PropTypes.string,
    placeholder: PropTypes.bool,
    label: PropTypes.string,
    showSingleTooltip: PropTypes.bool,
};

Avatars.defaultProps = {
    dense: false,
    onDelete: null,
    small: false,
    flipped: false,
    mainIds: [],
    classes: {},
    children: null,
    disableHover: false,
    hoverExtras: null,
    disablePortal: false,
    type: ASSIGNED,
    placeholder: false,
    label: null,
    showSingleTooltip: false,
};

export default Avatars;
