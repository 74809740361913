import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Select from '../form/formik/Select';
import {
    indexTaskStatuses,
    selectAllTaskStatuses,
    selectTaskStatusesInitialized,
} from '../../modules/taskStatus/taskStatusSlice';
import { useTranslation } from 'react-i18next';
import { TASK_STATUS_RESOURCE } from '../../modules/api/resources';
import TaskStatusSettingsForm from './TaskStatusSettingsForm';
import { useCan } from '../../modules/abilities/hooks';
import { UPDATE } from '../../modules/abilities/actions';

const TaskStatusSelect = ({ name, onChange, noIcon, I, ...other }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const taskStatuses = useSelector(state => selectAllTaskStatuses(state));
    const taskStatusesInitialized = useSelector(selectTaskStatusesInitialized);

    /* for admin-panel settings */
    const [selectedAdminEditItemId, setSelectedAdminEditItemId] = useState(null);
    const [selectedAdminEditItemLoading, setSelectedAdminEditItemLoading] = useState(false);
    const canAdminEdit = useCan(UPDATE, TASK_STATUS_RESOURCE);
    const adminFormPopupRef = useRef();

    const options = useMemo(() => {
        const taskStatusSelectOptions = taskStatuses
            ? Object.values(taskStatuses)
                  .sort((a, b) => (b.order != null) - (a.order != null) || a.order - b.order)
                  .map(taskStatus => ({
                      value: taskStatus.id,
                      label: taskStatus.name,
                      ...(!noIcon ? { icon: taskStatus.icon } : {}),
                      onEdit: () => setSelectedAdminEditItemId(taskStatus.id),
                  }))
            : [];
        return taskStatusSelectOptions;
    }, [taskStatuses, t]);

    useEffect(() => {
        if (!taskStatusesInitialized) dispatch(indexTaskStatuses());
    }, [dispatch, taskStatusesInitialized]);

    return (
        <Select
            name={name}
            label="Status"
            options={options}
            onChange={onChange}
            can={I}
            ItemEditFormPopupProps={{
                component: (
                    <TaskStatusSettingsForm
                        taskStatusId={selectedAdminEditItemId}
                        asPopup
                        popupStateRef={adminFormPopupRef}
                        onLoading={loading => setSelectedAdminEditItemLoading(loading)}
                    />
                ),
                canEdit: canAdminEdit,
                selectedEditItemValue: selectedAdminEditItemId,
                isLoading: selectedAdminEditItemLoading,
            }}
            {...other}
        />
    );
};

TaskStatusSelect.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    noIcon: PropTypes.bool,
    I: PropTypes.string,
};

TaskStatusSelect.defaultProps = {
    onChange: null,
    noIcon: false,
    I: null,
};

export default TaskStatusSelect;
