import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DELETE } from '../../../modules/abilities/actions';
import Can from '../../../modules/abilities/Can';
import { PERSONAL_ACCESS_TOKEN_RESOURCE } from '../../../modules/api/resources';
import { IdPropType } from '../../../modules/proptypes';
import DestroyIntent from '../../form/DestroyIntent';
import ResourceTableClassic from '../../table/resource/ResourceTableClassic';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { destroyPersonalAccessToken } from '../../../modules/personalAccessToken/personalAccessTokenSlice';
import Date from '../../utils/Date';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    item: {
        flex: 1,
        flexShrink: 0,
    },
    h3: {
        color: theme.palette.primary.main,
        fontSize: '1rem',
    },
}));

const EmployeePersonalAccessToken = ({ userId }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const classes = useStyles();

    const handleDelete = data => dispatch(destroyPersonalAccessToken({ id: data.id }));

    const columns = [
        {
            key: 'name',
        },
        {
            key: 'last_used_at',
            component: ({ last_used_at }) => <Date date={last_used_at} />,
        },
        {
            key: 'ip',
        },
        {
            key: 'user_agent',
        },
    ];

    const actions = useMemo(
        () => [
            {
                key: 'delete',
                action: ({ data }) => (
                    <Can I={DELETE} this={PERSONAL_ACCESS_TOKEN_RESOURCE}>
                        <DestroyIntent
                            onConfirmed={() => handleDelete(data)}
                            label={<DeleteForeverOutlinedIcon />}
                        />
                    </Can>
                ),
            },
        ],
        [handleDelete]
    );

    const params = useMemo(() => ({ created_by: userId }), [userId]);

    return (
        <Grid container className={classes.root} spacing={2}>
            <Grid item className={classes.item}>
                <Typography variant="h3" className={classes.h3}>
                    {t('components.EmployeeDialog.personalAccessToken')}
                </Typography>
                <ResourceTableClassic
                    resource={PERSONAL_ACCESS_TOKEN_RESOURCE}
                    listId="personalAccessTokensTable"
                    fetchParams={params}
                    columns={columns}
                    actions={actions}
                    showActionsInline={1}
                    autoload
                    index
                />
            </Grid>
        </Grid>
    );
};

EmployeePersonalAccessToken.propTypes = {
    userId: IdPropType.isRequired,
};

export default EmployeePersonalAccessToken;
