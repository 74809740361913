import React, { useCallback, useEffect, useRef } from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import MuiDialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import { useDialogControl } from './DialogControlContext';
import CloseIconButton from '../buttons/CloseIconButton';
import DestroyIntent from '../form/DestroyIntent';
import { CloseOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    close: {
        zIndex: 3,
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
        color: theme.palette.primary.contrastText,
    },
    closeBox: {
        zIndex: 999,
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
        boxShadow: `0px 0px 8px 8px ${theme.palette.primary.main}`,
        color: theme.palette.primary.contrastText,
        '& .MuiButton-root:first-child': {
            position: 'absolute',
            top: 0,
            right: 0,
            minWidth: 0,
            padding: 0,
            margin: 0,
        },
    },
    closeIconBox: {
        color: theme.palette.primary.contrastText,
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
        height: '0px',
    },
}));

const Dialog = ({ children, disableClose, onClose: overrideOnClose, ...props }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const destroyIntent = useRef(null);

    const { open, onClose, onExited, confirmClose } = useDialogControl();

    const handleEscModal = useCallback(
        event => {
            if (event.keyCode === 27 && destroyIntent.current) {
                destroyIntent.current.click();
            }
        },
        [destroyIntent]
    );

    const handleBackdropClick = useCallback(() => {
        if (destroyIntent.current) destroyIntent.current.click();
    }, [destroyIntent]);

    useEffect(() => {
        document.addEventListener('keydown', handleEscModal, true);
        return () => {
            document.removeEventListener('keydown', handleEscModal, true);
        };
    }, [handleEscModal]);

    return (
        <MuiDialog
            open={open}
            onClose={overrideOnClose || onClose}
            TransitionProps={{
                onExited,
            }}
            disableBackdropClick={disableClose || confirmClose}
            disableEscapeKeyDown={disableClose || confirmClose}
            onBackdropClick={handleBackdropClick}
            {...props}
        >
            {!disableClose &&
                (confirmClose ? (
                    <Box alignItems="center" className={classes.closeBox}>
                        <DestroyIntent
                            ref={destroyIntent}
                            onConfirmed={overrideOnClose || onClose}
                            label={
                                <Box className={classes.closeIconBox}>
                                    <CloseOutlined fontSize="small" color="inherit" />
                                </Box>
                            }
                            labelConfirm={t('components.CardModal.closeIntent')}
                            setYesLeft
                            noDanger
                            compact
                            contrast
                            dangerText={false}
                        />
                    </Box>
                ) : (
                    <Box className={classes.close}>
                        <CloseIconButton onClick={overrideOnClose || onClose} />
                    </Box>
                ))}
            {children}
        </MuiDialog>
    );
};

Dialog.propTypes = {
    children: PropTypes.node.isRequired,
    onClose: PropTypes.func,
    fullWidth: PropTypes.bool,
    maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    disableClose: PropTypes.bool,
};

Dialog.defaultProps = {
    onClose: null,
    fullWidth: false,
    disableClose: false,
    maxWidth: 'sm',
};

export default Dialog;
