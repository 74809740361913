import React, { useCallback, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import { IdPropType } from '../../../modules/proptypes';
import JournalTable from '../../journal/JournalTable';
import { useSelector } from 'react-redux';
import { selectEmployeeById } from '../../../modules/employees/employeesSlice';
import JournalSummary from '../../journal/JournalSummary';

const EmployeeJournal = ({ employeeId }) => {
    const [searchFilters, setSearchFilters] = useState([]);
    const employee = useSelector(state => selectEmployeeById(state, employeeId));
    const referenceId = useMemo(
        () => ({ key: 'user_id', value: employee?.user_id }),
        [employee?.user_id]
    );

    const summaryFetchParams = useMemo(
        () => ({
            user_id: employee?.user_id,
            filters: searchFilters,
        }),
        [employee, searchFilters]
    );

    const handleSearch = useCallback(filters => {
        setSearchFilters(filters);
    }, []);

    return (
        <JournalTable
            listId={`journals.employees.${employeeId}`}
            referenceId={referenceId}
            onSearch={handleSearch}
            bottomToolbarCustomActions={<JournalSummary fetchParams={summaryFetchParams} />}
        />
    );
};

EmployeeJournal.propTypes = {
    employeeId: IdPropType.isRequired,
};

export default EmployeeJournal;
