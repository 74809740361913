import { useEffect, useMemo } from 'react';
import { useCallbackFunc } from '../hooks';
import { useResourceList } from '../lists/hooks';
import { ATTACHMENT_RESOURCE } from '../api/resources';
import { useSelector } from 'react-redux';

export const PROTOCOL_ITEM_MODEL = 'App\\Models\\ProtocolItem';

export const makeAttachmentListId = protocolItemId => `attachments.${protocolItemId}`;

export const buildAttachmentListConfig = protocolItemId => {
    const listId = makeAttachmentListId(protocolItemId);

    const checkItemCriteria = item => {
        if (!item) {
            return false;
        }
        const { attachable_id, attachable_type } = item;
        return attachable_id === protocolItemId && attachable_type === PROTOCOL_ITEM_MODEL;
    };

    const compareItemCriteria = (old, current) => 1;

    return {
        listId,
        resource: ATTACHMENT_RESOURCE,
        fetchParams: {
            attachable_id: protocolItemId,
            attachable_type: PROTOCOL_ITEM_MODEL,
        },
        continuous: true,
        criteria: {
            check: checkItemCriteria,
            compare: compareItemCriteria,
        },
    };
};

export const useProtocolItemAttachments = (protocolItemId, initialLoad = true) => {
    const attachmentListConfig = buildAttachmentListConfig(protocolItemId);
    const { loading, initialized, fullSelector, handleFetch, handleNextPage } =
        useResourceList(attachmentListConfig);

    const attachmentsById = useSelector(fullSelector);
    const attachments = useMemo(() => Object.values(attachmentsById), [attachmentsById]);

    useEffect(() => {
        if (initialLoad && handleFetch && !loading && !initialized && protocolItemId) {
            handleFetch();
        }
    }, [protocolItemId, loading, initialized, handleFetch]);

    return {
        listId: attachmentListConfig.listId,
        attachments,
        handleFetch,
        handleNextPage,
    };
};
