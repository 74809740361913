import React from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Can from '../../../../modules/abilities/Can';
import DestroyIntent from '../../../form/DestroyIntent';
import { DELETE } from '../../../../modules/abilities/actions';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        height: '100%',
    },

    indicator: {
        backgroundColor: theme.palette.primary.main,
        left: 0,
        width: 5,
    },

    scrollButtons: {
        height: 24,
    },

    indentation: {
        paddingLeft: theme.spacing(3),
    },

    destroy: {
        position: 'absolute',
        padding: theme.spacing(1),
        bottom: 0,
    },
}));

const TabDialogNav = ({ tabs, active, onChange, onDelete, subject, disabled }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={disabled ? false : active}
                onChange={onChange}
                textColor="primary"
                aria-label="Card navigation"
                className={classes.root}
                classes={{
                    indicator: classes.indicator,
                    scrollButtons: classes.scrollButtons,
                }}
            >
                {tabs.map(tabData => (
                    <Tab
                        label={tabData.title}
                        id={`card-nav-${tabData.name}`}
                        aria-controls={`card-panel-${tabData.name}`}
                        value={tabData.name}
                        disabled={disabled || tabData.disabled}
                        key={Math.floor(Math.random() * 10000000)}
                    />
                ))}
            </Tabs>
            {onDelete && (
                <Box className={classes.destroy}>
                    <Can I={DELETE} this={subject}>
                        <DestroyIntent
                            onConfirmed={onDelete}
                            labelConfirm={t('components.TabDialog.delete')}
                            contrast
                        />
                    </Can>
                </Box>
            )}
        </>
    );
};

TabDialogNav.propTypes = {
    active: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    subject: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
    disabled: PropTypes.bool,
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            content: PropTypes.node.isRequired,
        }).isRequired
    ),
};

TabDialogNav.defaultProps = {
    onDelete: null,
    subject: null,
    disabled: false,
    tabs: [],
};

export default TabDialogNav;
