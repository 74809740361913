import React, { useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import DateTimeRangePicker from '../DateTimeRangePicker';
import { SingleInputDateTimeRangeField } from '@mui/x-date-pickers-pro';
import { formatLocalDateTime, getDateRangeTimes } from '../../../../modules/datetime/utils';

const AutocompleteDateTimeRangeSelect = ({ label, onChange }) => {
    const [open, setOpen] = useState(true);

    const dateSeparator = useMemo(() => 'bis', []); //ToDo: I18n

    const handleAccept = (value, context) => {
        if (Array.isArray(value) && value.length === 2) {
            const [startRaw, endRaw] = value;
            const { start, end } = getDateRangeTimes(startRaw, endRaw);
            const valueString = `${formatLocalDateTime(
                start
            )} ${dateSeparator} ${formatLocalDateTime(end)}`;

            onChange(valueString);
        }
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <DateTimeRangePicker
            value={[]}
            slots={{
                field: SingleInputDateTimeRangeField,
            }}
            slotProps={{
                field: {
                    dateSeparator,
                    fullWidth: true,
                },
            }}
            open={open}
            label={label}
            autoFocus
            onAccept={handleAccept}
            onOpen={handleOpen}
            onClose={handleClose}
        />
    );
};

AutocompleteDateTimeRangeSelect.propTypes = {
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default AutocompleteDateTimeRangeSelect;
