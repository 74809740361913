import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { EMPLOYEE_RESOURCE, RESOURCE_RESOURCE } from '../../../modules/api/resources';
import { useSelector } from 'react-redux';
import { selectEmployeesById } from '../../../modules/employees/selectors';
import { selectResourcesById } from '../../../modules/resources/selectors';

const CalendarDetailResourceGroupText = ({ groupedIds, groupConfig }) => {
    const { t } = useTranslation();
    const allEmployees = useSelector(selectEmployeesById);
    const allResources = useSelector(selectResourcesById);

    const baseResources = useMemo(() => {
        const cleanValue = groupedIds || [];
        const ids = Array.isArray(cleanValue) ? cleanValue : [cleanValue];

        if (groupConfig.api === EMPLOYEE_RESOURCE) {
            return ids.map(id => {
                if (id in allEmployees) {
                    const displayName = allEmployees[id]?.person?.display_name;

                    if (displayName) {
                        return displayName;
                    }
                }

                return '';
            });
        }

        if (groupConfig.api === RESOURCE_RESOURCE) {
            return ids.map(id => {
                if (id in allResources) {
                    const displayName = allResources[id]?.display_name;

                    if (displayName) {
                        return displayName;
                    }
                }

                return '';
            });
        }
    }, [groupedIds, groupConfig]);

    return `${groupConfig?.label}: ${baseResources.join('; ')}`;
};

CalendarDetailResourceGroupText.propTypes = {
    groupedIds: PropTypes.shape({}).isRequired,
    groupConfig: PropTypes.shape({}).isRequired,
};

export default CalendarDetailResourceGroupText;
