import React from 'react';
import * as PropTypes from 'prop-types';
import { DateRangePicker as DateRangePickerBase } from '@mui/x-date-pickers-pro';
import { useTranslation } from 'react-i18next';
import { PATTERN } from '../../../modules/datetime/utils';

const DateTimeRangePicker = ({ value, slots, slotProps, onAccept, ...other }) => {
    const { t } = useTranslation();

    return (
        <DateRangePickerBase
            value={value}
            slots={slots}
            slotProps={{
                textField: {
                    size: 'small',
                    variant: 'outlined',
                },
                ...slotProps,
            }}
            format={PATTERN.localDate}
            ampm={false}
            orientation="landscape"
            displayWeekNumber
            onAccept={onAccept}
            {...other}
        />
    );
};

DateTimeRangePicker.propTypes = {
    value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    slots: PropTypes.shape({}),
    slotProps: PropTypes.shape({}),
    onAccept: PropTypes.func.isRequired,
};

DateTimeRangePicker.defaultProps = {
    value: null,
    slots: {},
    slotProps: {},
};

export default DateTimeRangePicker;
