import React from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import GroupsPreview from './GroupsPreview';
import { IdPropType } from '../../modules/proptypes';

const GroupsData = ({ resourceId, employeeId }) => {
    const { t } = useTranslation();

    return (
        <>
            <Typography variant="h2">{t('components.ResourceDialog.tabs.groups.title')}</Typography>
            <br />
            <GroupsPreview resourceId={resourceId} employeeId={employeeId} />
        </>
    );
};

GroupsData.propTypes = {
    resourceId: IdPropType,
    personId: IdPropType,
};

GroupsData.defaultProps = {
    resourceId: null,
    personId: null,
};

export default GroupsData;
