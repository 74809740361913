import { createResourceSlice } from '../redux/resource/createResourceSlice';
import { CHECKLIST_RESOURCE } from '../api/resources';
const checklistSlice = createResourceSlice({
    resource: CHECKLIST_RESOURCE,
    byKey: 'taskId',
});
export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,
    indexChecklists,
    showChecklist,
    storeChecklist,
    updateChecklist,
    archiveChecklist,
    restoreChecklist,
    destroyChecklist,
} = checklistSlice.actions;
export const {
    selectChecklistById,
    makeChecklistsByIdsSelector,
    selectAllChecklistIds,
    selectAllChecklists,
    selectChecklistsLoading,
    selectChecklistsInitialized,
    selectChecklistLoading,
    selectChecklistInitialized,
    selectChecklistIdsByTaskId,
    makeChecklistsBySelector,
    makeChecklistsByTaskIdSelector,
    selectChecklistLoadingBy,
    selectChecklistInitializedBy,
} = checklistSlice.selectors;
export const { useChecklists, useChecklist, useChecklistsBy } = checklistSlice.hooks;
export default checklistSlice.reducer;
