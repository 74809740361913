import React, { useEffect } from 'react';
import { DialogActions, DialogTitle, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as PropTypes from 'prop-types';
import TabDialogNav from './nav/TabDialogNav';
import TabDialogTabs from './nav/TabDialogTabs';
import { useDialogControl } from '../DialogControlContext';
import Dialog from '../Dialog';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
    },

    header: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },

    container: {
        flexGrow: 1,
        minHeight: 0,
        flexWrap: 'nowrap',
    },

    sidebar: {
        backgroundColor: theme.palette.grey[100],
        width: 220,
        paddingTop: theme.spacing(2),
        paddingBottom: 0,
    },

    content: {
        overflowY: 'auto',
    },

    actions: {
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        background: `linear-gradient(90deg, ${theme.palette.grey[100]} 220px, ${theme.palette.background.paper} 220px)`,
    },

    destroy: {},
    close: {},
}));

const TabDialog = ({
    title,
    tabs,
    actions,
    onClose: overrideOnClose,
    onDelete,
    subject,
    'data-test-id': testId,
    loading,
    resetTabWhenChanged,
    maxWidth,
    initTab,
}) => {
    const classes = useStyles();

    const { onClose, onExited, confirmClose, setConfirmClose } = useDialogControl();

    const handleClose = event => {
        setConfirmClose(false);

        if (overrideOnClose) {
            overrideOnClose(event);
        } else {
            onClose(event);
        }
    };

    const defaultTab = initTab === '' ? tabs[0].name : initTab;
    const [tab, setTab] = React.useState(defaultTab);
    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    useEffect(() => {
        setTab(defaultTab);
    }, [defaultTab, resetTabWhenChanged]);

    const cleanedTab = tabs.some(({ name }) => name === tab) ? tab : defaultTab;
    const cleanedTabData = tabs.find(({ name }) => name === tab);

    return (
        <Dialog
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth={maxWidth}
            onClose={handleClose}
            TransitionProps={{
                onExited,
            }}
            disableBackdropClick={confirmClose}
            disableEscapeKeyDown={confirmClose}
            classes={{
                paper: classes.root,
            }}
            data-test-id={testId}
        >
            <DialogTitle className={classes.header}>
                <Typography variant="h2" component="span">
                    {title}
                </Typography>
            </DialogTitle>
            <Grid container className={classes.container}>
                <Grid item className={classes.sidebar}>
                    <TabDialogNav
                        onChange={handleChange}
                        onDelete={onDelete}
                        active={cleanedTab}
                        tabs={tabs}
                        subject={subject}
                    />
                </Grid>
                <Grid item xs className={classes.content}>
                    <TabDialogTabs tab={cleanedTab} tabs={tabs} loading={loading} />
                </Grid>
            </Grid>
            {cleanedTabData && !('borderless' in cleanedTabData && cleanedTabData.borderless) && (
                <DialogActions className={classes.actions}>
                    {actions[cleanedTab] || null}
                </DialogActions>
            )}
        </Dialog>
    );
};

TabDialog.propTypes = {
    onClose: PropTypes.func,
    onDelete: PropTypes.func,
    subject: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
    title: PropTypes.string,
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            content: PropTypes.node.isRequired,
        }).isRequired
    ),
    actions: PropTypes.objectOf(PropTypes.node),
    'data-test-id': PropTypes.string,
    loading: PropTypes.bool,
    resetTabWhenChanged: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
    maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    initTab: PropTypes.string,
};

TabDialog.defaultProps = {
    onClose: null,
    onDelete: null,
    subject: null,
    title: 'Dialog',
    tabs: [],
    actions: null,
    'data-test-id': null,
    loading: false,
    resetTabWhenChanged: null,
    maxWidth: false, // override default explicitly
    initTab: '',
};

export default TabDialog;
