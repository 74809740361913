import React from 'react';
import * as PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import TextInput from '../form/formik/TextInput';
import { useTranslation } from 'react-i18next';
import Button from '../form/base/Button';

const formatName = (name, prefix) => (prefix ? `${prefix}.${name}` : name);

const AddressFormBody = ({ namePrefix }) => {
    const { t } = useTranslation();

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <TextInput
                    name={formatName('address1', namePrefix)}
                    label={t('components.Address.address1')}
                    autoFocus
                    size="small"
                />
            </Grid>
            <Grid item xs={6}>
                <TextInput
                    name={formatName('address2', namePrefix)}
                    label={t('components.Address.address2')}
                    size="small"
                />
            </Grid>
            <Grid item xs={6}>
                <TextInput
                    name={formatName('zip', namePrefix)}
                    label={t('components.Address.zip')}
                    size="small"
                />
            </Grid>
            <Grid item xs={6}>
                <TextInput
                    name={formatName('city', namePrefix)}
                    label={t('components.Address.city')}
                    size="small"
                />
            </Grid>
        </Grid>
    );
};

AddressFormBody.propTypes = {
    namePrefix: PropTypes.string,
};

AddressFormBody.defaultProps = {
    namePrefix: null,
};

export default AddressFormBody;
