import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import * as PropTypes from 'prop-types';

const EmailHeaderRow = ({ title, children }) => {
    return (
        <Grid container style={{ minHeight: '28px' }} mb={0.5} alignItems="center">
            <Grid item>
                <Box mr={1}>
                    <Typography variant="body2" color="textSecondary">
                        {title}
                    </Typography>
                </Box>
            </Grid>
            {children}
        </Grid>
    );
};

EmailHeaderRow.propTypes = {
    title: PropTypes.string.isRequired,
};

export default EmailHeaderRow;
