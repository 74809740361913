import { all } from 'redux-saga/effects';
import cardSagas from '../cards/sagas';
import contactSagas from '../contacts/sagas';
import contactablesSagas from '../contactables/sagas';
import datacontainerSagas from '../datacontainers/sagas';
import protocolItemSagas from '../protocolItems/sagas';
import listSagas from '../lists/sagas';
import userSagas from '../users/sagas';
import checklistSagas from '../checklists/sagas';
import resourcesSagas from '../resources/sagas';
import employeesSagas from '../employees/sagas';
import taskSagas from '../tasks/sagas';
import appointmentSagas from '../appointments/sagas';
import activitySagas from '../activities/sagas';

export default function* rootSaga() {
    yield all([
        ...listSagas,
        ...cardSagas,
        ...contactSagas,
        ...contactablesSagas,
        ...datacontainerSagas,
        ...protocolItemSagas,
        ...userSagas,
        ...checklistSagas,
        ...resourcesSagas,
        ...employeesSagas,
        ...taskSagas,
        ...appointmentSagas,
        ...activitySagas,
    ]);
}
