import React from 'react';
import * as PropTypes from 'prop-types';
import { FormControlLabel, Switch as MUISwitch } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ContextualCan from '../../../modules/abilities/ContextualCan';
import { READ } from '../../../modules/abilities/actions';
import { useContextualCan } from '../../../modules/abilities/hooks';

const useContrastStyles = makeStyles(theme => ({
    colorPrimary: {
        '& + $track': {
            backgroundColor: theme.palette.primary.contrastText,
        },
        '& $thumb': {
            backgroundColor: theme.palette.primary.light,
        },

        '&$checked + $track': {
            backgroundColor: theme.palette.primary.contrastText,
        },
        '&$checked $thumb': {
            backgroundColor: theme.palette.primary.contrastText,
        },
    },

    colorSecondary: {
        '& + $track': {
            backgroundColor: theme.palette.secondary.contrastText,
        },
        '& $thumb': {
            backgroundColor: theme.palette.secondary.light,
        },

        '&$checked + $track': {
            backgroundColor: theme.palette.secondary.contrastText,
        },
        '&$checked $thumb': {
            backgroundColor: theme.palette.secondary.contrastText,
        },
    },

    checked: {},
    track: {},
    thumb: {},
}));

const BaseSwitch = ({
    label,
    name,
    value,
    onChange,
    color,
    disabled,
    labelPlacement,
    contrast,
    I,
}) => {
    const contrastClasses = useContrastStyles();
    const canDo = useContextualCan(I ? I : READ, name);

    return (
        <ContextualCan I={READ} field={name}>
            <FormControlLabel
                control={
                    <MUISwitch
                        checked={value}
                        onChange={onChange}
                        name={name}
                        color={color}
                        disabled={disabled || !canDo}
                        classes={contrast ? contrastClasses : undefined}
                    />
                }
                label={label}
                labelPlacement={labelPlacement}
            />
        </ContextualCan>
    );
};

BaseSwitch.propTypes = {
    value: PropTypes.bool.isRequired,
    label: PropTypes.node,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    labelPlacement: PropTypes.string,
    contrast: PropTypes.bool,
    I: PropTypes.string,
};

BaseSwitch.defaultProps = {
    label: '',
    color: 'primary',
    disabled: false,
    labelPlacement: 'start',
    contrast: false,
    I: null,
};

export default BaseSwitch;
