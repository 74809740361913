import React from 'react';
import * as PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        // marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}));

const Title = ({ value, color }) => {
    const classes = useStyles();

    return (
        <Typography variant="h3" component="p" color={color || undefined} className={classes.root}>
            {value}
        </Typography>
    );
};

Title.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    color: PropTypes.string,
    className: PropTypes.string,
};

Title.defaultProps = {
    color: undefined,
    className: null,
};

export default Title;
