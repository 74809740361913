import React from 'react';
import * as PropTypes from 'prop-types';
import { TableCell as MuiTableCell } from '@mui/material';

const TableCell = ({
    value,
    data,
    related,
    as,
    component: Component,
    sortDirection,
    align,
    className,
    dataTestId,
}) => {
    return (
        <MuiTableCell
            sortDirection={sortDirection}
            align={align}
            className={className}
            data-test-id={`cell-${dataTestId}`}
        >
            {Component ? (
                <Component data={data} related={related} {...{ [as]: value }} />
            ) : (
                <>{value}</>
            )}
        </MuiTableCell>
    );
};

TableCell.propTypes = {
    value: PropTypes.node,
    data: PropTypes.shape({}),
    related: PropTypes.shape({}),
    as: PropTypes.string,
    component: PropTypes.func,
    align: PropTypes.string,
    sortDirection: PropTypes.string,
    className: PropTypes.string,
    dataTestId: PropTypes.string,
};

TableCell.defaultProps = {
    value: null,
    data: null,
    related: null,
    as: null,
    align: 'inherit',
    component: null,
    sortDirection: null,
    className: null,
    dataTestId: null,
};

export default TableCell;
