import React, { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compareDesc } from 'date-fns';
import { IdPropType } from '../../modules/proptypes';
import { useResourceList } from '../../modules/lists/hooks';
import { PROTOCOL_ITEM_RESOURCE } from '../../modules/api/resources';

export const PROTOCOL_DISPLAY = {
    FULL: 'full',
    LITE: 'lite',
};

const ProtocolContext = createContext(null);

export const useProtocol = () => useContext(ProtocolContext);

const ProtocolProvider = ({
    cardId,
    protocolId,
    pinned,
    protocolDisplay,
    readOnly,
    overrideListId,
    children,
}) => {
    const listId = overrideListId || `Protocol.${cardId}.${protocolId || 'overview'}`;

    const fetchParams = useMemo(
        () => ({
            card_id: protocolId ? undefined : cardId,
            protocol_id: protocolId || undefined,
            pinned: pinned || undefined,
        }),
        [protocolId, cardId, pinned]
    );

    const { dataIds, initialized, loading, page, handlePage, handleNextPage, handleSearch } =
        useResourceList({
            listId,
            resource: PROTOCOL_ITEM_RESOURCE,
            initialSearch: fetchParams,
            initialOrderBy: ['date', 'desc'],
            with: ['ProtocolItem.contacts'],
            continuous: true,
            limit: 20,
            criteria: {
                check: item =>
                    item.card_id === cardId &&
                    ((pinned && item.pinned) ||
                        (!pinned && item.protocol_id === protocolId) ||
                        (!pinned && !protocolId)),
                compare: (item, newItem) => compareDesc(item.date, newItem.date),
            },
        });

    const value = useMemo(
        () => ({
            listId,
            cardId,
            protocolId,
            fetchParams,
            protocolDisplay,
            readOnly,
            dataIds,
            initialized,
            loading,
            page,
            handlePage,
            handleNextPage,
            handleSearch,
        }),
        [
            listId,
            cardId,
            protocolId,
            fetchParams,
            protocolDisplay,
            readOnly,
            dataIds,
            initialized,
            loading,
            page,
            handlePage,
            handleNextPage,
            handleSearch,
        ]
    );

    return <ProtocolContext.Provider value={value}>{children}</ProtocolContext.Provider>;
};

ProtocolProvider.propTypes = {
    cardId: IdPropType.isRequired,
    protocolId: IdPropType.isRequired,
    pinned: PropTypes.bool,
    protocolDisplay: PropTypes.oneOf([...Object.values(PROTOCOL_DISPLAY || {})]),
    readOnly: PropTypes.bool,
    overrideListId: PropTypes.string,
    children: PropTypes.node.isRequired,
};

ProtocolProvider.defaultProps = {
    pinned: false,
    protocolDisplay: PROTOCOL_DISPLAY.FULL,
    readOnly: false,
    overrideListId: null,
};

export default ProtocolProvider;
