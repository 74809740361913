import axios from 'axios';
import Echo from 'laravel-echo';
import { fulfilledAction, socketAction } from './actions';
import logger from '../../logger';
import { selectMetadataById } from '../metadata/selectors';
import { statusChanged } from '../cloud/cloudSlice';

window.io = require('socket.io-client');

const getCSRFCookie = () =>
    axios({
        method: 'get',
        url: '/sanctum/csrf-cookie',
        baseURL: '/', // FIXME: should be REACT_APP_API_AUTH
    });

const configureEcho = () =>
    getCSRFCookie().then(response => {
        return new Echo({
            broadcaster: 'socket.io',
            host: window.location.host,
            auth: {
                headers: {
                    // eslint-disable-next-line no-restricted-globals
                    Referer: `${window.location.protocol}//${window.location.host}`,
                    'X-XSRF-TOKEN': response.config.headers['X-XSRF-TOKEN'],
                },
            },
        });
    });

export const attachSocket = api => {
    const cloudHealthHandler = e => {
        logger.info('HealthEvent', e);
        api.dispatch(statusChanged(e.status));
    };

    const storeHandler = e => {
        logger.info('Event', e); // TODO später zu logger.debug ändern?

        if (api[e.model]) {
            if (e.type === 'store' || e.type === 'update') {
                const jitter = Number.parseInt(process.env.REACT_APP_SOCKET_EVENT_JITTER, 10);
                const staleModel = api.getState()[e.model].byId[e.id];
                setTimeout(() => {
                    const contexts = selectMetadataById(api.getState(), e.model, e.id);

                    if (
                        api[e.model].config.socket &&
                        api[e.model].config.socket.disableDefaultHandling &&
                        api[e.model].config.socket.disableDefaultHandling.includes(e.type)
                    ) {
                        api.dispatch(
                            socketAction(
                                e.model,
                                e.type,
                                { id: e.id },
                                {
                                    contexts: Object.keys(contexts),
                                    staleModel,
                                }
                            )
                        );
                    } else if (Array.isArray(e.id) && e.id.length > 1) {
                        api[e.model]
                            .search({
                                id: e.id,
                                contexts: Object.keys(contexts),
                            })
                            .then(({ data, meta }) => {
                                if (Array.isArray(data) && data.length) {
                                    api.dispatch(
                                        socketAction(e.model, 'bulk', data, {
                                            ...meta,
                                            contexts: Object.keys(contexts),
                                        })
                                    );
                                }
                            });
                    } else {
                        api[e.model]
                            .show(
                                { id: e.id, contexts: Object.keys(contexts) },
                                { socketReaction: true, staleModel }
                            )
                            .then(({ data, meta }) => {
                                /* apparently delete fires an additional update event o.o */
                                const [newModel] = Array.isArray(data) ? data : [data];
                                if (newModel) {
                                    api.dispatch(
                                        socketAction(e.model, e.type, newModel, {
                                            ...meta,
                                            contexts: Object.keys(contexts),
                                            staleModel,
                                        })
                                    );
                                }
                            });
                    }
                }, Math.floor(Math.random() * jitter));
            } else if (e.type === 'destroy') {
                const destroyedEntity = api.getState()[e.model].byId[e.id];

                if (destroyedEntity) {
                    api.dispatch(
                        fulfilledAction(e.model, e.type, {}, e.id, {
                            socketReaction: true,
                            destroyedEntity,
                        })
                    );
                } else if (Array.isArray(e.id) && e.id.length) {
                    api.dispatch(
                        fulfilledAction(e.model, 'destroyBulkFulfilled', {}, e.id, {
                            socketReaction: true,
                        })
                    );
                }
            }
        }
    };

    const configureListeners = user => {
        api.echo.private('debug').listen('DebugEvent', e => {
            logger.info('DebugEvent', e); // TODO später zu logger.debug ändern?
        });

        // TODO ab und zu kommt das gleiche Event mehrmals -> throttle einbauen?
        api.echo.private('private').listen('Event', storeHandler);

        api.echo
            .private('private')
            .listen('.App\\Components\\Cloud\\Sync\\Events\\CloudHealthEvent', cloudHealthHandler);

        if (user && user.id) {
            api.echo.private(`single.${user.id}`).listen('SingleUserEvent', storeHandler);
        }
    };

    const authenticate = params =>
        configureEcho().then(echo => {
            // eslint-disable-next-line no-param-reassign
            api.echo = echo;
            configureListeners(params);
            return params;
        });

    const logout = () => {
        if (api.echo) {
            api.echo.disconnect();
        }
    };

    if (!(process.env.NODE_ENV === 'development' && process.env.REACT_APP_DISABLE_SOCKETS)) {
        api.onAuthenticate(authenticate);
    }
    api.onLogout(logout);
};
