import React from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { selectUserById } from '../../../../modules/users/selectors';
import { IdPropType } from '../../../../modules/proptypes';
import Avatar from '../../../icons/Avatar';
import { emphasize } from '@mui/material';

const useStyles = makeStyles(theme => ({
    chipLabel: {
        display: 'flex',
        alignItems: 'flex-start',
    },

    type: {
        color: theme.palette.primary.light,
    },
    typeExtraSpace: {
        paddingRight: 4,
    },

    label: {
        paddingLeft: 4,
    },

    chip: {
        backgroundColor: theme.palette.background.primary,

        '&:hover, &:focus': {
            backgroundColor: emphasize(theme.palette.background.primary, 0.08),
        },
    },
}));

const TypedAutocompleteTag = ({ option, ...other }) => {
    const classes = useStyles();
    const optionIsUser = option.type === 'App\\Models\\User';
    const user = useSelector(state => selectUserById(state, optionIsUser ? option.id : null));

    return (
        <Chip
            label={
                <Typography component="span" className={classes.chipLabel}>
                    <Typography
                        component="span"
                        className={classNames({
                            [classes.type]: true,
                            [classes.typeExtraSpace]: user,
                        })}
                    >
                        {option.label}:
                    </Typography>
                    {user && <Avatar user={user} tiny inline disableHover />}
                    <Typography component="span" className={classes.label}>
                        {option.value}
                    </Typography>
                </Typography>
            }
            classes={{
                root: classes.chip,
            }}
            {...other}
        />
    );
};

TypedAutocompleteTag.propTypes = {
    option: PropTypes.shape({
        id: IdPropType,
        value: PropTypes.string,
        type: PropTypes.string,
        label: PropTypes.string,
    }).isRequired,
};

export default TypedAutocompleteTag;
