import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { usePermissionSubject } from './PermissionSubjectProvider';
import { useAbility } from './AbilityProvider';
import { selectUserIsRoot } from '../auth/selectors';
import { resolveAction, WRITE } from './actions';
import { resolveCan } from './utils';

export const useDynamicCan = () => {
    const ability = useAbility();
    const isRoot = useSelector(selectUserIsRoot);

    return useMemo(
        () => ({
            handleCan: (action, subject, field = null) => {
                if (isRoot) {
                    return true;
                }

                return resolveCan(ability, action, subject, field);
            },
        }),
        [ability, isRoot]
    );
};

export const useCan = (action, subject, field = null) => {
    const ability = useAbility();
    const isRoot = useSelector(selectUserIsRoot);

    return useMemo(() => {
        if (isRoot) {
            return true;
        }

        return resolveCan(ability, action, subject, field);
    }, [isRoot, ability, action, subject, field]);
};

export const useCanWrite = (subject, field) => {
    const action = resolveAction(WRITE, subject);
    return useCan(action, subject, field);
};

export const useContextualCan = (action, field = null) => {
    const subject = usePermissionSubject();
    return useCan(action, subject, field) || !subject;
};

export const useContextualCanWrite = (field = null) => {
    return useContextualCan(WRITE, field);
};
