export const SITE_NAME_SETTINGS = 'app.site.name';

export const LOGO_DEFAULT_SETTINGS = 'app.logo.default';

export const LOGO_SMALL_SETTINGS = 'app.logo.small';

export const LOGO_BIG_SETTINGS = 'app.logo.big';

export const MAP_TILES_SETTINGS = 'map.tiles';

export const FAVICON_ICO_SETTINGS = 'app.favicon.ico';

export const FAVICON_PNG_SETTINGS = 'app.favicon.png';

export const MANIFEST_SETTINGS = 'app.site.manifest';

export const MANIFEST_THEME_OPTIONS = 'app.theme.options';

export const IDENTIFIER_MODE_SETTINGS = 'app.identifier.mode';
export const IDENTIFIER_OFFER_SETTINGS = 'app.identifier.offer';
export const IDENTIFIER_ORDER_SETTINGS = 'app.identifier.order';
export const IDENTIFIER_INVOICE_SETTINGS = 'app.identifier.invoice';

export const MAP_DEFAULT_SETTINGS = 'map.default';

export const CALENDAR_SETTINGS = 'calendar';

export const SESSION_TIMEOUT_SETTINGS = 'app.session.timeout';

export const CLOUD_MAX_FILESIZE_SETTINGS = 'cloud.max_filesize';
