import React from 'react';
import * as PropTypes from 'prop-types';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import { EMPLOYEE_RESOURCE, RESOURCE_RESOURCE } from '../../../modules/api/resources';
import ConstructionIcon from '@mui/icons-material/Construction';
import PersonIcon from '@mui/icons-material/Person';
import AddResourceIcon from '../AddResourceIcon';
import { PersonAddAlt1 as PersonAddAlt1Icon } from '@mui/icons-material';
import { Icon } from '@mui/material';
import IconPair, { ICON_POSITION } from '../../icons/IconPair';

const CalendarResourceGroupIcon = ({ groupConfig, add, tiny }) => {
    const baseStyles = tiny ? { fontSize: 12 } : null;
    const defaultIcon = groupConfig?.iconDefault;

    if (defaultIcon && typeof defaultIcon === 'string' && defaultIcon !== '') {
        const addIcon = groupConfig?.iconAdd;

        if (add) {
            if (addIcon && typeof addIcon === 'string' && addIcon !== '') {
                return <Icon style={baseStyles}>{addIcon}</Icon>;
            }

            return (
                <IconPair
                    PrimaryIcon={props => (
                        <Icon style={baseStyles} {...props}>
                            {defaultIcon}
                        </Icon>
                    )}
                    position={ICON_POSITION.CENTER_LEFT}
                />
            );
        } else {
            return <Icon style={baseStyles}>{defaultIcon}</Icon>;
        }
    } else {
        if (groupConfig?.api === RESOURCE_RESOURCE) {
            if (add) {
                return <AddResourceIcon style={baseStyles} />;
            }
            return <ConstructionIcon style={baseStyles} />;
        }

        if (groupConfig?.api === EMPLOYEE_RESOURCE) {
            if (add) {
                return <PersonAddAlt1Icon style={baseStyles} />;
            }
            return <PersonIcon style={baseStyles} />;
        }
    }

    return <GppMaybeIcon style={baseStyles} />;
};

CalendarResourceGroupIcon.propTypes = {
    groupConfig: PropTypes.shape({}).isRequired,
    add: PropTypes.bool,
    tiny: PropTypes.bool,
};

CalendarResourceGroupIcon.defaultProps = {
    add: false,
    tiny: false,
};

export default CalendarResourceGroupIcon;
