import React from 'react';
import * as PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';
import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import {
    selectListIsLoading,
    selectListPage,
    selectListPageCount,
} from '../../../modules/lists/selectors';

const ResourceWaypoint = ({
    listId,
    onEnter,
    showLoading,
    showInitialLoading,
    overrideLoading,
    style,
    ...other
}) => {
    const loading = useSelector(state => selectListIsLoading(state, listId));
    const pageCount = useSelector(state => selectListPageCount(state, listId));
    const page = useSelector(state => selectListPage(state, listId));

    if (pageCount >= page + 1 && !loading) {
        return (
            <Waypoint onEnter={onEnter} key={page}>
                <Box style={{ textAlign: 'center', ...style }} {...other}>
                    <CircularProgress size={20} />
                </Box>
            </Waypoint>
        );
    } else if (
        overrideLoading ||
        (showLoading && (showInitialLoading || pageCount >= page + 1) && loading)
    ) {
        return (
            <Waypoint onEnter={() => {}}>
                <Box style={{ textAlign: 'center', ...style }} {...other}>
                    <CircularProgress size={20} disableShrink />
                </Box>
            </Waypoint>
        );
    }

    return null;
};

ResourceWaypoint.propTypes = {
    listId: PropTypes.string.isRequired,
    onEnter: PropTypes.func.isRequired,
    showLoading: PropTypes.bool,
    showInitialLoading: PropTypes.bool,
    overrideLoading: PropTypes.bool,
    style: PropTypes.shape({}),
};

ResourceWaypoint.defaultProps = {
    showLoading: false,
    showInitialLoading: false,
    overrideLoading: false,
    style: {},
};

export default ResourceWaypoint;
