import { CONTACT_RESOURCE } from '../api/resources';

export const indexContacts = params => (dispatch, getState, api) =>
    api[CONTACT_RESOURCE].index(params);

export const searchContacts = (params, meta = {}, sequentialId = null) => (
    dispatch,
    getState,
    api
) => api[CONTACT_RESOURCE].search(params, meta, sequentialId);

export const autocompleteContacts = params => (dispatch, getState, api) =>
    api[CONTACT_RESOURCE].autocomplete(params);

export const suggestContacts = params => (dispatch, getState, api) =>
    api[CONTACT_RESOURCE].suggest(params);

export const showContact = params => (dispatch, getState, api) =>
    api[CONTACT_RESOURCE].show(params);

export const storeContact = params => (dispatch, getState, api) =>
    api[CONTACT_RESOURCE].store(params);

export const updateContact = params => (dispatch, getState, api) =>
    api[CONTACT_RESOURCE].update(params);

export const archiveContact = params => (dispatch, getState, api) =>
    api[CONTACT_RESOURCE].destroy({ ...params, forceDelete: false });

export const destroyContact = params => (dispatch, getState, api) =>
    api[CONTACT_RESOURCE].destroy({ ...params, forceDelete: true });

export const restoreContact = params => (dispatch, getState, api) =>
    api[CONTACT_RESOURCE].restore(params);
