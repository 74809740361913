import { USER_RESOURCE } from '../api/resources';

export const indexUsers = params => (dispatch, getState, api) => api[USER_RESOURCE].index(params);

export const autocompleteUsers = params => (dispatch, getState, api) =>
    api[USER_RESOURCE].autocomplete(params);
export const passwordResetUser = params => (dispatch, getState, api) =>
    api[USER_RESOURCE].resetPassword(params);

export const storeUser = params => (dispatch, getState, api) => api[USER_RESOURCE].store(params);

export const updateUser = params => (dispatch, getState, api) => api[USER_RESOURCE].update(params);

export const suggestUsers = params => (dispatch, getState, api) =>
    api[USER_RESOURCE].suggest(params);
