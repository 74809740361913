import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Form from '../form/formik/Form';
import { cardSchema } from '../../modules/cards/schema';
import {
    storeCard as storeCardAction,
    updateCard as updateCardAction,
} from '../../modules/cards/actions';
import { selectAllCardStatusIds } from '../../modules/cardStatus/selectors';
import { IdPropType } from '../../modules/proptypes';
import CardTitleFormBody from './CardTitleFormBody';
import { indexContacts } from '../../modules/contacts/actions';
import { CARD_CONTEXTS } from '../../modules/contacts/contexts';
import { useSnackbar } from '../../modules/snackbar/hooks';
import { CARD_RESOURCE } from '../../modules/api/resources';
import { selectDatacontainerById } from '../../modules/datacontainers/selectors';
import { selectCardById } from '../../modules/cards/selectors';
import { IDENTIFIER_TYPES } from '../../modules/datacontainers/utils';
import { showDatacontainer } from '../../modules/datacontainers/actions';
import persistor from '../../modules/persistor/persistor';
import FormApiValidation from '../form/formik/FormApiValidation';
import { useArrangedDatacontainers } from '../../modules/datacontainers/hooks';
import { useApi } from '../../modules/api/ApiProvider';

const CardTitleForm = ({ cardId, cardStatusId, onSubmit, onDirty, can }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { kumoConnect } = useApi();
    const [error, setError] = useState(null);
    const fallbackId = useSelector(selectAllCardStatusIds)[0];
    const card = useSelector(state => selectCardById(state, cardId));
    const datacontainerId = card ? card.datacontainer_id : null;
    const datacontainer = useSelector(state => selectDatacontainerById(state, datacontainerId));

    const datacontainer2Id = card ? card.datacontainer_id2 : null;
    const datacontainer2 = useSelector(state => selectDatacontainerById(state, datacontainer2Id));

    useEffect(() => {
        if (datacontainerId && !datacontainer) {
            dispatch(showDatacontainer({ id: datacontainerId }));
        }
        if (datacontainer2Id && !datacontainer2) {
            dispatch(showDatacontainer({ id: datacontainer2Id }));
        }
    }, [datacontainer, datacontainerId, datacontainer2, datacontainer2Id, dispatch]);

    const customIdTypes = useArrangedDatacontainers(datacontainer, datacontainer2);

    const initialValues = useMemo(
        () => ({
            title: card ? card.title : '',
            card_status_id: card
                ? card.card_status_id
                : cardStatusId || (persistor.get('boardViewMode') === 'list' ? null : fallbackId),
            datacontainer:
                IDENTIFIER_TYPES.ORDER in customIdTypes
                    ? {
                          id: customIdTypes[IDENTIFIER_TYPES.ORDER].id,
                          value: customIdTypes[IDENTIFIER_TYPES.ORDER].id_custom,
                      }
                    : null,
            datacontainer2:
                IDENTIFIER_TYPES.OFFER in customIdTypes
                    ? {
                          id: customIdTypes[IDENTIFIER_TYPES.OFFER].id,
                          value: customIdTypes[IDENTIFIER_TYPES.OFFER].id_custom,
                      }
                    : null,
            id_order: card ? card.id_order : null,
            id_offer: card ? card.id_offer : null,
            responsibles: card ? card.responsibles : [],
            responsibles_main: card ? card.responsibles_main : [],
        }),
        [card, cardStatusId, fallbackId, customIdTypes]
    );

    const handleDatacontainers = values => {
        const preppedValues = {
            ...values,
            datacontainer_id: values.datacontainer ? values.datacontainer.id : null,
            datacontainer_id2: values.datacontainer2 ? values.datacontainer2.id : null,
        };

        if (preppedValues.datacontainer_id2 && !preppedValues.datacontainer_id) {
            preppedValues.datacontainer_id = preppedValues.datacontainer_id2;
            preppedValues.datacontainer_id2 = null;
        }

        return preppedValues;
    };

    const handleSubmit = useCallback(
        values => {
            const preppedValues = handleDatacontainers(values);

            return dispatch(
                !card
                    ? storeCardAction(preppedValues)
                    : updateCardAction({ id: card.id, ...preppedValues })
            )
                .then(async ({ data: newCard }) => {
                    if (card && card.datacontainer_id !== newCard.datacontainer_id) {
                        dispatch(
                            indexContacts({
                                limit: 250, // TODO: handle more than 250 contacts?
                                card_id: newCard.id,
                                contexts: CARD_CONTEXTS,
                            })
                        );
                    }
                    const kumoConnectStatus = await kumoConnect.getKumoConnectStatus();
                    if (kumoConnectStatus) kumoConnect.checkCardDir(newCard);
                    enqueueSnackbar(t('components.CardModal.onSaved'), {
                        variant: 'success',
                        onClick: () => {
                            closeSnackbar();
                        },
                    });

                    if (onSubmit) {
                        onSubmit(newCard);
                    }
                })
                .catch(err => setError(err));
        },
        [card, closeSnackbar, dispatch, enqueueSnackbar, onSubmit, t]
    );

    return (
        <Form
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={cardSchema}
            enableReinitialize
            onDirty={onDirty}
            subject={card || CARD_RESOURCE}
        >
            <FormApiValidation error={error} />
            <CardTitleFormBody can={can} card={card} onDirty={onDirty} />
        </Form>
    );
};

CardTitleForm.propTypes = {
    cardId: IdPropType,
    cardStatusId: IdPropType,
    onSubmit: PropTypes.func,
    onDirty: PropTypes.func.isRequired,
    can: PropTypes.string,
};

CardTitleForm.defaultProps = {
    cardId: null,
    cardStatusId: null,
    onSubmit: () => null,
    can: null,
};

export default memo(CardTitleForm);
