import ReactDOM from 'react-dom';
import { Provider as StoreProvider, useStore } from 'react-redux';
import ApiProvider, { useApi } from '../../modules/api/ApiProvider';
import React, { useCallback } from 'react';
import ThemeProvider from '../settings/ThemeProvider';

/*
    ToDo: Deprecated and removed ReactDOM.render(), use createRoot() instead:
        https://react.dev/blog/2024/04/25/react-19-upgrade-guide#removed-reactdom-render
*/

const usePrint = () => {
    const api = useApi();
    const store = useStore();

    const temporaryElement = document.createElement('div');

    return useCallback(
        (el, title) => {
            return new Promise(resolve => {
                ReactDOM.render(
                    <StoreProvider store={store}>
                        <ThemeProvider>
                            <ApiProvider api={api}>{el}</ApiProvider>
                        </ThemeProvider>
                    </StoreProvider>,
                    temporaryElement,
                    () => {
                        const printOutput = `<!DOCTYPE html>
                             <html> 
                                <head>
                                   <meta charset="UTF-8">
                                   <title>${title || 'Druckansicht'}</title>
                                   <style>
                                        html, body {
                                            font-family: Arial, sans-serif; 
                                            color-adjust: exact;
                                            -webkit-print-color-adjust:exact;
                                        }
                                        input {
                                            visibility: hidden;
                                        }
                                        table {
                                            width: 100%;
                                            border-collapse: collapse;
                                        }
                                        thead {
                                            background-color: #777;
                                            color: #fff;
                                            text-align: left;
                                            font-size: 14px;
                                        }
                                        thead th {
                                            border-left: 1px solid #666;
                                            border-right: 1px solid #666;
                                        }
                                        tbody tr:nth-child(odd) {
                                            background-color: #eee;
                                        }
                                        tbody tr:nth-child(odd) td {
                                            border-left: 1px solid #ddd;
                                            border-right: 1px solid #ddd;
                                        }
                                        tbody td {
                                            border-left: 1px solid #eee;
                                            border-right: 1px solid #eee;
                                        }
                                   </style>
                                 </head>
                                 <body>
                                    ${temporaryElement.innerHTML}
                                </body>
                            </html>`;

                        const ref = window.open('');

                        ref.document.write(printOutput);

                        setTimeout(() => {
                            ref.print();
                        }, 160);

                        temporaryElement.remove();

                        resolve(ref);
                    }
                );
            });
        },
        [api, store, temporaryElement]
    );
};

export default usePrint;
