import { GROUP_RESOURCE } from '../api/resources';
import { createResourceSlice } from '../redux/resource/createResourceSlice';

const groupsSlice = createResourceSlice({
    resource: GROUP_RESOURCE,
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,

    indexGroups,
    showGroup,
    storeGroup,
    updateGroup,
    archiveGroup,
    restoreGroup,
    destroyGroup,

    searchGroups,
    autocompleteGroups,
    suggestGroups,
} = groupsSlice.actions;

export const {
    selectGroupById,
    makeGroupsByIdsSelector,
    selectAllGroupIds,
    selectAllGroups,
    selectGroupsLoading,
    selectGroupsInitialized,
    selectGroupLoading,
    selectGroupInitialized,
    selectGroupObtainablePages,
    selectGroupCurrentPage,
} = groupsSlice.selectors;

export const { useGroups, useGroup } = groupsSlice.hooks;

export default groupsSlice.reducer;
