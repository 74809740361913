import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import Pagination from '../../pagination/Pagination';
import { selectListPage, selectListPageCount } from '../../../modules/lists/selectors';

const ResourcePagination = ({ listId, onChange, className }) => {
    const count = useSelector(state => selectListPageCount(state, listId));
    const page = useSelector(state => selectListPage(state, listId));

    return count > 1 ? (
        <Box className={className}>
            <Pagination count={count} onChange={onChange} page={page} />
        </Box>
    ) : null;
};

ResourcePagination.propTypes = {
    listId: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    className: PropTypes.string,
};

ResourcePagination.defaultProps = {
    onChange: null,
    className: null,
};

export default ResourcePagination;
