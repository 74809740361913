import React from 'react';
import * as PropTypes from 'prop-types';
import { EditingState } from '@devexpress/dx-react-scheduler';
import { useCalendar } from './CalendarContext';

const CalendarEditingState = ({ onSubmit, onEdit }) => {
    const { getAllValues } = useCalendar();

    const handleSubmit = ({ added, changed, deleted }) => {
        const hasExDateChanged = () => {
            // Fix appointment series duplication
            // when just moving/excluding one entity
            if (!changed) {
                return false;
            }
            const change = Object.values(changed)[0];
            return 'exDate' in change;
        };

        onSubmit({
            added: added && {
                ...(hasExDateChanged() || typeof getAllValues.current !== 'function'
                    ? {}
                    : getAllValues.current()),
                ...added,
            },
            changed,
            deleted,
        });
    };

    return (
        <EditingState
            onCommitChanges={handleSubmit}
            onEditingAppointmentChange={appointment => onEdit(appointment, false)}
            onAddedAppointmentChange={appointment => onEdit(appointment, true)}
        />
    );
};

CalendarEditingState.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onEdit: PropTypes.func,
};

CalendarEditingState.defaultProps = {
    onEdit: () => null,
};

export default CalendarEditingState;
