import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { selectCardById } from '../../modules/cards/selectors';
import { selectContactTypesByIds } from '../../modules/contactTypes/selectors';
import { cardPath } from '../../routes/paths';
import { showCard } from '../../modules/cards/actions';
import LoadingIcon from '../loading/LoadingIcon';
import { IdPropType } from '../../modules/proptypes';
import Link from '../utils/Link';
import CardAddress from '../card/CardAddress';
import CardIdentifierDisplay from '../card/CardIdentifierDisplay';

const useStyles = makeStyles(theme => ({
    shortType: {
        fontWeight: 'bold',
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        fontWeight: 'bold',
    },
}));

const LinkedCardsRow = ({ cardId, contactTypeIds }) => {
    const classes = useStyles();
    const card = useSelector(state => selectCardById(state, cardId));
    const contactTypes = useSelector(state => selectContactTypesByIds(state, contactTypeIds));
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!card && !loading) {
            setLoading(true);
            dispatch(showCard({ id: cardId })).then(() => setLoading(false));
        }
    }, [card, loading, cardId, dispatch]);

    return (
        <>
            <TableRow id={`link-${cardId}`}>
                <TableCell component="th" scope="row">
                    {contactTypes.map(contactType => (
                        <Typography key={contactType.id}>
                            <Typography className={classes.shortType} component="span">
                                {contactType && contactType.name_short}&nbsp;
                            </Typography>
                            {contactType && contactType.name}
                        </Typography>
                    ))}
                </TableCell>
                <TableCell component="th" scope="row">
                    <CardIdentifierDisplay cardId={card} />
                </TableCell>
                <TableCell component="th" scope="row">
                    {card && card.address && <CardAddress address={card.address} noIcon />}
                </TableCell>
                <TableCell component="th" scope="row">
                    <LoadingIcon isLoading={loading}>
                        {card && (
                            <Link
                                data-test-class="contact-card-link"
                                to={cardPath(cardId)}
                                className={classes.link}
                                variant="body1"
                                color="primary"
                                icon
                            >
                                {card.title}
                            </Link>
                        )}
                    </LoadingIcon>
                </TableCell>
            </TableRow>
        </>
    );
};

LinkedCardsRow.propTypes = {
    cardId: IdPropType.isRequired,
    contactTypeIds: PropTypes.arrayOf(IdPropType).isRequired,
};

export default LinkedCardsRow;
