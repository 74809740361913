import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectCardById } from '../../modules/cards/selectors';
import { selectDatacontainerById } from '../../modules/datacontainers/selectors';
import { useArrangedDatacontainers } from '../../modules/datacontainers/hooks';
import { IdPropType } from '../../modules/proptypes';
import CardIdentifierDatacontainers from './CardIdentifierDatacontainers';
import CardIdentifierSuggest from './CardIdentifierSuggest';
import { selectSettingsByKey } from '../../modules/settings/selectors';
import {
    IDENTIFIER_MODE_SETTINGS,
    IDENTIFIER_OFFER_SETTINGS,
    IDENTIFIER_ORDER_SETTINGS,
} from '../../modules/settings/config';
import { IDENTIFIER_TYPES } from '../../modules/datacontainers/utils';

const IDENTIFIER_MODES = {
    DATACONTAINER: 'datacontainer',
    SUGGEST: 'suggest',
};

const IDENTIFIER_SELECTORS = {
    [IDENTIFIER_MODES.DATACONTAINER]: CardIdentifierDatacontainers,
    [IDENTIFIER_MODES.SUGGEST]: CardIdentifierSuggest,
};

const CardIdentifier = ({ identifierType, cardId, variant, contrast, onChange, I }) => {
    const generalIdentifierMode = useSelector(state =>
        selectSettingsByKey(state, IDENTIFIER_MODE_SETTINGS)
    );

    const orderSettings = useSelector(state =>
        selectSettingsByKey(state, IDENTIFIER_ORDER_SETTINGS)
    );
    const offerSettings = useSelector(state =>
        selectSettingsByKey(state, IDENTIFIER_OFFER_SETTINGS)
    );

    const identifierMode = useMemo(() => {
        if (identifierType === IDENTIFIER_TYPES.ORDER && orderSettings?.mode) {
            return orderSettings.mode;
        }
        if (identifierType === IDENTIFIER_TYPES.OFFER && offerSettings?.mode) {
            return offerSettings.mode;
        }

        return generalIdentifierMode;
    }, [orderSettings, offerSettings]);

    const IdentifierSelector = IDENTIFIER_SELECTORS[identifierMode];

    const card = useSelector(state => selectCardById(state, cardId));

    const datacontainerId1 = card ? card.datacontainer_id : null;
    const datacontainerId2 = card ? card.datacontainer_id2 : null;
    const datacontainer1 = useSelector(state => selectDatacontainerById(state, datacontainerId1));
    const datacontainer2 = useSelector(state => selectDatacontainerById(state, datacontainerId2));

    const arrangedDatacontainers = useArrangedDatacontainers(datacontainer1, datacontainer2);
    const datacontainer = arrangedDatacontainers[identifierType];

    if (!IdentifierSelector) {
        return null;
    }

    return (
        <IdentifierSelector
            identifierType={identifierType}
            datacontainer={datacontainer}
            cardId={cardId}
            variant={variant}
            contrast={contrast}
            onChange={onChange}
            I={I}
        />
    );
};

CardIdentifier.propTypes = {
    identifierType: PropTypes.oneOf(Object.values(IDENTIFIER_MODES)).isRequired,
    cardId: IdPropType,
    contrast: PropTypes.bool,
    variant: PropTypes.string,
    onChange: PropTypes.func,
    I: PropTypes.string,
};

CardIdentifier.defaultProps = {
    cardId: null,
    contrast: false,
    variant: 'outlined',
    onChange: () => {},
    I: null,
};

export default CardIdentifier;
