import React from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import * as propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Form from '../form/formik/Form';
import TextInput from '../form/formik/TextInput';
import Button from '../form/base/Button';
import SubmitButton from '../form/formik/SubmitButton';
import { IdPropType } from '../../modules/proptypes';
import { selectGroupById } from '../../modules/groups/selectors';
import { groupSchema } from '../../modules/groups/schema';

const GroupForm = ({ id, input, onSubmit, onAbort, can, subject }) => {
    const { t } = useTranslation();
    const group = useSelector(state => selectGroupById(state, id));

    const initialValues = id
        ? group
        : {
              id: null,
              name: input,
          };

    return (
        <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={groupSchema}
            subject={subject}
        >
            <Grid container spacing={2} alignItems="center">
                <Grid item xs>
                    <TextInput
                        can={can}
                        size="small"
                        name="name"
                        label={t('components.Group.name')}
                    />
                </Grid>
                <Grid item>
                    <Button variant="outlined" size="small" onClick={onAbort}>
                        {t('abort')}
                    </Button>
                </Grid>
                <Grid item>
                    <SubmitButton
                        alwaysEnabled={input !== ''}
                        variant="contained"
                        color="primary"
                        size="small"
                        I={can}
                    />
                </Grid>
            </Grid>
        </Form>
    );
};

GroupForm.propTypes = {
    id: IdPropType,
    input: propTypes.string,
    onSubmit: propTypes.func,
    onAbort: propTypes.func,
    can: propTypes.string,
    subject: propTypes.string,
};

GroupForm.defaultProps = {
    id: null,
    input: '',
    onSubmit: null,
    onAbort: null,
    can: null,
    subject: null,
};

export default GroupForm;
