import { TAG_RESOURCE } from '../api/resources';
import { createResourceSlice } from '../redux/resource/createResourceSlice';

const tagSlice = createResourceSlice({
    resource: TAG_RESOURCE,
    byKey: '',
});
export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,
    indexTags,
    showTag,
    storeTag,
    updateTag,
    archiveTag,
    restoreTag,
    destroyTag,
    autocompleteTags,
} = tagSlice.actions;
export const {
    selectTagById,
    makeTagsByIdsSelector,
    selectAllTagIds,
    selectAllTags,
    selectTagsLoading,
    selectTagsInitialized,
    selectTagLoading,
    selectTagInitialized,
    selectTagIdsBy,
    makeTagsBySelector,
    selectTagLoadingBy,
    selectTagInitializedBy,
} = tagSlice.selectors;
export const { useTags, useTag, useTagsBy } = tagSlice.hooks;
export default tagSlice.reducer;
