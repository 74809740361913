import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Select from './Select';
import { IdPropType, ReferenceIdPropType } from '../../../modules/proptypes';
import { UPDATE } from '../../../modules/abilities/actions';
import { useTaskLoad } from '../../../modules/tasks/hooks';
import { useField } from 'formik';
import LoadingIcon from '../../loading/LoadingIcon';

const TaskSelect = ({ name, label, referenceId, fullWidth, onChange, nullable, I, ...other }) => {
    const { t } = useTranslation();
    const lastReferenceId = useRef(referenceId && Object.values(referenceId).join());
    const [{ value }, , { setValue }] = useField(name);

    const { tasks, loading } = useTaskLoad({
        referenceId,
        fetchParams: { limit: 100 },
        autoPaginate: true,
    });

    const options = useMemo(() => {
        const taskSelectOptions = tasks
            ? Object.values(tasks).map(task => ({
                  value: task.id,
                  label: task.name,
              }))
            : [];

        if (nullable) {
            taskSelectOptions.unshift({
                value: 'null',
                label: t('form.TaskSelect.empty'),
            });
        }

        taskSelectOptions.unshift({
            value: '',
            label: t('form.TaskSelect.select'),
        });

        return taskSelectOptions;
    }, [tasks, nullable, t]);

    useEffect(() => {
        const currentRefId =
            lastReferenceId.current && Object.values(lastReferenceId.current).join();
        const nextRefId = referenceId && Object.values(referenceId).join();

        if (nextRefId !== currentRefId) {
            if (value && lastReferenceId.current?.value) {
                setValue(null);
            }

            lastReferenceId.current = referenceId;
        }
    }, [referenceId, value]);

    return (
        <LoadingIcon isLoading={loading} size="small">
            <Select
                size="small"
                label={label || t('form.TaskSelect.label')}
                options={options}
                fullWidth={fullWidth}
                name={name}
                onChange={onChange}
                can={I}
                disabled={!referenceId?.value}
                {...other}
            />
        </LoadingIcon>
    );
};

TaskSelect.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    referenceId: ReferenceIdPropType,
    fullWidth: PropTypes.bool,
    onChange: PropTypes.func,
    nullable: PropTypes.bool,
    I: PropTypes.string,
};

TaskSelect.defaultProps = {
    label: null,
    referenceId: null,
    fullWidth: false,
    onChange: null,
    nullable: false,
    I: UPDATE,
};

export default TaskSelect;
