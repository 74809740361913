import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { AppointmentForm as SchedulerForm } from '@devexpress/dx-react-scheduler-material-ui';
import { useCalendar } from './CalendarContext';
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AppointmentForm from '../appointments/AppointmentForm';
import IconButton from '../form/base/IconButton';
import { useAppointmentCalendar } from './AppointmentCalendarContext';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { formatISO, parseISO, subSeconds } from 'date-fns';
import { EMPLOYEE_RESOURCE, RESOURCE_RESOURCE, TASK_RESOURCE } from '../../modules/api/resources';
import { CALENDAR_RESOURCE_MODELS, formatSwapFromFlat } from '../../modules/calendar/utils';
import { useAppointmentTypeByType } from '../../modules/appointmentTypes/appointmentTypeSlice';
import { useSelector } from 'react-redux';
import { selectAppointmentById } from '../../modules/appointments/appointmentSlice';
import { formatAppointmentImport } from '../../modules/appointments/utils';
import { markdownToPlainText } from '../../modules/markdown/utils';
import { formatTimeLong } from '../../modules/datetime/utils';

const useStyles = makeStyles(theme => ({
    toolbar: {
        width: '100%',
        padding: 6,
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
    },
    content: {
        height: '100%',
        overflow: 'hidden',
    },
    hiddenBox: {
        position: 'absolute',
        opacity: 0,
        pointerEvents: 'none',
    },
    loading: {
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

const APPOINTMENT_DATE_FIELDS = [
    'startDate',
    'endDate',
    'completedAt',
    'createdAt',
    'deletedAt',
    'plannedAt',
    'updatedAt',
];

const INITIAL_ENTITY_RESOURCES = [EMPLOYEE_RESOURCE, RESOURCE_RESOURCE];

const handleDates = (values, toString = false, isCreate = false) => {
    if (values) {
        return APPOINTMENT_DATE_FIELDS.reduce((carry, key) => {
            if (key in carry) {
                if (toString && carry[key] instanceof Date) {
                    const date = carry[key];
                    const optimizedDate =
                        key === 'endDate' && isCreate && formatTimeLong(date) === '00:00:00'
                            ? subSeconds(date, 1)
                            : date;
                    return {
                        ...carry,
                        [key]: formatISO(optimizedDate),
                    };
                }

                if (!toString && typeof carry[key] === 'string') {
                    return {
                        ...carry,
                        [key]: parseISO(carry[key]),
                    };
                }
            }

            return carry;
        }, values);
    }

    return values;
};

const handleId = values => {
    if ('originalId' in values) {
        return {
            ...values,
            id: values.originalId,
        };
    }

    return values;
};

const CalendarForm = ({ visible, fullSize: initialSize, target, onHide, children }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [fullSize, setFullSize] = useState(initialSize);
    const [initialResourceMeta, setInitialResourceMeta] = useState(null);
    const lastShowForm = useRef(visible);

    const { editingAppointment, isCreate } = useCalendar();
    const {
        overrideFetchKey,
        defaultEntityResource,
        defaultEntityId,
        forceCreateValues,
        handleSubmit,
        handleDelete,
    } = useAppointmentCalendar();
    const defaultAppointmentType = useAppointmentTypeByType('default');
    const originalAppointment = useSelector(state =>
        selectAppointmentById(state, editingAppointment?.originalId)
    );

    const cardId = useMemo(() => {
        if (defaultEntityResource === TASK_RESOURCE && overrideFetchKey === 'card_id') {
            return defaultEntityId;
        }

        return null;
    }, [defaultEntityResource, defaultEntityId, overrideFetchKey]);

    const initialValues = useMemo(() => {
        const finalAppointment = originalAppointment
            ? {
                  ...editingAppointment,
                  ...formatAppointmentImport(originalAppointment),
              }
            : editingAppointment;

        const optimizedAppointment = {
            ...handleDates(finalAppointment, true, isCreate),
            ...(finalAppointment?.task?.description
                ? {
                      task: {
                          ...finalAppointment.task,
                          description: markdownToPlainText(finalAppointment.task.description),
                      },
                  }
                : {}),
            form: true,
        };

        if (isCreate) {
            return {
                ...optimizedAppointment,
                entityId: '',
                entityType: '',
                appointmentTypeId: defaultAppointmentType?.id,
                grouped: {},
                ...(forceCreateValues || {}),
            };
        }

        return optimizedAppointment;
    }, [
        isCreate,
        editingAppointment,
        originalAppointment,
        defaultAppointmentType,
        forceCreateValues,
    ]);

    const formatSubmit = values => {
        setInitialResourceMeta(null);
        return handleSubmit(handleId(handleDates(values)));
    };

    const formatDelete = () => {
        return handleDelete({ id: initialValues.id });
    };

    useEffect(() => {
        if (editingAppointment) {
            const { flatResources } = editingAppointment;

            if (flatResources && Array.isArray(flatResources) && flatResources.length > 0) {
                const resourceMeta = formatSwapFromFlat(flatResources[0], true);

                if (resourceMeta && resourceMeta.id && resourceMeta.key) {
                    setInitialResourceMeta(resourceMeta);
                    return;
                }
            }
        }

        if (defaultEntityResource && INITIAL_ENTITY_RESOURCES.includes(defaultEntityResource)) {
            const entityResourceType = CALENDAR_RESOURCE_MODELS[defaultEntityResource];

            if (entityResourceType) {
                setInitialResourceMeta({
                    ...(defaultEntityId ? { id: defaultEntityId } : {}),
                    type: entityResourceType,
                    key: defaultEntityResource,
                });

                return;
            }
        }

        setInitialResourceMeta(null);
    }, [editingAppointment, defaultEntityResource, defaultEntityId]);

    const showForm = useMemo(() => {
        if (visible !== lastShowForm.current) {
            lastShowForm.current = visible;
        }

        return lastShowForm.current;
    }, [visible]);

    return (
        <>
            <SchedulerForm.Overlay
                visible={visible}
                target={target}
                onHide={onHide}
                fullSize={fullSize}
            >
                <Grid container direction="column" height="100%" wrap="nowrap">
                    <Grid item>
                        <Grid container className={classes.toolbar}>
                            <Grid item>
                                <Typography variant="h2" color="primary" ml={1}>
                                    {t('components.Appointment.singular')}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <IconButton onClick={onHide}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs className={classes.content}>
                        {showForm ? (
                            <AppointmentForm
                                initialValues={initialValues}
                                isCreate={isCreate}
                                fullHeight
                                cardId={cardId}
                                forceResource={initialResourceMeta}
                                onSubmit={formatSubmit}
                                onDelete={formatDelete}
                                onHide={onHide}
                                onResize={setFullSize}
                            />
                        ) : (
                            <Grid container className={classes.loading}>
                                <CircularProgress />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Box className={classes.hiddenBox}>{children}</Box>
            </SchedulerForm.Overlay>
        </>
    );
};

CalendarForm.propTypes = {};

export default memo(CalendarForm);
