import React from 'react';
import * as PropTypes from 'prop-types';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import { bindPopper, usePopupState } from 'material-ui-popup-state/hooks';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { TableActionPropType } from './proptypes';
import TableAction from './TableAction';
import ClickBoundary from '../form/ClickBoundary';
import MoreIconButton from '../buttons/MoreIconButton';

const useStyles = makeStyles(theme => ({
    hoverInner: {
        marginTop: 10,
        border: '1px solid #E6E6E6',
        boxShadow: '0 2px 10px 0 rgba(0,0,0,0.07)',
    },

    inlineAction: {
        display: 'inline-block',
        marginLeft: theme.spacing(1),
    },
}));

const TableInlineActions = ({ actions, data, showActionsInline, hidden }) => {
    const classes = useStyles();
    const popupState = usePopupState({
        variant: 'popper',
        popupId: 'TableInlineActions',
    });

    return (
        <Fade in={!hidden}>
            {/* needed by <Fade /> to work */}
            <Box>
                <ClickBoundary inline>
                    {actions.length <= showActionsInline ? (
                        actions.map(action => (
                            <Box key={action.key} className={classes.inlineAction}>
                                <TableAction action={action} data={data} compact />
                            </Box>
                        ))
                    ) : (
                        <>
                            <MoreIconButton size="small" onClick={popupState.open} />
                            <Popper {...bindPopper(popupState)}>
                                <Paper className={classes.hoverInner}>
                                    <ClickAwayListener onClickAway={popupState.close}>
                                        <Box m={2}>
                                            <Grid container spacing={1} direction="column">
                                                {actions.map(action => (
                                                    <Grid item key={action.key}>
                                                        <TableAction action={action} data={data} />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Box>
                                    </ClickAwayListener>
                                </Paper>
                            </Popper>
                        </>
                    )}
                </ClickBoundary>
            </Box>
        </Fade>
    );
};

TableInlineActions.propTypes = {
    actions: PropTypes.arrayOf(TableActionPropType),
    data: PropTypes.shape({}),
    showActionsInline: PropTypes.number.isRequired,
    hidden: PropTypes.bool,
};

TableInlineActions.defaultProps = {
    actions: [],
    data: null,
    hidden: false,
};

export default TableInlineActions;
