import PropTypes from 'prop-types';

export const CloudPropType = PropTypes.shape({
    directory: PropTypes.func.isRequired,
    stat: PropTypes.func.isRequired,
    download: PropTypes.func.isRequired,
    show: PropTypes.func.isRequired,
    getLocalPath: PropTypes.func.isRequired,
    upload: PropTypes.func.isRequired,
    bulkUpload: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    bulkDestroy: PropTypes.func.isRequired,
});

export const ApiPropType = PropTypes.shape({
    onAuthenticate: PropTypes.func.isRequired,
    authenticate: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
});
