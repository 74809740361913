import React from 'react';
import * as PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    root: {
        width: '100%',
        height: '100%',
        minHeight: 84,
    },
});

const Empty = ({ label, title, children }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            className={classes.root}
        >
            {title ? (
                <Grid item>
                    <Typography color="textSecondary" variant="h2">
                        {title}
                    </Typography>
                </Grid>
            ) : null}
            <Grid item>
                <Typography color="textSecondary" variant="body2">
                    {label || t('components.List.empty')}
                </Typography>
            </Grid>
            {children ? <Grid item>{children}</Grid> : null}
        </Grid>
    );
};

Empty.propTypes = {
    label: PropTypes.string,
    title: PropTypes.string,
};

Empty.defaultProps = {
    label: null,
    title: null,
};

export default Empty;
