import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import Grid from '@mui/material/Grid';
import classNames from 'classnames';
import Button from '../form/base/Button';
import { ASSIGNED } from '../../modules/responsibles/types';
import { ButtonBase } from '@mui/material';

const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: theme.palette.background.paper,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(1),
    },

    grid: {
        width: '100%',
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(1),
        cursor: 'pointer',
    },

    confirm: {
        backgroundColor: theme.palette.background.error,
        cursor: 'default',
    },

    buttons: {
        paddingTop: 4,
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },

    icon: {
        color: theme.palette.error.main,
        display: 'flex',
    },

    label: {
        color: theme.palette.error.main,
        lineHeight: 'normal',
        fontWeight: 'bold',
    },

    yes: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.error.main,

        '&:hover': {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.error.dark,
        },
    },
}));

const AvatarDestroyIntent = ({ onConfirmed, onConfirm, label, confirmLabel, Icon, type }) => {
    const classes = useStyles();
    const [confirm, setConfirm] = useState(false);
    const { t } = useTranslation();

    return (
        <Box
            className={classNames({
                [classes.confirm]: confirm,
            })}
        >
            <ButtonBase
                disableRipple={confirm}
                onClick={() => {
                    setConfirm(true);
                    if (onConfirm) {
                        onConfirm(true);
                    }
                }}
                className={classes.grid}
            >
                <Grid container spacing={1} alignItems="center">
                    <Grid item>
                        {Icon ? (
                            <Icon fontSize="small" className={classes.icon} />
                        ) : (
                            <DeleteForeverOutlinedIcon fontSize="small" className={classes.icon} />
                        )}
                    </Grid>
                    <Grid item xs>
                        <Typography variant="subtitle2" className={classes.label}>
                            {confirm
                                ? confirmLabel || t('components.Avatar.removeReally')
                                : label || t(`components.Avatar.${type}.remove`)}
                        </Typography>
                    </Grid>
                </Grid>
            </ButtonBase>
            {confirm && (
                <Grid
                    container
                    spacing={1}
                    justifyContent="space-between"
                    className={classes.buttons}
                >
                    <Grid item>
                        <Button
                            size="tiny"
                            variant="contained"
                            onClick={() => {
                                setConfirm(false);
                                if (onConfirm) {
                                    onConfirm(false);
                                }
                            }}
                            contrast
                        >
                            {t('no')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            size="tiny"
                            color="danger"
                            variant="contained"
                            onClick={onConfirmed}
                            contrast
                        >
                            {t('yes')}
                        </Button>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
};

AvatarDestroyIntent.propTypes = {
    onConfirmed: PropTypes.func.isRequired,
    onConfirm: PropTypes.func,
    label: PropTypes.string,
    confirmLabel: PropTypes.string,
    icon: PropTypes.elementType,
    type: PropTypes.string,
};

AvatarDestroyIntent.defaultProps = {
    onConfirm: undefined,
    type: ASSIGNED,
    label: null,
    confirmLabel: null,
    icon: null,
};

export default AvatarDestroyIntent;
