import { CARD_STATUS_RESOURCE } from '../api/resources';

export const selectCardStatusIsLoading = state => state[CARD_STATUS_RESOURCE].loading;

export const selectCardStatusError = state => state[CARD_STATUS_RESOURCE].error;

export const selectCardStatusById = state => state[CARD_STATUS_RESOURCE].byId;

export const selectAllCardStatusIds = state => state[CARD_STATUS_RESOURCE].allIds;

export const selectCardStateById = (state, id) => selectCardStatusById(state)[id];
