import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import IconButton from '../form/base/IconButton';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import usePrint from '../utils/usePrint';
import { READ } from '../../modules/abilities/actions';
import { SubjectPropType } from '../../modules/abilities/proptypes';
import LoadingIcon from '../loading/LoadingIcon';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

const PrintIconButton = ({ subject, onPrepare, onPrinted, children, label, title, ...other }) => {
    const { t } = useTranslation();
    const showPrint = usePrint();
    const [loading, setLoading] = useState(false);

    const finalizePrint = () => {
        setLoading(false);
    };

    const startPrint = () => {
        showPrint(children, title).then(finalizePrint);
    };

    const handlePrint = () => {
        setLoading(true);

        if (onPrepare) {
            onPrepare(startPrint);
        } else {
            startPrint();
        }
    };

    return (
        <Box style={{ position: 'relative' }}>
            <LoadingIcon isLoading={loading} size="small">
                <IconButton
                    onClick={handlePrint}
                    action={READ}
                    {...other}
                    label={label || t('components.Print.label')}
                >
                    <PrintOutlinedIcon />
                </IconButton>
            </LoadingIcon>
        </Box>
    );
};

PrintIconButton.propTypes = {
    subject: SubjectPropType,
    onPrepare: PropTypes.func,
    onPrinted: PropTypes.func,
    label: PropTypes.string,
    title: PropTypes.string,
};

PrintIconButton.defaultProps = {
    subject: null,
    onPrepare: null,
    onPrinted: null,
    label: null,
    title: null,
};

export default PrintIconButton;
