import React, { useEffect, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { Grid, Icon } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { grey } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import HelpIcon from '@mui/icons-material/Help';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { debounce } from 'lodash/function';

import { EMPLOYEE_RESOURCE, RESOURCE_RESOURCE } from '../../modules/api/resources';
import { selectEmployeeById, showEmployee } from '../../modules/employees/employeesSlice';
import { selectResourceById, showResource } from '../../modules/resources/resourcesSlice';
import { formatEmployee, formatResource, RESOURCE_MODELS } from '../../modules/resourceGroup/utils';
import { RESOURCES_FALLBACK_ICON } from '../../modules/resources/utils';
import BaseAvatar from '../icons/BaseAvatar';
import { getColor } from '../../modules/icons/avatars';

/* Todo:: maybe pass PSEUDO_CONFIGS as EXTRA_CONFIGS as props instead of importing components from 'calender' and remove these imports

import { FLAT_UNPLANNED } from '../../modules/calendar/utils';
import CalendarResourceGroupIcon from '../calendar/detail/CalendarResourceGroupIcon'; 
*/

const useStyles = makeStyles(theme => ({
    extraItem: {
        padding: 4,
        fontSize: 12,
        textAlign: 'center',
    },
}));

/* const PSEUDO_CONFIGS = {
    UNPLANNED: FLAT_UNPLANNED.key,
    EMPTY: 'empty',
}; */

const DETAIL_RESOURCE_CONFIG = {
    [EMPLOYEE_RESOURCE]: {
        format: formatEmployee,
        selector: selectEmployeeById,
        load: showEmployee,
        type: EMPLOYEE_RESOURCE,
        model: RESOURCE_MODELS[EMPLOYEE_RESOURCE],
    },
    [RESOURCE_RESOURCE]: {
        format: formatResource,
        selector: selectResourceById,
        load: showResource,
        type: RESOURCE_RESOURCE,
        model: RESOURCE_MODELS[RESOURCE_RESOURCE],
        fallbackIcon: RESOURCES_FALLBACK_ICON,
    },
    /* Todo:: maybe pass PSEUDO_CONFIGS as EXTRA_CONFIGS as props instead of importing components from 'calender'
            and integrate them dynamically here in DETAIL_RESOURCE_CONFIG

    [PSEUDO_CONFIGS.UNPLANNED]: {
        format: (_, t) => ({
            key: PSEUDO_CONFIGS.UNPLANNED,
            text: t('components.PlanningCalendar.unplanned'),
            icon: <HelpIcon />,
            color: getColor(PSEUDO_CONFIGS.UNPLANNED),
        }),
        selector: () => null,
        type: PSEUDO_CONFIGS.UNPLANNED,
    },
    [PSEUDO_CONFIGS.EMPTY]: {
        format: (_, t, groupConfig, tiny) => {
            return {
                key: PSEUDO_CONFIGS.EMPTY,
                text: t('components.PlanningCalendar.empty'),
                icon: <CalendarResourceGroupIcon groupConfig={groupConfig} tiny={tiny} />,
                color: grey[300],
            };
        },
        selector: () => null,
        type: PSEUDO_CONFIGS.EMPTY,
    }, */
};

const ResourceGroupConfigItem = ({
    flatKey,
    groupConfig,
    tiny,
    style,
    autoLoad,
    onRemove,
    ...other
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();

    const { id, format, load, selector, type, model, fallbackIcon } = useMemo(() => {
        /* Todo:: maybe pass PSEUDO_CONFIGS as EXTRA_CONFIGS as props instead of importing components from 'calender'
            and integrate them dynamically in DETAIL_RESOURCE_CONFIG and use it here
        
        if (Object.values(PSEUDO_CONFIGS).includes(flatKey)) {
            return {
                id: flatKey,
                ...DETAIL_RESOURCE_CONFIG[flatKey],
            };
        } */

        const [type, id] = flatKey.split('.');

        if (type in DETAIL_RESOURCE_CONFIG) {
            return {
                id,
                ...DETAIL_RESOURCE_CONFIG[type],
            };
        }

        return {
            id: flatKey,
            format: null,
            selector: () => null,
        };
    }, [flatKey]);

    const fullResource = useSelector(state => selector(state, id));

    const { key, text, short, icon, avatar, color } = useMemo(() => {
        if (format) {
            return format(fullResource, t, groupConfig, tiny) || {};
        }

        return {
            key: 'loading',
            icon: 'downloading',
            color: grey,
        };
    }, [fullResource, groupConfig, tiny, format, t]);

    const hoverExtra = useMemo(() => {
        return (
            <>
                {groupConfig?.label ? (
                    <Box className={classes.extraItem}>
                        <Typography>{groupConfig.label}</Typography>
                    </Box>
                ) : null}
            </>
        );
    }, [groupConfig]);

    const groupProps = useMemo(() => {
        if (typeof groupConfig?.avatarProps === 'object') {
            return groupConfig.avatarProps;
        }
        return {};
    }, [groupConfig]);

    const content = useMemo(() => {
        const finalIcon = icon || fallbackIcon;

        if (finalIcon) {
            if (tiny) {
                return <Icon style={{ fontSize: 12 }}>{finalIcon}</Icon>;
            }

            return <Icon>{finalIcon}</Icon>;
        }

        return short;
    }, [fallbackIcon, icon, short]);

    const debouncedLoad = useMemo(
        () =>
            debounce(id => {
                if (!fullResource && load) {
                    dispatch(load({ id }));
                }
            }, 60),
        [fullResource, load]
    );

    useEffect(() => {
        if (autoLoad && !fullResource) {
            debouncedLoad(id);
        }
    }, [id, fullResource, debouncedLoad]);

    return (
        <Grid item style={style} key={key}>
            <BaseAvatar
                {...other}
                displayName={text}
                shortName={content}
                hoverExtra={hoverExtra}
                path={avatar}
                color={color}
                outlined={type === RESOURCE_RESOURCE}
                style={type === RESOURCE_RESOURCE ? { backgroundColor: 'white' } : null}
                tiny={tiny}
                onDelete={onRemove}
                appointmentAvailabilityProps={{
                    resourceModel: model,
                    resourceId: id,
                    componentProps: {
                        style: {
                            fontSize: '20px',
                            marginTop: '22px',
                            marginLeft: '-2px',
                        },
                    },
                }}
                {...groupProps}
            />
        </Grid>
    );
};

ResourceGroupConfigItem.propTypes = {
    flatKey: PropTypes.string.isRequired,
    groupConfig: PropTypes.shape({}),
    tiny: PropTypes.bool,
    style: PropTypes.shape({}),
    autoLoad: PropTypes.bool,
    onRemove: PropTypes.func,
};

ResourceGroupConfigItem.defaultProps = {
    groupConfig: null,
    tiny: false,
    style: {},
    autoLoad: false,
    onRemove: null,
};

export default ResourceGroupConfigItem;
