import React from 'react';
import PropTypes from 'prop-types';
import { SnackbarProvider as NotistackSnackbarProvider } from 'notistack';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    containerAnchorOriginBottomLeft: {
        marginBottom: 48, // above bottom navigation
    },

    variantSuccess: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.primary.contrastText,
    },
    variantError: {
        backgroundColor: theme.palette.error.dark,
        color: theme.palette.error.contrastText,
    },
    variantWarning: {
        backgroundColor: theme.palette.error.dark,
        color: theme.palette.error.contrastText,
    },
    variantInfo: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
    },
}));

const SnackbarProvider = ({ children }) => {
    const classes = useStyles();

    return (
        <NotistackSnackbarProvider maxSnack={2} classes={classes}>
            {children}
        </NotistackSnackbarProvider>
    );
};

SnackbarProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default SnackbarProvider;
