import * as Yup from 'yup';

export const resourceSchema = Yup.object().shape({
    name: Yup.string().trim().required('required').max(255, 'string255MaxLength'),
    identifier: Yup.string().nullable(),
    short_name: Yup.string().nullable(),
    inventory_no: Yup.string().nullable(),
    color: Yup.string().nullable(),
    icon: Yup.string().nullable(),
    resource_class_id: Yup.number().required('required'),
    resource_type_id: Yup.number().required('required'),
});
