import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import Select from '../form/formik/Select';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { PERSON_FUNCTION_RESOURCE } from '../../modules/api/resources';
import { useCan } from '../../modules/abilities/hooks';
import { UPDATE } from '../../modules/abilities/actions';
import { selectAllPersonFunctions } from '../../modules/personFunction/selectors';
import { indexPersonFunctions } from '../../modules/personFunction/actions';
import PersonFunctionForm from './PersonFunctionForm';

const PersonFunctionSelect = ({ name, label, ...other }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    /* for admin-panel settings */
    const [selectedAdminEditItemId, setSelectedAdminEditItemId] = useState(null);
    const [selectedAdminEditItemLoading, setSelectedAdminEditItemLoading] = useState(false);
    const canAdminEdit = useCan(UPDATE, PERSON_FUNCTION_RESOURCE);
    const adminFormPopupRef = useRef();

    const personFunctions = useSelector(selectAllPersonFunctions);

    const options = useMemo(
        () =>
            personFunctions.map(({ id, name }) => ({
                label: name,
                value: id,
                onEdit: () => setSelectedAdminEditItemId(id),
            })),
        [personFunctions]
    );

    useEffect(() => {
        dispatch(indexPersonFunctions());
    }, [dispatch]);

    return (
        <Select
            name={name}
            options={options}
            label={label || t('components.ContactBaseData.function')}
            ItemEditFormPopupProps={{
                component: (
                    <PersonFunctionForm
                        personFunctionId={selectedAdminEditItemId}
                        asPopup
                        popupStateRef={adminFormPopupRef}
                        onLoading={loading => setSelectedAdminEditItemLoading(loading)}
                    />
                ),
                canEdit: canAdminEdit,
                selectedEditItemValue: selectedAdminEditItemId,
                isLoading: selectedAdminEditItemLoading,
            }}
            {...other}
        />
    );
};

PersonFunctionSelect.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

PersonFunctionSelect.defaultProps = {
    label: null,
};

export default PersonFunctionSelect;
