import { useCallback, useEffect, useState } from 'react';

export const useLoading = (onClick, isLoading = null) => {
    const [loading, setLoading] = useState(isLoading || false);

    useEffect(() => {
        if (isLoading !== null) {
            setLoading(isLoading);
        }
    }, [isLoading, setLoading]);

    const handleClick = useCallback(
        (event, ...params) => {
            if (loading) {
                event.preventDefault();
            } else if (onClick) {
                if (isLoading === null) {
                    setLoading(true);
                }

                Promise.resolve(onClick(event, ...params)).finally(() => {
                    if (isLoading === null) {
                        setLoading(false);
                    }
                });
            }
        },
        [loading, isLoading, setLoading, onClick]
    );

    return {
        handleClick,
        loading: isLoading || loading,
    };
};
