import React from 'react';
import Text from './Text';
import {
    LOCATION_COMPONENT,
    LOCATION_SUGGESTION_COMPONENT,
    MAP_COMPONENT,
    PERSONS_FILTERED_BY_TYPE_COMPONENT,
    PERSONS_FILTERED_BY_TYPE_SIMPLE_COMPONENT,
    TEXT_COMPONENT,
    TEXT_WITH_LABEL_COMPONENT,
    TITLE_COMPONENT,
} from '../../modules/metadata/components';
import TextWithLabel from './TextWithLabel';
import { MetadataPropType } from '../../modules/metadata/proptypes';
import LocationMap from './LocationMap';
import Location from './Location';
import PersonsFilteredByType from './PersonsFilteredByType';
import Title from './Title';
import LocationSuggestion from './LocationSuggestion';

const mapping = {
    [TEXT_COMPONENT]: Text,
    [TEXT_WITH_LABEL_COMPONENT]: TextWithLabel,
    [TITLE_COMPONENT]: Title,
    [PERSONS_FILTERED_BY_TYPE_COMPONENT]: PersonsFilteredByType,
    [PERSONS_FILTERED_BY_TYPE_SIMPLE_COMPONENT]: PersonsFilteredByType,
    [LOCATION_COMPONENT]: Location,
    [MAP_COMPONENT]: LocationMap,
    [LOCATION_SUGGESTION_COMPONENT]: LocationSuggestion,
};

const Metadata = ({ metadata: { component, value, name, itemId, resource }, ...other }) => {
    const Component = mapping[component];

    if (!Component) {
        throw Error(`${component} not found!`);
    }

    return <Component value={value} name={name} itemId={itemId} resource={resource} {...other} />;
};

Metadata.propTypes = {
    metadata: MetadataPropType.isRequired,
};

export default Metadata;
