import Grid from '@mui/material/Grid';
import classNames from 'classnames';
import { Icon } from '@mui/material';
import * as PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@mui/styles';
import Button from './Button';

const useStyles = makeStyles(theme => ({
    button: {
        height: '100%',
        width: '100%',
        fontSize: theme.spacing(1),
        wordBreak: 'break-word',
    },
    buttonSelected: {
        backgroundColor: theme.palette.grey[300],
    },
}));

const IconSelectButton = ({ value, label, selected, onClick }) => {
    const classes = useStyles();

    return (
        <Button
            onClick={() => onClick(value)}
            className={classNames({
                [classes.button]: true,
                [classes.buttonSelected]: selected,
            })}
            color="inherit"
        >
            <Grid container>
                <Grid item xs={12}>
                    <Icon>{value}</Icon>
                </Grid>
                <Grid item xs={12}>
                    {label}
                </Grid>
            </Grid>
        </Button>
    );
};

IconSelectButton.propTypes = {
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    selected: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
};

IconSelectButton.defaultProps = {
    selected: false,
};

export default IconSelectButton;
