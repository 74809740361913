import React from 'react';
import * as PropTypes from 'prop-types';
import { MenuItem as BaseMenuItem, ListItemIcon, ListItemText } from '@mui/material';

const MenuItem = ({ label, icon, labelProps, onClick }) => {
    return (
        <BaseMenuItem onClick={onClick}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primaryTypographyProps={labelProps}>{label}</ListItemText>
        </BaseMenuItem>
    );
};

MenuItem.propTypes = {
    label: PropTypes.string.isRequired,
    icon: PropTypes.element.isRequired,
    labelProps: PropTypes.shape({}),
    onClick: PropTypes.func,
};

MenuItem.defaultProps = {
    labelProps: {},
    onClick: null,
};

export default MenuItem;
