import React from 'react';
import * as PropTypes from 'prop-types';
import Dialog from '../Dialog';
import { useDialogControl } from '../DialogControlContext';
import { DialogTitle, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIconButton from '../../buttons/EditIconButton';
import DeleteIconButton from '../../buttons/DeleteIconButton';
import classNames from 'classnames';
import IconButton from '../../form/base/IconButton';

export const SLIM_DIALOG_WIDTH = {
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
    FULL: '',
};

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        width: '100%',
        maxHeight: 'calc(100% - 92px)',
    },

    [SLIM_DIALOG_WIDTH.SMALL]: {
        maxWidth: theme.spacing(64),
    },

    [SLIM_DIALOG_WIDTH.MEDIUM]: {
        maxWidth: theme.spacing(86),
    },

    [SLIM_DIALOG_WIDTH.LARGE]: {
        width: 'calc(100% - 120px)',
        maxWidth: 1400,
    },

    [SLIM_DIALOG_WIDTH.FULL]: {
        maxWidth: 'calc(100% - 64px)',
    },

    header: {
        height: 52,
        padding: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },

    headerContainer: {
        maxWidth: 'calc(100% - 36px)',
        overflow: 'hidden',
    },

    action: {
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },

    title: {
        display: 'inline-block',
        width: '100%',
        height: 32,
        lineHeight: '32px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },

    container: {
        height: 'calc(100% - 52px)',
        overflowX: 'hidden',
        overflowY: 'auto',
    },
}));

const SlimDialog = ({
    title,
    loading,
    width,
    'data-test-id': testId,
    subject,
    actions,
    open,
    deleteBadge,
    onEdit,
    onAbort,
    onDelete,
    children,
    ...other
}) => {
    const classes = useStyles();
    const { onClose, onExited, confirmClose } = useDialogControl();

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog
            data-test-id={testId}
            TransitionProps={{
                onExited,
            }}
            classes={{
                paper: classNames({
                    [classes.root]: true,
                    [classes[width]]: true,
                }),
            }}
            onClose={handleClose}
            disableBackdropClick={confirmClose}
            disableEscapeKeyDown={confirmClose}
            {...other}
        >
            <DialogTitle className={classes.header}>
                {loading ? null : (
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        wrap="nowrap"
                        spacing={1}
                        className={classes.headerContainer}
                    >
                        <Grid item flexShrink={1} flexGrow={1} overflow="hidden">
                            <Typography variant="h2" component="div" className={classes.title}>
                                {title}
                            </Typography>
                        </Grid>
                        {Array.isArray(actions) && actions.length
                            ? actions.map(action => (
                                  <Grid item height={48}>
                                      <Box className={classes.action}>{action}</Box>
                                  </Grid>
                              ))
                            : null}
                        {onDelete ? (
                            <Grid item height={48}>
                                <DeleteIconButton
                                    onClick={onDelete}
                                    confirmBadge={deleteBadge}
                                    size="small"
                                />
                            </Grid>
                        ) : null}
                        {onEdit ? (
                            <Grid item height={48}>
                                <EditIconButton onClick={onEdit} size="small" />
                            </Grid>
                        ) : null}
                        {onAbort && !confirmClose ? (
                            <Grid item height={48}>
                                <IconButton onClick={onAbort} size="small">
                                    <ArrowBackIcon />
                                </IconButton>
                            </Grid>
                        ) : null}
                    </Grid>
                )}
            </DialogTitle>
            <Box className={classes.container}>{children}</Box>
        </Dialog>
    );
};

SlimDialog.propTypes = {
    title: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    width: PropTypes.oneOf(Object.values(SLIM_DIALOG_WIDTH)),
    'data-test-id': PropTypes.string,
    subject: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
    actions: PropTypes.arrayOf([PropTypes.element]),
    open: PropTypes.bool,
    onEdit: PropTypes.func,
    onAbort: PropTypes.func,
    onClose: PropTypes.func,
    onDelete: PropTypes.func,
};

SlimDialog.defaultProps = {
    loading: false,
    width: SLIM_DIALOG_WIDTH.MEDIUM,
    'data-test-id': null,
    subject: null,
    actions: null,
    open: false,
    onEdit: null,
    onAbort: null,
    onClose: null,
    onDelete: null,
};

export default SlimDialog;
