import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import { useContextualCanWrite } from '../../../modules/abilities/hooks';
import ContextualCan from '../../../modules/abilities/ContextualCan';
import { READ } from '../../../modules/abilities/actions';

const BaseToggleButton = ({
    value,
    name,
    icon,
    iconToggled,
    size,
    onChange,
    preventToggle,
    disabled,
    I,
}) => {
    const canWrite = useContextualCanWrite(name);
    const [toggled, setToggled] = React.useState(value);

    useEffect(() => {
        setToggled(value);
    }, [value]);

    return (
        <ContextualCan I={READ} field={name}>
            <IconButton
                onClick={event => {
                    if (!preventToggle) {
                        setToggled(!toggled);
                    }

                    if (onChange) {
                        onChange(!toggled, event);
                    }
                }}
                size={size}
                disabled={disabled || !canWrite}
            >
                {toggled ? iconToggled : icon}
            </IconButton>
        </ContextualCan>
    );
};

BaseToggleButton.propTypes = {
    value: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    iconToggled: PropTypes.node.isRequired,
    onChange: PropTypes.func.isRequired,
    size: PropTypes.string,
    preventToggle: PropTypes.bool,
    disabled: PropTypes.bool,
    I: PropTypes.string,
};

BaseToggleButton.defaultProps = {
    size: 'medium',
    preventToggle: false,
    disabled: false,
    I: null,
};

export default BaseToggleButton;
