/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { CONTACTABLE_RESOURCE } from '../api/resources';

const affirmList = (state, id) => {
    if (!state.byCardId[id]) {
        state.byCardId[id] = [];
    }
};

const insertByContactId = (state, contactable) => {
    if (!state.byContactId[contactable.contact_id]) {
        state.byContactId[contactable.contact_id] = [contactable.id];
    } else if (!state.byContactId[contactable.contact_id].includes(contactable.id)) {
        state.byContactId[contactable.contact_id].push(contactable.id);
    }
};

const insertContactable = (state, contactable) => {
    insertByContactId(state, contactable);

    contactable.card_ids.forEach(cardId => affirmList(state, cardId));

    const stale = state.byId[contactable.id];

    if (!stale) {
        state.allIds.push(contactable.id);
        contactable.card_ids.forEach(cardId => state.byCardId[cardId].push(contactable.id));
    } else {
        Object.entries(state.byCardId).forEach(([cardId, ids]) => {
            const index = ids.findIndex(id => id === contactable.id);
            if (contactable.card_ids.includes(parseInt(cardId, 10))) {
                if (index < 0) {
                    ids.push(contactable.id);
                }
            } else if (index >= 0) {
                ids.splice(index, 1);
            }
        });
    }

    state.byId[contactable.id] = contactable;
};

const contactablesSlice = createSlice({
    name: CONTACTABLE_RESOURCE,
    initialState: {
        byId: {},
        byCardId: {},
        byContactId: {},
        allIds: [],
    },
    reducers: {
        indexFulfilled: {
            prepare: ({ payload, meta }) => ({ payload, meta }),
            reducer: (state, action) => {
                // falls index mit card_id, card-contactables erst leeren,
                // damit entfernte Kontakte auch im Frontend entfernt werden
                if (action.meta && action.meta.params && action.meta.params.card_id) {
                    state.byCardId[action.meta.params.card_id] = [];
                }

                action.payload.forEach(contactable => {
                    insertContactable(state, contactable);
                });
            },
        },

        showFulfilled: (state, action) => {
            insertContactable(state, action.payload);
        },

        storeFulfilled: (state, action) => {
            insertContactable(state, action.payload);
        },

        updateFulfilled: (state, action) => {
            insertContactable(state, action.payload);
        },

        multiupdateFulfilled: (state, action) => {
            // Remove contactables by contact_id and card_id
            if (action.meta && action.meta.params && action.meta.params.contactable_id) {
                state.byCardId[action.meta.params.contactable_id].forEach((value, index) => {
                    const contactable = state.byId[value];

                    if (
                        action.meta.params.contact_id === contactable.contact_id &&
                        contactable.card_ids.includes(action.meta.params.contactable_id) &&
                        contactable.synced === false
                    ) {
                        delete state.byId[value];
                        delete state.byCardId[action.meta.params.contactable_id][index];
                    }
                });
                delete state.byContactId[action.meta.params.contact_id];
            }

            action.payload.forEach(contactable => {
                insertContactable(state, contactable);
            });
        },

        destroyFulfilled: (state, action) => {
            const index = state.allIds.findIndex(id => id === action.payload);
            if (index >= 0) {
                delete state.byId[action.payload];
                state.allIds.splice(index, 1);

                Object.values(state.byCardId).forEach(ids => {
                    const byCardIndex = ids.findIndex(id => id === action.payload);
                    if (byCardIndex >= 0) {
                        ids.splice(byCardIndex, 1);
                    }
                });
            }
        },
    },
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,
} = contactablesSlice.actions;

export default contactablesSlice.reducer;
