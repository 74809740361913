import { pluralize } from './utils';

const extraActions = (name, resource) => ({
    [`index${pluralize(name)}`]: (params, meta, sequentialId = null) => (dispatch, getState, api) =>
        api[resource].index(params, meta, sequentialId),

    [`show${name}`]: (params, meta, sequentialId = null) => (dispatch, getState, api) =>
        api[resource].show(params, meta, sequentialId),

    [`store${name}`]: (params, meta, sequentialId = null) => (dispatch, getState, api) =>
        api[resource].store(params, meta, sequentialId),

    [`update${name}`]: (params, meta, sequentialId = null) => (dispatch, getState, api) =>
        api[resource].update(params, meta, sequentialId),

    [`archive${name}`]: (params, meta, sequentialId = null) => (dispatch, getState, api) =>
        api[resource].destroy({ ...params, forceDelete: false }, meta, sequentialId),

    [`archiveAll${pluralize(name)}`]: (params, meta, sequentialId = null) => (
        dispatch,
        getState,
        api
    ) => api[resource].destroyAll({ ...params, forceDelete: false }, meta, sequentialId),

    [`restore${name}`]: (params, meta, sequentialId = null) => (dispatch, getState, api) =>
        api[resource].restore(params, meta, sequentialId),

    [`destroy${name}`]: (params, meta, sequentialId = null) => (dispatch, getState, api) =>
        api[resource].destroy({ ...params, forceDelete: true }, meta, sequentialId),

    [`destroyAll${pluralize(name)}`]: (params, meta, sequentialId = null) => (
        dispatch,
        getState,
        api
    ) => api[resource].destroyAll({ ...params, forceDelete: true }, meta, sequentialId),

    [`search${pluralize(name)}`]: (params, meta, sequentialId = null) => (
        dispatch,
        getState,
        api
    ) => api[resource].search(params, meta, sequentialId),

    [`autocomplete${pluralize(name)}`]: (params, meta, sequentialId = null) => (
        dispatch,
        getState,
        api
    ) => api[resource].autocomplete(params, meta, sequentialId),

    [`suggest${pluralize(name)}`]: (params, meta, sequentialId = null) => (
        dispatch,
        getState,
        api
    ) => api[resource].suggest(params, meta, sequentialId),

    [`read${pluralize(name)}`]: (params, meta, sequentialId = null) => (dispatch, getState, api) =>
        api[resource].read(params, meta, sequentialId),
});

export default extraActions;
