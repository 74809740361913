import React from 'react';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TotalSum from './TotalSum';
import { useFormikContext } from 'formik';
import { makeStyles } from '@mui/styles';
import ScheduleRow from './ScheduleRow';

const useStyles = makeStyles(theme => ({
    container: {
        overflowX: 'unset',
    },
}));

export default function ScheduleTable({ name, days, shifts }) {
    const classes = useStyles();
    const { values, setFieldValue } = useFormikContext();

    const fillShifts = (days, { id }) => {
        // Sa und So nicht.
        days.slice(0, -2).forEach(day => {
            setFieldValue(`${name}.${day.id}_${id}_start`, values[name][`0_${id}_start`]);
            setFieldValue(`${name}.${day.id}_${id}_end`, values[name][`0_${id}_end`]);
        });
    };

    return (
        <TableContainer component={Paper} className={classes.container}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <b>Tage</b>
                        </TableCell>
                        {shifts.map((block, i) => (
                            <TableCell align="center" key={i}>
                                <b>
                                    {block.title}
                                    <br />
                                    {block.subTitle}
                                </b>
                                <br />
                                <Button variant="outlined" onClick={() => fillShifts(days, block)}>
                                    Alle wie Mo.
                                </Button>
                            </TableCell>
                        ))}
                        <TableCell>
                            <b>Summe</b>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {days.map(day => (
                        <ScheduleRow
                            name={name}
                            day={day}
                            shifts={shifts}
                            key={`${name}_${day.id}`}
                        />
                    ))}
                    <TableRow>
                        <TableCell>
                            <b>Summe</b>
                        </TableCell>
                        {shifts.map(shift => (
                            <TableCell></TableCell>
                        ))}
                        <TableCell>
                            <TotalSum name={name} days={days} shifts={shifts} />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}
