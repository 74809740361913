import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import Select from '../form/formik/Select';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RESOURCE_CLASS_RESOURCE } from '../../modules/api/resources';
import { useCan } from '../../modules/abilities/hooks';
import { UPDATE } from '../../modules/abilities/actions';
import {
    indexResourceClasses,
    selectAllResourceClasses,
    selectResourceClassesInitialized,
    selectResourceClassesLoading,
} from '../../modules/resourceClasses/resourceClassesSlice';
import ResourceClassForm from './ResourceClassForm';

const ResourceClassSelect = ({ name, label, options, ...other }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    /* for admin-panel settings */
    const [selectedAdminEditItemId, setSelectedAdminEditItemId] = useState(null);
    const [selectedAdminEditItemLoading, setSelectedAdminEditItemLoading] = useState(false);
    const canAdminEdit = useCan(UPDATE, RESOURCE_CLASS_RESOURCE);
    const adminFormPopupRef = useRef();

    const resClasses = useSelector(selectAllResourceClasses);
    const resClassesIsLoading = useSelector(selectResourceClassesLoading);
    const resClassesIsInitialized = useSelector(selectResourceClassesInitialized);

    const finalOptions = useMemo(
        () =>
            options && options.length > 0
                ? options.map(option => ({
                      ...option,
                      onEdit: () => setSelectedAdminEditItemId(option.value),
                  }))
                : resClasses.map(({ id, name }) => ({
                      label: name,
                      value: id,
                      onEdit: () => setSelectedAdminEditItemId(id),
                  })),
        [resClasses, options]
    );

    useEffect(() => {
        if (
            (!options || options.length === 0) &&
            !resClassesIsLoading &&
            !resClassesIsInitialized
        ) {
            dispatch(indexResourceClasses());
        }
    }, [dispatch, options, resClassesIsLoading, resClassesIsInitialized]);

    return (
        <Select
            name={name}
            options={finalOptions}
            label={label || t('form.ResourceClassSelect.label')}
            ItemEditFormPopupProps={{
                component: (
                    <ResourceClassForm
                        resourceClassId={selectedAdminEditItemId}
                        asPopup
                        popupStateRef={adminFormPopupRef}
                        onLoading={loading => setSelectedAdminEditItemLoading(loading)}
                    />
                ),
                canEdit: canAdminEdit,
                selectedEditItemValue: selectedAdminEditItemId,
                isLoading: selectedAdminEditItemLoading,
            }}
            {...other}
        />
    );
};

ResourceClassSelect.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            icon: PropTypes.string,
            iconColor: PropTypes.string,
            onEdit: PropTypes.func,
        })
    ),
};

ResourceClassSelect.defaultProps = {
    label: null,
    options: null,
};

export default ResourceClassSelect;
