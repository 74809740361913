const ERROR_STATUS_TRANSLATABLE = {
    [401]: 'Unauthenticated',
    [422]: 'The given data was invalid',
    [429]: 'throttle',
    [431]: 'headerToLarge',
    [500]: 'server',
    [502]: 'server',
    [503]: 'server',
    [504]: 'server',
};

export const getErrorStatusTranslatable = status => {
    if (Number.isNaN(status) || !status in ERROR_STATUS_TRANSLATABLE) {
        return null;
    }

    return ERROR_STATUS_TRANSLATABLE[status];
};

export const getFormFieldError = (fieldName, errors, meta) => {
    const apiError =
        errors && errors.errors && errors.errors[fieldName.replace('[', '.').replace(']', '')];
    if (apiError && (Array.isArray(apiError) || typeof apiError === 'string')) {
        return Array.isArray(apiError) ? apiError[0] : apiError;
    }

    // Sometimes error is an empty object?
    if (meta.error && Object.keys(meta.error).length === 0 && meta.error.constructor === Object) {
        return null;
    }

    return meta.touched && meta.error ? meta.error : null;
};
