/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import { SETTING_RESSOURCE } from '../api/resources';
import logger from '../../logger';

const insertItems = (state, item) => {
    /* prevent unnecessary re-rendering if the object is the same anyways */
    if (!isEqual(state[item.key], item.value)) {
        state[item.key] = item.value;
        state.keyIds[item.key] = item.id;
    }
};

const settingsSlice = createSlice({
    name: SETTING_RESSOURCE,
    initialState: {
        keyIds: {},
    },
    reducers: {
        indexFulfilled: (state, action) => {
            if (action.payload.length === 0) {
                logger.error('Settings should not be empty! Please check the database.');
            }

            action.payload.forEach(item => {
                insertItems(state, item);
            });
        },
        updateFulfilled: (state, action) => {
            insertItems(state, action.payload);
        },
        showFulfilled: (state, action) => {
            insertItems(state, action.payload);
        },
    },
});
export const { indexFulfilled, showFulfilled, updateFulfilled } = settingsSlice.actions;
export default settingsSlice.reducer;
