import { Grid } from '@mui/material';
import React from 'react';
import TextInput from '../../form/formik/TextInput';

export default function ScheduleInput({ name, day, shift }) {
    return (
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <TextInput
                    name={`${name}.${day.id}_${shift.id}_start`}
                    type="time"
                    variant="standard"
                    size="small"
                    errorAsTooltip
                    ignoreErrorHelperCases={['overlap']}
                />
            </Grid>
            <Grid item xs={6}>
                <TextInput
                    name={`${name}.${day.id}_${shift.id}_end`}
                    type="time"
                    variant="standard"
                    size="small"
                    errorAsTooltip
                    ignoreErrorHelperCases={['overlap']}
                />
            </Grid>
        </Grid>
    );
}
