import React, { useEffect, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Select from './formik/Select';
import { selectAllTaskDurations } from '../../modules/taskDurations/selectors';
import { useFieldFast } from '../../modules/form/hooks';

const TaskDurationSelect = ({ name, label, can, ...other }) => {
    const taskDurations = useSelector(selectAllTaskDurations);
    const [{ value }, , { setValue }] = useFieldFast(name);

    useEffect(() => {
        if (!value) {
            const fallback = taskDurations.find(task => task.is_default);
            if (fallback) {
                setValue(fallback.id);
            }
        }
    }, [value, setValue, taskDurations]);

    const options = useMemo(
        () =>
            taskDurations.map(duration => ({
                label: duration.name,
                value: duration.id,
            })),
        [taskDurations]
    );

    return <Select name={name} can={can} label={label} options={options} {...other} />;
};

TaskDurationSelect.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    can: PropTypes.string,
};

TaskDurationSelect.defaultProps = {
    label: null,
    can: null,
};

export default TaskDurationSelect;
