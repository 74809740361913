import { ADDRESS_TYPE_RESOURCE } from '../api/resources';

export const selectAllAddressTypeIds = state => state[ADDRESS_TYPE_RESOURCE].allIds;

export const selectAddressTypeById = (state, addressTypeId) =>
    state[ADDRESS_TYPE_RESOURCE].byId[addressTypeId];

export const selectAllAddressTypes = state =>
    selectAllAddressTypeIds(state).map(id => selectAddressTypeById(state, id));

export const selectAddressTypesIsLoading = state => state[ADDRESS_TYPE_RESOURCE].loading;
export const selectAddressTypesInitialized = state => state[ADDRESS_TYPE_RESOURCE].initialized;
