import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { IdPropType } from '../../modules/proptypes';
import { useDispatch, useSelector } from 'react-redux';
import { CREATE, UPDATE } from '../../modules/abilities/actions';
import { Box, Grid } from '@mui/material';
import Button from '../form/base/Button';
import SubmitButton from '../form/formik/SubmitButton';
import { useTranslation } from 'react-i18next';
import SlimDialog, { SLIM_DIALOG_WIDTH } from '../dialogs/slimDialog/SlimDialog';
import Badge from '@mui/material/Badge';
import { appointmentTypeSchema } from '../../modules/appointmentTypes/schema';
import {
    selectAppointmentTypesById,
    storeAppointmentType,
    updateAppointmentType,
} from '../../modules/appointmentTypes/appointmentTypeSlice';
import { assembleBulkValues, cleanPartialUpdateValues } from '../../modules/form/bulk';
import { APPOINTMENT_TYPE_RESOURCE } from '../../modules/api/resources';
import AppointmentTypeForm from './AppointmentTypeForm';

const AppointmentTypeDialog = ({ appointmentTypeIds, create, open, onClose, onExited }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const title = useMemo(
        () => t(`components.AppointmentTypeDialog.${create ? 'create' : 'bulk'}`),
        [create]
    );

    const appointmentTypesById = useSelector(selectAppointmentTypesById);

    const { bulkMode, initialValues, appointmentTypes } = useMemo(() => {
        const bulkMode =
            !create && Array.isArray(appointmentTypeIds) && appointmentTypeIds.length >= 1;

        if (bulkMode) {
            const { appointmentTypes } = appointmentTypeIds.reduce(
                (carry, appointmentTypeId) => {
                    const item = appointmentTypesById[parseInt(appointmentTypeId)];
                    return {
                        appointmentTypes: [...carry.appointmentTypes, item],
                    };
                },
                { appointmentTypes: [] }
            );
            const bulkValues = assembleBulkValues(appointmentTypes);

            return {
                bulkMode,
                initialValues: bulkValues,
                appointmentTypes,
            };
        }

        return {
            bulkMode,
            initialValues: {
                color: null,
                component: null,
                name: '',
                order: null,
                overlap: '',
                resource_config: null,
                type: null,
            },
            appointmentTypes: [],
        };
    }, [create, appointmentTypeIds, appointmentTypesById]);

    const dispatchSubmit = values => {
        if (create) {
            return dispatch(storeAppointmentType(values));
        }

        if (bulkMode) {
            const items = {
                ...values,
                id: appointmentTypeIds,
            };

            const cleaned = cleanPartialUpdateValues(initialValues, items);

            return dispatch(updateAppointmentType(cleaned));
        }

        if (Array.isArray(appointmentTypeIds) && appointmentTypeIds.length === 1) {
            return dispatch(updateAppointmentType(values));
        }

        console.error('could not distinguish submit mode (create/update/bulkUpdate)');
        return null;
    };

    const handleSubmit = values => {
        const submit = dispatchSubmit(values);

        if (submit) {
            submit.then(() => {
                onClose();
            });
        }

        return submit;
    };

    const handleDelete = () => null;

    const handleClose = props => {
        onClose(props);
    };

    const toolbarActions = useMemo(() => null, []);

    return (
        <SlimDialog
            title={title}
            open={open}
            width={SLIM_DIALOG_WIDTH.MEDIUM}
            onDelete={create || appointmentTypeIds.length ? null : handleDelete}
            onClose={handleClose}
            actions={toolbarActions}
        >
            <Box m={2}>
                <AppointmentTypeForm
                    initialValues={initialValues}
                    subject={
                        appointmentTypes.length === 1
                            ? appointmentTypes[0]
                            : APPOINTMENT_TYPE_RESOURCE
                    }
                    validationSchema={bulkMode ? null : appointmentTypeSchema}
                    onSubmit={handleSubmit}
                    actionsComponent={
                        <>
                            <Grid item display="flex" alignItems="end">
                                <Button onClick={handleClose} variant="outlined" size="medium">
                                    {t('abort')}
                                </Button>
                            </Grid>
                            <Grid item display="flex" alignItems="end">
                                <Badge
                                    invisible={appointmentTypeIds.length < 2}
                                    badgeContent={appointmentTypeIds.length}
                                    color="primary"
                                >
                                    <SubmitButton I={create ? CREATE : UPDATE} size="medium" />
                                </Badge>
                            </Grid>
                        </>
                    }
                />
            </Box>
        </SlimDialog>
    );
};

AppointmentTypeDialog.propTypes = {
    appointmentTypeIds: PropTypes.arrayOf(IdPropType.isRequired).isRequired,
    create: PropTypes.bool,
    open: PropTypes.bool,
};

AppointmentTypeDialog.defaultProps = {
    create: false,
    open: false,
};

export default AppointmentTypeDialog;
