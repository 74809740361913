import { createResourceSlice } from '../redux/resource/createResourceSlice';
import { ABSENCE_TYPE_RESOURCE } from '../api/resources';

const absenceTypeSlice = createResourceSlice({
    resource: ABSENCE_TYPE_RESOURCE,
    byKey: '',
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,
    indexAbsenceTypes,
    showAbsenceType,
    storeAbsenceType,
    updateAbsenceType,
    archiveAbsenceType,
    restoreAbsenceType,
    destroyAbsenceType,
} = absenceTypeSlice.actions;

export const {
    selectAbsenceTypeById,
    selectAbsenceTypesById,
    makeAbsenceTypesByIdsSelector,
    selectAllAbsenceTypeIds,
    selectAllAbsenceTypes,
    selectAbsenceTypesLoading,
    selectAbsenceTypesInitialized,
    selectAbsenceTypeLoading,
    selectAbsenceTypeInitialized,
    selectAbsenceTypeIdsBy,
    makeAbsenceTypesBySelector,
    selectAbsenceTypeLoadingBy,
    selectAbsenceTypeInitializedBy,
} = absenceTypeSlice.selectors;

export const { useAbsenceTypes, useAbsenceType, useAbsenceTypesBy } = absenceTypeSlice.hooks;

export default absenceTypeSlice.reducer;
