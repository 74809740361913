import React from 'react';
import * as PropTypes from 'prop-types';
import TypedAutocomplete from '../../base/autocomplete/TypedAutocomplete';
import { autocompleteGroups } from '../../../../modules/groups/actions';
import { useField } from 'formik';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    label: {
        paddingRight: theme.spacing(1),

        '& em': {
            fontWeight: 700,
            fontStyle: 'normal',
        },
    },
}));

const GroupsAutocomplete = ({
    name,
    label,
    fullWidth,
    fetchParams,
    getOptionDisabled,
    onChange,
    onFormatQuery,
    ...other
}) => {
    const classes = useStyles();
    const [{ value }, , { setValue }] = useField(name);

    const handleChange = value => {
        setValue(value);

        if (onChange) {
            onChange(value);
        }
    };

    const handleFetch = ({ q }) => {
        const payload = { ...fetchParams, q: onFormatQuery ? onFormatQuery(q) : q };
        return autocompleteGroups(payload);
    };

    return (
        <TypedAutocomplete
            name={name}
            fetchAction={handleFetch}
            value={value}
            label={label}
            fullWidth={fullWidth}
            onChange={handleChange}
            getOptionDisabled={getOptionDisabled}
            hideTypes
            {...other}
        />
    );
};

GroupsAutocomplete.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    initialValue: PropTypes.array,
    fullWidth: PropTypes.bool,
    fetchParams: PropTypes.shape({}),
    getOptionDisabled: PropTypes.func,
    onChange: PropTypes.func,
};

GroupsAutocomplete.defaultProps = {
    label: null,
    initialValue: null,
    fullWidth: true,
    fetchParams: {},
    getOptionDisabled: null,
    onChange: () => null,
};

export default GroupsAutocomplete;
