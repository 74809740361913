import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Box, Grid, ListItem, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useHistory } from 'react-router-dom';
import { IdPropType } from '../../modules/proptypes';
import { selectContactById } from '../../modules/contacts/selectors';
import RemoveIconButton from '../buttons/RemoveIconButton';
import { showContact } from '../../modules/contacts/actions';
import LoadingBox from '../loading/LoadingBox';
import { contactPath } from '../../routes/paths';
import ClickBoundary from '../form/ClickBoundary';
import Confirmation from '../form/Confirmation';

const useStyles = makeStyles(theme => ({
    listItem: {
        cursor: 'pointer',
        border: `1px solid ${theme.palette.grey['300']}`,
        borderRadius: theme.spacing(0.5),
        padding: theme.spacing(1, 2),
        '&:not(:last-child)': {
            marginBottom: theme.spacing(1),
        },
    },
    openIcon: {
        display: 'flex',
        justifyContent: 'center',
    },
    openIconColor: {
        color: theme.palette.grey[600],
    },
}));

const ContactListItem = ({ contactId, contextCardId, edit, removeLabel, onRemove }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const contact = useSelector(state => selectContactById(state, contactId));
    const history = useHistory();
    const [loading, setLoading] = useState(false); // ToDo: Loading in Redux

    useEffect(() => {
        if (!contact) {
            setLoading(true);
            dispatch(showContact({ id: contactId })).then(() => {
                setLoading(false);
            });
        }
    }, [contactId, contact, dispatch]);

    const navContact = () => !edit && history.push(contactPath(contactId, contextCardId));

    return (
        <ListItem className={classes.listItem} onClick={navContact} button>
            <LoadingBox loading={loading}>
                <Grid container alignItems="center">
                    <Grid item xs>
                        <Typography>{contact && contact.display_name}</Typography>
                    </Grid>
                    <Grid item>
                        {edit ? (
                            <ClickBoundary>
                                <Confirmation
                                    popupId="removeProtocolItemContact"
                                    onConfirm={() => onRemove(contactId)}
                                    label={removeLabel}
                                >
                                    <RemoveIconButton size="small" onClick={() => {}} />
                                </Confirmation>
                            </ClickBoundary>
                        ) : (
                            <Box className={classes.openIcon}>
                                <OpenInNewIcon className={classes.openIconColor} />
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </LoadingBox>
        </ListItem>
    );
};

ContactListItem.propTypes = {
    contactId: IdPropType.isRequired,
    contextCardId: IdPropType,
    edit: PropTypes.bool,
    removeLabel: PropTypes.string,
    onRemove: PropTypes.func,
};

ContactListItem.defaultProps = {
    contextCardId: null,
    edit: false,
    removeLabel: '',
    onRemove: () => {},
};

export default ContactListItem;
