import { isEqual } from 'lodash';
import { TASK_RESOURCE } from '../api/resources';
import { PER_PAGE } from '../kanban/config';
import {
    createResourceSlice,
    initializeId,
    initializeKey,
} from '../redux/resource/createResourceSlice';
import { selectAllTaskStatusIds, selectAllTaskStatuses } from '../taskStatus/taskStatusSlice';
import { createSelector } from '@reduxjs/toolkit';
import { asStateKey, toSnakeCase } from '../redux/resource/utils';
import { filterList } from '../lists/actions';

const affirmList = (state, statusId) => {
    if (!state.byTaskStatusId[statusId]) {
        state.byTaskStatusId[statusId] = { data: [] };
    }
};

const insertTask = (state, task, head = false, addToBoard = false, byKeys = []) => {
    const stale = state.byId[task.id];
    if (!stale) {
        state.allIds.push(task.id);

        if (byKeys) {
            (Array.isArray(byKeys) ? byKeys : [byKeys]).forEach(byKey => {
                if (byKey !== 'taskStatusId') {
                    let keys = task[toSnakeCase(byKey)];
                    const keyState = state[asStateKey(byKey)];

                    if (!Array.isArray(keys)) {
                        keys = [keys];
                    }

                    keys.forEach(key => {
                        if (!keyState[key]) {
                            keyState[key] = [];
                        }

                        keyState[key].push(task.id);
                    });
                }
            });
        }
    }

    if (addToBoard) {
        affirmList(state, task.task_status_id);

        if (!stale) {
            if (head) {
                state.byTaskStatusId[task.task_status_id].data.unshift(task.id);
            } else {
                state.byTaskStatusId[task.task_status_id].data.push(task.id);
            }
        } else if (
            stale.task_status_id !== task.task_status_id &&
            stale.task_status_id in state.byTaskStatusId
        ) {
            const staleList = state.byTaskStatusId[stale.task_status_id].data;
            const index = staleList.findIndex(id => id === task.id);
            if (index >= 0) {
                staleList.splice(index, 1);
            }
            state.byTaskStatusId[task.task_status_id].data.unshift(task.id);
        } else if (!state.byTaskStatusId[task.task_status_id].data.includes(task.id)) {
            state.byTaskStatusId[task.task_status_id].data.push(task.id);
        }
    }

    const normalized = {
        ...task,
        responsibles: [
            ...task.responsibles_main,
            ...task.responsibles.filter(id => !task.responsibles_main.includes(id)),
        ],
    };

    /* prevent unnecessary re-rendering if the object is the same anyways */
    if (!isEqual(state.byId[task.id], normalized)) {
        state.byId[task.id] = normalized;
    }
};

const byKeys = ['cardId'];

const tasksSlice = createResourceSlice({
    resource: TASK_RESOURCE,
    byKey: byKeys,
    initialState: {
        search: {},
        byTaskStatusId: {},
    },
    reducers: {
        saveSearch: {
            prepare: (search, key) => ({ payload: { search, key } }),
            reducer: (state, action) => {
                const { search, key } = action.payload;
                state.search[key] = search;
            },
        },

        indexFulfilled: (state, action) => {
            state.initialize.loading = false;
            state.initialize.done = true;

            action.payload.forEach(task => {
                insertTask(state, task, false, false, byKeys);
                initializeId(state, task.id, false, true);
            });
            initializeKey(state, action, false, true);
        },

        searchFulfilled: (state, action) => {
            if (
                !action.meta.socketReaction &&
                action.meta.params.task_status_id &&
                action.meta.current_page !== undefined
            ) {
                affirmList(state, action.meta.params.task_status_id);
                state.byTaskStatusId[action.meta.params.task_status_id].current_page =
                    action.meta.current_page;

                state.byTaskStatusId[action.meta.params.task_status_id].is_last_page =
                    action.meta.is_last_page || action.meta.current_page === action.meta.last_page;

                state.byTaskStatusId[action.meta.params.task_status_id].isInitialized = true;
                state.byTaskStatusId[action.meta.params.task_status_id].isLoading = false;

                if (action.meta.current_page === 1) {
                    state.byTaskStatusId[action.meta.params.task_status_id].data = [];
                }
            }

            if (action.meta.socketReaction && action.meta.params.id) {
                const task = state.byId[action.meta.params.id];
                if (task && !action.payload.find(({ id }) => id === action.meta.params.id)) {
                    const statusList = state.byTaskStatusId[task.task_status_id].data;
                    const index = statusList.findIndex(id => id === task.id);
                    if (index >= 0) {
                        statusList.splice(index, 1);
                    }
                }
            }

            action.payload.forEach(task => {
                insertTask(state, task, false, true);
                initializeId(state, task.id, false, true);
            });
            initializeKey(state, action, false, true);
        },

        storeFulfilled: (state, action) => {
            insertTask(state, action.payload, true, true, byKeys);
            initializeId(state, action.payload.id, false, true);
        },

        updateFulfilled: (state, action) => {
            insertTask(state, action.payload, false, true, byKeys);
        },

        destroyFulfilled: (state, action) => {
            const index = state.allIds.findIndex(id => id === action.payload);
            if (index >= 0) {
                state.byId[action.payload] = null;
                state.allIds.splice(index, 1);

                Object.values(state.byTaskStatusId).forEach(list => {
                    const listIndex = list.data.findIndex(id => id === action.payload);
                    if (listIndex >= 0) {
                        list.data.splice(listIndex, 1);
                    }
                });

                if (byKeys) {
                    (Array.isArray(byKeys) ? byKeys : [byKeys]).forEach(byKey => {
                        if (byKey !== 'taskStatusId') {
                            Object.values(state[asStateKey(byKey)]).forEach(ids => {
                                const _index = ids.findIndex(id => id === action.payload);
                                if (_index >= 0) {
                                    ids.splice(_index, 1);
                                }
                            });
                        }
                    });
                }
            }
        },
    },
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,

    indexTasks,
    showTask,
    storeTask,
    updateTask,
    archiveTask,
    restoreTask,
    destroyTask,
    searchTasks,
    autocompleteTasks,
    suggestTasks,
    saveSearch,
} = tasksSlice.actions;

export const {
    selectTaskById,
    selectTasksById,
    selectTasksByIds,
    makeTasksByIdsSelector,
    selectAllTaskIds,
    selectAllTasks,
    selectTasksLoading,
    selectTasksInitialized,
    selectTaskLoading,
    selectTaskInitialized,
    selectTaskIdsByCardId,
    makeTasksByCardIdSelector,
    selectTaskLoadingByCardId,
    selectTaskInitializedByCardId,
} = tasksSlice.selectors;

export const { useTasks, useTask, useTasksByCardId } = tasksSlice.hooks;

export default tasksSlice.reducer;

/* TODO Future work: make resourceList generic and use it instead */

/* extra selectors to handle TASK_BOARD */
export const selectTaskSearchByKey = (state, key) => state[TASK_RESOURCE].search[key] || {};

export const selectTaskListByTaskStatusId = (state, statusId) =>
    state[TASK_RESOURCE].byTaskStatusId[statusId] || {};

const selectTaskListsByTaskStatusId = state => state[TASK_RESOURCE].byTaskStatusId;

export const makeTaksByTaskStatusIdSelector = (_, statusId) =>
    createSelector([selectTaskListsByTaskStatusId], lists => {
        const list = lists[statusId];
        return (list && list.data) || [];
    });

/* extra actions to handle TASK_BOARD */
export const taskListPage =
    (statusId, page = 1, key) =>
    (dispatch, getState) => {
        const state = getState();
        const { filters, contexts, card_id } = state[TASK_RESOURCE].search[key] || {};

        return dispatch(
            searchTasks(
                {
                    limit: PER_PAGE,
                    page,

                    /* fallback if no special filters are set */
                    task_status_id: statusId,
                    card_id,
                    with_shallow: 'card',

                    filters:
                        (filters.$and && filters.$and.length) > 0
                            ? {
                                  $and: [
                                      /* custom filter for task status */
                                      {
                                          name: 'task.status',
                                          id: statusId,
                                          by_id: true,
                                      },
                                      ...filters.$and,
                                  ],
                              }
                            : [],

                    contexts,
                },
                null,
                statusId
            )
        );
    };

export const taskListNextPage = (statusId, key) => (dispatch, getState) => {
    const state = getState();
    const list = selectTaskListByTaskStatusId(state, statusId);

    return list.current_page && !list.is_last_page
        ? dispatch(taskListPage(statusId, list.current_page + 1, key))
        : Promise.resolve();
};

export const filterTasks = (search, key) => (dispatch, getState) => {
    const state = getState();

    dispatch(saveSearch(search, key));

    if (search.viewMode === 'board') {
        const taskStatuses = selectAllTaskStatuses(state);
        const activeTaskStatusIds = taskStatuses
            .filter(status => status.active)
            .map(status => status.id);

        return Promise.all(
            selectAllTaskStatusIds(state).map(taskStatusId => {
                if (activeTaskStatusIds.includes(taskStatusId)) {
                    dispatch(taskListPage(taskStatusId, 1, key));
                }
            })
        );
    }

    return dispatch(filterList(key, search));
};
