import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as propTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import Form from '../form/formik/Form';
import SubmitButton from '../form/formik/SubmitButton';
import CategorySelect from '../form/CategorySelect';
import { createTaskSchema } from '../../modules/tasks/schema';
import { IdPropType } from '../../modules/proptypes';
import { TASK_RESOURCE } from '../../modules/api/resources';
import TaskTemplateAutocomplete from '../form/formik/autocomplete/TaskTemplateAutocomplete';
import OptionalFormikContainer from '../form/formik/OptionalFormikContainer';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllTaskStatuses } from '../../modules/taskStatus/taskStatusSlice';
import { storeTask } from '../../modules/tasks/tasksSlice';
import CardAutocomplete from '../form/formik/autocomplete/CardAutocomplete';
import TaskStatusSelect from '../taskStatus/TaskStatusSelect';

const useStyles = makeStyles({
    container: {
        height: 420,
        flexWrap: 'nowrap',
    },
    item: {
        marginRight: 64,
        marginLeft: 64,
    },
    submit: {
        display: 'flex',
        justifyContent: 'end',
    },
});

const CreateTaskForm = ({ cardId, can, onCreate }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const taskStatuses = useSelector(selectAllTaskStatuses);
    const defaultTaskStatus = useMemo(
        () => taskStatuses.find(ts => ts.order === 1),
        [taskStatuses]
    );

    const handleCreated = (task, isTemplate = false) => {
        if (task?.id && onCreate) {
            onCreate(task.id, { cardId, edit: !isTemplate });
        }
    };

    const handleSubmitTask = useCallback(
        ({ templateId, ...values }) => {
            const preppedValues = templateId
                ? {
                      id: templateId,
                      card_id: values.card_id,
                      with: ['appointments', 'responsibles', 'checklists'],
                  }
                : values;

            return dispatch(storeTask(preppedValues)).then(({ data: task }) => {
                handleCreated(task, templateId);
            });
        },
        [defaultTaskStatus, onCreate]
    );

    const initialValues = {
        name: '',
        templateId: '',
        category_id: null,
        card_id: cardId || '',
        task_status_id: defaultTaskStatus?.id || '',
    };

    return (
        <Form
            initialValues={initialValues}
            validationSchema={createTaskSchema}
            onSubmit={handleSubmitTask}
            subject={TASK_RESOURCE}
        >
            <Grid container direction="column" spacing={4} className={classes.container}>
                <Grid item xs />
                {cardId ? null : (
                    <Grid item className={classes.item}>
                        <CardAutocomplete name="card_id" size="small" fullWidth />
                    </Grid>
                )}
                <Grid item className={classes.item}>
                    <TaskTemplateAutocomplete
                        initialValue={initialValues.name}
                        name="name"
                        nameId="templateId"
                        label={t('form.CreateTask.name')}
                        size="small"
                        I={can}
                        fullWidth
                    />
                </Grid>
                <Grid item className={classes.item}>
                    <OptionalFormikContainer
                        name="templateId"
                        propNode={formikCondition =>
                            formikCondition ? (
                                <TaskStatusSelect name="task_status_id" fullWidth />
                            ) : null
                        }
                    />
                </Grid>
                <Grid item className={classes.item}>
                    <OptionalFormikContainer
                        name="templateId"
                        propNode={formikCondition =>
                            formikCondition ? (
                                <CategorySelect
                                    name="category_id"
                                    label={t('form.CreateTask.category')}
                                    I={can}
                                    fullWidth
                                />
                            ) : null
                        }
                    />
                </Grid>
                <Grid item xs />
                <Grid item className={classes.submit}>
                    <OptionalFormikContainer
                        name="templateId"
                        propNode={formikCondition => (
                            <SubmitButton
                                data-test-id="add-new-task-btn"
                                label={t(
                                    `form.CreateTask.${formikCondition ? 'submit' : 'fromTemplate'}`
                                )}
                                variant="contained"
                                size="medium"
                                I={can}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </Form>
    );
};

CreateTaskForm.propTypes = {
    cardId: IdPropType.isRequired,
    can: propTypes.string,
    onCreate: propTypes.func,
};

CreateTaskForm.defaultProps = {
    can: null,
    onCreate: null,
};

export default CreateTaskForm;
