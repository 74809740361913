import React from 'react';
import { remarkableCommonPlugin } from './common';
import { findEditorElements, getElementData } from './element';

export const EDITOR_HIGHLIGHT = 'HIGHLIGHT';

export const getHighlightValues = elementData => {
    const background = /data-background="(.*?)"/.exec(elementData);
    const color = /data-color="(.*?)"/.exec(elementData);
    const text = /data-text="(.*?)"/.exec(elementData);

    return {
        backgroundColor: background[1] || '',
        color: color[1] || '',
        text: text[1] || '',
    };
};

const createHighlight = (elementData, state) => {
    const values = getHighlightValues(elementData);

    state.push({
        type: 'highlight_open',
        backgroundColor: values.backgroundColor,
        color: values.color,
        text: values.text,
        level: state.level,
    });

    state.push({
        type: 'text',
        content: values.text,
        level: state.level + 1,
    });

    state.push({
        type: 'highlight_close',
        level: state.level,
    });
};

export const remarkableHighlightPlugin = remarkable => {
    remarkable.inline.ruler.push(EDITOR_HIGHLIGHT, (state, silent) => {
        const highlightRegEx = /^<highlight (.*?)>.*?<\/highlight>/;
        return remarkableCommonPlugin(state, silent, highlightRegEx, createHighlight);
    });
};

export const findHighlightElements = (contentBlock, callback, contentState) =>
    findEditorElements(contentBlock, callback, contentState, EDITOR_HIGHLIGHT);

export const EditorHighlight = props => {
    const { text, ...style } = getElementData(props);
    return <span style={style}>{props.children}</span>;
};
