import { compareDesc, parseISO } from 'date-fns';

export const compareByDate = (p1, p2) => {
    const compareDate = compareDesc(p1.date, p2.date);

    if (compareDate !== 0) {
        return compareDate;
    }

    return compareDesc(parseISO(p1.created_at), parseISO(p2.created_at));
};
