import React from 'react';
import * as PropTypes from 'prop-types';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import { useTranslation } from 'react-i18next';
import { UPDATE } from '../../modules/abilities/actions';
import Button from '../form/base/Button';
import { SubjectPropType } from '../../modules/abilities/proptypes';

const RestoreButton = ({ onClick, disabled, subject }) => {
    const { t } = useTranslation();
    return (
        <Button
            startIcon={<UnarchiveOutlinedIcon />}
            onClick={onClick}
            disabled={disabled}
            action={UPDATE}
            field="deleted_at"
            subject={subject}
            data-test-id="RestoreButton"
        >
            {t('components.ResourceTableRowDetails.archived')}
        </Button>
    );
};

RestoreButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    subject: SubjectPropType,
};

RestoreButton.defaultProps = {
    disabled: false,
    subject: null,
};

export default RestoreButton;
