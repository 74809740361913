import { put, takeEvery } from 'redux-saga/effects';
import { DATACONTAINER_RESOURCE, DATACONTAINER_TYPE_RESOURCE } from '../api/resources';
import {
    indexFulfilled as datacontainerTypeIndexFulfilled,
    showFulfilled as datacontainerTypeShowFulfilled,
} from '../datacontainerTypes/datacontainerTypesSlice';

function* nestedIndexFulfilled(action) {
    const types = action.payload.map(({ datacontainer_type }) => datacontainer_type);

    yield put(datacontainerTypeIndexFulfilled(types, { key: DATACONTAINER_TYPE_RESOURCE }));
}

function* nestedShowFulfilled(action) {
    const { datacontainer_type: type } = action.payload;

    yield put(datacontainerTypeShowFulfilled(type, { key: DATACONTAINER_TYPE_RESOURCE }));
}

export default [
    takeEvery(`${DATACONTAINER_RESOURCE}/indexFulfilled`, nestedIndexFulfilled),
    takeEvery(`${DATACONTAINER_RESOURCE}/showFulfilled`, nestedShowFulfilled),
];
