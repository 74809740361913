import React, { useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Form from '../form/formik/Form';
import { IdPropType, RefPropType } from '../../modules/proptypes';
import LoadingBox from '../loading/LoadingBox';
import { useTranslation } from 'react-i18next';
import SubmitButton from '../form/formik/SubmitButton';
import FormPopup from '../popups/FormPopup';
import { SubjectPropType } from '../../modules/abilities/proptypes';
import { TASK_TYPE_RESOURCE } from '../../modules/api/resources';
import { taskTypeSettingsSchema } from '../../modules/taskTypes/schema';
import {
    destroyTaskType,
    selectTaskTypeById,
    storeTaskType,
    updateTaskType,
} from '../../modules/taskTypes/taskTypesSlice';
import TaskTypeSettingsFormBody from './TaskTypeSettingsFormBody';
import { useCan } from '../../modules/abilities/hooks';
import { DELETE } from '../../modules/abilities/actions';

const TaskTypeSettingsForm = ({
    taskTypeId,
    asPopup,
    openPopupWith,
    popupStateRef,
    onLoading,
    initialValues: initialValuesProps,
    onSubmit,
    subject,
    validationSchema,
    actionsComponent,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const taskType = useSelector(state => selectTaskTypeById(state, taskTypeId));
    const canDelete = useCan(DELETE, {
        type: taskType?.type,
        __type: TASK_TYPE_RESOURCE,
    });

    const initialValues = useMemo(
        () =>
            taskType ||
            initialValuesProps || {
                name: '',
                is_order_related: false,
                is_external: false,
                is_working_time: false,
                is_active: true,
                order: null,
            },
        [taskType, initialValuesProps]
    );

    const handleSubmit = newValue => {
        setLoading(true);
        if (onLoading) onLoading(true);
        return dispatch(taskTypeId ? updateTaskType(newValue) : storeTaskType(newValue)).finally(
            () => {
                setLoading(false);
                if (onLoading) onLoading(false);
            }
        );
    };

    const handleDelete = () => dispatch(destroyTaskType({ id: taskTypeId }));

    return asPopup ? (
        <FormPopup
            popupId="taskTypePopupForm"
            popupStateRef={popupStateRef}
            initialValues={initialValues}
            validationSchema={validationSchema || taskTypeSettingsSchema}
            onSubmit={onSubmit || handleSubmit}
            onDelete={taskTypeId && canDelete ? handleDelete : null}
            submitLabel={t('form.SubmitButton.defaultLabel')}
            abortLabel={t('abort')}
            enableReinitialize
            FormInputs={
                <Box
                    onKeyDown={e => {
                        if (e.code !== 'Escape') {
                            e.stopPropagation();
                        }
                    }}
                >
                    <TaskTypeSettingsFormBody autoFocus />
                </Box>
            }
        >
            <Grid container>
                <Grid item>
                    <LoadingBox loading={loading} variant="circular" size="small">
                        {openPopupWith}
                    </LoadingBox>
                </Grid>
            </Grid>
        </FormPopup>
    ) : (
        <Form
            initialValues={initialValues}
            subject={subject}
            validationSchema={validationSchema || taskTypeSettingsSchema}
            onSubmit={onSubmit || handleSubmit}
        >
            <TaskTypeSettingsFormBody />
            <Grid container spacing={2} justifyContent="flex-end">
                {actionsComponent ? (
                    actionsComponent
                ) : (
                    <SubmitButton>{t('form.SubmitButton.defaultLabel')}</SubmitButton>
                )}
            </Grid>
        </Form>
    );
};

TaskTypeSettingsForm.propTypes = {
    taskTypeId: IdPropType,
    asPopup: PropTypes.bool,
    openPopupWith: PropTypes.node,
    popupStateRef: RefPropType,
    onLoading: PropTypes.func,
    initialValues: PropTypes.shape({}),
    onSubmit: PropTypes.func,
    subject: SubjectPropType,
    validationSchema: PropTypes.shape({}),
    actionsComponent: PropTypes.node,
};

TaskTypeSettingsForm.defaultProps = {
    taskTypeId: null,
    asPopup: false,
    openPopupWith: null,
    popupStateRef: null,
    onLoading: null,
    initialValues: null,
    onSubmit: null,
    subject: TASK_TYPE_RESOURCE,
    validationSchema: null,
    actionsComponent: null,
};

export default TaskTypeSettingsForm;
