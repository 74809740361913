import React from 'react';
import * as PropTypes from 'prop-types';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { useTranslation } from 'react-i18next';
import Confirmation from '../form/Confirmation';
import Button from '../form/base/Button';
import { DELETE } from '../../modules/abilities/actions';
import { SubjectPropType } from '../../modules/abilities/proptypes';

const DeleteButton = ({
    onClick,
    confirmLabel,
    confirmBadge,
    disabled,
    subject,
    color,
    noConfirm,
}) => {
    const { t } = useTranslation();

    return (
        <Confirmation
            popupId="DeleteButton"
            onConfirm={onClick}
            label={confirmLabel || t('components.DestroyIntent.confirm')}
            disabled={noConfirm || disabled}
            badge={confirmBadge}
        >
            <Button
                startIcon={<DeleteForeverOutlinedIcon />}
                disabled={disabled}
                action={DELETE}
                subject={subject}
                data-test-id="DeleteButton"
                color={color}
                onClick={noConfirm ? onClick : null}
            >
                {t('components.ResourceTableRowDetails.delete')}
            </Button>
        </Confirmation>
    );
};

DeleteButton.propTypes = {
    confirmLabel: PropTypes.string,
    confirmBadge: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    subject: SubjectPropType,
    color: PropTypes.string,
    noConfirm: PropTypes.bool,
};

DeleteButton.defaultProps = {
    confirmLabel: null,
    confirmBadge: null,
    disabled: false,
    subject: null,
    color: 'default',
    noConfirm: false,
};

export default DeleteButton;
