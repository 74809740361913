import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectApiError } from '../../modules/api/selectors';
import { useSnackbar } from '../../modules/snackbar/hooks';
import { logout } from '../../modules/auth/actions';
import { clearApiError } from '../../modules/api/apiSlice';

export const USER_LOGGED_OUT = 401;
export const UNAUTHORIZED = 403;
export const NOT_FOUND = 404;
export const PAYLOAD_TOO_LARGE = 413;
export const PAGE_EXPIRED = 419;
export const INTERNAL_SERVER_ERROR = 500;

const ApiErrorHandler = () => {
    const { t } = useTranslation();
    const error = useSelector(selectApiError);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    useEffect(() => {
        if (error) {
            const forceHandleError = error?.forceHandleError; //Not enabled for logout statuses

            if ([USER_LOGGED_OUT, PAGE_EXPIRED].includes(error.status)) {
                dispatch(logout());
            } else if (error.status === UNAUTHORIZED && !forceHandleError) {
                enqueueSnackbar(t('errors.api.unauthorized'), { variant: 'error' });
            } else if (error.status === NOT_FOUND && !forceHandleError) {
                enqueueSnackbar(
                    t(
                        `errors.api.notFound.${error.key.toLowerCase()}`,
                        t('errors.api.notFound.generic')
                    ),
                    { variant: 'error' }
                );
            } else if (error.status === INTERNAL_SERVER_ERROR && !forceHandleError) {
                enqueueSnackbar(t('errors.api.internalError'), { variant: 'error' });
            } else if (error.status === PAYLOAD_TOO_LARGE && !forceHandleError) {
                enqueueSnackbar(t('errors.api.payloadTooLarge'), { variant: 'error' });
            } else if (!(error.handleError && error.handleError(error))) {
                const errorMessage = error.translate ? t(error.translate) : error.message;
                enqueueSnackbar(errorMessage, { variant: 'error' });
            }

            dispatch(clearApiError());
        }
    }, [error, dispatch, enqueueSnackbar, t]);

    return null;
};

ApiErrorHandler.propTypes = {};

export default ApiErrorHandler;
