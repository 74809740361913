import { PERSON_FUNCTION_RESOURCE } from '../api/resources';

export const indexPersonFunctions = params => (dispatch, getState, api) =>
    api[PERSON_FUNCTION_RESOURCE].index(params);

export const storePersonFunction = person => (dispatch, getState, api) =>
    api[PERSON_FUNCTION_RESOURCE].store(person);

export const updatePersonFunction = person => (dispatch, getState, api) =>
    api[PERSON_FUNCTION_RESOURCE].update(person);

export const destroyPersonFunction = params => (dispatch, getState, api) =>
    api[PERSON_FUNCTION_RESOURCE].destroy({ ...params, forceDelete: true });
