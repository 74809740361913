import {
    exportDate,
    formatDate,
    formatLocalDate,
    formatLocalDateTime,
    formatTime,
    formatTimeLong,
    importDate,
} from '../datetime/utils';
import {
    addDays,
    areIntervalsOverlapping,
    isBefore,
    isEqual,
    max,
    min,
    set,
    subSeconds,
} from 'date-fns';
import {
    CALENDAR_RESOURCE_MODELS,
    FLAT_UNPLANNED,
    formatEmployeeForCalendar,
    formatResourceForCalendar,
    formatSwapFromFlat,
} from '../calendar/utils';
import { EMPLOYEE_RESOURCE, RESOURCE_RESOURCE } from '../api/resources';
import { grey, indigo } from '@mui/material/colors';
import isSameDay from 'date-fns/isSameDay';
import { de } from 'date-fns/locale';

export const APPOINTMENT_TYPE_AWAY_KEYS = {
    ABSENCE: 'absence',
    SERVICE: 'service',
    HOLIDAY: 'holiday',
};

export const ALLDAY = 'allday';

export const formatAppointmentTypeResourceGroups = (appointmentType, onFilter) => {
    const resourceConfig = appointmentType?.resource_config;

    if (resourceConfig && Array.isArray(resourceConfig)) {
        return resourceConfig.reduce(
            (carry, group) => {
                if (!group || !group.group || (onFilter && onFilter(group))) {
                    return carry;
                }

                return {
                    groupKeys: [...carry.groupKeys, group.group],
                    groupConfig: {
                        ...carry.groupConfig,
                        [group.group]: group,
                    },
                };
            },
            { groupKeys: [], groupConfig: {} }
        );
    }
    return { groupKeys: [], groupConfig: {} };
};

const IMPORT_RESOURCES_PREFIX = 'resources.';

export const formatResourceImport = resources => {
    if (!resources || Array.isArray(resources)) {
        return {};
    }

    return Object.entries(resources).reduce(
        (mainCarry, [resource, grouped]) => {
            const { resourceMatched, ...groupResources } = Object.entries(grouped).reduce(
                (groupCarry, [group, resourceIds]) => {
                    return {
                        ...groupCarry,
                        resourceMatched: [
                            ...groupCarry.resourceMatched,
                            ...(Array.isArray(resourceIds) ? resourceIds : [resourceIds]),
                        ],
                        [`${IMPORT_RESOURCES_PREFIX}${group}`]: resourceIds,
                    };
                },
                { resourceMatched: [] }
            );

            return {
                ...mainCarry,
                resourceMatches: {
                    ...mainCarry.resourceMatches,
                    [resource]: resourceMatched,
                },
                grouped: {
                    ...mainCarry.grouped,
                    ...grouped,
                },
                ...groupResources,
            };
        },
        {
            resourceMatches: {},
            grouped: {},
        }
    );
};

export const formatAppointmentImport = ({
    id,
    originalId,
    name,
    description,

    starts_at,
    has_start_time,
    ends_at,
    has_end_time,
    locked,

    rrule,
    exdate,

    task_duration_id,
    duration_value,

    entity_id,
    entity_type,

    appointment_type_id,

    absence_type_id,
    category_id,
    priority_id,
    card,
    task,

    responsibles,
    responsibles_main,

    resources,
    resourceItems,
    resourceMatches,

    created_at,
    created_by,
    updated_at,
    updated_by,
    deleted_at,
    completed_at,
    completed_by,
    planned_at,
    planned_by,

    ...other
}) => ({
    ...other,

    id: originalId || id,
    originalId: originalId || id,
    title: name,
    notes: description,

    startDate: starts_at,
    hasStartTime: has_start_time,
    endDate: ends_at,
    hasEndTime: has_end_time,
    locked,

    allDay:
        !ends_at ||
        (starts_at &&
            formatTimeLong(importDate(starts_at)) === '00:00:00' &&
            ends_at &&
            formatTimeLong(importDate(ends_at)) === '23:59:59'),

    rRule: rrule || '',
    exDate: exdate || '',

    durationId: task_duration_id,
    durationValue: duration_value,

    entityId: entity_id,
    entityType: entity_type,

    appointmentTypeId: appointment_type_id,

    absenceTypeId: absence_type_id,
    categoryId: category_id,
    priorityId: priority_id,
    card: {
        ...card,
        id: card?.id,
        idOrder: card?.id_order,
        idOffer: card?.id_offer,
    },
    task: {
        ...task,
        id: task?.id,
        categoryId: task?.category_id,
        priorityId: task?.priority_id,
    },

    responsibles,
    responsiblesMain: responsibles_main,

    ...formatResourceImport(resources),
    baseGroups: resources,
    resourceItems,

    createdAt: created_at,
    createdBy: created_by,
    updatedAt: updated_at,
    updatedBy: updated_by,
    deletedAt: deleted_at,
    completedAt: completed_at,
    completedBy: completed_by,
    plannedAt: planned_at,
    plannedBy: planned_by,
});

export const appointmentResourceMatchSplit = (
    { resourceMatches, ...appointment },
    selectedEmployees = [],
    selectedResources = []
) => {
    const fallback = [
        {
            ...appointment,
            originalId: appointment.id,
            resourceOwner: null,
        },
    ];

    if (
        !resourceMatches ||
        !(selectedEmployees || selectedResources) ||
        (selectedEmployees.length === 0 && selectedResources.length === 0)
    ) {
        return fallback;
    }

    const employeeModel = CALENDAR_RESOURCE_MODELS[EMPLOYEE_RESOURCE];

    const employees = [
        ...((resourceMatches && resourceMatches[employeeModel]) || []),
        ...(appointment.entityType === employeeModel ? [appointment.entityId] : []),
    ];

    const resourceModel = CALENDAR_RESOURCE_MODELS[RESOURCE_RESOURCE];

    const resources = [
        ...((resourceMatches && resourceMatches[resourceModel]) || []),
        ...(appointment.entityType === resourceModel ? [appointment.entityId] : []),
    ];

    const formatSplitted = (id, resource) => ({
        ...appointment,
        id: `${appointment.id}.${resource}.${id}`,
        originalId: appointment.id,
        resourceOwner: {
            id,
            resource,
        },
    });

    const employeeAppointments = !employees
        ? []
        : employees.reduce((carry, id) => {
              if (selectedEmployees.includes(id)) {
                  return [...carry, formatSplitted(id, EMPLOYEE_RESOURCE)];
              }
              return carry;
          }, []);
    const resourceAppointments = !resources
        ? []
        : resources.reduce((carry, id) => {
              if (selectedResources.includes(id)) {
                  return [...carry, formatSplitted(id, RESOURCE_RESOURCE)];
              }
              return carry;
          }, []);

    if (employeeAppointments.length === 0 && resourceAppointments.length === 0) {
        return fallback;
    }

    return [...employeeAppointments, ...resourceAppointments];
};

export const appointmentFlatResourceSplit = ({ flatMeta, ...appointment }) =>
    flatMeta.map(({ key, id, resource }) => ({
        ...appointment,
        id: `${appointment.id}.${key}`,
        originalId: appointment.id,
        flatResources: [key],
        resourceOwner: {
            id,
            resource,
        },
    }));

export const formatResourceExport = entries => {
    if (entries?.form && entries.grouped) {
        return {
            ...entries,
            resources: entries.grouped,
        };
    }

    return Object.entries(entries).reduce(
        (carry, [key, value]) => {
            if (key.includes(IMPORT_RESOURCES_PREFIX)) {
                return {
                    ...carry,
                    resources: {
                        ...carry.resources,
                        [key.replace(IMPORT_RESOURCES_PREFIX, '')]: value,
                    },
                };
            }

            return {
                ...carry,
                remaining: { ...carry.remaining, [key]: value },
            };
        },
        { resources: {}, remaining: {} }
    );
};

export const formatAppointmentExport = ({
    id,
    originalId,
    title,
    address,
    notes,

    startDate,
    hasStartTime,
    endDate,
    hasEndTime,
    allDay,
    locked,

    rRule,
    exDate,

    durationId,
    durationValue,

    entityId,
    entityType,

    appointmentTypeId,

    absenceTypeId,
    categoryId,
    priorityId,

    responsibles,
    responsiblesMain,

    resourceItems,

    createdAt,
    createdBy,
    updatedAt,
    updatedBy,
    deletedAt,
    completedAt,
    completedBy,
    plannedAt,

    resource_swap,
    limit,

    ...other
}) => {
    const { resources } = formatResourceExport(other);
    const startsAt = importDate(startDate);
    const endsAt = importDate(endDate);
    const endBeforeStart = isBefore(endsAt, startsAt) || isEqual(startsAt, endsAt);

    const getId = () => {
        if (originalId && !Number.isNaN(originalId)) {
            return originalId;
        }

        if (!Number.isNaN(id)) {
            return id;
        }

        if (typeof id === 'string') {
            const idFromString = formatSwapFromFlat(id).id;

            if (!Number.isNaN(idFromString)) {
                return idFromString;
            }
        }

        throw new Error(`No id found in appointment: [${id} | ${originalId}] "${title}"`);
    };

    const getStart = () => {
        if (!startsAt) {
            return {};
        }

        return allDay || endBeforeStart
            ? { starts_at: set(startsAt, { hours: 0, minutes: 0, seconds: 0 }) }
            : { starts_at: startsAt, has_start_time: true };
    };

    const getEnd = () => {
        if (!endsAt) {
            return {};
        }

        const isStrangeDragAllDay =
            formatTimeLong(startsAt) === '00:00:00' && formatTimeLong(endsAt) === '00:00:00';

        const allDayValue =
            allDay && (other?.form || !isStrangeDragAllDay || endBeforeStart)
                ? set(endsAt, { hours: 23, minutes: 59, seconds: 59 })
                : subSeconds(endsAt, 1);

        return allDay || isStrangeDragAllDay || endBeforeStart
            ? { ends_at: allDayValue }
            : { ends_at: endsAt, has_end_time: true };
    };

    const getDateValue = (value, key) => {
        if (value === null) {
            return { [key]: null };
        }

        if (value) {
            return { [key]: exportDate(value) };
        }

        return {};
    };

    return {
        id: getId(),
        ...(title || title === '' ? { name: title } : {}),
        ...(address || address === null ? { address } : {}),
        ...(notes || notes === '' ? { description: notes } : {}),

        ...getStart(),
        ...(hasStartTime ? { has_start_time: hasStartTime } : {}),
        ...getEnd(),
        ...(hasEndTime ? { has_end_time: hasEndTime } : {}),
        ...(locked !== undefined && locked !== null ? { locked } : {}),

        ...(rRule || rRule === null ? { rrule: rRule } : {}),
        ...(exDate || exDate === null ? { exdate: exDate } : {}),

        ...(durationId || durationId === null ? { task_duration_id: durationId } : {}),
        ...(durationValue || durationValue === null ? { duration_value: durationValue } : {}),

        ...(entityId || entityId === null ? { entity_id: entityId } : {}),
        ...(entityType || entityType === null ? { entity_type: entityType } : {}),

        ...(appointmentTypeId ? { appointment_type_id: appointmentTypeId } : {}),

        ...(absenceTypeId || absenceTypeId === null ? { absence_type_id: absenceTypeId } : {}),
        ...(categoryId || categoryId === null ? { category_id: categoryId } : {}),
        ...(priorityId || priorityId === null ? { priority_id: priorityId } : {}),

        ...(responsibles ? { responsibles } : {}),
        ...(responsiblesMain ? { responsibles_main: responsiblesMain } : {}),

        ...(resources && Object.keys(resources).length !== 0 ? { resources } : {}),
        ...(resourceItems ? { resourceItems } : {}),

        ...(createdAt ? { created_at: createdAt } : {}),
        ...(createdBy ? { created_by: createdBy } : {}),
        ...(updatedAt ? { updated_at: updatedAt } : {}),
        ...(updatedBy ? { updated_by: updatedBy } : {}),
        ...(deletedAt ? { deleted_at: deletedAt } : {}),
        ...getDateValue(completedAt, 'completed_at'),
        ...(completedBy ? { completed_by: completedBy } : {}),
        ...(plannedAt
            ? { planned_at: typeof plannedAt === 'string' ? plannedAt : exportDate(null, true) }
            : {}),

        ...(resource_swap ? { resource_swap } : {}),
        ...(limit ? { limit } : {}),
    };
};

export const generateAppointmentColor = (appointment, employees, resources) => {
    const { resourceOwner } = appointment;

    if (resourceOwner) {
        const { id: resourceId, resource: resourceType } = resourceOwner;

        if (resourceType === FLAT_UNPLANNED.key) {
            return indigo['500'];
        }

        if (resourceType === EMPLOYEE_RESOURCE) {
            const employee = employees.find(employee => resourceId === employee.id);

            if (employee) {
                const formatted = formatEmployeeForCalendar(employee);

                if (formatted && formatted.color) {
                    return formatted.color;
                }
            }
        }

        if (resourceType === RESOURCE_RESOURCE) {
            const resource = resources.find(resource => resourceId === resource.id);

            if (resource) {
                const formatted = formatResourceForCalendar(resource);

                if (formatted && formatted.color) {
                    return formatted.color;
                }
            }
        }
    }

    return grey[500];
};

export const getDuplicationKeys = () => {
    if (window.navigator.platform.includes('Mac')) {
        return ['Meta'];
    }

    return ['Control'];
};

export const formatAppointmentDuplicationParams = appointment => {
    const { id, ...rest } = appointment;

    return rest;
};

export const restructureDates = (startDate, endDate, allDay = false, locale = de) => {
    const start = importDate(startDate);
    const end = importDate(endDate);

    if (!start && !end) {
        return null;
    }

    if (!start || !end) {
        return [formatLocalDateTime(start || end)];
    }

    if (isSameDay(start, end)) {
        return [
            formatLocalDate(start, true, locale),
            allDay ? ALLDAY : `${formatTime(start)} - ${formatTime(end)}`,
        ];
    }

    if (allDay) {
        return [formatLocalDate(start, true, locale), formatLocalDate(end, true, locale), ALLDAY];
    }

    return [formatLocalDateTime(start, locale), formatLocalDateTime(end, locale)];
};

export const isAppointmentInRange = (appointment, viewRange) => {
    if (!(appointment?.startDate && appointment.endDate && viewRange)) {
        return false;
    }

    const appointmentRange = {
        start: importDate(appointment.startDate),
        end: importDate(appointment.endDate),
    };

    return areIntervalsOverlapping(appointmentRange, viewRange);
};

export const generateMonthViewRowKey = () => {};

export const assignAppointmentForCalendar = (appointmentsByRow, appointment) => {
    if (appointment.allDay) {
        if (!appointmentsByRow[ALLDAY]) {
            appointmentsByRow[ALLDAY] = [];
        }

        appointmentsByRow[ALLDAY].push(appointment);
    }

    return appointmentsByRow;
};

export const assignAppointmentForPlanningCalendar = (appointmentsByRow, appointment) => {
    appointment.baseResources.forEach(flatResource => {
        if (!appointmentsByRow[flatResource]) {
            appointmentsByRow[flatResource] = [];
        }
        appointmentsByRow[flatResource].push(appointment);
    });

    if (!appointment.plannedAt) {
        if (!appointmentsByRow[FLAT_UNPLANNED.key]) {
            appointmentsByRow[FLAT_UNPLANNED.key] = [];
        }
        appointmentsByRow[FLAT_UNPLANNED.key].push(appointment);
    }

    return appointmentsByRow;
};

export const convertAppointmentsByRowToDailyMeta = (appointmentsObject, range) => {
    const { start: rangeStart, end: rangeEnd } = range;

    return Object.keys(appointmentsObject).reduce((resultObject, groupKey) => {
        const appointmentGroupArray = appointmentsObject[groupKey].sort(
            (a, b) => importDate(a.startDate) - importDate(b.startDate)
        );
        const dateCounts = {};
        const appointmentIdsByDate = {};

        appointmentGroupArray.forEach(({ id, startDate, endDate }) => {
            if (typeof startDate === 'string' && typeof endDate === 'string') {
                let maxStart = max([importDate(startDate), rangeStart]);
                const minEnd = min([importDate(endDate), rangeEnd]);

                while (maxStart <= minEnd) {
                    const dateKey = formatDate(maxStart);
                    dateCounts[dateKey] = (dateCounts[dateKey] || 0) + 1;
                    appointmentIdsByDate[dateKey] = Array.isArray(appointmentIdsByDate[dateKey])
                        ? [...appointmentIdsByDate[dateKey], id]
                        : [id];
                    maxStart = addDays(maxStart, 1);
                }
            } else if (typeof (startDate || endDate) === 'string') {
                const singleDate = new Date(startDate || endDate);
                const dateKey = formatDate(singleDate);
                dateCounts[dateKey] = (dateCounts[dateKey] || 0) + 1;
                appointmentIdsByDate[dateKey] = Array.isArray(appointmentIdsByDate[dateKey])
                    ? [...appointmentIdsByDate[dateKey], id]
                    : [id];
            }
        });

        resultObject[groupKey] = {
            total: Math.max(...Object.values(dateCounts)),
            appointmentIdsByDate,
        };
        return resultObject;
    }, {});
};
