import React, { useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import Button from '../form/base/Button';
import { makeStyles } from '@mui/styles';
import LoadingButton from '../loading/LoadingButton';
import MenuItem from './MenuItem';
import { DELETE } from '../../modules/abilities/actions';
import Can from '../../modules/abilities/Can';

const useStyles = makeStyles(theme => ({
    confirm: {
        listStyle: 'none',
        padding: theme.spacing(2),
        paddingTop: 2,
        marginTop: 1,
        backgroundColor: theme.palette.background.error,
    },
}));

const MenuDestroyIntent = ({
    label,
    confirmLabel,
    loading: overrideLoading,
    subject,
    Icon,
    onDelete,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [confirm, setConfirm] = useState(false);
    const [loading, setLoading] = useState(false);

    const IconComponent = useMemo(() => Icon || DeleteForeverOutlinedIcon, [Icon]);

    const handleDelete = () => {
        if (onDelete) {
            setLoading(true);
            return onDelete()
                .then(() => {
                    setLoading(false);
                    setConfirm(false);
                })
                .catch(() => {
                    setLoading(false);
                    setConfirm(false);
                });
        } else {
            setLoading(false);
            setConfirm(false);
        }
    };

    const handleAbort = () => {
        setConfirm(false);
    };

    const handleIntent = () => {
        setConfirm(true);
    };

    return confirm ? (
        <Grid
            container
            spacing={2}
            justifyContent="center"
            component="li"
            role="menuitem"
            className={classes.confirm}
        >
            <Grid item xs={2}>
                <IconComponent color="error" fontSize="small" />
            </Grid>
            <Grid item xs={10}>
                <Typography color="error">
                    {confirmLabel || t('components.MenuDestroyIntent.confirm')}
                </Typography>
            </Grid>
            <Grid item>
                <Button size="tiny" variant="contained" onClick={handleAbort} contrast>
                    {t('no')}
                </Button>
            </Grid>
            <Grid item>
                <LoadingButton
                    size="tiny"
                    color="danger"
                    variant="contained"
                    isLoading={overrideLoading === undefined ? loading : overrideLoading}
                    onClick={handleDelete}
                    contrast
                >
                    {t('yes')}
                </LoadingButton>
            </Grid>
        </Grid>
    ) : (
        <Can I={DELETE} this={subject}>
            <MenuItem
                label={label || t('components.MenuDestroyIntent.intent')}
                icon={<IconComponent color="error" fontSize="small" />}
                labelProps={{ color: 'error' }}
                onClick={handleIntent}
            />
        </Can>
    );
};

MenuDestroyIntent.propTypes = {
    label: PropTypes.string,
    confirmLabel: PropTypes.string,
    loading: PropTypes.bool,
    subject: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    Icon: PropTypes.elementType,
    onDelete: PropTypes.func.isRequired,
};

MenuDestroyIntent.defaultProps = {
    label: null,
    confirmLabel: null,
    loading: undefined,
    subject: null,
    Icon: null,
};

export default MenuDestroyIntent;
