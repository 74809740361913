import React from 'react';
import * as PropTypes from 'prop-types';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { useTranslation } from 'react-i18next';
import Confirmation from '../form/Confirmation';
import IconButton from '../form/base/IconButton';
import { DELETE } from '../../modules/abilities/actions';
import { SubjectPropType } from '../../modules/abilities/proptypes';

const DeleteIconButton = ({
    onClick,
    size,
    contrast,
    confirmLabel,
    disabled,
    subject,
    confirmBadge,
    ...other
}) => {
    const { t } = useTranslation();

    return (
        <Confirmation
            popupId="DeleteIconButton"
            label={confirmLabel || t('components.DestroyIntent.confirm')}
            badge={confirmBadge}
            onConfirm={onClick}
        >
            <IconButton
                size={size}
                contrast={contrast}
                disabled={disabled}
                subject={subject}
                action={DELETE}
                label={t('components.ResourceTableRowDetails.delete')}
                data-test-id="DeleteIconButton"
                {...other}
            >
                <DeleteForeverOutlinedIcon />
            </IconButton>
        </Confirmation>
    );
};

DeleteIconButton.propTypes = {
    confirmLabel: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    size: PropTypes.string,
    contrast: PropTypes.bool,
    disabled: PropTypes.bool,
    subject: SubjectPropType,
    confirmBadge: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

DeleteIconButton.defaultProps = {
    confirmLabel: null,
    size: 'medium',
    contrast: false,
    disabled: false,
    subject: null,
    confirmBadge: null,
};

export default DeleteIconButton;
