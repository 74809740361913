import * as PropTypes from 'prop-types';

export const TableActionPropType = PropTypes.shape({
    key: PropTypes.string,
    action: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    compact: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
});

export const TableExpandPropType = PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
        component: PropTypes.func.isRequired,
    }),
]);

export const TableOrderPropType = PropTypes.shape({
    key: PropTypes.string.isRequired,
    direction: PropTypes.oneOf(['asc', 'desc']),
});

export const TableSortingPropType = PropTypes.shape({
    id: PropTypes.string.isRequired,
    desc: PropTypes.bool,
});
