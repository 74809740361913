import React from 'react';
import * as PropTypes from 'prop-types';
import { IdPropType } from '../../modules/proptypes';
import { IDENTIFIER_TYPES } from '../../modules/datacontainers/utils';
import { Grid } from '@mui/material';
import CardIdentifier from './CardIdentifier';

const CardIdentifiers = ({ cardId, contrast, variant, onChange, I }) => (
    <Grid container justifyContent="space-between" alignItems="flex-end">
        {[IDENTIFIER_TYPES.ORDER, IDENTIFIER_TYPES.OFFER].map(identifierType => (
            <CardIdentifier
                identifierType={identifierType}
                cardId={cardId}
                contrast={contrast}
                variant={variant}
                onChange={onChange}
                I={I}
                key={`identifier.${identifierType}`}
            />
        ))}
    </Grid>
);

CardIdentifiers.propTypes = {
    cardId: IdPropType,
    contrast: PropTypes.bool,
    variant: PropTypes.string,
    onChange: PropTypes.func,
    I: PropTypes.string,
};

CardIdentifiers.defaultProps = {
    cardId: null,
    contrast: false,
    variant: 'outlined',
    onChange: () => {},
    I: null,
};

export default CardIdentifiers;
