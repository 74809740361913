import React from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import BlockIcon from '@mui/icons-material/Block';
import { useTranslation } from 'react-i18next';
import { selectPriorityById } from '../../modules/priority/prioritySlice';
import { IdPropType } from '../../modules/proptypes';
import Icon from '../icons/Icon';
import { ConditionalWrapper } from '../utils/ConditionalWrapper';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        width: 'fit-content',
        borderRadius: 2,
    },
    icon: {
        verticalAlign: 'middle',
    },
    empty: {
        color: theme.palette.text.secondary,
        fontStyle: 'italic',
    },
    emptyIcon: {
        marginLeft: 2,
        verticalAlign: 'middle',
        color: theme.palette.text.secondary,
    },
}));

const Priority = ({ priorityId, onlyIcon, showEmpty, size, enableTooltip }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const taskPriority = useSelector(state => selectPriorityById(state, priorityId));

    return (
        <ConditionalWrapper
            condition={enableTooltip}
            wrapper={_children => (
                <Tooltip
                    title={`${t('components.Priority.tooltip')} ${taskPriority?.name}`}
                    disableInteractive
                >
                    {_children}
                </Tooltip>
            )}
        >
            <Box
                className={classes.root}
                component="div"
                style={{
                    padding: size === 'small' || size === 'tiny' ? 0 : 4,
                }}
            >
                {taskPriority || showEmpty ? (
                    <>
                        {taskPriority && (
                            <Icon
                                type={taskPriority.icon}
                                className={classes.icon}
                                style={{
                                    ...(size === 'tiny'
                                        ? {
                                              height: 10,
                                              lineHeight: '10px',
                                          }
                                        : {}),
                                    fontSize: size === 'tiny' ? 12 : 24,
                                    color: `${taskPriority.color.startsWith('#') ? '' : '#'}${
                                        taskPriority.color
                                    }`,
                                    marginRight: onlyIcon ? 0 : 6,
                                }}
                            />
                        )}
                        {!taskPriority && showEmpty && onlyIcon && (
                            <BlockIcon
                                className={classes.emptyIcon}
                                style={{
                                    fontSize: size === 'tiny' ? 12 : 24,
                                    marginRight: onlyIcon ? 0 : 6,
                                }}
                            />
                        )}
                        {!onlyIcon && (
                            <Typography
                                component="span"
                                className={!taskPriority ? classes.empty : null}
                                style={{ marginRight: size === 'small' ? 6 : 3 }}
                            >
                                {(taskPriority && taskPriority.name) ||
                                    t('components.TaskPriority.selectTaskPriority')}
                            </Typography>
                        )}
                    </>
                ) : null}
            </Box>
        </ConditionalWrapper>
    );
};

Priority.propTypes = {
    priorityId: IdPropType.isRequired,
    onlyIcon: PropTypes.bool,
    showEmpty: PropTypes.bool,
    size: PropTypes.string,
    enableTooltip: PropTypes.bool,
};

Priority.defaultProps = {
    onlyIcon: false,
    showEmpty: false,
    size: 'small',
    enableTooltip: false,
};

export default Priority;
