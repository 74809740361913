import React from 'react';
import * as PropTypes from 'prop-types';
import NumberInput from './NumberInput';
import { InputAdornment } from '@mui/material';

const locale = 'de-DE'; // ToDo: I18n
const currencyFormat = { style: 'currency', currency: 'EUR' };

export const formatMoney = value => {
    const float = typeof value === 'string' ? parseFloat(value) : value;

    if (typeof float === 'number') {
        return float.toLocaleString(locale, currencyFormat);
    }

    return null;
};

const MoneyInput = ({ name, ...other }) => {
    return (
        <NumberInput
            name={name}
            digits={2}
            //numberFormat={currencyFormat}
            //locale={locale}
            InputProps={{
                endAdornment: <InputAdornment position="end">{'€'}</InputAdornment>,
            }}
            {...other}
        />
    );
};

MoneyInput.propTypes = {
    name: PropTypes.string.isRequired,
};

export default MoneyInput;
