import { createSlice } from '@reduxjs/toolkit';
import { CHECKLIST_ITEM_RESOURCE } from '../api/resources';

// function that sorts an checklistItem Array by sort
function compareBySort(e1, e2) {
    if (e1.sort < e2.sort) {
        return -1;
    }
    if (e1.sort > e2.sort) {
        return 1;
    }
    return 0;
}

const insertByChecklistId = (state, checklistItem) => {
    const byTaskId = state.byChecklistId[checklistItem.checklist_id];

    if (!byTaskId) {
        state.byChecklistId[checklistItem.checklist_id] = [];
    }

    if (!state.byChecklistId[checklistItem.checklist_id].includes(checklistItem.id)) {
        state.byChecklistId[checklistItem.checklist_id].push(checklistItem.id);
    }
};

const insertChecklistItem = (state, checklistItem) => {
    const stale = state.byId[checklistItem.id];

    if (!stale) {
        state.allIds.push(checklistItem.id);
    }

    state.byId[checklistItem.id] = checklistItem;

    insertByChecklistId(state, checklistItem);
};

const checklistItemsSlice = createSlice({
    name: CHECKLIST_ITEM_RESOURCE,
    initialState: {
        byId: {},
        allIds: [],
        byChecklistId: {},
        loading: false,
    },
    reducers: {
        indexPending: state => {
            state.loading = true;
        },

        indexFulfilled: (state, action) => {
            state.loading = false;
            action.payload.forEach(checklistItem => {
                insertChecklistItem(state, checklistItem);
            });
        },

        showFulfilled: (state, action) => {
            insertChecklistItem(state, action.payload);
        },

        storeFulfilled: (state, action) => {
            insertChecklistItem(state, action.payload);
        },

        updateFulfilled: (state, action) => {
            insertChecklistItem(state, action.payload);

            // update checklistItem Array by sort...
            const tmpArray = [];
            state.byChecklistId[action.payload.checklist_id].forEach(elementId => {
                tmpArray.push(state.byId[elementId]);
            });
            state.byChecklistId[action.payload.checklist_id] = [];
            state.allIds = [];
            tmpArray.sort(compareBySort).forEach(checklist => {
                state.byChecklistId[action.payload.checklist_id].push(checklist.id);
                state.allIds.push(checklist.id);
            });
        },

        destroyFulfilled: (state, action) => {
            const indexById = state.allIds.findIndex(id => id === action.payload);
            if (indexById >= 0) {
                delete state.byId[action.payload];
                state.allIds.splice(indexById, 1);
            }

            if (action.meta.params && action.meta.params.checklistId) {
                const indexByChecklist = state.byChecklistId[
                    action.meta.params.checklistId
                ].findIndex(id => id === action.payload);
                if (indexByChecklist >= 0) {
                    state.byChecklistId[action.meta.params.checklistId].splice(indexByChecklist, 1);
                }
            }
        },
    },
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,
} = checklistItemsSlice.actions;

export default checklistItemsSlice.reducer;
