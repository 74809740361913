/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { compareByDate } from './utils';
import { PROTOCOL_ITEM_RESOURCE } from '../api/resources';
import { convertUTCtoLocalDate } from '../datetime/utils';

const insertProtocolItem = (state, protocolItem) => {
    if (!state.byCard[protocolItem.card_id]) {
        state.byCard[protocolItem.card_id] = [];
    }

    const stale = state.byId[protocolItem.id];
    if (!stale) {
        state.allIds.push(protocolItem.id);
        state.byCard[protocolItem.card_id].push(protocolItem.id);
    }

    protocolItem.date = protocolItem.date
        ? convertUTCtoLocalDate(protocolItem.date)
        : protocolItem.date;

    protocolItem.contactIds = protocolItem.contacts
        ? protocolItem.contacts.map(contact => contact.id)
        : [];

    state.byId[protocolItem.id] = protocolItem;
};

const sortProtocolItems = (state, changed) =>
    changed.forEach(cardId =>
        state.byCard[cardId].sort((p1, p2) => compareByDate(state.byId[p1], state.byId[p2]))
    );

const protocolItemSlice = createSlice({
    name: PROTOCOL_ITEM_RESOURCE,
    initialState: {
        byId: {},
        allIds: [],
        byCard: {},
        error: null,
        loading: false,
    },
    reducers: {
        indexPending: (state, action) => {
            if (!action.meta.socketReaction) {
                state.loading = true;
            }
        },
        indexFulfilled: (state, action) => {
            if (!action.meta.socketReaction) {
                state.loading = false;
            }

            if (
                !action.meta.socketReaction ||
                /* ignore protocolItems for cards that were never opened */
                (action.payload.length > 0 && state.byCard[action.payload[0].card_id])
            ) {
                /* initialize with empty array anyway if cards have no protocol items yet
                 * (so that we know we have opened the card already) */
                if (
                    action.payload.length === 0 &&
                    action.meta.params &&
                    action.meta.params.card_id
                ) {
                    const cardId = action.meta.params.card_id;
                    if (!state.byCard[cardId]) {
                        state.byCard[cardId] = [];
                    }
                }

                const changed = action.payload.reduce((carry, protocolItem) => {
                    insertProtocolItem(state, protocolItem);
                    if (!carry.includes(protocolItem.card_id)) {
                        carry.push(protocolItem.card_id);
                    }
                    return carry;
                }, []);

                sortProtocolItems(state, changed);
            }
        },
        indexError: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
        showFulfilled: (state, action) => {
            insertProtocolItem(state, action.payload);
            sortProtocolItems(state, [action.payload.card_id]);
        },
        searchFulfilled: (state, action) => {
            action.payload.forEach((protocolItem, index) => {
                insertProtocolItem(state, protocolItem);
            });
        },
        storeFulfilled: (state, action) => {
            insertProtocolItem(state, action.payload);
            sortProtocolItems(state, [action.payload.card_id]);
        },
        updateFulfilled: (state, action) => {
            insertProtocolItem(state, action.payload);
            sortProtocolItems(state, [action.payload.card_id]);
        },
        destroyFulfilled: (state, action) => {
            const id = action.payload;

            const index = state.allIds.findIndex(_id => _id === id);
            if (index >= 0) {
                delete state.byId[id];
                state.allIds.splice(index, 1);
            }

            let cardId = null;
            Object.keys(state.byCard).some(key => {
                cardId = state.byCard[key].includes(id) ? key : null;
                return cardId !== null;
            });

            const byCard = state.byCard[cardId];
            if (byCard) {
                const indexInCard = byCard.findIndex(_id => _id === id);
                byCard.splice(indexInCard, 1);
            }
        },
    },
});

export const {
    indexPending,
    indexFulfilled,
    indexError,
    showFulfilled,
    searchFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,
} = protocolItemSlice.actions;

export default protocolItemSlice.reducer;
