import React, { forwardRef, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import DurationInput from '../form/formik/DurationInput';
import TaskSelect from '../form/formik/TaskSelect';
import TaskTypeSelect from '../form/formik/TaskTypeSelect';
import TextInput from '../form/formik/TextInput';
import { CREATE, UPDATE } from '../../modules/abilities/actions';
import ResponsiblesSelect from '../form/ResponsiblesSelect';
import { ReferenceIdPropType } from '../../modules/proptypes';
import { useTranslation } from 'react-i18next';
import DateTimeToggleInput from '../form/formik/DateTimeToggleInput';
import Checkbox from '../form/formik/Checkbox';
import { exportDate } from '../../modules/datetime/utils';
import IdentifierAutocomplete from '../form/formik/autocomplete/IdentifierAutocomplete';
import { IDENTIFIER_TYPES } from '../../modules/datacontainers/utils';
import { useContextualCan } from '../../modules/abilities/hooks';
import UserAutocomplete from '../form/formik/autocomplete/UserAutocomplete';
import FallbackValue from '../form/formik/FallbackValue';
import Duration from '../utils/Duration';
import ValueChangeAutofocus from '../form/formik/ValueChangeAutofocus';
import WatchContainer from '../form/formik/WatchContainer';
import AppointmentSelect from '../form/formik/AppointmentSelect';
import NumberInput from '../form/formik/NumberInput';
import CardAutocomplete from '../form/formik/autocomplete/CardAutocomplete';

const JournalFormBody = ({ create, referenceId, children }) => {
    const { t } = useTranslation();
    const canEditPersons = useContextualCan(create ? CREATE : UPDATE, 'user_id');

    const responsibleInput = useMemo(() => {
        if (canEditPersons) {
            if (create) {
                return <ResponsiblesSelect name="user_id" type="person" disableMain />;
            }

            return (
                <UserAutocomplete
                    name="user_id"
                    label={t('components.ResourceTable.JournalTable.replacePerson')}
                />
            );
        }

        return null;
    }, [create, canEditPersons]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <DateTimeToggleInput
                    name="started_at"
                    hasTimeName="started_has_time"
                    label={t('components.ResourceTable.JournalTable.startedAt')}
                />
            </Grid>
            <Grid item xs={6}>
                <DurationInput
                    name="duration"
                    label={t('components.ResourceTable.JournalTable.duration')}
                    size="small"
                    fullWidth
                />
            </Grid>
            {referenceId?.key !== 'card_id' ? (
                <Grid item xs={6}>
                    <CardAutocomplete
                        name="card_id"
                        label={t('components.ResourceTable.JournalTable.card')}
                        size="small"
                        showInitialState
                        fullWidth
                    />
                </Grid>
            ) : null}
            <Grid item xs={6}>
                <WatchContainer
                    name="card_id"
                    WatchComponent={({ value }) => (
                        <TaskSelect
                            name="task_id"
                            label={t('components.ResourceTable.JournalTable.task')}
                            referenceId={{ value, key: 'card_id' }}
                            fullWidth
                        />
                    )}
                />
            </Grid>
            <Grid item xs={6}>
                <WatchContainer
                    name="task_id"
                    WatchComponent={({ value }) => (
                        <AppointmentSelect
                            name="appointment_id"
                            taskId={value}
                            can={UPDATE}
                            fullWidth
                        />
                    )}
                />
            </Grid>
            <Grid item xs={6}>
                <TaskTypeSelect
                    name="task_type_id"
                    label={t('components.ResourceTable.JournalTable.taskType')}
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}>
                <FallbackValue
                    name="duration_billable"
                    fallbackName="duration"
                    applyOnClick
                    FallbackComponent={({ fallback }) =>
                        typeof fallback === 'string' ? (
                            fallback
                        ) : (
                            <Duration durationValue={fallback} />
                        )
                    }
                    FormComponent={forwardRef(({ hideLabel }, ref) => (
                        <DurationInput
                            name="duration_billable"
                            label={t('components.ResourceTable.JournalTable.durationBillable')}
                            size="small"
                            shrink={hideLabel ? true : undefined}
                            ref={ref}
                            fullWidth
                        />
                    ))}
                />
            </Grid>
            <Grid item xs={1}>
                <Checkbox
                    name="transport_private"
                    label={t('components.ResourceTable.JournalTable.transportPrivate')}
                    size="small"
                    labelTooltip
                />
            </Grid>
            <Grid item xs={5}>
                <NumberInput
                    name="transport_km"
                    label={t('components.ResourceTable.JournalTable.transportKm')}
                    digits={1}
                    size="small"
                    fullWidth
                />
            </Grid>
            <Grid item xs={6} />
            {referenceId?.key === 'card_id' ? <Grid item xs={6} /> : null}
            <Grid item xs={1}>
                <Checkbox
                    name="billed_at"
                    label={t('components.ResourceTable.JournalTable.billed')}
                    formatValue={checked => (checked ? exportDate(null, true) : null)}
                    size="small"
                    labelTooltip
                />
            </Grid>
            <Grid item xs={5}>
                <ValueChangeAutofocus
                    name="billed_at"
                    FocusComponent={({ focusRef, ...other }) => (
                        <IdentifierAutocomplete
                            name="id_invoice"
                            label={t('components.ResourceTable.JournalTable.invoice')}
                            fetchParams={
                                referenceId?.key === 'user_id' && referenceId.value
                                    ? { card_id: referenceId.value }
                                    : null
                            }
                            type={IDENTIFIER_TYPES.INVOICE}
                            size="small"
                            inputRef={focusRef}
                            confirmRemove
                            fullWidth
                            {...other}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <TextInput
                    name="description"
                    label={t('components.ResourceTable.JournalTable.description')}
                    size="small"
                    multiline
                    minRows={5}
                />
            </Grid>
            <Grid item xs>
                {responsibleInput}
            </Grid>
            {children}
        </Grid>
    );
};

JournalFormBody.propTypes = {
    create: PropTypes.bool,
    referenceId: ReferenceIdPropType,
};

JournalFormBody.defaultProps = {
    create: false,
    referenceId: null,
};

export default JournalFormBody;
