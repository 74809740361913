import React from 'react';
import * as PropTypes from 'prop-types';
import LoadingButton from '../../loading/LoadingButton';
import { RefPropType } from '../../../modules/proptypes';
import LoadingIcon from '../../loading/LoadingIcon';
import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useContextualCan } from '../../../modules/abilities/hooks';
import { WRITE } from '../../../modules/abilities/actions';

const SubmitIconButton = ({
    icon,
    innerRef,
    alwaysEnabled,
    disabled,
    onClick,
    I,
    size,
    ...other
}) => {
    const { dirty, isSubmitting, submitForm } = useFormikContext();
    const canDo = useContextualCan(I ? I : WRITE);

    const handleClick = event => {
        event.preventDefault();

        return Promise.resolve(onClick(event)).then(() => {
            /*
             * the submit button is not guaranteed to be a child of the form element in the dom tree
             * -> call submitForm explicitly
             */
            return submitForm();
        });
    };

    return (
        <LoadingIcon isLoading={isSubmitting} size="small">
            <IconButton
                type="submit"
                disabled={disabled || (!dirty && !alwaysEnabled) || !canDo}
                isLoading={isSubmitting}
                onClick={handleClick}
                ref={innerRef}
                size={size}
                {...other}
            >
                {icon || <SaveIcon fontSize={size} />}
            </IconButton>
        </LoadingIcon>
    );
};

SubmitIconButton.propTypes = {
    icon: PropTypes.node,
    innerRef: PropTypes.shape({ current: PropTypes.instanceOf(LoadingButton) }),
    color: PropTypes.string,
    onClick: PropTypes.func,
    alwaysEnabled: PropTypes.bool,
    disabled: PropTypes.bool,
    I: PropTypes.string,
    size: PropTypes.string,
};

SubmitIconButton.defaultProps = {
    icon: null,
    innerRef: null,
    color: 'primary',
    onClick: () => null,
    alwaysEnabled: false,
    disabled: false,
    I: null,
    size: 'small',
};

export default SubmitIconButton;
