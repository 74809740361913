import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as PropTypes from 'prop-types';
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';

import configureTheme from '../../styles/configureTheme';
import { selectSettingsByKey } from '../../modules/settings/selectors';
import { MANIFEST_THEME_OPTIONS } from '../../modules/settings/config';

const ThemeProvider = ({ children, override }) => {
    const overrideOptions = useSelector(state =>
        selectSettingsByKey(state, MANIFEST_THEME_OPTIONS)
    );

    const theme = useMemo(
        () => configureTheme(overrideOptions, override),
        [overrideOptions, override]
    );

    if (!overrideOptions) {
        return null;
    }

    return <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>;
};

ThemeProvider.propTypes = {
    children: PropTypes.node.isRequired,
    override: PropTypes.shape({}),
};

ThemeProvider.defaultProps = {
    override: null,
};

export default ThemeProvider;
