import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { Grid, Icon } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { IdPropType } from '../../../../modules/proptypes';
import truncate from 'truncate-html';
import BaseAvatar from '../../../icons/BaseAvatar';
import { RESOURCE_RESOURCE } from '../../../../modules/api/resources';
import { RESOURCES_FALLBACK_ICON } from '../../../../modules/resources/utils';

const MAX_SUGGEST_TEXT_LENGTH = 150;

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },

    typeContainer: {
        minWidth: '30%',
        textAlign: 'right',
    },

    type: {
        backgroundColor: theme.palette.background.primary,
        color: theme.palette.primary.light,
        display: 'inline-block',
        padding: '0px 4px',
        borderRadius: theme.shape.borderRadius,
    },

    label: {
        paddingRight: theme.spacing(1),

        '& em': {
            fontWeight: 700,
            fontStyle: 'normal',
        },
    },

    space: {
        paddingRight: theme.spacing(1),
    },
}));

const TypedAutocompleteOption = ({ option, hideTypes }) => {
    const classes = useStyles();

    const { shortName, outlined } = useMemo(() => {
        if (option.icon) {
            return {
                shortName: <Icon style={{ fontSize: 12 }}>{option.icon}</Icon>,
                outlined: true,
            };
        }

        if (option.group === RESOURCE_RESOURCE.toLowerCase()) {
            return {
                shortName: <Icon style={{ fontSize: 12 }}>{RESOURCES_FALLBACK_ICON}</Icon>,
                outlined: true,
            };
        }

        return { shortName: option.short_name, outlined: false };
    }, [option]);

    return (
        <Grid container data-test-id="TypedAutocompleteOption" spacing={1} className={classes.root}>
            {!hideTypes && (
                <Grid item className={classes.typeContainer}>
                    <Typography className={classes.type}>{option.label}:</Typography>
                </Grid>
            )}
            {(option.avatar || option.color || option.display_name) && (
                <Grid item>
                    <BaseAvatar
                        path={option.avatar}
                        color={option.color}
                        displayName={option.display_name}
                        shortName={shortName}
                        outlined={outlined}
                        disableHover
                        tiny
                        appointmentAvailabilityProps={{
                            resourceModel: option.type,
                            resourceId: option.id,
                            componentProps: {
                                style: {
                                    fontSize: '12px',
                                    marginTop: '12px',
                                    marginLeft: '-4px',
                                },
                            },
                        }}
                    />
                </Grid>
            )}
            <Grid item xs>
                <Typography
                    dangerouslySetInnerHTML={{
                        __html: truncate(option.highlight || option.value, MAX_SUGGEST_TEXT_LENGTH),
                    }}
                    className={classes.label}
                />
                {option.highlight2 && (
                    <Typography
                        variant="body2"
                        dangerouslySetInnerHTML={{ __html: option.highlight2 }}
                        className={classes.label}
                    />
                )}
                {process.env.NODE_ENV === 'development' && (
                    <Typography variant="body2" color="textSecondary">
                        {option.score}
                    </Typography>
                )}
            </Grid>
        </Grid>
    );
};

TypedAutocompleteOption.propTypes = {
    option: PropTypes.shape({
        id: IdPropType,
        label: PropTypes.string,
        type: PropTypes.string,
        score: PropTypes.number,
        avatar: PropTypes.string,
        value: PropTypes.string.isRequired,
        highlight: PropTypes.string,
        highlight2: PropTypes.string,
    }).isRequired,
    hideTypes: PropTypes.bool,
};

TypedAutocompleteOption.defaultProps = {
    hideTypes: false,
};

export default TypedAutocompleteOption;
