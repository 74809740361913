import React from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import HelpIcon from '@mui/icons-material/Help';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const AIVAILABILITY = {
    WARN: { type: 'warn', color: '#FFCC00' },
    BLOCK: { type: 'block', color: 'red' },
    DEFAULT: { type: 'default', color: 'limegreen' },
};

const useStyles = makeStyles(theme => ({
    icon: {
        fontSize: '12px',
        marginTop: '-10px',
        marginLeft: '-5px',

        position: 'absolute',
        backgroundColor: 'white',
        borderRadius: '100%',
        width: 'fit-content',
    },
}));

const AvailabilityStatus = ({ availability, style }) => {
    const classes = useStyles();

    switch (availability) {
        case AIVAILABILITY.WARN.type:
            return (
                <HelpIcon
                    className={classes.icon}
                    style={{
                        color: AIVAILABILITY.WARN.color,
                        ...style,
                    }}
                />
            );
        case AIVAILABILITY.BLOCK.type:
            return (
                <WarningIcon
                    className={classes.icon}
                    style={{
                        color: AIVAILABILITY.BLOCK.color,
                        ...style,
                    }}
                />
            );
        default:
            return (
                <CheckCircleIcon
                    className={classes.icon}
                    style={{
                        color: AIVAILABILITY.DEFAULT.color,
                        ...style,
                    }}
                />
            );
    }
};

AvailabilityStatus.propTypes = {
    availability: PropTypes.string,
    style: PropTypes.shape({}),
};

AvailabilityStatus.defaultProps = {
    availability: null,
    style: {},
};

export default AvailabilityStatus;
