import materialIcons from '../../assets/icons/MaterialIcons-Regular.json';
import * as categories from '../../assets/icons/IconCategories.json';

export const getIconsOnly = categorizedIcons => {
    return categorizedIcons.default.reduce((carry, category) => {
        const arrayCarry = Array.isArray(carry) ? carry : [carry];

        return [...category.icons, ...arrayCarry];
    });
};

/**
 * ICON SET GENERATOR
 * @return Object containing categorized icons
 * In case to update icons, you might want to:
 *  - Update IconCategories.json with a newer version -> https://github.com/google/material-design-icons -> update/current_versions.json
 *  - Remove check-file for currently available icons (MaterialIcons-Regular.json - some are not working still, but removes the most)
 *  - Convert to JSON and update MaterialIcons-Categorized.json
 *  - Look for missing icons removing these manually
 *  - Be aware to join small categories to larger ones since there is a issue having two waypoints in view (using VirtualizedGrid)
 *  - Add chosen category icons
 * */

const capitalizeWord = string => string.charAt(0).toUpperCase() + string.slice(1);
const formatText = icon =>
    icon.includes('_')
        ? icon
              .split('_')
              .map(word => capitalizeWord(word))
              .join(' ')
        : capitalizeWord(icon);

export const generateIconSet = () => {
    const rawIconList = Object.keys(categories.default);

    const rawStructure = Object.entries(
        rawIconList.reduce((carry, item) => {
            let tempCarry = carry;
            const [cat, ico] = item.split('::');
            if (typeof tempCarry === 'string') {
                const [catCarry, icoCarry] = item.split('::');
                tempCarry = {
                    [catCarry]: [icoCarry],
                };
            }

            if (tempCarry[cat]) {
                tempCarry[cat].push(ico);
                return tempCarry;
            }
            return {
                ...tempCarry,
                [cat]: [ico],
            };
        })
    );

    const formattedStructure = rawStructure.map(item => {
        return {
            label: formatText(item[0]),
            key: item[0],
            icon: 'TO BE CHOOSEN',
            icons: item[1].reduce((carry, icon) => {
                const tempCarry = typeof carry === 'string' ? [carry] : carry;

                if (!tempCarry.includes(icon) && materialIcons.icons.includes(icon)) {
                    return [...tempCarry, icon];
                }

                return tempCarry;
            }),
        };
    });

    return formattedStructure.map(category => ({
        ...category,
        icons: category.icons.map(icon => ({
            label: formatText(icon),
            value: icon,
        })),
    }));
};
