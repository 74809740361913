import React, { useEffect, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Box, Collapse, Grid, LinearProgress, Typography } from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { sortableContainer } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import ChecklistItem from './ChecklistItem';
import ChecklistForm from './ChecklistForm';
import Button from '../form/base/Button';
import {
    selectChecklistItemIdsByChecklistId,
    selectChecklistItemsByChecklistId,
    selectLastChecklistItemSortByChecklistId,
} from '../../modules/checklistItems/selectors';
import { IdPropType } from '../../modules/proptypes';
import ChecklistItemForm from './ChecklistItemForm';
import { UPDATE, WRITE } from '../../modules/abilities/actions';

import { updateChecklistItem } from '../../modules/checklistItems/actions';
import IconButton from '../form/base/IconButton';
import { selectChecklistById } from '../../modules/checklists/checklistSlice';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        alignItems: 'center',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        backgroundColor: theme.palette.grey[100],
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: theme.spacing(1),
        borderColor: theme.palette.grey[400],
        padding: theme.spacing(2),
    },
    addButton: {
        display: 'flex',
        margin: 'auto',
        marginTop: theme.spacing(1),
    },
    sortableHelper: {
        listStyleType: 'none',
        zIndex: '10000 !important',
    },
    sortableItem: {
        cursor: 'grab',
    },
}));

const SortableContainer = sortableContainer(({ children }) => {
    return <div className={useStyles().sortableItem}>{children}</div>;
});

const Checklist = ({ checklistId, taskId, isCreate, onCreate }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const checklist = useSelector(state => selectChecklistById(state, checklistId));
    const itemIds =
        useSelector(state => selectChecklistItemIdsByChecklistId(state, checklistId)) || [];
    const items = useSelector(state => selectChecklistItemsByChecklistId(state, checklistId));
    const itemsLastSort = useSelector(state =>
        selectLastChecklistItemSortByChecklistId(state, checklistId)
    );

    // use these itemsLists for fast Front DragnDrop render because waiting for redux dispatch takes too long
    // and the DragnDrop render will freeze
    const [dndItemIds, setDndItemIds] = useState([]);
    const [dndItems, setDndItems] = useState([]);
    const [isSet, setIsSet] = useState(false);

    const percent = useMemo(() => {
        if (items.length === 0) {
            return 0;
        }

        let checked = 0;

        items.forEach(item => {
            if (item.checked_at) {
                checked += 1;
            }
        });

        return Math.round((checked * 100) / items.length);
    }, [items]);

    const [editList, setEditList] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const [create, setCreate] = useState(false);

    useEffect(() => {
        if ((itemIds.length > 0 && !isSet) || itemIds.length !== dndItemIds.length) {
            setIsSet(true);
            setDndItemIds(itemIds);
        }
        setDndItems(items);
        // eslint-disable-next-line
    }, [itemIds.length, percent, editItem]);

    const handleEditList = value => {
        if (checklistId === null) {
            onCreate(value);
        }

        setEditList(value);
    };

    const handleEditItem = (itemId, edit) => {
        if (edit && itemId) {
            setEditItem(itemId);
        } else {
            setEditItem(null);
        }

        if (create) {
            setCreate(false);
        } else if (itemId === null) {
            setCreate(edit);
        }
    };

    const handleEditClick = () => setEditList(!editList);

    const handleAddItem = () => {
        if (editItem || create) {
            setCreate(false);
            setEditItem(null);

            setTimeout(() => {
                setCreate(true);
            }, 120);
        } else {
            setCreate(true);
            setEditItem(null);
        }
    };

    const dispatch = useDispatch();

    const onSortEnd = ({ oldIndex, newIndex }) => {
        setDndItemIds(items => arrayMove(items, oldIndex, newIndex));
        const update = arrayMove(dndItems, oldIndex, newIndex);
        setDndItems(update);
        const finalItems = [];
        update.map((it, i) => {
            const tmp = { ...it, sort: i };
            finalItems.push(tmp);
            return dispatch(updateChecklistItem(tmp));
        });
        setDndItems(finalItems);
    };

    /**
     * Function to see if a target or any of it's parents have a property
     */
    const targetHasProp = (target, hasProp) => {
        while (target) {
            if (hasProp(target)) {
                return true;
            }
            // eslint-disable-next-line no-param-reassign
            target = target.parentElement;
        }
        return false;
    };

    const shouldCancelSortStart = coach => {
        // Cancel sort if a user is interacting with a given element
        return targetHasProp(coach.target, el => {
            return ['button', 'input', 'textarea', 'select', 'option'].includes(
                el.tagName.toLowerCase()
            );
        });
    };

    return (
        <Box className={classes.root} data-test-id="checklist-row">
            {editList || checklistId === null ? (
                <ChecklistForm
                    can={WRITE}
                    checklistId={checklistId}
                    taskId={taskId}
                    onEdit={handleEditList}
                />
            ) : (
                <>
                    <Typography variant="h5" color="primary" data-test-id="checkListName">
                        {checklist.name}
                    </Typography>
                    <Grid container spacing={2} alignItems="center" data-test-class="checklist-row">
                        <Grid item xs>
                            <LinearProgress variant="determinate" value={percent} />
                        </Grid>
                        <Grid item data-test-id="checkListPercent">
                            <Typography>{`${percent}%`}</Typography>
                        </Grid>
                        <Grid item>
                            <IconButton
                                onClick={handleEditClick}
                                action={UPDATE}
                                subject={checklist}
                            >
                                <EditOutlinedIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </>
            )}
            <Collapse in={!isCreate && !editList}>
                <SortableContainer
                    helperClass={classes.sortableHelper}
                    onSortEnd={onSortEnd}
                    distance={0}
                    axix="y"
                    lockAxis="y"
                    shouldCancelStart={shouldCancelSortStart}
                >
                    {(create ? [...dndItemIds, null] : dndItemIds).map((itemId, hoverIndex) =>
                        editItem === itemId ? (
                            <ChecklistItemForm
                                itemId={itemId}
                                checklistId={checklistId}
                                taskId={taskId}
                                onEdit={handleEditItem}
                                key={itemId}
                                newSortIndex={
                                    dndItemIds.length > 0 && dndItemIds[dndItemIds.length - 1]
                                        ? dndItems[dndItems.length - 1].sort
                                        : itemsLastSort + 1
                                }
                                can={WRITE}
                            />
                        ) : (
                            <ChecklistItem
                                itemId={itemId}
                                onEdit={handleEditItem}
                                key={itemId}
                                index={hoverIndex} // for sortable
                            />
                        )
                    )}
                </SortableContainer>

                <Button
                    onClick={handleAddItem}
                    className={classes.addButton}
                    data-test-id="add-checklist-item-btn"
                >
                    <AddOutlinedIcon />
                    <Typography>{t('components.Checklist.addItem')}</Typography>
                </Button>
            </Collapse>
        </Box>
    );
};

Checklist.propTypes = {
    checklistId: IdPropType.isRequired,
    taskId: IdPropType.isRequired,
    isCreate: PropTypes.bool.isRequired,
    onCreate: PropTypes.func.isRequired,
};

export default Checklist;
